<template>
  <div>

    <div class="floating-menu" v-if="isShowFloatingOptions">
      <div class="floating-icons">
        <a @click="setBookmark" :class="'float-icon bookmark-icon ' + (isBookmarked ? 'active' : '')">
          <i :class="'fa fa-lg ' + (isBookmarked ? ' fa-bookmark' : ' fa-bookmark-o')"></i>
        </a>
        <!-- <a  href="#"  class="float-icon edit-icon">
          <img src="../../../assets/images/edit-icon.svg">
        </a> -->
        
        <a @click="highlight" :disabled="!(selectedRange && selectedRange.length && selectedRange.length >0)" href="#"
          class="float-icon highlight-icon">
          <img src="../../../assets/images/brush-icon.svg">
        </a>
        <a @click="takeNotes" href="#" class="float-icon note-icon">
          <img src="../../../assets/images/edit-alt-icon.svg">
        </a>
        <a href="#" class="float-icon calculator-icon">
          <img src="../../../assets/images/calculator-icon.svg">
        </a>
      </div>
    </div>
    <div class="freeText-main-content">
      <!-- <h1 class="main-heading">{{ contentData.title }}</h1> -->
      <div id="editor">
      </div>

      <b-sidebar id="my-sidebar" title="Notes" :visible="isOffcanvasOpen" shadow right>
        <div class="sidebar-content p-4">
          <div class="note-header">
            <h2 class="modal-heading">Notes</h2>
            
            <i v-if="selectedNoteIndex > -1" class="fas fa-trash-alt" @click="removeNote(selectedNoteIndex)"></i>
          </div>
          <div class="note-content">
            <p v-if="selectedNote.text">{{ selectedNote.text }}</p>
            <div class="form-group">
              <textarea v-model="selectedNote.comment" placeholder="Write your comment" maxlength="500"
                class="form-control notes-textarea"></textarea>
              <small class="form-text text-muted character-counter">
                {{ selectedNote.comment.length }} / 500 characters
              </small>
            </div>

            <button @click="setComment(selectedNoteIndex, selectedNote.comment)" class="btn btn-primary btn-confirm">
              Save
            </button>

            <button @click="toggleOffcanvas()" class="btn btn-outline-primary mx-3">
              Close
            </button>
          </div>
        </div>
      </b-sidebar>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "FreeText",
  components: { },
  data() {
    return {
      quillEditor: null,
      selectedRange: null,
      highlightedRanges: [],
      notes: [],
      currentTab: null,
      selectedNote: { comment: '' },
      selectedNoteIndex: -1,
      isOffcanvasOpen: false,
    };
  },
  props: {
    contentData: Object,
    isShowFloatingOptions: Boolean,
    isBookmarked: Boolean
  },
  methods: {

    ...mapActions("content", ["getContentById", "setUserContentAction", "getUserContentAction"]),
    setBookmark: function () {
      this.$emit("setContentBookmarked");
    },
    highlight: function () {
      if (this.quillEditor) {
        if (this.selectedRange) {
          var range = this.selectedRange;
          let overlappingRanges = [];
          for (let i = 0; i < this.highlightedRanges.length; i++) {
            const selectedRange = this.highlightedRanges[i];
            const endIndex = selectedRange.index + selectedRange.length - 1;

            if (range.index >= selectedRange.index && range.index <= endIndex) {
              overlappingRanges.push(i);
            }
          }
          if (overlappingRanges.length === 0) {

            var prependedRange = this.highlightedRanges.find(x => x.index + x.length == range.index);
            if (prependedRange) {
              var index = this.highlightedRanges.indexOf(prependedRange);
              this.highlightedRanges[index].length += range.length;
              this.quillEditor.formatText(this.highlightedRanges[index].index, this.highlightedRanges[index].length, 'highlights', true);
            } else {
              var selectedContent = this.quillEditor.getContents(range.index, range.length);
              var tempContainer = document.createElement('div')
              var tempQuill = new Quill(tempContainer);
              tempQuill.setContents(selectedContent);
              var text = tempContainer.querySelector('.ql-editor').innerHTML;
              this.quillEditor.formatText(range.index, range.length, 'highlights', true);
              range.id = generateGUID();
              range.text = text;
              this.highlightedRanges.push(range);
            }
          } else {
            overlappingRanges.forEach(overlapRange => {
              var overlapRangeOB = this.highlightedRanges[overlapRange];
              if ((range.index + range.length) <= (overlapRangeOB.index + overlapRangeOB.length)) {
                this.quillEditor.formatText(overlapRangeOB.index, overlapRangeOB.length, 'highlights', false);
                this.highlightedRanges.splice(overlapRange, 1);

                var firstLength = range.index - overlapRangeOB.index;
                if (firstLength > 0) {
                  var selectedContent = this.quillEditor.getContents(overlapRangeOB.index, firstLength);
                  var tempContainer = document.createElement('div');
                  var tempQuill = new Quill(tempContainer);
                  tempQuill.setContents(selectedContent);
                  var text = tempContainer.querySelector('.ql-editor').innerHTML;
                  this.quillEditor.formatText(overlapRangeOB.index, firstLength, 'highlights', true);
                  this.highlightedRanges.push({ index: overlapRangeOB.index, length: firstLength, id: generateGUID(), text });
                }

                var secondLength = (overlapRangeOB.index + overlapRangeOB.length) - (range.index + range.length);
                if (secondLength > 0) {
                  var selectedContent = this.quillEditor.getContents(range.index + range.length, secondLength);
                  var tempContainer = document.createElement('div')
                  var tempQuill = new Quill(tempContainer);
                  tempQuill.setContents(selectedContent);
                  var text = tempContainer.querySelector('.ql-editor').innerHTML;

                  this.highlightedRanges.push({ index: range.index + range.length, length: secondLength, id: generateGUID(), text });

                  this.quillEditor.formatText(range.index + range.length, secondLength, 'highlights', true);
                }

              }
              else {
                this.highlightedRanges[overlapRange].length = (range.index + range.length) - overlapRangeOB.index;
                this.quillEditor.formatText(this.highlightedRanges[overlapRange].index, this.highlightedRanges[overlapRange].length, 'highlights', true);
              }

            });
          }
          this.setHighlight();
        }
      }
    },
    setComment(index, comment) {
      if (index == null || index == undefined || index < 0) {
        this.selectedNote.comment = comment;
        this.isOffcanvasOpen = false;
        this.notes[this.notes.length - 1] = this.selectedNote;
        this.setNotes()
      }

      if (this.notes.length > 0 && (this.notes.length - 1) >= index) {
        this.notes[index].comment = comment;
        this.isOffcanvasOpen = false;
        this.setNotes()
      }
    },
    removeNote(index) {
      if (this.notes.length > 0 && index >= 0 && index < this.notes.length) {
        const note = this.notes[index];

        this.quillEditor.formatText(note.startIndex, note.endIndex, 'notes', false);
        this.notes.splice(index, 1);

        this.isOffcanvasOpen = false;
        this.selectedNote = { comment: '' };
        this.selectedNoteIndex = -1;
        this.setNotes()

      }
    },
    takeNotes: function () {
      if (this.quillEditor) {
        if (this.selectedRange) {
          var range = this.quillEditor.getSelection();
          if (range) {

            let note = {
              id: generateGUID(),
              comment: ''
            };

            this.quillEditor.formatText(range, 'notes', true);

            note.range = range;
            note.startIndex = range.index;
            note.endIndex = range.length;
            note.text = this.quillEditor.getText(note.startIndex, note.endIndex);

            let { condition, intersectingNote, intersectingIndex } = this.checkRangeIntersection(note);

            if (!condition) {
              this.isOffcanvasOpen = true;

              this.selectedNote = { comment: '', ...note };
              this.selectedNoteIndex = -1;
            }

            try {
              this.notes.push(note);
            } catch (e) {
              this.notes = [];
            }

          }
        }
      }

    },
    rangesIntersect: function (range1, range2) {
      return range1.index < range2.index + range2.length && range2.index < range1.index + range1.length;
    },
    checkRangeIntersection(newNote) {
      let intersectingNote = null;
      let intersectingIndex = -1;

      for (let i = 0; i < this.notes.length; i++) {
        const existingNote = this.notes[i];
        if (existingNote.range && this.rangesIntersect(newNote.range, existingNote.range)) {
          intersectingNote = existingNote;
          intersectingIndex = i;
          break;
        }
      }

      return {
        condition: intersectingNote !== null,
        intersectingNote: intersectingNote,
        intersectingIndex: intersectingIndex
      };
    },
    toggleOffcanvas() {
      this.isOffcanvasOpen = !this.isOffcanvasOpen;
    },
    setNotes() {
      if (this.contentData && this.contentData.id) {
        var contentAction = {
          ContentID: this.contentData.id,
          DataType: "notes",
          Data: JSON.stringify(this.notes)
        }

        this.setUserContentAction(contentAction).then((action) => {
        });
      }
    },
    getNotes: function () {
      this.getUserContentAction({ contentId: this.contentData.id, dataType: "notes" }).then((userActions) => {
        var userAction = userActions ? userActions.find(x => x.dataType == "notes") : null;
        if (userAction) {

          if (this.notes && this.notes.length > 0) {
            this.notes.forEach(note => {
              this.quillEditor.formatText(note.startIndex, note.endIndex, 'notes', false);
            });
          }

          if (userAction.data) {
            let parsedData = JSON.parse(userAction.data);
            this.notes = parsedData;
            this.notes.forEach(note => {
              this.quillEditor.formatText(note.startIndex, note.endIndex, 'notes', true);
            });
          }
        }
      });
    },
    setHighlight: function () {
      if (this.contentData && this.contentData.id) {
        var contentAction = {
          ContentID: this.contentData.id,
          DataType: "highlights",
          Data: JSON.stringify(this.highlightedRanges)
        }

        this.setUserContentAction(contentAction).then((action) => {

        });
      }

    },
    getHighlights: function () {
      this.getUserContentAction({ contentId: this.contentData.id, dataType: "highlights" }).then((userActions) => {
        var userAction = userActions ? userActions.find(x => x.dataType == "highlights") : null;
        if (userAction) {
          if (this.highlightedRanges && this.highlightedRanges.length > 0) {
            this.highlightedRanges.forEach(highlighted => {
              this.quillEditor.formatText(highlighted.index, highlighted.length, 'highlights', false);
            });
          }
          this.highlightedRanges = JSON.parse(userAction.data);
          this.highlightedRanges.forEach(highlighted => {

            this.quillEditor.formatText(highlighted.index, highlighted.length, 'highlights', true);
          });
        }
      });
    },
    handleNotesClick(event) {
      if (event.target.classList.contains('notes')) {
        const notesTarget = event.currentTarget;
        const text = notesTarget.innerText;
        const noteIndex = this.notes.findIndex(x => x.text.includes(text));

        if (noteIndex > -1) {
          this.selectedNoteIndex = noteIndex;
          this.selectedNote = this.notes[noteIndex];
        }
        
        if (!this.isOffcanvasOpen) {
          this.toggleOffcanvas()
        }
      }
    },
  },
  computed: {
  },
  created() {

  },
  mounted() {
    let Inline = Quill.import('blots/inline');

    class NotesBlot extends Inline { }
    NotesBlot.blotName = 'notes';
    NotesBlot.tagName = 'notes';
    NotesBlot.className = 'notes';
    Quill.register(NotesBlot);

    class HighlightBlot extends Inline { }
    HighlightBlot.blotName = 'highlights';
    HighlightBlot.tagName = 'highlights';
    HighlightBlot.className = 'highlights';
    Quill.register(HighlightBlot);

    var options = {
      theme: 'snow',
      pastePlain: true,
      readOnly: true,
      modules: {
        toolbar: false
      }
    };
    var editor = new Quill('#editor', options);
    var delta = editor.clipboard.convert(this.contentData.data);
    editor.setContents(delta);
    var self = this;
    editor.on('selection-change', function (range, oldRange, source) {
      if (range) {
        self.selectedRange = range;
      } else {
        self.selectedRange = null;
      }
    });
    this.quillEditor = editor;
    this.getHighlights();
    this.getNotes();

  },
  watch: {
    $route: function () {
      if (this.$route.query.tab && this.$route.query.tab != this.currentTab) {
        var oldTab = this.currentTab ?? "";
        var newTab = this.$route.query.tab;
        if (oldTab.toLowerCase() == "highlights" && newTab.toLowerCase() == "tableofcontents") {
          this.getHighlights();
          this.getNotes();
        }
        this.currentTab = newTab;
      }
    },
    notes: function () {
      const notesElements = document.querySelectorAll('.notes');
      notesElements.forEach(element => {
        element.addEventListener('click', this.handleNotesClick);
      });
    }
  },
};
function generateGUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );

}
</script>

<style scoped>
.sidebar-content {
  margin-top: var(--header-height);
}

.note-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 10px;
}

.fa-trash-alt {
  color: red;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}

.modal-heading {
  margin: 0;
}

.btn-confirm {
  padding: 4px 10px;
  margin-left: 7px;
  border-radius: 7px;
  background-color: var(--color-red);
  color: #fff;
  font-size: 14px;
}

.dialog-body {
  padding: 14px 20px;
  /* Updated padding */
}

.notes-textarea {
  width: 100%;
  min-height: 150px;
  resize: vertical;
  border-radius: 7px;
  font-size: 14px;
}

.character-counter {
  color: #888;
  font-size: 12px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding: 7px 14px;
  /* Updated padding */
}



/*.highlights */



.freeText-main-content {
  padding-right: 60px;
}

.ql-editor {
  padding: 0px;
}

.ql-container.ql-snow {
  border: 0px;
}

.ql-editor h2,
.ql-editor h3 {
    margin-bottom: 5px;
}

.ql-spanblock:after {
  content: "<spanblock/>";
}

.spanblock {
  background-color: #F8F8F8;
  border: 1px solid #CCC;
  line-height: 19px;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 15px 0;
}

.ql-container {
  font-family: "Open Sans";
  font-family: "Circular Std", sans-serif !important;
}

.floating-menu {
  top: 0;
}

@media (max-width:768px){
  .freeText-main-content {
    padding-right: 40px;
  }
  .ql-snow .ql-editor h1 {
      font-size: 1.85em;
      margin-bottom: 10px;
  }

}
</style>
<style>
.freeText-main-content .highlights {
  background: yellow !important;
}

.freeText-main-content .highlights {
  background: yellow !important;
}

.freeText-main-content .notes {
  border-bottom: 1.3px solid var(--primary-color);
  cursor: pointer;

}

/* .notes:after {
  content: "\f040";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  color: var(--primary-color);
  margin-left: 10px;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
} */

.freeText-main-content .highlights {
  background-color: yellow;
  color: black;
}

.freeText-main-content .notes:hover {
  background-color: var(--primary-color);
  color: white;
}

.freeText-main-content .notes:hover {
  background-color: var(--primary-color);
  color: white;
}

.freeText-main-content .notes:hover:after {
  color: white;
}



</style>
