class RecordedVideo {
	constructor(video) {
		// console.log("data in recordedvideo constructor", video);
		this.id = video.id;
		this.title = video.title;
		this.type = video.type;
		this.availableFrom = video.availableFrom;
		this.availableTo = video.availableTo;
		this.sequence = video.sequence;
		this.isContentAvailableForDemo = video.isContentAvailableForDemo;
		this.externalService = video.externalService;
		this.extraProperties = video.extraProperties
			? JSON.parse(video.extraProperties)
			: null;
		if (video.data == null) {
			this.data = null;
		} else {
			this.data = {
				url: video.data.url,
				viewUrl: video.data.viewUrl,
				status: video.data.status,
			};
		}
		this.parentDirectoryID = video.parentDirectoryID ?? null;
	}
}

export default RecordedVideo;
