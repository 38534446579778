<template>
	<div>
		<ProductContentTabs
			v-if="productVariantData"
			:productVariantData="productVariantData"
			:viewMode="viewMode"
			:purchased="purchased"
		/>
		<!-- <div class="accordion product-content-accordian" role="tablist">
			<ProductContentAccordian
				v-for="section in productVariantData.productVariantItems"
				:key="section.id"
				:section="section"
				:accordianActive="accordianActive"
				@accordianClick="accordianClick"
				:isFirstAccordian="
					section.id == productVariantData.productVariantItems[0].id
				"
			/>
		</div> -->
		<!-- <div class="accordion sidebar-content-accordian" role="tablist">
			<b-card
				no-body
				v-for="directory in productVariantData.productVariantItems"
				:key="directory.id"
				:id="directory.id"
			>
				<b-card-header
					header-tag="header"
					role="tab"
					v-b-toggle="'accordian-' + directory.id"
				>
					{{ directory.title }}
					<i class="fas fa-angle-down input-icon-right mt-0"></i>
				</b-card-header>
				<b-collapse
					:id="'accordian-' + directory.id"
					visible
					accordion="my-accordion"
					role="tabpanel"
				>
					<b-card-body>
						<ul class="content-item-list" v-if="!loading">
							<li
								v-for="item in content.children"
								:key="item.id"
								:id="item.id"
								:class="{ active: contentActive == item.id }"
							>
								<i
									v-if="
										item.type.toLowerCase() == RECORDED_VIDEO ||
											item.type.toLowerCase() == LIVE_SESSION
									"
									class="fab fa-youtube content-item-icon"
								></i>
								<i
									v-if="item.type.toLowerCase() == ASSIGNMENT"
									class="far fa-file content-item-icon"
								></i>
								{{ item.title }}
							</li>
						</ul>
					</b-card-body>
				</b-collapse>
			</b-card>
		</div> -->
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductContentAccordianList from "./ProductContentAccordianList.vue";
import ProductContentTabs from "./ProductContentTabs.vue";
export default {
	name: "ProductDetailsSidebar",
	props: { productVariantData: Object },
	data() {
		return {
			viewMode: "sidebar",
			purchased: false,
		};
	},
	components: { ProductContentAccordianList, ProductContentTabs },
	methods: {
		...mapActions("content", ["getContentById"]),
	},
	computed: {
		...mapState("content", ["loading", "content"]),
		// ...mapState("content", []),
		...mapState("user", ["user"]),
	},
	created() {
		if (this.user) {
			this.purchased = true;
		} else this.purchased = false;
	},
};
</script>

<style scoped></style>
