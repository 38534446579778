<template>
  <div class="search-container">
    <!-- {{ productVariants.length > 0 ? productVariants : "hello world" }} -->
    <div class="search-options-top gap-4">
      <a
        type="button"
        class="border-btn btn-md filter-button"
        @click="toggleAdvancedFilters"
      >
        Filters
        <i class="far fa-sort-amount-down btn-icon"></i>
      </a>
      <a
        v-b-toggle="'advanced-filters'"
        type="button"
        class="border-btn btn-md filter-button-mobile"
      >
        Show Filters
        <i class="far fa-sort-amount-down btn-icon"></i>
      </a>
      <div class="searchbar">
        <input
          type="search"
          autocomplete="off"
          id="variant-search"
          placeholder="Search"
          class="form-control"
          v-model="searchText"
        />
        <a role="button" @click="search()">
          <img class="search-icon" src="@/assets/images/search-icon.svg"
        /></a>
      </div>
      <div class="dropdown hide-mobile">
        <!-- <button
					class="dropdown-toggle border-input setButtonStyle m-0 text-left"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<p class="pContent">Sort By:</p>
					All Categories
				</button> -->
        <!-- <ul
					style="padding-top: 0px"
					class="dropdown-menu ulContent"
					aria-labelledby="dropdownMenuButton1"
				>
					<li class="liStyling setLiStyle">
						<a class="dropdown-item" href="#" style="margin-right: 10rem"
							>A <i class="fas fa-arrow-right"></i> Z</a
						>
					</li>
					<li class="liStyling setLiStyle">
						<a class="dropdown-item" href="#" style="margin-right: 10rem"
							>Highest Rating</a
						>
					</li>
					<li class="liStyling setLiStyle">
						<a class="dropdown-item" href="#" style="margin-right: 10rem"
							>Most Popular</a
						>
					</li>
					<li class="liStyling setLiStyle">
						<a class="dropdown-item" href="#" style="margin-right: 10rem"
							>Newest</a
						>
					</li>
				</ul> -->
      </div>
    </div>
    <div class="d-flex flex-row gap-4 align-items-start">
      <transition name="slide-fade">
        <div class="advanced-search-container" v-if="showAdvancedFilters">
          <!-- <h3>Advance Search</h3> -->
          <ProductAdvancedFilters :filters="filters" />
        </div>
      </transition>
      <b-sidebar
        id="advanced-filters"
        title="Advance Search"
        class="filters-sidebar"
        shadow
      >
        <div class="px-3 py-2">
          <ProductAdvancedFilters :filters="filters" />
        </div>
      </b-sidebar>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductAdvancedFilters from "./ProductAdvancedFilters.vue";

export default {
  name: "ProductFilters",
  props: {
    filters: Object
  },
  data() {
    return {
      showAdvancedFilters: true,
      searchText: "",
    };
  },
  methods: {
    ...mapActions("category", ["fetchCategories"]),
    ...mapActions("productVariant", ["fetchProductVariants"]),

    toggleAdvancedFilters() {
      this.showAdvancedFilters = !this.showAdvancedFilters;
    },
    search() {
      this.$router.push({path: 'search', query: { title: this.searchText, categoryId: this.filters?.categoryId, productId: this.filters?.productId }});
    },
    filterProducts() {

      this.fetchProductVariants(this.filters).then(() => {
        console.log(Object.values(this.productVariants.items));
      });
    }
  },

  computed: {
    ...mapState("productVariant", ["productVariants"]),
  },
  created() {

    if(this.filters && this.filters.productTitle) {
      this.searchText = this.filters.productTitle;
    }

    this.filterProducts();

    //console.log({ PVI: this.productVariants });
    // this.fetchCategories().then(() => {
    //   this.categoryList = this.categories;
    // });
    // this.fetchAllProducts().then(() => {
    //   console.log("products fetched");
    //   this.courseList = Object.values(this.products.items);
    // });
  },
  watch: {
    filters: function() {
      this.searchText = '';
      if(this.filters && this.filters.productTitle) {
        this.searchText = this.filters.productTitle;
      }

      this.filterProducts();
    }
  },
  components: { ProductAdvancedFilters },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
  .filters-sidebar {
    display: block !important;
  }

  .filter-button-mobile {
    display: inline-flex !important;
    border-radius: 6px;
    padding: 0.675rem 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    text-decoration: none;
    border: 1px solid #a7a9ac;
  }

  .filter-button {
    display: none !important;
  }

  .advanced-search-container {
    display: none !important;
  }

  .border-input,
  .border-btn {
    min-width: 150px !important;
    font-size: 0.85rem;
  }

  .search-options-top {
    padding: 1rem 0 !important;
  }
}

.search-container {
  display: flex;
  flex-direction: column;
}

.search-options-top {
  display: flex;
  flex: 100%;
  padding: 15px 0;
  /* border-bottom: 1px solid var(--color-gray); */
}

.advanced-search-container {
  display: flex;
  width: 20%;
  padding: 1rem 0;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  flex-shrink: 0;
}

.advanced-search-container h3 {
  font-size: 1.5rem !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-20rem);
}

.filter-accordian-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--color-white) !important;
  border-bottom: none !important;
  padding: 15px 5px !important;
  font-weight: bold;
  font-size: 1.1rem;
}

.filter-accordian-card {
  border: none !important;
  border-bottom: 1px solid var(--color-gray) !important;
}

.filter-accordian-card .card-body {
  padding: 0 0.3rem 0.5rem;
}

.filter-accordian-card .card-body .form-control {
  border: none;
}

.b-rating-icon {
  font-size: 1.5rem;
}

.filters-sidebar {
  display: none;
}

.filter-button-mobile {
  display: none;
}

.b-sidebar {
  background-color: var(--color-white) !important;
}

/* #advanced-filters input[type="radio"],
input[type="checkbox"] {
	margin: 0 !important;
} */

.filter-button {
  border-radius: 6px;
  padding: 0.675rem 1rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  text-decoration: none;
  border: 1px solid #a7a9ac;
}

.filter-button:hover {
  text-decoration: none;
}

.searchbar {
  position: relative;
  width: 60%;
}

.searchbar input {
  margin: 0;
  line-height: 1.5;
  border: 1px solid #a7a9ac;
  border-radius: 6px;
  outline: none;
  padding: 0.675rem 1rem;
  width: 100%;
  box-shadow: 0 3px 6px #dbdbdb29;
}

.searchbar .search-icon {
  position: absolute;
  margin-right: 0.75rem;
  right: 0;
  width: 1.375rem;
  top: 50%;
  transform: translateY(-50%);
}

/* #search[data-v-8e2be454] {
    line-height: 1.5;
    border: 1px solid #a3a3a3;
    border-radius: 8px;
    background-color: #fafafa;
    outline: none;
    padding: 1.125rem 1.5rem;
    width: 100%;
    box-shadow: 0 3px 6px #dbdbdb29;
} */
</style>
