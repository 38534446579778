<template>
	<b-modal
		id="payment-detail-modal"
		@change="$emit('updateModalState')"
		:visible="visible"
		hide-header
		centered
		content-class="payment-detail-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<div class="d-flex justify-content-between align-items-end">
				<div>
					<h1 v-if="payment" class="add-enroll-title">
						Payment # {{ payment.id }} ({{ payment.details[0].type }})
					</h1>
					<div v-if="payment" class="d-flex align-items-center gap-3 mb-3">
						<span>{{ user.firstName }} {{ user.lastName }}</span>
					</div>
					<button
						v-if="payment && payment.status == PaymentStatus.PENDING"
						role="button"
						class="add-btn"
						@click="openPreview"
					>
						<i class="fas fa-image add-btn-icon"></i> View Evidence
					</button>
					<vue-easy-lightbox
						escDisabled
						moveDisabled
						:visible="showPreview"
						:imgs="previewItem"
						@hide="handleHide"
					></vue-easy-lightbox>
				</div>
				<div class="d-flex gap-3">
					<!-- <button
						data-bs-toggle="dropdown"
						v-if="payment && payment.status == PaymentStatus.PENDING"
						class="add-btn dropdown-toggle"
						@click="toggleDropdown(true)"
						:disabled="mode != 'view'"
					>
						<i class="far fa-usd-circle add-btn-icon"></i>
						Mark as Paid
					</button>
					<div
						id="add-dropdown"
						class="dropdown-menu dropdown-menu-end"
						aria-labelledby="dropdownMenuLink"
					>
						<form @submit="markCompleted()">
							<input
								type="search"
								class="mark-paid-input"
								v-model="paymentAmount"
								placeholder="Enter payment amount (required)"
								id="mark-paid-input"
								required
								v-on:keyup.esc="toggleDropdown(false)"
							/>
							<button
								@click="markCompleted()"
								type="submit"
								class="content-action-btn save"
							>
								<img src="@/assets/images/check-icon-green.svg" alt="save" />
							</button>
							<button
								@click="toggleDropdown(false)"
								class="content-action-btn cancel"
								type="button"
								role="button"
							>
								<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
							</button>
						</form>
					</div> -->
					<!-- <button
						class="add-btn"
						@click="changeMode('edit')"
						:disabled="mode != 'view'"
					>
						<img class="add-btn-icon" src="@/assets/images/edit-icon.svg" />
						Edit payment
					</button>
					<button
						class="add-btn"
						@click="showDeleteConfirmPopup($event, payment)"
						:disabled="mode != 'view'"
					>
						<img class="add-btn-icon" src="@/assets/images/delete-icon.svg" />
						Delete payment
					</button> -->
				</div>
			</div>
			<div class="inline-group mt-5">
				<div class="form-group col">
					<label for="paymentDate" class="main-label">Payment Date</label>
					<input
						type="date"
						class="form-input"
						name="paymentDate"
						id="paymentDate"
						v-model="paymentDate"
						:readonly="mode != 'edit'"
						@change="isChanged = true"
					/>
				</div>
				<div class="form-group col">
					<label for="paymentMethod" class="main-label">Payment Method</label>
					<v-select
						class="form-input"
						placeholder="Select Payment Method"
						v-model="paymentMethod"
						name="paymentMethod"
						id="paymentMethod"
						:options="externalServicesList['PaymentGateway']"
						label="serviceName"
						:autoscroll="true"
						@input="isChanged = true"
						:disabled="mode != 'edit'"
					>
					</v-select>
				</div>
				<div class="form-group col">
					<label for="paymentStatus" class="main-label">Payment Status</label>
					<v-select
						class="form-input"
						placeholder="Select Payment Status"
						v-model="paymentStatus"
						name="paymentStatus"
						id="paymentStatus"
						:options="status"
						label="label"
						:autoscroll="true"
						@input="isChanged = true"
						:disabled="mode != 'edit'"
						:reduce="(option) => option.value"
					>
					</v-select>
				</div>
				<!-- </div> -->
			</div>
			<label for="payment-details" class="main-label"> Payment details </label>
			<table class="table table-borderless enrolled-users-table">
				<thead>
					<tr class="d-flex">
						<th v-if="mode == 'pay'" class="col-1 shrink">
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll()"
							/>
						</th>
						<th class="col-1">ID</th>
						<th class="col">Payment / Refund for</th>
						<th class="col">Plan</th>
						<th class="col">Payment Amount</th>
						<th v-if="mode != 'pay'" class="col">Type</th>
					</tr>
				</thead>
				<tbody v-if="payment && payment.details.length > 0">
					<PaymentDetailItem
						v-for="detail in payment.details"
						:key="detail.id"
						:paymentDetail="detail"
						:currency="currentCurrency"
						@updateError="error"
						:selectedDetails="selectedDetails"
						@toggleSelect="togglePaymentSelect"
						:mode="mode"
						@updateAmount="paymentUpdate"
						@updateType="updateType"
					/>
				</tbody>
				<div v-else class="no-content">No Payments found</div>
			</table>
			<p v-if="isError" class="text-danger">
				<i class="fas fa-exclamation-circle"></i> {{ errorText }}
			</p>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					cancel
				</button>
				<button
					@click="handleSave"
					:disabled="disableSave()"
					role="button"
					class="step-btn"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ mode == "pay" ? "Accept Payment" : "save" }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaymentDetailItem from "./PaymentDetailItem.vue";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import VueEasyLightbox from "vue-easy-lightbox";
import { PaymentStatus } from "../../../constants/pricing";

export default {
	name: "UserPaymentDetailModal",
	props: {
		user: Object,
		payment: Object,
		// mode: String,
		showModal: Boolean,
	},
	components: {
		PaymentDetailItem,
		VueEasyLightbox,
	},
	data() {
		return {
			selectedDetails: [],
			mode: "view",
			isError: false,
			method: null,
			paymentMethod: null,
			paymentDate: null,
			paymentStatus: null,
			status: [
				{ label: "Completed", value: "Completed" },
				{ label: "Pending", value: "Pending" },
			],
			errorText: "",
			metadata: null,
			showPreview: false,
			previewItem: null,
			showMarkAsPaidDropdown: false,
			paymentAmount: null,
			PaymentStatus,
		};
	},
	computed: {
		...mapState("appState", ["externalServicesList", "currentCurrency"]),
		...mapState("pricing", ["paymentsByUserId", "loading"]),
		visible() {
			return this.showModal;
		},
		totalAmount() {
			var total = 0;
			this.payment.details.forEach((detail) => (total += detail.amount));
			return total;
		},
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
		...mapActions("pricing", [
			"fetchPaymentsByUserId",
			"deletePayment",
			"updatePayment",
			"fetchInvoiceByUserId",
		]),
		closeModal() {
			this.$emit("updateModalState");
			Object.assign(this.$data, this.$options.data.call(this));
		},
		isDisabled(id) {
			var found = this.selectedDetails.find((d) => d.id == id);
			return !found;
		},
		disableSave() {
			return (
				this.isError ||
				this.loading ||
				(this.mode == "pay" && !this.selectedDetails.length)
			);
		},
		error(error, text) {
			this.isError = error;
			if (text) this.errorText = text;
		},
		changeMode(mode) {
			this.mode = mode;
		},
		editPayment() {
			var paymentObj = {
				...this.payment,
				paymentDate: new Date(this.paymentDate).toISOString(),
				method: this.paymentMethod.serviceName,
				methodProvider: this.paymentMethod.serviceProvider,
				methodProviderID: this.paymentMethod.serviceCode,
				status: this.paymentStatus,
				details: this.selectedDetails,
				userID: this.payment.customerID || this.payment.userID,
			};

			this.updatePayment(paymentObj).then(() => {
				console.log("payment updated");
				this.fetchInvoiceByUserId(this.user.id).then(() => {
					this.closeModal();

					this.$toasted.success("Payment Updated", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			});
		},
		showDeleteConfirmPopup(e, payment) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete payment ID: ${payment.id} for ${this.user.firstName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.paymentDelete(payment);
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		paymentDelete(payment) {
			this.deletePayment(payment).then((response) => {
				this.fetchInvoiceByUserId(this.user.id).then(() => {
					this.closeModal();
					// this.$toasted.success("Payment Deleted", {
					// 	theme: "outline",
					// 	position: "bottom-center",
					// 	duration: 3000,
					// });
				});
			});
		},
		togglePaymentSelect(payment) {
			// this.$emit("toggleSelect", payment);
			var found = this.selectedDetails.find((s) => s.id == payment.id);
			// console.log(found, payment);
			if (!found) {
				this.selectedDetails.push(payment);
			} else {
				this.selectedDetails = this.selectedDetails.filter(
					(su) => su.id != payment.id
				);
			}
		},
		paymentUpdate(amount, id) {
			var updatedDetails = this.selectedDetails.map((detail) => {
				if (detail.id == id) {
					detail.amount = parseFloat(amount);
				}
				return detail;
			});
			this.selectedDetails = updatedDetails;
			this.isChanged = true;
		},
		updateType(type, id) {
			var updatedDetails = this.selectedDetails.map((detail) => {
				if (detail.id == id) {
					detail.type = type;
				}
				return detail;
			});
			this.selectedDetails = updatedDetails;
			this.isChanged = true;
		},
		openPreview() {
			// if (this.type.toLowerCase() == "image") {
			this.previewItem = this.metadata.evidence;
			this.showPreview = true;
			// }
		},
		handleHide() {
			this.showPreview = false;
		},
		markCompleted() {
			var paymentObj = {
				...this.payment,
				paymentDate: new Date(this.paymentDate).toISOString(),
				method: this.paymentMethod.serviceName,
				methodProvider: this.paymentMethod.serviceProvider,
				methodProviderID: this.paymentMethod.serviceCode,
				status: "Completed",
				details: this.selectedDetails,
				userID: this.payment.customerID || this.payment.userID,
			};

			var remainingLineItems = this.payment.details.filter((detail) => {
				var found = paymentObj.details.find((d) => d.id == detail.id);
				if (!found) return detail;
			});

			remainingLineItems = remainingLineItems.map((item) => {
				item.amount = 0;
				return item;
			});
			console.log(remainingLineItems);
			paymentObj.details = [...paymentObj.details, ...remainingLineItems];

			this.updatePayment(paymentObj).then(() => {
				console.log("payment updated");
				this.fetchInvoiceByUserId(this.user.id).then(() => {
					this.closeModal();

					this.$toasted.success("Payment Updated", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			});
		},
		toggleDropdown() {
			this.showMarkAsPaidDropdown = value;
		},
		toggleSelectAll() {
			if (
				this.invoice.details &&
				this.invoice.details.length > 0 &&
				this.selectedDetails.length != this.invoice.details.length
			) {
				this.selectedDetails = this.invoice.details;
			} else {
				this.selectedDetails = [];
			}
		},
		isSelectedAll() {
			if (this.invoice && this.invoice.details) {
				return this.invoice.details.length == this.selectedDetails.length;
			}
		},
		handleSave() {
			this.mode != "pay" ? this.editPayment() : this.markCompleted();
		},
	},
	created() {
		// this.mode = this.mode;
		if (!this.paymentsByUserId[this.user.id]) {
			this.fetchPaymentsByUserId(this.user.id).then(() => {
				console.log("fetch payments by user");
			});
		}
	},
	watch: {
		mode: function () {
			if (this.mode == "pay") {
				console.log("mode changed");
			}
		},
		visible: function () {
			// console.log("payment visible", this.visible);
			if (this.visible) {
				if (this.payment.status == PaymentStatus.PENDING) {
					this.mode = "pay";
				}
				if (!this.paymentsByUserId[this.user.id]) {
					this.fetchPaymentsByUserId(this.user.id).then(() => {
						console.log("fetch payments by user");
					});
				}
				if (this.externalServicesList["PaymentGateway"])
					this.paymentMethod = this.externalServicesList["PaymentGateway"].find(
						(gateway) =>
							gateway.serviceCode.toLowerCase() ==
							this.payment.methodProviderID.toLowerCase()
					);
				else {
					this.fetchExternalServices("PaymentGateway").then(() => {
						this.paymentMethod = this.externalServicesList[
							"PaymentGateway"
						].find(
							(gateway) =>
								gateway.serviceCode.toLowerCase() ==
								this.payment.methodProviderID.toLowerCase()
						);
					});
				}
				this.paymentDate = getInputDateFormat(this.payment.paymentDate);
				this.paymentStatus = this.payment.status;
				this.metadata = this.payment.metadata
					? JSON.parse(this.payment.metadata)
					: null;
				this.paymentAmount = this.totalAmount;
			}
		},
	},
};
</script>

<style>
.status-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
	height: fit-content;
}

.status-tag.success {
	background-color: #00c672;
}

.status-tag.danger {
	background-color: var(--color-danger);
}

.status-tag.warning {
	background-color: var(--color-warning);
}

.payment-detail-modal input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.enrolled-users-table td {
	min-height: 4rem;
	vertical-align: middle;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.add-btn::after {
	display: none !important;
}

.cursor-pointer {
	cursor: pointer;
}

.view-evidence-btn {
	color: var(--primary-color);
	font-size: 1.125rem;
	text-decoration: none;
	font-weight: 500;
}

.mark-paid-input {
	height: 3.125rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.875rem 1rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
	padding-right: 5.25rem;
}

#add-dropdown {
	padding: 1rem;
	border-radius: 8px;
	width: 22.625rem;
}

#add-dropdown form {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.content-action-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 50%;
	width: 1.625rem;
	height: 1.625rem;
	/* padding: 0.375rem; */
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0.875rem;
}

.content-action-btn.save {
	border: 1px solid #21cf47;
	color: #21cf47;
	position: absolute;
	right: 3rem;
}
.content-action-btn.cancel {
	border: 1px solid #ff0000;
	color: #ff0000;
	position: absolute;
	right: 0.75rem;
}
</style>
