<template>
	<div>
		<h1 class="add-enroll-title">Generating Invoices</h1>

		<table class="table table-hover table-borderless enrolled-users-table mt-5">
			<thead>
				<tr class="d-flex">
					<th class="col-1">ID</th>
					<th class="col">Name</th>
					<th class="col">Plan</th>
					<th class="col">Invoice</th>
					<th v-if="selectedPlan.payments" class="col">Payments</th>
				</tr>
			</thead>
			<tbody>
				<tr
					class="d-flex"
					v-for="student in selectedStudents"
					:key="student.id"
				>
					<td class="col-1">{{ student.id }}</td>
					<td class="col">{{ student.firstName }} {{ student.lastName }}</td>
					<td class="col">{{ selectedPlan.item.title }}</td>
					<td class="col">
						<!-- <div v-if="!loading"> -->
						<div
							v-if="!loading && isInvoiceGenerated(student.id)"
							class="status-tag success"
						>
							Generated
						</div>
						<div
							v-else-if="
								(!loading && isFailed) ||
								(!loading && !isInvoiceGenerated(student.id))
							"
							class="status-tag danger"
						>
							Failed
						</div>
						<div v-if="loading" class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<!-- </div> -->
					</td>
					<td class="col" v-if="selectedPlan.payments">
						<!-- <div v-if="!loading && !isFailed -->
						<div v-if="!loading && !isFailed" class="status-tag success">
							Paid
						</div>
						<div
							v-else-if="
								(!loading && isFailed) ||
								(!loading && !isInvoiceGenerated(student.id))
							"
							class="status-tag danger"
						>
							Failed
						</div>
						<div v-else class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						<!-- </div> -->
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import formatter from "../../../helpers/FormatHelpers";
import { mapActions, mapState } from "vuex";
export default {
	name: "ValidateAndGenerateInvoices",
	data() {
		return {
			loading: false,
			generatedInvoices: [],
			paidInvoices: [],
			isFailed: false,
		};
	},
	methods: {
		...mapActions("pricing", [
			"createInvoice",
			"createPayment",
			"fetchUsersByProductVariant",
			"fetchInvoiceByUserId",
			"createBulkInvoice",
		]),
		...mapActions("user", ["fetchUsersByProductVariant", "fetchUsersList"]),
		paymentButtonText(id) {
			if (this.multiplePlanPayments[id]) {
				var amount =
					parseInt(this.multiplePlanPayments[id].received) +
					parseInt(this.multiplePlanPayments[id].discount);
				// console.log(amount);

				return formatter.toCurrencyString(
					amount,
					this.selectedPlan.currency,
					0
				);
			} else {
				return "+ Add";
			}
		},
		currencyParser(amount) {
			return formatter.toCurrencyString(
				parseInt(amount),
				this.selectedPlan.currency,
				0
			);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		generateInvoiceObject(id) {
			console.log("generateInvoice", id);
			if (this.selectedPlan.item) {
				var invoice;
				if (this.selectedPlan.item.type.toLowerCase() == "onetime") {
					invoice = {
						customerID: id,
						invoiceDate: new Date().toISOString(),
						currency: this.selectedPlan.item.currency,
						status: "unpaid",
						details: [
							{
								planDetailID: this.selectedPlan.item.details[0].id,
								amount: this.selectedPlan.item.details[0].amount,
								// status: "unpaid",
							},
						],
					};
					var dueDate = new Date(invoice.invoiceDate).setDate(
						new Date(invoice.invoiceDate).getDate() +
							this.selectedPlan.item.details[0].gracePeriod
					);
					dueDate = new Date(dueDate).setHours(23, 59, 59);

					console.log(dueDate);
					invoice.details[0].dueDate = new Date(dueDate).toISOString();
					var isDiscount =
						this.selectedPlan.payments &&
						this.selectedPlan.payments[this.selectedPlan.item.details[0].id] &&
						this.selectedPlan.payments[this.selectedPlan.item.details[0].id]
							.discount;
					console.log(isDiscount);
					if (isDiscount) {
						invoice.details[0].discount = parseFloat(isDiscount);
						invoice.details[0].discountType = "amount";
					} else {
						invoice.details[0].discount = 0.0;
						invoice.details[0].discountType = "amount";
					}
					if (this.selectedPlan.payments) {
						invoice.details[0].payment = {
							customerID: id,
							paymentDate: new Date().toISOString(),
							method: this.selectedPlan.payments.paymentMethod.serviceName,
							methodProvider:
								this.selectedPlan.payments.paymentMethod.serviceProvider,
							methodProviderID:
								this.selectedPlan.payments.paymentMethod.serviceCode,
							details: [
								{
									amount: parseFloat(
										this.selectedPlan.payments[
											this.selectedPlan.item.details[0].id
										].received
									),
									type: "Received",
								},
							],
							status: "Completed",
						};
						// invoice.details[0].payment = payment;
					}
				} else {
					invoice = {
						customerID: id,
						invoiceDate: new Date().toISOString(),
						currency: this.selectedPlan.item.currency,
						status: "unpaid",
						details: [],
					};
					this.selectedPlan.planCycles.forEach((cycle) => {
						var invoiceDetail = {
							planDetailID: cycle.id,
							amount: cycle.amount,
							// status: "unpaid",
						};
						var dueDate = new Date(invoice.invoiceDate).setDate(
							new Date(invoice.invoiceDate).getDate() + cycle.gracePeriod
						);
						dueDate = new Date(dueDate).setHours(23, 59, 59);

						console.log(dueDate);
						invoiceDetail.dueDate = new Date(dueDate).toISOString();
						var isDiscount =
							this.selectedPlan.payments &&
							this.selectedPlan.payments[cycle.id] &&
							this.selectedPlan.payments[cycle.id].discount;
						if (isDiscount) {
							invoiceDetail.discount = parseFloat(isDiscount);
							invoiceDetail.discountType = "amount";
						} else {
							invoiceDetail.discount = 0.0;
							invoiceDetail.discountType = "amount";
						}
						if (cycle.isPaid) {
							invoiceDetail.payment = {
								customerID: id,
								paymentDate: new Date().toISOString(),
								method: this.selectedPlan.payments.paymentMethod.serviceName,
								methodProvider:
									this.selectedPlan.payments.paymentMethod.serviceProvider,
								methodProviderID:
									this.selectedPlan.payments.paymentMethod.serviceCode,
								details: [
									{
										amount: parseFloat(
											this.selectedPlan.payments[cycle.id].received
										),
										type: "Received",
									},
								],
								status: "Completed",
							};
						}
						invoice.details.push(invoiceDetail);
					});
				}
				// this.this.getPaymentObject(id, invoice);
				// console.log(invoice);
				// const generatedInvoices = await this.createInvoice(invoice).then(
				// 	(inv) => {
				// 		// console.log(this.invoicesByUserId);
				// 		// console.log("invoice generated", inv);
				// 		this.generatedInvoices.push(inv);
				// 		return inv;
				// 	}
				// );
				return invoice;
			}
		},
		// getPaymentObject(id, invoice) {
		// 	console.log("generateInvoicePayment", id);
		// 	if (this.selectedPlan.item && this.selectedPlan.payments) {
		// 		var payment;
		// 		if (this.selectedPlan.item.type.toLowerCase() == "onetime") {
		// 			payment = {
		// 				userID: id,
		// 				paymentDate: new Date().toISOString(),
		// 				method: this.selectedPlan.payments.paymentMethod.serviceName,
		// 				methodProvider: this.selectedPlan.payments.paymentMethod
		// 					.serviceProvider,
		// 				methodProviderID: this.selectedPlan.payments.paymentMethod
		// 					.serviceCode,
		// 				details: [
		// 					{
		// 						invoiceDetailID: invoice.details[0].id.toString(),
		// 						amount: parseFloat(
		// 							this.selectedPlan.payments[
		// 								this.selectedPlan.item.details[0].id
		// 							].received
		// 						),
		// 						type: "received",
		// 					},
		// 				],
		// 			};
		// 		} else {
		// 			payment = {
		// 				userID: id,
		// 				paymentDate: new Date().toISOString(),
		// 				method: this.selectedPlan.payments.paymentMethod.serviceName,
		// 				methodProvider: this.selectedPlan.payments.paymentMethod
		// 					.serviceProvider,
		// 				methodProviderID: this.selectedPlan.payments.paymentMethod
		// 					.serviceCode,
		// 				details: [],
		// 			};
		// 			this.selectedPlan.planCycles.forEach((cycle, index) => {
		// 				if (cycle.isPaid) {
		// 					var paymentDetail = {
		// 						invoiceDetailID: invoice.details[index].id.toString(),
		// 						amount: parseFloat(
		// 							this.selectedPlan.payments[cycle.id].received
		// 						),
		// 						type: "received",
		// 					};
		// 					payment.details.push(paymentDetail);
		// 				}
		// 			});
		// 		}
		// 		console.log(payment);
		// 		// await this.createPayment(payment).then((pay) => {
		// 		// 	console.log(this.paymentsByCustomerId);
		// 		// 	this.paidInvoices.push(pay);
		// 		// 	this.fetchInvoiceByUserId(id);
		// 		// });
		// 		return payment;
		// 	}
		// },
		isInvoiceGenerated(id) {
			var found =
				this.generatedInvoices &&
				this.generatedInvoices.find(
					(inv) => inv.customerID == id && inv.id != 0
				);
			return found ? true : false;
		},
		isPaymentComplete(id) {
			return this.paidInvoices && this.paidInvoices.length > 0 ? true : false;
		},
	},
	props: {
		selectedPlan: Object,
		productVariantId: String,
		selectedStudents: Array,
	},
	created() {
		this.loading = true;
		// setTimeout(() => {
		var invoicesList = [];
		for (var i = 0; i < this.selectedStudents.length; i++) {
			var invoiceObj = this.generateInvoiceObject(this.selectedStudents[i].id);
			invoicesList.push(invoiceObj);

			// console.log("new invoice", inv);
			// if (this.selectedPlan.payments)
			// this.getPaymentObject(inv.customerID, inv).then((payment) => {});
			// this.fetchUsersByProductVariant(this.productVariantId);
			// this.fetchUsersList();
			// this.getPaymentObject(this.selectedStudents[i].id);
		}
		this.createBulkInvoice(invoicesList).then((res) => {
			if (res && res.length) {
				console.log("bulk invoices created");
				this.loading = false;
				this.generatedInvoices = res;
				setTimeout(() => {
					this.fetchUsersByProductVariant(this.productVariantId);
					this.fetchUsersList();
				}, 3000);
			} else {
				this.loading = false;
				this.isFailed = true;
			}
		});

		// }, 2000);
	},
	computed: {
		...mapState("pricing", ["invoicesByUserId", "paymentsByUserId"]),
	},
	watch: {
		loading: function () {
			this.$emit("setLoading", { status: this.loading });
		},
	},
};
</script>

<style scoped>
.spinner-border {
	border-color: var(--primary-color);
	border-right-color: transparent;
}

.enrolled-users-table thead th,
.enrolled-users-table td {
	min-height: 4rem;
	vertical-align: middle;
}

.enrolled-users-table input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.enrolled-users-table tbody {
	display: block;
	max-height: 14rem !important;
	overflow-y: auto;
}

.enrolled-users-table thead tr,
.enrolled-users-table tbody tr {
	display: table !important;
	width: 100%;
	table-layout: fixed;
}

.status-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
}

.status-tag.success {
	background-color: #00c672;
	/* border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1; */
}
</style>
