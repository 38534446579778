<template>
    <div>
      <div>
        <h5 v-for="(option, index) in options" :key="index" :class="'checkbox-inputs '+(isReviewMode && isCorrect(option)?' correct':(isReviewMode && isInCorrect(option)?' incorrect':''))" >
          <b-form-checkbox :id="'checkbox-option-' + option.identifier" name="checkbox-option" 
            :disabled="isReviewMode" v-model="selectedAnswer" 
            :value="option.identifier" class="quiz-checkbox"> 
            <span :for="'checkbox-option-' + option.identifier" class="input-option" v-html="option.option"></span> 
          </b-form-checkbox>
       </h5>


        <!-- <h5 v-for="(option, index) in options" :key="index" :class="'checkbox-inputs '+(isReviewMode && isCorrect(option)?' correct':(isReviewMode && isInCorrect(option)?' incorrect':''))" >
          
          <b-form-checkbox id="checkbox-option" name="checkbox-option" 
          :disabled="isReviewMode" v-model="selectedAnswer" 
          :value="option.identifier" class="quiz-checkbox"> 
          <span class="input-option" v-html="option.option"></span> 
          </b-form-checkbox>
        </h5> -->
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'CheckboxButtonQuestion',
      data() {
        return {
          selectedAnswer:[]
        }
      },
      props:{
        isReviewMode:Boolean,
        options:Array,
        answers:Array,
        correctAnswers:Array
      },
      computed: {
        // filteredOptions: {
        //     get() {
        //         return this.options && this.options.length > 0 ? this.options : [];
        //     },
        //     set(value) {
        //         this.options = value;
        //     },
        // },
      },
      mounted() {},
      methods: {
        isCorrect(option)
        {
          if(this.isReviewMode && this.correctAnswers)
          {
            return this.correctAnswers.indexOf(option.identifier)>-1;
          }
          return false;
        },
        isInCorrect(option){
          if(this.isReviewMode && !this.isCorrect(option.identifier) && this.answers)
          {
            return this.answers.indexOf(option.identifier)>-1;
          }
          return false;
        }
      },
      created(){
        // if(this.filteredOptions && this.filteredOptions.length > 0) {
        //     var _options = [];

        //     for(let i=0; i<this.filteredOptions.length; i++) {
        //         _options.push({value: this.filteredOptions[i], text: this.filteredOptions[i]});
        //     }

        //     this.filteredOptions = [];
        //     this.filteredOptions = _options;
            
        // }
        if(this.answers && this.answers.length>0)
          {
            this.selectedAnswer= this.answers;
          }
      },
      watch:{
        selectedAnswer:function(){
          this.$emit("setAnswer", this.selectedAnswer);
        },
        answers:function(){
          if(this.answers && this.answers.length>0)
          {
            this.selectedAnswer= this.answers;
          }
        }
      }
    }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .input-option{
    margin-left: 10px;
    font-weight: lighter;
  }
  .checkbox-inputs{
    max-width: 40%;
    padding: 5px;
    border-radius: 10px;
  
  }
  .checkbox-inputs.correct{
    background: #ddf1dd;
  
  }
  .checkbox-inputs.incorrect{  
    background: #fed2d2;
  
  }
  
  </style>
  
  <style>
  
  .quiz-checkbox input[type=checkbox]{
    accent-color: var(--primary-color);
  }
  
  .radio-inputs .custom-control-label{
    margin: 0px;
  }
  </style>