<template>
	<div class="attendance-list-item" :class="{ inactive_item: (studentData.status!=null && studentData.status.toLowerCase()=='inactive') }" @click="toggleViewEditUser">
		<div class="user-avatar">
			<img
				v-if="studentData.profilePicture"
				:src="studentData.profilePicture"
			/>
			<span v-else>{{ studentData.firstName[0] }}</span>
		</div>
		<div class="d-flex flex-column">
			<span class="text-black">
				{{ studentData.firstName }} {{ studentData.lastName }} <span v-if="studentData.status!=null && studentData.status.toLowerCase()=='inactive'" class="text-danger"> (Inactive) </span>
			</span>
			<span class="subtitle">{{ studentData.email }} {{ studentData.phone ? " | " + studentData.phone : "" }}</span>
			<span class="subtitle">
				<span v-if="studentAttendance && totalAttendedMinutes">
					<strong>Attended Duration: </strong><span>{{ totalAttendedMinutes }}</span>
				</span>
			</span>
		</div>
		<div class="right-menu ms-auto">
			<div  class="content-cta demo gap-2 d-flex">
				<div 	v-if="(type != AttendanceStatus.ONLINE && type != AttendanceStatus.UNPAID && type == AttendanceStatus.ABSENT )" class="dropdown">
					<button 
					@click.stop
					type="button"   class="dropbtn border-btn preview" @click="toggleCommentModel"><i class="far fa-comment"></i>
					<i class="fas fa-circle comment-notify-batch" v-if="studentData.Reason"></i>
				</button>
					
					
					<div class="dropdown-content">
				     <p v-if="studentData.Reason" class="p-2"> 
					 {{studentData.Reason}}
					</p>
					</div>
				</div>

				<button
					:disabled="loading"
					v-if="(type != AttendanceStatus.ONLINE && type != AttendanceStatus.UNPAID && showButtons)"
					@click="markAttendance"
					type="button"
					:class="[
						'border-btn preview',
						{
							danger: type == AttendanceStatus.PRESENT,
							success: type == AttendanceStatus.ABSENT,
						},
					]"
				>
					<b-spinner small v-if="loading"></b-spinner>
					<i
						v-else-if="!loading && type == AttendanceStatus.ABSENT"
						class="far fa-user-plus"
					></i>
					<i
						v-else-if="!loading && type == AttendanceStatus.PRESENT"
						class="far fa-user-minus"
					></i>
					{{ buttonText }}
				</button>
				<!-- <button
					v-if="type != AttendanceStatus.ONLINE"
					:disabled="loading"
					@click="markLate"
					type="button"
					class="border-btn preview"
				>
					<b-spinner small v-if="loading"></b-spinner>
					<i v-else class="far fa-stopwatch"></i>
					Mark Late
				</button> -->
			</div>
		</div>
		<div>
			<span class="subtitle" v-if="type == AttendanceStatus.ONLINE || type == AttendanceStatus.PRESENT">
				<span class="ms-2" :id="'info-icon-'+type+'-'+studentData.id" style="font-size: 14px;">
					<i class="fas fa-info-circle"></i>
				</span>
				<b-popover
					:target="'info-icon-'+type+'-'+studentData.id"
					triggers="hover"
					placement="bottomright"
					title="More Information"
					custom-class="popover-custom-style"
				>
					<div v-for="(attendance,index) in studentAttendance" :key="index">
						<div>
							<div v-if="attendance.timeIn">
								<strong>Time in: </strong>
								{{ timeParser(attendance.timeIn) + " | " + dateParser(attendance.timeIn) }}
							</div>
							<div v-if="attendance.timeOut">
								<strong>Time out: </strong>
								{{ timeParser(attendance.timeOut) + " | " + dateParser(attendance.timeOut) }}
							</div>
							<div v-if="attendance.duration != null">
								<strong>Duration: </strong>
								{{ attendance.duration + " minutes" }}
							</div>
						</div>
						<hr v-if="index+1 != studentAttendance.length" />
					</div>
				</b-popover>
			</span>
		</div>
		<ViewEditUserModal
			:user="userToViewEdit.user"
			:openTab="userToViewEdit.openTab"
			@updateModalState="toggleViewEditUser"
			:showModal="showUserDetailModal"
			viewOnly
		/>
		<b-modal id="absent-comment-modal" :visible="isShowCommentModel" hide-header centered size="md" body-class="modal-body"
			hide-footer modal-class="custom-modal">
			<div class="body-container">
				<h4 class="add-content-title">Absent Comment</h4>
				<br/>
				<textarea v-model="textComment" placeholder="Enter Comment" class="form-control" ></textarea>
			</div>
		
			<div >
				<center >
					<button :disabled="loading" 
						@click="saveComment" type="button" class="step-btn">Save</button>
				</center>
			</div>
		
		</b-modal>

	</div>
</template>

<script>
import { AttendanceStatus } from "../../../constants/attendance";
import { mapActions, mapState } from "vuex"; 
import ViewEditUserModal from "../Users/ViewEditUserModal.vue";
import formatter from "../../../helpers/FormatHelpers";

export default {
	name: "AttendanceListItem",
	data() {
		return {
			AttendanceStatus,
			showUserDetailModal: false,
			userToViewEdit: {
				user: this.$props.studentData,
				openTab: 0,
			},
			isShowCommentModel: false,
			textComment:""
		};
	},
	props: {
		studentData: Object,
		type: String,
		mode: { type: String, default: "view" },
		contentData: Object,
		absentComment: String,
		studentAttendance: Array
	},
	methods: {
		...mapActions("content", ["markLiveClassAttendance"]),
		markAttendance(e) {
			e.stopPropagation();
			var attendanceObj = {};
			switch (this.type) {
				case AttendanceStatus.ABSENT:
					attendanceObj = {
						contentID: this.contentData.id,
						userID: this.studentData.id,
						status: "Present",
						attendedFrom: "Physical",
						timeIn: new Date().toISOString(),
						isMarkLate: false,
						isLeftEarly: false,
					};
					break;
				case AttendanceStatus.PRESENT:
					attendanceObj = {
						contentID: this.contentData.id,
						userID: this.studentData.id,
						status: "Absent",
						attendedFrom: "Physical",
						timeIn: new Date().toISOString(),
						isMarkLate: false,
						isLeftEarly: false,
					};
					break;
			}
			this.markLiveClassAttendance([attendanceObj]).then((res) => {
				if (res) {
					this.$emit("refreshAttendance");
				}
			});
		},
		markLate() {
			var attendanceObj = {
				contentID: this.contentData.id,
				userID: this.studentData.id,
				status: "Absent",
				attendedFrom: "Physical",
				timeIn: new Date().toISOString(),
				isMarkLate: false,
				isLeftEarly: false,
			};
		},
		toggleViewEditUser() {
			this.showUserDetailModal = !this.showUserDetailModal;
			if (!this.showUserDetailModal) {
				// this.userToViewEdit.user = null;
				this.userToViewEdit.openTab = 0;
			}
		},
		toggleCommentModel(){

			this.isShowCommentModel=!this.isShowCommentModel;
			if(this.isShowCommentModel)
			{
				this.textComment=this.studentData.Reason;
			}
		},
		saveComment() {
			var attendanceObj = {
				contentID: this.contentData.id,
				userID: this.studentData.id,
				status: "Absent",
				attendedFrom: "Physical",
				timeIn: new Date().toISOString(),
				isMarkLate: false,
				isLeftEarly: false,
				reason: this.textComment
			};

			this.markLiveClassAttendance([attendanceObj]).then((res) => {
				if (!res) {					
			this.studentData.Reason="";
				}
			});
			
			this.studentData.Reason=this.textComment;
			this.toggleCommentModel();
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		timeParser(date) {
			return formatter.getTimeIn12Hours(date);
		},
	},
	computed: {
		...mapState("user", ["loading"]),
		buttonText() {
			switch (this.type) {
				case AttendanceStatus.PRESENT:
					return "Mark Absent";
				case AttendanceStatus.ABSENT:
					return "Mark Present";
			}
		},
		showButtons() {
			var endTime = new Date(
				new Date(this.contentData.availableTo).setMinutes(
					new Date(this.contentData.availableTo).getMinutes() + 30
				)
			);
			return endTime > new Date() ? true : false;
		},
		totalAttendedMinutes() {
			const totalDuration = this.studentAttendance?.some(o => o.duration !== null)
  				? this.studentAttendance.reduce((acc, o) => acc + (parseInt(o.duration) || 0), 0) + " minutes"
				: null;

			return totalDuration;
		}
	},
	components: { ViewEditUserModal },
	created() {},
};
</script>

<style>
.popover-custom-style .popover-body {
	/* .popover-body { */
	max-height: 200px !important;
	overflow: scroll !important;
/* } */
}

.popover-custom-style {
	/* top: 145px !important; */
	min-width: 200px;
}
</style>

<style scoped>
/* .popover-custom-style {
	max-height: 200px;
    overflow: scroll;
} */

.attendance-list-item {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	cursor: pointer;
}

.attendance-list-item:hover {
	background: #ededed;
}

.attendance-list-item.inactive_item{
	background-color: #ffebce;
}

.attendance-list-item .user-avatar {
	width: 2rem;
	height: 2rem;
	color: var(--color-white);
	background-color: #231f20;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2rem;
	margin-right: 0.5rem;
	/* z-index: 100; */
}

.attendance-list-item .user-avatar img {
	vertical-align: middle;
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.attendance-list-item .content-cta button {
	padding: 0.425rem 0;
	width: 5.75rem;
	line-height: 1;
	border-color: #282828;
	font-size: 0.875rem;
	border-radius: 4px;
	color: #282828 !important;
	font-weight: 500;
}

.attendance-list-item .content-cta button.preview {
	padding: 0.425rem 0.675rem;
	width: fit-content;
}

.attendance-list-item .content-cta button.success {
	border-color: var(--color-success) !important;
	color: var(--color-success) !important;
}

.attendance-list-item .content-cta button.danger {
	border-color: var(--color-danger) !important;
	color: var(--color-danger) !important;
}

.attendance-list-item .subtitle {
	font-size: 0.875rem;
}
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
	position: relative;
	display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 999;
	right: 0;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #3e8e41;}

  .comment-notify-batch{
	z-index: 99999999;
    color: red;
    position: absolute;
    right: -3px;
    font-size: 12px;
    top: -3px;
  }
</style>
