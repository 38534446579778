<template>
	<div class="form-group add-course-container">
		<input
			type="search"
			name="add-course"
			id="add-course"
			class="form-input"
			placeholder="Type your title name here..."
			@focus="showDropdown = true"
			@blur="closeDropdown"
			v-model="searchText"
		/>
		<div class="search-dropdown" v-if="searchList.length > 0 && showDropdown">
			<ul>
				<li
					v-b-tooltip.hover
					:title="
						contentList.find((c) => c.id == item.id)
							? 'Subject is already added'
							: ''
					"
					@click="addContent(item)"
					v-for="(item, index) in searchList"
					:key="index"
					:class="[{ added: contentList.find((c) => c.id == item.id) }]"
				>
					<div class="content-name">{{ item.title }}</div>
					<div
						v-b-tooltip.hover
						title="Subject is already added"
						class="disabled-info"
						v-if="contentList.find((c) => c.id == item.id)"
					>
						<i class="fas fa-info-circle"></i>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "ProductVariantContentAddExisting",
	data() {
		return {
			searchText: "",
			showDropdown: false,
		};
	},
	props: { contentList: Array },
	methods: {
		...mapActions("content", ["fetchAllContent"]),
		addContent(item) {
			console.log("addContent");
			this.searchText = "";
			if (!this.contentList.find((c) => c.id == item.id))
				this.$emit("addContent", item);
			// this.$router.push(
			// 	`/product/details/${item.product.friendlyTitle}-${item.id}`
			// );
		},
		closeDropdown() {
			setTimeout(() => (this.showDropdown = false), 300);
		},
		isAdded() {
			console.log(contentList.find((c) => c.id == index.id));
			return contentList.find((c) => c.id == index.id);
		},
	},
	computed: {
		...mapState("content", ["allRootContent"]),
		searchList() {
			// console.log("searchOnInput called");
			var result = [];
			if (this.allRootContent !== {} && this.searchText !== "") {
				console.log("filtering content", this.allRootContent);
				result = this.allRootContent.filter((content) => {
					// if (this.contentList.find((c) => c.id == content.id)) {
					// 	content.isAdded = true;
					// }
					return (
						(content.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1
						// ||
						// (content.lastName || "")
						// 	.toLocaleLowerCase()
						// 	.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						// (content.email || "")
						// 	.toLocaleLowerCase()
						// 	.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						// (content.phone || "").indexOf(this.searchText.toLocaleLowerCase()) >
						// 	-1
					);
				});
			}
			return result;
		},
	},
	created() {
		if (this.allRootContent && this.allRootContent.length == 0) {
			this.fetchAllContent(() => {
				console.log("Fetching all content");
			});
		}
	},
};
</script>

<style scoped>
.add-course-container {
	position: relative;
}

.search-dropdown {
	position: absolute;
	top: 3.5rem;
	background-color: #fff;
	/* padding: 1rem; */
	z-index: 1000;
	width: 100%;
	box-shadow: 0 3px 6px #2f2f2f1f;
	border-radius: 8px;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 8px;
	max-height: 30vh;
	overflow-y: auto;
}

ul li {
	padding: 1rem;
	font-size: 1rem;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	cursor: pointer;
	transition: all 0.3s linear;
}

.search-dropdown ul li.added {
	background-color: #fafafa;
	color: #cccccc;
}

ul li:hover {
	background-color: #f1f1f1;
}

ul li:first-of-type {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

ul li:last-of-type {
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.disabled-info {
	margin-left: auto;
}
</style>
