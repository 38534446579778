import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import {
	FETCH_FORM_API,
	POST_FORM_SUBMISSION_API,
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const formService = {
	fetchFormByEvent,
	fetchFormByID,
	submitForm
};

async function fetchFormByEvent( entity,  event, entityID=null) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_FORM_API+`?Entity=${entity}&Event=${event}${entityID?`&EntityID=${entityID}`:""}`,
			"POST",
			null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}
async function fetchFormByID(ID) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_FORM_API+`/${ID}`,
			"GET",
			null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}
async function submitForm(FormID,FormData) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + POST_FORM_SUBMISSION_API,
			"POST",
			JSON.stringify({
				formID:FormID,
				data:FormData
			}),
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}
function handleResponse(response) {
	console.log("handling response", response);
	if (!response.ok) {
		// 	// if (response.status == 401) {
		// 	console.log("status", response.status);
		// 	store.dispatch("user/regenerateUserToken", {
		// 		token: helpers.getCookie("token"),
		// 	});
		// return;
		// }
		throw new Error(response.status);
	} else {
		return response.json();
	}
}
