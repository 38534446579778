<template>
	<div class="enrollment-container">
		<div class="title-container mb-4">
			<h1 class="page-title">Enrollment</h1>
			<!-- <b-spinner v-if="loading" class="ms-4"></b-spinner> -->
			<div
				class="ms-auto"
				:title="
					productVariantData.endDate &&
					new Date(productVariantData.endDate) <= new Date()
						? 'You cannot enroll students in courses that have ended.'
						: ''
				"
			>
				<a
					class="add-btn dropdown-toggle"
					data-bs-toggle="dropdown"
					role="button"
					:disabled="
						productVariantData.endDate &&
						new Date(productVariantData.endDate) <= new Date()
					"
				>
					<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
					new enrollment
				</a>
				<ul class="dropdown-menu dropdown-menu-end">
					<li
						@click="
							toggleEnrollmentModal(
								ProductVariantEnrollmentType.DIRECT_ENROLLMENT
							)
						"
						class="dropdown-item"
					>
						Direct Enrollment
					</li>
					<li
						@click="
							toggleEnrollmentModal(ProductVariantEnrollmentType.PRICING_PLAN)
						"
						class="dropdown-item"
					>
						Pricing Plan Enrollment
					</li>
				</ul>
				<!-- <button
					:disabled="
						productVariantData.endDate &&
						new Date(productVariantData.endDate) <= new Date()
					"
					class="add-btn"
					role="button"
					@click="toggleEnrollmentModal"
				>
					<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
					new enrollment
				</button> -->
			</div>
			<div class="ms-2">
				<a
					class="add-btn"
					@click="exportUsersEnrollmentData()"
					role="button"
				>
					<i class="fas fa-file-csv me-2"></i>
					{{ exportLoading ? "&nbsp;Exporting, Please wait...": "Export Enrollments" }}
				</a>
			</div>
			<CreateEditEnrollmentModal
				:showModal="showModal"
				@updateModalState="toggleEnrollmentModal"
				:productVariantId="productVariantData.id"
				:productVariantData="productVariantData"
				:enrolledUsers="users"
				:isFree="isFree"
			/>
		</div>
		<div class="filters-container">
			<!-- <label class="main-label">filters</label> -->
			<div class="filter-inputs">
				<div
					class="searchbar position-relative d-flex align-items-center flex-grow-1"
				>
					<input
						v-model="searchText"
						class="search-input"
						type="search"
						placeholder="Search by student id, name, username, email or phone number"
					/>
					<a role="button" class="search-icon text-black">
						<i class="fa fa-search"></i>
					</a>
				</div>
				<!-- <input
					class="search-input"
					type="search"
					placeholder="Select Access Start Date"
					onfocus="this.type = 'date'"
				/>
				<input
					class="search-input"
					type="search"
					placeholder="Select Access End Date"
					onfocus="this.type = 'date'"
				/>
				<v-select
					class="search-input"
					placeholder="Select Pricing Plan"
					:options="pricingOptions"
					:appendToBody="true"
					:calculate-position="withPopper"
				>
				</v-select> -->
				<!-- <v-select
					class="search-input"
					placeholder="Select Enrollment Status"
					:options="enrollOptions"
					:appendToBody="true"
					:calculate-position="withPopper"
				>
				</v-select> -->
				<!-- <button class="search-button">
					<img src="@/assets/images/settings-icon.svg" /> Filter
				</button> -->
			</div>
		</div>
		<div class="d-flex mb-4 gap-3">
			<button
				@click="setEnrollmentStatus('all')"
				:class="['filter-user-type-btn', { active: enrollmentStatus == 'all' }]"
			>
				All ({{ users.length }})
			</button>
			<button
				@click="setEnrollmentStatus('allowed')"
				:class="[
					'filter-user-type-btn',
					{ active: enrollmentStatus == 'allowed' },
				]"
			>
				Allowed ({{ allowedUsers.length }})
			</button>
			<button
				@click="setEnrollmentStatus('blocked')"
				:class="[
					'filter-user-type-btn',
					{ active: enrollmentStatus == 'blocked' },
				]"
			>
				Blocked ({{ blockUsers.length }})
			</button>
			<button
				@click="setEnrollmentStatus('unenrolled')"
				:class="[
					'filter-user-type-btn',
					{ active: enrollmentStatus == 'unenrolled' },
				]"
			>
				Unenrolled ({{ unenrolledUsers.length }})
			</button>
			<button
				@click="setEnrollmentStatus('expired')"
				:class="[
					'filter-user-type-btn',
					{ active: enrollmentStatus == 'expired' },
				]"
			>
				Expired ({{ expiredUsers.length }})
			</button>
			<div v-if="selectedUsers && selectedUsers.length>0" class="btn-box">
				<!-- <button :class="[
								'filter-user-type-btn active'
							]">
							Change Expiry Date
					
				</button> -->
				<b-dropdown id="dropdown-form" text="Change Expiry Date" variant="none" block menu-class="w-100" ref="dropdown" class="dropdown-btn active">
					<b-dropdown-group id="dropdown-group-1" header="New Expiry Date">
						<b-dropdown-form  @submit.stop.prevent>
							<input placeholder="Expires At" class="form-control" type="date"
							v-model="updatedExpiresAt"
							name="expiresAt" id="expires-at" />
							<button class="btn btn-sm" @click="submitChangeExpiryDate">Submit</button>
						</b-dropdown-form>
					</b-dropdown-group>
				</b-dropdown>
			</div>
		</div>
		<div class="table-container">
			<b-overlay
				v-if="this.users && this.users.length > 0"
				:show="loading || processing"
				rounded="sm"
			>
				<table class="table table-hover table-borderless enrolled-users-table">
					<thead>
						<th>
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll()"
							/>
						</th>
						<th>S.No #</th>
						<th>Student ID</th>
						<th>Student Name</th>
						<th>Student Email</th>
						<!-- <th>Access Start Date</th>
						<th>Access End Date</th> -->
						<!-- <th>Pricing Plan</th> -->
						<th>Enrollment Type</th>
						<th>Expires At</th>
						<th>Enrollment Status</th>
						<th>Actions</th>
					</thead>
					<tbody>
						<tr v-for="(user, index) in filteredEnrollments" :key="index" @click="toggleViewEditUser(user)" class="row-hover-cursor-pointer">
							<td @click.stop>
								<input
									type="checkbox"
									name="select_1"
									:disabled="loading"
									:id="user.id"
									@click="toggleUserSelect(user)"
									:checked="isChecked(user.id)"
								/>
							</td>
							<td>{{ (index + 1).toString().padStart(3, "0") }}</td>
							<td>{{ user.id }}</td>
							<td>{{ user.firstName }} {{ user.lastName }}</td>
							<td>{{ user.email }}</td>
							<!-- <td>
								{{
									user.access_start_date
										? dateParser(user.access_start_date)
										: "-"
								}}
							</td>
							<td>
								{{
									user.access_end_date ? dateParser(user.access_end_date) : "-"
								}}
							</td>
							<td>{{ user.pricing_plan ? user.pricing_plan : "-" }}</td> -->
							<td>
								{{ user.enrollmentType ? user.enrollmentType : "-" }}
							</td>
							<td >
								{{ user.expiresAt ? new Date(user.expiresAt).toLocaleDateString('en-us', { weekday:"long",
								year:"numeric",
								month:"short",
								day:"numeric"}) : "-" }}
							</td>
							<td
								v-if="user.expiresAt && new Date(user.expiresAt) < new Date()"
								:class="`${
									user.enrollmentStatus ==
									ProductVariantSubscriptionStatus.BLOCKED
										? 'text-danger'
										: ''
								}`"
							>
								{{ "Expired" }}
							</td>
							<td 
								v-else
								:class="`${
									user.enrollmentStatus ==
									ProductVariantSubscriptionStatus.BLOCKED
										? 'text-danger'
										: ''
								}`"
							>
								{{ user.enrollmentStatus ? user.enrollmentStatus : "-" }}
							</td>
							<td @click.stop>
								<div class="right-menu" v-if="user.enrollmentStatus !=
								ProductVariantSubscriptionStatus.UNENROLLED">
									<button
										role="button"
										class="right-menu-icon"
										@click="showConfirmationModal('block', user)"
										title="Block"
										v-if="
											user.enrollmentStatus !=
											ProductVariantSubscriptionStatus.BLOCKED
										"
									>
										<i class="fa fa-ban"></i> <span>Block</span>
									</button>
									<button
										role="button"
										class="right-menu-icon"
										@click="showConfirmationModal('allow', user)"
										title="Unblock"
										v-else
									>
										<i class="far fa-unlock"></i> <span>Unblock</span>
									</button>
									<button
										role="button"
										class="right-menu-icon delete text-danger"
										@click="showConfirmationModal('unenroll', user)"
										title="Unenroll"
										v-if="
											user.enrollmentType ==
											ProductVariantEnrollmentType.DIRECT_ENROLLMENT
										"
									>
										<i class="fas fa-times"></i> <span>Unenroll</span>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</b-overlay>
			<h5 v-else class="text-center p-4 m-0">No enrolled users</h5>
		</div>
		<ViewEditUserModal
			:user="userToViewEdit.user"
			:openTab="userToViewEdit.openTab"
			@updateModalState="toggleViewEditUser"
			:showModal="showUserDetailModal"
			viewOnly
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateEditEnrollmentModal from "./CreateEditEnrollmentModal.vue";
import formatter from "../../../helpers/FormatHelpers";
import { createPopper } from "@popperjs/core";
import {
	ProductVariantEnrollmentType,
	ProductVariantSubscriptionStatus,
} from "../../../constants/productVariant";
import ViewEditUserModal from "../Users/ViewEditUserModal.vue";

export default {
	name: "CourseEnrollment",
	data() {
		return {
			fields: [
				"student_name",
				"student_email",
				"access_start_date",
				"access_end_date",
				"pricing_plan",
				"enrollment_status",
			],
			pricingOptions: [
				{ label: "Monthly", value: "monthly" },
				{ label: "Bi-Monthly", value: "bi-monthly" },
			],
			enrollOptions: [
				{ label: "Allowed", value: "allowed" },
				{ label: "Blocked", value: "blocked" },
			],
			processing:false,
			users: null,
			selectedUsers: [],
			showModal: false,
			enrollmentStatus: "all",
			searchText: "",
			selectedEnrollmentType: null,
			ProductVariantEnrollmentType,
			ProductVariantSubscriptionStatus,
			updatedExpiresAt:new Date().toISOString().slice(0,10),
			showUserDetailModal: false,
			userToViewEdit: {
				user: null,
				openTab: 0,
			},
			exportLoading: false
		};
	},
	methods: {
		...mapActions("user", [
			"fetchUsersByProductVariant",
			"editUserErollmentInProductVariant",
			"editUserSubscription",
			"exportUsersEnrollmentReport"
		]),

		exportUsersEnrollmentData() {
			this.exportLoading = true;
			this.exportUsersEnrollmentReport(this.productVariantData.id).then((result) => {

				if (result && result.url) {
				const link = document.createElement('a');
				link.href = result.url;
				link.setAttribute('download',result.name?result.name:`users-enrollments-export.csv`);
				document.body.appendChild(link);          
				this.exportLoading = false;
				link.click();
				link.parentNode.removeChild(link);
							// clean up Url
				window.URL.revokeObjectURL(result);
				}
			}).catch((err) => {
				console.log({ err });
				
				this.exportLoading = false;
			});
		},

		toggleViewEditUser(user) {
			if(user) {
				this.userToViewEdit.user = user;
				console.log({user});
			}

			this.showUserDetailModal = !this.showUserDetailModal;
			if (!this.showUserDetailModal) {
				// this.userToViewEdit.user = null;
				this.userToViewEdit.openTab = 0;
			}
		},

		toggleEnrollmentModal(type = null) {
			if (type) {
				this.selectedEnrollmentType = type;
			}
			this.showModal = !this.showModal;
		},
		toggleSelectAll() {
			if (
				this.users &&
				this.users.length > 0 &&
				this.selectedUsers.length != this.users.length
			) {
				this.selectedUsers = this.users;
			} else {
				this.selectedUsers = [];
			}
		},
		submitChangeExpiryDate(){
			if(this.selectedUsers.length>0)
			{
				this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to update course expiry for selected users?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				).then(async (val)=>{
					
					if(val){
						var date = new Date(this.updatedExpiresAt);
						date.setHours(23, 59, 59);
                        var productVariantID=this.productVariantData.id;
						this.processing=true;						
						for(var i=0;i<this.selectedUsers.length;i++){
							var user=this.selectedUsers[i];
							var requestObj = {
								userID: user.id,
								productVariantID:productVariantID,
								expiresAt: date
							};
							await this.editUserSubscription(requestObj);	
							if(i==this.selectedUsers.length-1)
							{
								this.selectedUsers = [];
								this.processing=false;	
								this.$toasted.success("Expires date updated successfully", {
									theme: "outline",
									position: "bottom-center",
									duration: 3000,
								});
								this.fetchUsersByProductVariant(this.productVariantData.id).then(() => {
									this.users = this.usersByProductVariant[this.productVariantData.id];
								});
							}

						};
						
					}
				});
			}
		},
		isChecked(id) {
			var found = this.selectedUsers.find((user) => user.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		isSelectedAll() {
			if (this.users) {
				return this.users.length == this.selectedUsers.length;
			}
		},
		toggleUserSelect(user) {
			var found = this.selectedUsers.find((s) => s.id == user.id);
			console.log(found);
			if (!found) {
				this.selectedUsers.push(user);
			} else {
				this.selectedUsers = this.selectedUsers.filter(
					(su) => su.id != user.id
				);
			}
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		showConfirmationModal(type, user) {
			// e.preventDefault();
			// e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to ${
						type.toLowerCase() == "allow" ? "unblock" : type
					} ${user.firstName} ${user.lastName} from ${
						this.productVariantData.title
					}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then(async (value) => {
					// this.isConfirm = value;
					if (value) {
						var requestObj = {
							userID: user.id,
							productVariantID: this.productVariantData.id,
						};
						if (type.toLowerCase() == "unenroll")
							requestObj.status = "Unenrolled";
						else if (type.toLowerCase() == "block")
							requestObj.status = "Blocked";
						else if (type.toLowerCase() == "allow")
							requestObj.status = "Allowed";

						await this.editUserErollmentInProductVariant(requestObj);
						this.fetchUsersByProductVariant(this.productVariantData.id).then(() => {
							this.users = this.usersByProductVariant[this.productVariantData.id];
						});
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		setEnrollmentStatus(status) {
			this.enrollmentStatus = status;

		},
	},
	computed: {
		...mapState("user", ["usersByProductVariant", "loading"]),
		filteredEnrollments() {
			return this.users.filter((user) => {
				return (
					((user.firstName + " " + user.lastName || "")
						.toLocaleLowerCase()
						.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.firstName || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.lastName || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.id || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.email || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.username || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(user.phone || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1) &&
					(this.enrollmentStatus == "all" ||
					(this.enrollmentStatus == "expired" &&  user.expiresAt && new Date(user.expiresAt)<new Date()) || 					
					(this.enrollmentStatus == "unenrolled" && user.enrollmentStatus.toLowerCase() == this.enrollmentStatus.toLowerCase() && (!user.expiresAt || new Date(user.expiresAt) > new Date())) || 
					(this.enrollmentStatus != "unenrolled" && user.enrollmentStatus.toLowerCase() ==
							this.enrollmentStatus.toLowerCase()))
				);
			});
		},
		blockUsers(){
			if(this.users && this.users.length>0)
			{
				return this.users.filter(x=>x.enrollmentStatus.toLowerCase()=="blocked");
			}
			return [];
		},
		
		expiredUsers(){
			if(this.users && this.users.length>0)
			{
				return this.users.filter(x=>x.expiresAt && new Date(x.expiresAt)<new Date());
			}
			return [];
		},
		unenrolledUsers(){
			if(this.users && this.users.length>0)
			{
				return this.users.filter(x=>(x.enrollmentStatus.toLowerCase() == "unenrolled") && (!x.expiresAt || new Date(x.expiresAt) > new Date()));
			}
			return [];
		},
		allowedUsers(){
			if(this.users && this.users.length>0)
			{
				return this.users.filter(x=>x.enrollmentStatus.toLowerCase() == "allowed" );
			}
			return [];
		},
		isFree() {
			return (
				this.selectedEnrollmentType ==
				ProductVariantEnrollmentType.DIRECT_ENROLLMENT
			);
		},
	},
	components: {
		CreateEditEnrollmentModal,ViewEditUserModal
	},
	props: {
		productVariantData: Object,
	},
	created() {
		if (!this.usersByProductVariant[this.productVariantData.id]) {
			this.fetchUsersByProductVariant(this.productVariantData.id).then(() => {
				this.users = this.usersByProductVariant[this.productVariantData.id];
			});
		}
		this.users = this.usersByProductVariant[this.productVariantData.id];
	},
	watch: {
		usersByProductVariant: function () {
			console.log(
				"usersByProductVariant",
				this.usersByProductVariant[this.productVariantData.id]
			);
			if (this.usersByProductVariant[this.productVariantData.id]) {
				this.users = this.usersByProductVariant[this.productVariantData.id];
			}
		},
	},
};
</script>

<style scoped>
.row-hover-cursor-pointer{ 
	cursor: pointer;
}

.enrollment-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 1.875rem;
	overflow-y: auto;
}

.title-container {
	display: flex;
	align-items: center;
}

.page-title {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	margin: 0;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.table-container {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
}

.enrolled-users-table thead,
.enrolled-users-table td {
	height: 4rem;
	vertical-align: middle;
}

.enrolled-users-table td:first-of-type {
	padding-left: 0;
}

.enrolled-users-table input[type="checkbox"] {
	margin-left: 1.5rem;
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.filters-container {
	padding: 1.5rem;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	background-color: #ffffff;
	margin-bottom: 1.5rem;
}

.filters-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.filter-inputs {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
}

.search-input {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	color: #000000;
	font-size: 1rem;
	column-gap: 0.75rem;
	position: relative;
	outline: none;
}

.search-button {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 2rem;
	filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
	text-decoration: none;
	color: #000000;
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
}

.enrolled-users-table .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
}

.right-menu-icon {
	/* width: 2rem; */
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.125rem;
	column-gap: 0.25rem;
}

.right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

.filter-user-type-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	border: 1px solid transparent;
	transition: all 0.3s ease;
}

.filter-user-type-btn.active {
	border: 1px solid var(--primary-color);
}
.dropdown-item {
	cursor: pointer;
}
.btn-box{
	flex: 1;	
}
.btn-box button{
	float: right;
}

</style>
