<template>
	<div>
		<div>
			<ProductPageBanner :class="{ purchased: true }" :productVariantData="productVariantData" :purchased="true" />
			<div class="container" v-if="courseMessage">
				<p class="text-danger">{{courseMessage}}</p>
			</div>
			<ProductContent :productVariantData="productVariantData" :purchased="true">
				<!-- <ProductActivityCard /> -->
				<ProductDetailsCard
					v-if="productVariantData && productVariantData.subscriptionStatus && productVariantData.subscriptionStatus.toLowerCase() == 'unenrolled'"
					@togglePlansModal="toggleModal"
					:productVariantData="productVariantData"
				/>
			</ProductContent>
			<ProductVariantFeedbackModal :showModal="showProductVariantFeedback" :productVariant="productVariantData"
				@updateModalState="toggleProductVariantFeedback" />

			<ProductDetailsCardMobile
				@togglePlansModal="toggleModal"
				v-if="isMobile && productVariantData && productVariantData.subscriptionStatus && productVariantData.subscriptionStatus.toLowerCase() == 'unenrolled'"
				:productVariantData="productVariantData"
			/>
			<ProductPricingPlansModal
				:productVariantData="productVariantData"
				:showModal="showModal"
				@updateModalState="toggleModal"
			/>
			<div class="container" v-if="productVariantData && productVariantData.subscriptionStatus && productVariantData.subscriptionStatus.toLowerCase() == 'allowed'">
				<button class="add-btn mt-2 mb-2" role="button" @click="showProductVariantFeedbackModal()">
					<i class="fas fa-star star-icon"></i>
					Provide Your Feedback
				</button>
			</div>
		</div>
	</div>
</template>

<script>
// import productVariantData from "../constants/tempProductsData";
import { mapActions, mapState } from "vuex";
// import { contentData } from "../constants/tempProductsData";
import ProductPageBanner from "../components/Product/ProductPageBanner.vue";
import ProductDetailsCard from "../components/Product/ProductDetailsCard.vue"
import ProductDetailsCardMobile from "../components/Product/ProductDetailsCardMobile.vue";
import ProductPricingPlansModal from "../components/Product/ProductPricingPlansModal.vue";
import ProductContent from "../components/Product/ProductContent.vue";
import ProductActivityCard from "../components/Product/ProductActivityCard.vue";
import ProductVariantFeedbackModal from "../components/ProductVariant/ProductVariantFeedbackModal.vue";

export default {
	name: "ProductContentPage",
	components: { ProductPageBanner, ProductContent, ProductActivityCard,ProductVariantFeedbackModal, ProductDetailsCard, ProductDetailsCardMobile, ProductPricingPlansModal },
	data() {
		return {
			productVariantData: null,
			showProductVariantFeedback: false,
			showModal: false
			// contentData: contentData,
		};
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById"]),
		...mapActions("user", ["fetchUsersByProductVariant"]),
		async getProductData() {
			const productId = this.productIdFromUrl;
			// console.log("id from url", productId);
			const data = await this.getProductVariantById(productId);
			// const data = this.allProductVariants.find((item) => {
			// 	if (item.productVariantID == productId) return item;
			// });
			this.productVariantData = data;
			// this.contentData =
			window.document.title = data.product.title;
		},

		showProductVariantFeedbackModal() {
			this.showProductVariantFeedback = true;
		},

		toggleProductVariantFeedback(state) {
			if (state != undefined) {
				this.showProductVariantFeedback = state;
			} else this.showProductVariantFeedback = !this.showProductVariantFeedback;
		},
		toggleModal(state) {
			this.showModal = state;
		},
	},
	computed: {
		...mapState("productVariant", ["loading"]),
		...mapState("appState", ["isMobile"]),
		...mapState("user", ["user"]),

		courseMessage() {
			if(this.productVariantData && this.productVariantData.subscriptionStatus){
				console.log({productVariantData:this.productVariantData});
				return this.productVariantData.expiresAt && new Date() > new Date(this.productVariantData.expiresAt)  ? 'Your subscription for the course has expired. If you wish to continue, please consider re-enrolling process or contact admin for further assistance.'
				: this.productVariantData.subscriptionStatus.toLowerCase() == 'unenrolled' ? 'To enroll, please buy-now or contact admin for further assistance.'
				: this.productVariantData.subscriptionStatus.toLowerCase() == 'blocked' ? 'Your access have been blocked from the course. Please contact Admin for further assistance.' 
				: null;
			} 
			return null
		},

		productIdFromUrl() {
			const urlID = this.$route.params.id.split("-");
			var productID = urlID[urlID.length - 1];
			return productID;
		},
	},
	created() {
		if (this.user) {
			var isEnrolled = this.user.productVariants.filter(
				(variant) => variant.id == this.productIdFromUrl
			);
			console.log({ isEnrolled });
			if (!isEnrolled?.length) {
				this.$router.push("/product/details/" + this.$route.params.id);
			}
		}
		this.getProductData();

	},

};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.purchased {
		margin-bottom: 8rem !important;
	}
}

.purchased {
	margin-bottom: 7.5rem;
}

.star-icon {
	color: #ffba00;
	margin-right: 10px;
}
</style>
