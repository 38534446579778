<template>	            
		<div class="settings-container">
			<h2 class="primary-label mt-2">Theme</h2>
			<div class="settings-container d-flex flex-wrap">
                <div class="me-5">
                    <h3 class="main-label">
                        Primary Color                   
                    </h3>
                    <div class="form-group">                             
						<input type="color" value="#ff0000" data-name="PrimaryColor" id="PrimaryColor" v-on:change="changeColor" v-model="PrimaryColor" />       
                    </div>  
                </div>
                <div class="">
                    <h3 class="main-label">
                        Secondary Color                     
                    </h3>
                    <div class="form-group">
						<input type="color" value="#ff0000" data-name="SecondaryColor" id="SecondaryColor" v-on:change="changeColor" v-model="SecondaryColor" />
                    </div>  
                </div>
                <div class="ms-4">                    
                    <b-spinner v-if="colorThemeLoading" class="loader"> </b-spinner>                     
                </div> 
			</div>            
        </div>	
    
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "ColorThemeSettings",
    data() {
		return {    
            PrimaryColor: null,
            SecondaryColor: null,      
            colorThemeLoading: false,			
		};
	},
    methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("appState", [
			"editSystemParameters",
			"fetchSystemParameters",			
		]),	
		testfun(){
			//var colorWell;
			//colorWell = document.querySelector("#PrimaryColor");
			//this.PrimaryColor.addEventListener("change", updateAll, false);
		},
		changeColor(event){
			if (event) {
				var el = event.target.getAttribute('data-name');
				this[el] = event.target.value;
				this.saveColorTheme();
			}
		},
		saveColorTheme(){	            
            this.colorThemeLoading = true;	
            var colorThemeObj = {
				PrimaryColor: this.PrimaryColor,
				SecondaryColor: this.SecondaryColor,
			};
			this.editSystemParameters({
				parameterName: "Theme",
				parameterValue: JSON.stringify(colorThemeObj),
			}).then(() => {
				this.fetchSystemParameters().then(() => {	
					this.$toasted.success("Updated successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
                    this.colorThemeLoading = false;
				});
			});
		},
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
	},	
	created() {
		if (this.systemParameters.Theme && this.systemParameters.Theme.PrimaryColor) {
            this.PrimaryColor = this.systemParameters.Theme.PrimaryColor;
		}
        if (this.systemParameters.Theme && this.systemParameters.Theme.SecondaryColor) {
            this.SecondaryColor = this.systemParameters.Theme.SecondaryColor;
		}        		
	},
	watch: {     
	},
}
</script>

<style scoped>
/* @import './node_modules/verte/dist/verte.css'; */
.primary-label{
	font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 0 0 15px;
    display: inline-block;
    color: #727272;
}
.preferences-card .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.form-input {
    font-size: 1rem;
    outline: none;
    padding: 0.5rem;
    width: 100%;
    border-radius: 0;
    box-shadow: none !important;
    position: relative; 
    border: solid 1px #aaa;
    border-radius: 4px;
}
.settings-container .upload-btn-wrapper .loader {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	position: absolute;
}
.verte {
    justify-content: flex-start;
}
</style>
