<template>
	<b-modal id="free-text-modal" @change="$emit('updateModalState', false)"   :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')"  :visible="visible" hide-header centered
		content-class="free-text-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">
			<div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
				<button @click="$emit('updateModalState', false)" class="close-button" role="button">
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
			</div>
			
			<h1 class="add-content-title">
				{{ this.isEditMode ? "Edit" : "Create" }} Free Text
			</h1>

			<div class="info-container mb-4">

				<div class="form-group mb-3">
					<label for="title" class="main-label">Free Text Title (Required)</label>
					<input placeholder="Type your free text title" class="form-input" type="search" name="title" id="title"
						v-model="title" required>
				</div>

				<div id="editor" class="quill-editor"></div>

			</div>

			<div class="step-btn-container">
				<button :disabled="!validateForm() || loading" @click="uploadFreeText()" role="button" class="step-btn">
					<b-spinner v-if="loading" small></b-spinner>
					Submit
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>



		</div>
	</b-modal>
</template>

<script>
import Loading from "../../../components/Layout/Loading.vue";
import draggable from "vuedraggable";
import { getFileType } from "../../../helpers/Converters"
import { mapActions } from "vuex";
export default {
	name: "FreeTextActionModal",
	props: {
		visible: Boolean,
		isEditMode: Boolean,
		parentContentId: String,
		contentData: Object,
	},
	data() {
		return {
			title: null,
			freetext: null,
			quillEditor: null,
			content: null,
			loading: false,
			isExpanded:false
		};
	},
	methods: {
		...mapActions("content", ['uploadContentFile', 'createFreeTextContent', 'updateContent', 'getContentById']),
		initQuillEditor() {
			const toolbarOptions = [
				['bold', 'italic', 'underline', 'strike'],
				['blockquote', 'code-block'],
				[{ 'header': 1 }, { 'header': 2 }],
				[{ 'list': 'ordered' }, { 'list': 'bullet' }],
				[{ 'script': 'sub' }, { 'script': 'super' }],
				[{ 'indent': '-1' }, { 'indent': '+1' }],
				[{ 'align': [] }],
				['link', 'image', 'video'],
				[{ 'color': [] }, { 'background': [] }],
				[{ 'font': [] }],
				[{ 'size': ['small', false, 'large', 'huge'] }],
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				[{ 'direction': 'rtl' }],
				['clean']
			];
			const options = {
				modules: {
					toolbar: toolbarOptions
				},
				theme: 'snow',
				placeholder: 'Compose an epic...',
			};
			this.quillEditor = new Quill('#editor', options);
			this.handleFileInput();
			this.handleSnippingImagePaste();
			//this.quillEditor.on('text-change', this.handleTextChange.bind(this));
		},
		validateForm() {
			return this.title && this.quillEditor;
		},
		uploadFreeText() {
			if (this.validateForm()) {
				// const delta = this.quillEditor.getContents();
                this.freetext = this.quillEditor.root.innerHTML;
            
				this.loading=true;
                var obj = {
                    id: this.contentData?.id ?? null,
                    title: this.title,
                    data: {
                        freetext: this.freetext
                    },
                    parentDirectoryID: this.parentContentId,
                    type: "freetext",
                    IsAvailableForDemo: false
                };
                var self=this;
                if(!this.isEditMode) {
                    this.createFreeTextContent(obj).then((res) => {
						self.loading=false;
                        if (res) {
                            this.$toasted.success("Free Text created successfully", {
                                theme: "outline",
                                position: "bottom-center",
                                duration: 3000,
                            });
                            this.$emit('updateModalState', false);
                        }
                    }).finally(()=>{
						self.loading=false;
					});
                }
                else {
                    this.updateContent(obj).then((res) => {
						self.loading=false;
                        if (res) {
                            this.$toasted.success("Free text updated successfully", {
                                theme: "outline",
                                position: "bottom-center",
                                duration: 3000,
                            });
                        }
                        this.$emit('updateModalState', false);
                    }).finally(()=>{
						self.loading=false;
					});
                }				
			}
		},
		async uploadFile(fileType, fileName, file) {
			let formData = new FormData();
			formData.append('Type', fileType);
			formData.append('Entity', 'content/freetext');
			formData.append('File', file, fileName);
			formData.append('IsCompressionRequired', 'false');
			try {
				return await this.uploadContentFile(formData);
			}
			catch (e) {
				return null;
			}
		},
		handleSnippingImagePaste() {
			const component = this;
			this.quillEditor.clipboard.addMatcher('img', async (node, delta) => {
				if (node && node.src) {
					try {
						const file = component.dataURItoBlob(node.src);
						file.name = "Picture.png";
						const range = component.quillEditor.getSelection(true);
						const url = await component.uploadFile('image', file.name, file);
						if (url) {
							component.quillEditor.insertEmbed(range.index, 
							'image', url, Quill.sources.USER);
						}
					} catch (error) {
						console.error("Error handling image paste:", error);
					}
				}
				return delta;
			});
		},
		handleFileInput() {
			var component = this;
			this.quillEditor.getModule('toolbar').addHandler('image', function () {
				var input = document.createElement('input');
				input.setAttribute('type', 'file');
				input.click();
				input.addEventListener('change', async function () {
					var uploadedFile = input.files[0];
					var fileName = uploadedFile.name;
					var fileType = getFileType(fileName);
					if (fileType !== 'unknown') {
						try {
							const url = await component.uploadFile(fileType, fileName, uploadedFile);
							const range = component.quillEditor.getSelection();
							if (fileType === 'image') {
								component.quillEditor.insertEmbed(range, 'image', url);
							}
							else if (fileType !== 'video') {
								const linkText = fileName.toUpperCase();
								let content = `<a href="${url}" class="editor-link" target="_blank">${linkText}</a>`;
								component.quillEditor.clipboard.dangerouslyPasteHTML(range.index, content);
							}
						} catch (error) {
							console.error('Error uploading and inserting link:', error);
						}
					}
				});
			});
		},
		dataURItoBlob(dataURI) {
			const byteString = atob(dataURI.split(',')[1]);
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ab], { type: mimeString });
		},
		// handleTextChange() {
		// 	const content = this.quillEditor.getText();
		// 	const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})/g;
		// 	let match = null;
		// 	while ((match = youtubeRegex.exec(content)) !== null) {
		// 		const videoId = match[1];
		// 		const endIndex = match.index + match[0].length;
		// 		this.embedVideo(videoId, match.index, endIndex);
		// 	}
		// },
		// embedVideo(videoId, startIndex, endIndex) {
		// 	const quillEditor = this.quillEditor;
		// 	const index = startIndex + endIndex;
		// 	if (index >= 0 && index <= quillEditor.getLength()) {
		// 		const embedCode = `<iframe width="400" height="265" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
		// 		quillEditor.insertEmbed(index, 'video', embedCode, 'user');
		// 		quillEditor.setSelection(index + 1);
		// 	}
		// },
	},
	components: {
		Loading,
		draggable,
	},
	mounted() {
	},
	created() {
		this.content = { ...this.contentData };
		this.loading=false;
	},
	computed: {},
	watch: {
		visible: function () {
			if (this.visible) {
				this.loading=false;
				setTimeout(this.initQuillEditor, 500);

                if(this.content && this.isEditMode) {
                    this.title = this.content.title;
                }
			}
		},

        quillEditor: async function () {
            if(this.quillEditor) {
                await this.getContentById({ id: this.content.id }).then((res) => {
                    this.content = res;
                })
                if(this.content && this.isEditMode) {
                    var data = JSON.parse(JSON.stringify(this.content.data));
    
                    if(data && this.quillEditor) {
                        this.quillEditor.root.innerHTML = data;
                    }
                }
            }
        }
	},
};
</script>

<style scoped>

	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}

.free-text-modal .modal-body .body-container {
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}
.info-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.info-container .form-group {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.info-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}
.form-input:focus {
	border-color: var(--primary-color) !important;
}
.info-container tbody img {
	height: 50px;
	width: 50px;
}
.quill-editor {
	height: 300px;
	border: 1px solid gray;
	padding: 15px;
}
.editor-link {
	color: var(--color-red);
	font-size: 18px;
	text-decoration: none;
}
</style>
<style>

.free-text-modal
{
  height: 45.375rem;
  /* width: 70vw; */
}
.free-text-modal .modal-body.modal-body
{
	display: flex;
    flex-direction: row;
    padding: 0 !important;
	overflow: auto;
}
.free-text-modal .modal-body.modal-body .body-container
{
	
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

#free-text-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#free-text-modal .expanded .free-text-modal
{
	height: 90vh;
	
}
	
</style>