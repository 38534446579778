// import helpers from "../../helpers/CookieHelpers";
import defaultRouteSettings from "./default";
import Home from "../../custom/edcentra/Home.vue";
import Dashboard from "../../custom/edcentra/Dashboard.vue";
import TeachWithUs from "../../custom/edcentra/TeachWithUs.vue";

const routes = defaultRouteSettings.routes.map((route) => {
	if (route.path == "/") {
		route.component = Home;
	}
	if (route.path == "/user/dashboard") {
		route.component = Dashboard;
	}
	return route;
});
routes.push({
	path: "/teach-with-us",
	name: "TeachWithUs",
	component: TeachWithUs,
	meta: {
		title: "Teach With Us",
	},
});

export default { routes, beforeEach: defaultRouteSettings.beforeEach };
