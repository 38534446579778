<template>
  <b-modal
    id="create-productVariant-feedback-modal"
    @change="$emit('updateModalState')"
    :visible="visible"
    hide-header
    centered
    content-class="create-productVariant-feedback-modal"
    size="lg"
    body-class="modal-body"
    hide-footer
    modal-class="custom-modal"
    no-close-on-backdrop
  >
    <div class="body-container">
      <button @click="closeModal" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <div>
        <div class="mb-3">
          <h1 class="add-enroll-title">Provide Your Feedback</h1>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-4">
            <label for="address" class="main-label">Rating (required)</label>
            <star-rating v-bind:star-size="25" v-model="rating"></star-rating>
          </div>
          <div class="col-lg-12 mt-4">
            <label for="address" class="main-label">Your Feedback (optional)</label>
            <textarea
              type="search"
              class="form-input"
              placeholder="Enter Review here..."
              v-model="review"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="step-btn-container">
        <button
          @click="addProductVariantFeedback()"
          role="button"
          class="step-btn"
          :disabled="loading || !rating"
        >
          <b-spinner v-if="loading" small> </b-spinner>
          Submit
          <i class="fas fa-long-arrow-alt-right"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StarRating from "vue-star-rating";
import Vue from "vue";

export default {
  name: "ProductVariantFeedbackModal",
  components: {
    StarRating,
  },

  data() {
    return {
      rating: null,
      review: null,
      loading: false,
      productVariantData: {},
    };
  },
  computed: {
    visible() {
      return this.showModal;
    },
  },
  props: {
    showModal: Boolean,
    productVariant: Object,
  },
  methods: {
    ...mapActions("productVariant", ["createProductVariantFeedback"]),

    closeModal() {
      this.$emit("updateModalState", false);
    },

    async addProductVariantFeedback() {
      this.loading = true;
      this.productVariantData.ratingPoint = this.rating;
      this.productVariantData.review = this.review ? this.review : undefined;
      this.productVariantData.productVariantID = this.productVariant.id;
      // this.productVariantData.productVariantID = "160";

      var data = await this.createProductVariantFeedback(
        JSON.stringify(this.productVariantData)
      )
        .then(() => {
          this.productVariantData.ratingPoint = null;
          this.productVariantData.review = null;
          this.rating = null;
          this.review = null;
          this.loading = false;
          this.closeModal();
          Vue.toasted.success("Feedback Added Successful", {
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async created() {},
};
</script>

<style>
.vue-star-rating-rating-text {
  font-size: 0px;
}

.create-productVariant-feedback-modal {
  min-height: 36.375rem;
  width: 70vw;
}

.create-productVariant-feedback-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.confirm-modal-body {
  padding: 1rem !important;
  font-size: 1.125rem;
}
.create-productVariant-feedback-modal .body-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.create-productVariant-feedback-modal .add-enroll-title {
  text-transform: capitalize;
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 1rem;
  color: #000000;
}

.create-productVariant-feedback-modal .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.create-productVariant-feedback-modal .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0 0 0.875rem;
  /* max-width: 21.625rem; */
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  height: 40px;
}

.create-productVariant-feedback-modal .upload-btn-wrapper {
  width: 11rem;
  max-width: 11rem;
  max-height: 11rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper.preview {
  padding: 0;
}

#preview {
  max-width: 100%;
}

.upload-btn {
  padding: 0.5rem 1.25rem;
  font-weight: bold;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 6px;
  text-transform: uppercase;
  color: #000000;
  font-weight: normal;
}

.input-icon-right {
  /* position: absolute; */
  font-size: 1.25rem;
  right: 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.create-productVariant-feedback-modal .upload-btn-wrapper .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.create-productVariant-feedback-modal .upload-btn-wrapper:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.create-productVariant-feedback-modal .upload-btn-wrapper .right-menu-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.8px solid #cbcbcb;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 50%;
}

.create-productVariant-feedback-modal .upload-btn-wrapper .right-menu-icon img {
  width: 1rem;
  height: 1rem;
}

.create-productVariant-feedback-modal
  .upload-btn-wrapper
  .right-menu-icon.delete {
  border-color: #ff0000;
}

.create-productVariant-feedback-modal #profilePicturePath {
  pointer-events: none;
}

.create-productVariant-feedback-modal .form-input.error {
  color: var(--color-danger) !important;
  border-color: var(--color-danger) !important;
}
</style>
