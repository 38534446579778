import { render, staticRenderFns } from "./Assignment.vue?vue&type=template&id=35380ec8&scoped=true"
import script from "./Assignment.vue?vue&type=script&lang=js"
export * from "./Assignment.vue?vue&type=script&lang=js"
import style0 from "./Assignment.vue?vue&type=style&index=0&id=35380ec8&prod&scoped=true&lang=css"
import style1 from "./Assignment.vue?vue&type=style&index=1&id=35380ec8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35380ec8",
  null
  
)

export default component.exports