<template>
	<div
		:class="['product-list-item']"
		:title="
			disabled ? 'You are already enrolled in a batch of this course' : ''
		"
	>
		<h1 class="product-title">
			{{ productData.title }}
		</h1>
		<span v-if="disabled" class="text-danger"
			>You are already enrolled in a batch of this course</span
		>

		<p v-if="productData.properties.trainers" class="instructor-name">
			<!-- {{ productData }} -->
			<!-- by Ahmed Saya -->
		</p>
		<div v-if="productVariantsLoaded" class="product-variant-list mt-4">
			<div
				v-for="item in filteredProductVariantsByProduct"
				:key="item.id"
				class="product-variant-item"
			>
				<input
					type="radio"
					:name="`productVariant-${item.product.id}`"
					:id="`productVariant-${item.id}`"
					:value="item"
					v-model="selectedProductVariant"
					:disabled="isAdded || disabled || isBatchDisabled(item)"
					:checked="isChecked(item)"
				/>
				<label :id="'batch-' + item.id" :for="`productVariant-${item.id}`">
					<div>
						<strong>{{ item.title }}</strong> <br />
						<strong>
							{{
								productTrainersList(item)
									.map((p) => {
										return p.firstName + " " + p.lastName;
									})
									.toString()
									.split(",")
									.join(",\n")
							}} </strong
						><br />
						<span style="white-space: pre-wrap">{{
							item.shortDescription ? item.shortDescription : ""
						}}</span>
						<br />
						<span v-if="isBatchDisabled(item)" class="text-danger"
							>This batch is full. Please select any other batch.</span
						>
						<!-- Rs. {{ item.pricing.amount }} -->
					</div>
				</label>
			</div>
		</div>
		<div
			v-if="productVariantsLoaded && !filteredProductVariantsByProduct.length"
			class="product-variant-list"
		>
			No batches found
		</div>
		<div class="text-center">
			<b-spinner v-if="loading"></b-spinner>
		</div>
		<button
			v-if="
				productVariantsByProduct[productData.id] &&
				productVariantsByProduct[productData.id].length > 0
			"
			:disabled="isAdded || !selectedProductVariant || disabled"
			@click="addItemToList"
			role="button"
			class="add-to-list"
		>
			{{ buttonText }}
		</button>
		<ProductPricingPlansModal
			:showModal="showPlansModal"
			:productVariantData="selectedProductVariant"
			@updateModalState="setModal"
			:caller="0"
		/>
	</div>
</template>

<script>
import ProductPricingPlansModal from "../../../components/Product/ProductPricingPlansModal.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	name: "ProductRegistrationListItem",
	data() {
		return {
			isAdded: false,
			selectedProductVariant: null,
			productVariantsLoaded: false,
			showPlansModal: false,
		};
	},
	props: {
		productData: Object,
		disabled: Boolean,
	},
	components: {
		ProductPricingPlansModal,
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantByProductId"]),
		...mapActions("cart", ["addToCart"]),
		isChecked(item) {
			if (
				this.selectedProductVariant &&
				this.selectedProductVariant.id == item.id
			) {
				console.log(
					"item is selected",
					this.selectedProductVariant.id == item.id
				);
				return true;
			}
		},
		addItemToList() {
			if (
				this.selectedProductVariant &&
				this.selectedProductVariant.pricingStatus.toLowerCase() == "paid"
			)
				this.showPlansModal = true;
			else this.enrollCourse();
		},
		setModal(value) {
			this.showPlansModal = value;
			document.getElementById("cart").scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "nearest",
			});
		},
		enrollCourse() {
			this.addToCart(this.selectedProductVariant);
			document.getElementById("cart").scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "nearest",
			});
		},
		productTrainersList(item) {
			// console.log(item.properties);
			var trainerList = [];
			if (item.properties && item.properties.trainers && this.trainers) {
				var list = JSON.parse(item.properties.trainers);
				console.log(list);
				list.forEach((trainerID) => {
					var found = this.trainers.find((t) => t.id == trainerID);
					if (found) {
						trainerList.push(found);
					}
				});
			}
			// console.log(trainerList);
			return trainerList;
		},
		isBatchDisabled(item) {
			return item.status.toLowerCase() == "private";
		},
	},
	computed: {
		...mapState("cart", ["cart"]),
		...mapState("user", ["user", "trainers"]),
		...mapGetters("cart", ["getCartItems"]),
		...mapState("productVariant", ["productVariantsByProduct", "loading"]),
		buttonText() {
			if (this.disabled) return "Already Enrolled";

			if (this.isAdded) {
				return "added";
			} else {
				return "add to list";
			}
		},
		filteredProductVariantsByProduct() {
			return this.productVariantsByProduct[this.productData.id].filter(
				(variant) => {
					if (
						variant.status.toLowerCase() != "draft"
						// && variant.status.toLowerCase() != "private"
					)
						return variant;
				}
			);
		},
	},
	created() {
		if (!this.productVariantsByProduct[this.productData.id]) {
			this.productVariantsLoaded = false;
			this.getProductVariantByProductId(this.productData.id).then(
				(variants) => {
					// console.log(this.productVariantsByProduct[this.productData.id]);
					var foundProductvariant = this.cart.items.find((item) => {
						var found = variants.find((variant) => {
							console.log(variant.id == item.id);
							return variant.id == item.id;
						});
						if (found) return found;
					});
					console.log("found product in cart", foundProductvariant);

					if (foundProductvariant) {
						this.selectedProductVariant = foundProductvariant;
						// setTimeout(() => {
						this.isAdded = true;
						// }, 200);
					} else {
						this.selectedProductVariant = null;
						this.isAdded = false;
					}
					this.productVariantsLoaded = true;
				}
			);
		} else {
			var foundProductvariant = this.cart.items.find((item) => {
				var found = this.productVariantsByProduct[this.productData.id].find(
					(variant) => {
						// console.log(variant.id == item.id);
						return variant.id == item.id;
					}
				);
				if (found) return found;
			});

			if (foundProductvariant) {
				// console.log(foundProductvariant);
				this.selectedProductVariant = foundProductvariant;
				// setTimeout(() => {
				this.isAdded = true;
				// }, 200);
			} else {
				this.selectedProductVariant = null;
				this.isAdded = false;
			}
			this.productVariantsLoaded = true;
		}
		// console.log(this.$store.state);
	},
	watch: {
		getCartItems: function () {
			console.log("cart changed");
			var foundProductvariant = this.cart.items.find((item) => {
				var found = this.productVariantsByProduct[this.productData.id].find(
					(variant) => {
						// console.log(variant.id == item.id);
						return variant.id == item.id;
					}
				);
				if (found) return found;
			});

			if (foundProductvariant) {
				console.log(foundProductvariant);
				// setTimeout(() => {
				this.selectedProductVariant = foundProductvariant;
				this.isAdded = true;
				// }, 200);
			} else {
				this.selectedProductVariant = null;
				this.isAdded = false;
			}
			// this.productVariantsLoaded = true;
		},
		productVariantsLoaded: function () {
			if (this.productVariantsLoaded) {
				console.log("batches loaded");
				this.$nextTick(() => {
					const event = new CustomEvent("batches-loaded", {
						detail: this.filteredProductVariantsByProduct,
					});
					document.dispatchEvent(event);
				});
			}
		},
	},
};
</script>

<style scoped>
.product-variant-list {
	margin-bottom: 2rem;
}

.product-list-item {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	box-shadow: 0px 3px 6px rgba(197, 197, 197, 0.25);
	border-radius: 12px;
	padding: 2rem 1.5rem 1.5rem 2rem;
	width: calc(50% - 0.5rem);
	display: flex;
	flex-direction: column;
	height: fit-content;
}

.product-list-item.disabled {
	opacity: 0.5;
}

.product-title {
	font-size: 1.875rem;
	margin-bottom: 0;
}

.instructor-name {
	font-size: 1.125rem;
}

.product-variant-item {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	/* padding: 0.625rem 1.125rem; */
	align-items: center;
	position: relative;
}

.product-variant-item input,
.product-variant-item label {
	margin: 0;
}

.product-variant-item label {
	padding: 1rem 2rem 1rem 4rem;
	width: 100%;
	border-radius: 8px;
	cursor: pointer;
}

.product-variant-item input[type="radio"] {
	width: 1.375rem;
	height: 1.375rem;
	position: absolute;
	left: 1rem;
}

.product-variant-item input[type="radio"]:checked::before {
	background-color: var(--primary-color);
}

.product-variant-item input[type="radio"]:checked + label {
	background-color: #ebebeb;
}

.add-to-list {
	padding: 0.75rem 1.25rem;
	margin-top: 1rem;
	background-color: #5cb85c;
	color: #ffffff;
	border-radius: 8px;
	text-transform: uppercase;
	font-size: 0.875rem;
	align-self: flex-end;
	margin-top: auto;
}

.add-to-list:disabled {
	background-color: #777;
}
</style>
