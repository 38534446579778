// import helpers from "../../helpers/CookieHelpers";
import defaultRouteSettings from "./default";
import Home from "../../custom/astc/Home.vue";

const routes = defaultRouteSettings.routes.map((route) => {
	if (route.path == "/") {
		route.component = Home;
	}
	return route;
});

export default { routes, beforeEach: defaultRouteSettings.beforeEach };
