<template>
  <b-modal id="invoice-detail-modal" @change="$emit('updateModalState')" :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')" :visible="visible" hide-header centered
    content-class="invoice-detail-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
    no-close-on-backdrop>

    <div class="body-container">
      <div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
				<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
				</button>
			</div>
			
      
      <div class="d-flex justify-content-between align-items-end">
        <div>
          <!-- <h1 v-if="invoice" class="add-enroll-title">
            Invoice # {{ invoice.id }}
          </h1>
          <div v-if="invoice" class="d-flex align-items-center gap-3 mb-4">
            <div
              :class="[
                'status-tag',
                {
                  success: invoice.status == InvoiceStatus.PAID,
                  danger:
                    invoice.status == InvoiceStatus.UNPAID ||
                    invoice.status == InvoiceStatus.OVER_PAID,
                  warning: invoice.status == InvoiceStatus.PARTIALLY_PAID,
                },
              ]"
            >
              {{ invoice.status }}
            </div>
            <span>{{ user.firstName }} {{ user.lastName }}</span>
          </div> -->
        </div>
      </div>

      <label for="invoice-details" class="main-label"> Questions </label>
      <table class="table table-borderless enrolled-users-table">
        <thead>
          <tr class="d-flex">
            <th>ID</th>
            <th class="col">Title</th>
            <!-- <th class="col">Text</th> -->
            <th class="col">Type</th>
            <th class="col">Marks</th>
            <th class="col">Randomize Answers</th>
            <th class="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loading && questions && questions.length > 0">
          <!-- <InvoiceDetailItem
            v-for="detail in invoice.details"
            :key="detail.id"
            :invoiceDetail="detail"
            :selectedDetails="selectedDetails"
            @toggleSelect="toggleInvoiceSelect"
            :arePaymentsAdded="arePaymentsAdded()"
            :currency="invoice.currency"
            @updateAmount="updatePayment"
            @updateDiscount="discountUpdate"
            @updateError="error"
            @updateDueDate="dueDateUpdate"
            :mode="mode"
            :error="isError"
            :invoiceId="invoice.id"
            :userId="user.id"
          /> -->
          <tr class="d-flex cursor-pointer" v-for="(question, index) in questions" :key="index"
            @click="toggleQuizQuestionsModal(true, question)">
            <td>{{ question.id }}</td>
            <td class="col">
              <span v-html="question.title"> </span>
            </td>
            <!-- <td class="col">
              <span v-html="question.questionText"> </span>
            </td> -->
            <td class="col">{{ question.type }}</td>
            <td class="col">{{ question.marks }}</td>
            <td class="col">
              {{ question.randomizeAnswerOptions ? "Yes" : "No" }}
            </td>
            <td class="col" @click.stop>
              <a @click="showDeleteConfirmPopup(question.id)" role="button" class="right-menu-icon delete">
                <img src="@/assets/images/delete-icon.svg" />
              </a>
              <a :id="'button-' + question.id" href="#" tabindex="0" class="right-menu-icon " style="margin-left: 20px;;">
                <i class="fa fa-eye"></i>
              </a>

              <!-- <a id="popover-button-variant" href="#" tabindex="0">Button</a> -->
              <b-popover :target="'button-' + question.id" placement="bottom" triggers="focus">
                <!-- <template #title>Danger!</template> -->
                <span v-html="question.questionText"> </span>
              </b-popover>
            </td>
          </tr>
        </tbody>
        <div v-else-if="loading" class="no-content">
          <b-spinner></b-spinner> Loading...
        </div>
        <div v-else-if="!loading && (!questions || questions.length <= 0)" class="no-content">
          No Questions found
        </div>
      </table>

      <QuizQuestionsModal @updateModalState="toggleQuizQuestionsModal" :visible="showQuizQuestionsModal"
        :item="contentData" :questionData="question" :mode="'edit'" @getData="get" />

      <div class="step-btn-container">
        <button @click="closeModal" role="button" class="skip-step-btn">
          Close
        </button>
        <!-- <button
          :disabled="disableSave()"
          @click="
            mode == 'pay' || mode == 'refund' ? payInvoice() : invoiceEdit()
          "
          role="button"
          class="step-btn"
        >
          <b-spinner v-if="loading" small></b-spinner>
          
          <img src="@/assets/images/arrow-right.svg" />
        </button> -->
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuizQuestionsModal from "./QuizQuestionsModal.vue";
// import InvoiceDetailItem from "./InvoiceDetailItem.vue";
import { currencyCodes } from "../../../constants/currencyCodes";
import { createPopper } from "@popperjs/core";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import { InvoiceStatus } from "../../../constants/pricing";

export default {
  name: "UserInvoiceDetailModal",
  props: {
    contentData: Object,
    showModal: Boolean,
  },
  components: {
    // InvoiceDetailItem,
    QuizQuestionsModal,
  },
  data() {
    return {
      questions: [],
      showQuizQuestionsModal: false,
      question: {},
      isExpanded:false
    };
  },
  computed: {
    ...mapState("quiz", ["loading"]),

    visible() {
      return this.showModal;
    },
  },
  methods: {
    ...mapActions("quiz", ["getQuizQuestions", "deleteQuestion"]),
    closeModal() {
      this.$emit("updateModalState", false);
    },

    showDeleteConfirmPopup(questionID) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete Question?`, {
          title: "Are you sure?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          bodyClass: "confirm-modal-body",
        })
        .then((value) => {
          // this.isConfirm = value;
          if (value) {
            this.delete(questionID);
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },

    delete(id) {
      this.deleteQuestion(id).then((response) => {
        if (response?.status.toLowerCase() == "success") {
          this.get();
          this.$toasted.success("Question Deleted Successful!", {
            icon: "check",
            theme: "outline",
            position: "bottom-right",
            duration: 5000,
          });
          // this.closeModal();
        }
      });
    },

    get() {
      if (this.contentData.id) {
        this.getQuizQuestions(this.contentData.id).then((res) => {
          if (res) {
            this.questions = res;
          }
        });
      }
    },

    toggleQuizQuestionsModal(state, q) {
      this.question = q;
      this.showQuizQuestionsModal = state;
    },
  },
  created() {
    this.get();
  },
  watch: {
    // mode: function () {
    // },
  },
};
</script>

<style>
.status-tag {
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1;
  width: fit-content;
  height: fit-content;
}

.status-tag.success {
  background-color: #00c672;
}

.status-tag.danger {
  background-color: var(--color-danger);
}

.status-tag.warning {
  background-color: var(--color-warning);
}

.invoice-detail-modal input[type="checkbox"] {
  /* margin-left: 1.5rem; */
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.enrolled-users-table td {
  min-height: 4rem;
  height: unset;
  vertical-align: middle;
}

.add-btn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.add-btn:hover {
  box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.add-btn::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

th.shrink {
  width: fit-content !important;
  padding-right: 1rem !important;
}


#invoice-detail-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#invoice-detail-modal .expanded .invoice-detail-modal
{
	height: 90vh;
	
}

</style>

<style scoped>
	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}
tbody {
  display: block;
  height: 350px;
  overflow: scroll;
}
</style>
