<template>
	<b-modal
		id="view-edit-user-modal"
		@change="$emit('updateModalState')"
		:visible="visible"
		hide-header
		centered
		content-class="view-edit-user-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div v-if="userData" class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-enroll-title">
				{{ userData.firstName }} {{ userData.lastName }}
			</h1>
			<div v-if="userData" class="d-flex align-items-center gap-3">
				<div
					:class="[
						'status-tag',
						{
							success: userData.status == UserStatus.ACTIVE,
							warning: userData.status == UserStatus.INACTIVE,
						},
					]"
				>
					{{ userData.status }}
				</div>
			</div>
			<ul class="inline-list user-modal-tabs d-flex">
				<li
					@click="changeTab(0)"
					:class="['user-tab', { active: currentTab == 0 }]"
				>
					User Info
				</li>
				<li
					v-if="userData.type.toLowerCase() == 'student' && !viewOnly"
					@click="changeTab(1)"
					:class="['user-tab', { active: currentTab == 1 }]"
				>
					Enrolled Courses
				</li>
				<li
					v-if="userData.type.toLowerCase() == 'student' && !viewOnly"
					@click="changeTab(2)"
					:class="['user-tab', { active: currentTab == 2 }]"
				>
					Invoices
				</li>
				<li
					v-if="userData.type.toLowerCase() == 'student' && !viewOnly"
					@click="changeTab(3)"
					:class="['user-tab', { active: currentTab == 3 }]"
				>
					Payments
				</li>
				<li
					v-if="userData.type.toLowerCase() == 'student' && !viewOnly"
					@click="changeTab(4)"
					:class="['user-tab', { active: currentTab == 4 }]"
				>
					Devices
				</li>
				<li
					v-if="userData.type.toLowerCase() == 'student'"
					@click="changeTab(5)"
					:class="['user-tab', { active: currentTab == 5 }]"
				>
					Attendance
				</li>
				<li
					class="p-0 ms-auto"
					v-if="currentTab == 0 && mode == 0 && !viewOnly"
				>
					<button @click="changeMode(1)" class="add-btn">
						<img src="@/assets/images/edit-icon.svg" class="me-2" /> Edit
					</button>
				</li>
				<li class="p-0 ms-3" v-if="currentTab == 0 && mode == 0 && !viewOnly">
					<button
						@click="
							isUserActive
								? showDisableConfirmPopup($event)
								: updateStatus($event)
						"
						:disabled="isUserStatusUpdate"
						:class="`add-btn ${isUserActive ? 'danger' : ''}`"
					>
						<b-spinner class="me-2" v-if="isUserStatusUpdate" small></b-spinner>
						<i class="fa fa-user me-2"></i>
						{{
							userData && userData.status == UserStatus.INACTIVE
								? "Enable"
								: "Disable"
						}}
					</button>
				</li>
				<li class="p-0 ms-3" v-if="currentTab == 0 && mode == 0 && !viewOnly">
					<button @click="showDeleteConfirmPopup" class="add-btn">
						<b-spinner v-if="isUserDeleting" small></b-spinner>
						<img v-else src="@/assets/images/delete-icon.svg" class="me-2" />
						Delete
					</button>
				</li>
				<li class="p-0 ms-auto" v-if="currentTab == 1 && !viewOnly">
					<button class="step-btn add-btn" @click="changeMode(2)">
						<img src="@/assets/images/add-border-icon.svg" alt="" /> Enroll
						Courses
					</button>
				</li>
				<li class="p-0 ms-auto" v-if="currentTab == 4 && mode!=3 && !viewOnly">
					<button
						:disabled="loading"
						class="step-btn add-btn"
						@click="changeMode(3)"
					>
						<i class="far fa-phone-laptop"></i> Set Device Limits
					</button>
				</li>				
				<li class="p-0 ms-auto" v-if="currentTab == 4 && mode==3 && !viewOnly">
					<button
						:disabled="loading"
						class="step-btn add-btn"
						@click="changeMode(4)"
					>
						<i class="far fa-phone-laptop"></i> User Devices
					</button>
				</li>
				<li class="p-0 ms-3" v-if="currentTab == 4 && mode!=3 && !viewOnly">
					<button
						:disabled="loading || disableReset"
						class="step-btn add-btn"
						@click="showConfirmResetDevices"
					>
						<b-spinner v-if="loading" small></b-spinner>
						<i v-else class="far fa-redo"></i> Reset devices
					</button>
				</li>
				<li class="p-0 ms-3" v-if="currentTab == 0">
					<button
						data-bs-toggle="dropdown"
						id="add-dropdown-btn"
						class="add-btn dropdown-toggle"
						:disabled="isChangePassword"
						@click="showDropdown"
					>
						<b-spinner
							class="me-2"
							v-if="loading && isChangePassword"
							small
						></b-spinner>
						<i v-else class="fas fa-key me-2"></i> Change Password
					</button>
					<div
						id="add-dropdown"
						class="dropdown-menu dropdown-menu-end"
						aria-labelledby="dropdownMenuLink"
					>
						<form @submit="passwordChange">
							<input
								type="search"
								class="change-password-input"
								v-model="newPassword"
								placeholder="Enter new password (required)"
								id="change-password-input"
								required
								v-on:keyup.esc="hideDropdown"
								autofocus
								autocomplete="off"
							/>
							<button
								@click="passwordChange"
								type="submit"
								class="content-action-btn save"
							>
								<img src="@/assets/images/check-icon-green.svg" alt="save" />
							</button>
							<button
								@click="hideDropdown"
								class="content-action-btn cancel"
								type="button"
								role="button"
							>
								<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
							</button>
						</form>
					</div>
				</li>
			</ul>
			<div class="tab-body">
				<component
					:is="tabComponent"
					:user="userData"
					:mode="mode"
					@updateMode="changeMode"
					:ref="isUserDetailTab && 'userDetails'"
					@disable="handleDisable"
					@updateData="handleUpdateData"
					@error="updateError"
					@disableSaveBtn="disableSaveBtn"
					refs="comp"
				/>
			</div>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					cancel
				</button>
				<button
					:disabled="disableSave || isSaveBtnDisabled"
					@click="save()"
					role="button"
					class="step-btn"
				>
					<b-spinner small v-if="loading"></b-spinner>
					save
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import UserDetails from "./UserDetails.vue";
import UserInvoicesAndPayments from "../Invoice/UserInvoices.vue";
import UserEnrollments from "../Enrollment/UserEnrollments.vue";
import UserPayments from "../Payment/UserPayments.vue";
import UserDevices from "./UserDevices.vue";
import UserAttendance from "./UserAttendance.vue";
import { mapActions, mapState } from "vuex";
import { UserStatus } from "../../../constants/user";

export default {
	name: "ViewEditUserModal",
	data() {
		return {
			currentTab: 0,
			userDetails: null,
			mode: 0,
			disableReset: false,
			deviceData: null,
			isError: false,
			UserStatus,
			newPassword: null,
			isChangePassword: false,
			isUserStatusUpdate: false,
			isUserDeleting: false,
			isSaveBtnDisabled: false
		};
	},
	methods: {
		...mapActions("user", [
			"editUser",
			"deleteUser",
			"resetUserDevices",
			"setUserDeviceRestrictions",
			"fetchUserDeviceRestrictions",
			"fetchUserById",
			"updateUserStatus",
			"changePassword",
		]),
		disableSaveBtn(state) {
			if(state == undefined || state == null) {
				return false;
			}
			this.isSaveBtnDisabled = state;
		},
		closeModal() {
			this.$emit("updateModalState");
			Object.assign(this.$data, this.$options.data.call(this));
		},
		changeTab(tab) {
			this.currentTab = tab;
		},
		save() {
			if (this.mode == 3) {
				console.log("save", this.$refs.userDetails);
				this.$refs.userDetails.emitDeviceDetails();
					var restrictionsObj = {
						userID: this.user.id,
						restrictionLimits: this.deviceData,
					};

					this.setUserDeviceRestrictions(restrictionsObj).then(() => {
						//this.changeMode(0);
						this.$toasted.success("Device limits updated successfully", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
						this.fetchUserDeviceRestrictions(this.user);
					});
				
				return;
			}
			this.userDetails = this.$refs.userDetails.updateObj;
			if (this.userDetails.mobile)
				this.userDetails.mobile = this.userDetails.mobile.replaceAll(" ", "");
			this.userDetails.extraProperties = JSON.stringify(
				this.$refs.userDetails.extraProperties
			);
			this.userDetails.id = this.user.id;
			this.editUser(this.userDetails).then(() => {
				this.$emit("updateModalState");
				Object.assign(this.$data, this.$options.data.call(this));
			});
		},
		changeMode(mode) {
			this.mode = mode;
		},
		isUserDetailTab() {
			return this.tabComponent == UserDetails;
		},
		showDisableConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to disable the User: ${this.userData.firstName} ${this.userData.lastName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.updateStatus();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		updateStatus() {
			this.isUserStatusUpdate = true;
			this.updateUserStatus({
				userID: this.user.id,
				status:
					this.userData.status == UserStatus.ACTIVE
						? UserStatus.INACTIVE
						: UserStatus.ACTIVE,
				user: this.user,
			}).then(() => {
				this.isUserStatusUpdate = false;
				console.log("user status updated");
				this.$toasted.success(`User Status Updated!`, {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				this.closeModal();
			});
		},
		userDelete() {
			this.isUserDeleting = true;
			this.deleteUser(this.user).then(() => {
				this.isUserDeleting = false;
				console.log("user delete successfully");
				this.$toasted.success(`User deleted successfully!`, {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				this.closeModal();
			});
		},
		handleDisable() {
			if (this.tabComponent == UserDevices) this.disableReset = true;
		},
		showConfirmResetDevices(e) {
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to reset all devices for ${this.userData.firstName} ${this.userData.lastName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.resetDevices();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		resetDevices() {
			this.resetUserDevices(this.user).then(() => {
				console.log("User devices reset successfully");
				this.disableReset = true;
				this.$toasted.success(`User devices reset successfully!`, {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				// this.closeModal();
			});
		},
		handleUpdateData(data) {
			if (this.mode == 3) {
				this.deviceData = data;
			}
		},
		updateError(error) {
			this.isError = error;
		},
		showDropdown() {
			console.log("dropdown toggled");
			document.getElementById("change-password-input").focus();
		},
		hideDropdown() {
			document.getElementById("add-dropdown-btn").click();
		},
		passwordChange(e) {
			e.preventDefault();
			this.isChangePassword = true;
			this.newPassword = this.newPassword.trim();
			if (this.newPassword.length > 0 && !this.loading) {
				var changePasswordObj = {
					id: this.user.id,
					password: this.newPassword,
				};
				this.editUser(changePasswordObj).then(() => {
					this.newPassword = "";
					this.isChangePassword = false;
					this.$toasted.success("User's password changed successfully", {
						position: "bottom-center",
						duration: 3000,
						theme: "outline",
					});
				});

				// console.log("CreateCategory called");
			} else {
				document.getElementById("add-dropdown-btn").click();
			}

			// this.categoryTitle = "";
		},
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete the User: ${this.userData.firstName} ${this.userData.lastName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.userDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
	},
	props: {
		user: Object,
		openTab: Number,
		showModal: Boolean,
		viewOnly: { default: false, type: Boolean },
	},
	components: { UserDetails },
	computed: {
		...mapState("user", ["loading", "allUsers"]),
		visible() {
			return this.showModal;
		},
		tabComponent() {
			switch (this.currentTab) {
				case 0:
					return UserDetails;
				case 1:
					return UserEnrollments;
				case 2:
					return UserInvoicesAndPayments;
				case 3:
					return UserPayments;
				case 4:
					return UserDevices;
				case 5:
					return UserAttendance;
			}
		},
		disableSave() {
			if (this.isError) return this.isError;
			if ((this.mode == 1 || this.mode == 3) && !this.loading) {
				return false;
			}
			return true;
		},
		isUserActive() {
			return this.userData.status == UserStatus.ACTIVE;
		},
		userData() {
			if (this.user)
				return (
					this.allUsers?.find((user) => user.id == this.user.id) ?? this.user
				);
		},
	},
	watch: {
		visible: function () {
			if (this.visible) {
				this.fetchUserById(this.user.id);
				this.currentTab = this.openTab;
			}
		},
		currentTab: function () {
			this.mode = 0;
			// console.log(this.tabComponent.computed.UserDevices());
			// if (
			// 	this.tabComponent == UserDevices &&
			// 	this.tabComponent.computed.UserDevices &&
			// 	this.tabComponent.devices.length == 0
			// )
			// 	this.disableReset = true;
		},
	},
};
</script>

<style>
.view-edit-user-modal,
.add-enroll-modal,
.invoice-detail-modal,
.payment-detail-modal {
	min-height: 45.375rem;
	width: 70vw;
}

.view-edit-user-modal .modal-body,
.add-enroll-modal .modal-body,
.invoice-detail-modal .modal-body,
.payment-detail-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.confirm-modal-body {
	padding: 1rem !important;
	font-size: 1.125rem;
}

.view-edit-user-modal .body-container,
.add-enroll-modal .body-container,
.invoice-detail-modal .body-container,
.payment-detail-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 100%;
}

.view-edit-user-modal .add-enroll-title,
.add-enroll-modal .add-enroll-title,
.invoice-detail-modal .add-enroll-title,
.payment-detail-modal .add-enroll-title {
	text-transform: capitalize;
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

.view-edit-user-modal .main-label,
.add-enroll-modal .main-label,
.invoice-detail-modal .main-label,
.payment-detail-modal .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.view-edit-user-modal .form-input,
.add-enroll-modal .form-input,
.invoice-detail-modal .form-input,
.payment-detail-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.user-modal-tabs {
	border-bottom: 1px solid #dedede;
	/* box-shadow: 0 3px 6px #dedede; */
}

.user-tab {
	padding: 0 0 0.5rem !important;
	margin: 1rem 1rem 0 0;
	/* padding-bottom: 1rem; */
	border-bottom: 2px solid transparent;
	font-size: 1.125rem;
}

.user-tab.active {
	border-bottom: 2px solid var(--primary-color);
}

.user-modal-tab-body {
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
	max-height: 31rem;
	overflow-y: auto;
	margin-right: -1rem;
	padding-right: 1rem;
	margin-bottom: 1rem;
}

.add-btn.danger {
	color: var(--color-danger);
}

.change-password-input {
	height: 3.125rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.875rem 1rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
	padding-right: 5.25rem;
}
</style>
