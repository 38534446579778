<template>
	<div class="dropdown-right-panel-container">
		<a @click="resetCategory" type="button" v-if="isMobile" class="text-muted"
			><i class="fa fa-arrow-left"></i> Back</a
		>
		<div :class="['product-dropdown-content', { mobile: isMobile }]">
			<div v-if="dataLoaded">
				<div
					class="product-list"
					v-for="category in categoryData"
					:key="category.id"
				>
					<h1>{{ category.title }}</h1>
					<ul>
						<li v-for="item in 5" :key="item">
							<img
								class="product-list-image"
								src="../../../assets/images/sample-thumbnail.jpeg"
								alt=""
							/>
							<a role="button"
								>AFM - Advanced Financial Management (P4 - Advance Financial
								Management)</a
							>
						</li>
					</ul>
					<a role="button" class="view-all">View all {{ category.title }}</a>
				</div>
				<div
					v-if="
						productVariantsByCategory[activeCategory] &&
						filteredProductVariantsByCategory.length
					"
					class="product-list"
					id="products"
				>
					<ul>
						<li
							v-for="item in filteredProductVariantsByCategory"
							:key="item.id"
						>
							<img
								class="product-list-image"
								:src="
									item.thumbnailPath
										? item.thumbnailPath
										: require('@/assets/images/sample-thumbnail.jpeg')
								"
								alt=""
							/>
							<a role="button" @click="handleClick(productVariantURL(item))"
								>{{ item.product.title }} <br />
								{{ item.title }}
								<!-- <br /> -->
								<span
									class="coming-soon-pill"
									v-if="item.status == ProductVariantStatus.COMING_SOON"
									>{{ item.status }}</span
								>
								<!-- {{ item.creator.name }} -->
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div
			v-if="
				(categoryData &&
					categoryData.length == 0 &&
					!productVariantsByCategory[activeCategory] &&
					!loading) ||
				(filteredProductVariantsByCategory &&
					!filteredProductVariantsByCategory.length)
			"
			class="no-content d-flex align-items-center justify-content-center"
		>
			no courses found
		</div>
		<b-spinner v-if="loading" />
		<img
			class="more-courses-pointer"
			src="@/assets/images/more-courses.gif"
			v-if="showMoreCourses"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { parseForURL } from "../../../helpers/FormatHelpers";
import { ProductVariantStatus } from "../../../constants/productVariant";
export default {
	name: "ProductDropdownRightPanel",
	props: { categoryData: Array, activeCategory: String },
	data() {
		return {
			dataLoaded: false,
			showMoreCourses: false,
			ProductVariantStatus,
		};
	},
	computed: {
		...mapState("appState", ["isMobile"]),
		...mapState("productVariant", ["productVariantsByCategory", "loading"]),
		...mapState("user", ["user"]),
		filteredProductVariantsByCategory() {
			return (
				this.productVariantsByCategory[this.activeCategory] &&
				this.productVariantsByCategory[this.activeCategory].filter(
					(v) =>
						v.status.toLowerCase() != "draft" &&
						v.status.toLowerCase() != "private"
				)
			);
		},
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantByCategoryId"]),
		purchased(id) {
			if (
				this.user &&
				this.user.productVariants &&
				this.user.productVariants.length > 0
			) {
				var found = this.user.productVariants.find((p) => p.id == id);
				return found ? true : false;
			}
		},
		getProductDetailsUrl(id) {
			if (this.purchased(id)) {
				return "/product/content/";
			} else {
				return "/product/details/";
			}
		},
		handleClick(link) {
			// this.$emit("close-dropdown");
			if (!this.isMobile) $("#coursesMenu.courses-btn")[0].click();
			else $("#coursesMenu.hamburger")[0].click();
			this.$router.push(link);
		},
		resetCategory() {
			this.$emit("resetCategory");
		},
		productVariantURL(item) {
			return (
				this.getProductDetailsUrl(item.id) +
				`${parseForURL(item.product.friendlyTitle)}-${item.id}`
			);
		},
	},
	watch: {
		activeCategory: function () {
			// setTimeout(() => {
			// 	var isOverflow = $("#products")[0].scrollWidth / 16 > 81;
			// 	this.showMoreCourses = isOverflow;
			// 	// $("#products").on("scroll", function (e) {
			// 	// 	console.log("scroll", e);
			// 	// });
			// }, 300).bind(this);

			if (!this.productVariantsByCategory[this.activeCategory]) {
				this.dataLoaded = false;
				this.getProductVariantByCategoryId(this.activeCategory)
					.then(() => {
						console.log(
							"data",
							this.productVariantsByCategory[this.activeCategory]
						);
						this.dataLoaded = true;
					})
					.catch((err) => {
						console.error(err);
						this.$toasted.error(err.message, {
							icon: "exclamation-triangle",
							theme: "outline",
							duration: 3000,
							position: "bottom-center",
						});
					});
			} else {
				this.dataLoaded = true;
			}
		},
	},
	created() {
		if (!this.productVariantsByCategory[this.activeCategory]) {
			this.getProductVariantByCategoryId(this.activeCategory)
				.then(() => {
					console.log(
						"data",
						this.productVariantsByCategory[this.activeCategory]
					);
					this.dataLoaded = true;
				})
				.catch((err) => {
					this.$toasted.error(err.message, {
						icon: "exclamation-triangle",
						theme: "outline",
						duration: 3000,
						position: "bottom-center",
					});
				});
		} else {
			this.dataLoaded = true;
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
	.product-list ul {
		flex-wrap: nowrap !important;
	}

	.product-list ul li {
		max-width: unset !important;
	}
}

.product-dropdown-content {
	/* display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 1.875rem; */
	/* display: flex;
	flex-direction: column;
	max-height: calc(80vh - 3.5rem);
	flex-wrap: wrap; */
}

.product-dropdown-content.mobile {
	/* grid-template-rows: 1fr !important;
	grid-row-gap: 1.875rem !important;
	grid-auto-flow: row; */
	grid-template-columns: unset !important;
}
.product-list {
	margin: 0;
	/* margin-bottom: 2rem; */
}

.product-list h1 {
	color: #000000;
	font-weight: normal;
	padding-bottom: 1.25rem;
	font-size: 1.125rem;
	margin: 0;
	border-bottom: 1px solid #d0d0d0;
	margin-bottom: 1.25rem;
}

.product-list ul {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-bottom: 1rem;
	display: flex !important;
	flex-direction: column;
	max-height: calc(80vh - 3.5rem);
	flex-wrap: wrap;
	column-gap: 1rem;

	/* max-height: 45%; 
	overflow: auto; */
}

.product-list ul li {
	padding: 0.75rem 0;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 33%;
}

.product-list ul li a {
	color: #000000 !important;
	text-decoration: none;
}

.product-list-image {
	width: 2.5rem;
	height: 2.5rem;
	object-fit: cover;
	object-position: top;
	border-radius: 6px;
	margin-right: 1rem;
}

.view-all {
	color: #0090fa;
	font-size: 1rem;
}

.dropdown-right-panel-container {
	height: 100%;
}

.dropdown-right-panel-container .no-content {
	height: 100%;
	font-size: 1.5rem;
	color: #d0d0d0;
	font-weight: 500;
}

.more-courses-pointer {
	width: 2rem;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
}

.coming-soon-pill {
	color: var(--color-white);
	background-color: var(--primary-color);
	padding: 0.25rem 0.25rem;
	border-radius: 0.25rem;
	font-size: 0.75rem;
}
</style>
