<template>
	<b-modal
		id="add-enroll-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="add-enroll-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<div class="step1" v-if="currentStep == 1">
				<h1 class="add-enroll-title mb-5">
					Enroll Courses for {{ user.firstName }} {{ user.lastName }}
				</h1>
				<!-- <p class="mb-5">Select Courses to enroll (required)</p> -->
				<div v-if="productVariants.length > 0">
					<div class="d-flex align-items-center mb-2 gap-3">
						<div class="flex-grow-1 col-8">
							<label for="variant-search" class="main-label">Search</label>
							<div class="searchbar">
								<input
									type="search"
									autocomplete="off"
									id="variant-search"
									placeholder="Search by id or course/batch name"
									class="form-input"
									v-model="searchText"
								/>
								<a role="button"
									><img
										class="search-icon"
										src="@/assets/images/search-icon.svg"
								/></a>
							</div>
						</div>
						<div class="flex-grow-1 col-3">
							<label for="enrollmentType" class="main-label"
								>Enrollment Type</label
							>
							<v-select
								id="enrollmentType"
								class="form-input"
								v-model="enrollmentType"
								:reduce="(option) => option.value"
								:options="enrollmentTypeOptions"
								placeholder="Select enrollment type"
								:clearable="false"
							>
							</v-select>
						</div>
					</div>
					<button @click="toggleSelectAll" type="button" class="select-all">
						{{ selectBtnText }}
					</button>
					<form>
						<div class="product-variant-list">
							<EnrollmentCourseListItem
								v-for="item in filteredVariantsList"
								:key="item.id"
								:item="item"
								:enrollmentType="enrollmentType"
								:selectedVariants="selectedVariants"
								:directEnrollVariants="directEnrollVariants"
								@updateEnrollmentLists="updateEnrollments"
							/>
							<div
								class="no-content text-center"
								v-if="filteredVariantsList.length == 0"
							>
								No courses found!
							</div>
							<div v-if="loading && !filteredVariantsList"></div>
						</div>
					</form>
				</div>
			</div>
			<div class="step2" v-if="currentStep == 2">
				<h1 class="add-enroll-title">
					Enroll Courses for {{ user.firstName }} {{ user.lastName }}
				</h1>
				<!-- <p v-if="selectedVariants.length > 0" class="mb-5">
					Select Pricing Plans and Mark As Paid
				</p> -->
				<div v-if="selectedVariants.length > 0">
					<h4>Paid Courses</h4>
					<table id="product-variants">
						<tr class="head">
							<th>ID</th>
							<th>Course Name</th>
							<!-- <th>Trainer</th>
							<th>Price from</th>
							<th>Start Date</th> -->
							<th></th>
						</tr>
						<tbody>
							<div
								v-for="(productVariant, index) in selectedVariants"
								:key="index"
								class="product-variant-item"
							>
								<tr
									data-bs-toggle="collapse"
									:data-bs-target="`#variant-collapse-${productVariant.id}`"
									aria-expanded="true"
									class=""
								>
									<td>{{ productVariant.id }}</td>
									<td>
										{{ productVariant.product.title }} ({{
											productVariant.title
										}})
									</td>
									<td>
										<!-- {{ productVariant.creator.name }} -->
									</td>
									<td>
										<!-- {{
											currencyParser(
												productVariant.pricing.amount,
												productVariant.pricing.currency
											)
										}} -->
									</td>
									<td>
										<!-- {{ new Date(productVariant.startDate).toDateString() }} -->
									</td>
									<td>
										<img src="@/assets/images/caret-down.svg" />
									</td>
								</tr>
								<div
									class="collapse show ps-4 pe-4"
									:id="`variant-collapse-${productVariant.id}`"
								>
									<EnrollmentPricingPlans
										v-if="productVariant.pricingStatus.toLowerCase() != 'free'"
										:productVariantId="productVariant.id"
										:showDone="true"
										@invoiceDetailAdd="addInvoiceDetail"
										:ref="`plan-${productVariant.id}`"
										:user="user"
										@error="setError"
										parent="userenroll"
									/>
								</div>
							</div>
						</tbody>
					</table>
				</div>

				<div v-if="directEnrollVariants.length > 0">
					<h4 class="mt-4">Free/Direct Enrollment Courses</h4>
					<table id="product-variants" class="mt-2">
						<tr class="head">
							<th>ID</th>
							<th>Course Name</th>
							<!-- <th>Tutor</th>
							<th>Price from</th>
							<th>Start Date</th> -->
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tbody>
							<tr
								v-for="(productVariant, index) in directEnrollVariants"
								:key="index"
								class="product-variant-item"
							>
								<td>{{ productVariant.id }}</td>
								<td>
									{{ productVariant.product.title }} ({{
										productVariant.title
									}})
								</td>
								<td>
									<!-- {{ productVariant.creator.name }} -->
								</td>
								<td>
									<!-- {{ productVariant.pricingStatus }} -->
								</td>
								<td>
									<!-- {{
										productVariant.startDate
											? new Date(productVariant.startDate).toDateString()
											: "-"
									}} -->
								</td>
								<td>
									<!-- <img src="@/assets/images/caret-down.svg" /> -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<p v-if="isError()" class="text-danger">
				<i class="fa fa-exclamation-circle"></i> {{ errorText }}
			</p>
			<div class="step-btn-container">
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
					><img src="@/assets/images/arrow-right.svg" /> prev
				</a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="
						disableNext || loading || isCurrentLoading || checkInvoiceDetails() || isError()
					"
				>
					<b-spinner small v-if="(loading || isCurrentLoading)"></b-spinner>
					{{ stepButtonContent }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../../helpers/FormatHelpers";
import EnrollmentPricingPlans from "./EnrollmentPricingPlans.vue";
import { ProductVariantEnrollmentType } from "../../../constants/productVariant";
import { ProductVariantPricingStatus } from "../../../constants/pricing";
import EnrollmentCourseListItem from "./EnrollmentCourseListItem.vue";
import Vue from "vue";
export default {
	name: "AddUserEnrollmentModal",
	data() {
		return {
			searchText: "",
			selectedVariants: [],
			currentStep: 1,
			totalSteps: 2,
			invoiceDetails: [],
			directEnrollVariants: [],
			enrolledVariants: [],
			selectedPlan: {
				item: null,
				payments: null,
				planCycles: null,
			},
			errorText: "",
			// isError: false,
			errorsList: {},
			enrollmentType: "Custom",
			enrollmentTypeOptions: [
				{
					label: "Direct Enrollment",
					value: ProductVariantEnrollmentType.DIRECT_ENROLLMENT,
				},
				{
					label: "Pricing Plan",
					value: ProductVariantEnrollmentType.PRICING_PLAN,
				},
				{ label: "Custom", value: "Custom" },
			],
			isCurrentLoading:false,
			ProductVariantEnrollmentType,
			ProductVariantPricingStatus,
			
		};
	},
	methods: {
		...mapActions("productVariant", ["fetchAllProductVariants"]),
		...mapActions("pricing", [
			"createBulkInvoice",
			"fetchInvoiceByUserId",
			"fetchPaymentsByUserId",
		]),
		...mapActions("user", ["fetchUserById", "enrollUserInProductVariant"]),
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		toggleSelectAll() {
			if (
				this.filteredVariantsList.length &&
				(this.selectedVariants.length || this.directEnrollVariants.length)
			) {
				var availableVariants = this.filteredVariantsList.filter((variant) => {
					if (!this.isCourseEnded(variant) && !this.isDisabled(variant)) {
						return variant;
					}
				});

				if (
					availableVariants.length ==
					this.selectedVariants.length + this.directEnrollVariants.length
				) {
					this.selectedVariants = [];
					this.directEnrollVariants = [];
				} else if(this.enrollmentType == ProductVariantEnrollmentType.PRICING_PLAN) {
					this.directEnrollVariants = [];
					this.selectedVariants = availableVariants.filter(
						(v) => v.pricingStatus.toLowerCase() == "paid"
					);
				} else if(this.enrollmentType == ProductVariantEnrollmentType.DIRECT_ENROLLMENT) {
					this.selectedVariants = [];
					this.directEnrollVariants = availableVariants;
				} else {
					this.selectedVariants = availableVariants.filter(
						(v) => v.pricingStatus.toLowerCase() == "paid"
					);
					this.directEnrollVariants = availableVariants.filter(
						(v) => v.pricingStatus.toLowerCase() != "paid"
					);
				}
			}
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep -= 1;
				this.invoiceDetails = [];
				// this.errorsList = {};
				// this.errorText = "";
			}
		},
		async next() {
			if (this.currentStep < this.totalSteps) {
				this.currentStep += 1;
			} else {
				this.isCurrentLoading=true;
				Object.values(this.$refs).forEach((ref) => {
					console.log(ref);
					// this.getInvoicesAndPaymentData(ref);
					if (ref[0]) ref[0].emitInvoiceDetails();
				});

				if (
					this.selectedVariants &&
					this.selectedVariants.length > 0 &&
					!this.isError()
				)
					await this.generateInvoice();
				// console.log("generateInvoice", this.errorsList);

				if (
					this.directEnrollVariants &&
					this.directEnrollVariants.length > 0 &&
					!this.isError()
				)
					await this.directlyEnrollCourses();
				
					this.isCurrentLoading=false;
					
				// console.log("directlyEnrollCourses");
			}
		},
		currencyParser(amount, currency) {
			// console.log(formatter.toCurrencyString(amount, this.planCurrency, 0));
			return formatter.toCurrencyString(amount, currency, 0);
		},
		addInvoiceDetail(details) {
			console.log(details);
			details.forEach((detail) => {
				var found = this.invoiceDetails.find(
					(invoice) => invoice.planDetailID == detail.planDetailID
				);
				if (!found) {
					this.invoiceDetails = [...this.invoiceDetails, detail];
				} else {
					this.invoiceDetails = this.invoiceDetails.map((d) => {
						if (detail.planDetailID == d.planDetailID) {
							return detail;
						} else return d;
					});
				}
			});
		},
		generateInvoice() {
			this.isCurrentLoading=true;
			var invoice = {
				customerID: this.user.id,
				invoiceDate: new Date().toISOString(),
				currency: "PKR",
				status: "unpaid",
				details: this.invoiceDetails,
			};

			this.createBulkInvoice([invoice]).then((res) => {
				this.isCurrentLoading = false;
				if (res && res.length && res[0].id != 0) {
					this.fetchUserById(this.user.id);
					this.fetchInvoiceByUserId(this.user.id).then(() => {
						this.fetchPaymentsByUserId(this.user.id).then(() => {
							console.log(this.invoicesByUserId);
							this.closeModal();
						});
					});
				}else if (res && res.length && res.length>0) {
					this.closeModal();
				}
			});
		},
		directlyEnrollCourses() {

			this.directEnrollVariants.forEach((variant) => {
				this.isCurrentLoading=true;
				if (variant.endDate && new Date(variant.endDate) < new Date()) {
					this.errorsList[variant.id] = true;
					this.errorText =
						"You cannot enroll students in courses that have ended.";
					this.showErrorMessage(variant);
					return;
				} else {
					this.errorsList[variant.id] = false;
					this.errorText = "";
				}
				var requestObj = {
					userID: this.user.id,
					productVariantID: variant.id,
					allowedRanges: [
						{
							startsFrom: variant.startDate
								? variant.startDate
								: new Date("12/31/1970").toISOString(),
							endsAt: variant.endDate
								? variant.endDate
								: new Date("12/31/2070").toISOString(),
						},
					],
				};
				this.enrollUserInProductVariant({
					user: this.user,
					requestObj,
				}).then(() => {
					this.isCurrentLoading=false;
					console.log(
						`User ${this.user.id} enrolled in variant ${variant.id}.`
					);
					// this.disableEnroll = false;
					this.enrolledVariants.push(variant);
					if (
						this.enrolledVariants.length == this.directEnrollVariants.length
					) {
						this.isCurrentLoading=true;
						this.fetchUserById(this.user.id).then(() =>
						 this.closeModal()
						 );
					}
				});
			});
		},
		checkInvoiceDetails() {
			console.log(this.selectedVariants.length, this.invoiceDetails.length);
			if (this.currentStep == 2)
				if (this.selectedVariants && this.selectedVariants.length > 0) {
					if (this.selectedVariants.length > this.invoiceDetails.length) {
						return true;
					} else return false;
				} else return false;
		},
		setError({ text, status, id }) {
			this.errorText = text;
			this.errorsList[id] = status;

			var errorVariants = [];
			for (var e in this.errorsList) {
				if (this.errorsList[e] == true) {
					errorVariants.push(e);
				} else {
					errorVariants = errorVariants.filter((v) => v !== true);
				}
			}
			this.errorText =
				// this.errorText.concat(
				`Missing information in courses: ${errorVariants
					.toString()
					.replace(",", ", ")}`;
			// );
			// this.isError = status;
		},
		// getInvoicesAndPaymentData(ref) {
		// 	console.log(ref[0]);
		// 	if (ref[0].selectedPlan) {
		// 		this.selectedPlan.item = ref[0].selectedPlan;
		// 		if (this.selectedPlan.item.type == "OneTime") {
		// 			if (ref[0].isSinglePlanPaid) {
		// 				if (!ref.singlePlanReceived) {
		// 					this.errorText = "Please enter received amount";
		// 					this.isError = true;
		// 				} else if (!ref.paymentMethod) {
		// 					this.errorText = "Please select a payment method";
		// 					this.isError = true;
		// 				} else {
		// 					this.errorText = "";
		// 					this.isError = false;
		// 					var payments = {
		// 						paymentMethod: ref[0].paymentMethod,
		// 						paymentComments: ref[0].paymentComments,
		// 					};
		// 					payments[this.selectedPlan.item.details[0].id] = {
		// 						received: ref[0].singlePlanReceived,
		// 						discount: ref[0].singlePlanDiscount,
		// 					};
		// 					this.selectedPlan.payments = payments;
		// 				}
		// 			}
		// 		} else {
		// 			console.log(Object.keys(ref[0].multiplePlanPayments).length > 0);
		// 			if (Object.keys(ref[0].multiplePlanPayments).length > 0) {
		// 				if (!ref.paymentMethod) {
		// 					this.errorText = "Please select a payment method";
		// 					this.isError = true;
		// 				} else if (!ref.selectedCycles) {
		// 					this.errorText = "Please select plan cycles";
		// 					this.isError = true;
		// 				} else {
		// 					this.isError = false;
		// 					this.errorText = "";
		// 					this.selectedPlan.payments = ref[0].multiplePlanPayments;
		// 					this.selectedPlan.payments.paymentMethod = ref[0].paymentMethod;
		// 					this.selectedPlan.payments.paymentComments =
		// 						ref[0].paymentComments;
		// 				}
		// 			}
		// 			this.selectedPlan.planCycles = ref[0].selectedCycles;
		// 		}
		// 	}
		// },
		isError() {
			// console.log(
			// 	"isError",
			// 	Object.values(this.errorsList).find((e) => e == true)
			// );

			return Object.values(this.errorsList).find((e) => e == true)
				? Object.values(this.errorsList).find((e) => e == true)
				: false;
		},
		showErrorMessage(variant) {
			// e.preventDefault();
			this.$bvModal.msgBoxOk(
				`You cannot enroll students in courses that have ended. Course ended: ${variant.product.title} ${variant.title}`,
				{
					title: "Warning!",
					size: "md",
					buttonSize: "md",
					okTitle: "Ok",
					okVariant: "danger",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
				}
			);
		},
		updateEnrollments(variant, enrollmentType) {
			var found;
			switch (enrollmentType) {
				case ProductVariantEnrollmentType.PRICING_PLAN:
					found = this.selectedVariants.find((s) => s.id == variant.id);
					if (!found) {
						this.selectedVariants.push(variant);
					}
					found = this.directEnrollVariants.find((s) => s.id == variant.id);
					if (found) {
						this.directEnrollVariants = this.directEnrollVariants.filter(
							(dv) => dv.id != variant.id
						);
					}
					return;
				case ProductVariantEnrollmentType.DIRECT_ENROLLMENT:
					found = this.directEnrollVariants.find((s) => s.id == variant.id);
					if (!found) {
						this.directEnrollVariants.push(variant);
					}
					found = this.selectedVariants.find((s) => s.id == variant.id);
					if (found) {
						this.selectedVariants = this.selectedVariants.filter(
							(sv) => sv.id != variant.id
						);
					}
					return;
				case null:
					this.selectedVariants = this.selectedVariants.filter(
						(sv) => sv.id != variant.id
					);
					this.directEnrollVariants = this.directEnrollVariants.filter(
						(dv) => dv.id != variant.id
					);
					return;
			}
		},
		isCourseEnded(course) {
			return course.endDate && new Date(course.endDate) < new Date();
		},
		isDisabled(course) {
			return (
				this.enrollmentType == ProductVariantEnrollmentType.PRICING_PLAN &&
				course.pricingStatus == ProductVariantPricingStatus.FREE
			);
		},
	},
	computed: {
		...mapState("productVariant", ["productVariants"]),
		...mapState("pricing", ["invoicesByUserId", "loading"]),
		visible() {
			return this.showModal;
		},
		filteredVariantsList() {
			if (this.productVariants.length > 0) {
				var remainingCourses = Object.values(this.productVariants.items).filter(
					(variant) => {
						// var toOmit = this.enrolledCourses.find(
						// 	(v) => v.productVariantID == variant.id
						// );

            var toOmit = false;

						if (!toOmit) {
							return variant;
						}
					}
				);

				var result = remainingCourses.filter((variant) => {
					return (
						(variant.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(variant.id || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(variant.product &&
							(variant.product.title || "")
								.toLocaleLowerCase()
								.indexOf(this.searchText.toLocaleLowerCase()) > -1)
					);
				});
			}

			return result;
		},
		selectBtnText() {
			if (
				this.filteredVariantsList.length &&
				(this.selectedVariants.length || this.directEnrollVariants.length)
			) {
				var availableVariants = this.filteredVariantsList.filter((variant) => {
					if (!this.isCourseEnded(variant) && !this.isDisabled(variant)) {
						return variant;
					}
				});
				if (
					availableVariants.length ==
					this.selectedVariants.length + this.directEnrollVariants.length
				) {
					return "Deselect All";
				} else {
					return "Select All";
				}
			}
		},
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			} else return "Finish";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		disableNext() {
			if (this.currentStep == 1) {
				if (
					(this.selectedVariants && this.selectedVariants.length > 0) ||
					(this.directEnrollVariants && this.directEnrollVariants.length > 0)
				) {
					return false;
				} else {
					return true;
				}
			} else if (this.currentStep == 2) {
				return false;

				// if (this.planDetailItems.length < 2) {
				// 	return true;
				// } else return false;
			}
			return false;
		},
	},
	props: {
		showModal: Boolean,
		user: Object,
		enrolledCourses: Array,
	},
	components: {
		EnrollmentPricingPlans,
		EnrollmentCourseListItem,
	},
	watch: {
		directEnrollVariants: function () {
			var mergedVariants = [
				...this.directEnrollVariants,
				...this.selectedVariants,
			];
			console.log("directenrolll change");
			mergedVariants.forEach((variant) => {
				if (variant.endDate && new Date(variant.endDate) < new Date()) {
					this.errorsList[variant.id] = true;
					this.errorText =
						"You cannot enroll students in courses that have ended.";
					this.showErrorMessage(variant);
					return;
				}
			});
			Object.keys(this.errorsList).forEach((id) => {
				var isAddedForEnrollment = mergedVariants.find((v) => v.id == id);
				if (!isAddedForEnrollment) {
					this.errorsList[id] = false;
				}
				if (Object.keys(this.errorsList).length == 0) this.errorText = "";
			});
		},
		selectedVariants: function () {
			console.log("selectedVariants change");
			var mergedVariants = [
				...this.directEnrollVariants,
				...this.selectedVariants,
			];
			mergedVariants.forEach((variant) => {
				if (variant.endDate && new Date(variant.endDate) < new Date()) {
					this.errorsList[variant.id] = true;
					this.errorText =
						"You cannot enroll students in courses that have ended.";
					this.showErrorMessage(variant);
					return;
				}
			});
			Object.keys(this.errorsList).forEach((id) => {
				var isAddedForEnrollment = mergedVariants.find((v) => v.id == id);
				if (!isAddedForEnrollment) {
					this.errorsList[id] = false;
				}
				if (Object.keys(this.errorsList).length == 0) this.errorText = "";
			});
		},
		enrollmentType: function () {
			switch (this.enrollmentType) {
				case ProductVariantEnrollmentType.PRICING_PLAN:
					this.directEnrollVariants = [];
					return;
				case ProductVariantEnrollmentType.DIRECT_ENROLLMENT:
					this.selectedVariants = [];
					return;
			}
		},
	},
};
</script>

<style scoped>
.searchbar {
	/* flex-grow: 16; */
	font-size: 1rem;
	position: relative;
	/* max-width: 45.75rem; */
	/* margin-bottom: 1rem; */
}

.searchbar .search-icon {
	position: absolute;
	margin-right: 0.75rem;
	right: 0;
	width: 1.375rem;
	top: 0.25rem;
}

.product-variant-list {
	max-height: 22rem;
	overflow-y: auto;
	margin-bottom: 2rem;
}

.select-all {
	margin-left: auto;
	font-size: 1rem;
	color: var(--primary-color);
}

#product-variants {
	width: 100%;
	margin-top: 2rem;
	/* max-height: 20rem; */
}

#product-variants tbody {
	max-height: 1rem;
}

#product-variants tr,
.add-payment-cycle-btn {
	display: flex;
	flex-direction: row;
	padding: 0.75rem 1.125rem;
	background: #ffffff;
	border: 1px solid #d8d8d8;
	border-radius: 6px;
	margin-bottom: 1rem;
}

.add-payment-cycle-btn {
	width: 100%;
	justify-content: center;
	font-size: 1rem;
	column-gap: 0.5rem;
	align-items: center;
}

#product-variants tr td:nth-of-type(2),
#product-variants tr th:nth-of-type(2) {
	flex-basis: 42%;
	/* text-align: center; */
}

#product-variants tr td:last-of-type,
#product-variants tr th:last-of-type,
#product-variants tr td:first-of-type,
#product-variants tr th:first-of-type {
	flex-basis: 5%;
}

#product-variants tr.head {
	border: none;
	margin: 0;
}

#product-variants tr td,
#product-variants tr th {
	font-weight: 500;
	flex-shrink: 0;
	flex-basis: 17%;
	font-size: 1rem;
}

#product-variants tr th {
	text-transform: uppercase;
	color: #727272;
}

#product-variants tr td {
	color: #000000;
}

#product-variants .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	margin-right: 0.75rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: relative;
	right: 4rem;
}

#product-variants tr:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

#product-variants .right-menu-icon {
	width: 1.625rem;
	height: 1.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

#product-variants .right-menu-icon.delete {
	border-color: #ff0000;
}

#product-variants .right-menu-icon img {
	max-height: 0.75rem;
}

.product-variant-item .collapse.show {
	padding: 1.5rem;
	border: 1px solid #cbcbcb;
	border-top: 0;
	border-radius: 0 0 6px 6px;
	margin-bottom: 1rem;
}

.product-variant-item tr:not(.collapsed) {
	/* border-bottom: 0 !important; */
	margin-bottom: 0 !important;
	border-radius: 6px 6px 0 0 !important;
}

.product-variant-item {
	cursor: pointer;
}
</style>
