<template>
	<div class="login-webDisabled" v-if="showWebDisabledModel">
		<h1 class="heading-bold-20">Please download app for your device to access content.</h1>
		<ul class="download-btn-list">
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.AndroidAppUpdateUrl">
				<a :href="systemParameters.AndroidAppUpdateUrl" class="download android" target="_blank">
					<i class="fa fa-android fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Android</span>
				</a>
			</lI>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.IOSAppUpdateUrl">
				<a :href="systemParameters.IOSAppUpdateUrl" class="download apple" target="_blank">
					<i class="fa fa fa-apple fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">IOS / IPAD</span>
				</a>
			</li>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.WindowsAppUpdateURL">
				<a class="download windows" :href="systemParameters.WindowsAppUpdateURL" target="_blank">
					<i class="fa fa fa-windows fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Windows</span>
				</a>
			</li>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.MacAppUpdateURL">
				<a :href="systemParameters.MacAppUpdateURL" target="_blank" class="download apple">
					<i class="fa fa fa-apple fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Mac</span>
				</a>
			</li>
		</ul>
	</div>
	<div v-else-if="(content[contentId] || !loading)  && contentData  " :class="'chapter-content has-floating-menu '+(contentData.type == 'FreeText'?' freeTextContent ': contentData.type)" >

		<div v-if="contentData.type != 'FreeText' && contentData.type.toLowerCase() != 'quiz'" class="floating-menu" >
			<div class="floating-icons">
				<a @click="setBookmark()" :class="'float-icon bookmark-icon animate__animated '+(bookmarks[contentId]=='true'?' active animate__bounceIn':'')">
					<i :class="'fa fa-lg '+(bookmarks[contentId]=='true'?' fa-bookmark':' fa-bookmark-o')"></i>
				</a>
				<a role="button" @click="showCalculator = true" class="float-icon calculator-icon">
					<img src="../../assets/images/calculator-icon.svg">
				</a>
				<a role="button" v-if="isAllowDownloadStudyMaterial" @click="handleDownloadDocumentButtonClick"
					class="float-icon calculator-icon downloadfile" v-b-tooltip.hover.left :title="tooltipMessage"
          style="height: auto;">
					<i v-if="!isDownloading && !isDownloaded" class="fa fa-download"></i>
					<span v-else-if="isDownloading" class="downloading-progress">
            {{ downloadProgress }}%
            <b-progress :value="downloadProgress" :max="100" style="height: 0.25rem; margin-bottom: 0.25rem;"></b-progress>
          </span>
					<i v-else class="fa fa-check text-success"></i>
				</a>
			</div>
		</div>

		<Calculator 
			:visible="this.showCalculator" 
			@updateModalState="toggleCalculator" />

		<div class="chapter-content-left">

			<div v-if="contentData.type=='RecordedVideos'">
				<RecordedVideo :contentData="contentData"></RecordedVideo>
			</div>
			<div v-else-if="contentData.type=='StudyMaterial'" style="height: inherit;">
				<StudyMaterial :contentData="contentData"></StudyMaterial>
			</div>
			<div v-else-if="contentData.type=='Flashcards'" style="height: inherit;">
				<Flashcards :contentData="contentData"></Flashcards>
			</div>

			<div v-else-if="contentData.type=='FreeText'" style="height: inherit;">
				<FreeText 
					:isShowFloatingOptions="true" 
					:isBookmarked="bookmarks[contentId]=='true'"
					:contentData="contentData" 
					@setContentBookmarked="setBookmark"
					></FreeText>
			</div>
			<div v-else-if="contentData.type=='LiveClass'" style="height: inherit;">
				<LiveClass 
				:productVariantData="selectedProductVariant" 
				:contentData="contentData"
				></LiveClass>
			</div>
			<div v-else-if="contentData.type=='Exam'">
				<Exam 
				:contentData="contentData"
				></Exam>
			</div>
			<div v-else-if="contentData.type=='Assignment'">
				<Assignment 
				:contentData="contentData"
				></Assignment>
			</div>
			<div v-else-if="contentData.type.toLowerCase()=='quiz'">

				<Quiz 

				:contentData="contentData"
				></Quiz>
			</div>
			<div v-else>

			</div>
		</div>
	</div>
	<div v-else-if="( (loading || !contentData) && !content[contentId])"  class="loading-div">
		<b-spinner class="loader"> </b-spinner>
	</div>
</template>

<script>

import { default as download } from 'downloadjs';
import { mapState, mapActions, mapMutations } from "vuex";
import { contentService } from "./../../services/content.service";
import { ContentType } from "../../constants/content";
import RecordedVideo from "./Content/RecordedVideo.vue";
import StudyMaterial from "./Content/StudyMaterial";
import { LegacyExam } from "../../constants/exam";
import { getCookie } from "../../helpers/CookieHelpers";
import { ServiceCodes } from "../../constants/serviceCodes";
import Flashcards from "./Content/Flashcards.vue";
import FreeText from "./Content/FreeText.vue";
import Calculator from "./Calculator.vue"
import LiveClass from './Content/LiveClass.vue';
import Exam from './Content/Exam.vue';

import Assignment from "./Content/Assignment.vue";
import Quiz from "../../components/Content/Quiz.vue";
import streamSaver from 'streamsaver';
export default {
	name: "ProductContentView",
	data() {
		return {
			contentId: null,
			contentData: null,
			showWebDisabledModel: false,
			productVariantId:null,
			showCalculator: false,
			bookmarks:[],
			isDownloading: false,
			isDownloaded: false,
			downloadProgress: 0,
			tooltipMessage: 'Click to download',
		};
	},
	components: {
		RecordedVideo,
		StudyMaterial,
		Flashcards, 
		FreeText, 
		Calculator, 
		LiveClass, 
		Exam, Assignment,Quiz
	},
	methods: {
		...mapActions("content", ["getContentById","setUserContentAction","getUserContentAction"]),
		...mapActions("content", ["updateAction"]),
		async handleDownloadDocumentButtonClick(e) {
			if(!this.isAllowDownloadStudyMaterial){
				return;
			}
			if (this.isDownloading) {
				this.tooltipMessage = 'File is already downloading...';
				return;
			}

			this.isDownloading = true;
			this.downloadProgress = 0;
			this.tooltipMessage = 'Downloading...';

      const abortController = new AbortController(); // Create AbortController
      const signal = abortController.signal;

      let writer;
			try {
				const response = await fetch(this.contentData.data?.Source, { method: 'GET', signal });
				const totalLength = +response.headers.get('Content-Length') || 0;

				if (!response.ok) {
          console.error(`Failed to download: ${response.statusText}`)
          throw new Error(`Unable to fetch file. ${response.statusCode}`);
				}

				let fileName = this.contentData.data?.Source.split('/').pop();
        fileName = fileName.split('?')[0].split('#')[0];  // this will handle cases like "https://example.com/files/document.pdf?version=2#section2"

        const fileStream = streamSaver.createWriteStream(fileName);
				writer = fileStream.getWriter();
				const reader = response.body.getReader();
				let receivedLength = 0;

				while (true) {
					const { done, value } = await reader.read();
					if (done) break;

					receivedLength += value.length;
					this.downloadProgress = Math.floor((receivedLength / totalLength) * 100);
					try {
            await writer.write(value);
          } catch(writeError) {
            console.error('Error writing to stream', writeError);

            abortController.abort();

            this.isDownloading = false;
            this.isDownloaded = false;
            this.tooltipMessage = 'Download cancelled!';
            this.$toasted.error(
                `Download cancelled`,
                {
                  icon: "exclamation-triangle",
                  theme: "outline",
                  position: "bottom-right",
                  singleton: true,
                  duration: 5000
                }
            );
            break;
          }
				}

        if(this.isDownloading) {
          this.isDownloading = false;
          this.isDownloaded = true;
          this.downloadProgress = 100;
          this.tooltipMessage = 'Download complete!';
        }
			} catch (error) {
				console.error('Error while downloading:', error);

        abortController.abort();

				this.isDownloading = false;
				this.tooltipMessage = 'Download failed!';
				this.$toasted.error(
					`Download failed:  ${error.message}`,
					{
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-right",
						singleton: true,
            duration: 5000
					}
				);
			} finally {
        if(writer) {
          try {
            await writer.close();
          } catch (closeError) {
            console.warn('Failed to close the stream, stream might be already errored or closed:', closeError);
          }
        }
      }
		},
		getContent() {
			if (this.contentId ) {
				this.contentData=null;
				this.$nextTick(() => {
				if(this.content[this.contentId])
				{
					// if (this.content[this.contentId].type == ContentType.LIVE_CLASS) {
					// 	this.contentData = this.content[this.contentId];
					// 	var joinURLFromCookies = getCookie(`${this.contentData.id}_${this.user?.id}`);
					// 	if (joinURLFromCookies) {
					// 		window.location.href = joinURLFromCookies;
					// 		return;
					// 	}else
					// 	{
					// 		this.$router.push(this.contentLink);
					// 	}
					// }
					// else 
					window.document.title=this.content[this.contentId].title;
					if(this.content[this.contentId].type == ContentType.STUDY_MATERIAL && this.content[this.contentId].externalService.serviceProvider.toLowerCase()!="vss" && this.content[this.contentId].data){
						this.contentData = this.content[this.contentId];
						return;
					}	
					else if(this.content[this.contentId].type == ContentType.FLASHCARDS){
						this.contentData = this.content[this.contentId];
						return;
					}	
					else if(this.content[this.contentId].type == ContentType.LIVE_CLASS){
						this.contentData = this.content[this.contentId];
						return;
					}
					else if(this.content[this.contentId].type == ContentType.EXAM){
						this.contentData = this.content[this.contentId];
						return;
					}
					else if(this.content[this.contentId].type == ContentType.Assignment){
						this.contentData = this.content[this.contentId];
						return;
					}					
					else if(this.content[this.contentId].type == ContentType.RECORDED_VIDEOS && this.content[this.contentId].externalService && this.content[this.contentId].externalService.serviceProvider.toLowerCase()!='vss'){
						this.contentData = this.content[this.contentId];
						return;
					}	
				}
				var self=this;
				this.getContentById({
						id: this.contentId,
						extraParams: { Platform: contentService.getCurrentPlatform(), action: LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS, RedirectUrl: window.location.href },
					}).then((value) => {
						this.contentData = this.content[this.contentId];						
						window.document.title=this.content[this.contentId].title;
						this.processContent();
					});
				});
			}

		},
		
		processContent(){
			if (this.contentData.type == ContentType.RECORDED_VIDEOS || this.contentData.type == ContentType.STUDY_MATERIAL || this.contentData.type == ContentType.FLASHCARDS || this.contentData.type == ContentType.LIVE_CLASS || this.contentData.type == ContentType.EXAM) {
				return;
			}
			// else if (this.contentData.type == ContentType.EXAM) {
			// 	if (
			// 		this.contentData.externalService.serviceProvider ==
			// 		ServiceCodes.EXAM.V3ExamSystem
			// 	) {
			// 		if (content) {
			// 			var examAttemptUrl =
			// 				content.data.redirectUrl +
			// 				"&RedirectUrl=" +
			// 				window.location.href;
			// 			window.open(examAttemptUrl, "_blank");
			// 			// window.location.href = examAttemptUrl;
			// 			return;
			// 		}
					
			// 		return;
			// 	}
			// 	var data = res.data;
			// 		var examAttemptUrl = `http://examapp.gocbeglobal.com/exam/attempt?TestID=${
			// 			data.examID
			// 		}&Name=${data.name ?? ""}&Email=${data.email ?? ""}&UserID=${
			// 			data.userID ?? ""
			// 		}&RedirectUrl=${data.redirectUrl}&Signature=${data.signature}`;
			// 		window.open(examAttemptUrl, "_blank");
			// 		// window.location.href = examAttemptUrl;
			// 		return;				
				
			// }
			// else if (this.contentData.type == ContentType.LIVE_CLASS) {
			// 	var joinURLFromCookies = getCookie(`${this.item.id}_${this.user?.id}`);
			// 	if (joinURLFromCookies) {
			// 		window.location.href = joinURLFromCookies;
			// 		return;
			// 	}
			// 	this.$router.push(this.contentLink);
			// } 
			else this.$router.push(this.contentLink);
		},	
		setBookmark:function()
		{
			if(this.contentId)
			{ 
				var contentAction = {
					ContentID:this.contentId,
					DataType:"bookmark",
					Data:this.bookmarks[this.contentId]?(!(this.bookmarks[this.contentId]=="true"))+"":"true"
				}
				this.bookmarks[this.contentId]=this.bookmarks[this.contentId]?(!(this.bookmarks[this.contentId]=="true"))+"":"true";
				var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
				
				this.setUserContentAction(contentAction).then((action)=>{
					if(action && action.dataType  && action.dataType.toLowerCase()=="bookmark")
					{
						this.bookmarks[action.contentID] = action.data;
						var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
						this.updateAction(action);
					}
				});
			}
		},	
		getBookmark:function(){
			if(!this.bookmarks[this.contentId])
			{
				this.getUserContentAction({contentId:this.contentId, dataType:"bookmark"}).then((userActions)=>{
					var userAction = userActions?userActions.find(x=>x.dataType=="bookmark"):null;
					if(userAction)
					{
						this.bookmarks[userAction.contentID]=userAction.data;
						var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
					}
				});
			}
		},
		toggleCalculator(state) {
			if (state !== undefined) {
				this.showCalculator = state;
			} else {
				this.showCalculator = !this.showCalculator;
			}
		},
	},
	computed: {
		...mapState("content", ["content","loading","contentActions"]),		
		...mapState("user", ["loggedIn", "user"]),	
		...mapState("appState", ["isMobile", "systemParameters"]),
		...mapState("productVariant", ["productVariants"]),

		isDesktopApp() {
			return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
		},
		isWebDisabled() {
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled == '1';
		},		
		isAllowDownloadStudyMaterial(){
			return  (this.contentData && this.contentData.type==ContentType.STUDY_MATERIAL
			&& this.contentData.externalService && this.contentData.externalService.serviceProvider.toLowerCase()=='local' && 
			this.contentData.data && this.contentData.data?.AllowDownload?.toString().toLowerCase() == "true") ? true : false
		},

		selectedProductVariant(){
			return this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantId + ""];
		},
		contentLink() {
			switch (this.contentData.type) {
				// case ContentType.LIVE_CLASS:
				// 	return {
				// 		path:
				// 			"/content/liveclass/" +
				// 			this.contentData.title
				// 				.toLowerCase()
				// 				.replace(/[^a-zA-Z0-9 ]/g, "")
				// 				.split(" ")
				// 				.join("-") +
				// 			"-" +
				// 			this.contentData.id +
				// 			"_" +
				// 			this.productVariantId,
				// 	};
				// case ContentType.ASSIGNMENT:
				// 	return {
				// 		path:
				// 			"/content/assignment/" +
				// 			this.contentData.title
				// 				.toLowerCase()
				// 				.replace(/[^a-zA-Z0-9 ]/g, "")
				// 				.split(" ")
				// 				.join("-") +
				// 			"-" +
				// 			this.contentData.id +
				// 			"_" +
				// 			this.productVariantId,
				// 	};
			}
		}
	},

	watch: {
		$route:function(to,from){
			
			if (this.$route.query.contentId && this.$route.query.contentId!=this.contentId) {

				if(this.contentData && this.contentData.type==ContentType.RECORDED_VIDEOS)
				{	var oldCID=this.contentData.id;
					var self=this;
					this.getUserContentAction({contentId:oldCID}).then((userActions)=>{
					
					});
				}

				this.contentId = this.$route.query.contentId;
				this.getContent();
			}
			
			if (this.$route.query.tab && this.$route.query.tab!=this.currentTab) {
				var oldTab=this.currentTab??"";
				var newTab=this.$route.query.tab;
				if(oldTab.toLowerCase()=="bookmarks" && newTab.toLowerCase()=="tableofcontents")
				{
					this.bookmarks[this.contentId]=null;
					this.getBookmark();
				}
				this.currentTab=newTab;
			}
		},
		contentId:function(){
			this.getBookmark();
		
		},
		contentActions:function(){
		}
	},
	created() {
		if (!this.isDesktopApp && this.isWebDisabled) {
			this.showWebDisabledModel = true;
			return;
		}
		if (this.$route.query.contentId) {
			this.contentId = this.$route.query.contentId;
			this.getContent();
		}
		this.productVariantId=this.$route.params.id;
	},
	mounted() {
	}
};
</script>

<style scoped>
.chapter-content{
	padding: 20px;
	height: inherit; 
	overflow: auto;
  }
  .chapter-content.has-floating-menu{
	position: relative;
  }
  .chapter-content.freeTextContent 
  {
	/* padding-right: 0px; */
  }
  .chapter-content.Flashcards,
  .chapter-content.RecordedVideos,
  .chapter-content.Quiz,
  .chapter-content.StudyMaterial{
	padding:0;
  }


  .chapter-content.freeTextContent .chapter-content-left
  {
	/* padding-right: 0px; */
  }
  .chapter-content.Quiz .chapter-content-left
  {
	padding: 0px;
	height: 100%;
  }
  .chapter-content.Quiz .chapter-content-left div
  {
	height: 100%;
  }
  
  .StudyMaterial .chapter-content-left{
	height: 100%;
  }

.loading-div {
	width: 100%;
	height: 50vh;
}

.loading-div .loader {
	top: 50%;
	left: 50%;
	position: absolute;
}

.login-webDisabled {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
	padding: 0 7% 0;
}

.download-btn-list {
	text-align: center;
	margin: 0;
	padding: 0;
	display: inline;
}

.download-btn-list-item {
	display: inline;
}

.download-btn-list-item a {
	margin: 10px;
}

.download {
	width: 200px;
	height: 70px;
	background: black;
	float: left;
	border-radius: 5px;
	position: relative;
	color: #fff;
	cursor: pointer;
	border: 1px solid #fff;
}

.download>.fa {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
}

.df,
.dfn {
	position: absolute;
	left: 60px;
	width: 50%;
}

.df {
	top: 16px;
	font-size: 12px;
}

.dfn {
	top: 28px;
	font-size: 18px;
}

.download:hover {
	-webkit-filter: invert(100%);
	filter: invert(100%);
}
.downloadfile{
	text-decoration: none;
}
.downloading-progress{
	font-size: 12px;
}
@media (max-width: 768px){
	.chapter-content{
		padding: 15px;
	}
	.chapter-content.Assignment{
		padding: 0;
	}
}
</style>
<style>
.chapter-content.Quiz .chapter-content-left .MainQuiz-Box
  {
	height: 100% !important; 
	display: flex;
	flex-direction: column;
	overflow: hidden;
  }
  .chapter-content.Quiz .chapter-content-left .MainQuiz-Box .center-section
  {
	flex: 1;
  }
  
  .chapter-content.Quiz .chapter-content-left .div-box
  {
	height: 100%;
  }
 
  </style>