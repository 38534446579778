<template>
	<b-modal
		id="invoice-detail-modal"
		@change="$emit('updateModalState')"
		:visible="visible"
		hide-header
		centered
		content-class="invoice-detail-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<div class="d-flex justify-content-between align-items-end">
				<div>
					<h1 v-if="invoice" class="add-enroll-title">
						Invoice # {{ invoice.id }}
					</h1>
					<div v-if="invoice" class="d-flex align-items-center gap-3 mb-4">
						<div
							:class="[
								'status-tag',
								{
									success: invoice.status == InvoiceStatus.PAID,
									danger:
										invoice.status == InvoiceStatus.UNPAID ||
										invoice.status == InvoiceStatus.OVER_PAID,
									warning: invoice.status == InvoiceStatus.PARTIALLY_PAID,
								},
							]"
						>
							{{ invoice.status }}
						</div>
						<span>{{ user.firstName }} {{ user.lastName }}</span>
					</div>
				</div>
				<div class="d-flex gap-3">
					<button
						class="add-btn"
						data-bs-toggle="dropdown"
						:disabled="mode != 'view'"
						v-if="invoice && invoice.status != InvoiceStatus.PAID"
					>
						<img
							class="add-btn-icon"
							src="@/assets/images/add-border-icon.svg"
						/>
						Add payment
					</button>
					<ul
						v-if="externalServicesList['PaymentGateway']"
						class="dropdown-menu dropdown-menu-end"
					>
						<li class="dropdown-item disabled">Select Payment Method:</li>
						<li
							class="dropdown-item cursor-pointer"
							v-for="method in externalServicesList['PaymentGateway']"
							:key="method.serviceCode"
							@click="addPayment($event)"
							:id="method.serviceCode"
						>
							{{ method.serviceName }}
						</li>
					</ul>
					<button
						class="add-btn"
						data-bs-toggle="dropdown"
						:disabled="mode != 'view'"
						v-if="invoice && invoice.status != InvoiceStatus.UNPAID"
					>
						<img
							class="add-btn-icon"
							src="@/assets/images/add-border-icon.svg"
						/>
						Add refund
					</button>
					<ul
						v-if="externalServicesList['PaymentGateway']"
						class="dropdown-menu dropdown-menu-end"
					>
						<li class="dropdown-item disabled">Select Payment Method:</li>
						<li
							class="dropdown-item cursor-pointer"
							v-for="method in externalServicesList['PaymentGateway']"
							:key="method.serviceCode"
							@click="addRefund($event)"
							:id="method.serviceCode"
						>
							{{ method.serviceName }}
						</li>
					</ul>
					<button
						class="add-btn"
						:disabled="mode != 'view'"
						@click="changeMode('edit')"
					>
						<img class="add-btn-icon" src="@/assets/images/edit-icon.svg" />
						Edit Invoice
					</button>
					<button
						class="add-btn"
						:disabled="
							mode != 'view' ||
							(invoice && invoice.status != InvoiceStatus.UNPAID)
						"
						@click="showDeleteConfirmPopup($event, invoice)"
						:title="
							invoice && invoice.status != InvoiceStatus.UNPAID
								? 'Paid invoice cannot be deleted'
								: ''
						"
					>
						<img class="add-btn-icon" src="@/assets/images/delete-icon.svg" />
						Delete Invoice
					</button>
				</div>
			</div>
			<!-- <div>
				<label for="invoice-details" class="main-label"> Edit Invoice </label> -->
			<div class="inline-group mt-5">
				<div v-if="mode != 'pay'" class="form-group col">
					<label for="invoiceDate" class="main-label">Invoice Date</label>
					<input
						type="date"
						class="form-input"
						name="invoiceDate"
						id="invoiceDate"
						v-model="invoiceDate"
						:readonly="true"
						@change="isChanged = true"
						:disabled="mode == 'edit'"
					/>
				</div>
				<div class="form-group col" v-else>
					<label for="paymentDate" class="main-label">Payment Date</label>
					<input
						type="date"
						class="form-input"
						name="paymentDate"
						id="paymentDate"
						v-model="paymentDate"
						@change="isChanged = true"
						:disabled="mode == 'edit'"
					/>
				</div>
				<div class="form-group col">
					<label for="invoiceDate" class="main-label">Currency</label>
					<v-select
						class="form-input"
						placeholder="Select currency"
						v-model="currency"
						name="currency"
						id="currency"
						:options="Object.values(currencyCodes)"
						label="code"
						:reduce="(currency) => currency.code"
						:filterBy="currencyFilterBy"
						:appendToBody="true"
						:calculate-position="withPopper"
						:autoscroll="true"
						:disabled="true"
						@input="isChanged = true"
					>
						<template v-slot:option="option">
							<span></span>
							{{ option.code }} - {{ option.name }} ({{ option.symbol_native }})
						</template>
					</v-select>
				</div>
				<!-- </div> -->
			</div>

			<label for="invoice-details" class="main-label"> Invoice details </label>
			<table class="table table-borderless enrolled-users-table">
				<thead>
					<tr class="d-flex">
						<th
							v-if="mode != 'view' && mode != 'edit'"
							:class="['col-1', { shrink: arePaymentsAdded() }]"
						>
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll()"
							/>
						</th>
						<th
							:class="[
								'col-1',
								{ shrink: arePaymentsAdded() || mode != 'view' },
							]"
						>
							ID
						</th>
						<th class="col">Course Name</th>
						<th v-if="mode == 'view'" class="col">Plan</th>
						<th class="col">Invoice Amount</th>
						<th class="col">Due Date</th>
						<th v-if="arePaymentsAdded()" class="col">Outstanding Amount</th>
						<th class="col" v-if="mode != 'edit'">Previously Paid</th>
						<th class="col">Discount</th>
						<!-- <th class="col" v-if="mode == 'edit' || mode == 'pay'">
							Discount Type
						</th> -->
						<th class="col" v-if="mode == 'view'">Status</th>
						<th v-if="arePaymentsAdded()" class="col">
							Current {{ mode == "pay" ? "Payment" : "Refund" }}
						</th>
						<th></th>
						<!-- <th v-if="mode == 'pay'" class="col">Payment Mode</th> -->
					</tr>
				</thead>
				<tbody v-if="invoice && invoice.details.length > 0">
					<InvoiceDetailItem
						v-for="detail in invoice.details"
						:key="detail.id"
						:invoiceDetail="detail"
						:selectedDetails="selectedDetails"
						@toggleSelect="toggleInvoiceSelect"
						:arePaymentsAdded="arePaymentsAdded()"
						:currency="invoice.currency"
						@updateAmount="updatePayment"
						@updateDiscount="discountUpdate"
						@updateError="error"
						@updateDueDate="dueDateUpdate"
						:mode="mode"
						:error="isError"
						:invoiceId="invoice.id"
						:userId="user.id"
					/>
				</tbody>
				<div v-else class="no-content">No Invoices found</div>
			</table>
			<p v-if="isError" class="text-danger">
				<i class="fas fa-exclamation-circle"></i>
				<span v-if="errorText != ''">
					{{ errorText }}
				</span>
				<span v-else>
					{{ mode == "pay" ? "Paid" : "Refund" }} amount cannot be greater than
					{{ mode == "pay" ? "course price" : "paid amount" }}
				</span>
			</p>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					cancel
				</button>
				<button
					:disabled="disableSave()"
					@click="
						mode == 'pay' || mode == 'refund' ? payInvoice() : invoiceEdit()
					"
					role="button"
					class="step-btn"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ mode == "pay" ? "pay" : mode == "refund" ? "refund" : "save" }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InvoiceDetailItem from "./InvoiceDetailItem.vue";
import { currencyCodes } from "../../../constants/currencyCodes";
import { createPopper } from "@popperjs/core";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import { InvoiceStatus } from "../../../constants/pricing";

export default {
	name: "UserInvoiceDetailModal",
	props: {
		user: Object,
		invoice: Object,
		invoiceMode: String,
		showModal: Boolean,
	},
	components: {
		InvoiceDetailItem,
	},
	data() {
		return {
			selectedDetails: [],
			mode: "view",
			isError: false,
			method: null,
			payments: {},
			currencyCodes,
			currency: null,
			invoiceDate: null,
			paymentDate: getInputDateFormat(new Date()),
			isChanged: false,
			errorText: "",
			InvoiceStatus,
		};
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		...mapState("pricing", ["paymentsByUserId", "loading"]),
		visible() {
			return this.showModal;
		},
		isInvoiceFullDiscount() {
			var fullInvoicePaidAmount = 0;
			this.invoice.details.forEach((detail) => {
				fullInvoicePaidAmount += detail.paidAmount;
			});
			return (
				fullInvoicePaidAmount == 0 && this.invoice.status == InvoiceStatus.PAID
			);
		},
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
		...mapActions("pricing", [
			"createPayment",
			"updateInvoice",
			"deleteInvoice",
			"fetchInvoiceByUserId",
		]),
		closeModal() {
			this.$emit("updateModalState");
			Object.assign(this.$data, this.$options.data.call(this));
		},
		toggleSelectAll() {
			if (
				this.invoice.details &&
				this.invoice.details.length > 0 &&
				this.selectedDetails.length != this.invoice.details.length
			) {
				this.selectedDetails = this.invoice.details;
			} else {
				this.selectedDetails = [];
			}
		},
		isSelectedAll() {
			if (this.invoice && this.invoice.details) {
				return this.invoice.details.length == this.selectedDetails.length;
			}
		},

		addPayment(e) {
			console.log("change mode", this.mode == "view");
			if (this.mode == "view") {
				this.mode = "pay";
				this.method = this.externalServicesList["PaymentGateway"].find(
					(service) => service.serviceCode == e.target.id
				);

				this.invoice.details.forEach((detail) => {
					var payment = {
						amount: null,
						type: "Received",
					};
					payment.invoiceDetailID = detail.id.toString();
					this.payments[detail.id] = payment;
				});
			}
		},
		addRefund(e) {
			// console.log("change mode", this.mode == "view");
			if (this.mode == "view") {
				this.mode = "refund";
				this.method = this.externalServicesList["PaymentGateway"].find(
					(service) => service.serviceCode == e.target.id
				);

				this.invoice.details.forEach((detail) => {
					var payment = {
						amount: null,
						type: "Refunded",
					};
					payment.invoiceDetailID = detail.id.toString();
					this.payments[detail.id] = payment;
				});
			}
		},
		changeMode(mode) {
			this.mode = mode;
		},

		dueDateUpdate(dueDate, id) {
			console.log("Due Date update");
			var updatedDetails = this.selectedDetails.map((detail) => {
				if (detail.id == id) {
					dueDate = new Date(dueDate).setHours(23, 59, 59);
					detail.dueDate = new Date(dueDate).toISOString();
				}
				return detail;
			});
			this.selectedDetails = updatedDetails;
			this.isChanged = true;
		},

		invoiceEdit() {
			var updateObject = {
				...this.invoice,
				details: this.selectedDetails,
				invoiceDate: this.invoiceDate,
				currency: this.currency,
			};

			this.updateInvoice(updateObject).then(() => {
				console.log("Invoice updated successfully.");
				if (this.mode != "pay" && this.mode != "refund") this.closeModal();
			});
		},
		isDisabled(id) {
			var found = this.selectedDetails.find((d) => d.id == id);
			return !found;
		},
		disableSave() {
			return (
				this.isError ||
				(this.selectedDetails.length == 0 && this.mode != "edit") ||
				this.loading ||
				(!this.isChanged && this.mode == "edit")
			);
		},
		updatePayment(amount, id) {
			this.payments[id].amount = parseFloat(amount);
		},
		discountUpdate(amount, type, id) {
			console.log("Discount update");
			var updatedDetails = this.selectedDetails.map((detail) => {
				if (detail.id == id) {
					detail.discount = parseFloat(amount);
					detail.discountType = type;
				}
				return detail;
			});
			this.selectedDetails = updatedDetails;
			this.isChanged = true;
		},
		arePaymentsAdded() {
			var payment = Object.keys(this.payments);
			// console.log(payment.length);
			return payment.length > 0;
		},
		toggleInvoiceSelect(invoice) {
			// this.$emit("toggleSelect", invoice);
			var found = this.selectedDetails.find((s) => s.id == invoice.id);
			// console.log(found, invoice);
			if (!found) {
				this.selectedDetails.push(invoice);
			} else {
				this.selectedDetails = this.selectedDetails.filter(
					(su) => su.id != invoice.id
				);
			}
		},
		error(error, errorText) {
			this.isError = error;
			if (errorText != null) this.errorText = errorText;
		},
		async payInvoice() {
			var res1 = null;
			var res2 = null;
			if (this.isChanged) {
				res1 = await this.invoiceEdit();
			}
			var receivedPayments = Object.values(this.payments).filter(
				(payment) => payment.amount != null
			);

			var payment = {
				customerID: this.user.id,
				paymentDate: new Date(this.paymentDate).toISOString(),
				method: this.method.serviceName,
				methodProvider: this.method.serviceProvider,
				methodProviderID: this.method.serviceCode,
				details: receivedPayments,
				status: "Completed",
				metaData: "{}",
			};
			console.log(payment);
			res2 = await this.createPayment(payment).then(async () => {
				console.log(
					"payment made successfully",
					this.paymentsByUserId[this.user.id]
				);
				await this.fetchInvoiceByUserId(this.user.id).then(() => {});
			});
			Promise.all([res1, res2]).then(() => {
				this.closeModal();
			});
		},
		showDeleteConfirmPopup(e, invoice) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete invoice ID: ${invoice.id} for ${this.user.firstName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.invoiceDelete(invoice);
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		invoiceDelete(invoice) {
			this.deleteInvoice(invoice).then((response) => {
				console.log("invoice deleted");
				this.closeModal();
			});
		},
		currencyFilterBy(option, label, search) {
			var result =
				(option.code || "")
					.toLocaleLowerCase()
					.indexOf(search.toLocaleLowerCase()) > -1 ||
				(option.name || "")
					.toLocaleLowerCase()
					.indexOf(search.toLocaleLowerCase()) > -1;

			return result;
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
	},
	created() {
		this.mode = this.invoiceMode;
		if (!this.externalServicesList["PaymentGateway"]) {
			this.fetchExternalServices("PaymentGateway").then(() => {
				console.log(" payment modes fetched");
				// this.paymentMethods = this.externalServicesList["PaymentGateway"];
			});
		} else {
			// this.paymentMethods = this.externalServicesList["PaymentGateway"];
		}
	},
	watch: {
		mode: function () {
			if (this.mode == "pay") {
				console.log("mode changed");
			}
			// if (this.mode == "edit") {
			// }
		},
		visible: function () {
			this.currency = this.invoice.currency;
			this.invoiceDate = getInputDateFormat(this.invoice.invoiceDate);
		},
	},
};
</script>

<style>
.status-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
	height: fit-content;
}

.status-tag.success {
	background-color: #00c672;
}

.status-tag.danger {
	background-color: var(--color-danger);
}

.status-tag.warning {
	background-color: var(--color-warning);
}

.invoice-detail-modal input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.enrolled-users-table td {
	min-height: 4rem;
	height: unset;
	vertical-align: middle;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.add-btn::after {
	display: none !important;
}

.cursor-pointer {
	cursor: pointer;
}

th.shrink {
	width: fit-content !important;
	padding-right: 1rem !important;
}
</style>
