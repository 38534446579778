<template>
  <BannerSmall>
    <div
      :class="[
        'product-page-banner-container',
        { purchased, mobile: isMobile, 'col-lg-8 col-md-12': !isMobile },
      ]"
    >
      <ol
        v-if="(purchased || isMobile) && productVariantData != null"
        class="breadcrumb"
        :class="{ mobile: isMobile }"
      >
        <li class="breadcrumb-item">Courses</li>
        <li class="breadcrumb-item">
          {{ productVariantData.product.title }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ productVariantData.title }}
        </li>
      </ol>
      <div
        v-if="!loading && productVariantData != null && !purchased"
        class="product-image mobile-only"
      >
        <img
          :src="`${thumbnailPath}`"
          :alt="productVariantData.product.title"
        />
        <i v-if="videoPreviewUrl" class="fa fa-play-circle video-thumbnail-icon" @click="openThumbnailPreview"></i>
      </div>
      <div
        :class="[{ 'product-title-card': purchased }]"
        v-if="!loading && productVariantData != null"
      >
        <h2
          v-if="
            !purchased &&
            productVariantTrainers &&
            productVariantTrainers.length > 0
          "
          class="product-creator"
          v-b-tooltip.hover
          :title="
            productVariantTrainers
              .map((p) => {
                return p.firstName + ' ' + p.lastName;
              })
              .toString()
              .split(',')
              .join(',\n')
          "
        >
          {{
            productVariantTrainers[0].firstName +
            " " +
            productVariantTrainers[0].lastName
          }}
          <span class="text-muted" v-if="productVariantTrainers.length > 1"
            >+ {{ productVariantTrainers.length - 1 }} trainer{{
              productVariantTrainers.length > 2 ? "s" : ""
            }}</span
          >
          <!-- <span class="seller-badge mobile-only"> Best Seller </span> -->
        </h2>
        <h1 :class="['product-title', { purchased }]">
          {{ productVariantData.product.title }}
        </h1>
        <p :class="['product-category text-bold', { purchased }]">
          {{ productVariantData.title }}
        </p>
        <div class="d-flex align-items-center gap-2">
          <h2
            v-b-tooltip.hover
            v-if="
              purchased &&
              productVariantTrainers &&
              productVariantTrainers.length > 0
            "
            class="product-creator purchased"
            :title="
              productVariantTrainers
                .map((p) => {
                  return p.firstName + ' ' + p.lastName;
                })
                .toString()
                .split(',')
                .join(',\n')
            "
          >
            {{
              productVariantTrainers[0].firstName +
              " " +
              productVariantTrainers[0].lastName
            }}
            <span class="text-muted" v-if="productVariantTrainers.length > 1"
              >+ {{ productVariantTrainers.length - 1 }} trainer{{
                productVariantTrainers.length > 2 ? "s" : ""
              }}</span
            >
          </h2>
          <span
            v-if="
              purchased &&
              productVariantTrainers &&
              productVariantTrainers.length > 0
            "
            >|</span
          >
          <span>{{
            productVariantData.shortDescription
              ? productVariantData.shortDescription
              : ""
          }}</span>
        </div>
        <div v-if="!purchased" class="top-seller-tag">
          <!-- <span class="seller-badge hide-mobile"> Best Seller </span> -->
          <div class="d-flex" v-if="productVariantData.ratings.value">
            <span class="ratings-value">{{
              productVariantData.ratings.value
                ? parseInt(productVariantData.ratings.value).toFixed(1)
                : ""
            }}</span>

            <b-form-rating
              :value="productVariantData.ratings.value"
              readonly
            ></b-form-rating>
          </div>
          <div v-else>&nbsp;</div>

          <!-- <span class="ratings-number"
						>({{ productVariantData.ratings.numberOfRatings }} ratings)</span
					> -->
          <!-- <span class="enrolled-students"
						>{{
							numberWithCommas(productVariantData.enrolledStudents)
						}}
						students</span
					> -->
        </div>       
      </div>
      <div v-else class="skeleton">
        <b-skeleton width="20%"></b-skeleton>
        <b-skeleton type="button" width="50%" class="mt-3"></b-skeleton>
        <b-skeleton width="30%" class="mt-3"></b-skeleton>
        <div class="d-flex mt-3">
          <b-skeleton width="10%"></b-skeleton>
          <b-skeleton width="10%" class="m-2 mt-0"></b-skeleton>
          <b-skeleton width="10%" class="m-2 mt-0"></b-skeleton>
          <b-skeleton width="10%" class="m-2 mt-0"></b-skeleton>
        </div>
      </div>
    </div>
    <b-modal
        :visible="showVideoPreview"
        centered
        hide-footer
        hide-header
        @change="closeVideoPreviewModal()"
        size="xl"
        body-class="video-modal-body"
        modal-class="video-modal"
    >
      <div class="body-container">
        <video :src="videoPreviewUrl" class="video-preview" controls autoplay></video>
      </div>
    </b-modal>
  </BannerSmall>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BannerSmall from "../Layout/BannerSmall.vue";

export default {
  name: "ProductPageBanner",
  props: { purchased: Boolean, productVariantData: Object },
  data() {
    return {
      showVideoPreview: false
    };
  },
  computed: {
    ...mapState("productVariant", ["loading"]),
    ...mapState("appState", ["isMobile"]),
    ...mapState("user", ["trainers"]),
    thumbnailPath() {
      if (this.isThumbnailError) {
        console.log("thumbnail error");
        return require(`@/assets/images/image-error.png`);
      } else {
        if (this.productVariantData.thumbnailPath) {
          var path = !this.productVariantData.thumbnailPath.includes("http")
            ? require(`@/${this.productVariantData.product.thumbnailPath}`)
            : this.productVariantData.thumbnailPath;
          return path;
        } else
          return require(`@/${this.productVariantData.product.thumbnailPath}`);
      }
    },
    productVariantTrainers() {
      // productTrainersList() {
      var trainerList = [];
      if (
        this.productVariantData.properties &&
        this.productVariantData.properties.trainers
      ) {
        if (this.trainers) {
          var list = JSON.parse(this.productVariantData.properties.trainers);
          // console.log(list);
          list.forEach((trainerID) => {
            var found = this.trainers.find((t) => t.id == trainerID);
            if (found) {
              trainerList.push(found);
            }
          });
        } else {
          this.fetchTrainersList().then(() => {
            var list = JSON.parse(this.productVariantData.properties.trainers);
            // console.log(list);
            list.forEach((trainerID) => {
              var found = this.trainers.find((t) => t.id == trainerID);
              if (found) {
                trainerList.push(found);
              }
            });
          });
        }
      }
      // console.log(trainerList);
      return trainerList;
    },
    videoPreviewUrl() {
      return this.productVariantData.properties.videoPreviewUrl;
    },
  },

  components: { BannerSmall },
  methods: {
    ...mapActions("user", ["fetchUsersList","fetchTrainersList"]),
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openThumbnailPreview() {
      this.showVideoPreview = true;
    },
    closeVideoPreviewModal() {
      this.showVideoPreview = false;
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 992px) {
  h2.product-creator {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem !important;
    display: flex;
    align-items: center !important;
  }

  h1.product-title {
    font-size: 2rem !important;
  }

  p {
    font-size: 1.2rem !important;
    margin-bottom: 0.5rem !important;
  }

  .seller-badge {
    margin-left: 1rem !important;
  }

  .product-details-container {
    display: none;
  }

  .banner-container .wrapper {
    flex-direction: column;
  }

  .skeleton {
    width: 100%;
  }

  .product-page-banner-container.purchased {
    padding: 1.75rem 0.25rem !important;
  }

  .product-title-card {
    padding: 2rem 1rem 0.5rem 1rem !important;
  }
}

.product-page-banner-container {
  padding: 1.75rem 0 3rem;
}

.product-page-banner-container.purchased {
  padding: 1.5rem 0 4rem;
  position: relative;
  /* margin-bottom: 9rem; */
}

.product-page-banner-container.mobile {
  width: 100%;
  padding: 1.75rem 0.25rem 3rem;
}

.banner-content-container {
  padding: 4.5rem 1.25rem 12rem;
}

.banner-content-container.purchased {
  padding-bottom: 4rem !important;
}

.product-creator {
  color: var(--secondary-color);
  font-size: 1.375rem;
  margin-bottom: 0.25rem;
}

.product-creator.purchased {
  /* display: inline-block; */
  color: var(--secondary-color);
  font-size: 1rem;
  margin-top: 0.5rem;
  /* margin-left: 1rem; */
}

h1.product-title {
  font-weight: 500;
  font-size: 2.125rem;
  color: var(--primary-color);
  letter-spacing: -0.58px;
  /* line-height: 1; */
}

.product-title-card h1.product-title.purchased {
  font-weight: bold;
  font-size: 1.875rem;
  color: #000000;
  letter-spacing: -0.58px;
  /* line-height: 1; */
}

.product-title-card h1.product-title.purchased::before {
  content: "";
  width: 2rem;
  height: 0.35rem;
  background-color: var(--primary-color);
  display: block;
  top: -1rem;
  left: 0;
  position: relative;
}

.product-category {
  font-size: 1.25rem;
  color: #595a5c;
  margin: 0;
}

.product-category.purchased {
  display: inline-block;
}

.top-seller-tag {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
}

.seller-badge {
  color: var(--color-black);
  background-color: #ffba00;
  padding: 0.425rem 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 0.25rem;
  text-transform: uppercase;
  line-height: 1;
  margin-right: 1rem;
}

.top-seller-tag .form-control {
  height: fit-content;
  background-color: transparent !important;
  border: none;
  height: unset !important;
  margin: 0;
  margin-left: 0.75rem !important;
  padding: 0;
  font-size: 0.8rem !important;
  width: fit-content;
  box-shadow: none;
}

.top-seller-tag .form-control:focus {
  box-shadow: none !important;
}

.leave-rating {
  color: var(--color-white);
  margin: 2rem 0 0;
}

.ratings-value {
  font-weight: bold;
  font-size: 1.125rem;
}

.ratings-number {
  text-decoration: underline;
  margin-left: 0.5rem;
}

.enrolled-students {
  color: var(--primary-color);
  margin-left: 0.75rem;
}

.product-page-banner-container .breadcrumb {
  background-color: transparent !important;
}

.breadcrumb.mobile {
  margin-bottom: 1.5rem;
  padding-left: 0.25rem;
}

.breadcrumb-item a {
  color: #231f20 !important;
  font-weight: normal;
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: bold !important;
  color: #231f20 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.product-title-card {
  background-color: var(--color-white);
  padding: 2.75rem 3rem 1.75rem 1.5rem;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #a3a3a3;
  position: absolute;
  top: 4rem;
  box-shadow: 0 0 16px #c9c9c929;
  min-width: 24rem;
}

.product-image {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  align-items: center !important;
}

.product-image .b-aspect {
  width: 100%;
}

.product-image .b-skeleton-img {
  width: 90%;
  border-radius: 1rem;
  margin: 0 auto;
  background-color: #dddddd;
}

.product-image img {
  height: auto;
  object-fit: cover;
  object-position: top;
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0 -6px 8px #d5d5d529;
}

/*/deep/ .video-modal-body {*/
/*  padding: 0 !important;*/
/*  background: black !important;*/
/*}*/


/*/deep/ .product-image /deep/ .video-modal .body-container {*/
/*  background: black !important;*/
/*}*/

.video-thumbnail-icon {
  opacity: 0.5;
  position: absolute;
  font-size: 7.5rem;
  cursor: pointer;
}

.video-thumbnail-icon:hover {
  opacity: 1;
}

.video-preview {
  width: 100%;
  height: 100%;
}
</style>

<!--
Following separate style tag is for global styling because the video preview modal is a nested child of current component,
and the above <style> tag is scoped to current component only therefore not applying styling on child/nested-child components.
-->
<style>
.video-modal-body {
  padding: 0 !important;
  background: black !important;
}
</style>
