<template>
	<div class="category-list-item">
		<div class="category-heading-container">
			<img src="../../../assets/images/drag-icon.svg" alt="" v-if="!isUncategorized" style="cursor: grab" class="category-drag-handler" />
			<h1 v-if="mode == 'view'" class="category-heading">
				{{ categoryData.title }}
			</h1>
			<form v-if="mode == 'edit'" @submit="editCategory">
				<input
					type="search"
					class="update-category-input"
					v-model="updatedTitle"
					placeholder="Enter new title"
					id="update-category-input"
					required
					v-on:keyup.esc="changeMode($event, 'view')"
					@blur="changeMode($event, 'view')"
				/>
				<button
					@click="editCategory"
					type="submit"
					class="content-action-btn save"
				>
					<img src="@/assets/images/check-icon-green.svg" alt="save" />
				</button>
				<button
					@click="changeMode($event, 'view')"
					class="content-action-btn cancel"
					type="button"
					role="button"
				>
					<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
				</button>
			</form>
			<div class="d-flex ms-auto align-items-center gap-3">
				<b-spinner v-if="isLoading" small></b-spinner>
				<a
					v-if="!isUncategorized"
					data-bs-toggle="dropdown"
					id="options-dropdown-btn"
					class="category-options dropdown-toggle"
					role="button"
					><img src="@/assets/images/options-icon.svg" />
				</a>
				<ul
					id="options-dropdown"
					class="dropdown-menu dropdown-menu-end"
					aria-labelledby="dropdownMenuLink"
				>
					<li @click="toggleCreate(true)" class="options-dropdown-item">
						<img src="@/assets/images/plus-icon.svg" /> Create Course
					</li>
					<li><hr class="dropdown-divider" /></li>
					<li
						@click="showDeleteConfirmPopup"
						class="options-dropdown-item delete"
					>
						<img src="@/assets/images/delete-icon.svg" /> Delete
					</li>
					<li @click="changeMode($event, 'edit')" class="options-dropdown-item">
						<img src="@/assets/images/edit-icon.svg" /> Edit
					</li>
				</ul>
			</div>
		</div>
		<div class="searchbar-container">
			<input
				type="search"
				id="category-card-search"
				autocomplete="off"
				placeholder="Find your course here..."
				v-model="searchText"
			/>
			<a role="button"
				><img src="@/assets/images/search-icon.svg" class="search-icon"
			/></a>
		</div>
		<div
			v-if="
				((filteredProductList && filteredProductList.length > 0) || createProduct) || (filteredProductList && filteredProductList.length == 0 && !createProduct)
			"
			class="product-list-container"
		>
			<ProductList
				:productList="filteredProductList"
				@toggleModal="$emit('toggleModal', $event)"
				:mode="createProduct ? 'create' : 'view'"
				:categoryId="categoryData.id"
				@changeMode="toggleCreate"
				:isUncategorized="isUncategorized"
			/>
		</div>
		<!-- <div
			class="no-content pt-4 text-center text-muted"
			v-else-if="
				
			"
		>
			No courses found!
		</div> -->
		<div v-else class="product-list-container skeleton">
			<b-skeleton
				v-for="item in 3"
				:key="item"
				class="product-list-item-skeleton"
			></b-skeleton>
		</div>

		<a
			v-if="!createProduct && !isUncategorized"
			@click="toggleCreate(true)"
			role="button"
			:class="['add-course-btn', { cancel: createProduct }]"
			><img src="@/assets/images/plus-icon-gray.svg" /> {{ createBtnText }}</a
		>
	</div>
</template>

<script>
import ProductList from "../Product/ProductList.vue";
import { mapActions, mapState, mapGetters } from "vuex";
// import CreateProductVariantModal from "../ProductVariant/CreateProductVariantModal.vue";
export default {
	name: "CategoryListItem",
	components: {
		ProductList,
		// CreateProductVariantModal,
	},
	props: {
		categoryData: Object,
		isUncategorized: Boolean,
	},
	data() {
		return {
			mode: "view",
			// showModal: false,
			productList: null,
			updatedTitle: "",
			title: "",
			createProduct: false,
			showDropdown: false,
			searchText: "",
			isLoading: false,
			// loading: false,
		};
	},
	methods: {
		...mapActions("product", ["getProductByCategoryId", "fetchAllProducts"]),
		...mapActions("category", ["updateCategory", "deleteCategory"]),
		changeMode(e, mode) {
			// e.stopPropagation();
			if (mode == "view") {
				setTimeout(() => (this.mode = mode), 300);
				return;
			}
			// console.log("changing mode to", mode);
			if (mode == "edit") {
				this.updatedTitle = this.categoryData.title;
				// this.mode = mode;
				setTimeout(
					() => document.getElementById("update-category-input").focus(),
					300
				);
			}

			this.mode = mode;
		},

		editCategory(e) {
			this.updatedTitle = this.updatedTitle.trim();
			e.preventDefault();
			if (
				this.title !== this.updatedTitle &&
				!this.loading &&
				this.updatedTitle.length > 0
			) {
				var updatedObject = {
					title: this.updatedTitle,
					id: this.categoryData.id,
					isVisible: true,
				};
				this.isLoading = true;
				this.updateCategory(updatedObject).then(() => {
					this.isLoading = false;
					this.mode = "view";
					this.title = this.updatedTitle;
				});
				console.log("update called");
			} else {
				this.mode = "view";
			}

			// this.categoryTitle = "";
		},
		toggleCreate(state) {
			this.createProduct = state;
			if (this.createProduct) {
				setTimeout(
					() => document.getElementById("create-product-input").focus(),
					200
				);
			}
		},

		handleLinkClick(item) {
			this.searchText = "";
			this.$router.push(`/management/course/${item.id}/content`);
		},
		closeDropdown() {
			setTimeout(() => (this.showDropdown = false), 300);
		},
		categoryDelete() {
			this.deleteCategory(this.categoryData.id).then(() => {
				console.log("category delete success");
			});
		},
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete the Qualification: \n${this.title}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.categoryDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
	},
	computed: {
		...mapState("product", ["productsByCategory", "products"]),
		...mapState("category", ["loading"]),
		...mapGetters("product", ["uncategorizedProducts"]),
		showSave() {
			return this.updatedTitle !== "";
		},
		createBtnText() {
			if (this.createProduct) {
				return "Cancel";
			} else return "Add Course";
		},
		filteredProductList() {
			if (this.productList && this.productList.length > 0) {
				var result = this.productList.filter((product) => {
					return (
						(product.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(product.id || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1
						// (product.product.title || "")
						// 	.toLocaleLowerCase()
						// 	.indexOf(this.searchText.toLocaleLowerCase()) > -1
					);
				});
			} else return this.productList;

			return result;
		},
	},
	created() {
		if (this.categoryData && this.categoryData.id == "-1") {
			if (this.products.items && Object.values(this.products.items).length == 0)
				this.fetchAllProducts().then(() => {
					this.productList = this.uncategorizedProducts;
					this.title = this.categoryData.title;
				});
			else {
				this.productList = this.uncategorizedProducts;
				this.title = this.categoryData.title;
			}
		} else {
			if (!this.productsByCategory[this.categoryData.id]) {
				this.getProductByCategoryId(this.categoryData.id).then(() => {
					this.productList = this.productsByCategory[this.categoryData.id];
					this.title = this.categoryData.title;
				});
			} else {
				this.productList = this.productsByCategory[this.categoryData.id];
				this.title = this.categoryData.title;
			}
		}
	},
	watch: {
		productsByCategory: function () {
			this.productList = !this.isUncategorized
				? this.productsByCategory[this.categoryData.id]
				: Object.values(this.products.items).filter(
						(product) => product.categories.length == 0
				  );
			this.title = this.categoryData.title;
		},
		products: function () {
			this.productList = !this.isUncategorized
				? this.productsByCategory[this.categoryData.id]
				: Object.values(this.products.items).filter(
						(product) => product.categories.length == 0
				  );
			this.title = this.categoryData.title;
		},
	},
};
</script>

<style scoped>
.category-list-item {
	background: #ffffff;
	box-shadow: 6px 3px 6px rgba(185, 185, 185, 0.07);
	border-radius: 12px;
	padding: 1.25rem 1.375rem 1.5rem;
	width: 22.625rem;
	flex-shrink: 0;
	/* min-height: 37.5rem; */
	display: flex;
	flex-direction: column;
}

.category-heading-container {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
	min-height: 3.75rem;
}
.category-heading-container .category-heading {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5;
	color: #000000;
	margin: 0;
}
.category-heading-container .category-heading span {
	font-size: 1rem;
	font-weight: normal;
}

.category-heading-icon {
	width: 2.25rem;
}

.category-options {
	width: 1.875rem;
	height: 1.875rem;
	background: #ebebeb;
	border-radius: 50%;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-left: auto;
}

.category-options::after {
	display: none !important;
}

.options-dropdown-item {
	padding: 0.75rem 1rem;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #000000;
	column-gap: 0.75rem;
}

.options-dropdown-item:hover {
	background-color: #ebebeb;
}

.options-dropdown-item.delete {
	color: #ff0000;
}

#options-dropdown {
	padding: 0;
}
#options-dropdown .dropdown-divider {
	margin: 0 !important;
	color: #ebebeb;
}

#category-card-search {
	border: 0;
	border-bottom: 1px solid #b2b2b2;
	border-radius: 0;
	outline: none;
	width: 100%;
	padding: 1rem 0;
	font-size: 1rem;
	transition: all 0.3s ease;
}

#category-card-search:focus {
	border-bottom-color: var(--primary-color);
}

.searchbar-container {
	position: relative;
	margin-top: 0.5rem;
}

.searchbar-container .search-icon {
	position: absolute;
	margin-right: 0;
	right: 0;
	/* width: 1.375rem; */
	top: 0.875rem;
}

.add-course-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.875rem;
	color: #9e9e9e;
	text-decoration: none;
	margin-top: auto;
}

.add-course-btn img {
	transition: all 0.3s;
}

.add-course-btn.cancel img {
	transform: rotate(45deg);
}

.update-category-input {
	height: 3.125rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.875rem 1rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
	padding-right: 5.25rem;
}

.category-heading-container form {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	width: 100%;
}

.content-action-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 50%;
	width: 1.625rem;
	height: 1.625rem;
	/* padding: 0.375rem; */
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0.875rem;
}

.content-action-btn.save {
	border: 1px solid #21cf47;
	color: #21cf47;
	position: absolute;
	right: 3rem;
}

.content-action-btn.cancel {
	border: 1px solid #ff0000;
	color: #ff0000;
	position: absolute;
	right: 0.75rem;
}

.product-list-item-skeleton {
	border-radius: 10px;
	margin-bottom: 1rem;
	height: 3.375rem;
}

.product-list-container {
	margin-bottom: 1rem;
	overflow-y: auto;
	/* margin-right: -1rem; */
	padding-right: 0.5rem;
}

.product-list-container.skeleton {
	margin-top: 1.625rem;
}
</style>
