<template>
	<div>
		<h3 class="main-label">
			Banners
			<i class="far fa-info-circle sys-icon" id="tooltip-target-1"></i>
			<b-tooltip target="tooltip-target-1" triggers="hover">
				Recommended dimensions for images and videos are 1920 x 800 for best
				output
			</b-tooltip>
		</h3>
		<div class="banners-container">
			<div
				v-if="bannersList && bannersList.length > 0"
				class="overflow-container"
				id="banners-list"
			>
				<div
					v-for="(banner, index) in bannersList"
					:key="index"
					class="banner-item"
				>
					<img
						v-if="banner.type.toLowerCase() == 'image'"
						:src="banner.sources[0].data"
						class="banner-preview"
					/>
					<i
						v-if="
							banner.type.toLowerCase() == 'video' &&
							banner.source.provider.toLowerCase() == 'youtube'
						"
						class="fab fa-youtube thumbnail-icon"
					></i>
					<i
						v-if="
							banner.type.toLowerCase() == 'video' &&
							banner.source.provider.toLowerCase() == 'vimeo'
						"
						class="fab fa-vimeo thumbnail-icon"
					></i>
					<i
						v-if="
							banner.type.toLowerCase() == 'video' &&
							banner.source.provider.toLowerCase() == 'mp4'
						"
						class="fas fa-play-circle thumbnail-icon"
					></i>
					<i
						v-if="banner.type.toLowerCase() == 'html'"
						class="fas fa-code thumbnail-icon"
					></i>
					<div v-if="!loading" class="right-menu">
						<a
							role="button"
							class="right-menu-icon"
							@click="editBanner(banner)"
						>
							<img src="@/assets/images/edit-icon.svg" />
						</a>
						<a
							role="button"
							class="right-menu-icon delete"
							@click="showDeleteConfirmPopup($event, banner.id)"
						>
							<img src="@/assets/images/delete-icon.svg" />
						</a>
					</div>
					<b-spinner v-else class="loader"> </b-spinner>
				</div>
			</div>
			<div class="banner-item add-new" @click="toggleBannerModal(true)">
				<i class="far fa-plus-square add-icon"></i>
				Add New Banner
			</div>
			<AddEditBannerModal
				:showModal="showBannersModal"
				:bannersList="bannersList"
				@updateModalState="toggleBannerModal"
				:isEdit="isEdit"
				:bannerData="bannerToEdit"
				@addBanner="addNewBanner"
				@editBanner="bannerEdit"
				ref="banner_edit"
			/>
		</div>
		<div class="form-check form-switch mt-3">
			<input
				class="form-check-input"
				type="checkbox"
				role="switch"
				id="autoplay"
				v-model="autoplay"
				@change="updateBanners"
			/>
			<label class="form-check-label" for="autoplay">Autoplay Banners</label>
		</div>
		<div v-if="autoplay" class="d-flex flex-column gap-2">
			<!-- <div class="form-group d-flex"> -->
			<label class="text-muted"> Duration (in milliseconds)</label>
			<input
				type="number"
				class="form-input col-2"
				v-model="duration"
				@change="updateBanners"
			/>
			<!-- </div> -->
			<div class="form-check form-switch mt-3">
				<input
					class="form-check-input"
					type="checkbox"
					role="switch"
					id="pauseOnHover"
					v-model="pauseOnHover"
					@change="updateBanners"
				/>
				<label class="form-check-label" for="pauseOnHover"
					>Pause autoplay on banner hover</label
				>
			</div>
		</div>
    <div class="form-check form-switch mt-3">
      <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="allowMaterialDownload"
          v-model="allowMaterialDownload"
          @change="updateStudyMaterial"
      />
      <label class="form-check-label" for="allowMaterialDownload">Enable Download/Print Button by default when uploading new Study Material?</label>
    </div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AddEditBannerModal from "./AddEditBannerModal.vue";

export default {
	name: "BannerSettings",
	data() {
		return {
			bannersList: null,
			mobileBanners: false,
			showBannersModal: false,
			bannerToEdit: null,
			isEdit: false,
			autoplay: false,
			pauseOnHover: false,
			allowMaterialDownload: false,
			duration: 3000,
		};
	},
	methods: {
		...mapActions("appState", [
			"editSystemParameters",
			"fetchSystemParameters",
		]),
		toggleBannerModal(state) {
			if (state != undefined) {
				if (state == false) {
					this.isEdit = false;
					this.bannerToEdit = null;
				}
				this.showBannersModal = state;
			} else this.showBannersModal = !this.showBannersModal;
		},
		editBanner(banner) {
			this.isEdit = true;
			this.bannerToEdit = banner;
			this.toggleBannerModal();
		},
		addNewBanner(banner) {
			var bannersFromStore = [];
			if (this.systemParameters.Banners)
				bannersFromStore = this.systemParameters.Banners.slides;
			var updatedBannerSlides;

			if (
				this.systemParameters.Banners &&
				bannersFromStore &&
				bannersFromStore.length > 0
			) {
				if (!banner.order)
					banner.order = this.systemParameters.Banners.slides.at(-1).order + 1;
				updatedBannerSlides = [...this.systemParameters.Banners.slides, banner];
			} else {
				banner.order = 1;
				updatedBannerSlides = [banner];
			}
			var bannersUpdateObj = {
				autoplay: this.autoplay,
				duration: this.duration,
				pauseOnHover: this.pauseOnHover,
				slides: updatedBannerSlides,
			};
			this.editSystemParameters({
				parameterName: "Banners",
				parameterValue: JSON.stringify(bannersUpdateObj),
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					this.$refs.banner_edit.closeModal();
					setTimeout(
						() =>
							document
								.getElementById("banners-list")
								.lastElementChild.scrollIntoView({
									behavior: "smooth",
								}),
						300
					);
				});
			});
		},
		deleteBanner(id) {
			var bannersFromStore = this.systemParameters.Banners;
			var updatedBannerSlides;
			// bannersFromStore.slides.splice(id, 1);
			updatedBannerSlides = bannersFromStore.slides.filter(
				(slide) => slide.id != id
			);
			var bannersUpdateObj = {
				autoplay: this.autoplay,
				duration: this.duration,
				pauseOnHover: this.pauseOnHover,
				slides: updatedBannerSlides,
			};
			this.editSystemParameters({
				parameterName: "Banners",
				parameterValue: JSON.stringify(bannersUpdateObj),
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					this.$refs.banner_edit.closeModal();
					setTimeout(
						() =>
							document
								.getElementById("banners-list")
								.lastElementChild.scrollIntoView({
									behavior: "smooth",
								}),
						300
					);
				});
			});
		},
		bannerEdit(banner) {
			console.log(banner);
			var bannersFromStore = this.systemParameters.Banners.slides;
			var updatedBannerSlides;

			if (
				this.systemParameters.Banners &&
				bannersFromStore &&
				bannersFromStore.length > 0
			) {
				if (!banner.order)
					banner.order = this.systemParameters.Banners.slides.at(-1).order + 1;
				updatedBannerSlides = bannersFromStore.map((b) => {
					console.log(b, banner);
					if (b.id == banner.id) return banner;
					else return b;
				});
			}
			// else {
			// 	banner.order = 1;
			// 	updatedBannerSlides = [banner];
			// }
			var bannersUpdateObj = {
				autoplay: this.autoplay,
				duration: this.duration,
				pauseOnHover: this.pauseOnHover,
				slides: updatedBannerSlides,
			};
			this.editSystemParameters({
				parameterName: "Banners",
				parameterValue: JSON.stringify(bannersUpdateObj),
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					this.$refs.banner_edit.closeModal();
					setTimeout(
						() =>
							document
								.getElementById("banners-list")
								.lastElementChild.scrollIntoView({
									behavior: "smooth",
								}),
						300
					);
				});
			});
		},
		updateBanners() {
			var bannersFromStore = [];
			if (this.systemParameters.Banners)
				bannersFromStore = this.systemParameters.Banners.slides;
			var bannersUpdateObj = {
				autoplay: this.autoplay,
				duration: this.duration,
				pauseOnHover: this.pauseOnHover,
				slides: bannersFromStore,
			};

			this.editSystemParameters({
				parameterName: "Banners",
				parameterValue: JSON.stringify(bannersUpdateObj),
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					console.log("banners updated");
				});
			});
		},
		updateStudyMaterial() {
			
			this.editSystemParameters({
				parameterName: "AllowStudyMaterialDefaultValue",
				parameterValue: this.allowMaterialDownload+"",
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					console.log("updated");
				});
			});
		},
		showDeleteConfirmPopup(e, id) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(`Are you sure you want to delete this banner?`, {
					title: "Are you sure?",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
					okTitle: "Yes",
					cancelTitle: "No",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
				})
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.deleteBanner(id);
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
	},
	props: {
		// bannersList: Object,
	},
	computed: {
		...mapState("appState", ["systemParameters", "loading"]),
	},
	created() {
		if (this.systemParameters && this.systemParameters.Banners) {
			this.bannersList = this.systemParameters.Banners.slides;
			this.autoplay = this.systemParameters.Banners.autoplay;
			this.pauseOnHover = this.systemParameters.Banners.pauseOnHover;
			this.duration = this.systemParameters.Banners.duration;
			if (this.bannersList)
				this.bannersList.sort(function (a, b) {
					if (a.order < b.order) {
						return -1;
					}
					if (a.order > b.order) {
						return 1;
					}
					return 0;
				});
			setTimeout(
				() =>
					document
						.getElementById("banners-list")
						.lastElementChild.scrollIntoView({
							behavior: "smooth",
						}),
				300
			);
		}
		if (this.systemParameters && this.systemParameters.AllowStudyMaterialDefaultValue) {
			this.allowMaterialDownload = this.systemParameters.AllowStudyMaterialDefaultValue;			
		}
	},
	components: {
		AddEditBannerModal,
	},
	watch: {
		systemParameters: function () {
			if (this.systemParameters.Banners) {
				this.bannersList = this.systemParameters.Banners.slides;
				if (this.bannersList)
					this.bannersList.sort(function (a, b) {
						if (a.order < b.order) {
							return -1;
						}
						if (a.order > b.order) {
							return 1;
						}
						return 0;
					});
			}
			// if (this.systemParameters && this.systemParameters.StudyMaterial) {
			// 	this.allowMaterialDownload = this.systemParameters.StudyMaterial.allowDownload;
			// }
			// if (this.bannersList) this.bannersList.sort();
		},
	},
};
</script>

<style scoped>
.banners-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	column-gap: 1rem;
	margin-bottom: 1rem;
}
.overflow-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	overflow-y: auto;
	margin-bottom: -0.5rem;
	padding-bottom: 0.5rem;
}

.banner-item {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 6px;
	cursor: pointer;
	width: 12.5rem;
	height: 7rem;
	font-size: 1rem;
	line-height: 1.37;
	color: #727272;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 0.875rem;
	overflow: hidden;
	flex-shrink: 0;
}

.banner-item .add-icon {
	font-size: 2rem;
}

.banner-item.add-new {
	background-color: transparent;
	border-style: dashed;
	box-shadow: none;
	border-width: 2px;
}

.preferences-card .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.preferences-heading {
	font-size: 1.25rem;
	margin: 0;
	font-weight: 500;
}

.banner-item .banner-preview {
	height: 100%;
	object-fit: cover;
	object-position: top;
}

.banner-item .thumbnail-icon {
	font-size: 4rem;
}

.form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.5rem 0;
	/* max-width: 21.625rem; */
	/* width: 100%; */
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	background-color: transparent;
}

.banner-item .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.banner-item .loader {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	position: absolute;
}

.banner-item:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.banner-item .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.banner-item .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.banner-item .right-menu-icon.delete {
	border-color: #ff0000;
}

.banner-item .right-menu-icon img {
	max-height: 1rem;
}
</style>
