<template>
  <div>
    <button
      v-if="item.status.toLowerCase() != 'paid'"
      @click="payInvoice"
      class="primary-btn pay-invoice"
    >
      Pay Now
    </button>
    <b-modal
      :visible="visible"
      hide-header
      hide-footer
      @change="closeModal"
      centered
      no-close-on-backdrop
      content-class="pay-now-modal"
    >
      <div v-if="loading" class="body-container text-center">
        <b-spinner></b-spinner>
      </div>
      <div
        v-else-if="invoice.status == InvoiceStatus.PARTIALLY_PAID"
        class="body-container"
      >
        <button @click="closeModal" class="close-button" role="button">
          <img src="@/assets/images/cancel-icon.svg" />
        </button>
        <h3 class="text-center pt-5 pb-5">
          Your invoice is partially paid. Please contact Admin for further
          payment.
        </h3>
      </div>
      <div v-else-if="pendingPayments" class="body-container">
        <button @click="closeModal" class="close-button" role="button">
          <img src="@/assets/images/cancel-icon.svg" />
        </button>
        <h3 class="text-center pt-5 pb-5">
          You already have a payment pending for review
        </h3>
        <button
          @click="handlePayNowClick"
          role="button"
          class="btn-block-rounded-primary width-100"
        >
          Update Payment
        </button>
      </div>
      <div v-else class="body-container">
        <button @click="closeModal" class="close-button" role="button">
          <img src="@/assets/images/cancel-icon.svg" />
        </button>
        <h5 class="text-gray">Total Amount</h5>
        <h2 class="text-bold mb-4">
          {{ parseCurrency(totalAmount, invoice.currency, 0) }}
        </h2>
        <p class="text-uppercase-danger">Payment Method</p>
        <div class="radio-group">
          <div
            v-for="option in paymentOptions"
            :key="option.code"
            class="border-bottom radio-input d-flex"
          >
            <input
              v-model="paymentMode"
              class="form-check-input"
              type="radio"
              name="payment-method"
              :id="option.serviceProvider"
              :value="option.serviceProvider"
            />
            <label
              class="form-check-label flex-grow-1 d-flex justify-content-between align-items-center"
              :for="option.serviceProvider"
            >
              {{ option.serviceName }}
              <img
                style="height: 20px"
                v-if="option.serviceData && JSON.parse(option.serviceData)"
                :src="JSON.parse(option.serviceData).IconSrc"
              />
            </label>
          </div>
          <table class="table mt-4">
            <tbody>
              <tr>
                <td class="col-7">Total (Before GST)</td>
                <td class="col-5 text-bold text-end">
                  {{ parseCurrency(totalAmount, "PKR", 0) }}
                </td>
              </tr>
              <tr>
                <td class="col-7 text-muted">GST</td>
                <td class="col-5 text-muted text-end">
                  {{ parseCurrency(0, "PKR", 2) }}
                </td>
              </tr>
              <tr class="text-bold">
                <td class="col-7">Total (Including GST)</td>
                <td class="col-5 text-end">
                  {{ parseCurrency(totalAmount, "PKR", 0) }}
                </td>
              </tr>
            </tbody>
          </table>
          <button
            @click="handlePayNowClick"
            role="button"
            class="btn-block-rounded-primary width-100"
          >
            Pay Now
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";
import { PaymentStatus, InvoiceStatus } from "../../constants/pricing";
// import { InvoiceStatus } from "../../constants/pricing";
export default {
  name: "PayInvoiceModal",
  data() {
    return {
      showInvoiceModal: false,
      paymentMode: "",
      paymentOptions: null,
      pendingPayments: null,
      InvoiceStatus,
    };
  },
  props: { invoice: Object, item: Object, totalAmount: String },
  components: {},
  methods: {
    ...mapActions("appState", ["fetchExternalServices"]),
    ...mapActions("pricing", ["fetchPaymentsList"]),
    payInvoice() {
      this.fetchPaymentsList({ invoiceID: this.invoice.id }).then((res) => {
        console.log(res);
        var isPendingPayment = res.payload.filter(
          (payment) => payment.status == PaymentStatus.PENDING
        );
        if (isPendingPayment) {
          this.pendingPayments = isPendingPayment.length
            ? isPendingPayment
            : null;
          if (this.pendingPayments && this.pendingPayments.length > 0) {
            this.paymentMode =
              this.pendingPayments[
                this.pendingPayments.length - 1
              ].methodProvider;
          }
          if (
            this.pendingPayments &&
            this.pendingPayments[
              this.pendingPayments.length - 1
            ].methodProvider.toLowerCase() != "banktransfer"
          ) {
            this.pendingPayments = null;
          }
        }
      });
      this.showInvoiceModal = true;
    },
    closeModal() {
      this.showInvoiceModal = false;
    },
    parseCurrency(amount, currency, decimal) {
      return formatter.toCurrencyString(amount, currency, decimal);
    },
    iconPath(option) {
      // console.log(`@${JSON.parse(option.serviceData).IconSrc}`);
      return require(`@${JSON.parse(option.serviceData).IconSrc}`);
    },
    handlePayNowClick() {
      if (
        this.paymentMode.toLowerCase() == "cash" ||
        this.paymentMode.toLowerCase() == "banktransfer" ||
        this.paymentMode.toLowerCase() == "paypro" ||
        this.paymentMode.toLowerCase() == "meezan" ||
        this.paymentMode.toLowerCase() == "scotia" ||
        this.paymentMode.toLowerCase() == "ngenius" ||
        this.paymentMode.toLowerCase() == "kuickpaycheckout" ||
        this.paymentMode.toLowerCase() == "kuickpaybps" ||
        this.paymentMode.toLowerCase() == "safepay" ||
        this.paymentMode.toLowerCase() == "stripe"
      ) {
        if(this.paymentMode.toLowerCase() == 'kuickpaybps') {
          var kuickpaybpsPayment = this.paymentOptions.find((x) => x.serviceProvider.toLowerCase() == this.paymentMode.toLowerCase());
          var serviceData = kuickpaybpsPayment.serviceData ? JSON.parse(kuickpaybpsPayment.serviceData) : null;
          this.$router.push(`${this.payNowLink}/${this.invoice.id}?merchantcode=${serviceData.MerchantCode}`);
          return;
        }
        if (!this.pendingPayments) {
          this.$router.push(`${this.payNowLink}/${this.invoice.id}`);
          return;
        }
        this.$router.push({
          path: `${this.payNowLink}/${this.invoice.id}`,
          query: { paymentID: this.pendingPayments[0].id },
        });
      }
    },
  },
  computed: {
    ...mapState("appState", ["externalServicesList"]),
    ...mapState("user", ["user"]),
    ...mapState("pricing", ["loading"]),
    visible() {
      return this.showInvoiceModal;
    },
    payNowLink() {
      return `/payment/${this.paymentMode.toLowerCase()}`;
    },
  },
  created() {
    if (!this.externalServicesList.PaymentGateway) {
      this.fetchExternalServices("PaymentGateway").then(() => {
        if (
          this.externalServicesList.PaymentGateway &&
          this.externalServicesList.PaymentGateway.length > 0
        ) {
          this.paymentOptions = this.externalServicesList.PaymentGateway;
          this.paymentMode =
            this.externalServicesList.PaymentGateway[0].serviceProvider;
        }
        console.log(
          "payment options",
          this.externalServicesList.PaymentGateway
        );
      });
    } else {
      this.paymentOptions = this.externalServicesList.PaymentGateway;
      this.paymentMode =
        this.externalServicesList.PaymentGateway.length > 0
          ? this.externalServicesList.PaymentGateway[0].serviceProvider
          : null;
    }
  },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .primary-btn.pay-invoice {
    font-weight: normal;
    font-size: 0.875rem !important;
    padding: 0.375rem !important;
  }
}

.text-uppercase-danger {
  font-size: 0.85rem;
  text-transform: uppercase;
  color: var(--color-danger);
  margin: 0.5rem 0 0.75rem;
}

.radio-input {
  padding: 1rem 0.25rem;
}

.radio-input input[type="radio"] {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  outline: none;
}
.payment-icon {
  width: 70px;
  float: right;
}

table {
  line-height: 1rem;
}

.pay-now-modal .modal-body {
  font-size: 1rem;
}

.primary-btn.pay-invoice {
  border-radius: 0.375rem;
}
</style>
