<template>
	<div>
		<BannerSmall>
			<h2 class="pt-5 pb-5">Cash Payment</h2>
		</BannerSmall>
		<div class="pt-5 container">
			<h5 v-html="instructions"></h5>
		</div>
	</div>
</template>

<script>
import BannerSmall from "../Layout/BannerSmall.vue";
export default {
	name: "Cash",
	props: { instructions: String, paymentMode: Object },
	components: { BannerSmall },
};
</script>

<style scoped>
</style>
