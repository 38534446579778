<template>
	<div class="cart-page">
		<BannerSmall>
			<div class="banner-title-card">
				<h1 class="banner-title">Confirm Payment</h1>
			</div>
		</BannerSmall>
		<div class="container cart-content-container">
			<div
				class="col-lg-8 cart-product-list container-fluid"
				v-if="!isCartEmpty"
			>
				<CartProductItem
					v-for="item in getCartItems"
					:key="item.id"
					:productVariantData="item"
				/>
			</div>
			<div
				class="col-lg-4 cart-payment-card container-fluid"
				v-if="!isCartEmpty"
			>
				<PaymentCard />
			</div>
			<div v-else class="container-fluid text-center p-5">
				<h2 class="text-bold text-muted">No items in cart.</h2>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import BannerSmall from "../components/Layout/BannerSmall.vue";
import CartProductItem from "../components/Cart/CartProductItem.vue";
import PaymentCard from "../components/Cart/PaymentCard.vue";
export default {
	name: "Cart",
	components: { BannerSmall, CartProductItem, PaymentCard },
	computed: {
		...mapGetters("cart", ["getCartItems", "isCartEmpty"]),
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.cart-content-container {
		flex-direction: column;
		padding: 0 !important;
	}
	.cart-payment-card {
		margin-bottom: 0;
	}

	.cart-banner {
		padding-bottom: 8rem !important;
	}
}

@media screen and (max-width: 768px) {
	.banner-title-card {
		top: 2rem !important;
	}
	.banner-small {
		margin-bottom: 1rem !important;
	}
}

.cart-page .banner-small {
	height: 8rem;
	margin-bottom: 3rem;
}

.cart-banner {
	padding-bottom: 6rem;
}

.cart-product-list {
	margin-top: 2rem;
}

.cart-content-container {
	display: flex;
	padding: 0;
}

.banner-title-card {
	background-color: var(--color-white);
	padding: 2.75rem 3rem 1.75rem 1.5rem;
	width: fit-content;
	border-radius: 8px;
	border: 1px solid #a3a3a3;
	position: absolute;
	top: 4rem;
	box-shadow: 0 0 16px #c9c9c929;
}

.banner-title {
	/* font-size: 2.5rem;
	font-weight: bold; */
	font-weight: bold;
	font-size: 2rem;
	color: #000000;
	/* padding: 2.5rem 0; */
	margin: 0;
}

.banner-title:before {
	content: "";
	width: 2rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -1rem;
	left: 0;
	position: relative;
}
</style>
