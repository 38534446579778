<template>
    <div>
      <div v-if="isReviewMode">
          <h5 v-if="isCorrect(selectedAnswer)" class="select-inputs correct" >Correct</h5>
          <h5 v-if="isInCorrect(selectedAnswer)" class="select-inputs incorrect" >Wrong! Correct Answer is: <span v-html="correctAnswer"></span></h5>
          <h5 v-if="!this.answers || this.answers.length <= 0" class="select-inputs incorrect" >Wrong! Correct Answer is: <span v-html="correctAnswer"></span></h5>
      </div>
        <b-form-select class="select-inputs-group" v-model="selectedAnswer" :disabled="isReviewMode">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option v-for="(option,index) in options" :value="option.identifier" :key="index"><span v-html="option.option"></span></b-form-select-option>
        </b-form-select>
    </div>
  </template>
  
  <script>
    export default {
      name: 'SelectButtonQuestion',
      data() {
        return {
          selectedAnswer:null
        }
      },
      props:{
        isReviewMode:Boolean,
        options:Array,
        answers:Array,
        correctAnswers:Array
      },
      computed: {
        filteredOptions: {
            get() {
                return this.options && this.options.length > 0 ? this.options : [];
            },
            set(value) {
                this.options = value;
            },
        },
        correctAnswer: function() {
          var correctOption = this.selectedAnswer != this.correctAnswers[0] ? this.options.filter((x) => x.identifier == this.correctAnswers[0]) : null;
          return correctOption[0]?.option;
        }
      },
      mounted() {},
      methods: {
        isCorrect(option)
        {
          if(this.isReviewMode && this.correctAnswers)
          {
            return this.correctAnswers.indexOf(option)>-1;
          }
          return false;
        },
        isInCorrect(option){
          if(this.isReviewMode && !this.isCorrect(option) && this.answers)
          {
            return this.answers.indexOf(option)>-1;
          }
          return false;
        },
      },
      created(){
        // if(this.filteredOptions && this.filteredOptions.length > 0) {
        //     var _options = [];
        //     _options.push({value: null, text: 'Please select an answer'});

        //     for(let i=0; i<this.filteredOptions.length; i++) {
        //         _options.push({value: this.filteredOptions[i], text: this.filteredOptions[i]});
        //     }

        //     this.filteredOptions = [];
        //     this.filteredOptions = _options;
            
        // }
        if(this.answers && this.answers.length>0)
          {
            this.selectedAnswer= this.answers[0];
          }
      },
      watch:{
        selectedAnswer:function(){
          this.$emit("setAnswer", [this.selectedAnswer]);
        },
        answers:function(){
          if(this.answers && this.answers.length>0)
          {
            this.selectedAnswer= this.answers[0];
          }
        }
      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .input-option{
    margin-left: 10px;
    font-weight: lighter;
  }
  .select-inputs{
    max-width: 40%;
    padding: 5px;
    border-radius: 5px;
  
  }
  .select-inputs-group{
    min-width: 20%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1rem;
  }
  .select-inputs.correct{
    background: #ddf1dd;
  
  }
  .select-inputs.incorrect{  
    background: #fed2d2;
  
  }
  
  </style>
  
  <style>
  
  .quiz-select input[type=select]{
    accent-color: var(--primary-color);
  }
  
  .select-inputs .custom-control-label{
    margin: 0px;
  }
  </style>