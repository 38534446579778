<template>
	<div class="plan-item mb-3">
		<input
			type="radio"
			:name="`variant-${productVariantId}`"
			:id="`plan-${plan.id}`"
			:checked="isChecked"
			@click="selectPlan"
		/>
		<label :for="`plan-${plan.id}`" class="form-control edit-profile-input">
			<!-- <div> -->
			{{ plan.title }} ({{ paymentTypeText(plan) }})
			<span class="ms-auto text-bold">{{
				plan.type.toLowerCase() == "onetime"
					? parseCurrency(plan.details[0].amount, plan.currency, 0)
					: null
			}}</span>
		</label>
		<div
			v-if="isChecked && plan.type.toLowerCase() != 'onetime'"
			class="plan-cycles-container mt-3"
		>
			<h5 class="mb-3">Select Plan Cycles</h5>
			<div class="plan-item" v-for="item in plan.details" :key="item.id">
				<input
					type="checkbox"
					:name="`plan-${plan.id}`"
					:id="`plan-cycle-${item.id}`"
					:checked="isCycleSelected(item)"
					@click="togglePlanCycle(item)"
				/>
				<label
					:for="`plan-cycle-${item.id}`"
					class="form-control edit-profile-input mb-2"
				>
					{{ item.title }}
					<span class="ms-auto text-bold">{{
						parseCurrency(item.amount, plan.currency, 0)
					}}</span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import formatter from "../../helpers/FormatHelpers";
import router from "../../router";

export default {
	name: "ProductPricingPlanItem",
	data() {
		return {
			selectedCycles: [],
		};
	},
	props: {
		plan: Object,
		selectedPlan: Object,
		productVariantId: String,
	},
	methods: {
		paymentTypeText(plan) {
			return plan.type == "OneTime"
				? "Single Payment"
				: `${plan.details.length} Plan-Cycles`;
		},
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
		selectPlan() {
			this.$emit("updatePlanSelection", this.plan);
		},
		isCycleSelected(cycle) {
			var found = this.selectedCycles.find((c) => c.id == cycle.id);
			return found ? true : false;
		},
		togglePlanCycle(cycle) {
			var found = this.selectedCycles.find((c) => c.id == cycle.id);
			if (!found) {
				this.selectedCycles.push(cycle);
			} else {
				this.selectedCycles = this.selectedCycles.filter(
					(c) => c.id != cycle.id
				);
			}
		},
	},

	computed: {
		isChecked() {
			if (this.selectedPlan && this.selectedPlan.id == this.plan.id) {
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		selectedCycles: function () {
			this.$emit("updateSelectedCycles", this.selectedCycles);
		},
	},
};
</script>

<style scoped>
.plan-item {
	position: relative;
}

.plan-item .edit-profile-input {
	height: auto;
}

.plan-item input,
.plan-item label {
	margin: 0;
}

.plan-item label {
	padding: 1rem 2rem 1rem 4rem;
	width: 100%;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
}

.plan-item input[type="radio"],
.plan-item input[type="checkbox"] {
	width: 1.375rem;
	height: 1.375rem;
	position: absolute;
	left: 1rem;
	top: 1.75rem;
	transform: translateY(-50%);
}

.plan-item input[type="radio"]:checked::before,
.plan-item input[type="checkbox"]:checked::before {
	background-color: var(--primary-color);
}

.plan-item input[type="radio"]:checked + label,
.plan-item input[type="checkbox"]:checked + label {
	background-color: #ebebeb;
}

.plan-cycles-container {
	padding: 0 0 0 1rem;
}
</style>
