class ServiceCodes {
	static LIVE_CLASS = {
		Zoom: "Zoom",
	};
	static EXAM = {
		ExamTestingSystem: "ES",
		V3ExamSystem: "V3ExamSystem",
	};
	static RECORDED_VIDEOS = {
		VideoStreamingServer: "VSS",
	};
	static STUDY_MATERIAL = {
		FileStreamingServer: "FSS",
	};
}

export { ServiceCodes };
