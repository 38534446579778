import Vue from "vue";
import store from ".";
import { assignmentService } from "../services/assignment.service";

const state = {
	loading: false,
	assignments: {},
};

const getters = {
	
};

const actions = {
	
	submitAssignment: async ({ commit }, {contentId, assignmentSubmissionFiles}) => {
		commit("setLoading", true);
		var submittedAssignment = await assignmentService.submitAssignment(contentId,assignmentSubmissionFiles)
        .then(
			(submittedAssignment) => {
				
				commit("setLoading", false);
				return submittedAssignment.payload;
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
		return submittedAssignment;
	},
	updateAssignmentSubmission: async ({ commit }, { contentId, submissionId, assignmentSubmissionFiles }) => {
		commit("setLoading", true);
		
		var submittedAssignment = await assignmentService.updateAssignmentSubmission(contentId,
			 submissionId, assignmentSubmissionFiles)
			.then(
				(submittedAssignment) => {

					commit("setLoading", false);
					return submittedAssignment.payload;
				},
				(error) => {
					commit("setLoading", false);
				}
			);
		return submittedAssignment;
	},
    getSubmittedAssignment: async ({ commit }, contentId) => {
		commit("setLoading", true);
		var submittedAssignment = await assignmentService.getSubmittedAssignment(contentId)
        .then(
			(submittedAssignment) => {
				
				commit("setLoading", false);
				return submittedAssignment.payload;
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
		return submittedAssignment;
	},
    getSubmittedAssignmentResults: async ({ commit }, contentId) => {
		commit("setLoading", true);
		var submittedAssignmentResults = await assignmentService.getSubmittedAssignmentResults(contentId)
        .then(
			(submittedAssignmentResults) => {
				
				commit("setLoading", false);
					const payload = submittedAssignmentResults.payload;
					state.assignments[contentId] = payload;
					return payload;
			},
				(error) => { commit("setLoading", false); }
		);
		return submittedAssignmentResults;
	},
    submitAssignmentResult: async ({ commit }, assignmentResult) => {
		commit("setLoading", true);
		var submittedAssignmentResult = await assignmentService
		.submitAssignmentResult(assignmentResult).then((data) => {
					commit("setLoading", false);
					let mutationData = { 
						payload: data.payload,
						type: 'marks',
						assignmentResult: assignmentResult,
					};
					commit("updateAssignmentResult", mutationData);
					return data.payload;
				},
				(error) => {
					commit("setLoading", false);
				}
			);
		return submittedAssignmentResult;
	},
    getProductvariantAssignmentResults: async ({ commit }, productvariantId) => {
		commit("setLoading", true);
		var submittedAssignmentResults = await assignmentService.getProductvariantAssignmentResults(productvariantId)
        .then(
			(submittedAssignmentResults) => {
				
				commit("setLoading", false);
				return submittedAssignmentResults.payload;
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
		return submittedAssignmentResults;
	},
    submitAssignmentFeedback: async ({ commit }, assignmentResult) => {
		commit("setLoading", true);

		var submittedAssignmentFeedback = await assignmentService
		.submitAssignmentFeedback(assignmentResult).then((data) => {
					commit("setLoading", false);
					let mutationData = { 
						payload: data.payload,
						type: 'feedback',
						assignmentResult: assignmentResult,
					};
					commit("updateAssignmentResult", mutationData);
					return data.payload;
				},
				(error) => {
					commit("setLoading", false);
				}
			);
		return submittedAssignmentFeedback;
	},
	submitAssignmentResult: async ({ commit }, assignmentResult) => {
		commit("setLoading", true);
		var submittedAssignmentResult = await assignmentService.submitAssignmentResult(assignmentResult)
        .then(
			(assignmentResult) => {
				
				commit("setLoading", false);
				return assignmentResult.payload;
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
		return submittedAssignmentResult;
	}
};

const mutations = {
	setLoading(state, data) {
		state.loading = data;
	},
	updateAssignmentResult(state, data) {
		const userId = data.assignmentResult.UserId;
		const contentId = data.assignmentResult.ContentId;
		const payload = data.payload;
		const type = data.type;
	  
		let clonedAssignments = { ...state.assignments };
	  
		if (clonedAssignments[contentId]) {
		  let assignmentObject = clonedAssignments[contentId];
	  
		  const assignmentIds = Object.keys(assignmentObject);
	  
		  assignmentIds.forEach((assignmentId) => {
			if (assignmentObject[assignmentId].user.id === userId) {
			  if (type === 'marks') {
				assignmentObject[assignmentId] = payload;
			  } else if (type === 'feedback') {
				let studentAssignment = assignmentObject[assignmentId];
				let submissions = studentAssignment.submissions || [];
				
				const index  = submissions.findIndex(submission => submission.id === payload.id);
				if(index > -1 && index < submissions.length){
					submissions[index] = payload
				}

				assignmentObject[assignmentId].submissions = submissions;
			  }
			}
		  });
	  
		  clonedAssignments[contentId] = assignmentObject;
		}
		state.assignments = clonedAssignments;
	  }
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};