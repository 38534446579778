<template>
	<div class="invoices">
		<BannerSmall>
			<div class="banner-title-card">
				<h1 class="banner-title">Invoices</h1>
			</div>
		</BannerSmall>
		<div class="invoice-page-container container">
			<div
				v-if="invoicesByUserId[user.id] && invoicesByUserId[user.id].length > 0"
				class="invoice-content-container"
			>
				<div class="invoice-table-head">
					<div class="table-head-item">Invoice ID</div>
					<div class="table-head-item">Issue Date</div>
					<div class="table-head-item">Amount</div>
					<!-- <div class="table-head-item">Balance</div> -->
					<div class="table-head-item">Status</div>
					<div class="table-head-item">Pay</div>
				</div>
				<div class="invoice-table-body">
					<div
						v-for="item in invoicesByUserId[user.id]"
						:key="item.id"
						class="invoice-table-row custom-accordian"
					>
						<div
							:id="item.id"
							:href="`#invoice-${item.id}`"
							:class="[
								'invoice-detail-head custom-accordian-head',
								{
									expanded: invoiceExpanded == item.id,
									'status-danger': item.status.toLowerCase() == 'unpaid',
									'status-success': item.status.toLowerCase() == 'paid',
									'status-warning':
										item.status.toLowerCase() == 'partially paid',
								},
							]"
							@click="toggleAccordian"
						>
							<i
								:class="[
									'fas fa-angle-down collapse-caret',
									{ expanded: invoiceExpanded == item.id },
								]"
							></i>
							<div class="table-row-item first">
								{{ item.id.toString().padStart(5, "0") }}
							</div>
							<div class="table-row-item">
								{{ new Date(item.invoiceDate).toDateString() }}
							</div>
							<div class="table-row-item">
								{{ invoiceAmount(item) }}
							</div>
							<!-- <div class="table-row-item">-</div> -->
							<div class="table-row-item">
								<span
									:class="[
										'status-tag',
										{
											success: item.status.toLowerCase() == 'paid',
											danger: item.status.toLowerCase() == 'unpaid',
											warning: item.status.toLowerCase() == 'partially paid',
										},
									]"
									>{{ item.status }}</span
								>
							</div>
							<div class="table-row-item">
								<PayInvoiceModal
									:invoice="item"
									:showModal="showInvoiceModal"
									:item="item"
									:totalAmount="invoiceAmount(item).toString()"
								/>
							</div>
						</div>
						<!-- <transition name="smooth"> -->
						<div
							:id="`invoice-${item.id}`"
							:class="[
								'invoice-detail-body custom-accordian-body',
								{
									expanded: invoiceExpanded == item.id,
									'status-danger': item.status.toLowerCase() == 'unpaid',
									'status-success': item.status.toLowerCase() == 'paid',
									'status-warning':
										item.status.toLowerCase() == 'partially paid',
								},
							]"
						>
							<div class="table-responsive">
								<table class="table">
									<thead class="invoice-detail-table-head">
										<th class="invoice-detail-head-item">Course</th>
										<th class="invoice-detail-head-item">Payment Plan</th>
										<th class="invoice-detail-head-item">Due Date</th>
										<th class="invoice-detail-head-item">Amount</th>
										<th class="invoice-detail-head-item">Discount</th>
										<th class="invoice-detail-head-item">Status</th>
									</thead>
									<tr
										v-for="detail in item.details"
										:key="detail.id"
										class="invoice-detail-table-row"
									>
										<td class="invoice-detail-row-item">
											{{
												detail.productName
													? detail.productName
													: detail.productID
													? detail.productID
													: "-"
											}}
										</td>
										<td class="invoice-detail-row-item">
											{{ detail.planName }}
											{{
												detail.planDetailTitle != detail.planName
													? " (" + detail.planDetailTitle + ")"
													: ""
											}}
											<!-- {{ detail.planName ? detail.planName : "-" }} -->
										</td>
										<td class="invoice-detail-row-item">
											{{ new Date(detail.dueDate).toDateString() }}
										</td>
										<!-- <td class="invoice-detail-row-item">31 - Jan</td> -->
										<td class="invoice-detail-row-item">
											{{ currencyParser(detail, item.currency) }}
										</td>
										<td class="invoice-detail-row-item">
											{{ detail.discount ? detail.discount : "-" }}
										</td>
										<!-- <td class="invoice-detail-row-item">3000</td> -->
										<td class="invoice-detail-row-item">
											<span
												:class="[
													'status-tag',
													{
														success: detail.status.toLowerCase() == 'paid',
														danger: detail.status.toLowerCase() == 'unpaid',
														warning:
															detail.status.toLowerCase() == 'partially paid',
													},
												]"
												>{{ detail.status }}</span
											>
										</td>
									</tr>
								</table>
							</div>
						</div>
						<!-- </transition> -->
					</div>
				</div>
			</div>
			<div
				class="d-flex justify-content-center gap-3"
				v-if="!invoicesByUserId[user.id] && loading"
			>
				<b-spinner></b-spinner> <span>Loading...</span>
			</div>
			<div
				v-else-if="
					invoicesByUserId[user.id] && invoicesByUserId[user.id].length == 0
				"
				class="container-fluid text-center p-5"
			>
				<h2 class="text-bold text-muted">No invoices found.</h2>
			</div>
		</div>
	</div>
</template>

<script>
import BannerSmall from "../components/Layout/BannerSmall.vue";
import PayInvoiceModal from "../components/Invoice/PayInvoiceModal.vue";
import { mapActions, mapState } from "vuex";
import { toCurrencyString } from "../helpers/FormatHelpers";
// import CookieHelpers from "../helpers/CookieHelpers";

export default {
	name: "Invoice",
	components: { BannerSmall, PayInvoiceModal },
	data() {
		return {
			invoiceExpanded: "1",
			// user: null,
			showInvoiceModal: false,
		};
	},
	methods: {
		...mapActions("pricing", ["fetchInvoiceByUserId"]),

		toggleAccordian(e) {
			console.log(e.target.parentNode.id);
			if (this.invoiceExpanded == e.target.parentNode.id) {
				this.invoiceExpanded = "-1";
			} else {
				this.invoiceExpanded = e.target.parentNode.id;
			}
		},
		payInvoice() {
			console.log("paynow clicked");
			// this.$router.push("/shop/checkout");
			this.showInvoiceModal = true;
		},
		invoiceAmount(invoice) {
			var total = 0;
			invoice.details.forEach((d) => (total += d.amount - d.discount));
			return total ? toCurrencyString(total, invoice.currency, 0) : 0;
		},
		currencyParser(detail, currency) {
			return detail.amount ? toCurrencyString(detail.amount, currency, 0) : 0;
		},
	},
	computed: {
		...mapState("pricing", ["invoicesByUserId", "loading"]),
		...mapState("user", ["user"]),
	},
	created() {
		// this.user = JSON.parse(localStorage.getItem("user"));
		if (!this.invoicesByUserId[this.user.id]) {
			this.fetchInvoiceByUserId(this.user.id).then(() => {
				console.log("invoices by customer id fetched");
				this.invoiceExpanded = this.invoicesByUserId[this.user.id][0].id;
			});
		} else {
			this.invoiceExpanded = this.invoicesByUserId[this.user.id][0].id;
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "invoice-page-loaded", data: null });
		});
	},
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
	.status-danger {
		border-left: 6px solid var(--color-danger) !important;
	}

	.status-success {
		border-left: 6px solid var(--color-success) !important;
	}

	.status-warning {
		border-left: 6px solid var(--color-warning) !important;
	}

	.invoice-page-container {
		padding: 0 1rem !important;
	}

	.table-row-item {
		padding: 1rem 0.25rem !important;
		align-items: center;
		justify-content: center;
		font-size: 0.875rem !important;
		/* pointer-events: none; */
	}
	.table-head-item {
		padding: 1rem 0.25rem !important;
		font-size: 1rem !important;
		line-height: 1 !important;
	}

	.table-head-item:first-of-type {
		padding: 1rem !important;
	}

	.banner-title-card {
		top: 2rem !important;
	}

	.collapse-caret {
		font-size: 1rem !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
		line-height: 0.75;
		left: 0.375rem !important;
	}
	.collapse-caret.expanded {
		top: 50% !important;
		transform: translateY(-50%) rotate(-180deg) !important;
		/* transform:  !important; */
	}

	.status-tag {
		padding: 0.25rem 0.375rem !important;
		font-size: 0.75rem;
	}

	.invoice-detail-head-item {
		padding: 0.5rem !important;
	}

	.invoice-detail-row-item {
		padding: 0.5rem !important;
	}
}

.invoices .banner-small {
	height: 8rem;
	margin-bottom: 3rem;
}

.banner-title-card {
	background-color: var(--color-white);
	padding: 2.75rem 3rem 1.75rem 1.5rem;
	width: fit-content;
	border-radius: 8px;
	border: 1px solid #a3a3a3;
	position: absolute;
	top: 4rem;
	box-shadow: 0 0 16px #c9c9c929;
}

.invoices .banner-title-card {
	min-width: 20rem !important;
}

.banner-title {
	/* font-size: 2.5rem;
	font-weight: bold; */
	font-weight: bold;
	font-size: 2rem;
	color: #000000;
	/* padding: 2.5rem 0; */
	margin: 0;
}

.banner-title:before {
	content: "";
	width: 2rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -1rem;
	left: 0;
	position: relative;
}

.invoice-page-container {
	padding: 2.125rem 0;
}

.invoice-page-container .border-input {
	min-width: unset !important;
	padding: 1.25rem 1rem;
	font-size: 1rem;
	border-width: 2px;
	font-weight: 500 !important;
	border-radius: 6px;
}

.invoice-search {
	display: flex;
	align-items: center;
	position: relative;
	flex-grow: 6;
	margin: 0 !important;
}

.invoice-content-container {
	display: flex;
	flex-direction: column;
}

.invoice-table-head {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
}

.table-head-item {
	display: flex;
	padding: 2rem;
	text-decoration: none;
	color: #959595;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.25;
}

.invoice-detail-head {
	position: relative;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 0.95fr 1fr 1fr 1fr 1fr;
	background-color: #f5f5f5;
	/* padding: 2.375rem; */
	border-radius: 0.25rem;
	cursor: pointer;
}

.invoice-detail-head.expanded {
	border-radius: 0.25rem 0.25rem 0 0;
}

.invoice-detail-body {
	display: none;
	border-radius: 0 0 0.25rem 0.25rem;
	background-color: #fafafa;
}

.invoice-detail-body.expanded {
	display: block;
}

.invoice-table-row {
	margin-bottom: 0.5rem;
}

.table-row-item {
	display: flex;
	color: #000000;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	padding: 2.375rem 0 2.375rem 2rem;
	/* pointer-events: none; */
}

.table-row-item.first {
	padding-left: 3.5rem;
}

.status-danger {
	border-left: 12px solid var(--color-danger);
}

.status-success {
	border-left: 12px solid var(--color-success);
}

.status-warning {
	border-left: 12px solid var(--color-warning);
}

.collapse-caret {
	position: absolute;
	font-size: 1.5rem;
	top: 2.375rem;
	line-height: 0.75;
	left: 1.25rem;
}

.collapse-caret.expanded {
	transform: rotate(-180deg);
}

.invoice-detail-table-head {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
}

.invoice-detail-head-item {
	color: #7c7c7c;
	font-weight: 500;
	font-size: 0.9rem;
	padding: 1.25rem 1.5rem;
}

.invoice-detail-table-row {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	border: none !important;
}

.invoice-detail-row-item {
	color: #000000;
	font-weight: 500;
	font-size: 0.9rem;
	padding: 1.25rem 1.5rem;
	border-top: 1px solid #dbdbdb;
}
.status-tag {
	border-radius: 0.25rem !important;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
}

.status-tag.success {
	background-color: #00c672;
}

.status-tag.danger {
	background-color: var(--color-danger);
}

.status-tag.warning {
	background-color: var(--color-warning);
}

status-warning .custom-accordian-head {
	cursor: pointer;
}

.smooth-enter-active,
.smooth-leave-active {
	overflow: hidden;
	transition: height 1s linear;
}
.smooth-enter,
.smooth-leave-to {
	max-height: 0;
}
</style>
