var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BannerSmall',[_c('div',{class:[
        'product-page-banner-container',
        { purchased: _vm.purchased, mobile: _vm.isMobile, 'col-lg-8 col-md-12': !_vm.isMobile },
      ]},[((_vm.purchased || _vm.isMobile) && _vm.productVariantData != null)?_c('ol',{staticClass:"breadcrumb",class:{ mobile: _vm.isMobile }},[_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Courses")]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(" "+_vm._s(_vm.productVariantData.product.title)+" ")]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(_vm.productVariantData.title)+" ")])]):_vm._e(),(!_vm.loading && _vm.productVariantData != null && !_vm.purchased)?_c('div',{staticClass:"product-image mobile-only"},[_c('img',{attrs:{"src":`${_vm.thumbnailPath}`,"alt":_vm.productVariantData.product.title}}),(_vm.videoPreviewUrl)?_c('i',{staticClass:"fa fa-play-circle video-thumbnail-icon",on:{"click":_vm.openThumbnailPreview}}):_vm._e()]):_vm._e(),(!_vm.loading && _vm.productVariantData != null)?_c('div',{class:[{ 'product-title-card': _vm.purchased }]},[(
            !_vm.purchased &&
            _vm.productVariantTrainers &&
            _vm.productVariantTrainers.length > 0
          )?_c('h2',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"product-creator",attrs:{"title":_vm.productVariantTrainers
              .map((p) => {
                return p.firstName + ' ' + p.lastName;
              })
              .toString()
              .split(',')
              .join(',\n')}},[_vm._v(" "+_vm._s(_vm.productVariantTrainers[0].firstName + " " + _vm.productVariantTrainers[0].lastName)+" "),(_vm.productVariantTrainers.length > 1)?_c('span',{staticClass:"text-muted"},[_vm._v("+ "+_vm._s(_vm.productVariantTrainers.length - 1)+" trainer"+_vm._s(_vm.productVariantTrainers.length > 2 ? "s" : ""))]):_vm._e()]):_vm._e(),_c('h1',{class:['product-title', { purchased: _vm.purchased }]},[_vm._v(" "+_vm._s(_vm.productVariantData.product.title)+" ")]),_c('p',{class:['product-category text-bold', { purchased: _vm.purchased }]},[_vm._v(" "+_vm._s(_vm.productVariantData.title)+" ")]),_c('div',{staticClass:"d-flex align-items-center gap-2"},[(
              _vm.purchased &&
              _vm.productVariantTrainers &&
              _vm.productVariantTrainers.length > 0
            )?_c('h2',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"product-creator purchased",attrs:{"title":_vm.productVariantTrainers
                .map((p) => {
                  return p.firstName + ' ' + p.lastName;
                })
                .toString()
                .split(',')
                .join(',\n')}},[_vm._v(" "+_vm._s(_vm.productVariantTrainers[0].firstName + " " + _vm.productVariantTrainers[0].lastName)+" "),(_vm.productVariantTrainers.length > 1)?_c('span',{staticClass:"text-muted"},[_vm._v("+ "+_vm._s(_vm.productVariantTrainers.length - 1)+" trainer"+_vm._s(_vm.productVariantTrainers.length > 2 ? "s" : ""))]):_vm._e()]):_vm._e(),(
              _vm.purchased &&
              _vm.productVariantTrainers &&
              _vm.productVariantTrainers.length > 0
            )?_c('span',[_vm._v("|")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.productVariantData.shortDescription ? _vm.productVariantData.shortDescription : ""))])]),(!_vm.purchased)?_c('div',{staticClass:"top-seller-tag"},[(_vm.productVariantData.ratings.value)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ratings-value"},[_vm._v(_vm._s(_vm.productVariantData.ratings.value ? parseInt(_vm.productVariantData.ratings.value).toFixed(1) : ""))]),_c('b-form-rating',{attrs:{"value":_vm.productVariantData.ratings.value,"readonly":""}})],1):_c('div',[_vm._v(" ")])]):_vm._e()]):_c('div',{staticClass:"skeleton"},[_c('b-skeleton',{attrs:{"width":"20%"}}),_c('b-skeleton',{staticClass:"mt-3",attrs:{"type":"button","width":"50%"}}),_c('b-skeleton',{staticClass:"mt-3",attrs:{"width":"30%"}}),_c('div',{staticClass:"d-flex mt-3"},[_c('b-skeleton',{attrs:{"width":"10%"}}),_c('b-skeleton',{staticClass:"m-2 mt-0",attrs:{"width":"10%"}}),_c('b-skeleton',{staticClass:"m-2 mt-0",attrs:{"width":"10%"}}),_c('b-skeleton',{staticClass:"m-2 mt-0",attrs:{"width":"10%"}})],1)],1)]),_c('b-modal',{attrs:{"visible":_vm.showVideoPreview,"centered":"","hide-footer":"","hide-header":"","size":"xl","body-class":"video-modal-body","modal-class":"video-modal"},on:{"change":function($event){return _vm.closeVideoPreviewModal()}}},[_c('div',{staticClass:"body-container"},[_c('video',{staticClass:"video-preview",attrs:{"src":_vm.videoPreviewUrl,"controls":"","autoplay":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }