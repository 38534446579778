<template>
	<div>
		<div class="container-fluid">
			<ul class="tabs">
				<router-link to="/account/login"
					><li :class="{ active: currentRouteName == 'Login', tab: true }">
						Login
					</li></router-link
				>
				<router-link to="/account/register"
					><li :class="{ active: currentRouteName == 'Register', tab: true }">
						Register
					</li></router-link
				>
			</ul>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "Account",
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
	},
};
</script>

<style scoped>
/* .login-logo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 0;
}

.login-logo img {
	padding: 15px 0px 5px 0px;
	margin: auto;
	height: 56px;
} */

.tabs {
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 24px 0 24px;
}

.tab {
	padding: 12px 8px;
	font-size: 18px;
	color: #111;
	display: inline-block;
	width: 100px;
	text-transform: uppercase;
	border-bottom: transparent solid 3px;
	cursor: pointer;
	font-weight: 500;
}

.active {
	border-bottom: var(--primary-color) solid 3px;
}
</style>
