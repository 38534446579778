<template>
	<div>
		<div
			v-if="displayMode === 'carousel' && productListData.length > 0"
			class="position-relative"
		>
			<VueSlickCarousel
				ref="product_carousel"
				class="product-listing-container container-fluid"
				v-bind="settings"
			>
				<ProductListItem
					v-for="productVariant in productListData"
					:key="productVariant.id"
					:data="productVariant"
					:purchased="false"
				/>
			</VueSlickCarousel>
			<a class="carousel-arrow" role="button" @click="prevSlide"
				><i class="fas fa-angle-left"></i
			></a>
			<a class="carousel-arrow right" role="button" @click="nextSlide"
				><i class="fas fa-angle-right"></i
			></a>
		</div>
		<ProductFilters v-if="showFilters" :showFilters="showFilters" :filters="filters">
      <div
          class="d-flex justify-content-center gap-3 width-100"
          v-if="loading"
      >
        <b-spinner></b-spinner> <span class="font-500">loading...</span>
      </div>
			<div
				:class="[
					'product-listing-container',
					{ grid: displayMode === 'grid', 'list-view': displayMode === 'list' },
				]"
				v-else-if="
					displayMode !== 'carousel' &&
					productListData &&
					productListData.length > 0
				"
			>
				<ProductListItem
					:class="[
						{
							grid: displayMode === 'grid',
							'list-view': displayMode === 'list',
						},
					]"
					v-for="productVariant in productListData"
					:key="productVariant.id"
					:data="productVariant"
					:purchased="false"
				/>
			</div>
			<div
				class="d-flex justify-content-center gap-3 mt-5 width-100"
				v-else-if="loading"
			>
				<b-spinner></b-spinner> <span class="font-500">loading...</span>
			</div>
			<div v-else class="product-listing-container">
				<div class="no-data">No courses found</div>
			</div>
		</ProductFilters>
		<div
			class="d-flex justify-content-center gap-3 width-100"
			v-else-if="loading"
		>
			<b-spinner></b-spinner> <span class="font-500">loading...</span>
		</div>
		<div
			v-if="productListData.length == 0 && !showFilters && !loading"
			class="product-listing-container-data"
		>
			No courses found
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ProductListItem from "./ProductListItem.vue";
import { PRODUCT_CAROUSEL_SETTINGS } from "../../constants/productCarouselSettings";
import VueSlickCarousel from "vue-slick-carousel";
import ProductFilters from "./ProductFilters.vue";
export default {
	name: "ProductList",
	data() {
		return {
			categoryActive: "0",
			products: this.allProductVariants ? this.allProductVariants : [],
			// settings: carouselSettings ? carouselSettings : PRODUCT_CAROUSEL_SETTINGS,
			showAdvancedFilters: false,
			displayMode: "list",
		};
	},
	props: {
		viewMode: String,
		showFilters: Boolean,
		productListData: Array,
		carouselSettings: { type: Object, default: null },
    filters: Object
	},
	components: {
		ProductListItem,
		ProductFilters,
		VueSlickCarousel,
	},
	methods: {
		...mapActions("productVariant", ["fetchProductVariants"]),
		nextSlide() {
			this.$refs.product_carousel.next();
			if (this.currentReminderSlide < this.totalReminderSlides)
				this.currentReminderSlide += 1;
		},
		prevSlide() {
			this.$refs.product_carousel.prev();
			if (this.currentReminderSlide > 1) this.currentReminderSlide -= 1;
		},
	},
	computed: {
		...mapState("productVariant", ["loading"]),
		...mapGetters("productVariant", ["allProductVariants"]),
		settings() {
			return this.carouselSettings
				? this.carouselSettings
				: PRODUCT_CAROUSEL_SETTINGS;
		},
	},
	created() {
		this.displayMode = this.viewMode ?? "grid";
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.category-pills-container {
		padding-left: 0 !important;
	}
	.category-pill {
		font-size: 0.9rem !important;
	}
	.product-listing-container {
		padding: 0 !important;
		row-gap: 1rem !important;
		display: flex;
		flex-direction: column !important;
	}
	.product-listing-container.list-view {
		width: 100% !important;
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: #eeeeee;
}

.category-pills-container {
	width: 100%;
	/* overflow: hidden; */
	padding-right: 100px;
	overflow-x: auto;
}

.category-pills {
	border-bottom: 1px solid transparent !important;
	flex-wrap: nowrap;
}

.category-pill {
	padding: 10px 20px;
	border-radius: 2rem;
	transition: all 0.2s ease-in-out;
	margin-left: 4px;
	/* font-weight: 500; */
	font-size: 19px;
	white-space: nowrap;
}

.category-pill.active,
.category-pill:hover {
	background-color: var(--primary-color);
}

.product-listing-container:not(.slick-slider) {
	overflow: hidden !important;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	row-gap: 1.25rem;
	flex-wrap: wrap;
	flex-grow: 1;
}

.product-listing-container.list-view {
	flex-direction: column !important;
	row-gap: 1rem !important;
	width: 60%;
	flex-grow: 0;
	/* flex-wrap: wrap; */
}

.carousel-product-item {
	margin: 0 5px;
}

.no-data {
	text-align: center;
	padding: 2rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}

.carousel-arrow {
	/* margin: 0.5rem; */
	font-size: 2rem;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 1;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 50%;
	border: 2px solid var(--secondary-color);
	background-color: #ffffff;
	box-shadow: 3px 3px 6px #00000029;
	color: var(--secondary-color);
	text-decoration: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.carousel-arrow.right {
	right: 0;
}
</style>
