var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login-register"}},[_c('h1',{staticClass:"heading-bold-20"},[_vm._v("Forgot Password")]),_c('div',{staticClass:"width-100"},[_c('form',[(_vm.isOTPVerified)?_c('div',[_c('div',{staticClass:"form-group position-relative"},[_c('i',{staticClass:"input-icon-left fas fa-unlock-alt"}),((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],class:[
							'form-control input-w-icon',
							{ 'text-danger': _vm.pwdError },
						],attrs:{"id":"newPassword","placeholder":"Enter your new password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.newPassword)?_vm._i(_vm.newPassword,null)>-1:(_vm.newPassword)},on:{"change":function($event){var $$a=_vm.newPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.newPassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.newPassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.newPassword=$$c}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],class:[
							'form-control input-w-icon',
							{ 'text-danger': _vm.pwdError },
						],attrs:{"id":"newPassword","placeholder":"Enter your new password","type":"radio"},domProps:{"checked":_vm._q(_vm.newPassword,null)},on:{"change":function($event){_vm.newPassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],class:[
							'form-control input-w-icon',
							{ 'text-danger': _vm.pwdError },
						],attrs:{"id":"newPassword","placeholder":"Enter your new password","type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.newPassword=$event.target.value}}}),_c('i',{class:[
							'fas input-icon-right',
							{ 'fa-eye-slash': _vm.showPassword, 'fa-eye': !_vm.showPassword },
						],on:{"click":_vm.toggleShowPassword}})])]):(_vm.isOTPGenerated)?_c('div',[_c('div',{staticClass:"form-group"},[_c('i',{staticClass:"input-icon-left far fa-key"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.OTPCode),expression:"OTPCode"}],staticClass:"form-control input-w-icon",attrs:{"type":"text","name":"OTPCode","id":"OTPCode","maxlength":"6","placeholder":"Enter the OTP sent to your email"},domProps:{"value":(_vm.OTPCode)},on:{"input":function($event){if($event.target.composing)return;_vm.OTPCode=$event.target.value}}})]),_c('div',{staticClass:"d-flex gap-3"},[_c('button',{attrs:{"type":"button","disabled":_vm.timer > 0},on:{"click":_vm.getOTP}},[_vm._v(" Resend OTP ")]),_c('span',{staticClass:"timer"},[_vm._v("0:"+_vm._s(_vm.timer.toString().padStart(2, "0")))])])]):_c('div',[(_vm.isNumber)?_c('div',{staticClass:"form-group"},[_c('vue-tel-input',{class:`form-control ${
							_vm.isPhoneError && _vm.showError ? 'error' : ''
						}`,attrs:{"defaultCountry":"PK","inputOptions":_vm.telInputOptions,"validCharactersOnly":"","autoFormat":true,"mode":"national","invalidMsg":"Please enter a valid phone number","id":"resetInput_number"},on:{"input":_vm.validate,"blur":function($event){_vm.showError = true},"focus":function($event){_vm.showError = false}},model:{value:(_vm.resetInput),callback:function ($$v) {_vm.resetInput=$$v},expression:"resetInput"}}),(_vm.isPhoneError && _vm.showError)?_c('p',{staticClass:"text-danger"},[_vm._v(" Invalid Phone Number ")]):_vm._e()],1):_c('div',{staticClass:"form-group"},[_c('i',{staticClass:"input-icon-left far fa-user"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resetInput),expression:"resetInput"}],staticClass:"input-w-icon form-control",attrs:{"type":"text","name":"resetInput","id":"resetInput","placeholder":"Enter Email to receive OTP","required":"","tabindex":"10","maxlength":"80"},domProps:{"value":(_vm.resetInput)},on:{"input":[function($event){if($event.target.composing)return;_vm.resetInput=$event.target.value},_vm.checkType]}})])]),_c('button',{staticClass:"login-register-btn primary-btn form-control mt-4",attrs:{"disabled":_vm.loading,"type":"submit"},on:{"click":_vm.handleResetPassword}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isOTPGenerated && !_vm.isOTPVerified ? "Next" : "Reset Password")+" ")],1),(!_vm.isOTPGenerated && !_vm.isOTPVerified)?_c('button',{staticClass:"secondary-btn btn-bold form-control mt-4",attrs:{"type":"button"},on:{"click":() => _vm.$router.back()}},[_vm._v(" Back ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }