<template>
  <div v-if="productVariantData">
    <div class="course-content-tabs">
      <ul class="inline-list">
        <draggable
          class="content-items"
          v-model="filteredProductVariantItems"
          v-if="filteredProductVariantItems"
          @start="drag = true"
          @end="drag = false"
          ghost-class="ghost"
          group="content"
          animation="200"
          @change="update($event, productVariantData.id)"
        >
          <CourseContentTab
            v-for="item in filteredProductVariantItems"
            :key="item.id"
            :item="item"
            :activeTab="activeTab"
            @changeTab="changeTab"
            :productVariantID="productVariantId"
            @updateActiveTab="setActiveTab"
          />
          <li
            v-if="mode == 'view' && showAddSubject"
            @click="changeMode('create')"
            class="content-tab"
          >
            <img src="@/assets/images/plus-icon-gray.svg" alt="" />
            Add Subject
          </li>

          <form v-else-if="mode != 'view'" @submit="createDirectory">
            <input
              type="search"
              name="newTab"
              id="newTab"
              class="form-input"
              placeholder="Type Subject title"
              v-model="title"
              @blur="changeMode('view')"
              v-on:keyup.esc="changeMode('view')"
            />
            <b-spinner class="content-loader" small v-if="loading"></b-spinner>
            <button
              v-if="!loading"
              @click="createDirectory"
              type="submit"
              class="content-action-btn save"
            >
              <img src="@/assets/images/check-icon-green.svg" alt="save" />
            </button>
            <button
              v-if="!loading"
              @click="changeMode('view')"
              class="content-action-btn cancel"
              type="button"
              role="button"
            >
              <img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
            </button>
          </form>
        </draggable>
      </ul>
    </div>

    <div class="page-content-container">
      <div class="col-9 tab-body" :id="activeTab">
        <div
          v-for="tabBody in productVariantData.productVariantItems"
          :key="tabBody.id"
          :class="[
            'course-content-body',
            { 'tab-hidden': activeTab != tabBody.id },
          ]"
        >
          <ContentTabBody
            v-if="tabLoaded && activeTab == tabBody.id"
            :tabData="tabData"
            :productVariantData="productVariantData"
            :isBlocked="isBlocked"
            :activeTab="activeTab"
          />
          <div v-if="!tabLoaded && loading">
            <b-skeleton height="12rem" class="mb-4"></b-skeleton>
            <b-skeleton height="12rem" class="mb-4"></b-skeleton>
            <b-skeleton height="12rem" class="mb-4"></b-skeleton>
          </div>
        </div>
        <div
          v-if="
            productVariantData.productVariantItems &&
            productVariantData.productVariantItems.length == 0 &&
            mode == 'view'
          "
          class="no-content"
        >
          <h1 class="title">
            Start creating your Course by adding the first Subject
          </h1>
          <img src="@/assets/images/no-content-icon.svg" />
          <button
            @click="changeMode('create')"
            class="create-section"
            role="button"
          >
            <img src="@/assets/images/add-border-icon.svg" alt="" />
            add subjects
          </button>
        </div>
        <!-- <div v-if="tabData == null">
					You are not allowed to access this chapter
				</div> -->
      </div>
      <div class="col-3 position-relative">
        <div class="info-card general-info">
          <h5 class="title">general information</h5>
          <div class="info-list">
            <div class="info-item">
              <span class="info-label">Batch ID</span>
              <span class="info-value">
                {{ productVariantData.id }}
              </span>
            </div>
            <div class="info-item">
              <span class="info-label">Status</span>
              <span class="info-value status-value"
                ><span class="status-icon"
                  ><img src="@/assets/images/check-icon.svg" alt=""
                /></span>
                {{ productVariantData.status }}</span
              >
            </div>
            <div class="info-item">
              <span class="info-label">Start Date</span>
              <span class="info-value">{{
                this.productVariantData.startDate
                  ? new Date(this.productVariantData.startDate).toDateString()
                  : "-"
              }}</span>
            </div>
            <div class="info-item">
              <span class="info-label">End Date</span>
              <span class="info-value">{{
                this.productVariantData.endDate
                  ? new Date(this.productVariantData.endDate).toDateString()
                  : "-"
              }}</span>
            </div>
          </div>
        </div>
        <div class="info-card course-info">
          <h5 class="title">course information</h5>
          <div class="info-list">
            <!-- <div class="info-item">
							<span class="info-label">Last Modified</span>
							<span class="info-value">{{ new Date().toDateString() }}</span>
						</div>
						<div class="info-item">
							<span class="info-label">Published On</span>
							<span class="info-value">{{
									this.productVariantData.startDate
										? new Date(this.productVariantData.startDate).toDateString()
										: "-"
							}}</span>
						</div> -->
            <!-- <div class="info-item">
							<span class="info-label">Total Revenue</span>
							<span class="info-value">{{ parseCurrency(10000) }}</span>
						</div>
						<div class="info-item">
							<span class="info-label">Number of Enrollments</span>
							<span class="info-value">{{
								numberWithCommas(productVariantData.enrolledStudents)
							}}</span>
						</div>
						<div class="info-item">
							<span class="info-label">Online Users</span>
							<span class="info-value">{{ 25 }}</span>
						</div> -->
            <div class="info-item">
              <span class="info-label">Ratings</span>
              <span class="info-value">
                {{
                  productVariantData.ratings.value
                    ? parseInt(productVariantData.ratings.value).toFixed(1)
                    : ""
                }}
                <b-form-rating
                  v-if="productVariantData.ratings.value"
                  :value="productVariantData.ratings.value"
                  readonly
                ></b-form-rating>
              </span>
            </div>
            <div class="info-item">
              <span class="info-label">Short Description</span>
              <span class="info-value text-right">{{
                productVariantData.shortDescription
                  ? productVariantData.shortDescription
                  : "-"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ContentTabBody from "../Content/ContentTabBody.vue";
import CourseContentTab from "./CourseContentTab.vue";
import formatter from "../../../helpers/FormatHelpers";
import { ProductVariantSubscriptionStatus } from "../../../constants/productVariant";
import { UserType } from "../../../constants/user";
import draggable from "vuedraggable";

export default {
  name: "CourseContent",
  data() {
    return {
      // productVariantData: null,
      activeTab: null,
      tabData: null,
      tabLoaded: false,
      mode: "view",
      title: "",
      loading: false,
      isBlocked: false,
    };
  },
  components: { ContentTabBody, CourseContentTab, draggable },
  props: { productVariantData: Object },
  computed: {
    ...mapState("productVariant", ["productVariants"]),
    ...mapState("content", ["content", "copiedTab"]),
    ...mapState("user", ["user"]),
    productVariantId() {
      return this.$route.params.id;
    },
    showSave() {
      return this.title !== "";
    },
    showAddSubject() {
      if (
        (this.user.type && this.user.type.toLowerCase() == "trainer") ||
        (this.user.role && this.user.role.toLowerCase() == "trainer")
      ) {
        return false;
      } else return true;
    },
    filteredProductVariantItems: {
      get() {
        if (this.user.type == UserType.MANAGEMENT)
          return this.productVariantData.productVariantItems;

        return this.productVariantData.productVariantItems.filter(
          (item) => item.status == ProductVariantSubscriptionStatus.ALLOWED
        );
      },
      set(value) {
        this.productVariantData.productVariantItems = value;
      },
    },
  },
  methods: {
    ...mapActions("productVariant", [
      "getProductVariantById",
      "assignContentToProductVariant",
      "PasteProductVariantItems",
    ]),
    ...mapActions("content", [
      "getContentById",
      "createDirectoryContent",
      "editProductVariantItemsSequence",
    ]),

    changeTab(e) {
      this.activeTab = e.target.id;
    },
    changeMode(mode) {
      if (mode == "view") {
        setTimeout(() => {
          this.mode = mode;
        }, 300);
      } else {
        this.mode = mode;
      }
      // this.mode = mode;
      if (mode == "create") {
        setTimeout(() => {
          document.getElementById("newTab").focus();
        }, 200);
      }
    },
    createDirectory(e) {
      e.preventDefault();
      if (this.title != "" && this.title != null) {
        this.loading = true;
        var contentObject = {
          title: this.title,
          type: "Directory",
          data: {},
          // availableTo: this.productVariantData.endDate,
          // availableFrom: this.productVariantData.startDate,
          isAvailableForDemo: true,
        };
        // if (this.productVariantData.startDate) {
        // 	contentObject.availableFrom = this.productVariantData.startDate;
        // }
        // if (this.productVariantData.endDate) {
        // 	contentObject.availableTo = this.productVariantData.endDate;
        // }
        this.createDirectoryContent(contentObject).then((res) => {
          this.loading = false;
          console.log(res);
          if (typeof res != "string") {
            console.log(`#${res.id}`);
            var assignContentObject = {
              productVariantID: this.productVariantData.id,
              items: [res],
            };
            this.assignContentToProductVariant(assignContentObject).then(() => {
              console.log(
                "assigned content to productVariant",
                assignContentObject
              );
              this.loading = false;
              this.title = "";
              this.mode = "view";
              this.activeTab = res.id;
              // $(`#${res.id}`).click();
            });
          } else {
            this.title = "";
            this.mode = "view";
          }

          // this.getProductVariantById(this.productVariantId);
        });
      } else {
        this.mode = "view";
      }

      // this.mode = "created";
      // this.isCreated = true;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    parseCurrency(amount) {
      return formatter.toCurrencyString(amount, "pkr", 0);
    },
    setActiveTab() {
      console.log(
        "setActiveTab",
        this.productVariantData.productVariantItems[0].id
      );
      this.activeTab = this.productVariantData.productVariantItems[0].id;
      // var tabs = $(".content-tab");
      // console.log(tabs);
      // this.activeTab =
    },
    setFilteredActiveTab() {
      this.activeTab = this.filteredProductVariantItems[0].id;
    },
    pasteContent() {
      console.log("pasting content");
      var assignContentObject = {
        TargetProductVariantID: this.productVariantData.id,
        SubjectContentID: this.copiedTab.id,
      };
      console.log({ assignContentObject });

      this.PasteProductVariantItems(assignContentObject).then(() => {
        this.getProductVariantById(
          assignContentObject.TargetProductVariantID
        ).then((response) => {
          this.filteredProductVariantItems =
            response.productVariantItems.length > 0
              ? response.productVariantItems
              : [];
        });
        console.log("assigned content to productVariant", assignContentObject);
        this.activeTab = this.copiedTab.id;
        this.$toasted.success("Subject pasted successfully", {
          duration: 3000,
          theme: "outline",
          icon: "check",
          position: "bottom-center",
        });
      });
    },
    pasteContentShortcut() {
      console.log("pasting content");
      var assignContentObject = {
        productVariantID: this.productVariantData.id,
        items: [this.copiedTab],
      };
      this.assignContentToProductVariant(assignContentObject).then(() => {
        console.log("assigned content to productVariant", assignContentObject);
        this.activeTab = this.copiedTab.id;
        this.$toasted.success("Subject pasted successfully", {
          duration: 3000,
          theme: "outline",
          icon: "check",
          position: "bottom-center",
        });
      });
    },
    update(e, productVariantID) {
      var contentObj = {};
      var productVariantItemID;
      var afterContentID;

      if (e.added) {
        if (e.added.newIndex != 0) {
          afterContentID =
            this.filteredProductVariantItems[e.added.newIndex - 1].id;
          contentObj.afterProductVariantItemID = afterContentID;
        }
        contentObj = { ...contentObj, productVariantID: productVariantID };
        productVariantItemID = e.added.element.id;
      }
      if (e.moved) {
        if (e.moved.newIndex != 0) {
          afterContentID =
            this.filteredProductVariantItems[e.moved.newIndex - 1].id;
          console.log({
            productvariantitems: this.filteredProductVariantItems,
          });
          contentObj.afterProductVariantItemID = afterContentID;
        }
        contentObj = { ...contentObj, productVariantID: productVariantID };
        productVariantItemID = e.moved.element.id;
      }
      if (!e.removed) {
        this.editProductVariantItemsSequence({
          content: contentObj,
          productVariantItemID: productVariantItemID,
        }).then(() => {
          console.log("editContentSequence content", contentObj);
        });
      }
    },
  },
  created() {
    if (this.productVariantData.productVariantItems.length > 0) {
      //this.activeTab = this.productVariantData.productVariantItems[0].id;
      this.setFilteredActiveTab();
      if (!this.content[this.activeTab]) {
        this.loading = true;
        this.getContentById({ id: this.activeTab }).then((res) => {
          this.loading = false;
          if (res == "122" || res == "123") {
            this.isBlocked = true;
            // } else if (res == "123") {
            // 	this.isBlocked = true;
          } else {
            this.tabData = this.content[this.activeTab];
            this.tabLoaded = true;
          }
        });
      } else {
        this.tabData = this.content[this.activeTab];
        this.tabLoaded = true;
      }
    }
    // });
    // } else {
    // 	this.productVariantData = this.productVariants.items[
    // 		this.productVariantId
    // 	];
    // 	if (this.productVariantData.productVariantItems.length > 0) {
    // 		this.activeTab = this.productVariantData.productVariantItems[0].id;
    // 		if (!this.content[this.activeTab]) {
    // 			this.getContentById(this.activeTab).then(() => {
    // 				this.tabData = this.content[this.activeTab];
    // 				this.tabLoaded = true;
    // 			});
    // 		} else {
    // 			this.tabData = this.content[this.activeTab];
    // 			this.tabLoaded = true;
    // 		}
    // 	}
    // }
  },
  watch: {
    activeTab: function () {
      this.tabLoaded = false;
      if (!this.content[this.activeTab]) {
        this.loading = true;
        this.getContentById({ id: this.activeTab }).then((res) => {
          this.loading = false;
          if (res == "122" || res == "123") {
            this.isBlocked = true;
          }
          this.tabData = this.content[this.activeTab]
            ? this.content[this.activeTab]
            : null;
          this.tabLoaded = true;

          // this.tabData = this.content[this.activeTab]
          // 	? this.content[this.activeTab]
          // 	: null;
          // this.tabLoaded = true;
        });
      } else {
        this.tabData = this.content[this.activeTab]
          ? this.content[this.activeTab]
          : null;
        this.tabLoaded = true;
      }
    },
    content: function () {
      this.tabData = this.content[this.activeTab]
        ? this.content[this.activeTab]
        : null;
    },
  },
  mounted() {
    $(() => {
      $(".course-content-tabs").contextMenu({
        selector: ".inline-list",
        callback: (key, options) => {
          if (key == "paste") {
            this.pasteContent();
          } else if (key == "pasteshortcut") {
            this.pasteContentShortcut();
          }
        },
        items: {
          pasteshortcut: {
            name: "Paste copied Subject (With Sync)",
            icon: "paste",
            disabled: () => !this.copiedTab,
          },
          paste: {
            name: "Paste copied Subject (Without Sync)",
            icon: "paste",
            disabled: () => !this.copiedTab,
          },
        },
      });
    });
  },
};
</script>

<style scoped>
.content-items {
  display: flex;
}

.course-content-tabs {
  padding: 0 1.125rem;
  background: #f9f9f9;
  box-shadow: 0px 0px 6px rgba(185, 185, 185, 0.25);
  height: 4.875rem;
  width: 100%;
  overflow-x: auto;
}

.course-content-tabs .inline-list {
  height: 100%;
  align-items: flex-end;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.content-tab {
  padding: 1.5rem 2.5rem;
  column-gap: 0.5rem;
  color: #838383;
  border-bottom: 2px solid transparent;
  /* height: 100%; */
  flex-shrink: 0;
  position: relative;
}

.content-tab.active {
  border-bottom: 2px solid var(--primary-color);
}

.course-content-body {
  padding: 1.5rem;
}

.course-content-body.tab-hidden {
  display: none;
}

.page-content-container {
  background-color: #f5f5f5;
  height: calc(100vh - 9.875rem);
  /* padding: 1.875rem; */
  overflow-y: auto;
  display: flex;
  position: relative;
}

.form-input {
  border: 0;
  font-size: 1rem;
  outline: none;
  padding: 0.5rem 0;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  background-color: transparent;
  text-align: center;
}

.form-input::-webkit-input-placeholder {
  font-size: 1rem !important;
}

.course-content-tabs form {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 100%;
}

.content-action-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  /* padding: 0.375rem; */
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1.5rem;
}

.content-action-btn.save {
  border: 1px solid #21cf47;
  color: #21cf47;
  position: absolute;
  right: -1rem;
}

.content-action-btn.cancel {
  border: 1px solid #ff0000;
  color: #ff0000;
  position: absolute;
  right: -3rem;
}

.info-card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 6px 3px 6px rgba(185, 185, 185, 0.07);
  border-radius: 6px;
  padding: 1.5rem;
  width: calc(25% - 6rem);
}

.info-card.general-info {
  position: fixed;
  top: 11.5rem;
}

.info-card.course-info {
  position: fixed;
  top: 27rem;
}

.info-card .title {
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 1.25rem;
}

.info-card .info-list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.info-card .info-list .info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info-card .info-list .info-item .info-label {
  color: #727272;
}

.info-card .info-list .info-item .info-value {
  color: #000000;
  display: flex;
  align-items: center;
}

.info-card .info-item .info-value.status-value {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  color: var(--primary-color);
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.status-value .status-icon {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}

.status-value .status-icon img {
  width: 0.5rem;
}

.info-value .form-control {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent !important;
  border: none;
  height: unset !important;
  margin: 0;
  margin-left: 0.75rem !important;
  padding: 0;
  font-size: 1rem !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: none;
}

.no-content {
  padding: 4.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 4rem;
}

.no-content .title {
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  text-align: center;
  color: #000000;
}

.no-content img {
  width: 13.625rem;
  height: 13.625rem;
}

.no-content .create-section {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.875rem 1.125rem;
  font-size: 1.125rem;
  line-height: 1.37;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  color: #000000;
}

.no-content .create-section img {
  width: 1.25rem;
  height: 1.25rem;
}

.content-loader {
  position: absolute;
  right: -1rem;
}
</style>
