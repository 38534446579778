<template>
	<div class="container-fluid assignment-container mt-4" v-if="data">
		<img class="page-view-icon" style="margin-right: 15px; width: 50px; height: 50px;"
			src="@/assets/images/exam-test.png" />
		<div style="width: 100%;" class="row">
			<div class="col-lg-9 col-md-8 col-sm-12">
				<h3 style="margin: 0px;">{{ contentData.title }}</h3>
				<h4 style="margin: 0px;">
					<small class="text-dark">{{ productVariantData.product.title }} ({{ productVariantData.title }})</small>
				</h4>
				<small v-if="submittedAssignment" ><b :style="'color:' + currentSubmissionStatusColor">{{ currentSubmissionStatus }}</b> | <b>Submitted At: </b> {{ 
					new Date(assignmentSubmittedAt).toDateString() }}  {{ 
						new Date(assignmentSubmittedAt).toLocaleTimeString() }}</small>
				<div class="line3 mt-2">
					<h6 style="font-size: small;" v-if="data.Marks">{{ data.Marks }} Marks</h6>
					<h6 style="flex: 1; text-align: right; font-size: small;" v-if="data.Deadline"><b>Submission Deadline: </b> {{ new
						Date(data.Deadline).toDateString() }} {{ new Date(data.Deadline).toLocaleTimeString() }}</h6>
				</div>
				<hr style="color: var(--primary-color); margin-top: 2px;" class="mb-4" />
				<div id="editor" v-html="data.Discription"></div>
				<div class="row mt-4" v-if="data.Files">

					<div class="pr-3 col-lg-3 col-md-4" v-for="file in data.Files">
						<a class="card file-card" download :href="file.Url" target="_blank">

							<h6 :title="file.Name">
								{{ file.Name }}
								<small><br />{{ file.Type }}</small>
							</h6>
							<span @click.stop style="display: contents;">
								<a :href="file.Url" target="_blank" download="true" class="downloadbtn"> <i
										style="margin-top: 60%;" class="fa fa-download"></i></a>
							</span>
						</a>
					</div>
				</div>
			</div>
			<div class=" col-lg-3 col-md-4 p-4 pl-5">
				<div class="card p-3">
					<div style="display: flex; align-items: end;" class="mb-2">
						<h5>Your Submissions</h5>
						<h6 v-if="isMissing"
							:style="`margin-left: 15px; flex: 1; text-align: right; color: ${currentSubmissionStatusColor}`">
							{{ currentSideSubmissionStatus }}</h6>
					</div>

					<div v-if="submittedFiles && submittedFiles.length > 0">
						<a v-for="file in submittedFiles" :class="'card file-card '+file.status"  @click.stop target="_blank">
							<b-progress class="progress m-0" v-if="file.status == 'uploading'" :value="file.uploadingProgress" :max="100"
								animated></b-progress>
							<label :title="file.name">
								{{ file.name }}
								<small><br />{{ file.type }}</small>
								<small v-if="file.status == 'submitted'" class="text-success"><br />SUBMITTED</small>
								<small v-if="file.status == 'uploaded'" class="text-primary"><br />Uploaded</small>
								<small v-if="file.status == 'pending'" style="color: gray;"><br />Waiting For Upload</small>
								<small v-if="file.status == 'failed'" ><br />Failed ! {{file.error}}</small>
							</label>
							<div v-if="isSubmissionAllowed" @click.stop style="display: contents;">
								<button @click.stop="removeFile(file.id)" class="downloadbtn"> <i style="margin-top: 60%;"
										class="fa fa-times"></i></button>
								</div>
						</a>
					</div>
					<a v-if="isSubmissionAllowed" class="add-btn dropdown-toggle" data-bs-toggle="dropdown" role="button" style="align-items: center;">
						<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
						Add New File
					</a>
					<ul v-if="isSubmissionAllowed" class="dropdown-menu dropdown-menu-end">
						<li class="dropdown-item">Link
						</li>
						<li class="dropdown-item" @click="uploadFile">
							<input type="file" style="display: none;" @change="onFilePicked" multiple ref="fileinput" />
							File
						</li>
					</ul>
					<!-- <keep-alive>
							<vue-dropzone
								ref="myVueDropzone"
								id="dropzone"
								:options="dropzoneOptions"
								:useCustomSlot="true"
								
							>
							  <div class="dropzone-custom-content">
								<h5 class="dropzone-custom-title">
								  Drag and drop to upload file!
								</h5>
								<div class="subtitle">
									<small>
								  ...or click to select a file from your computer
									</small>
								</div>
							  </div>
							</vue-dropzone>
					</keep-alive> -->
					<!-- <button
						v-if="!isSubmitDisabled"
						data-bs-toggle="dropdown"
						id="add-dropdown-btn"
						class="add-btn dropdown-toggle mt-1"
						role="button"
					>
						<div style="align-items: center;"
						>
								<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
								Upload your Files
						</div>
					</button>
					 -->
					<button v-if="isSubmissionAllowed" @click="makeAssignmentSubmission" :disabled="isSubmitDisabled" data-bs-toggle="dropdown" id="add-dropdown-btn"
						class="add-btn submit-btn dropdown-toggle mt-3" role="button" >
						Turn In
					</button>
					<p class="text-danger" v-if="isMissing && data.IsLateSubimissionAllowed==false && !isSubmitted">
						Assignment submission date passed away.
					</p>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import CookieHelpers from "./../../helpers/CookieHelpers";
import { mapActions, mapState } from "vuex";
import { POST_FILE_API } from "./../../constants/urls";
import { v4 as uuidv4 } from "uuid";
export default {
	name: "Assignment",
	props: { productVariantData: Object, contentData: Object },
	components: {
		vueDropzone: vue2Dropzone
	},
	data: function () {
		return {			
			quillEditor: null,
			fileSizeLimit:100,
			dropzoneOptions: {
				url: " ",
				thumbnailWidth: 300,
				timeout: 100000000,
				uploadMultiple: true,
				autoProcessQueue: false
			},
			submittedFiles: [],
			isLoading:false,
			submittedAssignment:null,
			assignmentSubmittedAt:null,			
		}
	},
	computed: {

		...mapState("appState", ["apiURL"]),
		data() {
			return this.contentData && this.contentData.extraProperties ? JSON.parse(this.contentData.extraProperties) : null;
		},
		isSubmitted() {
			return this.submittedAssignment && this.submittedAssignment.submittedFiles.length>0;
		},
		isLateSubmission() {
			return this.submittedAssignment && this.submittedAssignment.isLateSubmission;
		},
		isMissing() {
			if (this.data && this.data.Deadline && new Date(this.data.Deadline) < new Date()) {
				return true;
			}
			return false;
		},
		currentSubmissionStatusColor() {
			if (this.isSubmitted) {
				if (this.isLateSubmission)
					return "rgb(var(--bs-danger-rgb))"

				return "#198754"
			}
			if (this.isMissing) {
				return "rgb(var(--bs-danger-rgb))"
			}
			return "#808080"
		},
		currentSubmissionStatus() {
			if (this.isSubmitted) {
				if (this.isLateSubmission)
					return "Late Submission"
				
				return "Submitted"
			}
			if (this.isMissing) {
				return "Submission Date Passed"
			}
			return "Not Submitted Yet"
		},
		currentSideSubmissionStatus() {
			if (this.isSubmitted) {
				if (this.isLateSubmission)
					return "Submitted Late"
				return "Submitted"
			}
			if (this.isMissing && this.data.IsLateSubimissionAllowed==true) {
				return "Date Missed"
			}
			return ""
		},
		isSubmitDisabled() {
			if(this.isLoading)
				true;

			if(this.submittedFiles.length==0)
				return true;
			if(this.submittedFiles.find(x=>x.status=="pending"))
			{
				return true;
			}
			if(!this.submittedFiles.find(x=>x.status=="uploaded"))
			{
				return true;
			}

			return false;
		},
		isSubmissionAllowed(){
			return (!this.isMissing || this.data.IsLateSubimissionAllowed==true);
		}
		
	},
	methods: {
		
		...mapActions("assignment", ["submitAssignment","getSubmittedAssignment"]),
		uploadFile() {
			this.$refs.fileinput.click();
		},
		onFilePicked(event) {
			const files = event.target.files;
			files.forEach(file => {
				this.addFileForUpload(file);
			});

			// const fileReader = new FileReader()
			// fileReader.addEventListener('load', () => {
			// 	this.imageUrl = fileReader.result
			// })
			// fileReader.readAsDataURL(files[0])
		},
		addFileForUpload(file) {
		
			var nameParts = file.name.split(".");
			var extension = nameParts.pop();
			var name = nameParts.join(".").toUpperCase();
			var sizeInMB = (file.size/1024)/1024;
			var isSizeIncreasedLimit = sizeInMB>this.fileSizeLimit;
			 
			this.submittedFiles.push({
				id: uuidv4(),
				name: name,
				file: file,
				extension: extension,
				type: this.fileTypeByExtentions(file.name) ?? this.fileTypeByType(file.type) ?? "File",
				url: null,
				status: isSizeIncreasedLimit?'failed':'pending',
				error: isSizeIncreasedLimit?`File size should be less then ${this.fileSizeLimit}`:null,
				uploadingProgress: 0
			});
			this.uplaodNextFileInQueue();
		},
		fileTypeByExtentions(filename) {
			if (filename.endsWith(".pdf")) {
				return "PDF";
			}
			if (filename.endsWith(".ppt") || filename.endsWith(".pptx")) {
				return "Powerpoint";
			}
			if (filename.endsWith(".xls") || filename.endsWith(".xlsx")) {
				return "Excel Worksheet";
			}
			if (filename.endsWith(".csv")) {
				return "Comma Seperated Values";
			}
			if (filename.endsWith(".doc") || filename.endsWith(".docx")) {
				return "Document";
			}
		},
		fileTypeByType(fileType) {
			fileType = fileType.toLowerCase();
			if (fileType.indexOf("image") > -1) {
				return "Image";
			}
			if (fileType.indexOf("video") > -1) {
				return "Video";
			}

		},
		removeFile(fileId){
			var file=this.submittedFiles.find(x=>x.id==fileId);
			if(file)
			{
				if (file.xhr) {
					file.xhr.abort();
				}
				this.submittedFiles.splice(this.submittedFiles.indexOf(file),1);
				this.uplaodNextFileInQueue();
			}

		},
		uplaodNextFileInQueue() {
			var self=this;
			var minUploadinglimit = 2;
			var inUploadingFiles = this.submittedFiles.filter(x=>x.status.toLowerCase() == "uploading");
			var queuedFile = this.submittedFiles.find(x=>x.status.toLowerCase() != "uploading" && x.status.toLowerCase() == "pending");
			if (inUploadingFiles.length <= (minUploadinglimit - 1) && queuedFile) {
				queuedFile.status = "uploading";
				queuedFile.uploadingProgress = 0;
				var uploadurl = this.apiURL + POST_FILE_API;

				const formData = new FormData();
      			formData.append("entity", "Assignment");
      			formData.append("type", queuedFile.type);
				formData.append('file', queuedFile.file);

				// post form data
				const xhr = new XMLHttpRequest()
				xhr.upload.onprogress = function (event) {
					var percentComplete = Math.floor((event.loaded / event.total) * 100);
					queuedFile.uploadingProgress = percentComplete;
					queuedFile.status = "uploaded";
					queuedFile.status = "uploading";
				};

				// Set up a handler for when the request completes
				xhr.onload = function () {
					if (xhr.status === 201) {
						setTimeout(function () {
							var response=JSON.parse(xhr.responseText);
							if(response && response.payload)
							{
								queuedFile.url=response.payload;
							}
							queuedFile.status = "uploaded";
							self.uplaodNextFileInQueue();
						}, 2000);
					} else {
						queuedFile.status = "failed";
						queuedFile.error = "Due to some error occurred";
						self.uplaodNextFileInQueue();
					}
				};

				xhr.open('POST', uploadurl,);
				queuedFile.xhr = xhr;
				xhr.setRequestHeader("AuthToken", "System123");
				xhr.setRequestHeader("Authorization", `Bearer ${CookieHelpers.getCookie("token")}`);
				xhr.send(formData);
			}
		},
		initializeQuillEditor() {
			var options = {
				theme: 'snow',
				pastePlain: true,
				readOnly: true,
				modules: {
					toolbar: false
				}
			};
			var editor = new Quill('#editor', options);
		},
		makeAssignmentSubmission(){
			if(this.isMissing && this.data.IsLateSubimissionAllowed==false)
				return;
			
			if (this.isSubmitted && this.data.IsReSubimissionAllowed==false )
				return ;
				
			if(this.submittedFiles.find(x=>x.status.toLowerCase()=='pending'))
				return;

			if(!this.submittedFiles.find(x=>x.status.toLowerCase()=='uploaded'))
				return;

			var files = this.submittedFiles.filter(x=>x.status!='failed').map(x=>{
				return {
					Name:x.name,
					Url:x.url,
					Extension:x.extension,
					Type:x.type
				};
			});

			if(files.length>0)
			{
				this.isLoading=true;
				this.submitAssignment({contentId:this.contentData.id, assignmentSubmissionFiles:files}).then(response=>{
					if(response && response.submittedFiles && response.submittedFiles.length>0)
					{							
						this.isLoading=false;
						this.mapSubmittedAssignmentFiles(response);						 
					}
				});
			}

		},
		getAssignmentSubmissions(){
			this.isLoading=true;
			this.getSubmittedAssignment(this.contentData.id).then(response=>{
					if(response && response.submittedFiles && response.submittedFiles.length>0)
					{							
						this.isLoading=false;						
						this.mapSubmittedAssignmentFiles(response);
					}
				});
		},
		mapSubmittedAssignmentFiles(data){
			this.submittedAssignment=data;
			this.assignmentSubmittedAt = new Date(data.submittedAt);
			this.submittedFiles=data.submittedFiles.map(x=>{
							return {
								id: uuidv4(),
								name: x.name,
								extension: x.extension,
								type: x.type,
								url: x.url,
								status: "submitted"
							}
						});
		}
	},
	mounted(){
		this.initializeQuillEditor();
	},
	created(){
		this.getAssignmentSubmissions();
	}
};
</script>

<style scoped>
.assignment-container {
	padding: 0 2rem;
	width: 100%;
	display: flex;
}

.assignment-container h3 {
	color: var(--primary-color);
}


.assignment-container .section-heading {
	font-size: 1.9rem;
}

.assignment-container .line3 {
	display: flex;
}


.submit-heading {
	font-size: 1.4rem !important;
	font-weight: normal !important;
	color: var(--color-dark-gray);
	line-height: 1.1;
}

.add-btn.submit-btn.dropdown-toggle {
	background: var(--primary-color);
	color: white;
}

@media only screen and (max-width: 600px) {
	.page-view-icon {
		display: none;
	}

	.col-md-3.p-4.pl-5 {
		padding: 0px !important;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

.card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 15px;
	border: 0px;
}

.file-card:hover {
	background-color: whitesmoke;
}

.file-card {
	display: flex;
	padding: 5px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid var(--primary-color);
	cursor: pointer;
	flex-direction: inherit;
	margin-bottom: 10px;
	text-decoration: none;
	color: black;
	overflow: hidden;
}

.file-card h6 {
	flex: 1;
	padding: 0px;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.file-card label {
	flex: 1;
	padding: 0px;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: x-small;
	font-weight: bold;
}
.file-card.failed
{
	background-color:rgb(var(--bs-danger-rgb)) ;
}
.file-card.failed label {
	color: white;
}


.file-card h6 small {
	color: var(--primary-color);
	text-transform: uppercase;
	padding: 0px;
	margin: 0px;
}

.file-card label small {
	color: var(--primary-color);
	text-transform: uppercase;
	padding: 0px;
	margin: 0px;
	font-weight: lighter;
}

.file-card.failed label small {
	color: white;
}


.file-card .progress {
	position: absolute;
	width: 100%;
	bottom: -2px;
	left: 0px;
	height: 7px;

}

.file-card .downloadbtn {
	border-color: var(--primary-color);
	color: var(--primary-color);
	font-size: medium;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 10px;
}


.file-card.failed .downloadbtn {
	color: white;	
}
.vue-dropzone {
	border: 1px solid var(--primary-color);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding: 15px 20px;
	min-height: auto;
	background-color: #fdfdfd;
}

.vue-dropzone h5 {
	color: var(--primary-color);
}

.add-btn.dropdown-toggle:hover {
	color: black; 
	text-decoration: none;
}


  
  .ql-container.ql-snow {
	height: auto;
	border: 0px;
  }
</style>
<style>
.file-card .progress-bar-striped {
	background-color: var(--primary-color);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, .45) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .45) 50%, rgba(255, 255, 255, .45) 75%, transparent 75%, transparent);
}

.assignment-container .ql-editor {
	padding: 0px;
    height: auto;	
  }
</style>