<template>
  <div class="product-list">
    <draggable
      v-model="productList"
      @start="(() => {
        drag=true;
        dragStart();
      })"
      @end="drag = false"
      ghost-class="ghost"
      group="category-items"
      animation="200"
      handle=".product-drag-handler"
      @change="update($event)"
    >
      <div
        class="no-content pt-4 text-center text-muted product-list-container-empty"
        v-if="
          productList && productList.length <= 0 && mode != 'create'
        "
      >
        No courses found!
      </div>
      <ProductListItem
        @toggleModal="$emit('toggleModal', $event)"
        v-else
        v-for="item in productList"
        :key="item.id"
        :productData="item"
        :categoryId="categoryId"
        :isUncategorized="isUncategorized"
      />
      <form v-if="mode == 'create'" @submit="addProduct">
        <input
          type="search"
          class="create-product-input"
          v-model="productTitle"
          placeholder="Enter title of new course"
          id="create-product-input"
          required
          v-on:keyup.esc="$emit('changeMode', false)"
          @blur="blur"
        />
        <button
          @click="addProduct"
          type="submit"
          class="content-action-btn save"
        >
          <img src="@/assets/images/check-icon-green.svg" alt="save" />
        </button>
        <button class="content-action-btn cancel" type="button" role="button">
          <img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
        </button>
      </form>
    </draggable>
  </div>
</template>

<script>
import ProductListItem from "./ProductListItem.vue";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ProductList",
  data() {
    return {
      productTitle: "",
      dragStartCategoryId: null,
    };
  },
  components: {
    ProductListItem,
    draggable,
  },
  props: {
    productList: Array,
    mode: String,
    categoryId: String,
    isUncategorized: Boolean,
  },
  methods: {
    ...mapActions("product", ["createProduct", "getProductByCategoryId"]),
    ...mapActions("product", ["editProductSequence"]),
    ...mapActions("category", ["assignCategoryToProduct", "unassignCategoryToProduct", "dragProductAndAssignCategory"]),

    addProduct(e) {
      e.preventDefault();
      if (this.productTitle.trim().length > 0 && !this.loading) {
        var productObject = {
          title: this.productTitle,
          friendlyTitle: this.productTitle.toLowerCase().split(" ").join("-"),
          categories: [this.categoryId],
        };

        this.createProduct({
          product: productObject,
          id: this.categoryId,
        }).then((res) => {
          this.$emit("changeMode", false);
          // this.addProductToCategory().then((res) => {
          this.title = this.productTitle;
          this.productTitle = "";
          document.getElementById(res.id).scrollIntoView();
          // });
        });
        console.log("create called");
      } else {
        this.$emit("changeMode", false);
      }
    },
    async update(e) {
      var productObj = {};
      var afterContentID;

      if(e.removed) {
        if(this.dragStartCategoryId && parseInt(this.dragStartCategoryId) > 0) {
          await this.unassignCategoryToProduct({
            productID: e.removed.element.id,
            categories: [this.dragStartCategoryId.toString()]
          });
        }
      }

      if (e.added) {
        await this.assignCategoryToProduct({
          productID: e.added.element.id,
          categories: [this.categoryId.toString()]
        }).then(() => {
          this.getProductByCategoryId(this.categoryId).then((res) => {
            if(res && res.length > 0) {
              this.productList = res;
            }
          })
        });

        // if (e.added.newIndex != 0) {
        //   afterContentID = this.productList[e.added.newIndex - 1].id;
        //   productObj.UpperProductID = afterContentID;
        // } 
        // else if (e.added.newIndex == 0) {
        //   productObj.BelowProductID = this.productList[e.added.newIndex].id;
        // }
        // // productObj = { ...productObj, parentContentID: this.categoryId };
        // productObj.ProductID = e.added.element.id;
      }
      if (e.moved) {
        if (e.moved.newIndex != 0) {
          afterContentID = this.productList[e.moved.newIndex - 1].id;
          productObj.UpperProductID = afterContentID;
        } else if (e.moved.newIndex == 0) {
          productObj.BelowProductID = this.productList[e.moved.newIndex + 1].id;
        }
        // productObj = { ...productObj, parentContentID: parent };
        productObj.ProductID = e.moved.element.id;
      }
      
      if (!e.removed && !e.added) {
        await this.editProductSequence(productObj).then(() => {
          console.log("editContentSequence product", productObj);
        });
      }


    },
    blur() {
      setTimeout(() => this.$emit("changeMode", false), 300);
    },
    dragStart() {
      this.dragStartCategoryId = this.categoryId;
    },
  },
  computed: {
    ...mapState("product", ["loading"]),
    showSave() {
      return this.productTitle !== "";
    },
  },
};
</script>

<style scoped>
.product-list {
  margin-top: 1.625rem;
}

.product-list form {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.create-product-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.875rem 0.75rem;
  width: 100%;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  padding-right: 5.25rem;
}

.content-action-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  /* padding: 0.375rem; */
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.875rem;
}

.content-action-btn.save {
  border: 1px solid #21cf47;
  color: #21cf47;
  position: absolute;
  right: 3rem;
}
.content-action-btn.cancel {
  border: 1px solid #ff0000;
  color: #ff0000;
  position: absolute;
  right: 0.75rem;
}
</style>
