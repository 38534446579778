<template>
	<div class="content-item">
		<div
			class="content-title-container"
			v-b-toggle="`collapse${contentData ? contentData.id : ''}`"
			role="button"
			aria-expanded="false"
			@click="togleButton"
		>
			<div class="m-2" v-if="isChapterSelectable">
				<input
					type="checkbox"
					name="select_all"
					id="select_all"
					@change="manageContentState($event)"
					@click.stop
				/>
			</div>
			<h1
				class="d-flex flex-row gap-3 align-items-center"
				v-if="mode == 'view'"
			>
				<img src="@/assets/images/drag-icon.svg" class="drag-icon chapter-handle handle" />
				<span>{{ contentData.id }}</span>
				<span class="section-name">
					{{ contentData.title }}
				</span>
			</h1>
			<form v-if="mode == 'create' && !isCreated" @submit="createContent">
				<input
					type="search"
					name="content-title"
					id="content-title"
					v-model="title"
					placeholder="Type new section's name ..."
					autofocus
					@blur="cancel"
					v-on:keyup.esc="cancel"
				/>
			</form>
			<div v-if="mode == 'create'" class="right-menu position-relative">
				<button
					@click="createContent"
					class="content-save-btn"
					type="button"
					role="button"
				>
					save
				</button>
				<button
					role="button"
					class="cancel-content-btn"
					@click="$emit('cancelCreate', 'view')"
				>
					<img src="@/assets/images/cancel-icon.svg" class="cancel-icon" />
					cancel
				</button>
			</div>
			<div v-else class="right-menu">
				<a
					v-if="!isContentSectionSelectable"
					title="Select Multiple Content Sections"
					v-b-tooltip:hover
					role="button"
					class="right-menu-icon"
					@click="makeContentSectionSelectable"
					v-b-toggle="`collapse${contentData ? contentData.id : ''}`"
				>
					<i class="far fa-square-check checkbox-icon"></i>
				</a>

				<button
					v-if="isContentSectionSelectable"
					title="Cancel Select"
					role="button"
					class="right-menu-icon"
					@click="makeContentSectionUnselectable"
				>
					<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
				</button>
				
				<button
					v-if="isContentSectionSelectable"
					title="Copy Selected"
					role="button"
					class="right-menu-icon"
					@click="copyAllSelectedContentSection" 
					:disabled="!selectedBulkCopiedContent || selectedBulkCopiedContent.length <= 0"
				>
					<img src="@/assets/images/check-icon-green.svg" alt="save" />
				</button>

				<button
					v-b-tooltip:hover
					title="Paste copied Content inside"
					role="button"
					class="right-menu-icon"
					@click="pasteSelectedContentSections"
					>
					<!-- @click="pasteContent" -->
					<i class="fal fa-clipboard"></i>
				</button>
				<a
					title="Copy Section"
					v-b-tooltip:hover
					role="button"
					class="right-menu-icon"
					@click="copySection"
				>
					<i class="fal fa-copy"></i>
				</a>
				<a
					role="button"
					title="Edit Section"
					v-b-tooltip:hover
					class="right-menu-icon"
				>
					<img src="@/assets/images/edit-icon.svg" @click="showModal" />
				</a>
				<EditContentModal
					:contentData="contentData"
					:showModal="showEditModal"
					@updateModalState="toggleEditModal"
					entity="Section"
					:parentDirectoryID="tabData.id"
				/>
				<a
					title="Delete Section"
					v-b-tooltip:hover
					@click="showDeleteConfirmPopup"
					role="button"
					class="right-menu-icon delete"
				>
					<img src="@/assets/images/delete-icon.svg" />
				</a>
				<!-- <a role="button" class="right-menu-icon">
					<img src="@/assets/images/plus-icon.svg" />
				</a> -->
			</div>
			<span><i class="fas fa-angle-down"></i></span>
		</div>

		<div v-if="mode == 'view'" class="content-activity-container">
			<b-collapse
				:id="`collapse${contentData ? contentData.id : ''}`"
				:visible="showContentExpanded"
				v-model="contentData.accordianState"
			>
				<div class="content-list">
					<draggable
						v-if="filteredContent"
						v-model="filteredContent"
						ghost-class="ghost"
						group="content"
						animation="200"
						handle=".content-handle"
						@change="update($event, contentData.id)"
					>
						<CourseContentSectionChild
							v-for="item in filteredContent"
							:key="item.id"
							:contentData="item"
							:contentLoadingId="contentLoadingId"
							:contentProgress="contentProgress"
							:parentDirectoryID="contentData.id"
							:productVariantId="productVariantId"
						/>
					</draggable>
					<div v-if="loading">
						<b-skeleton height="5rem" class="pb-2"></b-skeleton>
						<b-skeleton height="5rem" class="pb-2"></b-skeleton>
						<b-skeleton height="5rem" class="pb-2"></b-skeleton>
					</div>
					<div
						v-if="filteredContent && filteredContent.length == 0 && !loading"
						class="no-data"
					>
						No content found
					</div>
				</div>
			</b-collapse>
		</div>
		<ExternalServicesList
			:parentContentId="contentData ? contentData.id : null"
			@updateVideo="updateProgressbar"
			@expandContent="toggleContentCollapse"
			:mode="mode"
			:contentData="contentData"
			:productVariantId="productVariantId"
		/>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ExternalServicesList from "./ExternalServicesList.vue";
import CourseContentSectionChild from "./CourseContentSectionChild.vue";
import EditContentModal from "./EditContentModal.vue";
import { contentService } from "../../../services/content.service";
import { ContentType } from "../../../constants/content";

// import formatter from "../../../helpers/FormatHelpers";
import draggable from "vuedraggable";
export default {
	name: "CourseContentSection",
	data() {
		return {
			mode: "view",
			title: "",
			contentList: [],
			// expandedCollapse: false,

			isCreated: false,
			showAddOptions: false,
			showContentExpanded: false,
			loading: false,
			contentProgress: 0,
			contentLoadingId: null,
			contentLoading: false,
			showZoomModal: false,
			showRecordedLectureModal: false,
			showStudyMaterialModal: false,
			showEditModal: false,
			ContentType,
		};
	},
	components: {
		ExternalServicesList,
		draggable,
		CourseContentSectionChild,
		EditContentModal,
	},
	props: {
		contentData: Object,
		createMode: Boolean,
		tabData: Object,
		productVariantId: String,
	},
	methods: {
		...mapActions("content", [
			"getContentById",
			"createDirectoryContent",
			"updateContent",
			"deleteContent",
			"editContentSequence",
			"copyContent",
			"saveCopiedSection",
			"makeBulkContentSelectionUnselectable",
			"makeBulkContentSelectionSelectable",
			"saveBulkCopiedContent",
			"pasteBulkCopiedContent",
			"selectBulkChapters",
			"unselectBulkChapters"
		]),
		...mapActions("productVariant", ["assignContentToProductVariant"]),

		manageContentState(event) {
			if(event){
				event.stopPropagation();

				if(event.target.checked) {
					this.selectBulkChapters(this.contentData);
				}
				else {
					this.unselectBulkChapters(this.contentData)
				}
			}
		},

		copyAllSelectedContentSection() {
			this.saveBulkCopiedContent().then((res) => {
				let msg = res == 1 ? `${res} Content Copied` : `${res} Contents Copied`;
	
				this.$toasted.success(msg, {
					duration: 5000,
					theme: "outline",
					position: "bottom-center",
				});
			});
		},

		makeContentSectionSelectable(e) {
			if(e) {
				e.stopPropagation();
			}

			if(this.contentData && !this.contentData.accordianState) {
				this.contentData.accordianState = true;
				this.togleButton();
			}
			
			this.makeBulkContentSelectionSelectable();
		},

		makeContentSectionUnselectable(e) {
			if(e) {
				e.stopPropagation();
			}

			this.makeBulkContentSelectionUnselectable();
		},

		createContent(e) {
			e.preventDefault();
			if (this.title != "" && this.title != null) {
				var contentObject = {
					type: "Directory",
					data: {},
					title: this.title,
					availableTo: this.tabData.availableTo,
					availableFrom: this.tabData.availableFrom,
					isAvailableForDemo: false,
					parentDirectoryID: this.tabData.id,
				};
				this.createDirectoryContent(contentObject).then((id) => {
					this.title = "";
					// this.loading = false;
					document.getElementById("content-title").blur();
				});
			}
		},
		togleButton(){
			if(!this.content[this.contentData.id])
			{
				this.loading = true;
				this.getContentById({
					id: this.contentData.id,
					extraParams: { Platform: contentService.getCurrentPlatform() },
				}).then(() => {
					var newArray = [];
					if (this.content[this.contentData.id].children != null) {
						newArray = [
							...newArray,
							...this.content[this.contentData.id].children,
						];
					}
					this.contentList = newArray;
					this.loading = false;
				});
			}
		},
		toggleContentCollapse(mode) {
           
			if (mode) {
				this.showContentExpanded = mode;
				return;
			}
			if (this.showContentExpanded) {
				setTimeout(() => {
					this.showContentExpanded = !this.showContentExpanded;
				}, 300);
			} else this.showContentExpanded = !this.showContentExpanded;


		},

		update(e, parent) {
			var contentObj = {};
			var contentID;
			var afterContentID;
			if (e.added) {
				if (e.added.newIndex != 0) {
					afterContentID = this.contentList[e.added.newIndex - 1].id;
					contentObj.afterContentID = afterContentID;
				}
				contentObj = { ...contentObj, parentContentID: parent };
				contentID = e.added.element.id;
			}
			if (e.moved) {
				if (e.moved.newIndex != 0) {
					afterContentID = this.contentList[e.moved.newIndex - 1].id;
					contentObj.afterContentID = afterContentID;
				}
				contentObj = { ...contentObj, parentContentID: parent };
				contentID = e.moved.element.id;
			}
			if (!e.removed) {
				this.editContentSequence({ content: contentObj, id: contentID }).then(
					() => {
						console.log("editContentSequence content", contentObj);
					}
				);
			}
		},
		updateProgressbar(value, id) {
			this.contentProgress = value;
			this.contentLoadingId = id;
		},
		cancel() {
			setTimeout(() => this.$emit("cancelCreate", "view"), 300);
		},
		toggleEditModal(state) {
			if (state !== undefined) {
				this.showEditModal = state;
			} else this.showEditModal = !this.showEditModal;
		},
		showModal(e) {
			e.stopPropagation();
			this.showEditModal = true;
		},
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete the Section: ${this.contentData.title}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.contentDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		contentDelete() {
			var contentObj = {
				...this.contentData,
				parentDirectoryID: this.tabData.id,
				entity: "Section",
			};

			this.deleteContent(contentObj).then(() => {
				console.log("Section deleted");
			});
		},
		copySection(e) {
			if (e) e.stopPropagation();
			this.saveCopiedSection(this.contentData).then(() => {
				this.$toasted.success("Section copied", {
					duration: 3000,
					theme: "outline",
					icon: "check",
					position: "bottom-center",
				});
			});
		},
		pasteContent(e) {
			if (e) e.stopPropagation();
			var copyContentObject = {
				content: this.copiedContent,
				destinationContentID: this.contentData.id,
				destinationParentID: this.tabData.id,
			};
			this.copyContent(copyContentObject).then((res) => {
				this.$toasted.success("Copied content pasted successfully", {
					duration: 3000,
					theme: "outline",
					icon: "check",
					position: "bottom-center",
				});
			});
		},
		async pasteSelectedContentSections(e) {
			if (e) e.stopPropagation();
			
			if(this.contentData && this.contentData.id) {
				await this.pasteBulkCopiedContent(this.contentData.id).then((res) => {
					if(res > 0) {
						this.$toasted.success("Copied content pasted successfully", {
							duration: 3000,
							theme: "outline",
							icon: "check",
							position: "bottom-center",
						});
						console.log({id: this.contentData.id});
						return;
					}
					this.$toasted.error("No Content copied", {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			}
		},
	},
	computed: {
		...mapState("content", ["content", "copiedSection", "copiedContent", "copiedBulkContent", "isContentSectionSelectable", "selectedBulkCopiedContent", "isChapterSelectable"]),
		...mapGetters("content", ["getContent"]),
		filteredContent: {
			get() {
				console.log("filtering recorded video");
				if (this.contentList && this.contentList.length > 0) {
					return this.contentList.filter((item) => {
						console.log(item);
						if (item.type == ContentType.RECORDED_VIDEOS && item.externalService?.serviceProvider?.toLowerCase() == 'vss') {
							if (
								(item.extraProperties && item.extraProperties.Status) ||
								item.id == this.contentLoadingId
							) {
								if (item.extraProperties.Status != "NotUploaded") {
									// console.log("item is not uploaded");
									return item;
								}
							}
							return;
						}
						if (item.type == ContentType.STUDY_MATERIAL && item.externalService.serviceProvider.toLowerCase()=="vss") {
							if(item.extraProperties && (typeof item.extraProperties)=="string"){
								item.extraProperties=JSON.parse(item.extraProperties);
							}
							if (
								(item.extraProperties && item.extraProperties.Status) ||
								item.id == this.contentLoadingId
							) {
								if (item.extraProperties.Status != "NotUploaded") {
									// console.log("item is not uploaded");
									return item;
								}
							}
							return;
						}
						return item;
					});
				}
				return [];
			},
			set(value) {
				this.contentList = value;
			},
		},
	},
	created() {
		this.makeContentSectionUnselectable();

		if(this.contentData && this.contentData.id) {
			this.contentData.accordianState = false;
		}

		if (this.createMode) {
			this.mode = "create";
		} else {
			if (this.content[this.contentData.id]) {
				var newArray = [];
				if (this.content[this.contentData.id].children != null) {
					newArray = [
						...newArray,
						...this.content[this.contentData.id].children,
					];
				}
				this.contentList = newArray;
			}
		}
	},
	watch: {
		getContent: function () {
			this.contentList =
				this.contentData &&
				this.getContent[this.contentData.id] &&
				this.getContent[this.contentData.id].children;
			console.log("content in store updated");
		},
	},
};
</script>

<style scoped>
.content-item {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 6px;
	/* padding: 2rem 2.75rem; */
	margin-bottom: 1.5rem;
}
.content-title-container {
	padding: 2rem 1.5rem;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* .create-content-activity-container {
	display: flex;
	flex-direction: column;
	column-gap: 1.25rem;
}

.create-content-activity-container .button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1.25rem;
	padding: 2rem 1.5rem;
	border-top: 1px solid #dcdcdc;
} */

.content-activity-container {
	/* padding: 2rem 1.5rem; */
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.content-activity-toggle {
	padding: 2rem 1.5rem;
}

.content-title-container h1 {
	font-weight: 500;
	font-size: 1.875rem;
	line-height: 0.8;
	color: #000000;
	margin: 0;
	transition: color 0.3s ease;
}

.content-title-container.not-collapsed h1 {
	color: var(--primary-color);
}

.content-title-container input {
	font-weight: 500;
	font-size: 1.875rem;
	line-height: 0.8;
	color: #838383;
	margin: 0;
	border: 0 !important;
	outline: none !important;
	width: 100%;
}

.content-title-container input:focus {
	outline: none !important;
}

.content-title-container form {
	width: 100%;
}

.content-title-container .fa-angle-down {
	transition: all 0.3s ease;
	font-weight: 500;
	font-size: 1.5rem;
	padding-top: 0.25rem;
}

.content-title-container .drag-icon.handle {
	width: 1.5rem;
	height: 1.5rem;
	cursor: grab;
}

.content-title-container.not-collapsed .fa-angle-down {
	transform: rotate(180deg);
}

.content-title-container input::-webkit-input-placeholder {
	font-size: 1.875rem;
}
.content-title-container h1 span {
	font-weight: bold;
	color: #838383;
}
/*
.content-list .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
} */

.content-title-container .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	margin-right: 0.75rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.content-title-container:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
	color: #000000;
	text-decoration: none;
}

.right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

/* .right-menu-icon img {
	max-height: 0.75rem;
} */
.content-activity-toggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: 0.05em;
	color: #000000;
	width: 100%;
}

.add-content-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: 0.05em;
	color: #cd222d;
}

.cancel-content-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: 0.05em;
	color: #000000;
}

.no-data {
	text-align: center;
	padding: 1rem 0 1rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}

.option-tabs {
	background: #ffffff;
	border-bottom: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	/* border-radius: 6px 6px 0px 0px; */
	display: flex;
	flex-direction: row;
	padding: 0 1.5rem;
}

.option-tab {
	padding: 1.5rem 1.25rem;
	font-weight: 500;
	font-size: 1.125rem;
	border-bottom: 1.5px solid transparent;
}

.option-tab.active {
	border-bottom: 1.5px solid var(--primary-color);
}

.option-tab-body {
	padding: 1.75rem;
	background-color: #f9f9f9;
}

.option-tabs .cancel-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.5rem;
	margin-left: auto;
}
.cancel-icon {
	width: 0.875rem;
}

.content-save-btn {
	background: #ffffff;
	border: 1px solid #21cf47;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 0.375rem 0.5rem;
	color: #21cf47;
	font-weight: bold;
	font-size: 0.75rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	position: absolute;
	right: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.section-name {
	font-weight: 500 !important;
	color: #000 !important;
	line-height: 1.1;
}

.checkbox-icon::before{
	content: "\f14a";
}
</style>
