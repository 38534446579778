import { formService } from "../services/form.service";
import Vue from "vue";
import store from ".";

const state = {
	loading: false,
};

const getters = {
};

const actions = {
	fetchFormByEvent: async ({ commit },{entity,event,entityID=null}) => {
		commit("setLoading", true);
		// state.loading = true;
		return await formService.fetchFormByEvent(entity,event,entityID).then(
			(forms) => {
				commit("setLoading", false);
				return forms.payload;
			},
			(error) => {
				commit("setLoading", false);
			}
		);
	},
	
	submitForm: async ({ commit }, {FormID,FormData})=> {
		commit("setLoading", true);
		// state.loading = true;
		return await formService.submitForm(FormID,FormData).then(
			(formSubmission) => {
				commit("setLoading", false);
				return formSubmission.payload;
			},
			(error) => {
				commit("setLoading", false);
			}
		);
	},
	fetchFormByID: async ({ commit },ID) => {
		commit("setLoading", true);
		// state.loading = true;
		return await formService.fetchFormByID(ID).then(
			(form) => {
				commit("setLoading", false);
				return form.payload;
			},
			(error) => {
				commit("setLoading", false);
			}
		);
	},
};

const mutations = {
	setLoading(state, data) {
		if (data) {
		} else {
		}
		state.loading = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
