<template>
	<div class="dashboard-products-container" id="dashboard-products-container">
		<div v-if="userProducts.length > 0 && !loading">
			<ProductListItem
				v-for="product in userProducts"
				:key="product.id"
				class="grid"
				:data="product"
				:purchased="true"
			/>
		</div>
		<div v-else-if="loading" class="mt-5">
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
		</div>
		<div v-else class="no-products">
			<h4>
				You are not enrolled in any of the courses. <br />
				<router-link to="/search">Click here</router-link> to browse through
				full list of our courses.
			</h4>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ProductListItem from "../Product/ProductListItem.vue";

export default {
	name: "UserProducts",
	props: { userProducts: Array },
	components: { ProductListItem },
	computed: {
		...mapState("productVariant", ["loading"]),
	},
};
</script>

<style scoped>
@media screen and (min-width: 1440px) {
	.dashboard-products-container {
		width: 70% !important;
	}
}

@media screen and (max-width: 996px) {
	.dashboard-products-container {
		width: 100% !important;
		margin-top: 1.5rem;
	}
}

@media screen and (max-width: 600px) {
	.dashboard-products-container div {
		display: flex;
		row-gap: 1rem;
		flex-direction: column;
	}
}

.no-products {
	padding: 1.5rem !important;
}

.dashboard-products-container {
	width: 65%;
	padding-top: 2rem;
}
</style>
