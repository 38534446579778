<template>
  <b-modal @change="closeModal()" :id="`upload-video-modal-${parentContentId}`" :visible="visible" size="xl" hide-header
    hide-footer centered :content-class="`upload-video-modal ${parentContentId}`" body-class="modal-body"
    modal-class="custom-modal" no-close-on-backdrop :data-id="parentContentId">
    <div class="body-container">
      <button @click="closeModal()" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <h1 class="add-content-title">
        {{ isEditMode ? "Edit" : "Create" }} Youtube Content
      </h1>
      <div :class="['info-container']">
        <div class="inline-group">

          <div class="col">
            <label class="main-label" for="title">Title (required)</label>
            <input placeholder="Type Title here..." class="form-input" type="search" name="title" id="title"
              v-model="title" />
          </div>
        </div>
        <div>
          <div class="inline-group">
            <div class="col">
              <label class="main-label" for="passingPercentage">Video URL</label>
              <p v-if="!validateUrl && url" class="text-danger">Invalid URL! Valid URL format:
                https://youtube.com/watch?v=your_video_id</p>
              <div>
                <input class="form-input" style="display: inline" type="url" placeholder="Type your Video URL here"
                  v-model="url" />
              </div>
            </div>
          </div>

          <div class="inline-group">
            <div class="col">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="isRandom"
                  v-model="isAvailableForDemo" />
                <label class="form-check-label" for="isRandom">Available for Demo
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="inline-group">
          <div class="col">
            <label for="start-date" class="main-label m-0">start date (optional)</label>
            <input placeholder="Select start date" class="form-input" type="date" name="availableFrom" id="availableFrom"
              v-model="startDate" />
          </div>
          <div class="col">
            <label for="end-date" class="main-label m-0">end date (optional)</label>
            <input placeholder="Select end date" class="form-input" type="date" name="availableTo" id="availableTo"
              v-model="endDate" />
          </div>
        </div>
      </div>
      <div class="step-btn-container">
        <button @click="createEditContent" role="button" class="step-btn" :disabled="loading || !title || !url || !validateUrl
          ">
          <b-spinner v-if="loading" small></b-spinner>
          Submit
          <img src="@/assets/images/arrow-right.svg" />
        </button>
      </div>
    </div>

  </b-modal>
</template>
  
<script>
import { mapActions, mapMutations, mapState } from "vuex"; // import CryptoJS from "crypto-js";
import RecordedVideo from "../../../models/content/recordedVideo";
import { contentService } from "../../../services/content.service";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";

export default {
  name: "UploadYoutubeRecordedLectureModal",
  components: {
  },
  computed: {
    ...mapState("content", ["loading"]),
    visible() {
      return this.showModal;
    },

    validateUrl() {
      if (this.url) {
        if (this.validateYouTubeUrl(this.url)) {
          return true;
        }
      }
      return false;
    }
  },
  props: {
    showModal: Boolean,
    parentContentId: String,
    serviceData: Object,
    service: Object,
    contentType: String,
    isEditMode: Boolean,
    contentData: Object,
    productVariantId: String,
  },
  data: function () {
    return {
      type: "RecordedVideos",
      title: null,
      startDate: null,
      endDate: null,
      isAvailableForDemo: false,
      url: null
    };
  },
  methods: {
    ...mapActions("content", [
      "createYoutubeContent",
      "updateContent",
      "getContentById",
    ]),

    async createEditContent() {
      var obj = {
        id: this.contentData?.id ?? null,
        title: this.title,
        data: {
          url: this.url,
          servicecode: 'youtube'
        },
        availableFrom: this.startDate,
        availableTo: this.endDate,
        isAvailableForDemo: this.isAvailableForDemo,
        parentDirectoryID: this.parentContentId,
        type: "RecordedVideos"
      };

      if (!this.isEditMode) {
        await this.createYoutubeContent(obj).then((res) => {
          if (res) {
            this.$toasted.success("content created successfully", {
              duration: 5000,
              theme: "outline",
              position: "bottom-center",
            });
          }
        });
      }
      else {
        await this.updateContent(obj).then((res) => {
          if (res) {
            this.$toasted.success("content updated successfully", {
              duration: 5000,
              theme: "outline",
              position: "bottom-center",
            });
          }
        });
      }
      this.closeModal();

    },

    closeModal() {
      this.$emit("updateModalState", false);
    },

    validateYouTubeUrl(url) {
      var regExp = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      var match = url.match(regExp);
      return match !== null;
    }
  },
  watch: {
    visible: function () {
      console.log(this.serviceData, "service");
      if (this.visible) {
        if (this.isEditMode) {
          this.getContentById({ id: this.contentData.id }).then((res) => {
            if (res) {
              this.id = res.id;
              this.title = res.title;
              this.title = res.title;
              this.startDate = res.availableFrom
                ? getInputDateFormat(res.availableFrom)
                : null;
              this.endDate = res.availableTo
                ? getInputDateFormat(res.availableTo)
                : null;
              this.isAvailableForDemo = res.isContentAvailableForDemo;
              this.url = this.contentData && res.data && res.data.url ? res.data.url : null;
            }
          });
          return;
        }

        this.url = null;
        this.title = null;
        this.startDate = null;
        this.endDate = null;
        this.isAvailableForDemo = false;

      }
    },
  },
};
</script>
  
<style>
.upload-video-modal {
  height: 45.375rem;
  width: 70vw;
}

/* .custom-modal .modal-dialog {
      max-width: 70vw;
  } */

.upload-video-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.upload-video-modal .modal-body .body-container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.body-content-container {
  display: flex;
  flex-direction: row;
  /* column-gap: 1rem; */
  align-items: flex-start;
  margin-top: 1rem;
  height: 100%;
}

.body-content-container .info-container {
  width: 0;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.body-content-container .info-container.expand {
  width: 70% !important;
  opacity: 1 !important;
  padding-right: 4rem;
}

.body-content-container .info-container.expand.link {
  width: 100% !important;
  padding: 0;
}

.body-content-container .upload-container {
  width: 100%;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.body-content-container .upload-container.shrink {
  width: 30% !important;
  height: 50% !important;
}

.body-content-container .upload-container.shrink.multiple {
  width: 30% !important;
  height: 90% !important;
}

.body-content-container .upload-container #dropzone {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: unset;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 34.375rem;
}

.body-content-container .upload-container .preview {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: unset;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 34.375rem;
  background: #eeeeee;
  border: 1px solid #ddd;
}

.body-content-container .upload-container .preview img {
  transition: all 0.3s ease;
}

.body-content-container .upload-container .preview img:hover {
  box-shadow: 0 0 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.body-content-container .upload-container.shrink #dropzone {
  justify-content: flex-start;
}

.dropzone .dz-preview {
  min-height: unset !important;
}

.info-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.info-container .form-group {
  display: flex;
  flex-direction: column;
}

.info-container .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0.875rem 0;
  /* max-width: 21.625rem; */
  width: 100%;
}

.form-input:focus {
  border-color: var(--primary-color) !important;
}

.vue-dropzone>.dz-preview {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.vue-dropzone>.dz-preview .dz-details {
  /* color: #fff !important; */
  background-color: #dddddd !important;
  transition: all 0.6s linear;
  border-radius: 8px;
}

.vue-dropzone>.dz-preview.dz-success .dz-details {
  background-color: #21cf47 !important;
}

.vue-dropzone>.dz-preview.dz-error .dz-details {
  background-color: var(--color-danger) !important;
}

.vue-dropzone>.dz-preview .dz-remove {
  border-radius: 8px;
  /* background-color: #dcdcdc !important;
    color: #000000 !important;
    margin: 0 !important;
    transform: translateX(-50%) !important;
    left: 50%; */
  /* width: 100%; */
}

.vue-dropzone>.dz-preview .dz-filename,
.vue-dropzone>.dz-preview .dz-size {
  color: #000000;
}

.vue-dropzone>.dz-preview.dz-success .dz-filename,
.vue-dropzone>.dz-preview.dz-error .dz-filename,
.vue-dropzone>.dz-preview.dz-success .dz-size,
.vue-dropzone>.dz-preview.dz-error .dz-size {
  color: #ffffff;
}

.minimize-button {
  position: absolute;
  color: #000000;
  right: 5rem;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: -0.25rem;
}

.min .minimize-button {
  right: 3rem !important;
  font-size: 1.25rem !important;
  top: 1.125rem;
}

.video-title {
  position: absolute;
  color: #000000;
  font-size: 1rem;
  left: 1rem;
  display: none;
  top: 0.675rem;
}

.min .video-title {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
}

.min {
  width: 22rem !important;
  height: 3rem !important;
  overflow: hidden !important;
  padding: 0px !important;
  margin: 0px;

  float: left;
  position: static !important;
}

.min .modal-dialog,
.min .modal-content {
  height: 100%;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

.display-none {
  display: none !important;
}

.minmaxCon {
  height: 3rem;
  bottom: 0.5rem;
  right: 0.5rem;
  position: fixed;
  /* right: 1px; */
  z-index: 9999;
  display: flex;
  column-gap: 0.5rem;
}

.min .close-button {
  position: absolute;
  top: 0.675rem;
  right: 1rem;
}

.min .close-button img {
  width: 1rem;
}
</style>
  