<template>
  <div class="ms-5">
    <div>
      <h2 class="pt-5 pb-5">Visa/Master Card</h2>
      <h5 v-if="message" class="text-success">{{ message }}</h5>
      <h5 v-if="error" class="text-danger">{{ error }}</h5>
    </div>

    <div v-if="paymentData">
      <form id="paymentForm" :action="paymentData.data.url" method="POST">
        <input
          type="hidden"
          name="chargetotal"
          :value="paymentData.data.chargetotal"
        />
        <input
          type="hidden"
          name="checkoutoption"
          :value="paymentData.data.checkoutoption"
        />
        <input
          type="hidden"
          name="currency"
          :value="paymentData.data.currency"
        />
        <input
          type="hidden"
          name="hash_algorithm"
          :value="paymentData.data.hash_algorithm"
        />
        <input
          type="hidden"
          name="language"
          :value="paymentData.data.language"
        />
        <input
          type="hidden"
          name="hashExtended"
          :value="paymentData.data.hashExtended"
        />
        <input
          type="hidden"
          name="responseFailURL"
          :value="paymentData.data.responseFailURL"
        />
        <input
          type="hidden"
          name="responseSuccessURL"
          :value="paymentData.data.responseSuccessURL"
        />
        <input
          type="hidden"
          name="storename"
          :value="paymentData.data.storename"
        />
        <input
          type="hidden"
          name="timezone"
          :value="paymentData.data.timezone"
        />
        <input
          type="hidden"
          name="txndatetime"
          :value="paymentData.data.txndatetime"
        />
        <input type="hidden" name="txntype" :value="paymentData.data.txntype" />
        <!-- <input
          style="width: 160px; height: 45px"
          class="mt-3 btn-block-rounded-primary"
          type="hidden"
        /> -->
      </form>
    </div>
  </div>
</template>

<script>
import { mounted } from "vue2-dropzone";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
  name: "ScotiaPaymentGateway",
  data() {
    return {
      message: null,
      error: null,
      paymentData: null,
      isSubmitForm: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("pricing", ["invoicesByUserId", "loading"]),
    isPostPayment() {
      let action = this.$route.params.action;

      return action && action.toLowerCase() == "postPayment";
    },
  },
  methods: {
    ...mapActions("pricing", ["fetchInvoiceByUserId", "processPayment"]),
  },
  created() {
    let action = this.$route.query.action;
    let status = this.$route.query.status;
    let msg = this.$route.query.message;

    if (action && action.toLowerCase() == "postpayment") {
      if (msg) {
        msg = msg.replace(/\+/g, "%20");
        msg = decodeURIComponent(msg);

        if (status && status.toLowerCase() == "failed") {
          let fail_rc = this.$route.query.fail_rc;
          let approval_code = this.$route.query.approval_code;

          //this.error = `${fail_rc} - ${msg}. You may navigate to your courses.`;
          Swal.fire({
            title: "Transaction Failed",
            text: `${fail_rc} - ${msg}. You may navigate to your courses.`,
            icon: "error",
            button: "Ok",
            dangerMode: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((confirm) => {
            if (confirm) {
              this.$router.push("/user/dashboard");
            }
          });
        } else {
          //this.message = `${msg}. You may navigate to your courses now to access the content.`;
          Swal.fire({
            title: "Transaction Successful",
            text: `${msg}. You may navigate to your courses.`,
            icon: "success",
            button: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((confirm) => {
            if (confirm) {
              this.$router.push("/user/dashboard");
            }
          });
        }
      }
    } else {
      let invoiceID = this.$route.params.id;

      if (invoiceID) {
        this.message = "Fetching payment details, please wait...";
        let invoice;

        if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
          invoice = this.invoicesByUserId[this.user.id].find(
            (inv) => inv.id == invoiceID
          );
        } else {
          this.fetchInvoiceByUserId(this.user.id).then(() => {
            invoice = this.invoicesByUserId[this.user.id].find(
              (invoice) => invoice.id == invoiceID
            );
          });
        }

        if (invoiceID == invoice.id) {
          // var getCurrentTimeZone =
          //   Intl.DateTimeFormat().resolvedOptions().timeZone;
          this.processPayment({
            invoiceId: invoiceID,
            serviceCode: "Scotia",
            data: {
              redirectUrl: `${window.location.origin}/payment/Scotia`,
            },
            // timezone: getCurrentTimeZone,
          })
            .then((response) => {
              if (response && response.data && response.data.url) {
                this.error = undefined;
                this.paymentData = response;
                //this.paymentData.data.timezone = getCurrentTimeZone;
                this.paymentData.data.timezone = "America/Mexico_City";
                //this.$refs.form.$el.submit();

                let count = 5;

                setInterval(() => {
                  count--;
                  this.message = `You will be redirect to Scotia Payment Gateway in ${count} seconds. Don't reload or leave browser.`;

                  if (count == 1) {
                    document.getElementById("paymentForm").submit();
                    clearInterval();
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              console.log(error);
              this.error =
                "Some error occurred while fetching data from payment gateway.";
            });
        } else {
          this.error = "Invoice not found.";
        }
      } else {
        this.error = "Invalid parameters";
      }
    }
  },
};
</script>

<style scoped></style>
