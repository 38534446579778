<template>
	<div class="inner-page-banner">
		<div class="container"><slot></slot></div>
	</div>
</template>

<script>
export default {
	name: "InnerPageBanner",
};
</script>

<style scoped>
.inner-page-banner {
	padding: 2.5rem 2rem;
	background-image: url("../../assets/images/productTopBanner1.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	font-weight: bold;
	font-size: 3rem;
	color: var(--color-white);
	position: relative;
}

.inner-page-banner:after {
	content: "";
	width: 45%;
	height: 5px;
	background-color: var(--color-danger);
	position: absolute;
	bottom: 0;
	right: 0;
}
</style>
