<template>
	<div class="page-content-container">
		<div class="title-container mb-4">
			<h1 class="page-title">Payments</h1>
		</div>
		<div class="filters-container">
			<div class="buttons-container">
				<div class="searchbar position-relative d-flex align-items-center">
					<input
						autocomplete="off"
						type="search"
						name="paymentIDFilter"
						id="paymentIDFilter"
						placeholder="Search by Payment ID"
						v-model="paymentIDFilter"
						class="search-input"
					/>
				</div>
				<div class="searchbar position-relative d-flex align-items-center">
					<input
						autocomplete="off"
						type="search"
						name="invoiceIDFilter"
						id="invoiceIDFilter"
						placeholder="Search by Invoice ID"
						v-model="invoiceIDFilter"
						class="search-input"
					/>
				</div>
				<div class="searchbar position-relative d-flex align-items-center">
					<date-range-picker
						ref="picker"
						:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
						:showWeekNumbers="true"
						:showDropdowns="true"
						:autoApply="true"
						v-model="paymentDateFilter"
						class="search-input"
						control-container-class="d-flex align-items-center gap-3"
						opens="left"
						:ranges="dateRangePresets"
						:maxDate="new Date()"
					>
						<template v-slot:input="picker" style="min-width: 350px">
							<i class="fal fa-calendar-alt text-muted"></i>
							<span class="text-sm" v-if="picker.startDate || picker.endDate">
								{{ formatDate(picker.startDate) }} -
								{{ formatDate(picker.endDate) }}
							</span>
							<span class="text-muted text-sm" v-else>
								Search by Payment Date
							</span>
							<i
								@click="resetDateFilter"
								class="fa fa-times ms-auto text-muted cursor-pointer"
								v-if="picker.startDate || picker.endDate"
							></i>
							<i v-else class="fa fa-caret-down ms-auto text-muted"></i>
						</template>
					</date-range-picker>
				</div>
				<button @click="filterPayments" :disabled="loading" class="filter-btn">
					<i class="fa fa-search"></i>
					Search
				</button>
			</div>
		</div>
		<div class="d-flex gap-5">
			<div>
				<p class="mb-2">Payment Status</p>
				<div class="d-flex mb-4 gap-3">
					<button
						@click="setStatus(null)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentStatusFilter == null },
						]"
					>
						All
					</button>
					<button
						@click="setStatus(PaymentStatus.PENDING)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentStatusFilter == PaymentStatus.PENDING },
						]"
					>
						Pending
					</button>
					<button
						@click="setStatus(PaymentStatus.COMPLETED)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentStatusFilter == PaymentStatus.COMPLETED },
						]"
					>
						Completed
					</button>
				</div>
			</div>
			<div>
				<p class="mb-2">Payment Method</p>
				<div class="d-flex mb-4 gap-3">
					<button
						@click="setPaymentMethod(null)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentMethodFilter == null },
						]"
					>
						All
					</button>

					<button
						v-for="method in externalServicesList['PaymentGateway']"
						:key="method.serviceCode"
						@click="setPaymentMethod(method.serviceProvider)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentMethodFilter == method.serviceProvider },
						]"
					>
						{{ method.serviceName }}
					</button>
				</div>
			</div>
		</div>
		<div class="table-container">
			<b-overlay :show="loading" rounded="sm">
				<table class="table table-hover table-borderless payments-table">
					<thead>
						<th>
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll"
							/>
						</th>
						<th>Payment ID</th>
						<th>Student Name</th>
						<th>Amount</th>
						<th>Payment Method</th>
						<th>Payment Status</th>
						<th>Payment Date</th>
					</thead>
					<tbody v-if="allPayments && allPayments.length > 0">
						<tr
							class="cursor-pointer"
							@click="togglePaymentModal(payment)"
							v-for="payment in filteredPayments"
							:key="payment.id"
						>
							<td>
								<input
									@click="togglePaymentSelect($event, payment)"
									type="checkbox"
									name="select_1"
									:id="payment.id"
									:checked="isChecked(payment.id)"
								/>
							</td>
							<td>{{ payment.id }}</td>
							<td>{{ userName(payment.customerID) }}</td>
							<td>
								{{ totalAmount(payment.details) }}
							</td>
							<td>{{ payment.method }}</td>
							<td>{{ payment.status }}</td>
							<td>
								{{
									formatCurrency(
										formatDate(payment.paymentDate),
										payment.currency
									)
								}}
							</td>
							<UserPaymentDetailModal
								v-if="
									payment &&
									paymentToViewPay &&
									paymentToViewPay.id == payment.id
								"
								:payment="payment"
								:showModal="showPaymentModal"
								@updateModalState="togglePaymentModal"
								:user="userData(payment.customerID)"
							/>
						</tr>
					</tbody>
				</table>
			</b-overlay>
			<div
				v-if="!allPayments && loading"
				class="d-flex align-items-center justify-content-center gap-3 p-3 font-500"
			>
				<b-spinner></b-spinner> Loading...
			</div>
			<h5
				v-else-if="allPayments && filteredPayments.length == 0 && !loading"
				class="text-center text-muted p-4 pt-0 m-0"
			>
				No payments found
			</h5>
		</div>
		<b-pagination
			v-if="filteredPayments.length > 0"
			align="right"
			v-model="currentPage"
			:total-rows="rows"
			:per-page="perPage"
		></b-pagination>
	</div>
</template>

<script>
import { PaymentStatus } from "../../constants/pricing";
import { mapActions, mapState } from "vuex";
import {
	toShortDateString,
	toCurrencyString,
} from "../../helpers/FormatHelpers";
import UserPaymentDetailModal from "../../components/Admin/Payment/UserPaymentDetailModal.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
	name: "PaymentManagement",
	data() {
		return {
			paymentStatusFilter: null,
			paymentMethodFilter: null,
			searchText: "",
			selectedPayments: [],
			PaymentStatus,
			perPage: 10,
			currentPage: 1,
			paymentIDFilter: null,
			invoiceIDFilter: null,
			studentIDFilter: null,
			paymentDateFilter: {
				startDate: null,
				endDate: null,
			},
			paymentToViewPay: null,
			showPaymentModal: false,
			rows: 0,
			totalPages: 0,
		};
	},
	computed: {
		...mapState("pricing", ["allPayments", "loading"]),
		...mapState("user", ["allUsers"]),
		...mapState("appState", ["externalServicesList"]),
		filteredPayments() {
			var filtered = this.allPayments;
			return filtered;
		},
		isSelectedAll() {
			if (this.allPayments) {
				return this.allPayments.length == this.selectedPayments.length;
			}
		},
		offset() {
			return (this.currentPage - 1) * this.perPage;
		},
		dateRangePresets() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date();
			yesterday.setDate(today.getDate() - 1);
			yesterday.setHours(0, 0, 0, 0);
			let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
			let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
			return {
				Today: [today, today],
				Yesterday: [yesterday, yesterday],
				"This month": [thisMonthStart, thisMonthEnd],
				"Last month": [
					new Date(today.getFullYear(), today.getMonth() - 1, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"Last 3 months": [
					new Date(today.getFullYear(), today.getMonth() - 3, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"Last 6 months": [
					new Date(today.getFullYear(), today.getMonth() - 6, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"This year": [
					new Date(today.getFullYear(), 0, 1),
					new Date(today.getFullYear(), 11, 31),
				],
				"Last year": [
					new Date(today.getFullYear() - 1, 0, 1),
					new Date(today.getFullYear() - 1, 11, 31),
				],
			};
		},
	},
	components: { UserPaymentDetailModal, DateRangePicker },
	methods: {
		...mapActions("user", ["fetchUsersList"]),
		...mapActions("appState", ["fetchExternalServices"]),
		...mapActions("pricing", ["fetchPaymentsList"]),
		setStatus(status) {
			this.paymentStatusFilter = status;
		},
		setPaymentMethod(method) {
			this.paymentMethodFilter = method;
		},
		toggleSelectAll() {
			if (
				this.allPayments &&
				this.allPayments.length > 0 &&
				this.selectedPayments.length != this.allPayments.length
			) {
				this.selectedPayments = this.allPayments;
			} else {
				this.selectedPayments = [];
			}
		},
		togglePaymentSelect(e, payment) {
			e.stopPropagation();
			var found = this.selectedPayments.find((s) => s.id == payment.id);
			console.log(found);
			if (!found) {
				this.selectedPayments.push(payment);
			} else {
				this.selectedPayments = this.selectedPayments.filter(
					(su) => su.id != payment.id
				);
			}
		},
		// rows() {
		// 	if (this.allPayments) {
		// 		return this.filteredPayments.length;
		// 	} else return 0;
		// },
		isChecked(id) {
			var found = this.selectedPayments.find((payment) => payment.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		userName(id) {
			// console.log(id);
			if (this.allUsers && this.allUsers.length > 0) {
				var found = this.allUsers.find((user) => user.id == id);
				console.log(found);
				if (found) {
					return found.firstName + " " + found.lastName;
				}
			}
		},
		userData(id) {
			if (this.allUsers && this.allUsers.length > 0) {
				var found = this.allUsers.find((user) => user.id == id);
				console.log(found);
				if (found) {
					return found;
				}
			}
		},
		totalAmount(details) {
			var total = 0;
			details.forEach((d) => {
				total += d.amount;
			});
			return total;
		},
		formatDate(date) {
			return toShortDateString(date);
		},
		filterPayments() {
			this.fetchPaymentsList({
				invoiceID: this.invoiceIDFilter,
				ID: this.paymentIDFilter,
				Status: this.paymentStatusFilter,
				MethodProvider: this.paymentMethodFilter,
				StartFrom: this.paymentDateFilter.startDate
					? new Date(this.paymentDateFilter.startDate).toISOString()
					: null,
				EndAt: this.paymentDateFilter.endDate
					? new Date(this.paymentDateFilter.endDate).toISOString()
					: null,
				pageSize: this.perPage,
				currentpage: this.currentPage,
			}).then((res) => {
				this.rows = res.totalRecords;
			});
		},
		togglePaymentModal(payment = null) {
			if (payment) {
				this.paymentToViewPay = payment;
				setTimeout(() => {
					this.showPaymentModal = !this.showPaymentModal;
				}, 300);
			} else {
				this.showPaymentModal = !this.showPaymentModal;
			}
		},
		resetDateFilter(e) {
			e.stopPropagation();
			this.paymentDateFilter = {
				startDate: null,
				endDate: null,
			};
		},
		formatCurrency(amount, currency) {
			return toCurrencyString(amount, currency, 0);
		},
	},
	created() {
		this.fetchUsersList();
		this.fetchExternalServices("PaymentGateway");
		this.fetchPaymentsList({
			invoiceID: this.invoiceIDFilter,
			ID: this.paymentIDFilter,
			Status: this.paymentStatusFilter,
			MethodProvider: this.paymentMethodFilter,
			StartFrom: this.paymentDateFilter.startDate
				? new Date(this.paymentDateFilter.startDate).toISOString()
				: null,
			EndAt: this.paymentDateFilter.endDate
				? new Date(this.paymentDateFilter.endDate).toISOString()
				: null,
			pageSize: this.perPage,
			currentpage: this.currentPage,
		}).then((res) => {
			this.rows = res.totalRecords;
		});
	},
	watch: {
		paymentStatusFilter: function () {
			this.filterPayments();
		},
		paymentMethodFilter: function () {
			this.filterPayments();
		},
		showPaymentModal: function () {
			if (this.showPaymentModal == false) {
				this.filterPayments();
			}
		},
		currentPage: function () {
			this.filterPayments();
		},
	},
};
</script>

<style scoped>
.page-content-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 1.875rem;
	overflow-y: auto;
}

.title-container {
	display: flex;
	align-items: center;
}

.page-title {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	/* margin: 0; */
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.filters-container {
	padding: 1.5rem;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	background-color: #ffffff;
	margin-bottom: 1.5rem;
}
.filters-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.filter-inputs {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
}

.search-input {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	/* margin-bottom: 1rem; */
	/* font-weight: 500; */
	color: #000000;
	font-size: 1rem;
	column-gap: 0.75rem;
	position: relative;
	outline: none;

	/* transition: transform 0.3s; */
}

.search-button {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 2rem;
	filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
	text-decoration: none;
	color: #000000;
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
}

.buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 1rem;
}

.buttons-container .searchbar {
	flex-grow: 1;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 1rem;
	right: 0;
	color: #000000;
}

.filter-payment-type-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	border: 1px solid transparent;
	transition: all 0.3s ease;
}

.filter-payment-type-btn.active {
	border: 1px solid var(--primary-color);
}

.table-container {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
}

.payments-table thead,
.payments-table td {
	height: 4rem;
	vertical-align: middle;
}

.payments-table td:first-of-type {
	padding-left: 0;
}

.payments-table input[type="checkbox"] {
	margin-left: 1.5rem;
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.filter-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 2rem;
	text-decoration: none;
	color: #000000;
	display: flex;
	-moz-column-gap: 0.5rem;
	column-gap: 0.5rem;
	align-items: center;
	transition: all 0.3s ease;
}

.filter-btn:hover {
	box-shadow: (3px 5px 6px rgba(197, 197, 197, 0.3));
}
</style>
