<template>
  <div>

    <div class="assignment-main-content">

      <div class="assignment-header-section">
        <!-- <h2 class="assignment-title">{{ contentData.title }}</h2> -->
        <div class="assignment-info-row">
          <p class="subtitle">Deadline: <span> {{ submissionDeadLine }}</span><span class="seperator">|</span> </p>
          <p class="subtitle" v-if="data && data.Marks && !(assignmentResult && assignmentResult.marks)">Marks: <span> {{ data.Marks }}</span></p>
          <!-- <p class="subtitle" v-if="data && data.Marks">Passing Percentage: <span> {{ data.PassingPercentage }}</span> 
        <span
              class="seperator">|</span> </p> -->
          <p class="subtitle" v-if="assignmentResult && assignmentResult.marks">Score: <span>{{ assignmentResult &&
            assignmentResult.marks ? assignmentResult.marks : '0' }}/{{ data.Marks }} ({{ assignmentResult.percentage }}%)</span> </p>
          <!-- <p v-else-if="!isMaxSubmissionReached" class="subtitle">Submission Attempt: <span>{{ submissions.length + 1 }}</span> -->
          <!-- </p> -->
        </div>
        
        <h6 class="mb-0" v-if="assignmentResult && assignmentResult.isGraded && assignmentResult.result"><b-badge  :variant="assignmentResult.result.toLowerCase()=='pass'?'success':assignmentResult.result.toLowerCase()=='fail'?'danger':''" >{{ assignmentResult.result.toLowerCase()=='pass'?'Passed':assignmentResult.result.toLowerCase()=='fail'?'Failed':'' }}</b-badge> </h6>
      </div><!-- /assignment-header-section -->

      <!-- Attachment Right Sidebar  -->
      <div class="attachments-sidebar" id="attachments-modal">
        <div class="attachments-sidebar-body">

          <img class="attachment-close-icon me-2 right" 
          @click="toggleAttachmentsModal()"
          src="@/assets/images/cancel-icon.svg" />
          <div class="attachment-list" v-if="attachments && attachments.length > 0">
            <a class="card file-card" v-for="file in attachments" :key="file.Id" :href="file.url" target="_blank"
              download>
              <div class="attachment-container">
                <img src="@/assets/images/attachment-icon.svg" class="attachment-icon">
                <div class="attachment-details">
                  <h6 :title="file.name" class="attachment-title">{{ file.name }}</h6>
                  <small class="attachment-type">{{ file.type }}</small>
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            No files found
          </div>
        </div>
      </div>


      <div class="assignment-tabs">

        <b-tabs active-nav-item-class="font-weight-bold" class="tabs-content" v-model="activeTab">
          <b-tab title="Instructions">
            <div class="tab-content">
              <div class="tab-content-wrapper mt-3">
                <QuillRichTextEditor
								  :text="data.Discription"
								  :isInline="false"
								  editorID="instructions-description"
								  :isReadOnly="true"
								  v-model="data.Discription"
								/>
                <!-- <div class="instructions-description" v-html="sanitizeQuillHtml(data.Discription)"></div> -->

                <div class="row" v-if="data.Files">
                  <div class="col-lg-12 col-md-12" v-for="file in data.Files" :key="file.Id">
                    <a class="card file-card" :href="file.Url" target="_blank" download>
                      <div class="attachment-container">
                        <img class="attachment-icon" src="@/assets/images/attachment-icon.svg" />
                        <div class="attachment-details">
                          <h6 class="attachment-title" :title="file.Name">{{ file.Name }}</h6>
                          <small class="attachment-type">{{ file.Type }}</small>
                        </div>

                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Current Submission">
            <div class="tab-content">
              <div class="tab-content-wrapper my-submissions mt-4 mb-3">

                <div v-if="isMissing" class="mb-1 text-right">
                  <h6 class="status-label">Status:</h6>
                  <h6 class="status-value" :style="{ color: currentSubmissionStatusColor }">{{ currentSideSubmissionStatus
                  }}</h6>
                </div>


                <div v-if="submittedFiles && submittedFiles.length > 0">
                  <div v-for="file in submittedFiles" :key="file.id">
                    <a class="card file-card" :href="file.url" target="_blank" download>
                      <div class="attachment-container">
                        <img class="attachment-icon" src="@/assets/images/attachment-icon.svg" />
                        <div class="attachment-details">
                          <h6 class="attachment-title" :title="file.name">{{ file.name }}</h6>
                          <small class="attachment-type"> {{ file.type }}</small>

                          <small v-if="file.status == 'submitted'" class="text-success"><br />SUBMITTED</small>
                          <small v-if="file.status == 'uploaded'" class="text-primary"><br />Uploaded</small>
                          <small v-if="file.status == 'pending'" style="color: gray;"><br />Waiting For Upload</small>
                          <small v-if="file.status == 'failed'"><br />Failed ! {{ file.error }}</small>
                        </div>
                        <div v-if="isSubmissionAllowed" class="file-card-remove" @click.stop>
                          <button @click="removeFile(file.id)" class="downloadbtn"> <i class="fa fa-times"></i></button>
                        </div>
                      </div>
                      <div class="file-card-progress" v-if="file.status == 'uploading'">
                        <b-progress class="progress m-0" :value="file.uploadingProgress" :max="100" animated></b-progress>
                      </div>
                    </a>
                  </div>
                </div>

                <div v-else class="no-files-message">
                  <p>No files have been submitted. </p>
                </div>



                <div class="file-upload-actions">
                  <div v-if="isSubmissionAllowed" class="add-new-file">
                    <input type="file" style="display: none;" @change="onFilePicked" multiple ref="fileinput" />
                    <a class="add-btn dropdown-toggle" @click="uploadFile"  >

                      <img src="@/assets/images/plus-icon.svg" class="add-btn-icon" />
                      Add New File
                    </a>
                    
                  </div>

                  <p class="text-danger mx-4" v-if="isMaxSubmissionReached">
                    Assignment submission limit has been reached.
                  </p>

                  <div v-else-if="isSubmissionAllowed" class="d-md-flex">
                    <button @click="makeAssignmentSubmission" :disabled="isSubmitDisabled" class="add-btn submit-btn "
                      role="button">
                      Turn In
                    </button>
                    
                    <button @click="newSubmission" v-if="!isNewSubmission && assignmentResult &&  assignmentResult.isSubmitAssignment && isSubmissionAllowed"  :disabled="!isSubmissionAllowed"
                      class="add-btn submit-btn btn-secondary" role="button">
                      New Submission
                    </button>

                  </div> 

                  <p class="text-danger" v-else-if="isMissing">
                    Assignment submission date passed away.
                  </p>
                </div><!-- /file-upload-actions -->


              </div>
            </div>
          </b-tab>
          <b-tab title="Previous Submissions">
            <div class="tab-content">
              <div class="submission-table">
                <div v-if="submissions.length > 0" class="submission-body round">
                  <div class="submission-table-wrapper">
                    <ul class="submission-list">

                      <li class="submission-header bg-light">
                        <div class="submission-cell" style="flex: 0.15;"><b>#</b></div>
                        <div class="submission-cell" style="flex: 2;"><b>Feedback</b></div>
                        <div class="submission-cell" style="flex: 1;"><b>Date</b></div>
                        <div class="submission-cell" style="flex: 0.8; display: flex; align-items: center;">
                          <img class="submission-icon" src="@/assets/images/attachment-icon.svg" />
                          <b class="mx-2">Attachments</b>
                        </div>
                        <div class="submission-cell" style="flex: 0.8; display: flex; align-items: center;">
                          <img class="submission-icon" src="@/assets/images/attachment-icon.svg" />
                          <b class="mx-2">Trainer Attachments</b>
                        </div>
                      </li>

                      <li class="submission-row" v-for="(submission, index) in submissions"
                        :key="submission.id + '' + index">
                        <div class="submission-cell" style="flex: 0.15;">
                          {{ index + 1 }}
                        </div>
                        <div class="submission-cell" style="flex: 2;">
                          <span class="submission-feedback">{{ submission.feedback || "--" }}</span>
                        </div>
                        <div class="submission-cell" style="flex: 1;">
                          <span class="submission-date">{{ submissionDate(submission.submittedAt) }}s</span>
                        </div>

                        <div class="submission-cell"
                          style="flex: 0.8; display: flex; justify-content: center; align-items: center;">
                          <button @click="toggleAttachmentsModal(submission.submittedFiles)"
                            class="submission-action-button btn btn-outline-primary">View</button>
                          <div class="mx-1"></div>
                        </div>

                        <div class="submission-cell"
                          style="flex: 0.8; display: flex; justify-content: center; align-items: center;">
                          <button @click="toggleAttachmentsModal(submission.feedbackFiles)"
                            class="submission-action-button btn btn-outline-primary">View</button>
                          <div class="mx-1"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>


                <div v-else class="card p-3">
                  <div class="no-files-message">
                    <p>No previous submissions are made.. </p>
                  </div>
                </div>

              </div>
            </div>
          </b-tab>
        </b-tabs>



      </div><!-- /main-tabs-row -->


    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import QuillRichTextEditor from "./../../Editors/QuillRichTextEditor.vue";
import CookieHelpers from "./../../../helpers/CookieHelpers";
import { mapActions, mapState } from "vuex";
import { POST_FILE_API } from "./../../../constants/urls"
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Assignment",
  props: { contentData: Object },
  components: {
    vueDropzone: vue2Dropzone,
    QuillRichTextEditor
  },
  data() {
    return {
      activeTab: 0,
      quillEditor: null,
      fileSizeLimit: 500,
      dropzoneOptions: {
        url: " ",
        thumbnailWidth: 300,
        timeout: 100000000,
        uploadMultiple: true,
        autoProcessQueue: false
      },
      submittedFiles: [],
      isLoading: false,
      submittedAssignment: null,
      assignmentSubmittedAt: null,
      assignmentResult: null,
      submissions: [],
      attachments: [],
      isNewSubmission: false,
    };
  },
  props: {
    contentData: Object,
  },
  methods: {
    ...mapActions("assignment", [
      "submitAssignment",
      "getSubmittedAssignment",
      "getSubmittedAssignmentResults",
      "updateAssignmentSubmission",
      "getProductvariantAssignmentResults"
    ]),

    uploadFile() {
      this.$refs.fileinput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      files.forEach(file => {
        this.addFileForUpload(file);
      });

      // const fileReader = new FileReader()
      // fileReader.addEventListener('load', () => {
      // 	this.imageUrl = fileReader.result
      // })
      // fileReader.readAsDataURL(files[0])
    },
    addFileForUpload(file) {

      var nameParts = file.name.split(".");
      var extension = nameParts.pop();
      var name = nameParts.join(".").toUpperCase();
      var sizeInMB = (file.size / 1024) / 1024;
      var isSizeIncreasedLimit = sizeInMB > this.fileSizeLimit;

      this.submittedFiles.push({
        id: uuidv4(),
        name: name,
        file: file,
        extension: extension,
        type: this.fileTypeByExtentions(file.name) ?? this.fileTypeByType(file.type) ?? "File",
        url: null,
        status: isSizeIncreasedLimit ? 'failed' : 'pending',
        error: isSizeIncreasedLimit ? `File size should be less then ${this.fileSizeLimit}` : null,
        uploadingProgress: 0
      });
      this.uplaodNextFileInQueue();
    },
    fileTypeByExtentions(filename) {
      if (filename.endsWith(".pdf")) {
        return "PDF";
      }
      if (filename.endsWith(".ppt") || filename.endsWith(".pptx")) {
        return "Powerpoint";
      }
      if (filename.endsWith(".xls") || filename.endsWith(".xlsx")) {
        return "Excel Worksheet";
      }
      if (filename.endsWith(".csv")) {
        return "Comma Seperated Values";
      }
      if (filename.endsWith(".doc") || filename.endsWith(".docx")) {
        return "Document";
      }
      
      if (filename.endsWith(".png") || filename.endsWith(".jpg")) {
        return "image";
      }
    },
    fileTypeByType(fileType) {
      fileType = fileType.toLowerCase();
      if (fileType.indexOf("image") > -1) {
        return "Image";
      }
      if (fileType.indexOf("video") > -1) {
        return "Video";
      }

    },
    removeFile(fileId) {
      var file = this.submittedFiles.find(x => x.id == fileId);
      if (file) {
        if (file.xhr) {
          file.xhr.abort();
        }
        this.submittedFiles.splice(this.submittedFiles.indexOf(file), 1);
        this.uplaodNextFileInQueue();
      }

    },
    uplaodNextFileInQueue() {
      var self = this;
      var minUploadinglimit = 2;
      var inUploadingFiles = this.submittedFiles.filter(x => x.status.toLowerCase() == "uploading");
      var queuedFile = this.submittedFiles.find(x => x.status.toLowerCase() != "uploading" && x.status.toLowerCase() == "pending");
      if (inUploadingFiles.length <= (minUploadinglimit - 1) && queuedFile) {
        queuedFile.status = "uploading";
        queuedFile.uploadingProgress = 0;
        var uploadurl = this.apiURL + POST_FILE_API;

        const formData = new FormData();
        formData.append("entity", "Assignment");
        formData.append("type", queuedFile.type);
        formData.append('file', queuedFile.file);

        // post form data
        const xhr = new XMLHttpRequest()
        xhr.upload.onprogress = function (event) {
          var percentComplete = Math.floor((event.loaded / event.total) * 100);
          queuedFile.uploadingProgress = percentComplete;
          queuedFile.status = "uploaded";
          queuedFile.status = "uploading";
        };

        // Set up a handler for when the request completes
        xhr.onload = function () {
          if (xhr.status === 201) {
            setTimeout(function () {
              var response = JSON.parse(xhr.responseText);
              if (response && response.payload) {
                queuedFile.url = response.payload;
              }
              queuedFile.status = "uploaded";
              self.uplaodNextFileInQueue();
            }, 2000);
          } else {
            queuedFile.status = "failed";
            queuedFile.error = "Due to some error occurred";
            self.uplaodNextFileInQueue();
          }
        };

        xhr.open('POST', uploadurl,);
        queuedFile.xhr = xhr;
        xhr.setRequestHeader("AuthToken", "System123");
        xhr.setRequestHeader("Authorization", `Bearer ${CookieHelpers.getCookie("token")}`);
        xhr.send(formData);
      }
    },
    // initializeQuillEditor() {
    //   var options = {
    //     theme: 'snow',
    //     pastePlain: true,
    //     readOnly: true,
    //     modules: {
    //       toolbar: false
    //     }
    //   };
    //   var editor = new Quill('#editor', options);
    // },
    newSubmission() {
      this.submittedFiles = [];
      this.isNewSubmission = true;
    },
    makeAssignmentSubmission() {
      if (this.isMissing && this.data.IsLateSubimissionAllowed == false)
        return;

      if (this.isSubmitted && this.data.IsReSubimissionAllowed == false)
        return;

      if (this.submittedFiles.find(x => x.status.toLowerCase() == 'pending'))
        return;

      if (!this.submittedFiles.find(x => x.status.toLowerCase() == 'uploaded'))
        return;

      var files = this.submittedFiles.filter(x => x.status != 'failed').map(x => {
        return {
          Name: x.name,
          Url: x.url,
          Extension: x.extension,
          Type: x.type
        };
      });


      if (files.length > 0) {
        this.isLoading = true;

        let submissionId = this.submissions.length > 0 ?
          this.submissions[this.submissions.length - 1].id : null;

        if (!this.isNewSubmission && submissionId) {
          this.updateAssignmentSubmission({
            contentId: this.contentData.id,
            submissionId: submissionId,
            assignmentSubmissionFiles: files
          }).then(response => {
            if (response && response.submittedFiles && response.submittedFiles.length > 0) {
              this.submittedFiles = this.mapAssignmentFiles(response.submittedFiles);
              this.isLoading = false;
            }
          });
        }
        else {
          this.submitAssignment({ contentId: this.contentData.id, assignmentSubmissionFiles: files }).then(response => {
            if (response && response.submittedFiles && response.submittedFiles.length > 0) {
              this.submittedFiles = this.mapAssignmentFiles(response.submittedFiles);
              this.isLoading = false;
            }
          });
        }
      }

    },
    async getAssignmentSubmissions() {
      this.isLoading = true;
      var self=this;
      this.getSubmittedAssignmentResults(this.contentData.id).then(function (results) {
        if (results) {
          if (results.length > 0) {
            self.assignmentResult = results[results.length - 1];
            let dataList = self.assignmentResult.submissions || [];

            if (dataList && dataList.length > 0) {

              dataList.sort((a, b) => new Date(a.submittedAt) - new Date(b.submittedAt));
              self.submittedAssignment = dataList[dataList.length - 1];
              self.assignmentSubmittedAt = new Date(self.submittedAssignment.submittedAt);
              self.submittedFiles = self.mapAssignmentFiles(self.submittedAssignment.submittedFiles);
              self.submissions = [...dataList];

              self.isLoading = false;
            }
            else {
              self.isNewSubmission = true;
            }
          }
        }
      });

    },
    mapAssignmentFiles(files, status) {
      let tempFiles = files || [];

      return tempFiles.map(x => {
        return {
          id: uuidv4(),
          name: x.name,
          extension: x.extension,
          type: x.type,
          url: x.url,
          status: status || "submitted"
        }
      });
    },


    parseDate(date) {
      const deadlineDate = new Date(date);
      const dateString = deadlineDate.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const timeString = deadlineDate.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
      });

      return `${dateString} ${timeString}`;
    },

    toggleAttachmentsModal(attachments) {
      this.attachments = attachments;
      var el = document.getElementById('attachments-modal');
      if (el.classList.contains('active')) {
        el.classList.remove('active');
      } else {
        el.classList.add('active');
      }
    },

    setAttachments(attachments) {
      this.attachments = attachments;
    }


  },
  computed: {

    ...mapState("appState", ["apiURL"]),
    ...mapState("assignment", ["assignments"]),

    data() {
      return this.contentData && this.contentData.extraProperties ? JSON.parse(this.contentData.extraProperties) : null;
    },
    isSubmitted() {
      return this.submittedAssignment && this.submittedAssignment.submittedFiles.length > 0;
    },
    isLateSubmission() {
      return this.submittedAssignment && this.submittedAssignment.isLateSubmission == true;
    },
    isMissing() {
      if (this.data && this.data.Deadline && (new Date(this.data.Deadline) < new Date())) {
        return true;
      }
      return false;
    },

    isReSubmissionAllowed() {
      return this.data && this.data.IsReSubimissionAllowed == true;
    },

    isMaxSubmissionReached() {
      const allowedSubmissions = parseInt(this.data.AllowedSubmissions, 10);

      if (!isNaN(allowedSubmissions) && this.submissions != null) {
        return this.submissions.length >= allowedSubmissions;
      }

      return false;
    },

    submissionDeadLine() {
      if (this.data.Deadline) {
        return this.parseDate(this.data.Deadline)
      }

      return null;
    },

    submissionDate() {
      return (date) => {
        if (date) {
          return this.parseDate(date);
        }
        return null;
      };
    },
    currentSubmissionStatusColor() {
      if (this.isSubmitted) {
        if (this.isLateSubmission)
          return "rgb(var(--bs-danger-rgb))"

        return "#198754"
      }
      if (this.isMissing) {
        return "rgb(var(--bs-danger-rgb))"
      }
      return "#808080"
    },
    currentSubmissionStatus() {
      if (this.isSubmitted) {
        if (this.isLateSubmission)
          return "Late Submission"

        return "Submitted"
      }
      if (this.isMissing) {
        return "Submission Date Passed"
      }
      return "Not Submitted Yet"
    },
    currentSideSubmissionStatus() {
      if (this.isSubmitted) {
        if (this.isLateSubmission)
          return "Submitted Late"
        return "Submitted"
      }
      if (this.isMissing && this.data.IsLateSubimissionAllowed == true) {
        return "Date Missed"
      }
      return ""
    },
    isSubmitDisabled() {
      if (this.isLoading)
        true;

      if (this.submittedFiles.length == 0)
        return true;
      if (this.submittedFiles.find(x => x.status == "pending")) {
        return true;
      }
      if (!this.submittedFiles.find(x => x.status == "uploaded")) {
        return true;
      }

      return false;
    },
    // submissionResultClass() {
    //   return (result) => ({
    //     'submission-result': true,
    //     pass: result === 'Pass',
    //     fail: result === 'Fail',
    //   });
    // },
    isAssignmentGraded() {
      return this.assignmentResult && this.assignmentResult.isGraded === true;
    },
    isSubmissionAllowed() {
      return !this.isMaxSubmissionReached && !this.isAssignmentGraded &&
            (!this.isMissing || this.data.IsLateSubimissionAllowed) && 
            (!this.isSubmitted || this.isReSubmissionAllowed == true);
    }

  },
  mounted() {
    //this.initializeQuillEditor();
  },
  created() {
    this.getAssignmentSubmissions();
    // this.getAssignmentResult();
  },
  watch: {

  },
};

</script>

<style scoped>
.badge.badge-success
{
  background-color: green;
}
.badge.badge-danger
{
  background-color:red;
}
.assignment-main-content {
  margin-right: 60px;
  position: relative;
}

.assignment-title {
  color: black;
  margin-bottom: 16px;
}

.assignment-info-row {
  display: flex;
  justify-content: start;
}

.subtitle {
  color: black;
  font-size: 0.975rem;
  font-weight: 700;
}

.subtitle span {
  color: #8F8F8F;
  font-size: 18;
  font-weight: 500;
  margin-left: 5px;
}

.seperator {
  color: black;
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.tabs-content {
  margin-top: 20px;
}

.assignment-tabs {
  padding: 0;
}

.assignment-tabs>>>.nav-tabs {
  display: flex;
  border-bottom: solid 1px var(--border-color);
}

.assignment-tabs>>>.nav-tabs .nav-link {
  height: 46px;
  line-height: 40px;
  font-size: 0.975rem;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--gray);
}

.assignment-tabs>>>.nav-tabs .nav-link,
.assignment-tabs>>>.nav-tabs .nav-link:hover {
  border: none !important;
  background: none;
  outline: none !important;
}

.assignment-tabs>>>.nav-tabs .nav-link.active {
  background: none;
  border: none;
  border-bottom: solid var(--primary-color) !important;
  font-weight: 700;
  color: var(--primary) !important;
}


.assignment-tabs .tabs-content {
  margin-top: 0;
}



.instructions-description {
  padding: 25px 0 15px;
}

.file-card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  text-decoration: none;
  margin: 10px 0;
  padding: 10px 15px;
  transition: box-shadow 0.3s ease-in-out;
}

.file-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attachment-container {
  display: flex;
  align-items: start;
  width: 100%;
  cursor: pointer;
}

.attachment-icon {
  flex-shrink: 0;
  height: 30px;
  width: 25px;
  margin-right: 5px;
  color: #333;
}

.attachment-details {
  flex-grow: 1;
  padding: 2px 5px;
}

.attachment-title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
  word-break: break-word;
}

.attachment-type {
  font-size: 14px;
  color: #767676;
}

.file-card-progress {
  margin-bottom: 10px;
}

.file-card label {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.file-card small {
  font-size: 1rem;
  color: #888;
}

.file-card .text-success,
.file-card .text-primary {
  font-size: 1rem;
  font-weight: bold;
}

.file-card .text-success {
  color: green;
}

.file-card .text-primary {
  color: var(--primary-color);
}

.file-card .text-danger {
  color: red;
}

.file-card-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}


.add-btn {
  display: flex;
  width: 200px;
  align-items: center;
  margin: 0 15px 10px 0;
  color: #000;
  height: 38px;
  border-radius: 6px;
}

.add-btn-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.add-btn.submit-btn {
  background: var(--primary-color);
  color: white;
}

.status-label {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0;
}

.status-value {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}

.tab-content {
  /* background-color: #f5f5f5;
  padding: 20px; */
}


.no-files-message {
  text-align: left;
  padding: 20px 0;
  font-size: 1rem;
  color: black;
}


/* Last Submissions */

.submission-body {
  background-color: #fff;
  overflow: hidden;
}

.submission-table-wrapper {
  overflow-x: auto;
}

.submission-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submission-header {
  display: flex;
  color: black;
  padding: 10px 0;
  border-bottom: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
}

.submission-cell {
  flex: 1;
  padding: 10px;
}

.submission-cell b {
  font-weight: bold;
}

.submission-row {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  transition: background-color 0.2s ease;
  align-items: center;
}

.submission-row:hover {
  background-color: #f0f0f0;
}

.submission-feedback {
  /* color: #e60000; */
  /* Red color for feedback */
}

.submission-date {
  /* font-weight: bold; */
}

.submission-result {
  font-weight: bold;
  color: green;
  /* Green color for pass */
}

.pass {
  color: green;
}

.fail {
  color: red;
  /* Red color for fail */
}

.submission-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.eb-content-wrapper .submission-action-button {
  /* background-color: var(--color-red);
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: transform 0.2s ease; */
  font-size: 0.975rem;
}

.file-upload-actions {
  display: flex;
  flex-wrap: wrap;
}


.mx-1 {
  margin: 0 5px;
}


/* Style for the modal */
.b-modal-content {
  width: 30%;
  height: 60%;
  overflow-y: auto;
  margin: 0;
  padding: 20px;
  border-radius: 0;
  position: fixed;
  right: 0;
  top: 20%;
  transform: translateY(-50%);
}

.attachment-modal .modal-footer {
  display: none;
}

.attachments-sidebar {
  position: absolute;
  right: -70px;
  width: 30%;
  max-width: 300px;
  top: -20px;
  bottom: 0;
  z-index: 25;
  height: 100%;
  min-height: calc(100vh - 248px);
  transition: all 0.2s ease-in-out;
  width: 0;
  opacity: 0;
}

.attachments-sidebar.active {
  width: 30%;
  opacity: 1;
}

.attachments-sidebar-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--gray-light);
  padding: 20px;
}

.attachments-sidebar-body .file-card {
  margin: 0 0 15px;
}

.attachment-close-icon {
  max-width: 12px;
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.attachment-list {
  margin-top: 20px;
}

@media (min-width: 769px) {
  .assignment-tabs>>>.nav-tabs>li:first-child .nav-link {
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .assignment-main-content {
    padding-right: 0px;
  }

  .assignment-info-row {
    flex-wrap: wrap;
  }

  .assignment-info-row p {
    margin-bottom: 4px;
  }

  .assignment-header-section {
    padding: 15px;
    background-color: var(--gray-light);
  }

  .tab-content-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .attachment-title {
    word-break: break-word;
  }

  .attachments-sidebar {
    right: 0;
    overflow: hidden;
    top: 0;
  }

  .attachments-sidebar.active {
    width: 75%;
  }

  .submission-cell {
    font-size: 1rem;
  }

  .submission-header .submission-cell:last-child {
    justify-content: center;
  }

  .submission-header .submission-cell:last-child b {
    display: none;
  }

  .attachment-modal .modal-body {
    flex-direction: column;
    padding: 20px 15px 25px 15px !important;
  }

  .attachment-modal .modal-body .file-card-wrapper {
    width: 100%;
  }
}</style>
<style>@media (max-width: 768px) {
  .chapter-content.Assignment {
    padding: 0 0 15px;
  }
}</style>