function getFileType(fileName) {
  var fileExt = fileName.split(".").pop().toLowerCase();

  switch (fileExt) {
    case "mp4":
    case "webm":
    case "ogg":
      return "video";
    case "ppt":
    case "pptx":
    case "doc":
    case "docx":
    case "xls":
    case "xlsx":
    case "csv":
      return "document";
    case "pdf":
      return "pdf";
    case "txt":
      return "text";
    case "jpg":
    case "jpeg":
    case "png":
    case "svg":
    case "gif":
      return "image";
    default:
      return "unknown";
  }
}

module.exports = {
  getFileType,
};
