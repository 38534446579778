class ErrorMessage {
	static 102 =
		"The device limit for this platform has exceeded. First reset previous devices then attempt login.";
	static 104 = "Invalid Device Token";
	static 107 = "Invalid Email or Password.";
	static 108 = "User not found";
	static 116 =
		"A User with the same username, email or phone number already exists";
	static 137 =
		"Unable to reset device. Please wait ? minutes before attempting again";
	static 138 = "Unable to reset device. Device not found";

	static 142 =
		"Your account has been deactivated. Please contact system administrator.";

	static default = "Some error occurred";
}

export default ErrorMessage;
