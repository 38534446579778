<template>
	<div class="tabs-banner" :class="{ mobile: isMobile }">
		<div class="container">
			<div class="d-flex align-items-center mb-4">
				<div class="d-flex align-items-center gap-3" v-if="!isMobile">
					<div v-if="user.profilePicture" class="user-avatar large">
						<img :src="user.profilePicture" />
					</div>
					<img height="54" v-else src="../Web Assets/user-avatar.svg" />
					<p class="mb-0 banner-text">
						Welcome &nbsp;-&nbsp; {{ currentUserName }}
					</p>
				</div>
				<router-link
					to="/register"
					class="add-btn ms-auto"
					id="registerCoursesBtn"
					role="button"
					v-if="systemParameters.allowStudentCourseRegistration"
				>
					<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
					register new courses
				</router-link>
			</div>
			<div>
				<ul
					:class="[
						'inline-list',
						{
							'mobile-banner-tabs': isMobile,
							'banner-tabs': !isMobile,
						},
					]"
				>
					<li
						v-for="tab in tabsList"
						:key="tab.value"
						:id="tab.value"
						@click="changeTab"
						:class="{
							selected: activeTab == tab.value,
							'mobile-tab': isMobile,
							'banner-tab': !isMobile,
							'd-none': !tab.showOnMobile && isMobile,
						}"
					>
						<i :class="`${tab.iconClass} tab-icon`"></i
						>{{ !isMobile ? tab.label : tab.mobileLabel }}
					</li>
					<!-- <li
						@click="changeTab"
						:class="{
							selected: this.activeTab == 'courses',
							'mobile-tab': isMobile,
							'banner-tab': !isMobile,
						}"
						id="courses"
					>
						<i class="fas fa-book tab-icon"></i>{{ tabText[0] }}
					</li> -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "DashboardBanner",
	methods: {
		changeTab(e) {
			// this.activeTab = e.target.id;
			this.$emit("activeTab", e.target.id);
		},
	},
	props: { activeTab: String, tabsList: Array },
	computed: {
		...mapState("user", ["user"]),
		...mapState("appState", ["isMobile", "systemParameters"]),
		currentUserName() {
			return `${this.user.firstName} ${this.user.lastName}`;
		},
		tabText() {
			if (this.isMobile) {
				return ["Courses", "Discussion"];
			} else return ["My Courses", "Discussion Forum"];
		},
	},
};
</script>

<style scoped>
.tabs-banner {
	padding: 1.5rem 0 0;
	font-weight: 500;
	font-size: 1rem;
	color: var(--color-text-default);
	/* background-color: var(--color-light-gray); */
	background-image: url("../Web Assets/Student Dashbaord BG.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

/* .tabs-banner .container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
} */

.tabs-banner.mobile .container,
.tabs-banner .container {
	padding: 0 1rem;
}

.mobile-banner-tabs {
	display: flex;
	padding: 0 0 5rem;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	box-shadow: 0 3px 6px #d5d5d529;
}

.banner-tab {
	padding: 1rem 1.25rem;
	border-radius: 8px 8px 0 0;
	background-color: var(--color-off-white);
	font-weight: 500;
	/* font-size: 0.8rem; */
	margin-right: 1rem;
	cursor: pointer;
	box-shadow: 0 -6px 8px #e0e0e029;
}

.mobile-tab {
	background-color: var(--color-off-white);
	font-weight: 500;
	padding: 1.125rem 0rem;
	box-shadow: 0 -6px 8px #e0e0e029;
	border-radius: 0 0.5rem 0.5rem 0 !important;
}

.mobile-tab:first-of-type {
	border-radius: 0.5rem 0 0 0.5rem !important;
}

.banner-tab.selected,
.mobile-tab.selected {
	background-color: var(--color-white) !important;
	/* line-height: 1.6; */
}

.tab-icon {
	font-size: 1.1rem;
	margin-right: 0.5rem;
	pointer-events: none;
}

.banner-tab.selected,
.mobile-tab.selected {
	color: var(--primary-color) !important;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.user-avatar {
	width: 2rem;
	height: 2rem;
	color: var(--color-white);
	background-color: #231f20;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2rem;
	margin-right: 0.5rem;
	/* z-index: 100; */
}

.user-avatar img {
	vertical-align: middle;
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.banner-text {
	font-size: 1.125rem;
}
</style>
