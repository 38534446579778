<template>
	<div class="d-flex justify-content-center container">
		<div class="service-features">
			<div class="service-features-item">
				<div>
					<img
						class="service-image"
						src="../../assets/images/servicIcon2.png"
						alt=""
					/>
				</div>
				<div class="service-text">
					Best
					<span class="text-bold">
						Courses<br />
						in Lowest</span
					><br />
					Cost
				</div>
			</div>
			<div class="service-features-item">
				<div>
					<img
						class="service-image"
						src="../../assets/images/servicIcon1.png"
						alt=""
					/>
				</div>
				<div class="service-text">
					Top
					<span class="text-bold"
						>Award <br />
						Winning</span
					>
					<br />
					Teachers
				</div>
			</div>
			<div class="service-features-item">
				<div>
					<img
						class="service-image"
						src="../../assets/images/servicIcon3.png"
						alt=""
					/>
				</div>
				<div class="service-text">
					7 Days <br />
					<span class="text-bold"
						>Free Trial <br />
						Classes</span
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomePageServiceFeatures",
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.service-features-item {
		flex-direction: column;
		text-align: center;
	}
}

@media screen and (max-width: 767px) {
	.service-features {
		display: none !important;
	}
}

.service-features {
	width: 100%;
	border-radius: 10px;
	background-color: #ebebeb;
	box-shadow: 0 3px 6px #d5d5d529;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-column-gap: 1rem;
	padding: 1.875rem;
	margin-top: -8rem;
	z-index: 99;
}

.service-features-item {
	padding: 2rem;
	display: flex;
	background-color: #fafafa;
	border-radius: 10px;
	align-items: center;
	box-shadow: 0 3px 6px #a8a8a829;
}

.service-image {
	height: 6.25rem;
	margin-right: 2.375rem;
}

.service-text {
	font-size: 1.5rem;
	text-transform: uppercase;
	color: var(--secondary-color);
	line-height: 1.3;
	font-weight: 500;
}

.service-text span {
	font-weight: bold;
	color: var(--primary-color);
}
</style>
