<template>
	<div class="eb-chapter-list" v-if="chapterContent && chapterContent.children">
		<div v-if="filteredTopics.length > 0">
			<div class="eb-chapter-row" v-for="topic in filteredTopics">
				<div class="chapter-row-item"><a class="topic-text">{{ topic.title }}</a></div>
				<div class="chapter-row-item">
					<ul class="chapter-row-subitems">
						<ProductContentChaptersContentListItem
							v-for="content in applyFilter(filterChildrens(topic.children))" :chapterID="chapterID" :item="content">
						</ProductContentChaptersContentListItem>
					</ul>
				</div>
			</div>
		</div>
		<div v-else>
			<br />
			<center>
				<h6>No Content Found</h6>
			</center>
		</div>
	</div><!-- /eb-chapter-list -->
	<div v-else class="loading-div mt-2 mb-2">
		<center><b-spinner class="loader"> </b-spinner></center>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus } from "../../constants/productVariant";
import ProductContentChaptersContentListItem from "./ProductContentChaptersContentListItem"
export default {
	name: "ProductContentChaptersContentList",
	data() {
		return {
		};
	},
	props: {
		filters: Object,
		chapterID: String
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		
		filterChildrens(list) {
			return list.filter((item) => {
				if (item.type !== 'RecordedVideos' || item?.externalService?.serviceProvider?.toLowerCase() != 'vss') {
					return item;
				}
				else if (item.extraProperties && item.extraProperties.Status && item.extraProperties.Status.toLowerCase() === 'processed') {
					return item
				}
			});
		},

		applyFilter(list) {
			const filters = this.filters;
			var self=this;
			if(filters.confidence_level && filters.confidence_level.length>0)
			{
				list=list.filter(x=>
					(filters.confidence_level.indexOf("unrated")>-1  && !(self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level"))) || 
					(filters.confidence_level.indexOf("high")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='high')) || 
					(filters.confidence_level.indexOf("medium")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='medium')) || 
					(filters.confidence_level.indexOf("low")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='low')) 
				);
			}

			
			if(filters.completionStatus && filters.completionStatus.length>0)
			{
				list=list.filter(x=>
					(filters.completionStatus.indexOf("not_started")>-1  && !(self.contentActions[x.id]?.find(x=>x.dataType=="completionStatus" && x.data.toLowerCase()=='completed'))) || 
					(filters.completionStatus.indexOf("completed")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="completionStatus" && x.data.toLowerCase()=='completed')) 
				);
			}

			if(filters.bookmark_content && filters.bookmark_content.length>0)
			{
				list=list.filter(x=>
					(filters.bookmark_content.indexOf("bookmark")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="bookmark" && x.data.toLowerCase()=='true')) 
				);
			}

			if(filters.searchText)
			{
				list=list.filter(x=>x.title.toLowerCase().indexOf(filters.searchText.toLowerCase())>-1);
			}

			return list;
			
		},


		isFilterSelected(filter, filterType) {
			if (this.filters[filterType]) {
				const lowerCaseFilter = filter.toLowerCase();
				return this.filters[filterType].some(f => f.toLowerCase() === lowerCaseFilter);
			}

			return false;
		}


	},
	computed: {
		...mapState("content", ["content", "contentActions"]),
		...mapState("content", ["loading"]),

		filteredTopics() {
			if (!this.chapterContent || !this.chapterContent.children) return [];
			return this.chapterContent.children.filter(topic => {
				return (
					topic &&
					this.applyFilter(this.filterChildrens(topic.children)).length > 0
				);
			});
		},
		chapterContent(){
			if(this.chapterID && this.content[this.chapterID])
			{
				return this.content[this.chapterID];
			}
		}
	},
	watch: {
		content: function () {
			
		},
		// filters: function () {
		// 	console.log("Filters called watch")
		// },

		// $route(to, from) {
		// 	if (this.$route.query.chapterId && this.$route.query.chapterId != this.chapterId) {
		// 		this.chapterId = this.$route.query.chapterId;

		// 		this.getChapterContent();
		// 	}
		// }
	},
	components: { ProductContentChaptersContentListItem },
	created() {
		// if (this.$route.query.chapterId && this.$route.query.chapterId != this.chapterId) {
		// 	this.chapterId = this.$route.query.chapterId;
		// 	this.chapterContent = null;
		// }
		// this.getChapterContent();
	},
	mounted() {
	}
};
</script>

<style scoped>
.topic-text{
	color: var(--primary-color);
}
</style>
