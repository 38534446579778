var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"add-enroll-title"},[_vm._v("Generating Invoices")]),_c('table',{staticClass:"table table-hover table-borderless enrolled-users-table mt-5"},[_c('thead',[_c('tr',{staticClass:"d-flex"},[_c('th',{staticClass:"col-1"},[_vm._v("ID")]),_c('th',{staticClass:"col"},[_vm._v("Name")]),_c('th',{staticClass:"col"},[_vm._v("Plan")]),_c('th',{staticClass:"col"},[_vm._v("Invoice")]),(_vm.selectedPlan.payments)?_c('th',{staticClass:"col"},[_vm._v("Payments")]):_vm._e()])]),_c('tbody',_vm._l((_vm.selectedStudents),function(student){return _c('tr',{key:student.id,staticClass:"d-flex"},[_c('td',{staticClass:"col-1"},[_vm._v(_vm._s(student.id))]),_c('td',{staticClass:"col"},[_vm._v(_vm._s(student.firstName)+" "+_vm._s(student.lastName))]),_c('td',{staticClass:"col"},[_vm._v(_vm._s(_vm.selectedPlan.item.title))]),_c('td',{staticClass:"col"},[(!_vm.loading && _vm.isInvoiceGenerated(student.id))?_c('div',{staticClass:"status-tag success"},[_vm._v(" Generated ")]):(
							(!_vm.loading && _vm.isFailed) ||
							(!_vm.loading && !_vm.isInvoiceGenerated(student.id))
						)?_c('div',{staticClass:"status-tag danger"},[_vm._v(" Failed ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])]):_vm._e()]),(_vm.selectedPlan.payments)?_c('td',{staticClass:"col"},[(!_vm.loading && !_vm.isFailed)?_c('div',{staticClass:"status-tag success"},[_vm._v(" Paid ")]):(
							(!_vm.loading && _vm.isFailed) ||
							(!_vm.loading && !_vm.isInvoiceGenerated(student.id))
						)?_c('div',{staticClass:"status-tag danger"},[_vm._v(" Failed ")]):_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }