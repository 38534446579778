<template>
	<div class="course-pricing-container">
		<div class="pricing-status-card" v-if="!loading">
			<div class="title-section">
				<div class="title-text">
					<h1 class="title">Status</h1>
					<span class="sub-title"> Select pricing status for this course </span>
				</div>
				<CreatePricingPlanSidebar
					:productVariantData="productVariantData"
					v-if="pricingType.toLowerCase() == 'paid'"
					@updatePlans="updatePricingPlansByType"
					:planData="planToEdit"
					:editMode="editMode"
					:planDetailToEdit="planDetailToEdit"
					@updateModalState="toggleModal"
					:showModal="showModal"
					:planCountryAndCurrency="planCountryAndCurrency"
				/>
			</div>
			<div class="buttons-section">
				<div
					@click="changeType('Paid')"
					:class="[
						'status-card',
						{ checked: pricingType.toLowerCase() == 'paid' },
					]"
				>
					<p>Paid</p>
					The course will be paid. You can create multiple plans to cater to
					students from different countries.
				</div>
				<div
					@click="changeType('Free')"
					:class="[
						'status-card',
						{ checked: pricingType.toLowerCase() == 'free' },
					]"
				>
					<p>Free</p>
					The course will be free. Students will be able view and consume all
					content in this course without cost.
				</div>
			</div>
		</div>
		<div
			v-if="loading && oneTimePlans.length == 0 && multiplePlans.length == 0"
		>
			<b-skeleton height="18rem" width="100%" class="mb-4"></b-skeleton>
			<b-skeleton height="2.5rem" width="40%" class="mb-4"></b-skeleton>
			<div class="inline-group">
				<b-skeleton width="50%" height="15rem"></b-skeleton>
				<b-skeleton width="50%" height="15rem"></b-skeleton>
			</div>
		</div>
		<div
			v-if="
				this.pricingPlanByProductVariantId[this.productVariantData.id] &&
				productVariantData &&
				pricingType.toLowerCase() == 'paid'
			"
		>
			<div v-if="oneTimePlans.length">
				<h1 id="single-plan" class="page-title">One-Time Payment Plans</h1>
				<div class="pricing-plan-list">
					<div
						v-for="(plan, index) in oneTimePlans"
						:key="index"
						class="pricing-plan-item single"
					>
						<div class="title-section">
							<div class="title-text">
								<h1 class="title">
									{{ plan.title }}
								</h1>
								<span class="sub-title">
									Currency:
									<span class="text-black"
										>{{ getCurrencyName(plan.currency) }} ({{
											plan.currency
										}})</span
									>
									<br />
									<div
										class="country-tags"
										:title="planCountriesText(plan.country)"
									>
										<span>Applicable Countries: </span>
										<span class="text-black" v-if="plan.country.length == 249"
											>All Countries</span
										>
										<span class="countries-text text-black" v-else>
											{{ planCountriesText(plan.country) }}
										</span>
									</div>
								</span>
							</div>
							<div class="d-flex flex-column align-items-end">
								<h1 class="title">
									{{
										currencyParser(
											parseInt(planDetailAmount(plan.details[0])),
											plan.currency
										)
									}}
								</h1>
								<div class="right-menu">
									<a
										@click="editPlan(plan)"
										role="button"
										class="right-menu-icon"
									>
										<img src="@/assets/images/edit-icon.svg" />
									</a>
									<a
										@click="showDeleteConfirmPopup($event, plan)"
										role="button"
										class="right-menu-icon delete"
									>
										<img src="@/assets/images/delete-icon.svg" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="multiplePlans.length">
				<h1 id="multiple-plan" class="page-title">
					Multiple Payment-Cycle Plans
				</h1>
				<div class="pricing-plan-list">
					<div
						v-for="(plan, index) in multiplePlans"
						:key="index"
						class="pricing-plan-item multiple"
					>
						<div class="title-section">
							<div class="title-text">
								<h1 class="title">
									{{ plan.title }}
								</h1>
								<div class="sub-title">
									Currency:
									<span class="text-black"
										>{{ getCurrencyName(plan.currency) }} ({{
											plan.currency
										}})</span
									>
									<div
										class="country-tags"
										:title="planCountriesText(plan.country)"
									>
										<span>Applicable Countries: </span>
										<br />
										<span class="text-black" v-if="plan.country.length == 249"
											>All Countries</span
										>
										<span class="countries-text text-black" v-else>
											{{ planCountriesText(plan.country) }}
										</span>
									</div>
								</div>
							</div>
							<div class="right-menu">
								<a
									@click="editPlan(plan)"
									role="button"
									class="right-menu-icon"
								>
									<img src="@/assets/images/edit-icon.svg" />
								</a>
								<a
									@click="showDeleteConfirmPopup($event, plan)"
									role="button"
									class="right-menu-icon delete"
								>
									<img src="@/assets/images/delete-icon.svg" />
								</a>
							</div>
						</div>
						<div class="buttons-section">
							<div class="d-flex flex-column width-100">
								<p>Payment Cycles:</p>
								<div class="d-flex flex-row flex-wrap gap-2">
									<div
										v-for="(detail, index) in plan.details"
										:key="index"
										class="status-card col-6"
									>
										<div class="d-flex flex-row justify-content-between">
											<div>
												<p class="m-0 mb-3">{{ detail.title }}</p>
												Start Date:
												{{ new Date(detail.startAt).toDateString() }}
												<br />
												End Date: {{ new Date(detail.endAt).toDateString() }}
												<br />
											</div>
											<div class="d-flex flex-column align-items-end">
												<p class="m-0">
													{{ currencyParser(detail.amount, plan.currency) }}
												</p>
												<div class="right-menu">
													<a
														@click="editPlanDetail(plan, index)"
														role="button"
														class="right-menu-icon"
													>
														<img src="@/assets/images/edit-icon.svg" />
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CreatePricingPlanSidebar from "./CreatePricingPlanSidebar.vue";
import { mapState, mapActions } from "vuex";
import { currencyCodes } from "../../../constants/currencyCodes";
import { countryCodes } from "../../../constants/countryCodes";
import formatter from "../../../helpers/FormatHelpers";
export default {
	name: "CoursePricing",
	data() {
		return {
			pricingType: "Free",
			currencyCodes,
			countryCodes,
			oneTimePlans: [],
			multiplePlans: [],
			editMode: false,
			planToEdit: null,
			planDetailToEdit: null,
			showModal: false,
			planTypes: {
				ONE_TIME: "OneTime",
				MULTIPLE_PAYMENT_CYCLES: "MultiplePaymentCycles",
			},
			loading: false,
			plansLoading: false,
			planCountryAndCurrency: {},
		};
	},
	methods: {
		...mapActions("pricing", [
			"fetchPricingPlanByProductVariantId",
			"deletePricingPlan",
		]),
		...mapActions("productVariant", ["updateProductVariant"]),
		changeType(type) {
			console.log(type);
			var updateVariant = {
				id: this.productVariantData.id,
				pricingStatus: type,
				productID: this.productVariantData.product.id,
			};
			this.updateProductVariant(updateVariant).then(() => {
				this.pricingType = type;
			});
		},
		getCurrencyName(code) {
			return Object.values(this.currencyCodes).find((c) => c.code == code).name;
		},
		getCountryName(code) {
			if (code.length == 3)
				return this.countryCodes.find((c) => c["alpha-3"] == code).name;
			else return "";
		},
		currencyParser(amount, currency) {
			// console.log(formatter.toCurrencyString(amount, this.planCurrency, 0));
			return formatter.toCurrencyString(amount, currency, 0);
		},
		updatePricingPlansByType(type) {
			this.planCountryAndCurrency = {};
			if (this.pricingPlanByProductVariantId[this.productVariantData.id]) {
				var filteredPlans = this.pricingPlanByProductVariantId[
					this.productVariantData.id
				].filter((plan) => {
					if (!this.planCountryAndCurrency[plan.currency]) {
						this.planCountryAndCurrency[plan.currency] = plan.country;
					} else {
						plan.country.forEach((c) => {
							if (this.planCountryAndCurrency[plan.currency].indexOf(c) == -1) {
								this.planCountryAndCurrency[plan.currency] = [];
								this.planCountryAndCurrency[plan.currency].push(
									...plan.country
								);
							}
						});
					}
					if (plan.type.toLowerCase() == type.toLowerCase()) {
						return plan;
					}
				});
				type == this.planTypes.ONE_TIME
					? (this.oneTimePlans = filteredPlans)
					: (this.multiplePlans = filteredPlans);
				// return filteredPlans;
			}
			// return [];
		},
		editPlan(plan) {
			this.planToEdit = plan;
			this.editMode = true;
			this.showModal = true;
		},
		editPlanDetail(plan, index) {
			this.planToEdit = plan;
			this.planDetailToEdit = index.toString();
			this.editMode = true;
			this.showModal = true;
		},
		toggleModal(state) {
			if (this.editMode) {
				this.planToEdit = null;
				this.editMode = false;
				this.planDetailToEdit = null;
			}
			this.showModal = state;
		},
		planDetailAmount(detail) {
			return (
				detail && detail.amountDetail.find((d) => d.title == "price").amount
			);
		},
		showDeleteConfirmPopup(e, plan) {
			e.preventDefault();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete the Pricing Plan: \n${plan.title}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.planDelete(plan);
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		planDelete(plan) {
			plan.productId = this.productVariantData.id;
			this.deletePricingPlan(plan).then(() => {
				console.log("plan deleted");
			});
		},
		planCountriesText(countries) {
			if (countries.length > 230) {
				var countriesNotSelected = this.countryCodes
					.filter((c) => {
						if (countries.indexOf(c["alpha-3"]) == -1) {
							return c;
						}
					})
					.map((country) => {
						return country.name;
					});
				console.log(countriesNotSelected);
				return (
					"All countries except " +
					countriesNotSelected.toString().split(",").join(", ") +
					"."
				);
			}
			return (
				countries
					.map((country) => this.getCountryName(country))
					.toString()
					.split(",")
					.join(", ") + "."
			);
		},
	},
	components: { CreatePricingPlanSidebar },
	props: { productVariantData: Object },
	computed: {
		...mapState("pricing", ["pricingPlanByProductVariantId"]),
	},
	created() {
		this.pricingType = this.productVariantData.pricingStatus;
		if (this.pricingType && this.pricingType.toLowerCase() == "paid")
			if (
				this.pricingPlanByProductVariantId[this.productVariantData.id] &&
				this.pricingPlanByProductVariantId[this.productVariantData.id].length >
					0
			) {
				// console.log("found plans");
				// this.pricingType = "Paid";
				this.updatePricingPlansByType(this.planTypes.ONE_TIME);
				this.updatePricingPlansByType(this.planTypes.MULTIPLE_PAYMENT_CYCLES);
			} else {
				this.loading = true;
				this.fetchPricingPlanByProductVariantId(
					this.productVariantData.id
				).then(() => {
					this.loading = false;
					if (
						this.pricingPlanByProductVariantId[this.productVariantData.id] &&
						this.pricingPlanByProductVariantId[this.productVariantData.id]
							.length
					) {
						// console.log("fetched plans");
						// this.pricingType = "Paid";
						this.updatePricingPlansByType(this.planTypes.ONE_TIME);
						this.updatePricingPlansByType(
							this.planTypes.MULTIPLE_PAYMENT_CYCLES
						);
					}
				});
			}
	},
	watch: {
		pricingType: function () {
			if (this.pricingType && this.pricingType.toLowerCase() == "paid")
				if (
					this.pricingPlanByProductVariantId[this.productVariantData.id] &&
					this.pricingPlanByProductVariantId[this.productVariantData.id].length
				) {
					console.log("found plans");
					// this.pricingType = "Paid";
					this.updatePricingPlansByType(this.planTypes.ONE_TIME);
					this.updatePricingPlansByType(this.planTypes.MULTIPLE_PAYMENT_CYCLES);
				} else {
					this.loading = true;
					this.fetchPricingPlanByProductVariantId(
						this.productVariantData.id
					).then(() => {
						this.loading = false;
						if (
							this.pricingPlanByProductVariantId[this.productVariantData.id] &&
							this.pricingPlanByProductVariantId[this.productVariantData.id]
								.length
						) {
							console.log("fetched plans");
							// this.pricingType = "Paid";
							this.updatePricingPlansByType(this.planTypes.ONE_TIME);
							this.updatePricingPlansByType(
								this.planTypes.MULTIPLE_PAYMENT_CYCLES
							);
						}
					});
				}
		},
		pricingPlanByProductVariantId: function () {
			this.updatePricingPlansByType(this.planTypes.ONE_TIME);
			this.updatePricingPlansByType(this.planTypes.MULTIPLE_PAYMENT_CYCLES);
		},
		showModal: function () {
			if (this.showModal) return;
			this.updatePricingPlansByType(this.planTypes.ONE_TIME);
			this.updatePricingPlansByType(this.planTypes.MULTIPLE_PAYMENT_CYCLES);
		},
	},
};
</script>

<style scoped>
.course-pricing-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 2rem 1.5rem 0;
	overflow-y: auto;
}

.pricing-status-card,
.pricing-plan-item {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 6px;
	/* padding: 2rem 1.875rem; */
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	flex-grow: 0;
}

.title-section {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 2rem 1.875rem;
	border-bottom: 1px solid #ebebeb;
	justify-content: space-between;
}

/*  .title-section .title-text {
} */

.buttons-section {
	padding: 2rem 1.875rem;
	display: flex;
	flex-direction: row;
	column-gap: 1.5rem;
}

.title-section .title {
	font-weight: 450;
	font-size: 1.5rem;
	line-height: 1.37;
	color: #000000;
	margin-bottom: 0.625rem;
}

.title-section .sub-title {
	font-weight: normal;
	font-size: 1.25rem;
	line-height: 1.5;
	color: #727272;
}

.status-card {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 6px;
	padding: 1.125rem;
	cursor: pointer;
	font-size: 1rem;
	line-height: 1.37;
	color: #727272;
	position: relative;
	width: calc(50% - 0.25rem);
}

.status-card.checked::after {
	content: url("~@/assets/images/check-icon.svg");
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	border-radius: 50%;
}

.status-card.checked {
	border-color: var(--primary-color);
}

.status-card p {
	color: #000000;
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.pricing-plan-item .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	/* margin-right: 0.75rem; */
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.pricing-plan-item:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.right-menu-icon {
	width: 1.625rem;
	height: 1.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

.right-menu-icon img {
	max-height: 0.75rem;
}

.pricing-plan-list {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	row-gap: 1rem;
	flex-wrap: wrap;
	padding-bottom: 2rem;
}

.pricing-plan-item {
	width: calc(50% - 0.5rem);
	margin-bottom: 0;
}

.page-title {
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	margin: 0 0 2rem;
}

.pricing-plan-item.single .title-section {
	border-bottom: 0 !important;
}

/* .country-tags {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 4rem;
	width: 100%;
} */

.title-section .title-text {
	width: 80%;
	overflow: hidden;
}

/* .title-section .title-text .countries-block {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 30rem;
} */

.countries-text {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	transition: all 0.3s ease;
}

.countries-text.show-all {
	-webkit-line-clamp: unset;
}
</style>
