class PricingPlanType {
	static ONE_TIME = "OneTime";
	static MULTIPLE_PAYMENT_CYCLES = "MultiplePaymentCycles";
}

class PaymentStatus {
	static PENDING = "Pending";
	static COMPLETED = "Completed";
}

class ProductVariantPricingStatus {
	static PAID = "Paid";
	static FREE = "Free";
}
class InvoiceStatus {
	static UNPAID = "unpaid";
	static PAID = "paid";
	static PARTIALLY_PAID = "partially paid";
	static OVER_PAID = "over paid";
}

export {
	PricingPlanType,
	PaymentStatus,
	InvoiceStatus,
	ProductVariantPricingStatus,
};
