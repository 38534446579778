<template>
  <div>
    <b-form-group>
      <h5
        v-for="(option, index) in answerOptions"
        :key="index"
        :class="'d-flex align-items-center gap-3'"
      >
        <b-form-checkbox
          v-model="selectedAnswer"
          :value="index"
          :disabled="!option.option"
          style="flex-grow: 9"
        >
          <QuillRichTextEditor
            class="form-input answer-option"
            :text="option.option"
            :isInline="true"
            :editorID="'editor-answeroption-' + index"
            placeholder="Enter option here..."
            v-model="option.option"
          />
        </b-form-checkbox>

        <button
          @click="removeOption(index)"
          class=""
          role="button"
          style="flex-grow: 1"
        >
          <img src="@/assets/images/cancel-icon.svg" style="max-height: 13px" />
        </button>
      </h5>
    </b-form-group>
  </div>
</template>

<script>
import QuillRichTextEditor from "../../../Editors/QuillRichTextEditor.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "CheckboxButtonQuestion",
  data() {
    return {
      selectedAnswer: [],
    };
  },
  props: {
    answerOptions: Array,
  },
  components: {
    QuillRichTextEditor,
  },
  computed: {},
  mounted() {},
  methods: {
    removeOption(index) {
      if (this.answerOptions.length < 3) {
        this.$toasted.error("Atleast 2 options are required", {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          singleton: true,
        });
        return;
      }

      this.$emit("removeOpt", index);
    },
  },
  created() {
    var self = this;

    if (this.answerOptions.length > 0) {
      self.selectedAnswer = [];
      this.answerOptions.forEach(function (val, ind, arr) {
        if (val.isCorrect) {
          self.selectedAnswer.push(ind);
        }
      });
    }
  },
  watch: {
    selectedAnswer: function () {
      var objs = this.selectedAnswer.map((index) => {
        var obj = this.answerOptions[index];
        obj.index = index;
        return obj;
      });
      this.$emit("setAnswer", objs);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-option {
  margin-left: 10px;
  font-weight: lighter;
}
.radio-inputs {
  /* max-width: 100%; */
  padding: 5px;
  border-radius: 10px;
}
</style>

<style>
input[type="checkbox"] {
  margin-right: 5px;
}

label.custom-control-label {
  width: 95%;
}
</style>
