<template>
	<b-modal
		id="create-meeting-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="create-meeting-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="custom-stepper-container">
			<div class="custom-stepper">
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon first',
							{ active: currentStep == 1, complete: currentStep > 1 },
						]"
					>
						1
					</div>
					<div class="step-text">
						<h1 class="title">Basic information</h1>
						<h3 class="subtitle">Fill meeting information</h3>
					</div>
				</div>
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon',
							{ active: currentStep == 2, complete: currentStep > 2 },
						]"
					>
						2
					</div>
					<div class="step-text">
						<h1 class="title">Meeting Type</h1>
						<h3 class="subtitle">Select Meeting Type</h3>
					</div>
				</div>
			</div>
			<!-- Custom Stepper -->
		</div>
		<div class="body-container">
			<button
				@click="$emit('updateModalState', false)"
				class="close-button"
				role="button"
			>
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<form id="zoom-meeting-form">
				<div v-if="currentStep == 1" class="step1">
					<h1 class="add-content-title">
						{{ isEditMode ? "Edit" : "Create" }} Zoom Meeting
					</h1>
                    <div class="form-group">
						<label for="topic" class="main-label">topic (required)</label>
						<input
							type="search"
							name="topic"
							id="topic"
							:disabled="onlyReadable"
							v-model="topic"
							class="form-input"
							placeholder="Enter your zoom meeting topic..."
						/>
					</div>
					<!-- <button
						@click="toggleDesc"
						type="button"
						v-if="!showDesc"
						class="add-desc-btn"
					>
						<img src="@/assets/images/plus-icon-gray.svg" alt="" />
						Add Description
					</button>
					<div v-else class="form-group">
						<label for="agenda" class="main-label"
							>Description (optional)</label
						>
						<textarea
							type="textarea"
							name="agenda"
							id="agenda"
							v-model="agenda"
							class="form-input"
							placeholder="Enter Description"
							rows="1"
						/>
					</div> -->
					<div class="inline-group">
						<div class="col-lg-6 p-0">
							<label for="start_time" class="main-label"
								>Start Time (required)</label
							>
							<input
								type="datetime-local"
								name="start_time"
								id="start_time"
								v-model="start_time"
								class="form-input"
								:disabled="onlyReadable"
								placeholder="Meeting Date"
							/>
						</div>
						<div class="col-lg-6">
							<label for="durationHrs" class="main-label"
								>Duration (required)</label
							>
							<div class="d-flex flex-direction-row gap-2 align-items-center">
								<div class="col">
									<select
										type="number"
										name="durationHrs"
										id="durationHrs"
										v-model="durationHrs"
										class="form-input"
										placeholder="Meeting Duration"
										min="0"
										:disabled="onlyReadable"
										max="23"
									>
										<option v-for="num in 25" :value="num - 1" :key="num - 1">
											{{ num - 1 }}
										</option>
									</select>
								</div>
								<div class="col-1">hr</div>
								<div class="col">
									<select
										name="durationMin"
										id="durationMin"
										:disabled="onlyReadable"
										class="form-input"
										v-model="durationMin"
									>
										<option :value="0">0</option>
										<option :value="15">15</option>
										<option :value="30">30</option>
										<option :value="45">45</option>
									</select>
								</div>
								<div class="col-1">min</div>
							</div>
						</div>
					</div>

					<div class="form-check form-switch">
						<input
							class="form-check-input"
							type="checkbox"
							:disabled="onlyReadable"
							role="switch"
							id="isAvailableForDemo"
							v-model="isAvailableForDemo"
						/>
						<label class="form-check-label" for="isAvailableForDemo"
							>Available for Demo
						</label>
					</div>

					<div class="form-check form-switch">
						<input
							:disabled="isEditMode"
							class="form-check-input"
							type="checkbox"
							role="switch"
							id="isRecurring"
							v-model="isRecurring"
						/>
						<label
							class="form-check-label"
							for="isRecurring"
							:title="
								isEditMode
									? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
									: ''
							"
							v-b-tooltip.hover
							>Recurring Meeting</label
						>
					</div>
					<div v-if="isRecurring">
						<div class="inline-group">
							<div class="col">
								<label for="recurrence" class="main-label"
									>Recurrence (required)</label
								>
								<v-select
									:disabled="isEditMode"
									id="recurrence"
									class="form-input"
									v-model="recurrence.type"
									:reduce="(option) => option.value"
									:options="[
										{ label: 'Daily', value: 'daily' },
										{ label: 'Weekly', value: 'weekly' },
									]"
									placeholder="Select recurrence"
									:appendToBody="true"
									:calculate-position="withPopper"
									:clearable="false"
								>
									<!-- <option :value="1">Daily</option>
										<option :value="2">Weekly</option>
										<option :value="3">Monthly</option>
										<option value="no-fixed-time">No Fixed Time</option> -->
								</v-select>
							</div>
							<div class="col">
								<label for="repeatEvery" class="main-label"
									>Repeat Every (required)</label
								>
								<div class="d-flex gap-2 align-items-end">
									<div class="col-9">
										<v-select
											name="repeatEvery"
											id="repeatEvery"
											class="form-input"
											v-model="recurrence.repeat_interval"
											:options="repeatEveryOptions"
											:appendToBody="true"
											:calculate-position="withPopper"
											:clearable="false"
										>
											<!-- <option v-for="days in 15" :key="days" :value="days">{{
													days
												}}</option> -->
										</v-select>
									</div>
									<div class="col">
										{{ repeatEveryUnit }}
									</div>
								</div>
							</div>
						</div>
						<div v-if="recurrence.type == 'weekly'" class="form-group">
							<label for="" class="main-label">Occurs on (required)</label>
							<b-form-checkbox-group
								v-model="recurrence.weekly_days"
								:options="[
									{ item: 1, name: 'Sun' },
									{ item: 2, name: 'Mon' },
									{ item: 3, name: 'Tue' },
									{ item: 4, name: 'Wed' },
									{ item: 5, name: 'Thurs' },
									{ item: 6, name: 'Fri' },
									{ item: 7, name: 'Sat' },
								]"
								class="weekly-days-checkbox-grp"
								value-field="item"
								text-field="name"
							></b-form-checkbox-group>
						</div>
						<label for="recurrenceEndDateType" class="main-label"
							>End Date (required)</label
						>
						<div class="inline-group">
							<div class="col">
								<div class="d-flex gap-3 align-items-center">
									<input
										type="radio"
										name="recurrenceEndDateType"
										id="recurrenceEndBy"
										value="date"
										v-model="recurrenceEndDateType"
									/>

									<label
										for="recurrenceEndBy"
										class="col d-flex gap-2 align-items-center"
									>
										by
										<input
											type="date"
											class="form-input"
											id="recurrenceEndByValue"
											:disabled="recurrenceEndDateType != 'date'"
											v-model="recurrenceEndDateValue"
										/>
									</label>
								</div>
							</div>
							<div class="col">
								<div class="d-flex gap-2 align-items-center">
									<input
										type="radio"
										name="recurrenceEndDateType"
										id="recurrenceEndAfter"
										value="occurrences"
										v-model="recurrenceEndDateType"
									/>

									<label
										for="recurrenceEndAfter"
										class="col d-flex gap-2 align-items-center"
									>
										after
										<select
											class="form-input"
											id="recurrenceEndAfterValue"
											:disabled="recurrenceEndDateType != 'occurrences'"
											v-model="recurrenceCount"
										>
											<option v-for="days in 60" :key="days" :value="days">
												{{ days }}
											</option>
										</select>
										occurrences
									</label>
								</div>
							</div>
						</div>
						<p class="text-danger custom-alert-danger">Note: Max 60 occurrences are allowed for recurring meeting.</p>
			
					</div>
					<!-- <div class="inline-group">
						<div class="form-check form-switch">
							<input
								class="form-check-input"
								type="checkbox"
								role="switch"
								id="allowJbh"
								v-model="allowJbh"
							/>
							<label class="form-check-label" for="allowJbh"
								>Allow join before host</label
							>
						</div>
					</div> -->
				</div>
				<div v-if="currentStep == 2" class="step2">
					<div class="form-group">
						<label for="createWith" class="main-label"
							>Create Meeting using (required)</label
						>
						<v-select
							:disabled="isEditMode"
							v-model="schedule_for"
							name="createWith"
							id="createWith"
							class="form-input"
							:options="externalServicesList[ContentType.LIVE_CLASS]"
							:reduce="(option) => option.serviceCode"
							label="serviceName"
							:appendToBody="true"
							:calculate-position="withPopper"
							placeholder="Select a Zoom ID"
							:title="
								isEditMode
									? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
									: ''
							"
							v-b-tooltip.hover
							:clearable="false"
						>
						</v-select>
					</div>

					<div class="form-group">
						<label class="main-label" for="meeting">meeting type</label>
						<div class="status-cards">
							<div class="status-card-container col-5">
								<input
									type="radio"
									name="meetingType"
									:value="false"
									id="openForAll"
									v-model="meetingType"
									:disabled="isEditMode"
								/>
								<label
									class="status-card"
									for="openForAll"
									:title="
										isEditMode
											? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
											: ''
									"
									v-b-tooltip.hover
								>
									<p>Open for All</p>
									The meeting will be accessible to all.
								</label>
							</div>
							<div class="status-card-container col-5">
								<input
									type="radio"
									name="meetingType"
									:value="true"
									id="registrantsOnly"
									v-model="meetingType"
									:disabled="isEditMode"
								/>
								<label
									class="status-card"
									for="registrantsOnly"
									:title="
										isEditMode
											? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
											: ''
									"
									v-b-tooltip.hover
								>
									<p>Registrants Only</p>
									Only Registrants will be able to join.
									<span class="text-danger">( requires Paid Zoom ID )</span>
								</label>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="main-label" for="meeting">open on</label>
						<div class="status-cards w-100">
							<div class="status-card-container col-5">
								<input
									type="radio"
									name="openInSDK"
									:value="true"
									id="portal"
									v-model="openInSDK"
									:disabled="isEditMode"
									:title="
										isEditMode
											? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
											: ''
									"
								/>
								<label class="status-card" for="portal">
									<p>Portal</p>
									The meeting will open on the Portal.
								</label>
							</div>
							<div class="status-card-container col-5">
								<input
									type="radio"
									name="openInSDK"
									:value="false"
									id="zoomSoftware"
									v-model="openInSDK"
									:disabled="isEditMode"
									:title="
										isEditMode
											? 'This can not be changed. Please remove and recreate meeting if you want to change these fields.'
											: ''
									"
								/>
								<label class="status-card" for="zoomSoftware">
									<p>Zoom Software</p>
									The meeting will open on the Zoom Desktop Application.
								</label>
							</div>
						</div>
					</div>

					<div v-if="false" class="form-group">
						<div class="d-flex align-items-start">
							<label for="registrants" class="main-label"
								>Select registrants</label
							>
							<button @click="toggleSelectAll" type="button" class="select-all">
								{{ selectBtnText }}
							</button>
						</div>
						<div class="registrants-list">
							<div v-for="item in renderList" :key="item.id" class="registrant">
								<input
									type="checkbox"
									name="registrant"
									:id="`registrant${item.id}`"
									@change="checkboxToggle($event, item)"
									:checked="isChecked(item.id)"
								/>
								<label :for="`registrant${item.id}`"
									>{{ item.name }} ({{ item.email }})</label
								>
							</div>
						</div>
					</div>
					<div class="form-group mb-2">
						<div class="form-check form-switch">
							<input
								class="form-check-input"
								type="checkbox"
								role="switch"
								@change="changeSaveRecording($event)"
								:disabled="onlyReadable"
								id="saveRecording"
								v-model="saveRecording"
							/>
							<label class="form-check-label" for="saveRecording"
								>Automatically save meeting recording as lecture
								<span class="text-danger"
									>( requires Paid Zoom ID )</span
								></label
							>
						</div>
						<!-- <div class="form-check form-switch">
							<input
								class="form-check-input"
								type="checkbox"
								role="switch"
								id="openInSDK"
								v-model="openInSDK"
							/>
							<label class="form-check-label" for="openInSDK"
								>Open on Portal</label
							>
						</div> -->
					</div>

					<div v-if="false">
						<div class="inline-group">
							<div class="col-lg-6 p-0">
								<label for="recordingTitle" class="main-label">Title</label>
								<input
									type="search"
									name="recordingTitle"
									id="recordingTitle"
									v-model="recordingTitle"
									class="form-input"
									placeholder="Recording title"
								/>
							</div>
							<div class="col-lg-6">
								<label for="sectionAssigned" class="main-label">Section</label>
								<select
									type="search"
									name="sectionAssigned"
									id="sectionAssigned"
									v-model="sectionAssigned"
									class="form-input"
									placeholder="Assign to section"
								>
									<option value="null">Assign to section</option>
									<option value="english">English</option>
									<option value="math">Math</option>
								</select>
							</div>
						</div>
						<div class="inline-group">
							<div class="col-lg-6 p-0">
								<label for="recordingAvailableFrom" class="main-label"
									>Available From</label
								>
								<input
									type="date"
									name="recordingAvailableFrom"
									id="recordingAvailableFrom"
									v-model="recordingAvailableFrom"
									class="form-input"
									placeholder="Available From"
									
							:disabled="onlyReadable"
								/>
							</div>
							<div class="col-lg-6">
								<label for="recordingAvailableTo" class="main-label"
									>Available To</label
								>
								<input
									type="date"
									name="recordingAvailableTo"
									id="recordingAvailableTo"
									v-model="recordingAvailableTo"
									class="form-input"
									
							:disabled="onlyReadable"
									placeholder="Available To"
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
			<p class="text-danger custom-alert-danger" v-if="onlyReadable">Attention: The available date for this content has expired, therefore it can no longer be edited.</p>
					
			<div class="step-btn-container">
				
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
					><i class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext() || loading"
					:title="
										onlyReadable
											? 'The available date for this content has expired, therefore it can no longer be edited.'
											: ''
									"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }} <i class="fas fa-long-arrow-alt-right"></i>
					<!-- <img src="@/assets/images/arrow-right.svg" /> -->
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createPopper } from "@popperjs/core";
import { ContentType } from "../../../constants/content";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";

export default {
	name: "CreateZoomMeetingModal",
	props: {
		visible: Boolean,
		parentContentId: String,
		mode: String,
		meetingData: Object,
		contentData: Object,
	},
	data() {
		return {
			agenda: null,
			showDesc: true,
			currentStep: 1,
			totalSteps: 2,
			topic: null,
			type: "2",
			start_time: null,
			durationHrs: 1,
			durationMin: 0,
			isRecurring: false,
			allowJbh: false,
			schedule_for: null,
			meetingType: false,
			saveRecording: true,
			recurrenceEndDateType: "date",
			recurrenceEndDateValue: null,
			recurrenceCount: null,
			recurrence: {
				type: "daily",
				repeat_interval: 1,
			},
			openInSDK: true,
			isAvailableForDemo: false,
			ContentType,
		};
	},
	methods: {
		...mapActions("content", [
			"createLiveClassContent",
			"getContentById",
			"updateContent",
		]),
		toggleDesc() {
			this.showDesc = !this.showDesc;
		},
		next() {
			this.recurrenceStartTime(this.start_time);
			if (this.currentStep < this.totalSteps) {
				this.currentStep += 1;
			} else if (this.currentStep == this.totalSteps) {
				if (this.isEditMode) {
					var contentObject = {
						id: this.contentData.id,
						title: this.topic,
						availableFrom: new Date(this.start_time).toISOString(),
						availableTo: new Date(this.zoomMeetingEndDate()).toISOString(),
						isAvailableForDemo: this.isAvailableForDemo,
						parentDirectoryID: this.parentContentId,
						type: "LiveClass",
						data: {
							topic: this.topic,
							duration: (this.durationHrs * 60 + this.durationMin).toString(),
							isRecordingSave: this.saveRecording.toString(),
						},
					};

					this.updateContent(contentObject).then(() => {
						this.$toasted.success("Live Class updated successfully", {
							duration: 3000,
							theme: "outline",
							position: "bottom-center",
						});
						this.$emit("updateModalState", false);
						Object.assign(this.$data, this.$options.data.call(this));
					});
					return;
				}
				var liveClass = {
					title: this.topic,
					availableFrom: new Date(this.start_time).toISOString(),
					availableTo: new Date(this.zoomMeetingEndDate()).toISOString(),
					isAvailableForDemo: this.isAvailableForDemo,
					parentDirectoryID: this.parentContentId,
					type: "LiveClass",
					data: {
						servicecode: this.schedule_for,
						topic: this.topic,
						isrecurringmeeting: this.isRecurring.toString(),
						duration: (this.durationHrs * 60 + this.durationMin).toString(),
						joinbeforehost: this.allowJbh.toString(),
						openinsdk: this.openInSDK.toString(),
						registrants_confirmation_email: "false",
						registrants_email_notification: "false",
						isRecordingSave: this.saveRecording.toString(),
						registrationallow: this.meetingType.toString(),
					},
				};
				if (this.agenda) {
					liveClass.data.agenda = this.agenda;
				}
				if (this.isRecurring) {
					liveClass.data.recurrence = this.recurrence;
					// if (this.recurrenceEndDateType == "date") {
					liveClass.data.recurrence.ends_at = this.zoomMeetingEndDate();
					// } else
					// 	liveClass.data.recurrence.end_times = this.recurrenceEndDateValue.toString();
					liveClass.data.recurrence.start_time = this.recurrenceStartTime(
						this.start_time
					);
					liveClass.data.recurrence.duration = liveClass.data.duration;
					if (this.recurrence.type.toLowerCase() == "weekly") {
						liveClass.data.recurrence.weekly_days =
							liveClass.data.recurrence.weekly_days.toString();
					}
					liveClass.data.recurrence = JSON.stringify(liveClass.data.recurrence);
				}

				// console.log(liveClass);

				this.createLiveClassContent(liveClass).then(() => {
					this.$emit("updateModalState", false);
					Object.assign(this.$data, this.$options.data.call(this));
					// this.getContentById(this.parentContentId);
					this.$toasted.success("Zoom Meeting created successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
				// this.$emit("updateModalState", false);
			}
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep -= 1;
			}
		},
		isChecked(id) {
			console.log(id);
			var checked = false;
			this.registrantsList.map((item) => {
				if (item.id == id) {
					checked = true;
				}
			});
			return checked;
		},
		changeSaveRecording(e){
			if(!this.saveRecording && this.visible==true)
			{
				this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to disable automatically save recording as lecture?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.saveRecording=false;
					}else {
						this.saveRecording=true;
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
			}
		},
		checkboxToggle(e, registrant) {
			if (e.target.checked) {
				var found = false;
				this.registrantsList.map((item) => {
					if (item.id == registrant.id) {
						found = true;
					}
				});
				if (!found) {
					this.registrantsList.push(registrant);
				}
			} else {
				this.registrantsList = this.registrantsList.filter(
					(item) => item.id != registrant.id
				);
			}
		},
		toggleSelectAll() {
			if (this.registrantsList.length > 0 && this.renderList.length > 0) {
				if (this.renderList.length == this.registrantsList.length) {
					this.registrantsList = [];
				} else {
					this.registrantsList = this.renderList;
				}
			}
		},
		recurrenceStartTime(startDate) {
			var startTime = new Date(startDate).toTimeString();
			return startTime.substr(0, 8);
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		zoomMeetingEndDate() {
			if (!this.isRecurring) {
				var endDate = new Date(this.start_time);
				var durationInMinutes = this.durationHrs * 60 + this.durationMin;
				endDate = endDate.setMinutes(endDate.getMinutes() + durationInMinutes);
				return endDate;
			} else {
				var endDate = new Date(this.start_time);
				if (this.recurrenceEndDateType == "date") {
					endDate = new Date(new Date(this.recurrenceEndDateValue)).setHours(
						24,
						0,
						0,
						0
					);
				} else {
					var offset;
					switch (this.recurrence.type) {
						case "daily":
							if (this.recurrenceCount > 1) {
								offset = this.recurrenceCount * this.recurrence.repeat_interval;
							} else offset = 0;
							endDate = endDate.setDate(endDate.getDate() + offset - 1);
							break;
						case "weekly":
							var interval_period = 7; // 7 for weekly, 30 for monthly
							var occurrence_dates = [];

							while (this.recurrenceCount > occurrence_dates.length) {
								var day_no = parseInt(new Date(endDate).getDay() % 7);
								day_no++;
								if (this.recurrence.weekly_days.includes(day_no)) {
									occurrence_dates.push(endDate);
								}

								if (day_no == 7 && this.recurrence.repeat_interval > 1) {
									endDate = new Date(endDate).setDate(
										new Date(endDate).getDate() +
											(this.recurrence.repeat_interval - 1) * interval_period
									);
								}

								endDate = new Date(endDate).setDate(
									new Date(endDate).getDate() + 1
								);
							}
							endDate = new Date(new Date(endDate)).setHours(24, 0, 0, 0);
							break;
						// case "monthly":
						// 	if (this.recurrenceCount > 1) {
						// 		offset =
						// 			this.recurrenceCount * 30 * this.recurrence.repeat_interval;
						// 		console.log(offset);
						// 	} else offset = 0;
						// 	endDate = endDate.setDate(endDate.getDate() + offset - 1);
						// 	break;
					}
				}
				return new Date(endDate);
			}
		},
		
		disableNext() {
			
           if(this.isEditMode && this.currentStep == 2 && new Date(this.contentData.availableTo)<new Date() ){			
				return true;
			}
			if (this.currentStep == 1) {
				if (this.topic && this.start_time) {
					if (this.isRecurring) {
						console.log(
							this.recurrence &&
								this.recurrence?.type &&
								this.recurrence?.repeat_interval &&
								(this.recurrenceEndDateValue || this.recurrenceCount)
						);
						if (
							this.recurrence &&
							this.recurrence.type &&
							this.recurrence.repeat_interval &&
							(this.recurrenceEndDateValue || this.recurrenceCount)
						) {
							return false;
						}
						return true;
					}
					return false;
				} else return true;
			} else if (this.currentStep == 2) {
				if (this.isEditMode) return false;
				if (this.schedule_for) return false;
				return true;
				// if (this.saveRecording) {
				// 	if (
				// 		this.recordingTitle &&
				// 		this.recordingAvailableFrom &&
				// 		this.sectionAssigned
				// 	) {
				// 		return false;
				// 	} else return true;
				// }
				// if (this.meetingType.toLowerCase() == "registrantsonly") {
				// 	if (this.registrantsList.length == 0) {
				// 		return true;
				// 	} else return false;
				// }
			}
		},
	},
	computed: {
		...mapState("content", ["loading"]),
		...mapState("appState", ["externalServicesList"]),
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			}
			if (this.isEditMode) return "Update";
			return "Create";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		onlyReadable(){
			if(this.isEditMode && this.contentData.availableTo && new Date(this.contentData.availableTo)<new Date() ){			
				return true;
			}
		},	
		selectBtnText() {
			if (this.registrantsList.length > 0 && this.renderList.length > 0) {
				if (this.registrantsList.length == this.renderList.length) {
					return "Deselect All";
				} else {
					return "Select All";
				}
			}
		},
		repeatEveryOptions() {
			var options = [];
			if (this.recurrence.type == "daily") {
				for (var i = 1; i <= 15; i++) {
					options.push(i);
				}
			} else if (this.recurrence.type == "weekly") {
				for (var i = 1; i <= 12; i++) {
					options.push(i);
				}
			} else if (this.recurrence.type == "monthly") {
				for (var i = 1; i <= 3; i++) {
					options.push(i);
				}
			}
			return options;
		},
		repeatEveryUnit() {
			return this.recurrence.type == "daily"
				? "day"
				: this.recurrence.type == "weekly"
				? "week"
				: "month";
		},

		isEditMode() {
			return this.mode == "edit";
		},
	},
	watch: {
		"recurrence.type": function () {
			this.recurrence.repeat_interval = 1;
		},
		saveRecording: function(){
			
		},
		isRecurring: function () {
			if (!this.isRecurring) {
				this.recurrenceEndDateType = "date";
				this.recurrenceEndDateValue = null;
				this.recurrenceCount = null;
				this.recurrence = {
					type: "daily",
					repeat_interval: 1,
				};
			}
		},
		visible: function () {
			if (this.mode == "edit" && this.visible) {
				this.start_time = this.contentData.availableFrom
					? getInputDateFormat(new Date(this.contentData.availableFrom), true)
					: null;
				this.topic = this.contentData.title;
				if (this.meetingData.Duration) {
					var diff =
						new Date(this.contentData.availableTo) -
						new Date(this.contentData.availableFrom);
					var hours = Math.floor(diff / (1000 * 60 * 60));
					// console.log(hours);
					var mins = (diff - hours * (1000 * 60 * 60)) / (1000 * 60);
					// var hours = Math.floor(this.meetingData.Duration / 60);
					// var mins = (this.meetingData.Duration - hours * 60) * 60;
					this.durationHrs = hours;
					this.durationMin = mins;
				}
				this.openInSDK = this.meetingData.OpenInSDK;
				this.meetingType = this.meetingData.RegistrantAllow;
				this.isAvailableForDemo = this.contentData.isContentAvailableForDemo;
				this.schedule_for = this.contentData.externalService.serviceName;
				if (this.contentData.extraProperties.RecordingStatus) {
					this.isRecordingSave = this.contentData.extraProperties.RecordingStatus.toLowerCase() == "required";
					this.saveRecording = this.contentData.extraProperties.RecordingStatus.toLowerCase() == "required";
				}
			}
		},
	},
};
</script>

<style>
.create-meeting-modal {
	min-height: 45.375rem;
	width: 70vw;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.create-meeting-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.create-meeting-modal .modal-body .custom-stepper-container {
	background: #f5f5f5;
	border-radius: 8px 0px 0px 8px;
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.create-meeting-modal .modal-body .custom-stepper-container .custom-stepper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 7.5rem;
}

.create-meeting-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 60%;
}

.create-meeting-modal .add-content-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}

.create-meeting-modal .step1 {
	width: 100%;
	display: flex;
	flex-direction: column;
}

#zoom-meeting-form .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

#zoom-meeting-form .form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}
#zoom-meeting-form .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.form-input:disabled {
	background-color: #f5f5f5 !important;
	opacity: 0.5;
}

#zoom-meeting-form .form-input .form-control {
	border-radius: 0;
	box-shadow: none !important;
}

#zoom-meeting-form .form-check-input {
	outline: none !important;
}

.add-desc-btn {
	/* padding: 1.5rem 2.5rem; */
	margin: 0 0 2rem;
	column-gap: 0.5rem;
	color: #838383;
	display: flex;
	flex-direction: row;
	font-size: 1rem;
	align-items: center;
}

.inline-group {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: flex-start;
	margin-bottom: 2rem;
}

input#duration::before {
	content: "hrs";
	/* position: absolute; */
	display: flex;
	right: 0;
	top: 0;
}

.registrants-list {
	max-height: 9.375rem;
	overflow-y: auto;
}

.registrants-list .registrant {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75rem;
	padding: 0.75rem 0;
	font-size: 1rem;
	border-bottom: 1px solid #dcdcdc;
	color: #000000;
}

.registrants-list .registrant input,
.registrants-list .registrant label {
	margin: 0;
}

.registrants-list input[type="checkbox"],
.weekly-days-checkbox-grp input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}
.step2 .select-all {
	margin-left: auto;
	font-size: 1rem;
	color: var(--primary-color);
}

.weekly-days-checkbox-grp {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1.25rem;
	font-size: 1rem;
}

.weekly-days-checkbox-grp .custom-control {
	display: flex;
	align-items: center;
	flex-direction: row;
	column-gap: 0.5rem;
}

.weekly-days-checkbox-grp .custom-control input,
.weekly-days-checkbox-grp .custom-control label {
	margin: 0;
}

#zoom-meeting-form .status-card {
	padding: 0.875rem;
	max-width: unset;
}

#zoom-meeting-form input:disabled + .status-card {
	opacity: 0.5;
	cursor: not-allowed;
}
</style>
