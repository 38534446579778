<template>
	<div class="page-content-container">
		<div class="title-container mb-4">
			<h1 class="page-title">Invoices</h1>
		</div>
		<form @submit="filterInvoices" class="filters-container">
			<div class="d-flex flex-column flex-grow-1">
				<div class="buttons-container mb-3">
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="studentIDFilter"
							id="studentIDFilter"
							placeholder="Search by Student ID"
							v-model="studentIDFilter"
							class="search-input"
						/>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="studentNameFilter"
							id="studentNameFilter"
							placeholder="Search by Student Name"
							v-model="studentNameFilter"
							class="search-input"
						/>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="studentPhoneFilter"
							id="studentPhoneFilter"
							placeholder="Search by Student Number"
							v-model="studentPhoneFilter"
							class="search-input"
						/>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="email"
							name="studentEmailFilter"
							id="studentEmailFilter"
							placeholder="Search by Student Email"
							v-model="studentEmailFilter"
							class="search-input"
						/>
					</div>
				</div>
				<div class="buttons-container">
					<!-- <div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="invoiceIDFilter"
							id="invoiceIDFilter"
							placeholder="Search by Invoice ID"
							v-model="invoiceIDFilter"
							class="search-input"
						/>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="studentPhoneFilter"
							id="studentPhoneFilter"
							placeholder="Search by Student Number"
							v-model="studentPhoneFilter"
							class="search-input"
						/>
					</div> -->
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<input
							autocomplete="off"
							type="search"
							name="invoiceIDFilter"
							id="invoiceIDFilter"
							placeholder="Search by Invoice ID"
							v-model="invoiceIDFilter"
							class="search-input"
						/>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<v-select
							name="productIDFilter"
							id="productIDFilter"
							class="search-input"
							multiple
							v-model="productVariantIDFilter"
							:reduce="(option) => option.id"
							:options="productVariantList"
							placeholder="Search by Course"
							:appendToBody="true"
							:calculate-position="withPopper"
							label="name"
						>
							<template #selected-option="option">
								<div
									style="
										width: 10vw;
										text-overflow: ellipsis;
										white-space: nowrap;
										overflow: hidden;
									"
								>
									{{ option.product.title }} - {{ option.title }}
								</div>
							</template>
							<template v-slot:option="option">
								{{ option.product.title }} - {{ option.title }}
							</template>
						</v-select>
						<!-- <input
							autocomplete="off"
							type="email"
							name="productIDFilter"
							id="productIDFilter"
							placeholder="Search by Course ID"
							v-model="productVariantIDFilter"
							class="search-input"
						/> -->
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<date-range-picker
							ref="picker"
							:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
							:showWeekNumbers="true"
							:showDropdowns="true"
							:autoApply="true"
							v-model="paymentDueDateFilter"
							class="search-input"
							control-container-class="d-flex align-items-center gap-3"
							opens="left"
							:ranges="dateRangePresets"
							:maxDate="new Date()"
						>
							<template v-slot:input="picker" style="min-width: 350px">
								<i class="fal fa-calendar-alt text-muted"></i>
								<span class="text-sm" v-if="picker.startDate || picker.endDate">
									{{ formatDate(picker.startDate) }} -
									{{ formatDate(picker.endDate) }}
								</span>
								<span class="text-muted text-sm" v-else>
									Search by Due Date
								</span>
								<i
									@click="resetDueDateFilter"
									class="fa fa-times ms-auto text-muted cursor-pointer"
									v-if="picker.startDate || picker.endDate"
								></i>
								<i v-else class="fa fa-caret-down ms-auto text-muted"></i>
							</template>
						</date-range-picker>
					</div>
					<div
						class="searchbar col position-relative d-flex align-items-center"
					>
						<date-range-picker
							ref="picker"
							:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
							:showWeekNumbers="true"
							:showDropdowns="true"
							:autoApply="true"
							v-model="paymentDateFilter"
							class="search-input"
							control-container-class="d-flex align-items-center gap-3"
							opens="left"
							:ranges="dateRangePresets"
							:maxDate="new Date()"
						>
							<template v-slot:input="picker" style="min-width: 350px">
								<i class="fal fa-calendar-alt text-muted"></i>
								<span class="text-sm" v-if="picker.startDate || picker.endDate">
									{{ formatDate(picker.startDate) }} -
									{{ formatDate(picker.endDate) }}
								</span>
								<span class="text-muted text-sm" v-else>
									Search by Invoice Date
								</span>
								<i
									@click="resetDateFilter"
									class="fa fa-times ms-auto text-muted cursor-pointer"
									v-if="picker.startDate || picker.endDate"
								></i>
								<i v-else class="fa fa-caret-down ms-auto text-muted"></i>
							</template>
						</date-range-picker>
					</div>
				</div>
			</div>
			<button @click="filterInvoices" :disabled="loading" class="filter-btn">
				<i class="fa fa-search"></i>
				Search
			</button>
		</form>
		<div class="d-flex gap-5">
			<div>
				<p class="mb-2">Invoice Status</p>
				<div class="d-flex mb-4 gap-3">
					<button
						@click="setStatus(null)"
						:class="[
							'filter-payment-type-btn',
							{ active: invoiceStatusFilter == null },
						]"
					>
						All
					</button>
					<button
						@click="setStatus(InvoiceStatus.UNPAID)"
						:class="[
							'filter-payment-type-btn',
							{ active: invoiceStatusFilter == InvoiceStatus.UNPAID },
						]"
					>
						Unpaid
					</button>
					<button
						@click="setStatus(InvoiceStatus.PARTIALLY_PAID)"
						:class="[
							'filter-payment-type-btn',
							{ active: invoiceStatusFilter == InvoiceStatus.PARTIALLY_PAID },
						]"
					>
						Partially Paid
					</button>
					<button
						@click="setStatus(InvoiceStatus.PAID)"
						:class="[
							'filter-payment-type-btn',
							{ active: invoiceStatusFilter == InvoiceStatus.PAID },
						]"
					>
						Paid
					</button>
				</div>
			</div>
			<!-- <div>
				<p class="mb-2">Payment Method</p>
				<div class="d-flex mb-4 gap-3">
					<button
						@click="setPaymentMethod(null)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentMethodFilter == null },
						]"
					>
						All
					</button>

					<button
						v-for="method in externalServicesList['PaymentGateway']"
						:key="method.serviceCode"
						@click="setPaymentMethod(method.serviceProvider)"
						:class="[
							'filter-payment-type-btn',
							{ active: paymentMethodFilter == method.serviceProvider },
						]"
					>
						{{ method.serviceName }}
					</button>
				</div>
			</div> -->
		</div>
		<div class="table-container">
			<b-overlay :show="loading" rounded="sm">
				<table class="table table-hover table-borderless payments-table">
					<thead>
						<th>
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll"
							/>
						</th>
						<th>Invoice ID</th>
						<th>Student Name</th>
						<th>Amount</th>
						<th>Invoice Status</th>
						<th>Invoice Date</th>
					</thead>
					<tbody v-if="allInvoices && allInvoices.length > 0">
						<tr
							class="cursor-pointer"
							@click="toggleInvoiceModal(invoice)"
							v-for="invoice in filteredInvoices"
							:key="invoice.id"
						>
							<td>
								<input
									@click="toggleInvoiceSelect($event, invoice)"
									type="checkbox"
									name="select_1"
									:id="invoice.id"
									:checked="isChecked(invoice.id)"
								/>
							</td>
							<td>{{ invoice.id }}</td>
							<td>{{ userName(invoice.customerID) }}</td>
							<td>
								{{
									formatCurrency(totalAmount(invoice.details), invoice.currency)
								}}
							</td>
							<td>
								<div
									:class="[
										'status-tag',
										{
											success: invoice.status == InvoiceStatus.PAID,
											danger:
												invoice.status == InvoiceStatus.UNPAID ||
												invoice.status == InvoiceStatus.OVER_PAID,
											warning: invoice.status == InvoiceStatus.PARTIALLY_PAID,
										},
									]"
								>
									{{ invoice.status }}
								</div>
							</td>
							<td>{{ formatDate(invoice.invoiceDate) }}</td>
							<UserInvoiceDetailModal
								v-if="
									invoice &&
									invoiceToViewPay &&
									invoiceToViewPay.id == invoice.id
								"
								:invoice="invoice"
								:showModal="showInvoiceModal"
								@updateModalState="toggleInvoiceModal"
								:user="userData(invoice.customerID)"
								invoiceMode="view"
							/>
						</tr>
					</tbody>
				</table>
			</b-overlay>
			<div
				v-if="!allInvoices && loading"
				class="d-flex align-items-center justify-content-center gap-3 p-3 font-500"
			>
				<b-spinner></b-spinner> Loading...
			</div>
			<h5
				v-else-if="allInvoices && filteredInvoices.length == 0 && !loading"
				class="text-center text-muted p-4 pt-0 m-0"
			>
				No payments found
			</h5>
		</div>
		<b-pagination
			v-if="filteredInvoices.length > 0"
			align="right"
			v-model="currentPage"
			:total-rows="rows"
			:per-page="perPage"
		></b-pagination>
	</div>
</template>

<script>
import { PaymentStatus, InvoiceStatus } from "../../constants/pricing";
import { mapActions, mapState } from "vuex";
import {
	toShortDateString,
	toCurrencyString,
} from "../../helpers/FormatHelpers";
import UserInvoiceDetailModal from "../../components/Admin/Invoice/UserInvoiceDetailModal.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { createPopper } from "@popperjs/core";

export default {
	name: "InvoiceManagement",
	data() {
		return {
			invoiceStatusFilter: null,
			paymentMethodFilter: null,
			searchText: "",
			selectedPayments: [],
			perPage: 10,
			currentPage: 1,
			productVariantIDFilter: null,
			paymentIDFilter: null,
			invoiceIDFilter: null,
			studentIDFilter: null,
			studentNameFilter: null,
			studentEmailFilter: null,
			studentPhoneFilter: null,
			paymentDateFilter: {
				startDate: null,
				endDate: null,
			},
			paymentDueDateFilter: {
				startDate: null,
				endDate: null,
			},
			invoiceToViewPay: null,
			showInvoiceModal: false,
			rows: 0,
			PaymentStatus,
			InvoiceStatus,
		};
	},
	computed: {
		...mapState("pricing", ["allInvoices", "loading"]),
		...mapState("user", ["allUsers"]),
		...mapState("appState", ["externalServicesList"]),
		...mapState("productVariant", ["productVariants"]),
		filteredInvoices() {
			var filtered = this.allInvoices;
			return filtered;
		},
		isSelectedAll() {
			if (this.allInvoices) {
				return this.allInvoices.length == this.selectedPayments.length;
			}
		},
		offset() {
			return (this.currentPage - 1) * this.perPage;
		},
		dateRangePresets() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date();
			yesterday.setDate(today.getDate() - 1);
			yesterday.setHours(0, 0, 0, 0);
			let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
			let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
			return {
				Today: [today, today],
				Yesterday: [yesterday, yesterday],
				"This month": [thisMonthStart, thisMonthEnd],
				"Last month": [
					new Date(today.getFullYear(), today.getMonth() - 1, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"Last 3 months": [
					new Date(today.getFullYear(), today.getMonth() - 3, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"Last 6 months": [
					new Date(today.getFullYear(), today.getMonth() - 6, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				"This year": [
					new Date(today.getFullYear(), 0, 1),
					new Date(today.getFullYear(), 11, 31),
				],
				"Last year": [
					new Date(today.getFullYear() - 1, 0, 1),
					new Date(today.getFullYear() - 1, 11, 31),
				],
			};
		},
		productVariantList() {
			var list = Object.values(this.productVariants.items).map(x=>{  x.name=x.product.title+" - "+x.title; return x; });
			list.sort(function (a, b) {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			return list;
		},
	},
	components: { UserInvoiceDetailModal, DateRangePicker },
	methods: {
		...mapActions("user", ["fetchUsersList"]),
		...mapActions("appState", ["fetchExternalServices"]),
		...mapActions("pricing", ["fetchInvoicesList"]),
		formatCurrency(amount, currency) {
			return toCurrencyString(amount, currency, 0);
		},
		setStatus(status) {
			this.invoiceStatusFilter = status;
		},
		setPaymentMethod(method) {
			this.paymentMethodFilter = method;
		},
		toggleSelectAll() {
			if (
				this.allInvoices &&
				this.allInvoices.length > 0 &&
				this.selectedPayments.length != this.allInvoices.length
			) {
				this.selectedPayments = this.allInvoices;
			} else {
				this.selectedPayments = [];
			}
		},
		toggleInvoiceSelect(e, payment) {
			e.stopPropagation();
			var found = this.selectedPayments.find((s) => s.id == payment.id);
			console.log(found);
			if (!found) {
				this.selectedPayments.push(payment);
			} else {
				this.selectedPayments = this.selectedPayments.filter(
					(su) => su.id != payment.id
				);
			}
		},
		isChecked(id) {
			var found = this.selectedPayments.find((payment) => payment.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		userName(id) {
			// console.log(id);
			if (this.allUsers && this.allUsers.length > 0) {
				var found = this.allUsers.find((user) => user.id == id);
				console.log(found);
				if (found) {
					return found.firstName + " " + found.lastName;
				}
			}
		},
		userData(id) {
			if (this.allUsers && this.allUsers.length > 0) {
				var found = this.allUsers.find((user) => user.id == id);
				console.log(found);
				if (found) {
					return found;
				}
			}
		},
		totalAmount(details) {
			var total = 0;
			details.forEach((d) => {
				total += d.amount;
			});
			return total;
		},
		formatDate(date) {
			return toShortDateString(date);
		},
		filterInvoices(e) {
			if (e) e.preventDefault();
			debugger;
			this.fetchInvoicesList({
				InvoiceID: this.invoiceIDFilter,
				// ID: this.paymentIDFilter,
				CustomerName: this.studentNameFilter,
				CustomerEmail: this.studentEmailFilter,
				CustomerPhone: this.studentPhoneFilter,
				Status: this.invoiceStatusFilter,
				CustomerSourceID: this.studentIDFilter,
				ProductSourceIDs: this.productVariantIDFilter,
				// MethodProvider: this.paymentMethodFilter,
				StartDate: this.paymentDateFilter.startDate
					? new Date(this.paymentDateFilter.startDate).toISOString()
					: null,
				EndDate: this.paymentDateFilter.endDate
					? new Date(this.paymentDateFilter.endDate).toISOString()
					: null,
				StartDueDate: this.paymentDueDateFilter.startDate
					? new Date(this.paymentDueDateFilter.startDate).toISOString()
					: null,
				EndDueDate: this.paymentDueDateFilter.endDate
					? new Date(this.paymentDueDateFilter.endDate).toISOString()
					: null,
				pageSize: this.perPage,
				currentpage: this.currentPage,
			}).then((res) => {
				this.rows = res.totalRecords;
			});
		},
		toggleInvoiceModal(payment = null) {
			if (payment) {
				this.invoiceToViewPay = payment;
				setTimeout(() => {
					this.showInvoiceModal = !this.showInvoiceModal;
				}, 300);
			} else {
				this.showInvoiceModal = !this.showInvoiceModal;
			}
		},
		resetDateFilter(e) {
			e.stopPropagation();
			this.paymentDateFilter = {
				startDate: null,
				endDate: null,
			};
		},
		resetDueDateFilter(e) {
			e.stopPropagation();
			this.paymentDueDateFilter = {
				startDate: null,
				endDate: null,
			};
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = "fit-content";
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
	},
	created() {
		this.fetchUsersList();
		this.fetchExternalServices("PaymentGateway");
		this.fetchInvoicesList({
			InvoiceID: this.invoiceIDFilter,
			// ID: this.paymentIDFilter,
			CustomerName: this.studentNameFilter,
			CustomerEmail: this.studentEmailFilter,
			CustomerPhone: this.studentPhoneFilter,
			Status: this.invoiceStatusFilter,
			CustomerSourceID: this.studentIDFilter,
			ProductSourceID: this.productVariantIDFilter,
			// MethodProvider: this.paymentMethodFilter,
			StartDate: this.paymentDateFilter.startDate
				? new Date(this.paymentDateFilter.startDate).toISOString()
				: null,
			EndDate: this.paymentDateFilter.endDate
				? new Date(this.paymentDateFilter.endDate).toISOString()
				: null,
			StartDueDate: this.paymentDueDateFilter.startDate
				? new Date(this.paymentDueDateFilter.startDate).toISOString()
				: null,
			EndDueDate: this.paymentDueDateFilter.endDate
				? new Date(this.paymentDueDateFilter.endDate).toISOString()
				: null,
			pageSize: this.perPage,
			currentpage: this.currentPage,
		}).then((res) => {
			console.log(res);
			this.rows = res.totalRecords;
		});
	},
	watch: {
		invoiceStatusFilter: function () {
			this.currentPage = 1;
			this.filterInvoices();
		},
		paymentMethodFilter: function () {
			this.currentPage = 1;
			this.filterInvoices();
		},
		showInvoiceModal: function () {
			if (this.showInvoiceModal == false) {
				this.filterInvoices();
			}
		},
		currentPage: function () {
			this.filterInvoices();
		},
	},
};
</script>

<style scoped>
.page-content-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 1.875rem;
	overflow-y: auto;
}

.title-container {
	display: flex;
	align-items: center;
}

.page-title {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	/* margin: 0; */
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.filters-container {
	padding: 1.5rem;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	background-color: #ffffff;
	margin-bottom: 1.5rem;
	display: flex;
	align-items: flex-end;
	column-gap: 1rem;
}
.filters-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.filter-inputs {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
}

.search-input {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	/* margin-bottom: 1rem; */
	/* font-weight: 500; */
	color: #000000;
	font-size: 1rem;
	column-gap: 0.75rem;
	position: relative;
	outline: none;

	/* transition: transform 0.3s; */
}

.search-button {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 2rem;
	filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
	text-decoration: none;
	color: #000000;
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
}

.buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 1rem;
}

.buttons-container .searchbar {
	flex-grow: 1;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 1rem;
	right: 0;
	color: #000000;
}

.filter-payment-type-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	border: 1px solid transparent;
	transition: all 0.3s ease;
}

.filter-payment-type-btn.active {
	border: 1px solid var(--primary-color);
}

.table-container {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
}

.payments-table thead,
.payments-table td {
	height: 4rem;
	vertical-align: middle;
}

.payments-table td:first-of-type {
	padding-left: 0;
}

.payments-table input[type="checkbox"] {
	margin-left: 1.5rem;
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.filter-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 2rem;
	text-decoration: none;
	color: #000000;
	display: flex;
	-moz-column-gap: 0.5rem;
	column-gap: 0.5rem;
	align-items: center;
	transition: all 0.3s ease;
}

.filter-btn:hover {
	box-shadow: (3px 5px 6px rgba(197, 197, 197, 0.3));
}
</style>
