<template>
	<router-view :instructions="instructions" :paymentMode="selectedGateway">
	</router-view>
	<!-- <component :is="paymentOption" :instructions="instructions" :paymentMode="selectedGateway"></component> -->
</template>

<script>
import { mapActions, mapState } from "vuex";
import Cash from "../components/Payment/Cash.vue";
import BankTransfer from "../components/Payment/BankTransfer.vue";
export default {
	name: "Payment",
	data() {
		return {
			instructions: null,
			selectedGateway: null,
		};
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		paymentOption() {
			console.log(this.$route.name);
			return this.$route.name;
		},
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
	},
	created() {
		if (!this.externalServicesList.PaymentGateway) {
			this.fetchExternalServices("PaymentGateway").then(() => {
				var paymentGateway = this.externalServicesList.PaymentGateway.find(
					(gateway) => gateway.serviceProvider == this.paymentOption
				);
				console.log(
					'{ "IconSrc": "/assets/images/ibft_logo.png", "Instructions": "To pay through IBFT/Bank Transfer, transfer amount in following Bank Account<br><br>Bank: Dubai Islamic Bank<br> Account Title: Saylani Welfare International Trust (Branch Code: 061)<br> Account No: 0011036003<br><br>Upload snapshot of transaction as evidence, SBIL Team will review and approve your payment then you will be able to access your enrolled courses." }'
				);
				this.instructions = JSON.parse(paymentGateway.serviceData).Instructions;
				this.selectedGateway = paymentGateway;
			});
		} else {
			var paymentGateway = this.externalServicesList.PaymentGateway.find(
				(gateway) => gateway.serviceProvider == this.paymentOption
			);
			console.log(
				'{ "IconSrc": "/assets/images/ibft_logo.png", "Instructions": "To pay through IBFT/Bank Transfer, transfer amount in following Bank Account<br><br>Bank: Dubai Islamic Bank<br> Account Title: Saylani Welfare International Trust (Branch Code: 061)<br> Account No: 0011036003<br><br>Upload snapshot of transaction as evidence, SBIL Team will review and approve your payment then you will be able to access your enrolled courses." }'
			);
			this.instructions = JSON.parse(paymentGateway.serviceData).Instructions;
			this.selectedGateway = paymentGateway;
		}
	},
	components: {
		Cash,
		BankTransfer,
	},
};
</script>

<style scoped>
</style>
