<template>
	<b-modal id="exam-action-modal" @change="$emit('updateModalState', false)" :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')"  :visible="visible" hide-header centered
		content-class="exam-action-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">
			<div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
				<button @click="$emit('updateModalState', false)" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
				</button>
			</div>
			
		</div>
		<div >
			<div v-if="this.iframeURL">
				<center>
					<h6 class="load-head"><b-spinner ></b-spinner> Loading...</h6>
				</center>
				<iframe :src="this.iframeURL" class="modal-iframe"> -->
			
				</iframe>
			</div>
			<div v-if="!this.iframeURL"  class="modal-iframe">
				<!-- <Loading /> -->
			</div>

		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LegacyExam } from "../../../constants/exam";
import Loading from "../../Layout/Loading.vue";

export default {
	name: "ExamActionModal",
	props: {
		visible: Boolean,
		item:Object,
		action:String
	},
	data() {
		return {
			iframeURL:null,
			isExpanded:false
		};
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		handleLegacyExamActions(action) {
			this.iframeURL=null;
			this.getContentById({
				id: this.item.id,
				extraParams: { action: action },
			}).then((content) => {
				if (content ) {
					var examActionUrl =
						content.data.redirectUrl;

            if(this.isAppleDevice()) {
              window.open(
                  content.data.redirectUrl,
                  "_blank"
              );

              this.visible = false;
            } else {
              this.iframeURL=examActionUrl;
            }

					return;
				}
			});
		},
    isAppleDevice() {
      const userAgent = navigator.userAgent;

      return /Mac|Macintosh|iPhone|iPad/.test(userAgent);
    }

	},
	components: {
		Loading,
		LegacyExam
	},
	created(){
	},
	computed: {	
	},
	watch: {
		visible: function () {
			if (this.visible==true) {
					this.handleLegacyExamActions(this.action);
			}
		},
	},
};
</script>

<style scoped>
	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}
</style>
<style>
.exam-action-modal {
	min-height: 45.375rem;
	width: 90vw;
	position: relative;
}


#exam-action-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#exam-action-modal .expanded .exam-action-modal
{
	height: 90vh;
	
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */
.load-head{
	position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
	font-size: 24px;
}

.exam-action-modal .modal-body {
	padding: 0 !important;
	font-size: 1rem;
	min-height: 43rem;
}

.exam-action-modal .modal-body .modal-iframe {
	width: 100%;
	position:absolute;
	height: 100%;
}


.exam-action-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
    position: absolute;
    z-index: 999999;
    right: 0;

}


.exam-action-modal .body-container .header {
	display: flex;
	justify-content: space-between;
	margin-right: 30px;

}

.exam-action-modal .exam-action-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}
</style>
