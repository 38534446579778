<template>
	<tr class="d-flex">
		<td v-if="mode == 'pay'" :class="['col-1', { shrink: mode == 'pay' }]">
			<input
				type="checkbox"
				name="select_1"
				:id="paymentDetail.id"
				@click="togglePaymentSelect"
				:checked="isChecked(paymentDetail.id)"
			/>
		</td>
		<td class="col-1">{{ paymentDetail.id }}</td>
		<td class="col">
			{{ paymentDetail.productName ? paymentDetail.productName : "-" }}
		</td>
		<td class="col">
			{{ paymentDetail.planName }}
			{{
				paymentDetail.planDetailTitle != paymentDetail.planName
					? " (" + paymentDetail.planDetailTitle + ")"
					: ""
			}}
		</td>
		<td class="col">
			<input
				v-if="mode == 'edit' || mode == 'pay'"
				placeholder="Enter amount"
				type="number"
				id="paymentAmount"
				:class="['form-input', { 'text-danger': isError }]"
				:disabled="isDisabled(paymentDetail.id)"
				v-model="paymentAmount"
				@change="validateAmount"
			/>
			<span v-else>
				{{ currencyParser(paymentDetail.amount, paymentDetail.currency) }}
			</span>
		</td>
		<td class="col" v-if="mode != 'pay'">
			<!-- <v-select
				v-if="mode == 'edit'"
				class="form-input"
				placeholder="Select payment type"
				v-model="paymentType"
				name="paymentType"
				id="paymentType"
				:options="paymentTypes"
				:autoscroll="true"
				:disabled="isDisabled(paymentDetail.id)"
				:reduce="(option) => option.value"
			></v-select> -->
			<div
				:class="[
					'status-tag',
					{
						success: paymentDetail.type.toLowerCase() == 'received',
						danger: paymentDetail.type.toLowerCase() == 'refunded',
					},
				]"
			>
				{{ paymentDetail.type }}
			</div>
		</td>
	</tr>
</template>

<script>
import formatter from "../../../helpers/FormatHelpers";
import { mapActions, mapState } from "vuex";
export default {
	name: "PaymentDetailItem",
	data() {
		return {
			paymentAmount: null,
			paymentType: null,
			paymentTypes: [
				{ label: "Received", value: "Received" },
				{ label: "Refunded", value: "Refunded" },
			],
		};
	},
	props: {
		paymentDetail: Object,
		currency: String,
		selectedDetails: Array,
		mode: String,
	},
	computed: {
		...mapState("pricing", ["paymentsByUserId"]),
		...mapState("appState", ["currentCurrency"]),
		isError() {
			// return this.outstandingAmount == "Invalid amount";
		},
	},
	methods: {
		...mapActions("pricing", ["fetchPaymentsByUserId"]),
		currencyParser(amount, currency) {
			return formatter.toCurrencyString(parseInt(amount), currency, 0);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		isDisabled(id) {
			var found = this.selectedDetails.find((d) => d.id == id);
			return !found;
		},
		handleNumberInput(e) {
			if (e.keyCode == 189) {
				console.log("- pressed", this.paymentAmount);
				this.paymentAmount = "";
			}
		},
		togglePaymentSelect() {
			this.$emit("toggleSelect", this.paymentDetail);
		},
		validateAmount(e) {
			if (e.target.value < 0) {
				this.$emit("updateError", true, "Enter valid payment amount");
			} else {
				this.$emit("updateError", false, "");
			}
		},
		isChecked(id) {
			var found = this.selectedDetails.find((payment) => payment.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
	},
	created() {
		if (this.paymentDetail.amount) {
			this.paymentAmount = this.paymentDetail.amount;
		}
		if (this.paymentDetail.type) {
			this.paymentType = this.paymentDetail.type;
		}
	},
	watch: {
		paymentAmount: function () {
			this.$emit("updateAmount", this.paymentAmount, this.paymentDetail.id);
		},
		isError: function () {
			this.$emit("updateError", this.isError);
		},
		paymentType: function () {
			this.$emit("updateType", this.paymentType, this.paymentDetail.id);
		},
		mode: function () {
			if (this.mode == "edit") {
				this.togglePaymentSelect();
			}
		},
	},
};
</script>

<style></style>
