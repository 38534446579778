<template>
  <div id="login-register">
    <h1 class="heading-bold-20">
      {{ getFormattedCredential(credentialType) }} Verification
    </h1>
    <div class="width-100">
      <form @submit.prevent="handleVerification()">
        <div v-if="credentials && verificationId">
          <i class="input-icon-left far fa-key"></i>
          <input
            class="form-control input-w-icon"
            type="text"
            name="OTPCode"
            id="OTPCode"
            maxlength="6"
            :placeholder="'Enter the OTP sent to your ' + credentialType"
            v-model="OTPCode"
            :disabled="timer <= 0"
            required
          />
        </div>

        <button
          class="secondary-btn btn-bold form-control mt-4"
          type="submit"
          :disabled="!OTPCode"
        >
          Verify
        </button>

        <button
          :disabled="loading || timer > 0"
          class="login-register-btn primary-btn form-control mt-4"
          type="button"
          @click.prevent="handleResendOtp"
        >
          <b-spinner v-if="loading" small></b-spinner>
          Resend OTP
        </button>
      </form>
      <div class="d-flex gap-3">
        <span class="timer">{{
          formattedMinutes + ":" + formattedSeconds
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
const getCountryISO3 = require("country-iso-2-to-3");

export default {
  name: "UserVerification",
  data() {
    return {
      OTPCode: null,
      timer: 5 * 60,
      credentials: null,
      verificationId: null,
      credentialType: null,
      formattedMinutes: "",
      formattedSeconds: "",
    };
  },
  computed: {
    ...mapState("user", ["loading"]),
  },
  methods: {
    ...mapActions("user", ["generateVerificationID", "verifyUser"]),

    handleResendOtp() {
      if (this.credentials) {
        var object;
        if (this.credentialType == "email") {
          object = {
            email: this.credentials,
          };
        }

        if (this.credentialType == "phone") {
          object = {
            phone: this.credentials,
          };
        }

        if (object) {
          if (object.phone) {
            object.phone = object.phone.trim();
          }
          if (object.email) {
            object.email = object.email.trim();
          }

          this.generateVerificationID(object)
            .then((res) => {
              if (res.verificationID) {
                this.verificationId = res.verificationID;
                this.timer = 5 * 60;
                this.handleTimer();
              } else {
                this.handleErrorMessage(
                  "Some error occurred while generating OTP"
                );
              }
            })
            .catch((error) => {
              this.handleErrorMessage("Some Error occurred");
            });
        }
      }
    },
    handleTimer() {
      if (this.timer > 0) {
        var minutes = Math.floor(this.timer / 60);
        var remainingSeconds = this.timer % 60;

        this.formattedMinutes = minutes.toString().padStart(2, "0");
        this.formattedSeconds = remainingSeconds.toString().padStart(2, "0");
      }
    },
    handleErrorMessage(errorMessage) {
      this.$toasted.error(errorMessage, {
        icon: "exclamation-triangle",
        duration: 5000,
        theme: "outline",
        position: "bottom-center",
      });
    },
    handleVerification() {
      if (this.verificationId && this.OTPCode) {
        this.verifyUser({
          verificationID: this.verificationId,
          verificationToken: this.OTPCode,
        })
          .then((res) => {
            if (res && res.status && res.status.toLowerCase() == "success") {
              this.$router.push("/account/login?verificationSuccessful=true");
              return;
            } else if (res && res.errorCode == "140") {
              this.handleErrorMessage("Invalid OTP");
              return;
            }
            this.timer = 0;
            this.formattedMinutes = "";
            this.formattedSeconds = "";
            this.handleErrorMessage("Some error occurred");
          })
          .catch((error) => {
            this.timer = 0;
            this.formattedMinutes = "";
            this.formattedSeconds = "";
            this.handleErrorMessage("Some error occurred");
          });
      }
    },
    getFormattedCredential(credentialType) {
      return credentialType == "phone" ? "Phone" : "Email";
    },
  },
  created() {
    this.credentials = this.$route.query.credentials;
    this.credentialType = this.$route.query.type;
    this.verificationId = this.$route.query.verificationId;

    this.counter = setInterval(() => {
      this.handleTimer();
      this.timer -= 1;
    }, 1000);
  },
  // watch: {
  //   timer: function () {
  //     if (this.timer == 0) {
  //       clearInterval(this.counter);
  //     }
  //   },
  // },
  watch: {
    $route(to, from) {
      clearInterval(this.counter);
    },
  },
};
</script>

<style scoped>
.timer {
  color: #aaa;
}

.input-icon-right {
  top: 40%;
  transform: translateY(-50%);
}
</style>
