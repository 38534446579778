class ContentType {
	static DIRECTORY = "Directory";
	static RECORDED_VIDEOS = "RecordedVideos";
	static LIVE_CLASS = "LiveClass";
	static STUDY_MATERIAL = "StudyMaterial";
	static STUDY_MATERIAL_SERVICE = "Study Material";
	static EXAM = "Exam";
	static ASSIGNMENT = "Assignment";
	static QUIZ = "Quiz";
	static FREETEXT = "FreeText";
	static FLASHCARDS = "Flashcards";
}

class RecordedVideoStatus {
	static NOT_UPLOADED = "NotUploaded";
	static PROCESSING = "Processing";
	static PROCESSED = "Processed";
}

const UpcomingContentType = {
	CURRENT: "Current",
	UPCOMING: "Upcoming",
	PAST: "Past",
};

export { ContentType, RecordedVideoStatus, UpcomingContentType };
