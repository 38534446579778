<template>
	<div class="categories-column">
		<div class="search-container">
			<div class="position-relative w-100">
				<input
					type="search"
					class="searchbar"
					placeholder="Search question pool"
					v-model="searchText"
				/>
				<a role="button"
					><img class="search-icon" src="@/assets/images/search-icon.svg"
				/></a>
			</div>
			<a
				v-if="!selectMode"
				data-bs-toggle="dropdown"
				id="options-dropdown-btn"
				class="category-options dropdown-toggle"
				role="button"
				><img src="@/assets/images/options-icon.svg" />
			</a>
			<ul
				id="options-dropdown"
				class="dropdown-menu dropdown-menu-end"
				aria-labelledby="dropdownMenuLink"
			>
				<li @click="toggleCreate(true)" class="options-dropdown-item">
					<img src="@/assets/images/plus-icon.svg" /> Create Pool
				</li>
			</ul>
			<!-- <button>
				<img src="@/assets/images/options-icon.svg" />
			</button> -->
		</div>
		<QuestionCategoryList
			@selectCategory="selectCategory"
			:selectedCategory="selectedCategory"
			:createMode="createMode"
			@cancelCreate="toggleCreate(false)"
			:productVariantId="productVariantId"
			:searchText="searchText"
			:selectMode="selectMode"
		/>
	</div>
</template>

<script>
import QuestionCategoryList from "../Question/QuestionCategoryList.vue";

export default {
	name: "CategoriesColumn",
	data() {
		return {
			createMode: false,
			searchText: "",
		};
	},
	components: {
		QuestionCategoryList,
	},
	methods: {
		selectCategory(category) {
			this.$emit("selectCategory", category);
		},
		toggleCreate(value) {
			if (value !== undefined) {
				// this.createMode = value;
				if (value) {
					this.createMode = value;
					setTimeout(() => $("input#categoryName").focus(), 100);
				} else {
					setTimeout(() => (this.createMode = value), 300);
				}
			} else {
				this.createMode = !this.createMode;
				if (this.createMode)
					setTimeout(() => $("input#categoryName").focus(), 100);
			}
		},
	},
	props: {
		selectedCategory: Array,
		productVariantId: String,
		selectMode: { type: Boolean, default: false },
	},
};
</script>

<style scoped>
.categories-column {
	width: 25%;
	display: flex;
	border-right: 1px solid #ddd;
	flex-direction: column;
}

.categories-column .search-container {
	padding: 1rem;
	background-color: #f9f9f9;
	/* border-bottom: 1px solid #ddd; */
	border-radius: 0.5rem 0 0 0;
	display: flex;
	align-items: center;
	column-gap: 1rem;
	height: 4.625rem;
}

.search-container .searchbar {
	border: 1px solid #ddd;
	padding: 0.5rem 0.75rem;
	width: 100%;
	border-radius: 0.5rem;
}

.search-icon {
	position: absolute;
	margin-right: 1rem;
	right: 0;
	width: 1.375rem;
	top: 50%;
	transform: translateY(-50%);
}

.category-options {
	width: 1.875rem;
	height: 1.875rem;
	background: #ebebeb;
	border-radius: 50%;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-left: auto;
}

.category-options::after {
	display: none !important;
}

.options-dropdown-item {
	padding: 0.75rem 1rem;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #000000;
	column-gap: 0.75rem;
}

.options-dropdown-item:hover {
	background-color: #ebebeb;
}
</style>
