export default function XLSXtoJSON(file) {
	let reader = new FileReader();

	reader.onload = function (event) {
		console.log(event);
		var data = event.target.result;
		var workbook = XLSX.read(data, {
			type: "binary",
		});

		var sheetsJSONArray = [];

		workbook.SheetNames.forEach(function (sheet) {
			let rowObject = XLSX.utils.sheet_to_row_object_array(
				workbook.Sheets[sheet]
			);
			sheetsJSONArray.push(rowObject);
			// console.log(rowObject);
		});
		return sheetsJSONArray;
	};

	reader.readAsBinaryString(file);
}
