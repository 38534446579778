var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.createMode && _vm.mode == 'view')?_c('div',{class:`category-collapse-button ${
			_vm.selectedCategory.find((c) => c.id == _vm.categoryData.id) ? 'active' : ''
		}`,on:{"click":_vm.selectCategory}},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${_vm.categoryData.id}`),expression:"`collapse-${categoryData.id}`"}],attrs:{"id":`collapse-btn-${_vm.categoryData.id}`},on:{"click":(e) => e.stopPropagation()}},[_c('img',{staticClass:"caret-icon",attrs:{"src":require("@/assets/images/caret-down.svg")}})]),_c('div',{staticClass:"category-name"},[_vm._v(" "+_vm._s(_vm.categoryData.categoryName)+" ")]),_c('span',{staticClass:"ms-auto"},[_vm._v("("+_vm._s(_vm.categoryData.questionsCount != null ? _vm.categoryData.questionsCount + _vm.categoryData.childQuestionsCount : 0)+") ")]),(!_vm.selectMode)?_c('div',{staticClass:"right-menu"},[_c('a',{staticClass:"right-menu-icon",attrs:{"role":"button"},on:{"click":function($event){return _vm.toggleSubCategoryCreate($event, true)}}},[_c('img',{attrs:{"src":require("@/assets/images/plus-icon.svg")}})]),_c('a',{staticClass:"right-menu-icon",attrs:{"role":"button"},on:{"click":function($event){return _vm.changeMode($event, 'edit')}}},[_c('img',{attrs:{"src":require("@/assets/images/edit-icon.svg")}})]),_c('a',{staticClass:"right-menu-icon",attrs:{"role":"button"},on:{"click":_vm.showDeleteConfirmPopup}},[_c('img',{attrs:{"src":require("@/assets/images/delete-icon.svg")}})])]):_vm._e()]):_vm._e(),(!_vm.createMode && _vm.mode == 'view')?_c('b-collapse',{staticClass:"category-collapse-body",attrs:{"id":`collapse-${_vm.categoryData.id}`},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[(
				_vm.expanded &&
				_vm.mode == 'view' &&
				_vm.questionCategories[_vm.productVariantId][_vm.categoryData.id]
			)?_c('div',{staticClass:"sub-category-container"},[_vm._l((_vm.questionCategories[_vm.productVariantId][
					_vm.categoryData.id
				].childCategories),function(category){return _c('QuestionCategoryListItem',{key:category.id,attrs:{"categoryData":category,"selectedCategory":_vm.selectedCategory,"productVariantId":_vm.productVariantId,"parentCategoryId":_vm.categoryData.id,"selectMode":_vm.selectMode},on:{"selectCategory":_vm.selectChildCategory}})}),(
					_vm.questionCategories[_vm.productVariantId][_vm.categoryData.id]
						.childCategories &&
					_vm.questionCategories[_vm.productVariantId][_vm.categoryData.id]
						.childCategories.length == 0 &&
					!_vm.isSubCategoryCreate
				)?_c('div',{staticClass:"no-categories mb-2"},[_c('span',[_vm._v(" No sub categories ")])]):_vm._e()],2):_vm._e(),(_vm.expanded && _vm.isSubCategoryCreate)?_c('div',{staticClass:"sub-category-container"},[_c('QuestionCategoryListItem',{attrs:{"parentCategoryId":_vm.categoryData.id,"selectedCategory":_vm.selectedCategory,"createMode":_vm.isSubCategoryCreate,"productVariantId":_vm.productVariantId,"selectMode":_vm.selectMode},on:{"selectCategory":_vm.selectChildCategory,"cancelCreate":_vm.cancelSubCategoryCreate}})],1):_vm._e()]):_vm._e(),(_vm.createMode || _vm.mode == 'edit')?_c('div',{staticClass:"category-collapse-button create"},[_c('form',{on:{"submit":function($event){return _vm.createCategory($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.categoryName),expression:"categoryName"}],staticClass:"category-input",attrs:{"type":"search","name":"categoryName","id":"categoryName","placeholder":"Enter pool name"},domProps:{"value":(_vm.categoryName)},on:{"blur":function($event){_vm.createMode ? _vm.$emit('cancelCreate') : _vm.changeMode($event, 'view')},"input":function($event){if($event.target.composing)return;_vm.categoryName=$event.target.value}}}),_c('div',{staticClass:"ms-auto d-flex gap-3"},[_c('button',{staticClass:"category-action-btn save",attrs:{"type":"submit"},on:{"click":function($event){return _vm.createCategory($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon-green.svg"),"alt":"save"}})]),_c('button',{staticClass:"category-action-btn cancel",attrs:{"type":"button","role":"button"},on:{"click":function($event){return _vm.changeMode($event, 'view')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon-red.svg"),"alt":"cancel"}})])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }