<template>
  <b-modal
    id="create-ticket-modal"
    @change="$emit('updateModalState')"
    :visible="visible"
    hide-header
    centered
    content-class="create-ticket-modal"
    size="lg"
    body-class="modal-body"
    hide-footer
    modal-class="custom-modal"
    no-close-on-backdrop
  >
    <div class="body-container">
      <button @click="closeModal" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <div>
        <div class="mb-5">
          <h1 class="add-enroll-title">Add New Ticket</h1>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <label for="services" class="main-label">Services (required)</label>
            <v-select
              class="form-input"
              id="services"
              placeholder="Select Service..."
              :options="ticketServices"
              label="title"
              v-model="service"
            ></v-select>
            <!-- :reduce="(option) => option['alpha-3']" -->
          </div>
          <div class="col-lg-12 mt-4">
            <label for="subject" class="main-label">Subject (required)</label>
            <input
              type="search"
              class="form-input"
              id="subject"
              placeholder="Enter Subject here..."
              v-model="subject"
            />
          </div>
          <div class="col-lg-12 mt-4">
            <label for="address" class="main-label">Body (required)</label>
            <textarea
              class="form-input"
              placeholder="Enter here..."
              @input="resizeTextarea"
              v-model="body"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="step-btn-container">
        <button
          @click="addTicket()"
          role="button"
          class="step-btn"
          :disabled="loading || !subject || !body || !service"
        >
          Submit
          <i class="fas fa-long-arrow-alt-right"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AddTicketModal",

  data() {
    return {
      service: null,
      subject: null,
      body: null,
      ticketServices: [],
    };
  },
  computed: {
		...mapState("support", ["loading"]),
	
    visible() {
      return this.showModal;
    },
  },
  props: {
    showModal: Boolean,
  },
  methods: {
    ...mapActions("support", ["fetchTicketServices","createSupportTicket","fetchTickets"]),
    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    closeModal() {
      this.$emit("updateModalState", false);
    },

    async getServices() {
      var data = await this.fetchTicketServices();

      this.ticketServices = data && data.length > 0 ? data : [];
    },

    async addTicket() {
      
      var ticket={
        serviceID:this.service.id,
        subject:this.subject,
        body: this.body
      };

      var ticket=await this.createSupportTicket(ticket);
      if(ticket){
        this.service = null;
        this.subject = null;
        this.body = null;
        this.closeModal();
        this.fetchTickets();
        // Vue.toasted.success("Ticket Created", {
				// 	theme: "outline",
				// 	position: "bottom-center",
				// 	duration: 3000,
				// });
      }

    },
  },
  watch: {
    showModal:function () {
      if(this.showModal==true)
       this.getServices();
		}
  },
  async created() {
    this.getServices();

  },
};
</script>

<style>
.create-ticket-modal {
  min-height: 45.375rem;
  width: 70vw;
}

.create-ticket-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.confirm-modal-body {
  padding: 1rem !important;
  font-size: 1.125rem;
}
.create-ticket-modal .body-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.create-ticket-modal .add-enroll-title {
  text-transform: capitalize;
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 1rem;
  color: #000000;
}

.create-ticket-modal .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.create-ticket-modal .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0 0 0.875rem;
  /* max-width: 21.625rem; */
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  height: 40px;
}

.create-ticket-modal .upload-btn-wrapper {
  width: 11rem;
  max-width: 11rem;
  max-height: 11rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper.preview {
  padding: 0;
}

#preview {
  max-width: 100%;
}

.upload-btn {
  padding: 0.5rem 1.25rem;
  font-weight: bold;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 6px;
  text-transform: uppercase;
  color: #000000;
  font-weight: normal;
}

.input-icon-right {
  /* position: absolute; */
  font-size: 1.25rem;
  right: 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.create-ticket-modal .upload-btn-wrapper .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.create-ticket-modal .upload-btn-wrapper:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.create-ticket-modal .upload-btn-wrapper .right-menu-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.8px solid #cbcbcb;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 50%;
}

.create-ticket-modal .upload-btn-wrapper .right-menu-icon img {
  width: 1rem;
  height: 1rem;
}

.create-ticket-modal .upload-btn-wrapper .right-menu-icon.delete {
  border-color: #ff0000;
}

.create-ticket-modal #profilePicturePath {
  pointer-events: none;
}

.create-ticket-modal .form-input.error {
  color: var(--color-danger) !important;
  border-color: var(--color-danger) !important;
}
</style>
