<template>
	<b-modal
		id="create-user-modal"
		@change="$emit('updateModalState')"
		:visible="visible"
		hide-header
		centered
		content-class="create-user-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<!-- <div class="custom-stepper-container">
			<div class="custom-stepper">
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon first',
							{ active: currentStep == 1, complete: currentStep > 1 },
						]"
					>
						1
					</div>
					<div class="step-text">
						<h1 class="title">
							User Information
						</h1>
						<h3 class="subtitle">
							Enter basic information
						</h3>
					</div>
				</div>
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon',
							{ active: currentStep == 2, complete: currentStep > 2 },
						]"
					>
						2
					</div>
					<div class="step-text">
						<h1 class="title">
							Additional Info
						</h1>
						<h3 class="subtitle">Fill out additional information</h3>
					</div>
				</div>
			</div>
			Custom Stepper
		</div> -->
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<div v-if="currentStep == 1" id="page1">
				<div class="mb-4">
					<h1 class="add-enroll-title">
						Add New {{ createUserType }}
						{{ createUserType.toLowerCase() == "management" ? "User" : null }}
					</h1>
					<span class="add-pricing-sub-title"> Basic Information </span>
				</div>
				<form>
					<div class="inline-group">
						<div class="col">
							<div class="inline-group">
								<div class="col">
									<label for="firstName" class="main-label"
										>First Name (required)</label
									>
									<input
										type="search"
										class="form-input"
										id="firstName"
										v-model="firstName"
										placeholder="Enter First Name here..."
										@change="setUsername"
									/>
								</div>
								<div class="col">
									<label for="lastName" class="main-label"
										>Last Name (required)</label
									>
									<input
										type="search"
										class="form-input"
										id="lastName"
										v-model="lastName"
										placeholder="Enter Last Name here..."
										@change="setUsername"
									/>
								</div>
							</div>
							<div class="inline-group">
								<div class="col">
									<label for="email" class="main-label">Email (required)</label>
									<input
										type="email"
										class="form-input"
										id="email"
										v-model="email"
										placeholder="Enter Email here..."
									/>
								</div>
								<div
									v-if="createUserType.toLowerCase() == 'management'"
									class="col"
								>
									<label for="role" class="main-label">Role</label>
									<v-select
										class="form-input"
										id="role"
										v-model="managementRole"
										placeholder="Select Role..."
										:options="roleOptions"
										:appendToBody="true"
										:calculate-position="withPopper"
									/>
								</div>
								<!-- <div
									v-if="createUserType.toLowerCase() == 'trainer'"
									class="col"
								>
									<label for="education" class="main-label"
										>Education (required)</label
									>
									<input
										type="search"
										class="form-input"
										id="education"
										v-model="extraproperties.CustomFields['Education']"
										placeholder="Enter your Education here..."
									/>
								</div> -->
							</div>
						</div>
						<div class="col-3 ps-5">
							<label class="main-label">Profile Picture (optional)</label>
							<div
								:class="['upload-btn-wrapper', { preview: profilePicturePath }]"
							>
								<img
									:class="[{ 'd-none': !profilePicturePath }]"
									id="preview"
									:src="profilePicturePath"
									alt="preview"
								/>
								<div v-if="profilePicturePath" class="right-menu">
									<a role="button" class="right-menu-icon" @click="openDialog">
										<img src="@/assets/images/edit-icon.svg" />
									</a>
									<a
										@click="removeFile"
										role="button"
										class="right-menu-icon delete"
									>
										<img src="@/assets/images/delete-icon.svg" />
									</a>
								</div>
								<button
									v-if="!profilePicturePath"
									type="button"
									class="upload-btn"
									@click="openDialog"
								>
									upload
								</button>
								<input
									class="form-input"
									type="file"
									accept="image/*,video/*"
									name="image-upload"
									id="image-upload"
									@change="getFile"
								/>
							</div>
						</div>
					</div>
					<div class="inline-group">
						<div class="col">
							<label for="username" class="main-label"
								>Username (required)</label
							>
							<input
								type="search"
								class="form-input"
								id="username"
								v-model="username"
								placeholder="Enter Username here..."
							/>
						</div>
						<div class="col">
							<label for="mobile" class="main-label"
								>Phone Number (required)</label
							>
							<vue-tel-input
								:class="`form-input ${
									isPhoneError && showError ? 'error' : ''
								}`"
								defaultCountry="PK"
								:inputOptions="telInputOptions"
								validCharactersOnly
								v-model="mobile"
								@input="validate"
								@blur="showError = true"
								@focus="showError = false"
								:autoFormat="true"
								mode="national"
								invalidMsg="Please enter a valid phone number"
								id="mobile"
							></vue-tel-input>
							<p class="text-danger" v-if="showError && isPhoneError">
								Invalid Phone Number
							</p>
						</div>
					</div>
					<div class="inline-group align-items-end">
						<div class="col position-relative">
							<label
								for="password"
								:class="['main-label', { 'text-danger': pwdError }]"
								>Password (required)</label
							>
							<input
								:type="showPassword ? 'text' : 'password'"
								:class="['form-input', { 'text-danger': pwdError }]"
								id="password"
								v-model="password"
								placeholder="Enter Password here..."
							/>
							<i
								@click="toggleShowPassword"
								:class="[
									'fas input-icon-right',
									{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword },
								]"
							></i>
						</div>
						<div class="col-1">
							<button
								@click="generatePassword"
								type="button"
								class="primary-btn"
							>
								Generate
							</button>
						</div>
					</div>
					<div class="inline-group">
						<div class="col">
							<label for="address" class="main-label">Address (optional)</label>
							<textarea
								type="search"
								class="form-input"
								placeholder="Enter Address here..."
								v-model="address"
							></textarea>
						</div>
						<div class="col">
							<label for="city" class="main-label">City (required)</label>
							<input
								type="search"
								class="form-input"
								id="city"
								v-model="city"
								placeholder="Enter city here..."
							/>
						</div>
						<div class="col">
							<label for="country" class="main-label">Country (required)</label>
							<v-select
								type="password"
								class="form-input"
								id="country"
								v-model="country"
								placeholder="Select Country..."
								:options="countryCodes"
								label="name"
								:reduce="(option) => option['alpha-3']"
								:appendToBody="true"
								:calculate-position="withPopper"
							></v-select>
						</div>
					</div>
				</form>
				<p v-if="pwdError" class="error-text text-danger">{{ errorText }}</p>
			</div>
			<div v-if="currentStep == 2 && hasExtraFields" id="page2">
				<div class="mb-4">
					<h1 class="add-enroll-title">
						Add New {{ createUserType }}
						{{ createUserType.toLowerCase() == "management" ? "User" : null }}
					</h1>
					<span class="add-pricing-sub-title"> Additional Information </span>
				</div>
				<div
					v-for="input in parsedExtraFields"
					:key="input.Key"
					class="form-group mb-4"
				>
					<!-- <label for="gender" class="main-label">Gender (required)</label> -->
					<DynamicFormInput
						customInputClass="form-input"
						customLabelClass="main-label"
						:inputFieldOptions="input"
						@inputValue="
							(value) => {
								extraproperties.CustomFields[input.Key] = value;
							}
						"
						:required="true"
						:showLabel="true"
					/>
				</div>
			</div>
			<div class="step-btn-container">
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev">
					<i class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner small v-if="loading"></b-spinner> {{ stepButtonContent }}
					<i class="fas fa-long-arrow-alt-right"></i>
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { countryCodes } from "../../../constants/countryCodes";
import { mapActions, mapState } from "vuex";
import DynamicFormInput from "../../Layout/DynamicFormInput.vue";
const getCountryISO3 = require("country-iso-2-to-3");
import { createPopper } from "@popperjs/core";

export default {
	name: "CreateNewUserModal",
	data() {
		return {
			currentStep: 1,
			totalSteps: 1,
			firstName: null,
			lastName: null,
			email: null,
			password: null,
			confirmPassword: null,
			username: null,
			mobile: null,
			phone: null,
			city: null,
			countryCodes,
			country: null,
			managementRole: null,
			// education: null,
			roleOptions: [{ id: "1", value: "admin", label: "Admin" }],
			profilePicturePath: null,
			profilePictureType: "image",
			// role: null,
			address: null,
			extraproperties: { CustomFields: {} },
			errorText: "",
			pwdError: false,
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: 10,
				placeholder: "Phone Number (required)",
				mode: "national",
			},
			showPassword: false,
			isPhoneError: false,
			showError: false,
		};
	},
	computed: {
		...mapState("appState", [
			"systemParameters",
			"currentCountry",
			"currentCity",
		]),
		...mapState("user", ["allUsers", "loading"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			} else return "Create";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		disableNext() {
			if (this.currentStep == 1) {
				if (this.isPhoneError) {
					return true;
				}
				if (
					this.firstName &&
					this.lastName &&
					this.email &&
					this.password &&
					this.country &&
					this.city &&
					this.phone
				) {
					return false;
				} else return true;
			} else if (this.currentStep == 2) {
				return false;
			}
		},
		hasExtraFields() {
			if (
				(this.systemParameters.UserRegisterExtraFields &&
					this.createUserType.toLowerCase() == "student") ||
				(this.systemParameters.TrainerUserRegisterExtraFields &&
					this.createUserType.toLowerCase() == "trainer")
			)
				return true;
			else return false;
		},
		parsedExtraFields() {
			// console.log(this.systemParameters);
			if (
				this.systemParameters &&
				this.systemParameters.UserRegisterExtraFields &&
				this.createUserType.toLowerCase() == "student"
			) {
				// console.log("user type student");
				return this.systemParameters.UserRegisterExtraFields;
			}
			if (
				this.systemParameters &&
				this.systemParameters.TrainerUserRegisterExtraFields &&
				this.createUserType.toLowerCase() == "trainer"
			) {
				// console.log("user type TRAINER");
				return this.systemParameters.TrainerUserRegisterExtraFields;
			}
		},
	},
	props: {
		showModal: Boolean,
		createUserType: String,
	},
	methods: {
		...mapActions("user", ["createUser"]),
		openDialog() {
			$("#image-upload").click();
		},
		removeFile() {
			this.profilePicturePath = null;
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep -= 1;
			}
		},
		next() {
			if (this.currentStep < this.totalSteps) {
				this.currentStep += 1;
			} else if (this.currentStep == this.totalSteps) {
				var newUser = {
					mobile: this.phone,
					email: this.email,
					username: this.username,
					password: this.password,
					countryCode: this.country,
					city: this.city,
					extraproperties: JSON.stringify(this.extraproperties),
					firstName: this.firstName,
					lastName: this.lastName,
					profilePicturePath: this.profilePicturePath,
					address: this.address,
					type: this.createUserType,
					isActive: true,
				};
				if (this.createUserType.toLowerCase() == "trainer") {
					newUser.education = this.education;
				} else if (this.createUserType.toLowerCase() == "management") {
					newUser.role = this.managementRole;
				}
				this.createUser(newUser).then((response) => {
					console.log(response);
					if (response) {
						if (this.createUserType.toLowerCase() == "student")
							this.triggerEnroll(response);
						this.closeModal();
					}
				});
			}
		},
		getFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.profilePicturePath = URL.createObjectURL(file);
				document.getElementById("preview").title = file.name;
				this.profilePictureType = file.type.split("/")[0];
			}
			console.log("file: ", e.target.files);
		},
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		showEnrollUserPopup(user) {
			this.$bvModal
				.msgBoxConfirm("Do you wish to enroll this student in any courses?", {
					title: "Enroll New Student",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
					okTitle: "Yes",
					cancelTitle: "No",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
					noCloseOnBackdrop: true,
					noCloseOnEsc: true,
				})
				.then((value) => {
					// this.isConfirm = value;

					if (value) {
						console.log(value);
						this.triggerEnroll(user);
						return;
					}

					this.closeModal();
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		triggerEnroll(user) {
			console.log("triggerEnroll");
			this.$emit("enrollStudent", user);
			this.closeModal();
		},
		validate(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.country && obj.country.iso2) {
				this.country = getCountryISO3(obj.country.iso2);
				this.phone = obj.number;
			}
			return false;
		},
		setUsername() {
			if (this.firstName && this.lastName)
				var length = 4,
					charset =
						"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
					randomCode = "";
			for (var i = 0, n = charset.length; i < length; ++i) {
				randomCode += charset.charAt(Math.floor(Math.random() * n));
			}
			this.username =
				this.firstName.trim().replace(/ +/g, "_").toLowerCase() +
				"_" +
				this.lastName.trim().replace(/ +/g, "_").toLowerCase() +
				"_" +
				randomCode;
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		generatePassword() {
			var length = 8,
				charset =
					"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
				retVal = "";
			for (var i = 0, n = charset.length; i < length; ++i) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}
			this.password = retVal;
			// return retVal;
		},
		toggleShowPassword() {
			this.showPassword = !this.showPassword;
		},
	},
	watch: {
		visible: function () {
			if (this.hasExtraFields) {
				this.totalSteps = 2;
			}
			this.country = this.currentCountry;
			if (this.currentCity) this.city = this.currentCity;
		},
		password: function () {
			if (this.pwdError) {
				this.pwdError = false;
				this.errorText = "";
			}
		},
		confirmPassword: function () {
			if (this.pwdError) {
				this.pwdError = false;
				this.errorText = "";
			}
		},
	},
	components: { DynamicFormInput },
};
</script>

<style>
.create-user-modal {
	min-height: 45.375rem;
	width: 70vw;
}

.create-user-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.confirm-modal-body {
	padding: 1rem !important;
	font-size: 1.125rem;
}
.create-user-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 100%;
}

.create-user-modal .add-enroll-title {
	text-transform: capitalize;
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

.create-user-modal .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.create-user-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.create-user-modal .upload-btn-wrapper {
	width: 11rem;
	max-width: 11rem;
	max-height: 11rem;
	height: 10rem;
	position: relative;
	overflow: hidden;
	background: #f5f5f5;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-btn-wrapper.preview {
	padding: 0;
}

#preview {
	max-width: 100%;
}

.upload-btn {
	padding: 0.5rem 1.25rem;
	font-weight: bold;
	cursor: pointer;
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 6px;
	text-transform: uppercase;
	color: #000000;
	font-weight: normal;
}

.input-icon-right {
	/* position: absolute; */
	font-size: 1.25rem;
	right: 0rem;
	margin-top: 0.5rem;
	cursor: pointer;
}

.create-user-modal .upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.create-user-modal .upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.create-user-modal .upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.create-user-modal .upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.create-user-modal .upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

.create-user-modal #profilePicturePath {
	pointer-events: none;
}

.create-user-modal .form-input.error {
	color: var(--color-danger) !important;
	border-color: var(--color-danger) !important;
}
</style>
