<template>
	<div class="container instructors-section">
		<div class="section-heading-container">
			<h1 class="section-heading">Our Instructors</h1>
			<h5 class="section-subheading">
				Most recommendable and highly qualified teachers squad
			</h5>
		</div>
		<div class="instructor-section">
			<InstructorList v-if="trainersLoaded" :instructorList="trainersList" />
		</div>
	</div>
</template>

<script>
import InstructorList from "./InstructorList.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	name: "HomePageInstructorSection",
	data() {
		return {
			trainersLoaded: false,
			trainersList: [],
			trainerCourses:{}
		};
	},
	components: { InstructorList },
	created() {
		
		if (!this.trainers) {
			this.ffetchTrainersList().then(() => {
				this.trainersLoaded = true;
				this.trainersList = this.trainers.map((t) => {
					return {
						id: t.id,
						name: t.firstName + " " + t.lastName,
						short_description:
							t.extraProperties &&
							JSON.parse(t.extraProperties).CustomFields.ShortDescription
								? JSON.parse(t.extraProperties).CustomFields.ShortDescription
								: "",
						ratings: {
							value: 4.7,
							number: "500+",
						},
						courses: 0,
						image_path: t.profilePicture ?? null,
					};
				});
				this.UpdateCoursesTrainerList();
			});
		} else {
			this.trainersLoaded = true;
			this.trainersList = this.trainers.map((t) => {
				return {
					id: t.id,
					name: t.firstName + " " + t.lastName,
					short_description:
						t.extraProperties &&
						JSON.parse(t.extraProperties).CustomFields.ShortDescription
							? JSON.parse(t.extraProperties).CustomFields.ShortDescription
							: "",
					ratings: {
						value: 4.7,
						number: "500+",
					},
					courses: 0,
					image_path: t.profilePicture ?? null,
				};
			});
			
			this.UpdateCoursesTrainerList();
		}
	},
	computed: {
		...mapState("user", ["trainers"]),
		...mapGetters("productVariant", ["allProductVariants"]),
		
	},
	methods: {
		...mapActions("user", ["fetchUsersList","fetchTrainersList"]),
		UpdateCoursesTrainerList:function(){
			if(this.trainersList && this.trainersList.length>0)
			{
				this.trainersList.map((t) => {
					if(this.trainerCourses[t.id])
					{
						t.courses=this.trainerCourses[t.id];
					}
					return t;
				});
			}
		}
	},
	watch: {
		allProductVariants:function () {
			if(this.allProductVariants && this.allProductVariants.length>0){
				this.trainerCourses={};
			    this.allProductVariants.forEach(element => {
					if(element.properties.trainers){
						JSON.parse(element.properties.trainers).forEach(trainerID=>{
							if (this.trainerCourses[trainerID]) {
								this.trainerCourses[trainerID].push(element);
							} else {
								this.trainerCourses[trainerID]=[];
								this.trainerCourses[trainerID].push(element);
							}

						});						
					}
				});
				
				this.UpdateCoursesTrainerList();
			}
		}

	}
};
</script>

<style scoped>
@media screen and (max-width: 990px) {
	.instructor-section {
		padding: 3rem 1rem !important;
	}
}

@media screen and (max-width: 480px) {
	.instructor-section {
		padding: 3rem 0 !important;
		border-radius: 0 !important;
		margin: 0 -1rem !important;
	}

	.section-heading {
		font-size: 2rem !important;
	}

	.section-subheading {
		font-size: 1rem !important;
	}

	.section-heading-container {
		margin: 2rem 0 !important;
	}
}

@media screen and (max-width: 320px) {
	.instructor-section {
		padding: 3rem 0 !important;
		border-radius: 0 !important;
		margin: 0 -1rem !important;
	}
}

.section-heading {
	font-weight: 500;
	font-size: 2.75rem;
	line-height: 1.3;
	color: var(--secondary-color);
	margin-bottom: 0;
	letter-spacing: -0.45px;
}

.section-subheading {
	font-size: 1.375rem;
	color: #898989;
	font-weight: normal;
}

.section-heading-container {
	margin: 4rem 0 3.375rem;
}
.instructor-section {
	background-color: var(--primary-color);
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 3.125rem;
}
</style>
