<template>
	<div class="container">
		<div class="teach-partner-section">
			<div class="teach-with-us image">
				<div class="content">
					<h1 class="title">Teach with Us</h1>
					<p class="body-text">
						Teach what you love. we gives you the tools to create an online
						course.
					</p>
					<button role="button" class="cta-btn primary">
						Why Waiting Just Start
					</button>
				</div>
			</div>
			<div class="become-a-partner image">
				<div class="content">
					<h1 class="title">Become a Partner</h1>
					<p class="body-text">
						Get unlimited access to 3,000+ of our top courses for your team.
					</p>
					<button role="button" class="cta-btn secondary">
						Build your own Platform
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomePageTeachOrPartner.vue",
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.teach-partner-section .image {
		padding: 0 3rem !important;
	}

	.teach-partner-section .image .title {
		font-size: 2.375rem !important;
	}

	.teach-partner-section .image .content {
		margin: 3rem 0 !important;
		height: 18rem !important;
	}
	.teach-partner-section .image .cta-btn {
		margin-top: 1rem !important;
		font-size: 1.5rem !important;
	}

	.teach-partner-section .image .body-text {
		font-size: 1.5rem !important;
	}
}

@media screen and (max-width: 767px) {
	.teach-partner-section {
		flex-direction: column !important;
		padding: 1.5rem !important;
		margin-top: 8rem !important;
	}

	.teach-partner-section .image {
		width: 100% !important;
		margin-top: 0 !important;
		border-radius: 8px;
	}

	.teach-partner-section .image.teach-with-us {
		margin-bottom: 1rem !important;
	}
}

.teach-partner-section {
	border-radius: 8px;
	background-color: var(--primary-color);
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 0 2.25rem 2.25rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 23.75rem;
}

.teach-partner-section .image {
	background-position: center center;
	background-size: 100% 100%;
	width: 49%;
	margin-top: -14.375rem;
	/* height: fit-content; */
	padding: 0 6rem;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	box-shadow: 0 3px 6px #d5d5d529;
}

.teach-partner-section .image .content {
	height: 23.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 30% 0;
}

.teach-partner-section .image.teach-with-us {
	background-image: url(../../assets/images/teach.png);
}
.teach-partner-section .image.become-a-partner {
	background-image: url(../../assets/images/partner.png);
}

.teach-partner-section .image .title {
	font-size: 4.375rem;
	color: #000000;
	font-weight: 500;
}
.teach-partner-section .image .body-text {
	font-size: 1.875rem;
	color: #898989;
	font-weight: normal;
}
.teach-partner-section .image .cta-btn {
	font-size: 1.875rem;
	color: #ffffff;
	font-weight: 500;
	padding: 0.875rem 1rem;
	border-radius: 2rem;
	text-decoration: none;
	width: 100%;
	line-height: 1;
	margin-top: 3.125rem;
}

.teach-partner-section .image .cta-btn.primary {
	background-color: var(--primary-color);
}

.teach-partner-section .image .cta-btn.secondary {
	background-color: var(--secondary-color);
}
</style>
