<template>
	<div class="user-modal-tab-body payments">
		<table class="table table-hover table-borderless enrolled-users-table">
			<thead>
				<tr class="d-flex">
					<th class="col-1">
						<input
							type="checkbox"
							name="select_all"
							id="select_all"
							@click="toggleSelectAll()"
							:checked="isSelectedAll()"
						/>
					</th>
					<th class="col-1">ID</th>
					<th class="col">Payment Date</th>
					<th class="col">Payment Method</th>
					<th class="col">Amount</th>
					<!-- <th class="col">Currency</th> -->
					<!-- <th class="col">Status</th> -->
					<th class="col">Type</th>
				</tr>
			</thead>
			<tbody v-if="payments && payments.length > 0">
				<tr
					:class="[
						'd-flex payment-item',
						{
							warning:
								payment.status && payment.status.toLowerCase() == 'pending',
						},
					]"
					v-for="payment in payments"
					:key="payment.id"
					@click="togglePaymentModal(payment, 'view')"
				>
					<td class="col-1">
						<input
							type="checkbox"
							name="select_1"
							:id="payment.id"
							@click="togglePaymentSelect(payment)"
							:checked="isChecked(payment.id)"
						/>
					</td>
					<td class="col-1">{{ payment.id }}</td>
					<td class="col">{{ dateParser(payment.paymentDate) }}</td>
					<td class="col">{{ payment.method }}</td>
					<td class="col">
						{{
							currencyParser(
								totalAmount(payment.details),
								payment.details && payment.details.length>0 && payment.details[0].currency? payment.details[0].currency : ( payment.currency ? payment.currency : currentCurrency)
							)
						}}
					</td>
					<!-- <td class="col">
						{{ payment.currency }}
					</td> -->
					<!-- <td class="col">
						<div
							:class="[
								'status-tag',
								{
									success:
										payment.status &&
										payment.status.toLowerCase() == 'completed',
									warning:
										payment.status && payment.status.toLowerCase() == 'pending',
								},
							]"
						>
							{{ payment.status ? payment.status : "Completed" }}
						</div>
						{{ payment.status ? payment.status : "Completed" }}
					</td> -->
					<td class="col">
						<div
							v-if="payment.details[0]"
							:class="[
								'status-tag',
								{
									success:
										payment.details[0].type &&
										payment.details[0].type.toLowerCase() == 'received',
									danger:
										payment.details[0].type &&
										payment.details[0].type.toLowerCase() == 'refunded',
									warning:
										payment.details[0].type &&
										payment.details[0].type.toLowerCase() == 'partially paid',
								},
							]"
						>
							{{ payment.details[0].type }}
						</div>
					</td>
				</tr>
			</tbody>
			<div
				v-else-if="payments.length == 0 && loading"
				class="d-flex align-items-center justify-content-center gap-2 p-3 font-500"
			>
				<b-spinner small></b-spinner> Loading...
			</div>
			<div v-else class="no-content">No Payments found</div>
		</table>
		<UserPaymentDetailModal
			:payment="paymentToViewPay"
			:mode="mode"
			:showModal="showPaymentModal"
			@updateModalState="togglePaymentModal"
			:user="user"
		/>
	</div>
</template>

<script>
import formatter from "../../../helpers/FormatHelpers";
import UserPaymentDetailModal from "./UserPaymentDetailModal.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "UserPayments",
	data() {
		return {
			payments: [],
			selectedPayments: [],
			showPaymentModal: false,
			paymentToViewPay: null,
			mode: "view",
		};
	},
	components: { UserPaymentDetailModal },
	computed: {
		...mapState("pricing", ["paymentsByUserId", "loading"]),
		...mapState("appState", ["currentCurrency"]),
	},
	props: { user: Object },
	methods: {
		...mapActions("pricing", ["fetchPaymentsByUserId", "deletePayment"]),
		toggleSelectAll() {
			if (
				this.payments &&
				this.payments.length > 0 &&
				this.selectedPayments.length != this.payments.length
			) {
				this.selectedPayments = this.payments;
			} else {
				this.selectedPayments = [];
			}
		},
		isSelectedAll() {
			return this.payments.length == this.selectedPayments.length;
		},
		togglePaymentModal(payment, mode) {
			this.showPaymentModal = !this.showPaymentModal;
			if (this.showPaymentModal) {
				this.paymentToViewPay = payment;
				this.mode = mode;
			} else {
				// this.paymentToViewPay = null;
				this.mode = "view";
			}
		},
		togglePaymentSelect(payment) {
			var found = this.selectedPayments.find((s) => s.id == payment.id);
			// console.log(found, payment);
			if (!found) {
				this.selectedPayments.push(payment);
			} else {
				this.selectedPayments = this.selectedPayments.filter(
					(su) => su.id != payment.id
				);
			}
		},
		isChecked(id) {
			var found = this.selectedPayments.find((payment) => payment.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		currencyParser(amount, currency) {
			return formatter.toCurrencyString(parseInt(amount), currency, 0);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		totalAmount(details) {
			var total = 0;
			details.forEach((detail) => {
				total += detail.amount;
				// console.log(detail.amount);
			});
			// console.log(total);
			return total;
		},
	},
	watch: {
		paymentsByUserId: function () {
			console.log("payments updated in store");
			if (this.paymentsByUserId[this.user.id]) {
				this.payments = this.paymentsByUserId[this.user.id];
			}
		},
	},
	created() {
		// if (!this.paymentsByUserId[this.user.id]) {
		this.fetchPaymentsByUserId(this.user.id).then(() => {
			console.log("payments fetched");
			this.payments = this.paymentsByUserId[this.user.id];
		});
		// } else {
		// 	this.payments = this.paymentsByUserId[this.user.id];
		// }
	},
};
</script>

<style scoped>
.no-content {
	padding: 1rem;
	text-align: center;
	font-weight: 500;
	color: #666;
	font-size: 1.125rem;
}

input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.status-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
}

.status-tag.success {
	background-color: #00c672;
}

.status-tag.danger {
	background-color: var(--color-danger);
}

.status-tag.warning {
	background-color: var(--color-warning);
}

.payment-item {
	cursor: pointer;
}

.payment-item .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	margin-right: 0.75rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.payment-item:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}
</style>
