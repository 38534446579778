
import { v4 as uuidv4 } from "uuid";
const state = {
	filesInQueueToUpload: [],
	isMaximized: false
};

const getters = {
};

const actions = {

	toggleBulkFileUploadingModel({ commit }, isOpen) {
		state.isMaximized = isOpen;
	},
	addFileInUploadQueue({ commit, dispatch }, { file, parentContentID, service }) {
		state.filesInQueueToUpload.push({
			"id": uuidv4(),
			"title": file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
			"name": file.name,
			"type": file.type,
			"contentType": service.serviceType=="RecordedVideos"?"RecordedVideos":"StudyMaterial",
			"size": file.size,
			"status": "queued",
			"file": file,
			"service": service,
			"parentContentID": parentContentID,
			"onCancelCallback": function(_fileObject){
				if (_fileObject.xhr) {
					_fileObject.xhr.abort();
				}
				_fileObject.status="cancelled";
				dispatch('NextFileInQueue');
			}
		});
		dispatch('NextFileInQueue');
	},
	NextFileInQueue({ commit, dispatch }) {
		debugger;
		var minUploadinglimit=1;
		if(this.state.appState.systemParameters.MinUploadingLimit)
		{
			minUploadinglimit=parseInt( this.state.appState.systemParameters.MinUploadingLimit + '');
		}
		var inProcessingOrUploadingFiles = state.filesInQueueToUpload.filter(x => x.status.toLowerCase() == "processing" || x.status.toLowerCase() == "uploading");
		var queuedFile = state.filesInQueueToUpload.find(x => x.status.toLowerCase() != "processing" && x.status.toLowerCase() != "uploading" && x.status.toLowerCase() == "queued");
		if (inProcessingOrUploadingFiles.length<=(minUploadinglimit-1) && queuedFile) {
			processFileAndFetchingUploadURL(queuedFile, dispatch, commit);
		}
	},
	CloseComplete({ commit, dispatch })
	{
		state.filesInQueueToUpload=state.filesInQueueToUpload.filter(x=>x.status=="uploading" || x.status=="processing" || x.status=="processed");
	},
	async processQueuedFileAndFetchingUploadURL({dispatch},fileObject) {
		if(fileObject.content)
		{	
			return;
		}
		fileObject.status = "processing";
		var recordedVideoObject = {
			title: fileObject.title,
			data: {
				servicecode: fileObject.service.serviceCode,
				filehash: "368B3C12BF01D191B690764EF94D962A",
				filename: fileObject.name,
			},
			isAvailableForDemo: false,
			parentDirectoryID: fileObject.parentContentID,
			type: fileObject.contentType,
		};
		await dispatch('content/createRecordedVideosContent', recordedVideoObject, { root: true })
			.then(video => {
				fileObject.id = video.id;
				fileObject.content = video;
				fileObject.status = "queued";
			})
			.catch(error => {
				console.log(error);
				fileObject.status = "failed";			
		});
		return fileObject;
	
	}
};


const mutations = {
};


function processFileAndFetchingUploadURL(fileObject, dispatch, commit) {
	if(fileObject.content)
	{		
		fileObject.status = "processed";
		startUploadingProcessedVideos(fileObject, dispatch, commit);
		return;
	}

	fileObject.status = "processing";
	var recordedVideoObject = {
		title: fileObject.title,
		data: {
			servicecode: fileObject.service.serviceCode,
			filehash: "368B3C12BF01D191B690764EF94D962A",
			filename: fileObject.name,
		},
		isAvailableForDemo: false,
		parentDirectoryID: fileObject.parentContentID,
		type: fileObject.contentType,
	};
	dispatch('content/createRecordedVideosContent', recordedVideoObject, { root: true })
		.then(video => {
			// console.log("recorded video uploaded", video);
			fileObject.id = video.id;
			fileObject.content = video;
			fileObject.status = "processed";
			startUploadingProcessedVideos(fileObject, dispatch, commit);
		})
		.catch(error => {
			console.log(error);
			fileObject.status = "failed";
			dispatch('NextFileInQueue');
		});

}
function processUploadingURL(url) {
	var queryString = url.split("?")[1];
	var uploadURL = url;
	var notifyURL = null;
	var params = new URLSearchParams(queryString);
	for (const param of params) {
		if (param[0] == "notifyUploadCompletionAt") {
			notifyURL = param[1];
			uploadURL = url.slice(0, url.indexOf(param[0]) - 1);
			console.log(uploadURL, notifyURL);
		}
	}
	// console.log({ uploadURL, notifyURL });
	return uploadURL;
}
async function startUploadingProcessedVideos(fileObject, dispatch, commit) {

	fileObject.status = "uploading";
	fileObject.PercentComplete = 0;
	var uploadurl = processUploadingURL(
		fileObject.content.data.uploadingUrl
	);

	const formData = new FormData();
	formData.append('file', fileObject.file);

	// post form data
	const xhr = new XMLHttpRequest()
	xhr.upload.onprogress = function (event) {		
			var percentComplete = Math.floor((event.loaded / event.total) * 100);
			console.log(percentComplete);
			fileObject.PercentComplete = percentComplete;					
			fileObject.status = "uploaded";
			fileObject.status = "uploading";
	};

	// Set up a handler for when the request completes
	xhr.onload = function () {
		if (xhr.status === 200) {
			setTimeout(function(){
				fileObject.status = "uploaded";
				dispatch('NextFileInQueue');
				dispatch('content/getContentById',{id: fileObject.content.id}, { root: true })
				.then(function(content){					
					commit('content/updateContentInParent',  {
						content: content,
						parentDirectoryID: fileObject.parentContentID,
					}, { root: true });
				});		
			
			},2000);
		} else {
			fileObject.status = "failed";
			dispatch('NextFileInQueue');
		}
	};

	xhr.open('PUT', uploadurl);
	fileObject.xhr=xhr;
	
	xhr.send(formData)

}




export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
