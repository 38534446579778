var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-right-panel-container"},[(_vm.isMobile)?_c('a',{staticClass:"text-muted",attrs:{"type":"button"},on:{"click":_vm.resetCategory}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Back")]):_vm._e(),_c('div',{class:['product-dropdown-content', { mobile: _vm.isMobile }]},[(_vm.dataLoaded)?_c('div',[_vm._l((_vm.categoryData),function(category){return _c('div',{key:category.id,staticClass:"product-list"},[_c('h1',[_vm._v(_vm._s(category.title))]),_c('ul',_vm._l((5),function(item){return _c('li',{key:item},[_c('img',{staticClass:"product-list-image",attrs:{"src":require("../../../assets/images/sample-thumbnail.jpeg"),"alt":""}}),_c('a',{attrs:{"role":"button"}},[_vm._v("AFM - Advanced Financial Management (P4 - Advance Financial Management)")])])}),0),_c('a',{staticClass:"view-all",attrs:{"role":"button"}},[_vm._v("View all "+_vm._s(category.title))])])}),(
					_vm.productVariantsByCategory[_vm.activeCategory] &&
					_vm.filteredProductVariantsByCategory.length
				)?_c('div',{staticClass:"product-list",attrs:{"id":"products"}},[_c('ul',_vm._l((_vm.filteredProductVariantsByCategory),function(item){return _c('li',{key:item.id},[_c('img',{staticClass:"product-list-image",attrs:{"src":item.thumbnailPath
									? item.thumbnailPath
									: require('@/assets/images/sample-thumbnail.jpeg'),"alt":""}}),_c('a',{attrs:{"role":"button"},on:{"click":function($event){_vm.handleClick(_vm.productVariantURL(item))}}},[_vm._v(_vm._s(item.product.title)+" "),_c('br'),_vm._v(" "+_vm._s(item.title)+" "),(item.status == _vm.ProductVariantStatus.COMING_SOON)?_c('span',{staticClass:"coming-soon-pill"},[_vm._v(_vm._s(item.status))]):_vm._e()])])}),0)]):_vm._e()],2):_vm._e()]),(
			(_vm.categoryData &&
				_vm.categoryData.length == 0 &&
				!_vm.productVariantsByCategory[_vm.activeCategory] &&
				!_vm.loading) ||
			(_vm.filteredProductVariantsByCategory &&
				!_vm.filteredProductVariantsByCategory.length)
		)?_c('div',{staticClass:"no-content d-flex align-items-center justify-content-center"},[_vm._v(" no courses found ")]):_vm._e(),(_vm.loading)?_c('b-spinner'):_vm._e(),(_vm.showMoreCourses)?_c('img',{staticClass:"more-courses-pointer",attrs:{"src":require("@/assets/images/more-courses.gif")}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }