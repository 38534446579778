<template>
  <li
    :id="item.id"
    :class="['content-tab created', { active: activeTab == item.id }]"
    @click="$emit('changeTab', $event)"
  >
    <img
      src="@/assets/images/drag-icon.svg"
      class="drag-icon chapter-handle handle"
    />{{ item.title }}
    <div class="right-menu">
      <a role="button" class="right-menu-icon" @click="showModal">
        <img src="@/assets/images/edit-icon.svg" />
      </a>
      <EditContentModal
        :contentData="item"
        :showModal="showEditModal"
        @updateModalState="toggleEditModal"
        entity="Subject"
        :productVariantID="productVariantID"
      />
      <a
        v-if="user.type && user.type.toLowerCase() != 'trainer'"
        @click="showDeleteConfirmPopup"
        role="button"
        class="right-menu-icon delete"
      >
        <img src="@/assets/images/delete-icon.svg" />
      </a>
    </div>
  </li>
</template>

<script>
import EditContentModal from "./EditContentModal.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "CourseContentTab",
  data() {
    return {
      showEditModal: false,
    };
  },
  props: { item: Object, activeTab: String, productVariantID: String },
  components: { EditContentModal },
  methods: {
    ...mapActions("content", ["saveCopiedTab", "deleteProductVariantItem"]),
    toggleEditModal(state) {
      if (state !== undefined) {
        this.showEditModal = state;
      } else this.showEditModal = !this.showEditModal;
    },
    showModal(e) {
      if (e) e.stopPropagation();
      this.showEditModal = true;
    },
    showDeleteConfirmPopup(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete the Subject: ${this.item.title}?`,
          {
            title: "Are you sure?",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
            bodyClass: "confirm-modal-body",
          }
        )
        .then((value) => {
          // this.isConfirm = value;
          if (value) {
            this.delete();
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
    delete() {
      var itemObj = {
        productVariantID: this.productVariantID,
        items: [
          {
            type: "Content",
            id: this.item.id,
            extraProperties: null,
          },
        ],
      };
      this.$emit("updateActiveTab");
      this.deleteProductVariantItem(itemObj).then((res) => {
        console.log("Subject deleted");
      });
    },
    copyTab() {
      console.log(this.item.id);
      this.saveCopiedTab(this.item);
      this.$toasted.success("Subject copied", {
        duration: 3000,
        theme: "outline",
        icon: "check",
        position: "bottom-center",
      });
    },
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    // $(() => {
    $(".course-content-tabs").contextMenu({
      selector: `#${this.item.id}`,
      callback: (key, options) => {
        switch (key) {
          case "edit":
            this.showModal();
            break;
          case "delete":
            this.showDeleteConfirmPopup();
            break;
          case "copy":
            this.copyTab();
        }
      },
      items: {
        edit: { name: "Edit", icon: "edit" },
        copy: { name: "Copy", icon: "copy" },
        delete: { name: "Delete", icon: "delete" },
      },
    });
    // });
  },
};
</script>

<style scoped>
.content-tab .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  margin-left: auto;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
}

.content-tab:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.drag-icon {
  cursor: grab;
}
</style>
