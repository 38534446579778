<template>
	<div class="create-content-activity-container">
		<b-collapse
			:id="`options-collapse${contentData ? contentData.id : ''}`"
			v-if="showAddOptions"
			class="create-options-container"
		>
			<div class="option-tabs">
				<ul class="inline-list">
					<li
						@click="changeTab"
						:id="ContentType.LIVE_CLASS"
						:class="[
							'option-tab',
							{ active: activeTab == ContentType.LIVE_CLASS },
						]"
					>
						Live Classes
					</li>
					<li
						@click="changeTab"
						:id="ContentType.RECORDED_VIDEOS"
						:class="[
							'option-tab',
							{
								active: activeTab == ContentType.RECORDED_VIDEOS,
							},
						]"
					>
						Recorded Videos
					</li>
					<li
						@click="changeTab"
						:id="ContentType.STUDY_MATERIAL_SERVICE"
						:class="[
							'option-tab',
							{
								active: activeTab == ContentType.STUDY_MATERIAL_SERVICE,
							},
						]"
					>
						Study Material
					</li>
					<li
						v-if="
							this.externalServicesList &&
							this.externalServicesList[ContentType.EXAM]
						"
						@click="changeTab"
						:id="ContentType.EXAM"
						:class="['option-tab', { active: activeTab == ContentType.EXAM }]"
					>
						Exam
					</li>
					<li
						v-if="currentTheme=='ebook'"
						@click="changeTab"
						:id="ContentType.ASSIGNMENT"
						:class="['option-tab', { active: activeTab == ContentType.ASSIGNMENT }]"
					>
						Assignment
					</li>
					<li v-if="currentTheme=='ebook'" @click="changeTab" :id="ContentType.FREETEXT" :class="[
						'option-tab',
						{
							active: activeTab == ContentType.FREETEXT,
						},
					]">
						Freetext
					</li>
					<li
						v-if="currentTheme=='ebook'"
						@click="changeTab"
						:id="ContentType.FLASHCARDS"
						:class="[
							'option-tab',
							{
								active: activeTab == ContentType.FLASHCARDS,
							},
						]"
					>
						Flash Cards
					</li>
				</ul>
				<button
					v-b-toggle="`options-collapse${contentData ? contentData.id : ''}`"
					@click="toggleAddOptions"
					role="button"
					class="cancel-button"
				>
					<img src="@/assets/images/cancel-icon.svg" class="cancel-icon" />
					Cancel
				</button>
			</div>
			<div class="option-tab-body"  >
				
				<div v-if="activeTab==ContentType.ASSIGNMENT && currentTheme=='ebook'" class="services-list" >
					<div :class="['service-list-item']" @click="handleManageAssignmentsClick()" >
						<img class="icon" src="@/assets/images/exam-test.png"/>
						<span class="title">Assignments </span>
					</div>
				</div>

				<div
					v-else-if="activeTab==ContentType.FLASHCARDS  && currentTheme=='ebook'"
					class="services-list"
				>
					<div :class="['service-list-item']" @click="handleFlashCardClick()" >
						<img class="icon" src="@/assets/images/exam-test.png"/>
						<span class="title"> Flash Card </span>
					</div>

			</div>

			<div
					v-else-if="activeTab==ContentType.FREETEXT  && currentTheme=='ebook'"
					class="services-list"
				>
					<div :class="['service-list-item']" @click="handleFreeTextClick()" >
						<img class="icon" src="@/assets/images/exam-test.png"/>
						<span class="title">Free Text </span>
					</div>

			</div>

				<div
					v-else-if="!loading && serviceProviders[activeTab]"
					class="services-list"
				>
					<div
						v-for="service in serviceProviders[activeTab]"
						v-if="isAllowService(service)"
						:key="service.serviceProvider"
						:class="[
							'service-list-item',
							{
								disabled:
									isVideoLoading &&
									service.serviceProvider.toLowerCase() == 'vss',
							},
						]"
						@click="handleServiceClick(service)"
					>
						
						<img
							v-if="service.serviceProvider.toLowerCase() == 'zoom'"
							src="@/assets/images/zoom-logo.png"
							class="zoom-icon"
						/>
						<!-- <i
							v-else-if="
								isVideoLoading && service.serviceProvider.toLowerCase() == 'vss'
							"
							class="fas fa-upload icon"
						></i> -->
						<img
							class="icon"
							v-else-if="
								isVideoLoading && service.serviceProvider.toLowerCase() == 'vss'
							"
							src="@/assets/images/study-material.png"
						/>
						<img
							class="icon"
							v-else-if="service.serviceProvider.toLowerCase() == 'vss' && service.serviceType==ContentType.STUDY_MATERIAL_SERVICE"
							src="@/assets/images/study-material.png"
						/>
						<img
							class="icon"
							v-else-if="service.serviceProvider.toLowerCase() == 'vss'"
							src="@/assets/images/recorded-lecture.png"
						/>
						<img
							class="icon"
							v-else-if="service.serviceProvider.toLowerCase() == 'youtube'"
							src="@/assets/images/youtube-icon.png"
						/>
						<img
							class="icon"
							v-else-if="
								service.serviceProvider ==
									ServiceCodes.EXAM.ExamTestingSystem ||
								service.serviceProvider == ServiceCodes.EXAM.V3ExamSystem
							"
							src="@/assets/images/exam-test.png"
						/>
						<img
							class="icon"
							v-else-if="
								activeTab == ContentType.EXAM
							"
							src="@/assets/images/study-material.png"
						/>
						<!-- <i v-else class="far fa-file icon"></i> -->
						<img class="icon" v-else src="@/assets/images/study-material.png" />
						
						
						<span class="title" :title="serviceTitle(service)">{{
							serviceTitle(service)
						}}</span>
					</div>
					<div v-if="activeTab == ContentType.EXAM  && currentTheme=='ebook'" class="service-list-item" @click="handleQuizClick()">
						<img
							class="icon"
							src="@/assets/images/exam-test.png"
						/>
						<span class="title" title="Quiz">Quiz</span>
					</div>
					
					<div
						v-if="serviceProviders[activeTab].length == 0 && activeTab != ContentType.EXAM"
						class="no-services text-muted"
					>
						No services found
					</div>
				</div>

				<div v-else class="services-list skeleton">
					<b-skeleton width="11.25rem" height="8.75rem"></b-skeleton>
					<b-skeleton width="11.25rem" height="8.75rem"></b-skeleton>
					<b-skeleton width="11.25rem" height="8.75rem"></b-skeleton>
				</div>
			</div>
		</b-collapse>
		<div class="button-container">
			<button
				v-if="showAddOptions"
				role="button"
				class="cancel-content-btn"
				@click="toggleAddOptions"
				v-b-toggle="`options-collapse${contentData ? contentData.id : ''}`"
			>
				<img src="@/assets/images/cancel-icon.svg" class="cancel-icon" />
				cancel
			</button>
			<button
				:disabled="mode == 'create'"
				v-else
				role="button"
				class="add-content-btn"
				@click="toggleAddOptions"
				v-b-toggle="`options-collapse${contentData ? contentData.id : ''}`"
			>
				<object
					:data="require('@/assets/images/add-border-icon-red.svg')"
					alt=""
					type="image/svg+xml"
					class="color-change-svg"
				/>
				add activity
			</button>
			<!-- <span>OR</span>
			<button
				:disabled="mode == 'create'"
				role="button"
				class="add-content-btn"
			>
				<img src="@/assets/images/add-border-icon-red.svg" alt="" />
				import activity
			</button> -->
		</div>
		<UploadRecordedLectureModal
			v-if="serviceProviders[activeTab] && selectedService"
			:showModal="showRecordedLectureModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleRecordedVideosModal"
			:serviceData="serviceData"
			:service="selectedService"
			:contentType="selectedService.serviceType"
			@updateProgress="updateVideoProgress"
		/>
		<UploadYoutubeRecordedLectureModal
			v-if="serviceProviders[activeTab] && selectedService"
			:showModal="showYoutubeRecordedLectureModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleYoutubeRecordedVideosModal"
			:serviceData="serviceData"
			:service="selectedService"
			:contentType="selectedService.serviceType"
		/>
		<CreateZoomMeetingModal
			v-if="serviceProviders[activeTab]"
			:visible="showZoomModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleZoomModal"
		/>
		<UploadStudyMaterialModal
			v-if="serviceProviders[activeTab]"
			:showModal="showStudyMaterialModal"
			:parentContentId="parentContentId"
			:allowDownload="allowDownload"
			@updateModalState="toggleStudyMaterialModal"
			:serviceData="serviceData"
			:serviceProvider="selectedProvider"
		/>
		<CreateEditExamModal
			v-if="serviceProviders[activeTab]"
			:showModal="showExamModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleExamModal"
			:productVariantId="productVariantId"
		/>
		<CreateEditLegacyExamModal
			v-if="serviceProviders[activeTab]"
			:showModal="showLegacyExamModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleLegacyExamModal"
			:productVariantId="productVariantId"
		/>

		<FlashCardActionModal
			:isEditMode="false"
			:visible="this.showFlashCardModel"
			:parentContentId="parentContentId"
			@updateModalState="toggleFlashCardModal"
		/>

		<FreeTextActionModal 
			:isEditMode="false"
			:visible="this.showFreetextModal" 
			:parentContentId="parentContentId"
			@updateModalState="toggleFreeTextModal" 
		/>

		<AssignmentActionModal
			:isEditMode="false"
			:visible="this.showManageAssignmentsModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleManageAssignmentsModal"
		/>
		<CreateEditQuizModal
			v-if="showQuizModal"
			:showModal="showQuizModal"
			:parentContentId="parentContentId"
			@updateModalState="toggleQuizModal"
			:productVariantId="productVariantId"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateZoomMeetingModal from "./CreateZoomMeetingModal.vue";
import UploadRecordedLectureModal from "./UploadRecordedLectureModal.vue";
import UploadYoutubeRecordedLectureModal from "./UploadYoutubeRecordedLectureModal.vue";
import UploadStudyMaterialModal from "./UploadStudyMaterialModal.vue";
import CreateEditExamModal from "../Exam/CreateEditExamModal.vue";
import CreateEditLegacyExamModal from "../Exam/CreateEditLegacyExamModal.vue";
import FlashCardActionModal from "./FlashCardActionModal.vue"
import FreeTextActionModal from "./FreeTextActionModal.vue"
import AssignmentActionModal from "./AssignmentActionModal.vue";
import CreateEditQuizModal from "../Exam/Quiz/CreateEditQuizModal.vue";
import { ContentType } from "../../../constants/content";
import { ServiceCodes } from "../../../constants/serviceCodes";
export default {
	name: "ExternalServicesList",
	data() {
		return {
			showZoomModal: false,
			showRecordedLectureModal: false,
			showYoutubeRecordedLectureModal: false,
			showStudyMaterialModal: false,
			showManageAssignmentsModal: false,
			showExamModal: false,
			showLegacyExamModal: false,
			showFlashCardModel: false,
			showFreetextModal: false,
			serviceProviders: {},
			loading: false,
			activeTab: ContentType.LIVE_CLASS,
			isVideoLoading: false,
			isStudyMaterialLoading: false,
			showAddOptions: false,
			selectedProvider: null,
			selectedService: null,
			allowDownload: false,
			showManageAssignmentsModal: false,
			showQuizModal: false,
			productVariant:null,
			ContentType,
			ServiceCodes
		};
	},
	props: {
		parentContentId: String,
		mode: String,
		contentData: Object,
		productVariantId: String,
	},
	components: {
		CreateZoomMeetingModal,
		UploadRecordedLectureModal,
		UploadStudyMaterialModal,
		CreateEditExamModal,
		CreateEditLegacyExamModal,
		FlashCardActionModal,
		FreeTextActionModal,
		AssignmentActionModal,
		CreateEditQuizModal,
		UploadYoutubeRecordedLectureModal
	},
	methods: {
		...mapActions("content", [
			"getContentById",
		]),
		...mapActions("appState", ["fetchExternalServices"]),		
		...mapState("content", ["content"]),
		handleFlashCardClick(){
			this.showFlashCardModel = !this.showFlashCardModel;
			console.log('handle: '+this.showFlashCardModel)
		},
		toggleFlashCardModal(state) {
			if (state !== undefined) {
				this.showFlashCardModel = state;
			} else {
				this.showFlashCardModel = !this.showFlashCardModel;
			}
		},
		handleFreeTextClick() {
			this.showFreetextModal = !this.showFreetextModal;
		},
		toggleFreeTextModal(state) {
			if (state !== undefined) {
				this.showFreetextModal = state;
			} else {
				this.showFreetextModal = !this.showFreetextModal;
			}
		},
		handleServiceClick(service) {
			console.log(service);
			if (service.serviceProvider.toLowerCase() == "zoom") {
				this.showZoomModal = true;
			} else if (service.serviceProvider.toLowerCase() == "vss") {
				this.showRecordedLectureModal = true;
				this.selectedProvider = service.serviceProvider.toLowerCase();
				this.selectedService = service;
			} else if (service.serviceProvider.toLowerCase() == "youtube") {
				this.selectedProvider = service.serviceProvider.toLowerCase();
				this.selectedService = service;
				this.showYoutubeRecordedLectureModal = true;
			}
			else if (
				service.serviceProvider.toLowerCase() == "local" ||
				service.serviceProvider.toLowerCase() == "remote"
			) {
				this.showStudyMaterialModal = true;
				this.selectedProvider = service.serviceProvider.toLowerCase();
				this.selectedService = service;
			} else if (
				service.serviceProvider == ServiceCodes.EXAM.ExamTestingSystem
			) {
				this.showExamModal = true;
			} else if (service.serviceProvider == ServiceCodes.EXAM.V3ExamSystem) {
				this.showLegacyExamModal = true;
			}
		},
		handleManageAssignmentsClick(){
			this.showManageAssignmentsModal = !this.showManageAssignmentsModal;
		},
		toggleManageAssignmentsModal(state) {
			if (state !== undefined) {
				this.showManageAssignmentsModal = state;
			} else {
				this.showManageAssignmentsModal = !this.showManageAssignmentsModal;
			}
		},
		handleQuizClick() {
			this.showQuizModal = true;
		},
		toggleZoomModal(state) {
			if (state !== undefined) {
				this.showZoomModal = state;
			} else {
				this.showZoomModal = !this.showZoomModal;
			}
			if (!this.showZoomModal) {
				// this.$emit("expandContent");
			}
		},
		toggleRecordedVideosModal(state) {
			if (state !== undefined) {
				this.showRecordedLectureModal = state;
			} else {
				this.showRecordedLectureModal = !this.showRecordedLectureModal;
			}
			if (!this.showRecordedLectureModal) {
				// this.$emit("expandContent");
			}
		},
		toggleYoutubeRecordedVideosModal(state) {
			if (state !== undefined) {
				this.showYoutubeRecordedLectureModal = state;
			} else {
				this.showYoutubeRecordedLectureModal = !this.showYoutubeRecordedLectureModal;
			}
		},
		toggleStudyMaterialModal(state) {
			if (state !== undefined) {
				this.showStudyMaterialModal = state;
			} else {
				this.showStudyMaterialModal = !this.showStudyMaterialModal;
			}
			if (!this.showStudyMaterialModal) {
				// this.$emit("expandContent");
			}
		},
		toggleExamModal(state) {
			if (state !== undefined) {
				this.showExamModal = state;
			} else {
				this.showExamModal = !this.showStudyMaterialModal;
			}
		},
		toggleLegacyExamModal(state) {
			if (state !== undefined) {
				this.showLegacyExamModal = state;
			} else {
				this.showLegacyExamModal = !this.showStudyMaterialModal;
			}
		},
		toggleQuizModal(state) {
			if (state !== undefined) {
				this.showQuizModal = state;
			} else {
				this.showQuizModal = !this.showQuizModal;
			}
		},
		serviceIcon(serviceCode) {
			console.log(serviceCode);
			if (serviceCode.toLowerCase() == "vss") {
				return <img src="@/assets/images/play-icon.svg" />;
			}
		},
		updateVideoProgress(progress, id) {
			console.log("external services list", progress, id);
			if (progress < 100) {
				this.isVideoLoading = true;
			} else {
				this.isVideoLoading = false;
			}
			this.$emit("updateVideo", progress, id);
		},
		changeTab(e) {
			this.activeTab = e.target.id;
		},
		toggleAddOptions() {
			if (this.showAddOptions) {
				setTimeout(() => {
					this.showAddOptions = !this.showAddOptions;
				}, 300);
			} else {
				this.$emit("expandContent", true);
				this.showAddOptions = !this.showAddOptions;
				setTimeout(() => {
					document
						.getElementById(`options-collapse${this.contentData.id}`)
						.scrollIntoView({
							behavior: "smooth",
							block: "end",
							inline: "nearest",
						});
				}, 600);
			}

			if (this.showAddOptions) {
				this.loading = true;
				if (!this.externalServicesList[this.activeTab]) {
					this.fetchExternalServices(this.activeTab).then(() => {
						var providers = [];
						var list = this.externalServicesList[this.activeTab].filter(
							(service) => {
								if (!providers.includes(service.serviceProvider)) {
									providers.push(service.serviceProvider);
									return service;
								}
							}
						);
						this.serviceProviders[this.activeTab] = list;
						this.loading = false;
					});
				} else {
					var providers = [];
					var list = this.externalServicesList[this.activeTab].filter((service) => {
						if (!providers.includes(service.serviceProvider)) {
							providers.push(service.serviceProvider);
							return service;
						}
					});
					this.serviceProviders[this.activeTab] = list;
					this.loading = false;
				}
				if (this.showAddOptions && !this.content[this.contentData.id]) {
					
					this.getContentById({
						id: this.contentData.id,
					});
				}






			}


		},
		serviceTitle(service) {
			return service.serviceProvider.toLowerCase() == "vss"  && service.serviceType==ContentType.STUDY_MATERIAL_SERVICE ?
			"Upload Secure PDF":
			service.serviceProvider.toLowerCase() == "vss" ||
				service.serviceProvider.toLowerCase() == "local" ||
				service.serviceProvider.toLowerCase() == "remote"
				? !this.isVideoLoading
					? "Upload File"
					: "Uploading..."
				: service.serviceProvider.toLowerCase() == "zoom"
				? "Zoom"
				: service.serviceName;
		},
		isAllowService(service){
			if(this.currentTheme=='ebook')
				return true;

			if(service.serviceType==ContentType.RECORDED_VIDEOS && service.serviceProvider.toLowerCase()=='youtube')
				return false;
			else
				return true;
		}
	},
	computed: {
		...mapState("appState", ["externalServicesList", "systemParameters"]),
		
		...mapState("productVariant", ["productVariants"]),
		serviceData() {
			if (this.selectedService) {
				return JSON.parse(this.selectedService.serviceData);
			} else {
				return null;
			}
		},
		currentTheme(){
			if( this.productVariants && this.productVariants.items[this.productVariantId] && this.productVariants.items[this.productVariantId].properties)
				return this.productVariants?.items[this.productVariantId]?.properties?.contentViewTheme?.toLowerCase();
		}
	},
	created() {
		if (this.systemParameters && this.systemParameters.AllowStudyMaterialDefaultValue) {
			  this.allowDownload = this.systemParameters.AllowStudyMaterialDefaultValue=="true";			
		}
		this.productVariant=this.productVariants;
	},
	watch: {
		activeTab: function () {
			this.loading = true;
			if (!this.externalServicesList[this.activeTab]) {
				this.fetchExternalServices(this.activeTab).then(() => {
					var providers = [];
					var list = (this.externalServicesList[this.activeTab]||[]).filter(
						(service) => {
							if (!providers.includes(service.serviceProvider)) {
								providers.push(service.serviceProvider);
								return service;
							}
						}
					);
					this.serviceProviders[this.activeTab] = list;
					this.loading = false;
				});
			} else {
				var providers = [];
				var list = this.externalServicesList[this.activeTab]?.filter(
					(service) => {
						if (!providers.includes(service.serviceProvider)) {
							providers.push(service.serviceProvider);
							return service;
						}
					}
				);
				this.serviceProviders[this.activeTab] = list;
				this.loading = false;
			}
		},
		showAddOptions: function () {
			this.$nextTick(() => {
				setTimeout(() => {
					var svg = document.querySelectorAll(".color-change-svg");
					// console.log("svg", svg);
					for (var j = 0; j < svg.length; j++) {
						var data = svg[j].contentDocument;
						if (data) {
							var primary = data.querySelectorAll(".primary-color");
							// console.log(primary);
							for (var i = 0; i < primary.length; i++) {
								primary[i].attributes["fill"].value =
									this.systemParameters.Theme.PrimaryColor;
							}
						}
					}
				}, 300);
			});
		},
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				var svg = document.querySelectorAll(".color-change-svg");
				// console.log("svg", svg);
				for (var j = 0; j < svg.length; j++) {
					var data = svg[j].contentDocument;
					if (data) {
						var primary = data.querySelectorAll(".primary-color");
						var secondary = data.querySelectorAll(".secondary-color");
						// console.log(primary);
						for (var i = 0; i < primary.length; i++) {
							primary[i].attributes["fill"].value =
								this.systemParameters.Theme.PrimaryColor;
						}
					}
				}
			}, 300);
		});
	},
};
</script>

<style scoped>
.services-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1.5rem;
}

.service-list-item {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 6px;
	width: 11.25rem;
	height: 8.75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 1.5rem;
	position: relative;
	cursor: pointer;
}

.service-list-item.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.service-list-item .title {
	font-size: 1rem;
	color: #000000;
	line-height: 1.37;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-width: 80%;
	text-align: center;
}

.service-list-item .upload {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	width: 11.25rem;
	height: 8.75rem;
	top: 0;
	left: 0;
}

.service-list-item .icon {
	max-height: 3rem;
	font-size: 2.75rem;
}

.service-list-item .zoom-icon {
	width: 3.25rem;
	height: 3.675rem;
}

.create-content-activity-container {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	column-gap: 1.25rem;
}

.create-content-activity-container .button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1.25rem;
	padding: 2rem 1.5rem;
	border-top: 1px solid #dcdcdc;
}

.add-content-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: 0.05em;
	color: var(--primary-color);
}

.cancel-content-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: 0.05em;
	color: #000000;
}

.no-data {
	text-align: center;
	padding: 1rem 0 1rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}

.option-tabs {
	background: #ffffff;
	border-bottom: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	/* border-radius: 6px 6px 0px 0px; */
	display: flex;
	flex-direction: row;
	padding: 0 1.5rem;
}

.option-tab {
	padding: 1.5rem 1.25rem;
	font-weight: 500;
	font-size: 1.125rem;
	border-bottom: 1.5px solid transparent;
}

.option-tab.active {
	border-bottom: 1.5px solid var(--primary-color);
}

.option-tab-body {
	padding: 1.75rem;
	background-color: #f9f9f9;
}

.option-tabs .cancel-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.5rem;
	margin-left: auto;
}

.cancel-icon {
	width: 0.875rem;
}
</style>
