import RecordedVideo from "./recordedVideo";
import LiveClass from "./liveClass";

class Directory {
	constructor(data) {
		// console.log("data in directory constructor", data);
		this.id = data.id;
		this.title = data.title;
		this.type = data.type;
		this.availableFrom = data.availableFrom;
		this.availableTo = data.availableTo;
		this.isContentAvailableForDemo = data.isContentAvailableForDemo;
		this.sequence = data.sequence;
		this.parentDirectoryID = data.parentDirectoryID ?? null;
		this.extraProperties = data.extraProperties
			? JSON.parse(data.extraProperties)
			: null;
		if (data.data != null) {
			this.children = data.data.children.map((item) => {
				if (item.type.toLowerCase() == "recordedvideos") {
					console.log(item.type.toLowerCase());
					return new RecordedVideo(item);
				} else if (item.type.toLowerCase() == "directory") {
					console.log(item.type.toLowerCase());
					return new Directory(item);
				} else if (item.type.toLowerCase() === "liveclass") {
					console.log(item.type.toLowerCase());
					return new LiveClass(item);
				} else return item;
			});
		} else this.children = null;
	}
}

export default Directory;
