class User {
	constructor(userData) {
		console.log(userData);
		this.id = userData.id;
		// this.fulName = userData.firstName + " " + userData.lastName;
		this.firstName = userData.firstName;
		this.lastName = userData.lastName;
		this.gender = userData.gender ? userData.gender : null;
		this.username = userData.username;
		this.email = userData.email;
		this.type = userData.type || userData.role;
		this.phone = userData.phone;
		this.countryCode = userData.countryCode;
		this.profilePicture = userData.profilePic || userData.profilePicture;
		this.productVariants = userData.productVariants
			? userData.productVariants
			: null;
		this.city = userData.city ?? null;
		this.address = userData.address ?? null;
		this.extraProperties = userData.extraProperties
			? JSON.parse(userData.extraProperties)
			: null;
	}
}

export default User;
