function toCurrencyString(amount, currency, decimalPlaces) {
	return amount.toLocaleString(undefined, {
		style: "currency",
		currency: currency,
		maximumFractionDigits: decimalPlaces,
	});
}

function minToHoursAndMins(mins) {
	var hours = (mins / 60).toFixed(0);
	var minutes = mins - hours * 60;
	if (minutes < 10) {
		minutes = "0" + minutes.toString();
	}
	var output = "";
	return output + hours + ":" + minutes;
}

function toShortDateString(date) {
	var parsedDate = new Date(date).toDateString();
	parsedDate = parsedDate.substr(parsedDate.indexOf(" ") + 1);
	return parsedDate;
}

function getInputDateFormat(date, isDateTimeLocal = false) {
	var _Date = new Date(date),
		Day = _Date.getDate() > 9 ? _Date.getDate() : "0" + _Date.getDate(),
		Month =
			_Date.getMonth() + 1 > 9
				? _Date.getMonth() + 1
				: "0" + (_Date.getMonth() + 1);
	if (!isDateTimeLocal) {
		return _Date.getFullYear() + "-" + Month + "-" + Day;
	} else {
		var Hours =
			_Date.getHours() > 9 ? _Date.getHours() : "0" + _Date.getHours();
		var Minutes =
			_Date.getMinutes() > 9 ? _Date.getMinutes() : "0" + _Date.getMinutes();
		return (
			_Date.getFullYear() +
			"-" +
			Month +
			"-" +
			Day +
			"T" +
			Hours +
			":" +
			Minutes
		);
	}

	// 2018-06-12T19:30
}

function parseDateToYMD(dateString) {
	const dateObj = new Date(dateString);
	const year = dateObj.getFullYear();
	const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
  }
  // Example output: 2023-07-11"
  

function getTimeIn12Hours(date) {
	var Hours = new Date(date).getHours();
	var timeOfDay = parseInt(Hours) >= 12 ? "PM" : "AM";
	var Minutes = new Date(date).getMinutes();
	Hours = Hours <= 12 ? Hours : Hours - 12;
	return (
		Hours.toString().padStart(2, "0") +
		":" +
		Minutes.toString().padStart(2, "0") +
		" " +
		timeOfDay
	);
}

function parseForURL(string) {
	return string?.replace(/[^a-zA-Z0-9.-]/gi, "");
}

module.exports = {
	toCurrencyString,
	toShortDateString,
	getInputDateFormat,
	parseDateToYMD,
	getTimeIn12Hours,
	minToHoursAndMins,
	parseForURL,
};
