<template>
	<div class="create-content-container">
		<ProductVariantContentCreateItem
			v-for="item in contentList"
			:key="item.id"
			mode="created"
			:contentData="item"
			@deleteContent="removeContentFromList"
			@assignToContent="assign"
		/>
		<ProductVariantContentCreateItem
			@addContent="addContentToList"
			mode="edit"
			:availableTo="availableTo"
			:availableFrom="availableFrom"
		/>
	</div>
</template>

<script>
import ProductVariantContentCreateItem from "./ProductVariantContentCreateItem.vue";
import { mapActions, mapState } from "vuex";
export default {
	name: "ProductVariantContentCreate",
	components: { ProductVariantContentCreateItem },
	// data() {
	// 	return {
	// 		contentList: [],
	// 	};
	// },
	methods: {
		...mapActions("content", ["assignTrainerToContent"]),
		addContentToList(item) {
			this.$emit("addContentToList", item);
		},
		removeContentFromList(id) {
			this.$emit("deleteContent", id);
		},
		assign(assignTrainers) {
			this.contentList.map((content) => {
				if (content.id == assignTrainers.id) {
					content.trainers = assignTrainers.trainers;
				}
			});
			// this.assignTrainerToContent(assignTrainers).then()
		},
	},
	props: {
		contentList: Array,
		availableTo: String,
		availableFrom: String,
	},
};
</script>

<style scoped>
.create-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	/* max-height: 17.5rem;
	overflow-y: auto; */
}
</style>
