import store from "../store/index";
import {
  FETCH_PUT_POST_CONTENT_API,
  FETCH_POST_USER_CONTENT_ACTION_API,
  FETCH_POST_CONTENT_TRAINERS_API,
  POST_FILE_API,
  PATCH_CONTENT_SEQUENCE,
  FETCH_POST_LIVE_CLASS_ATTENDANCE_API,
  FETCH_CONTENT_ATTENDANCE_API,
  FETCH_CONTENT_ATTENDANCE_DETAIL_API,
  EXPORT_CONTENT_ATTENDANCE_API,
  MARK_LIVE_CLASS_ATTENDANCE_API,
  MARK_ALL_IN_PROGRESS_ATTENDANCE_API,
  EXPORT_LIVE_ATTENDANCE_API,
  COPY_CONTENT_API,
  PATCH_CONTENT_ITEMS_SEQUENCE,
  DELETE_PRODUCTVARIANT_ITEM,
  FETCH_USER_CONTENT_ACTIONS_BY_PRODUCT_VARIANT,
  FETCH_USER_CONTENT_BY_PRODUCT_VARIANT,
} from "../constants/urls";
import APIHelpers from "../helpers/ApiHelpers";
import parser from "ua-parser-js";

export const contentService = {
  fetchContentById,
  fetchUpcomingContent,
  createDirectoryContent,
  setUserContentAction,
  getUserContentAction,
  createLiveClassContent,
  createFlashCardsContent,
  assignTrainersToContent,
  unassignTrainersToContent,
  createRecordedVideosContent,
  createStudyMaterialContent,
  uploadContentFile,
  updateContent,
  fetchAllContent,
  fetchContentTrainers,
  deleteContent,
  editContentSequence,
  getCurrentPlatform,
  getLiveClassContentAttendance,
  fetchLiveClassAttendance,
  fetchContentAttendance,
  fetchContentAttendanceDetail,
  markLiveClassAttendance,
  markAllLiveClassAttendance,
  exportAttendanceReport,
  exportContentAttendanceReport,
  copyContent,
  editProductVariantItemsSequence,
  deleteProductVariantItem,
  fetchUserContentActionsByID,
  getUserContentByProductVariant,
  createAssignmentContent,
  createFreeTextContent,
  createYoutubeContent,
  getQuizResultByProductVariantId
};

async function fetchContentById(id, extraParams = null) {
  store.dispatch("appState/startLoading");
  var queryParams = "";
  if (extraParams) {
    queryParams = "?";
    Object.entries(extraParams).forEach(([key, value], index) => {
      if (index > 0) {
        queryParams += "&" + key + "=" + value;
      } else {
        queryParams += key + "=" + value;
      }
    });
    console.log(queryParams);
  }
  try {
    // if (
    // 	router.currentRoute.name.toLowerCase() == "liveclass" ||
    // 	router.currentRoute.name.toLowerCase() == "coursecontent"
    // ) {
    // 	console.log("current item is live_class", router.currentRoute.name);
    // 	var requestUrl =
    // 		store.state.appState.apiURL +
    // 		FETCH_PUT_POST_CONTENT_API +
    // 		`/${id}` +
    // 		`?Platform=${getCurrentPlatform()}`;
    // }
    let responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL +
      FETCH_PUT_POST_CONTENT_API +
      `/${id}${queryParams}`,
      "GET",
      null,
      false
    ).then(handleResponse);

    console.log("resonse from apihelper", responseBody);
    store.dispatch("appState/stopLoading");
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}


async function getUserContentByProductVariant({productVariantID, dataType = null}) {
  try {
    store.dispatch("appState/startLoading");
    let url = `${store.state.appState.apiURL}${FETCH_USER_CONTENT_BY_PRODUCT_VARIANT}`;

    let PATH = `/${productVariantID}`

    let QUERY = `?`;
    if (dataType !== null && dataType !== undefined) {
      QUERY += `Type=${dataType}`;
    }

   url = url + PATH + QUERY;

    const response = await APIHelpers.makeAPICall(url, "GET", null, false);
    return handleResponse(response);

  } catch (error) {
    console.log("error:", error)
    throw error;

  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchUserContentActionsByID({productVariantID, dataType = null}) {
  try {
    store.dispatch("appState/startLoading");
    let url = `${store.state.appState.apiURL}${FETCH_USER_CONTENT_ACTIONS_BY_PRODUCT_VARIANT}`;

    let PATH = `/${productVariantID}`

    let QUERY = `?`;
    if (dataType !== null && dataType !== undefined) {
      QUERY += `dataType=${dataType}`;
    }

   url = url + PATH + QUERY;

    const response = await APIHelpers.makeAPICall(url, "GET", null, false);
    return handleResponse(response);

  } catch (error) {
    console.log("error:", error)
    throw error;

  } finally {
    store.dispatch("appState/stopLoading");
  }
}


async function getLiveClassContentAttendance({ 
  productVariantID, 
  currentPage = null, 
  pageSize = null 
}) {
  try {
    store.dispatch("appState/startLoading");

    let url = `${store.state.appState.apiURL}${FETCH_POST_LIVE_CLASS_ATTENDANCE_API}?productvariantID=${productVariantID}`;

    if (currentPage !== null) {
      url += `&currentPage=${currentPage}`;
    }

    if (pageSize !== null) {
      url += `&pageSize=${pageSize}`;
    }

    const response = await APIHelpers.makeAPICall(url, "GET", null, false);
    return handleResponse(response);

  } catch (error) {
    console.log("error:", error)
    throw error;

  } finally {
    store.dispatch("appState/stopLoading");
  }
}


async function fetchLiveClassAttendance(contentId, { userId = null}) {
  store.dispatch("appState/startLoading");
  try {
    const baseUrl = store.state.appState.apiURL;
    let queryParams = `pageSize=500&ContentID=${contentId}`;

    if (userId !== null && userId !== undefined) {
      queryParams += `&UserID=${userId}`;
    }

    const url = `${baseUrl}${FETCH_POST_LIVE_CLASS_ATTENDANCE_API}?${queryParams}`;

    let responseBody = await APIHelpers.makeAPICall(url, "GET", null, false)
    .then(handleResponse);

    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function getQuizResultByProductVariantId(id) {
  store.dispatch("appState/startLoading");
  try {
    const baseUrl = store.state.appState.apiURL;
    const url = `${baseUrl}${FETCH_PUT_POST_CONTENT_API}/quiz/${id}`;

    let responseBody = await APIHelpers.makeAPICall(url, "GET", null, false)
    .then(handleResponse);

    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchContentAttendance(contentid,userid,productVariantID) {
  store.dispatch("appState/startLoading");
  console.log(FETCH_CONTENT_ATTENDANCE_API);
  try {
    var url = store.state.appState.apiURL + FETCH_CONTENT_ATTENDANCE_API+"?";

    if(contentid) {
      url += `ContentID=${contentid}&`;
    }
    if(userid) {
      url += `UserID=${userid}&`;
    }
    if(productVariantID) {
      url += `ProductvariantId=${productVariantID}`;
    }
    let responseBody = await APIHelpers.makeAPICall(
      url,
      "GET",
      null,
      false
    ).then(handleResponse);

    // console.log("resonse from apihelper", responseBody);
    store.dispatch("appState/stopLoading");
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function fetchContentAttendanceDetail(contentid,userid) {
  debugger;
  store.dispatch("appState/startLoading");
  try {
    var url = store.state.appState.apiURL + "/Content/Attendance/Detail?";

    if(contentid) {
      url += `ContentID=${contentid}&`;
    }
    if(userid) {
      url += `UserID=${userid}`;
    }
    
    let responseBody = await APIHelpers.makeAPICall(
      url,
      "GET",
      null,
      false
    ).then(handleResponse);

    // console.log("resonse from apihelper", responseBody);
    store.dispatch("appState/stopLoading");
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function markLiveClassAttendance(attendanceData) {
  store.dispatch("appState/startLoading");
  try {
    let responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + MARK_LIVE_CLASS_ATTENDANCE_API,
      "POST",
      JSON.stringify(attendanceData),
      false
    ).then(handleResponse);

    // console.log("resonse from apihelper", responseBody);
    store.dispatch("appState/stopLoading");
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function markAllLiveClassAttendance(attendanceData) {
  store.dispatch("appState/startLoading");
  try {
    let responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + MARK_ALL_IN_PROGRESS_ATTENDANCE_API,
      "POST",
      JSON.stringify(attendanceData),
      false
    ).then(handleResponse);

    // console.log("resonse from apihelper", responseBody);
    store.dispatch("appState/stopLoading");
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function exportAttendanceReport(contentID, productvariantID) {


  const response = await APIHelpers.makeAPICall(
    store.state.appState.apiURL + EXPORT_LIVE_ATTENDANCE_API + "?contentID=" + contentID + "&productvariantID=" + productvariantID,
    "GET",
    null,
    false
  );
  if (!response.ok) {

    return response.json();
  } else {
    return response;
  }
}

async function exportContentAttendanceReport(contentID) {


  const response = await APIHelpers.makeAPICall(
    store.state.appState.apiURL + EXPORT_CONTENT_ATTENDANCE_API + "?contentID=" + contentID,
    "GET",
    null,
    false
  );
  if (!response.ok) {

    return response.json();
  } else {
    return response;
  }
}


async function fetchContentTrainers(id) {
  try {
    const responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_CONTENT_TRAINERS_API + `/${id}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    console.log(responseBody);
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchUpcomingContent(startDate, endDate, productVariantID = null) {
  store.dispatch("appState/startLoading");
  console.log(startDate, endDate);
  try {
    console.log('service fetch', { startDate, endDate, productVariantID })
    var requestQuery = "?";

    if (startDate !== undefined) {
      requestQuery += "StartDate=" + startDate;
    }
    if (startDate === undefined) {
      if (productVariantID !== null) {
        requestQuery += "ProductVariantId=" + productVariantID;
      }
    }
    else {
      if (productVariantID !== null) {
        requestQuery += "&ProductVariantId=" + productVariantID;
      }
    }

    if (endDate !== undefined) {
      requestQuery += "&EndDate=" + endDate;
    }

    // requestQuery = JSON.stringify(requestBody);

    const responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL +
      FETCH_PUT_POST_CONTENT_API +
      `/upcoming` +
      requestQuery,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    console.log(responseBody);
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchAllContent() {
  store.dispatch("appState/startLoading");
  try {
    const responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    console.log(responseBody);
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createDirectoryContent(content) {
  store.dispatch("appState/startLoading");
  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function setUserContentAction(action) {
  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_USER_CONTENT_ACTION_API,
      "POST",
      JSON.stringify(action),
      false
    ).then(handleResponse);
    return body;
  } finally {
  }
}
async function getUserContentAction(id=null, contentId=null, dataType=null) {
  try {
   var filter=[];
   if(id)
   {
    filter.push("id="+id);
   }

   if(contentId)
   {
    filter.push("contentId="+contentId);
   }
   if(dataType)
   {
    filter.push("dataType="+dataType);
   }

    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_USER_CONTENT_ACTION_API+"?"+filter.join("&"),
      "GET",null,
      false
    ).then(handleResponse);
    return body;
  } finally {
  }
}
async function createLiveClassContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createFlashCardsContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function updateContent(content) {
  store.dispatch("appState/startLoading");
  console.log("updateContent making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      // + `?Platform=${getCurrentPlatform()}`,
      "PUT",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createRecordedVideosContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createStudyMaterialContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function createAssignmentContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function uploadContentFile(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", ...content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + POST_FILE_API,
      // "https://webhook.site/ad34a709-9a01-43e9-996c-32b746d13418",
      "POST",
      content,
      false,
      null
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function assignTrainersToContent(content) {
  store.dispatch("appState/startLoading");
  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_CONTENT_TRAINERS_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return content;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function unassignTrainersToContent(content) {
  store.dispatch("appState/startLoading");
  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_CONTENT_TRAINERS_API,
      "DELETE",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return content;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function deleteContent(id) {
  store.dispatch("appState/startLoading");
  try {
    const body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API + `/${id}`,
      "DELETE",
      null,
      false
    );
    // .then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function deleteProductVariantItem(content) {
  store.dispatch("appState/startLoading");
  try {
    const body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + DELETE_PRODUCTVARIANT_ITEM,
      "DELETE",
      JSON.stringify(content),
      false
    );
    // .then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function editContentSequence({ content, id }) {
  store.dispatch("appState/startLoading");
  try {
    const body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + PATCH_CONTENT_SEQUENCE + `/${id}`,
      "PATCH",
      JSON.stringify(content),
      false
    );
    // return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function editProductVariantItemsSequence({
  content,
  productVariantItemID,
}) {
  content.productVariantItemID = productVariantItemID;
  console.log({ content });
  store.dispatch("appState/startLoading");
  try {
    const body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + PATCH_CONTENT_ITEMS_SEQUENCE,
      "PATCH",
      JSON.stringify(content),
      false
    );

    store.dispatch("")
    // return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}


async function createFreeTextContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createYoutubeContent(content) {
  store.dispatch("appState/startLoading");
  console.log("making api call", content);

  try {
    let body = await APIHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
      "POST",
      JSON.stringify(content),
      false
    ).then(handleResponse);
    console.log(body);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function copyContent(content) {
  store.dispatch("appState/startLoading");
  try {
    const responseBody = await APIHelpers.makeAPICall(
      store.state.appState.apiURL +
      COPY_CONTENT_API +
      `?ContentID=${content.content.id}&DestinationContentID=${content.destinationContentID}`,
      "POST",
      null,
      false
    ).then(handleResponse);
    console.log({ responseBody });
    return responseBody;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (response.status == 500) return response;
  if (!response.ok) {
    var res = await response
      .json()
      .then(
        (data) =>
        (res = {
          error: data.error,
        })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    throw new Error(res.error.errorCode);
  } else {
    return response.json();
  }
}

function getCurrentPlatform() {
  var ua = parser(navigator.userAgent);
  var platform =
    ua.os.name == "Windows" && ua.device.type == undefined
      ? "Desktop_Web"
      : ua.device.type == "mobile" && ua.os.name == "Android"
        ? "Mobile-Web"
        : "Desktop";
  return platform;
}
