<template>
  <div v-if="!isAuthenticationProcessed" class="text-center p-5 " style="width: 100%; position: absolute;">
    <img src="../../../assets/images/zoom-logo.png"/>
    <h2 class="mb-3 mt-3">Connect Zoom Account</h2>
   
    <center>
      <h6 style="text-align: left; max-width: 500px;" class="mb-3">   To connect Zoom account, log into your Zoom Developer Account and create a new server to server OAuth app. Once created, locate the "Client ID", "Client Secret", "Secret Token" and "Account ID" within the app settings. And allowed neccessary scopes.
        Follow these steps while referring to this <a href="https://lms-files.techsol360.com/LMS_Zoom_Account_Integration_Process.pdf">tutorial document</a>
      </h6>
      <div style="max-width: 350px;">
        <input
          v-model="zoomOAuthObj.email"
          autocomplete="off"
          type="search"
          placeholder="Enter Zoom Email"
          class="search-input mt-3"
        />
        <input
          v-model="zoomOAuthObj.accountId"
          autocomplete="off"
          type="search"
          placeholder="Enter Zoom AccountId"
          class="search-input mt-3"
        />

        <input
          v-model="zoomOAuthObj.clientId"
          autocomplete="off"
          type="search"
          placeholder="Enter Zoom Client ID"
          class="search-input mt-3"
        />
        <input
          v-model="zoomOAuthObj.clientSecret"
          autocomplete="off"
          type="search"
          placeholder="Enter Zoom Client Secret"
          class="search-input mt-3"
        />
        <input
          v-model="zoomOAuthObj.secretToken"
          autocomplete="off"
          type="search"
          placeholder="Enter Zoom Event Secret Token"
          class="search-input mt-3 mb-3"
        />
        <p v-if="error" class="text-danger mb-2">{{ error }}</p>
        <button @click="connectServerToServerOAuth" class="connect-btn step-btn mb-3">Connect</button>
        <!-- <h6>Or</h6>
        <button @click="makeOAuthServiceConnection" class="step-btn mb-3"> 
          <i  style="color: #2D8CFF; font-weight: bold;" class="fa fa-video"></i>
           Continue With <span style="color: #2D8CFF; font-weight: bold;"> Zoom </span></button> -->
      </div>
    </center>
  </div>
  <div v-else-if="isAuthenticationProcessed" class="ms-5 pt-5">
    <img src="../../../assets/images/zoom-logo.png"/>
    <h2 class="pb-5 mt-1">Account Connecting<br/><small>Redirecting...</small></h2>
      <h5 v-if="message">{{ message }}</h5>
      <h5 v-if="error" class="text-danger">{{ error }}</h5>
    
  </div>
  
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ZoomOAuthService",
  data() {
    return {
      message: undefined,
      error: undefined,
      isRedirected:false,
      isAuthenticationProcessed:false,
      zoomOAuthObj:{
        type:"s2s",
        clientId:"",
        clientSecret:"",
        accountId:"",
        secretToken:"",
        email:null
      }
    };
  },
  computed: {
    
  },
  methods: {
		...mapActions("appState", ["createExternalServices"]),	
    makeOAuthServiceConnection(){
      this.error=null;
      this.isAuthenticationProcessed=true;
      this.createExternalServices({ type: "liveclass", provider: "zoom", data: { redirecturl: `${window.location.origin}/service/zoom` } }).then((response) => {
        if (response && response.payload && response.payload.redirectUrl) {
          if (this.isRedirected == false) {
            this.isRedirected = true;
            window.location.href = response.payload.redirectUrl;

          }
        } else {
          this.error = "Some error occurred. ERR_CODE: 001."
          setTimeout(function(){
            window.close();
          },1000);
        }
      });
    },
    connectServerToServerOAuth(){
      this.error=null;
      if(this.zoomOAuthObj && 
      this.zoomOAuthObj.email && 
      this.zoomOAuthObj.clientId && 
      this.zoomOAuthObj.clientSecret && 
      this.zoomOAuthObj.secretToken && 
      this.zoomOAuthObj.accountId)
      {
        
        this.isAuthenticationProcessed=true;
        this.createExternalServices({ type: "liveclass", provider: "zoom", data: this.zoomOAuthObj}).then((response) => {
          if (response && response.error && response.error.message) {
            this.isAuthenticationProcessed = false;
            this.error = response.error.message;
            return;
          }

          if (response) {     
            if (window.opener.beforeCloseCallback) {
              window.opener.beforeCloseCallback();
            }
            window.close();
          } else {
            this.isAuthenticationProcessed=false;
            this.error = "Some error occured. ERR_CODE: 002";
          }

        }).catch((response) => {
            this.isAuthenticationProcessed=false;
            this.error = "Some error cccured. ERR_CODE: 003";
        });
        
      }else{
        this.error="Please fill all the fields";
      }
    }
  },
  created() {
    let action = this.$route.query.action;
    if (action && action.toLowerCase() == "postservice") {
      this.isAuthenticationProcessed=true;
      let status = this.$route.query.status;
      if(status.toLowerCase()=="success")
      {
        if(window.opener.beforeCloseCallback)
        {
          window.opener.beforeCloseCallback();
        }
        window.close();
      }else
      {
        this.error==this.$route.query.message??"Some Error Occured";        
      }
    }
    else {
      //this.makeOAuthServiceConnection();
    }
  }
}
</script>

<style scoped>
.search-input {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	color: #000000;
	font-size: 1.5rem;
	column-gap: 0.75rem;
	position: relative;
	outline: none;
}
.connect-btn{
  background-color: #2D8CFF;
  border: #2D8CFF;
  color: #ffffff;
  font-size: small;

}
.step-btn{
  width: 100%;  
}
</style>