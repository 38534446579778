<template>
	<div class="container">
		<div class="after-instructor-div">
			<!-- ; margin-top: 7rem -->
			<div style="margin-left: 3rem" class="setFlexOrder">
				<img
					src="https://gocbe-lms-files.b-cdn.net/edcentra/student-rating-image.svg"
					style="height: 20px; margin-bottom: 1rem"
				/>
				<p style="font-size: 40px; font-size: 40px">
					<strong
						>Extremely satisfied with the<br />
						quality of Education</strong
					>
				</p>
				<p style="color: #575757; font-size: 1.15rem">
					GoCBE has been a source of intellectual<br />
					enrichmentand practical professional<br />
					development for me.
				</p>
				<p class="studentName">Nimra Shah</p>
				<p style="display: inline-block; margin-left: 5px">Student</p>
			</div>
			<div class="backgroundStudentImage">
				<img
					src="https://gocbe-lms-files.b-cdn.net/edcentra/student-block-image.png"
					class="student-image"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomepageTestimonials",
};
</script>

<style></style>
