<template>
	<tr class="d-flex">
		<td
			v-if="arePaymentsAdded"
			:class="['col-1', { shrink: arePaymentsAdded }]"
		>
			<input
				type="checkbox"
				name="select_1"
				:id="invoiceDetail.id"
				@click="toggleInvoiceSelect"
				:checked="isChecked(invoiceDetail.id)"
				:disabled="disableDetailItem"
				:title="
					disableDetailItem && mode == 'pay'
						? 'Already Paid!'
						: disableDetailItem && mode == 'refund'
						? 'No amount to refund!'
						: ''
				"
				v-b-tooltip.hover
			/>
		</td>
		<td :class="['col-1', { shrink: arePaymentsAdded || mode == 'edit' }]">
			{{ invoiceDetail.id }}
		</td>
		<td class="col">{{ invoicedProductVariant }}</td>
		<td class="col" v-if="mode == 'view'">
			{{ invoiceDetail.planName }}
			{{
				invoiceDetail.planDetailTitle != invoiceDetail.planName
					? " (" + invoiceDetail.planDetailTitle + ")"
					: ""
			}}
		</td>
		<!-- <td class="col">{{ dateParser(invoiceDetail.dueDate) }}</td> -->
		<td class="col">
			{{ currencyParser(invoiceDetail.amount, currency) }}
		</td>
		<td class="col">
			<input
				v-if="mode == 'edit'"
				placeholder="Enter due date"
				type="date"
				id="dueDate"
				:class="['form-input', { 'text-danger': isError }]"
				:disabled="isDisabled(invoiceDetail.id)"
				v-model="dueDate"
			/>
			<span v-else>
				{{ dateParser(invoiceDetail.dueDate) }}
			</span>
		</td>
		<td class="col" v-if="arePaymentsAdded">
			<input
				placeholder="Enter amount"
				type="search"
				id="outstanding_amount"
				:class="['form-input', { 'text-danger': isError }]"
				disabled
				:value="outstandingAmount"
			/>
		</td>
		<td class="col" v-if="mode != 'edit'">
			<input
				v-if="arePaymentsAdded"
				placeholder="Enter amount"
				type="number"
				id="paid_amount"
				:class="['form-input', { 'text-danger': isError }]"
				disabled
				:value="paidAmount"
			/>
			<span v-else>{{
				currencyParser(invoiceDetail.paidAmount, currency)
			}}</span>
		</td>
		<td class="col">
			<div v-if="mode == 'edit' || mode == 'pay'" class="d-flex">
				<input
					placeholder="Enter amount"
					type="number"
					id="discount"
					:class="['form-input', { 'text-danger': isError }]"
					:disabled="isDisabled(invoiceDetail.id)"
					v-model="discountAmount"
					@change="validateAmount"
				/>
				<v-select
					:title="discountType + ` (Click to change)`"
					v-b-tooltip.hover
					class="form-input col"
					placeholder="Select discount type"
					v-model="discountType"
					name="discountType"
					id="discountType"
					:options="discountTypes"
					:autoscroll="true"
					:disabled="isDisabled(invoiceDetail.id)"
					:reduce="(option) => option.value"
					:clearable="false"
				></v-select>
			</div>
			<span v-else>
				{{
					invoiceDetail.discount
						? invoiceDetail.discountType.toLowerCase() == "amount"
							? currencyParser(invoiceDetail.discount, currency)
							: discountAmount + "%"
						: "-"
				}}
			</span>
		</td>
		<!-- <td class="col" v-if="mode == 'edit' || mode == 'pay'">
			<v-select
				class="form-input"
				placeholder="Select discount type"
				v-model="discountType"
				name="discountType"
				id="discountType"
				:options="discountTypes"
				:autoscroll="true"
				:disabled="isDisabled(invoiceDetail.id)"
				:reduce="(option) => option.value"
			></v-select>
		</td> -->
		<td v-if="!arePaymentsAdded && mode != 'edit'" class="col">
			<div
				:class="[
					'status-tag',
					{
						success: invoiceDetail.status == InvoiceStatus.PAID,
						danger:
							invoiceDetail.status == InvoiceStatus.UNPAID ||
							invoiceDetail.status == InvoiceStatus.OVER_PAID,
						warning: invoiceDetail.status == InvoiceStatus.PARTIALLY_PAID,
					},
				]"
			>
				{{ invoiceDetail.status }}
			</div>
		</td>
		<td class="col" v-if="arePaymentsAdded">
			<input
				placeholder="Enter amount (required)"
				type="number"
				id="payment_amount"
				:class="['form-input', { 'text-danger': error }]"
				:disabled="isDisabled(invoiceDetail.id)"
				v-model="paymentAmount"
				@keydown="handleNumberInput"
			/>
		</td>
		<td><img v-if="!isAnyPaymentAddedForCurrentInvoiceDetail" src="@/assets/images/delete-icon.svg" class="add-btn-icon cursor-pointer" @click="removeInvoiceDetail"></td>
	</tr>
</template>

<script>
import formatter from "./../../../helpers/FormatHelpers";
import { mapActions, mapState } from "vuex";
import { createPopper } from "@popperjs/core";
import { InvoiceStatus } from "../../../constants/pricing";
export default {
	name: "InvoiceDetailItem",
	data() {
		return {
			paymentAmount: null,
			paidAmount: 0,
			discountAmount: 0,
			discountType: "Amount",
			discountTypes: [
				{ value: "Amount", label: "💵" },
				{ value: "Percentage", label: "%" },
			],
			dueDate: null,
			InvoiceStatus,
		};
	},
	props: {
		invoiceDetail: Object,
		selectedDetails: Array,
		arePaymentsAdded: Boolean,
		currency: String,
		mode: String,
		error: Boolean,
		invoiceId: Number,
		userId: String
	},
	computed: {
		...mapState("pricing", ["paymentsByUserId"]),
		...mapState("productVariant", ["productVariants"]),
		outstandingAmount() {
			var balance;
			if (this.mode == "pay")
				balance =
					this.invoiceDetail.amount -
					this.discountRawValue -
					this.paidAmount -
					(this.paymentAmount ? parseInt(this.paymentAmount) : 0);
			else if (this.mode == "refund")
				balance =
					this.invoiceDetail.amount -
					this.discountRawValue -
					this.paidAmount +
					(this.paymentAmount ? parseInt(this.paymentAmount) : 0);
			else balance = 0;
			return balance >= 0 && balance <= this.invoiceDetail.amount
				? balance
				: "Invalid amount";
		},
		isError() {
			return this.outstandingAmount == "Invalid amount";
		},
		invoicedProductVariant() {
			if(this.productVariants.items) {
				return (
					this.productVariants.items[this.invoiceDetail.productSourceID].product
						.title +
					" " +
					this.productVariants.items[this.invoiceDetail.productSourceID].title
				);
			}
		},
		disableDetailItem() {
			if (this.mode == "pay")
				return this.invoiceDetail.paidAmount == this.invoiceDetail.amount;
			else if (this.mode == "refund") return this.invoiceDetail.paidAmount == 0;
		},
		discountRawValue() {
			if (
				this.invoiceDetail.discountType &&
				this.invoiceDetail.discountType.toLowerCase() == "amount"
			)
				return this.discountAmount;
			else if (
				this.invoiceDetail.discountType &&
				this.invoiceDetail.discountType.toLowerCase() == "percentage"
			)
				return this.discountAmount * 0.01 * this.invoiceDetail.amount;
			else return 0;
		},
		isAnyPaymentAddedForCurrentInvoiceDetail() {			
			return this.invoiceDetail.status != InvoiceStatus.UNPAID;
		}
	},
	methods: {
		...mapActions("pricing", ["fetchPaymentsByUserId", "deleteInvoiceDetail"]),
		toggleInvoiceSelect() {
			this.$emit("toggleSelect", this.invoiceDetail);
		},
		isChecked(id) {
			var found = this.selectedDetails.find((invoice) => invoice.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		currencyParser(amount, currency) {
			return formatter.toCurrencyString(parseInt(amount), currency, 0);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		isDisabled(id) {
			var found = this.selectedDetails.find((d) => d.id == id);
			return !found;
		},
		handleNumberInput(e) {
			if (e.keyCode == 189) {
				// console.log("- pressed", this.paymentAmount);
				this.paymentAmount = "";
			}
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		validateAmount(e) {
			if (e.target.value < 0) {
				this.$emit("updateError", true, "Enter valid discount amount");
			} else {
				this.$emit("updateError", false, "");
			}
		},
		removeInvoiceDetail(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete line item?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {

						console.log({ userId: this.userId, invoiceId: this.invoiceId, invoiceDetailId: this.invoiceDetail.id });

						this.deleteInvoiceDetail({ userId: this.userId, invoiceId: this.invoiceId, invoiceDetailId: this.invoiceDetail.id });
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		}
	},
	created() {
		if (this.invoiceDetail.discount) {
			this.discountAmount =
				this.invoiceDetail.discountType.toLowerCase() == "amount"
					? parseFloat(this.invoiceDetail.discount)
					: parseFloat(this.invoiceDetail.discount) * 100;
			this.discountType = this.invoiceDetail.discountType;
		}
		this.paidAmount = this.invoiceDetail.paidAmount;
		this.dueDate = formatter.getInputDateFormat(
			this.invoiceDetail.dueDate,
			false
		);
		// this.discountAmount = this.invoiceDetail.discount;
	},
	watch: {
		paymentAmount: function () {
			if (this.mode != "pay" && this.mode != "refund") return;
			if (!this.paymentAmount) {
				this.$emit(
					"updateError",
					true,
					"Enter current payment and/or discount to pay"
				);
				return;
			}
			if (parseInt(this.paymentAmount) < 0) {
				this.$emit("updateError", true, "Please enter a valid payment amount");
				return;
			}
			if (this.error) this.$emit("updateError", false, "");
			this.$emit("updateAmount", this.paymentAmount, this.invoiceDetail.id);
			this.$emit("updateError", this.isError, "");
		},
		isError: function () {
			this.$emit("updateError", this.isError, "");
		},
		discountAmount: function () {
			this.$emit(
				"updateDiscount",
				this.discountType == "Amount"
					? parseFloat(this.discountAmount)
					: parseFloat(this.discountAmount) * 0.01,
				this.discountType,
				this.invoiceDetail.id
			);
		},
		discountType: function () {
			this.$emit(
				"updateDiscount",
				this.discountType == "Amount"
					? parseFloat(this.discountAmount)
					: parseFloat(this.discountAmount) * 0.01,
				this.discountType,
				this.invoiceDetail.id
			);
		},
		mode: function () {
			if (this.mode == "edit") this.toggleInvoiceSelect();
		},
		dueDate: function () {
			this.$emit("updateDueDate", this.dueDate, this.invoiceDetail.id);
		},
		selectedDetails: function () {
			if (this.mode != "pay" && this.mode != "refund") return;
			if (this.selectedDetails.length > 0 && !this.paymentAmount) {
				this.$emit(
					"updateError",
					true,
					"Enter current payment and/or discount to pay"
				);
				return;
			}
			this.$emit("updateError", false, "");
		},
	},
};
</script>

<style>
td.shrink {
	width: fit-content !important;
	padding-right: 1rem;
}

#discountType .vs__actions {
	display: none !important;
}
</style>
