<template>
	
	<div v-if="selectedProductVariant && (isEbookDigitalTheme ? true : !contentId ) && selectedProductVariant.productVariantItems && hasContent()">
		<div  class="eb-header d-flex mb-3">
			<!-- <h5>
				<i class="fas fa-align-left"></i>{{ selectedProductVariant.productVariantItems.length }} Chapters
			</h5> -->
			<!-- <h5>
				<i class="far fa-clock"></i> 2500 Pages
			</h5>			
			 -->
			<div style="flex-grow: 2;" class="eb-chapters-search">
				<input v-model="filters.searchText" @input="handleInput" type="text" class="form-control" id=""
					placeholder="Search">
			</div>
			<button class="eb-filter-toggle dropdown-toggle ms-auto" data-bs-toggle="collapse" data-bs-target="#ebFilters"
            aria-expanded="false" aria-controls="ebFilters">
            <img src="../../assets/images/filter-icon.svg">
            Filter
        </button>

		</div>

		<div class="eb-filters collapse" id="ebFilters">
			<div class="eb-filter-area">
				<div class="row g-3 eb-filters">
					<div class="col-md-5">
						<label class="form-label d-block">Understanding Level</label>
						<div class="btn-group confidence-group w-100" role="group"
							aria-label="Basic radio toggle button group">

							<input type="checkbox" @click="setOrRemoveFilter('unrated', 'confidence_level')"
								class="btn-check" name="confidenceLevel" id="confidenceLevelUnrated" autocomplete="off"
								:checked="isFilterSelected('unrated', 'confidence_level')">
							<label class="btn btn-outline-primary" for="confidenceLevelUnrated">Unrated</label>


							<input type="checkbox" @click="setOrRemoveFilter('low', 'confidence_level')" class="btn-check"
								name="confidenceLevel" id="confidenceLevelLow" autocomplete="off"
								:checked="isFilterSelected('low', 'confidence_level')">
							<label class="btn btn-outline-primary" for="confidenceLevelLow">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="16.38" height="16.444" viewBox="0 0 16.38 16.444">
									<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
										<path id="" data-name="Path 8048"
											d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
											transform="translate(1.016 1.015)" fill="none" stroke="#707070"
											stroke-width="1.5" />
									</g>
								</svg>
								<span>Low</span>
							</label>


							<input type="checkbox" @click="setOrRemoveFilter('medium', 'confidence_level')"
								class="btn-check" name="confidenceLevel" id="confidenceLevelMedium" autocomplete="off"
								:checked="isFilterSelected('medium', 'confidence_level')">
							<label class="btn btn-outline-primary" for="confidenceLevelMedium">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="16.38" height="16.444" viewBox="0 0 16.38 16.444">
									<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
										<path id="" data-name="Path 8048"
											d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
											transform="translate(1.016 1.015)" fill="none" stroke="#707070"
											stroke-width="1.5" />
									</g>
								</svg>
								<span>Medium</span>
							</label>


							<input type="checkbox" @click="setOrRemoveFilter('high', 'confidence_level')" class="btn-check"
								name="confidenceLevel" id="confidenceLevelHigh" autocomplete="off"
								:checked="isFilterSelected('high', 'confidence_level')">
							<label class="btn btn-outline-primary" for="confidenceLevelHigh">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="16.38" height="16.444" viewBox="0 0 16.38 16.444">
									<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
										<path id="" data-name="Path 8048"
											d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
											transform="translate(1.016 1.015)" fill="none" stroke="#707070"
											stroke-width="1.5" />
									</g>
								</svg>
								<span>High</span>
							</label>
						</div>
					</div>

					<div class="col-md-4">
						<label class="form-label d-block">Status</label>
						<div class="btn-group confidence-group status-group w-100" role="group"
							aria-label="Basic radio toggle button group">

							<input type="checkbox" @click="setOrRemoveFilter('not_started', 'completionStatus')"
								class="btn-check" name="completionStatus" id="statusNotStarted" autocomplete="off"
								:checked="isFilterSelected('not_started', 'completionStatus')">
							<label class="btn btn-outline-primary" for="statusNotStarted">
								<img src="../../assets/images/not-started-icon.svg">
								<span>Not Completed</span>
							</label>

							<!-- 
							<input type="checkbox" @click="setOrRemoveFilter('in_progress', 'completionStatus')"
								class="btn-check" name="completionStatus" id="statusInProgress" autocomplete="off"
								:checked="isFilterSelected('in_progress', 'completionStatus')">
							<label class="btn btn-outline-primary" for="statusInProgress">
								<img src="../../assets/images/in-progress-icon.svg">
								<span>In Progress</span>
							</label> -->


							<input type="checkbox" @click="setOrRemoveFilter('completed', 'completionStatus')"
								class="btn-check" name="completionStatus" id="statusCompleted" autocomplete="off"
								:checked="isFilterSelected('completed', 'completionStatus')">
							<label class="btn btn-outline-primary" for="statusCompleted">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
									width="16.38" height="16.444" viewBox="0 0 16.38 16.444">
									<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
										<path id="" data-name="Path 8048"
											d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
											transform="translate(1.016 1.015)" fill="none" stroke="#707070"
											stroke-width="1.5" />
									</g>
								</svg>
								<span>Completed</span>
							</label>
						</div>
					</div>

					<div class="col-md-2">
						<div class="form-check form-check-inline filter-bookmark">

							<input class="form-check-input bookmark-checkbox"
								@click="setOrRemoveFilter('bookmark', 'bookmark_content')" type="checkbox"
								id="bookmarkCheckbox" value="" :checked="isFilterSelected('bookmark', 'bookmark_content')">
							<label class="form-check-label btn btn-outline-primary" for="bookmarkCheckbox">
								<i class="fa me-2 fa-bookmark-o"></i>
								<span>Bookmarks</span>
								<span class="check ms-auto">
									<img src="../../assets/images/check-green-icon.svg">
								</span>
							</label>

						</div>
					</div>

					<div class="col-md-1 pt-md-4">
						<button @click="resetFilters()" class="btn btn-link">Reset Filter</button>
					</div>

					<div class="col-12" style="display: none;">
						<button type="submit" class="btn btn-primary me-1">Filter</button>
						<button type="submit" class="btn btn-outline-primary">Clear Filters</button>
					</div>
				</div>
			</div>
		</div><!-- eb-filters -->

		<!-- <div class="accordion eb-content" role="tablist">
			<b-card no-body class="accordion-item eb-chapter-toggle"  v-for="pvItem in selectedProductVariant.productVariantItems" >
			  <b-card-header header-tag="header" class="accordion-header eb-toggle-header" role="tab" @click="selectChapter(pvItem.id)" >
				<b-button block v-b-toggle="pvItem.id" class="accordion-button">{{ pvItem.title }}</b-button>
			  </b-card-header>
			  <b-collapse :id="pvItem.id" class="accordion-collapse eb-toggle-body" :visible="(pvItem.id+'')==(selectedChapterID+'')" accordion="my-accordion" role="tabpanel">
				<b-card-body class="accordion-body">
					<div v-if="content[pvItem.id]">
					<ProductContentChaptersContentList></ProductContentChaptersContentList>
					</div>
				</b-card-body>
			  </b-collapse>
			</b-card>
		</div> -->
		<div class="eb-content">
			<div class="accordion eb-accordion" id="tableOfContents" v-if="selectedProductVariant.productVariantItems">
				<div  v-for="pvItem in selectedProductVariant.productVariantItems" v-if="contentUnderChapterCount(pvItem.id)>0" class="accordion-item eb-chapter-toggle">
					<b-card-header header-tag="header" class="accordion-header eb-toggle-header" role="tab">
					  <b-button @click="selectChapter(pvItem.id)" block v-b-toggle="'accordion-'+pvItem.id" variant="none" class="accordion-button"><span class="head-text" style="
						flex:1;
						">{{ pvItem.title }}
											 </span> <span class="side-text" v-if="completedContentUnderChapterCount(pvItem.id)<contentUnderChapterCount(pvItem.id)" >{{completedContentUnderChapterCount(pvItem.id)}} out of {{ contentUnderChapterCount(pvItem.id) }} Activities</span>
											 <div class="completed-alert d-flex align-items-center"
							v-else>
							<div class="btn btn-primary btn-sm mark-complete-btn chapter completed me-3">
								<img src="../../assets/images/check-green-circle-icon.svg" width="12" height="12" />
								 Completed
							</div>
						</div>
											</b-button>
					</b-card-header>
					<b-collapse :id="'accordion-'+pvItem.id" :class="'accordion-collapse eb-toggle-body'" v-model="accordionState[pvItem.id]"  :visible="true"  :accordion="'accordion-'+pvItem.id" role="tabpanel">
						<div class="accordion-body" >
							<ProductContentChaptersContentList :filters="filters"  :chapterID="`${pvItem.id}`" />
						</div>
					</b-collapse>
				</div>

			</div>

		</div>
	</div>
	<div v-else-if="selectedProductVariant && contentId">
		<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
	  />
		<div class="eb-content chapter-details">
			<div class="cd-head d-flex">
				<div class="cd-chapter-selection">

					<b-tooltip target="vue-treeselect_sv" v-if="selectedContent" triggers="hover">{{selectedContent.label}}</b-tooltip>
					<treeselect id="vue-treeselect_sv" v-if="selectedContent"   class="chapter-dd-btn" v-model="contentId" :disable-branch-nodes="true"
						:load-options="loadChapterOptions" :multiple="false"
						:options="options"
						:searchable="true"
						
						nested-search
						:flatten-search-results="true">
						<label slot="option-label"
							slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
							:class="labelClassName">
							<span :class="(node.isBranch ? 'chapter '
								: 'content '
							)
								">
								<img v-if="!node.isBranch && node.raw.type == ContentType.RECORDED_VIDEOS"
									class="option-icon" src="../../assets/images/recorded-lecture.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.LIVE_CLASS"
									class="option-icon" src="../../assets/images/zoom-icon.svg">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.STUDY_MATERIAL"
									class="option-icon" src="../../assets/images/study-material.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.EXAM" class="option-icon"
									src="../../assets/images/exam-icon.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.FLASHCARDS"
									class="option-icon" src="../../assets/images/flash-card.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.ASSIGNMENT" class="option-icon"
									src="../../assets/images/assignment.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.QUIZ" class="option-icon"
									src="../../assets/images/exam-icon.png">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.FREETEXT" class="option-icon"
									src="../../assets/images/freeText.png">
								<!-- <img v-else-if="!node.isBranch && node.raw.type == ContentType.RECORDED_VIDEOS" class="option-icon" src="../../assets/images/video-icon.svg">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.RECORDED_VIDEOS" class="option-icon" src="../../assets/images/video-icon.svg">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.RECORDED_VIDEOS" class="option-icon" src="../../assets/images/video-icon.svg">
								<img v-else-if="!node.isBranch && node.raw.type == ContentType.RECORDED_VIDEOS" class="option-icon" src="../../assets/images/video-icon.svg"> -->
								&nbsp;&nbsp;{{ upper(node.label) }}</span>
						</label>
					</treeselect>
					<!-- <a class="btn chapter-dd-btn dropdown-toggle" href="#" id="chapterDropdownBtn" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
					  <img src="../../assets/images/list-icon.svg"> 
					  <span>1.1 Recognition Criteria</span>
					</a>
				   -->
					<!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
					  <li><a class="dropdown-item" href="#">Action</a></li>
					  <li><a class="dropdown-item" href="#">Another action</a></li>
					  <li><a class="dropdown-item" href="#">Something else here</a></li>
					</ul> -->
					<!-- <div class="dropdown-menu p-3 text-muted">  
						<ul class="px-0">
						  <li><a class="dropdown-item" href="#">Business Reporting: Valid for exams Sept 2022</a></li>                                    
						  <li><a class="dropdown-item" href="#">1.1.6 Compliance With Accounting Standards</a></li>
						  <li><a class="dropdown-item" href="#">Employability and Technology Skills</a></li>
						  <li><a class="dropdown-item" href="#">1.1.4 The Accountant as An Individual</a></li>
						  <li><a class="dropdown-item" href="#">Strategic Business Reporting: Valid for exams Sept 2022 - June 2023</a></li>
						  <li><a class="dropdown-item" href="#">2.2.6 Compliance With Accounting Standards</a></li>
						  <li><a class="dropdown-item" href="#">Employability and Technology Skills</a></li>
						  <li><a class="dropdown-item" href="#">1.1.4 The Accountant as An Individual</a></li>
						  
						  
						   
						</ul>
					</div> -->



				</div>

				
				<!-- <div  class="form-check assignment-attachments mb-0 ms-auto d-flex align-items-center px-2 px-md-4 py-1 show-attachments"
				id="instructorAttachmentsBtn"
				@click="showAttachments()"
				ref="showAttachmentsBtn"
				>
					<img class="attachment-icon me-2" src="@/assets/images/attachment-icon.svg" />
					<img class="attachment-close-icon me-2" src="@/assets/images/cancel-icon.svg" />
					<span>Attachments</span> 
				</div> -->

				<div class="form-check confidence-levels mb-0 ms-auto d-md-flex d-none align-items-center pe-2 py-1" >
					<div class="me-2 pe-2">Rating:</div>
					<div :class="'rating-option pe-1 animate__animated '+(confidenceLevel[contentId] == 'High'?'animate__bounceIn':'')">
						<input class="form-check-input high" type="radio" name="confidenceBtn"
							:checked="confidenceLevel[contentId] == 'High'" id="confidenceBtn1" value="high"
							@click="setContentConfidenceLevel('High')">
						<label class="confidence-btn btn-high" for="confidenceBtn1">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.38"
								height="16.444" viewBox="0 0 16.38 16.444">
								<defs>
									<clipPath id="clip-path">
										<rect id="Rectangle_499" data-name="Rectangle 499" width="16.38" height="16.444"
											fill="none" stroke="#707070" stroke-width="1.5" />
									</clipPath>
								</defs>
								<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
									<path id="" data-name="Path 8048"
										d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
										transform="translate(1.016 1.015)" fill="none" stroke="#707070"
										stroke-width="1.5" />
								</g>
							</svg>
							High
						</label>
					</div>

					<div :class="'rating-option pe-1 animate__animated '+(confidenceLevel[contentId] == 'Medium'?'animate__bounceIn':'')">
						<input class="form-check-input medium" type="radio" name="confidenceBtn"
							:checked="confidenceLevel[contentId] == 'Medium'" id="confidenceBtn2" value="medium"
							@click="setContentConfidenceLevel('Medium')">
						<label class="confidence-btn btn-medium " for="confidenceBtn2">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.38"
								height="16.444" viewBox="0 0 16.38 16.444">
								<defs>
									<clipPath id="clip-path">
										<rect id="Rectangle_499" data-name="Rectangle 499" width="16.38" height="16.444"
											fill="none" stroke="#707070" stroke-width="1.5" />
									</clipPath>
								</defs>
								<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
									<path id="" data-name="Path 8048"
										d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
										transform="translate(1.016 1.015)" fill="none" stroke="#707070"
										stroke-width="1.5" />
								</g>
							</svg>
							Medium
						</label>
					</div>

					<div :class="'rating-option pe-1 animate__animated '+(confidenceLevel[contentId] == 'Low'?'animate__bounceIn':'')">
						<input class="form-check-input low" type="radio" name="confidenceBtn"
							:checked="confidenceLevel[contentId] == 'Low'" id="confidenceBtn3" value="low"
							@click="setContentConfidenceLevel('Low')">
						<label class="confidence-btn btn-low" for="confidenceBtn3">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.38"
								height="16.444" viewBox="0 0 16.38 16.444">
								<defs>
									<clipPath id="clip-path">
										<rect id="Rectangle_499" data-name="Rectangle 499" width="16.38" height="16.444"
											fill="none" stroke="#707070" stroke-width="1.5" />
									</clipPath>
								</defs>
								<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
									<path id="" data-name="Path 8048"
										d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
										transform="translate(1.016 1.015)" fill="none" stroke="#707070"
										stroke-width="1.5" />
								</g>
							</svg>
							Low
						</label>
					</div>

				</div>

			</div><!-- /cd-head -->

			<div class="cd-body">

				<ProductContentView></ProductContentView>



			</div>

			<div class="cd-footer" v-if="selectedContent">
				<div class="px-3 py-1 d-flex align-items-center h-100" v-if="!loading">
					
					<div class=""
						v-if="content[contentId] && (content[contentId].type.toLowerCase() != 'recordedvideos' || !content[contentId].externalService || content[contentId].externalService.serviceProvider.toLowerCase()!='vss')  && content[contentId].type.toLowerCase() != 'assignment' && content[contentId].type.toLowerCase() != 'liveclass' && content[contentId].type.toLowerCase() != 'flashcards'&& content[contentId].type.toLowerCase() != 'quiz'">
						<a class="btn btn-outline-primary mark-complete-btn"
							v-if="!completionStatus[contentId] || completionStatus[contentId].toLowerCase() == 'uncompleted'"
							@click="markCompleted(true)">
							<span></span>
							Mark Completed
						</a>
						<div class="completed-alert d-flex align-items-center"
							v-if="completionStatus[contentId] && completionStatus[contentId].toLowerCase() == 'completed'">
							<div class="btn btn-outline-primary mark-complete-btn completed me-3">
								<img src="../../assets/images/check-green-circle-icon.svg">
								Completed
							</div>
							<!-- <br class="completed-break" > -->
							<small><a @click="markCompleted(false)" class="uncompleted-btn">Mark Uncompleted</a></small>
						</div>
					</div>
					<div class="ms-auto cd-footer-nav">
						<button v-if="showPreviousButton" @click="previousContent()" :disabled="loading"
							class="btn btn-outline-primary btn-sm btn-prev me-2">
							<img src="../../assets/images/arrow-bottom.svg">
							<span>Previous</span>
						</button>
						<button @click="nextContent()" :disabled="loading"
							class="btn btn-outline-primary btn-next btn-sm">
							<span>Next</span>
							<img src="../../assets/images/arrow-bottom.svg">
						</button>
					</div>
				</div>
			</div>

		</div>
	</div>
	
	<div v-else>
		<div  class="loading-div">
			
			<center><b-spinner class="loader"> </b-spinner></center>
		</div>
	</div>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ProductContentChaptersContentList from "./ProductContentChaptersContentList.vue";
import Treeselect from "@riophae/vue-treeselect";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import ProductContentView from "./ProductContentView.vue";
import { ContentType } from "../../constants/content";
import Swal from "sweetalert2";

export default {
	name: "ProductContentChapters",
	data() {
		return {
			ContentType,
			productVariantID: null,
			selectedProductVariant: null,
			selectedChapterID: null,
			selectedContent:null,
			contentId: null,
			options: [],
			confidenceLevel: {},
			completionStatus: {},
			filters: {},
			accordionState:{},
			isFiltering:false
			
		};
	},
	components: {
		ProductContentChaptersContentList, Treeselect, ProductContentView, ContentType
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById", "getProductVariantProgress"]),
		...mapActions("content", ["getContentById", "setUserContentAction", "getUserContentAction", "getUserContentByProductVariant", "fetchUserAllContentActionsByID"]),
		...mapActions("content", ["updateAction"]),
		
		getProductVariant: async function () {
			this.selectedChapterID = null;
			if (this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantID + ""] && !this.selectedProductVariant) {
				this.selectedProductVariant = this.productVariants.items[this.productVariantID + ""];
				if(this.selectedProductVariant && this.selectedProductVariant.productVariantItems && this.selectedProductVariant.productVariantItems.length>0){
					var self=this;
					
					// temporary closed later will be handled by system parameters
					// setTimeout(function () {
						
					// 	if (self.$route.query.chapterId) {
					// 		self.accordionState[self.$route.query.chapterId]=true;
					// 	} else {
					// 		self.accordionState[self.selectedProductVariant.productVariantItems[0].id]=true;
					// 	}


					// }, 800);
				}
				


				this.getUserContentByProductVariant({ productVariantID: this.productVariantID });
				await this.fetchUserAllContentActionsByID({ productVariantID: this.productVariantID });
				if (this.$route.query.chapterId && this.selectedProductVariant.productVariantItems.find(x => x.id == this.$route.query.chapterId)) {
				{

				}
				this.options = this.selectedProductVariant.productVariantItems.map(
					x => {
						x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
						return {
							id: x.id, label: x.title, type: x.type,
							children: this.mapNestedChild(x)
						};
					});
				return;
			}
		}
		},
		selectChapter: function (id) {
			if (id) {
			var self=this;
			setTimeout(function(){
				if (self.selectedChapterID != id) {
					self.selectedChapterID = id;
				 	if (self.$route.query.chapterId != id) {
						self.$router.push({ query: {...self.$route.query, ["chapterId"]: id } });
					}
				} else {
					self.selectedChapterID = null;
					if (self.$route.query.chapterId == id && self.accordionState[id]==false) {
						self.$router.push({ query: { ...self.$route.query, ["chapterId"]: null } });
					}
				}
			
			},200);
		}
			// if(!this.content[id+""]){
			// 	this.getContentById({id:id,extraParams:{ "NLevel":3}}).then((val)=>{
			// 		this.options=this.selectedProductVariant.productVariantItems.map(
			// 			x=>{ 
			// 				if(this.content && this.content[x.id+""]){
			// 					x=this.content[x.id+""];
			// 				}
			// 				return { id:x.id,label:x.title,type:x.type,
			// 					children:this.mapNestedChild(x)
			// 			};
			// 		});
			// 	});
			// }
		},
		optionContentClass(type) {
			if (type.toLowerCase() == ContentType.RECORDED_VIDEOS.toLowerCase()) {
				return 'video';
			}
		},
		loadChapterOptions({ action, parentNode, callback }) {
			if (action === LOAD_CHILDREN_OPTIONS) {
				if (this.content && this.content[parentNode.id]) {
					parentNode.children = this.mapNestedChild(this.content[parentNode.id]);
					callback();
				} else {
					var self = this;
					this.getContentById({ id: parentNode.id, extraParams: { "NLevel": 3 } }).then(x => {
						parentNode.children = this.mapNestedChild(x);
						callback();
					});
				}
			}
		},
		isunderSelected(children){
			if(children && children.length>0 && children.find(x=>x.isSelected==true))
			{
				this.selectedContent = children.find(x=>x.isSelected==true);
				return true;
			}
			return false;
		},
		upper(text) {
			return text?.replace(
				/\w\S*/g,
				function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				}
			);
		},
		mapNestedChild: function (content) {
			return content.type.toLowerCase() == 'directory' ?
				(content.children &&
					content.children.length > 0 ?
					content.children.map(x => { return { id: x.id,isSelected:(x.id==this.contentId), label: x.title,isDefaultExpanded:this.isunderSelected(this.mapNestedChild(x)), children: this.mapNestedChild(x), type: x.type } }) : null) : undefined;
		},
		setContentConfidenceLevel: function (confidenceLevel) {
			if (this.contentId) {
				if (this.confidenceLevel[this.contentId] && this.confidenceLevel[this.contentId].toLowerCase() == confidenceLevel.toLowerCase()) {
					confidenceLevel = 'Unrated';
				}
				this.confidenceLevel[this.contentId] = confidenceLevel;
				var self = this.confidenceLevel;
				this.confidenceLevel = null;
				this.confidenceLevel = self;
				var contentAction = {
					ContentID: this.contentId,
					DataType: "confidence_level",
					Data: confidenceLevel
				}
				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "confidence_level") {
						this.confidenceLevel[action.contentID] = action.data;
						var self = this.confidenceLevel;
						this.confidenceLevel = null;
						this.confidenceLevel = self;
						this.updateAction(action);
					}
				});
			}
		},
		previousContent: function (isForce = false) {
			
			if(!isForce && !this.isAbleToSwitchContent)
			{	
				this.askToConfirmToSwitch(this.previousContent);
				return;
			}

			
			this.selectedChapterID = this.$route.query.chapterId;
			if (this.contentId && this.selectedChapterID && this.content[this.selectedChapterID]) {

				var chapter = this.content[this.selectedChapterID];
				var topic = chapter.children.find(x => x.children ? (x.children.find(k => k.id == this.contentId)) : false);
				var contentIndexInTopic = topic.children?.indexOf(topic.children?.find(x => x.id == this.contentId)) ?? -1;

				if (contentIndexInTopic != null && contentIndexInTopic > -1) {
					if ((contentIndexInTopic - 1) > -1) {
						this.contentId = topic.children[contentIndexInTopic - 1].id;
					} else {
						var topicIndexInChapter = this.content[this.selectedChapterID].children.indexOf(topic);

						if (topicIndexInChapter != null && topicIndexInChapter > -1) {
							if ((topicIndexInChapter - 1) > -1) {
								this.contentId = chapter.children[topicIndexInChapter - 1].children[chapter.children[topicIndexInChapter - 1].children.length - 1].id;
							} else {
								var chapterIndex = this.selectedProductVariant && this.selectedProductVariant.productVariantItems ?
									this.selectedProductVariant.productVariantItems.indexOf(
										this.selectedProductVariant.productVariantItems.find(x => x.id == this.selectedChapterID)
									) : -1;
								if (chapterIndex > -1 && (chapterIndex - 1) > -1) {
									this.selectChapter(this.selectedProductVariant.productVariantItems[chapterIndex - 1].id);
								}
							}
						}
					}
				}
			}
		},
		nextContent: function (isForce = false) {
			if(!isForce && !this.isAbleToSwitchContent)
			{	
				this.askToConfirmToSwitch(this.nextContent);
				return;
			}

			this.selectedChapterID = this.$route.query.chapterId; 
			if (this.contentId && this.selectedChapterID && this.content[this.selectedChapterID]) {

				var chapter = this.content[this.selectedChapterID];
				var topic = chapter.children.find(x => x.children ? (x.children.find(k => k.id == this.contentId)) : false);
				var contentIndexInTopic = topic.children?.indexOf(topic.children?.find(x => x.id == this.contentId)) ?? -1;

				if (contentIndexInTopic != null && contentIndexInTopic > -1) {
					if (topic.children.length > (contentIndexInTopic + 1)) {
						this.contentId = topic.children[contentIndexInTopic + 1].id;
					} else {
						var topicIndexInChapter = this.content[this.selectedChapterID].children.indexOf(topic);
						if (topicIndexInChapter != null && topicIndexInChapter > -1) {
							if (chapter.children.length > (topicIndexInChapter + 1)) {
								this.contentId = chapter.children[topicIndexInChapter + 1].children[0].id;
							} else {
								var chapterIndex = this.selectedProductVariant && this.selectedProductVariant.productVariantItems ?
									this.selectedProductVariant.productVariantItems.indexOf(
										this.selectedProductVariant.productVariantItems.find(x => x.id == this.selectedChapterID)
									) : -1;
								if (chapterIndex > -1 && (chapterIndex + 1) < this.selectedProductVariant.productVariantItems.length) {
									this.selectChapter(this.selectedProductVariant.productVariantItems[chapterIndex + 1].id);
								}
							}
						}
					}
				}
			}
		},
		getConfidenceLevel: function () {
			if (!this.confidenceLevel[this.contentId]) {
				this.getUserContentAction({ contentId: this.contentId, dataType: "confidence_level" }).then((userActions) => {
					var userAction = userActions ? userActions.find(x => x.dataType == "confidence_level") : null;
					if (userAction) {
						this.confidenceLevel[userAction.contentID] = userAction.data;
					}
				});
			}
		},
		getCompletedStatus: function () {
			if (!this.completionStatus[this.contentId] && (!this.content[this.contentId] || (this.content[this.contentId].type && this.content[this.contentId].type.toLowerCase() != 'recordedvideos' && this.content[this.contentId].type.toLowerCase() != 'liveclass' && this.content[this.contentId].type.toLowerCase() != 'flashcard'))) {
				this.getUserContentAction({ contentId: this.contentId, dataType: "completionStatus" }).then((userActions) => {
					var userAction = userActions ? userActions.find(x => x.dataType == "completionStatus") : null;
					if (userAction) {
						this.completionStatus[userAction.contentID] = userAction.data;
						var self = this.completionStatus;
						this.completionStatus = null;
						this.completionStatus = self;
					}
				});
			}
		},
		markCompleted: function (isCompleted) {
			if (this.contentId) {

				var contentAction = {
					ContentID: this.contentId,
					DataType: "completionStatus",
					Data: isCompleted ? "completed" : "uncompleted"
				}
				this.completionStatus[this.contentId] = isCompleted ? "completed" : "uncompleted";
				var self = this.completionStatus;
				this.completionStatus = null;
				this.completionStatus = self;

				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
						this.completionStatus[action.contentID] = action.data;
						var self = this.completionStatus;
						this.completionStatus = null;
						this.completionStatus = self;
						this.getProductVariantProgress(this.productVariantID);

						this.updateAction(action);
					}

				});
			}
		},
		hasContent(){
			return this.selectedProductVariant && this.selectedProductVariant.productVariantItems && this.selectedProductVariant.productVariantItems.find(x=>this.content[x.id]);
		},
		setOrRemoveFilter(filter, filterType) {
			var currentFilters = this.filters;

			if (!currentFilters[filterType]) {
				currentFilters[filterType] = [filter];
			} else {
				const filterIndex = currentFilters[filterType].indexOf(filter);

				if (filterIndex === -1) {
					currentFilters[filterType].push(filter);
				} else {
					currentFilters[filterType].splice(filterIndex, 1);

					if (currentFilters[filterType].length === 0) {
						delete currentFilters[filterType];
					}
				}
			}

			this.filters = {};
			var self = this;
			setTimeout(function () {

				self.filters = currentFilters;
			}, 5);

		},
		isFilterSelected(filter, filterType) {
			if (this.filters[filterType]) {
				const lowerCaseFilter = filter.toLowerCase();
				return this.filters[filterType].some(f => f.toLowerCase() === lowerCaseFilter);
			}

			return false;
		},
		showSwalErrorMessage({ title, message, buttonText = null }) {
			const swalOptions = {
				title: title,
				text: message,
				icon: "success",
				dangerMode: true,
				allowOutsideClick: true,
				allowEscapeKey: true,
			};

			if (buttonText !== null) {
				swalOptions.confirmButtonText = buttonText;
				swalOptions.confirmButtonColor = "#FF0000";
			}

			Swal.fire(swalOptions).then((result) => {
			});
		},
		handleInput() {
			if (this.filters.searchText === '') {
				delete this.filters.searchText;
			}
		},
		resetFilters() {
			this.filters = {}
			// this.showSwalErrorMessage({
			// 	title: "Filters Cleared",
			// 	message: "All the configured filters has been cleared..",
			// 	buttonText: "OK"
			// })
		},
		contentUnderChapterCount(contentId) {
			if (this.content[contentId] && this.content[contentId].children && this.content[contentId].children.length > 0) {
				return this.content[contentId].children.map(x => x.children && x.children.length>0 ? this.applyFilter(this.filterChildrens(x.children)).length : 0).reduce((partialSum, a) => partialSum + a, 0);
			}
			return 0;
		},		
		filterChildrens(list) {
			return list.filter((item) => {
				if (item.type !== 'RecordedVideos') {
					return item;
				}
				else if (item.extraProperties && item.extraProperties.Status && item.extraProperties.Status.toLowerCase() === 'processed') {
					return item
				}
			});
		},
        completedContentUnderChapterCount(contentId) {
			if (this.content[contentId] && this.content[contentId].children && this.content[contentId].children.length > 0) {
				return this.content[contentId].children.map(x => x.children && x.children.length>0 ? this.applyFilter(this.filterChildrens(x.children))
				.filter(j=>this.contentActions[j.id]?.find(x=>x.dataType=="completionStatus" && x.data.toLowerCase()=='completed') || this.isLiveClassContentAndEnded(j))				
				.length : 0).reduce((partialSum, a) => partialSum + a, 0);
			}
			return 0;
		},
		isLiveClassContentAndEnded(item){
			var currentDate = new Date();			
			const end = new Date(item.availableTo);
			return item.type === ContentType.LIVE_CLASS && item.availableTo && currentDate > new Date(item.availableTo);
		},
		applyFilter(list) {
			const filters = this.filters;
			var self=this;
			if(filters.confidence_level && filters.confidence_level.length>0)
			{
				list=list.filter(x=>
					(filters.confidence_level.indexOf("unrated")>-1  && !(self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level"))) || 
					(filters.confidence_level.indexOf("high")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='high')) || 
					(filters.confidence_level.indexOf("medium")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='medium')) || 
					(filters.confidence_level.indexOf("low")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="confidence_level" && x.data.toLowerCase()=='low')) 
				);
			}

			
			if(filters.completionStatus && filters.completionStatus.length>0)
			{
				list=list.filter(x=>
					(filters.completionStatus.indexOf("not_started")>-1  && !(self.contentActions[x.id]?.find(x=>x.dataType=="completionStatus" && x.data.toLowerCase()=='completed'))) || 
					(filters.completionStatus.indexOf("completed")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="completionStatus" && x.data.toLowerCase()=='completed')) 
				);
			}

			if(filters.bookmark_content && filters.bookmark_content.length>0)
			{
				list=list.filter(x=>
					(filters.bookmark_content.indexOf("bookmark")>-1  && self.contentActions[x.id]?.find(x=>x.dataType=="bookmark" && x.data.toLowerCase()=='true')) 
				);
			}

			if(filters.searchText)
			{
				list=list.filter(x=>x.title.toLowerCase().indexOf(filters.searchText.toLowerCase())>-1);
			}

			return list;
			
		},
		isAccordionVisible(id)
		{

			if(this.accordionState[id]!=null)
			{
				return this.accordionState[id];
			}

			if((this.$route.query.chapterId!=null && this.$route.query.chapterId==id) || (this.$route.query.chapterId==null && this.accordionState[id]==null))
			{
				this.accordionState[id]=true;
				return true;
			}
			return false;
		},
		showAttachments: function() {
			//var el = document.getElementById('instructorAttachmentsBtn');
			var el = this.$refs.showAttachmentsBtn;
			if(el.classList.contains('active')){
				document.getElementById('attachments-modal').classList.remove('active');
				el.classList.remove('active');
			}else{
				document.getElementById('attachments-modal').classList.add('active');
				el.classList.add('active');
			}
			
			
		},
		askToConfirmToSwitch(onSwitch){
			Swal.fire({
				title: "Are you sure?",
				text: "You won't be able to resume this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes"
			}).then((result) => {
				if (result.isConfirmed) {
					onSwitch(true);	
				}
			});
			
		}
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState("content", ["content", "loading","contentActions"]),
		...mapState("productVariant", ["productVariants"]),

		showPreviousButton() {
			try{
			if (this.contentId && this.selectedChapterID && this.content[this.selectedChapterID]) {
				let firstChapter = Object.entries(this.productVariants.items)[0][1].productVariantItems[0];
				let firstContent = this.content[firstChapter.id].children[0].children[0]
				
				return firstContent.id !== this.contentId;
			}}
			catch(e){
				return true;
			}
			return true;
		},
		isAbleToSwitchContent(){
			if( this.contentId && this.content[this.contentId] && this.content[this.contentId].type.toLowerCase()=='quiz'  && this.$route.query.action?.toLowerCase()=='attempt')
			{				
				return false;
			}
			return true;
		},

		isEbookDigitalTheme() {
			return /\/ebook/i.test(this.$route.path);
		}
	},
	watch: {
		productVariants: function () {
			if (!this.selectedProductVariant) {
				this.getProductVariant();
			}
		},
		selectedProductVariant:function(){
			
		},
		filters:function(){
			var self=this;
			
			Object.keys(this.accordionState).forEach(function(val,ind,arr){
				self.accordionState[val]=true;			
			});
			var temp=self.accordionState;
				self.accordionState=null;
				self.accordionState=temp;
				debugger;

		},
		selectedChapterID(){
			var self=this;

			Object.keys(this.accordionState).forEach(function(val,ind,arr){
				self.accordionState[val]=false;
				if(val==self.selectedChapterID)
				{
					self.accordionState[val]=true;
				}
			});
		},
		$route(to, from) {
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.selectedProductVariant = null;
				this.getProductVariant();
			}




			if (this.contentId != this.$route.query.contentId) {
				this.contentId = this.$route.query.contentId;
				if (this.contentId && this.selectedProductVariant) {
					var self = this;
					this.options = this.selectedProductVariant.productVariantItems.map(
						x => {
							x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
							return {
								id: x.id, label: x.title, type: x.type,
								children: self.mapNestedChild(x)
							};
						});
				}
			}

		},
		contentId: function () {
			if (this.$route.query.contentId != this.contentId) {
				this.$router.push({ query: { chapterId: this.selectedChapterID, contentId: this.contentId } })
			}
			this.getConfidenceLevel();
			this.getCompletedStatus();
			var self = this;
			this.options = this.selectedProductVariant.productVariantItems.map(
				x => {
					x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
					return {
						id: x.id, label: x.title, type: x.type,
						children: self.mapNestedChild(x)
					};
				});
		}
	},
	created() {
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.getProductVariant();
		}
		

		if (this.$route.query.contentId && this.contentId != this.$route.query.contentId) {
			this.contentId = this.$route.query.contentId;
		}
	},
	mounted() {
		
	}
};
</script>

<style scoped>

.eb-chapter-toggle .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.eb-chapter-toggle .accordion-button .head-text {
  color: #231F20;
  font-family: 'Circular Std', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-right: 10px; 
}

.eb-chapter-toggle .accordion-button .side-text {
  white-space: nowrap;
  flex-shrink: 0;
}

.eb-chapter-toggle .accordion-button .completed-alert {
  white-space: nowrap;
  flex-shrink: 0;
}

.spinner-border {
	border-color: var(--primary-color);
	border-right-color: transparent;
}

.vs__clear {
	display: none;
}

.select-head {
	color: var(--primary-color);
	font-weight: bold;
}

.vue-treeselect__control {
	border: 0px;
}

.vue-treeselect__control {
	border: 0px;
}

.eb-filters .form-label {
	margin-bottom: 0;
	height: 24px;
}


.ebook-digital .eb-filters > *
{
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);
}

.ebook-digital .eb-filters > *:last-child,
.ebook-digital .eb-filters > *:nth-last-child(2),
.ebook-digital .eb-filters > *:nth-last-child(3) {
    margin-top: 0; 
}


.eb-filter-area .btn-group>label:first-of-type {
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
}

.eb-filter-area .btn-check:checked+.btn,
.eb-filter-area .btn-check:checked+.btn:hover {
	/* background-color: #607D8B;
    border-color: #607D8B; */
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	color: white;
}

.eb-filter-area .btn-check:checked+.btn svg path {
	fill: white;
	stroke: var(--primary-color);
}

.eb-filter-area .btn-group>.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
}

.eb-filter-area .btn-check+.btn:hover {
	color: inherit;
	background-color: var(--gray-light-hover);
	border-color: var(--border-color);
}

.eb-filter-area .confidence-group>.btn span {
	padding: 2px 0 0 4px;
}

.eb-filter-area .confidence-group>.btn svg,
.eb-filter-area .btn-group>.btn img {
	height: 14px;
	width: auto;
}

.eb-filter-area .btn-check:checked+.btn img {
	filter: brightness(0) invert(1);
}


.eb-filters .filter-bookmark {
	position: relative;
	padding: 24px 0 0 0;
	width: 100%;
}

.filter-bookmark .form-check-label {
	position: relative;
	display: flex;
	align-items: center;
	height: 36px;
}

.filter-bookmark input {
	position: absolute;
	width: 0;
	left: -99999px;
}

.filter-bookmark .check {
	width: 16px;
	height: 16px;
	line-height: 14px;
	text-align: center;
	border: solid 1px #11AE00;
	border-radius: 3px;
}

.filter-bookmark .check img {
	height: 10px;
	opacity: 0;
}

.filter-bookmark .bookmark-checkbox:checked+.form-check-label .check img {
	opacity: 1;
}

.filter-bookmark :not(.btn-check)+.btn:active {
	color: inherit;
	background-color: var(--btn-outline-primary);
	border-color: var(--border-color);
}

.completed-alert {
	color: var(--primary-color);
	font-weight: bold;
}

.completed-break {
	height: 8px;
}

.uncompleted-btn {
	margin-top: -10px;
	font-size: smaller;
	font-weight: lighter;
	cursor: pointer;
	color: var(--secondary-color);
}

.mark-complete-btn {
	position: relative;
	padding: 6px 15px 4px 34px;
	text-align: left;
}

.mark-complete-btn span,
.mark-complete-btn img {
	width: 16px;
	height: 16px;
	display: inline-block;
	border-radius: 50%;
	border: solid 1px var(--border-color);
	position: absolute;
	left: 10px;
	top: 8px;
}

.mark-complete-btn img {
	border: none;
	border-radius: 0;
	width: 18px;
	height: 18px;
	left: 9px;
	top: 7px;
}

.mark-complete-btn.completed,
.mark-complete-btn.completed:hover {
	border-color: var(--confidence-label-high);
	color: var(--confidence-label-high);
	background-color: white;
}

.eb-chapter-toggle .accordion-button:not(.collapsed)::after,
.eb-chapter-toggle .accordion-button::after {
	background: rgba(255, 255, 255, 1);
	border: 1.5px solid black;
	color: black;
}
.accordion-item.eb-chapter-toggle{
	filter: drop-shadow(0px 3px 6px rgba(213, 213, 213, 0.16));
}
.accordion-button:not(.collapsed) {
	color: black;
	height: auto;
	min-height: 42px;
}

button.btn.accordion-button.btn-none.btn-block.collapsed,
button.btn.accordion-button.btn-none.btn-block.not-collapsed {
    height: auto;
	min-height: 42px;
	padding-top: 10px;
    padding-bottom: 10px;
}


.show-attachments{
	cursor: pointer;
}
.show-attachments img{
	max-width:20px;
}
.show-attachments .attachment-close-icon{
	max-width:12px;
}
.show-attachments .attachment-close-icon{
	display: none;
}
.show-attachments.active .attachment-icon{
	display: none;
}
.show-attachments.active .attachment-close-icon{
	display: block;
}

.ebook-digital .eb-filter-toggle {
		padding-right: 30px;
	}

@media (max-width:768px){

	
	.show-attachments span{
		display: none;
	}
	.show-attachments{
		width: 50px;
    	flex-shrink: 0;
    	justify-content: center;
	}

	.ebook-digital .eb-filters > *
	{
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding-right: 10px;
		padding-left: 10px;
		margin-top: var(--bs-gutter-y);
	}

	.ebook-digital .eb-filters > *:last-child,
	.ebook-digital .eb-filters > *:nth-last-child(2),
	.ebook-digital .eb-filters > *:nth-last-child(3) {
		margin-top: 8px; 
	}
}
</style>

<style>
.cd-chapter-selection .vue-treeselect__control {
	height: 36px;
	border: none;
}

.cd-chapter-selection .vue-treeselect__value-container {
	padding-left: 35px;
}

.cd-chapter-selection .vue-treeselect__value-container:before {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 6px;
	top: 9px;
	content: '';
	background-image: url('../../assets/images/list-red-icon.svg');
	background-repeat: no-repeat;
	background-size: contain;

}

.cd-chapter-selection .vue-treeselect__single-value {
	padding-left: 35px;
	padding-right: 10px;
	line-height: 38px;
	font-weight: 600;
	color: var(--primary-color);
}

.cd-chapter-selection .vue-treeselect--disabled .vue-treeselect__single-value {
	padding-left: 0;
}

.cd-chapter-selection .vue-treeselect--searchable .vue-treeselect__input-container {
	padding-left: 0;
}

.cd-chapter-selection .vue-treeselect__input-container .vue-treeselect__input,
.cd-chapter-selection .vue-treeselect__input-container .vue-treeselect__input:focus,
.cd-chapter-selection .vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
	outline: none !important;
	box-shadow: none !important;
}

.cd-chapter-selection .vue-treeselect__control-arrow {
	width: 18px;
	height: 18px;
	background-image: url('../../assets/images/arrow-bottom.svg');
	background-repeat: no-repeat;
	background-position: center center;
}

.cd-chapter-selection .vue-treeselect__option {
	display: flex;
	flex-direction: row-reverse;
	border-bottom: solid 1px var(--border-color);
	cursor: pointer !important;
	border-left: solid 3px transparent;
}

.cd-chapter-selection .vue-treeselect__option:hover {
	background: #f5f5f5;
}

.cd-chapter-selection .vue-treeselect__option--highlight {
	background: inherit;
}

.cd-chapter-selection .vue-treeselect__option--selected {
	border-left-color: var(--primary-color);
}

.cd-chapter-selection .vue-treeselect__option-arrow {
	width: 20px;
	height: 20px;
	background-image: url('../../assets/images/plus-icon.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 70%;
	cursor: pointer !important;
}

.cd-chapter-selection .vue-treeselect__option-arrow--rotated {
	background-image: url('../../assets/images/minus-icon.svg');
	background-size: 66%;
}

.cd-chapter-selection .vue-treeselect__option-arrow path,
.cd-chapter-selection .vue-treeselect__control-arrow path {
	display: none;
}

.cd-chapter-selection .vue-treeselect__label {
	height: 40px;
	padding-left: 10px;
	cursor: pointer !important;
}

.cd-chapter-selection .vue-treeselect__option-arrow-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cd-chapter-selection .vue-treeselect--open-below .vue-treeselect__menu {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-left: none;
	border-bottom: none;
	padding-bottom: 0;
	padding-top: 0;
	border-top-color: var(--border-color);
}

.cd-chapter-selection .vue-treeselect--open-below .vue-treeselect__menu .chapter {
	font-weight: bold;
}

.option-icon {
	width: 19px;
	height: 21px;
}

</style>
<style>

.mark-complete-btn.chapter{
	
    font-size: 12px;
	padding-top: 1px;
	padding-bottom: 1px;
    padding-right: 7px;
	padding-left:32px;
}
.mark-complete-btn.chapter img {
    top: 3px;
	width:16px;
	height:16px;
	margin-right:2px;
}
</style>