<template>
  <div class="payment-card">
    <h5 class="text-gray">Total Amount</h5>
    <h2 class="text-bold mb-4">
      {{ parseCurrency(getCartTotalAmount, cart.currency, 0) }}
    </h2>
    <p class="text-uppercase-danger">Payment Method</p>
    <div class="radio-group">
      <div
        v-for="option in paymentOptions"
        :key="option.code"
        class="radio-input d-flex"
      >
        <input
          v-model="paymentMode"
          class="form-check-input"
          type="radio"
          name="payment-method"
          :id="option.serviceProvider"
          :value="option.serviceProvider"
        />

        <label
          class="form-check-label flex-grow-1 d-flex justify-content-between align-items-center"
          :for="option.serviceProvider"
        >
          {{ option.serviceName }}
          <img
            style="height: 20px"
            v-if="option.serviceData && JSON.parse(option.serviceData)"
            :src="JSON.parse(option.serviceData).IconSrc"
          />
        </label>
      </div>
      <div
        v-if="!paymentOptions || loading"
        class="d-flex justify-content-center align-items-center gap-2"
      >
        <b-spinner small></b-spinner> Loading...
      </div>
      <div v-if="paymentOptions == []" class="text-center no-methods">
        No payment methods available
      </div>
      <table class="table mt-4">
        <tbody>
          <tr>
            <td class="col-7">Total (Before GST)</td>
            <td class="col-5 text-bold text-end">
              {{ parseCurrency(getCartTotalAmount, cart.currency, 0) }}
            </td>
          </tr>
          <tr>
            <td class="col-7 text-muted">GST</td>
            <td class="col-5 text-muted text-end">
              {{ parseCurrency(0, cart.currency, 2) }}
            </td>
          </tr>
          <tr class="text-bold">
            <td class="col-7">Total (Including GST)</td>
            <td class="col-5 text-end">
              {{ parseCurrency(getCartTotalAmount, cart.currency, 0) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="text-muted">Disclaimer: By clicking on Pay Now, you agree to the terms and conditions and all policies including refund policy.</p>
      <button
        :disabled="paymentMode == '' || loading"
        @click="handlePayNowClick"
        role="button"
        class="btn-block-rounded-primary width-100"
      >
        Pay Now
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";
export default {
  name: "PaymentCard",
  data() {
    return {
      paymentMode: "",
      paymentOptions: null,
      enrolledVariants: [],
    };
  },
  methods: {
    ...mapActions("appState", ["fetchExternalServices"]),
    ...mapActions("pricing", ["createBulkInvoice", "fetchInvoiceByUserId"]),
    ...mapActions("user", ["fetchUserById", "enrollUserInProductVariant"]),
    ...mapActions("cart", ["emptyCart"]),
    parseCurrency(amount, currency, decimal) {
      return formatter.toCurrencyString(amount, currency, decimal);
    },
    iconPath(option) {
      // console.log(`@${JSON.parse(option.serviceData).IconSrc}`);
      return require(`@${JSON.parse(option.serviceData).IconSrc}`);
    },
    confirmRegistration() {
      if (this.directEnrollVariants && this.directEnrollVariants.length > 0) {
        this.directlyEnrollCourses();
      }
      if (this.paidVariants && this.paidVariants.length > 0) {
        this.cart.items.forEach((item) => {
          if (item.pricingStatus.toLowerCase() == "paid")
            this.getInvoiceDetails(item);
        });
        this.generateInvoice();
      }
    },
    generateInvoice() {
      var invoice = {
        customerID: this.user.id,
        invoiceDate: new Date().toISOString(),
        currency: this.cart.currency,
        status: "unpaid",
        details: this.invoiceDetails,
      };

      this.createBulkInvoice([invoice]).then((res) => {
        console.log(res);
        if (res && res.length && res[0]?.id != 0) {
          // this.enrolledVariants.push(...this.invoiceDetails);
          this.fetchUserById(this.user.id);
          this.fetchInvoiceByUserId(this.user.id).then(() => {
            // console.log(this.invoicesByUserId);
            // this.$router.push("/user/invoices");
            this.processPayments(res);
            // this.closeModal();
            this.$toasted.success(
              "Courses enrolled & invoice generated successfully",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
              }
            );
            this.emptyCart();
          });
        } else {
          this.$toasted.show(
            "You are already enrolled in one or more of the selected courses and have an unpaid invoice. Please pay the invoice to access the course.",
            {
              theme: "outline",
              position: "bottom-center",
              fullwidth: true,
              action: {
                text: "DISMISS",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            }
          );
          this.$router.push("/user/invoices");
        }
      });
    },
    directlyEnrollCourses() {
      this.directEnrollVariants.forEach((variant) => {
        var requestObj = {
          userID: this.user.id,
          productVariantID: variant.id,
          expiresAt: variant.endDate
            ? variant.endDate
            : new Date("12/31/2070").toISOString(),
          allowedRanges: [
            {
              startsFrom: variant.startDate
                ? variant.startDate
                : new Date("12/31/1970").toISOString(),
              endsAt: variant.endDate
                ? variant.endDate
                : new Date("12/31/2070").toISOString(),
            },
          ],
        };
        this.enrollUserInProductVariant({
          user: this.user,
          requestObj,
        }).then(() => {
          console.log(
            `User ${this.user.id} enrolled in variant ${variant.id}.`
          );
          // this.disableEnroll = false;
          this.enrolledVariants.push(variant);
          if (
            this.enrolledVariants.length >= this.directEnrollVariants.length
          ) {
            this.fetchUserById(this.user.id).then(() => {
              console.log("direct enroll variants complete");
              if (this.paidVariants && this.paidVariants.length == 0) {
                this.$toasted.success("Courses enrolled successfully", {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                });
                this.$router.push("/user/dashboard");
              }
              this.emptyCart(this.currentCurrency);
            });
          }
        });
      });
    },
    getInvoiceDetails(variant) {
      console.log("getInvoiceDetails", variant);
      var invoiceDetail = [];
      if (variant.selectedPlan.type.toLowerCase() == "onetime") {
        var detail = {
          planDetailID: parseInt(variant.selectedPlan.details[0].id),
          amount: 0, //variant.selectedPlan.details[0].amount,
          status: "unpaid",
        };
        var dueDate = new Date().setDate(
          new Date().getDate() + variant.selectedPlan.details[0].gracePeriod
        );
        dueDate = new Date(dueDate).setHours(23, 59, 59);

        console.log(dueDate);
        detail.dueDate = new Date(dueDate).toISOString();
        detail.discount = 0.0;
        detail.discountType = null;
        invoiceDetail.push(detail);
      } else {
        variant.selectedPlan.details.forEach((detail) => {
          var invDetail = {
            planDetailID: parseInt(detail.id),
            amount: 0, //detail.amount,
            status: "unpaid",
          };
          var dueDate = new Date().setDate(
            new Date().getDate() + detail.gracePeriod
          );
          dueDate = new Date(dueDate).setHours(23, 59, 59);

          console.log(dueDate);
          invDetail.dueDate = new Date(dueDate).toISOString();
          invDetail.discount = 0.0;
          invDetail.discountType = null;
          invoiceDetail.push(invDetail);
        });
      }
      if (!this.invoiceDetails) this.invoiceDetails = invoiceDetail;
      else this.invoiceDetails.push(...invoiceDetail);
    },
    processPayments(invoice) {
      if(this.paymentMode.toLowerCase() == 'kuickpaybps') {
        var kuickpaybpsPayment = this.paymentOptions.find((x) => x.serviceProvider.toLowerCase() == this.paymentMode.toLowerCase());

        var serviceData = kuickpaybpsPayment.serviceData ? JSON.parse(kuickpaybpsPayment.serviceData) : null;

        this.$router.push(`${this.payNowLink}/${invoice[0].id}?merchantcode=${serviceData.MerchantCode}`);
      }
      else{
        this.$router.push(`${this.payNowLink}/${invoice[0].id}`);
      }
    },
    handlePayNowClick() {
      if (
        this.paymentMode.toLowerCase() == "cash"
        // ||
        // this.paymentMode.toLowerCase() == "banktransfer"
      ) {
        this.$router.push(`${this.payNowLink}`);
      } else if (
        this.paymentMode.toLowerCase() == "banktransfer" ||
        this.paymentMode.toLowerCase() == "paypro" ||
        this.paymentMode.toLowerCase() == "meezan" ||
        this.paymentMode.toLowerCase() == "scotia" ||
        this.paymentMode.toLowerCase() == "ngenius" ||
        this.paymentMode.toLowerCase() == "kuickpaybps" ||
        this.paymentMode.toLowerCase() == "kuickpaycheckout" ||
        this.paymentMode.toLowerCase() == "safepay" ||
        this.paymentMode.toLowerCase() == "stripe"
      ) {
        this.confirmRegistration();
      }
    },
  },
  computed: {
    ...mapGetters("cart", ["getCartTotalAmount"]),
    ...mapState("appState", ["externalServicesList", "loading"]),
    ...mapState("cart", ["cart"]),
    ...mapState("pricing", ["paymentsByUserId"]),
    ...mapState("user", ["user"]),
    payNowLink() {
      return `/payment/${this.paymentMode.toLowerCase()}`;
    },
    directEnrollVariants() {
      return this.cart.items.filter(
        (item) => item.pricingStatus.toLowerCase() != "paid"
      );
    },
    paidVariants() {
      return this.cart.items.filter(
        (item) => item.pricingStatus.toLowerCase() == "paid"
      );
    },
  },
  created() {
    if (!this.externalServicesList.PaymentGateway) {
      this.fetchExternalServices("PaymentGateway").then((res) => {
        console.log(res);
        if (res.payload.length > 0) {
          this.paymentOptions = this.externalServicesList.PaymentGateway;
          this.paymentMode =
            this.externalServicesList.PaymentGateway[0].serviceProvider;
        }
        // console.log(
        // 	"payment options",
        // 	this.externalServicesList.PaymentGateway
        // );
      });
    } else {
      this.paymentOptions = this.externalServicesList.PaymentGateway;
      this.paymentMode =
        this.externalServicesList.PaymentGateway[0].serviceProvider;
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
  .payment-card {
    top: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .payment-card {
    border: 1px solid var(--color-light-blue) !important;
    box-shadow: 3px 3px 10px var(--color-light-blue) !important;
  }
}

.payment-card {
  /* height: 500px; */
  border-radius: 0.75rem;
  position: relative;
  top: -7rem;
  background-color: var(--color-white);
  z-index: 99;
  border: 3px solid var(--color-light-blue);
  box-shadow: 0 0 10px var(--color-gray);
  padding: 1.5rem;
}

.text-uppercase-danger {
  font-size: 0.85rem;
  text-transform: uppercase;
  color: var(--color-danger);
  margin: 0.5rem 0 0.75rem;
}

.radio-input {
  padding: 1rem 0.25rem;
  border-bottom: 1px solid #dee2e6 !important;
}

.radio-input:last-of-type {
  border-bottom: none !important;
}

.radio-input input[type="radio"] {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  outline: none;
}

.radio-input input[type="radio"]:checked {
  background-color: white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23febb2c'/%3e%3c/svg%3e");
  border-color: var(--color-dark-gray);
}

.payment-icon {
  width: 70px;
  float: right;
}

table {
  line-height: 1rem;
}
</style>
