<template>
  <div id="qrcode-attendance" class="qrcode-scanner-screen">
    <img class="logo top-logo" />
    <h4 class="text-center">Please scan QR code to get user details</h4>
    <b-overlay class="text-center":show="isLoading">
      <video id="qr-code-scanner"></video>
    </b-overlay>
    <ViewEditUserModal 
      :user="userData"
      :openTab="openTab"
      @updateModalState="toggleViewEditUser"
      :showModal="showUserDetailModal"
    />
  </div>
</template>

<script>
import QrScanner from "qr-scanner";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import ViewEditUserModal from "./ViewEditUserModal.vue";
export default {
  name: "UserScan",
  data() {
    return {
      qrScanner: null,
      selectedCamera: null,
      availableCameras: [],
      openTab: 0,
      showUserDetailModal: false,
      currentScannedUserId: null,
      isLoading: false,
      userData:{}
    };
  },
  components: {
    ViewEditUserModal
  },
  methods: {
    async onDetectedCallback(result) {
      try {
      this.isLoading=true;
      const scannedUserId = result.data;
      if (this.currentScannedUserId !== scannedUserId) {
        this.currentScannedUserId = scannedUserId;
            this.userData = { id: this.currentScannedUserId };
            this.showUserDetailModal = true;
            var sound = new Howl({
              src: ["https://gocbe-lms-files.b-cdn.net/qr-scan-beep.mp3"],
            });
            sound.play();
      } } catch (error) {
          console.error("Error fetching user:", error);
        }
        finally{
          this.isLoading=false;
        }
    },
    initializeScanner() {
      const videoElem = document.getElementById("qr-code-scanner");
      this.qrScanner = new QrScanner(videoElem, this.onDetectedCallback, {
        highlightCodeOutline: true,
        highlightScanRegion: true,
      });
      QrScanner.listCameras(true).then((cameras) => {
        this.availableCameras = cameras;
        if (cameras.length) {
          this.showCameraSelectionDialog();
        }
      });
    },
    showCameraSelectionDialog() {
      Swal.fire({
        title: "Select a camera",
        html: `
          <div class='d-flex flex-column justify-content-center align-items-center gap-2'>
            <select id="camera-select"></select>
          </div>
        `,
        target: document.querySelector("#qrcode-attendance"),
        preConfirm: () => {
          const selectElem = document.getElementById("camera-select");
          this.selectedCamera = selectElem.value;
          this.qrScanner.setCamera(this.selectedCamera);
          this.qrScanner.start();
        },
      }).then(() => {
        const selectElem = document.getElementById("camera-select");
        this.availableCameras.forEach((cam) => {
          const option = document.createElement("option");
          option.text = cam.label;
          option.value = cam.id;
          selectElem.add(option);
        });
      });
    },
toggleViewEditUser() {
      this.showUserDetailModal = !this.showUserDetailModal;
      if (!this.showUserDetailModal) {
        this.currentScannedUserId = null;
        this.openTab = 0;
        this.qrScanner.start();
      }
    },
  },
  mounted() {
    this.initializeScanner();
  },
  beforeDestroy() {
    if (this.qrScanner) {
      this.qrScanner.stop();
      this.qrScanner.destroy();
      this.qrScanner = null;
    }
  },
};
</script>
<style scoped>
#qr-code-scanner {
  height: 50vh;
}
</style>