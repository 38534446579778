"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  id: 1,
  imgSrc: "../assets/images/banner1.jpg"
}, {
  id: 2,
  imgSrc: "../assets/images/banner1.jpg"
}, {
  id: 3,
  imgSrc: "../assets/images/banner1.jpg"
}, {
  id: 4,
  imgSrc: "../assets/images/banner1.jpg"
}];
exports["default"] = _default;