<template>
  <div class="row full">
    
    <div class="flex-grow-1 my-2" style="display:flex;">
        <button class="back-btn" @click="$emit('closeSubmissionDetail', false)">
          Back To Submissions
        </button>
        <!-- <button 
          class="collapse-btn" 
          @click="()=>{panelCollapsed = !panelCollapsed;}" 
        >
          {{ panelCollapsed ? "Uncollapse": "Collapse" }}
      </button> -->
    </div>

    <div :class="'col-md-3 '+ 'mt-3 full'" :style="panelStyle">
      <div class="top-side-div">
        <a class="icon-btn mr-3 first" @click="previous()" :disabled="selectedStudentAssignmentIndex < 1">
          <i class="fas fa-caret-left"></i>
        </a>
        <div class="username-box">
          <v-select class="select2 search-input" v-model="selectedStudentAssignmentIndex"
            :options="assignmentResultsWithIndex" placeholder="Search by Student" label="name" :appendToBody="true"
            :reduce="(option) => option.value">

            <template #selected-option="option">
              <div v-if="selectedStudentAssignment" class="user-name d-flex align-items-center" style="flex: 1;">
                <img v-if="selectedStudentAssignment.user.profilePicture" class="user-image user-avatar"
                  :src="selectedStudentAssignment.user.profilePicture" />
                <div v-else class="user-avatar">
                  {{ selectedStudentAssignment.user.firstName[0] }}
                </div>
                <h6 style="margin-left: 10px; margin-bottom: 0px;">
                  {{ selectedStudentAssignment.user.firstName }} {{ selectedStudentAssignment.user.lastName
                  }}<br />
                  <small class="text-muted">{{
                    selectedStudentAssignment.user.email }}</small>
                </h6>
              </div>

            </template>
            <template v-slot:option="option">
              <div class="user-name d-flex align-items-center" style="flex: 1;">
                <h6 style="margin-bottom: 0px;">
                  {{ option.user.firstName }} {{ option.user.lastName
                  }}<br />
                  <small class="text-muted">{{
                    option.user.email }}</small>
                </h6>
              </div>
            </template>
          </v-select>
        </div>
        <!-- <div class="user-name d-flex align-items-center" style="flex: 1;">
          <img v-if="selectedStudentAssignment.user.profilePicture" class="user-image user-avatar"
            :src="selectedStudentAssignment.user.profilePicture" />
          <div v-else class="user-avatar">
            {{ selectedStudentAssignment.user.firstName[0] }} 
          </div>
          <h6 style="margin-left: 10px; margin-bottom: 0px;">
            {{ selectedStudentAssignment.user.firstName }} {{ selectedStudentAssignment.user.lastName
            }}<br />
            <small class="text-muted">{{
              selectedStudentAssignment.user.email }}</small>
          </h6>
        </div> -->
        <a class="icon-btn ml-3" @click="next()" :disabled="selectedStudentAssignmentIndex > (assignmentResults.length - 1)">
          <i class="fas fa-caret-right"></i>
        </a>
      </div>

      <div class="mt-3">
        <b-tabs v-model="activeTabIndex" content-class="" v-if="isSubmittedAssignment">
          <b-tab title="Submission" active>
            <b-card class="feedback-box" v-if="isSubmittedAssignment">
              <div v-if="isSubmittedAssignment && submittedSubmissions.length > 1" class="top-side-div"
                style="margin-top: 0px; padding-top: 0px;">

                <a class="icon-btn mr-3 first" @click="previousSubmission()" :disabled="selectedSubmissionIndex < 1">
                  <i class="fas fa-caret-left"></i>
                </a>

                <div class="user-name d-flex align-items-center" style="flex: 1;">
                  <h5 style="color: black; margin: 0px;"> Submission {{ selectedSubmissionIndex + 1 }}</h5>
                </div>
                <a class="icon-btn ml-3" @click="nextSubmission()"
                  :disabled="selectedSubmissionIndex > (submittedSubmissions.length - 1)">
                  <i class="fas fa-caret-right"></i>
                </a>

              </div>
              <!-- Student Work -->
              <div v-if="isSubmittedAssignment" class="submission-stamp">
                <div v-if="selectedStudentAssignment.isLateSubmission" style="display: flex; align-items: center;"
                  class="mt-3">
                  <div v-if="selectedStudentAssignment.isLateSubmission" class="status-tag danger late-submitted-status">
                    Late Submission
                  </div>
                </div>
                <h6>
                  <small v-if="activeSubmission && activeSubmission.submittedAt">
                    Turned in
                    {{ parseDateTime(activeSubmission.submittedAt) }}</small>
                </h6>
              </div>
              <!-- File Selector -->
              <div class="file-selection-area mt-3">

                <ul class="submited-files"
                  v-if="activeSubmission && activeSubmission.submittedFiles && activeSubmission.submittedFiles.length > 0">
                  <li v-for="(file, index) in activeSubmission.submittedFiles"
                    :class="index === activeFileIndex ? 'active' : ''" @click="changeFile(index)"
                    class="d-flex justify-content-between align-items-center">

                    <!-- File Name and Type -->
                    <div>
                      {{ file.name }}
                      <small><br />{{ file.type }}</small>
                    </div>

                    <!-- <div class="icon-container">
                      <i v-if="(annotations[file.url] != true) && getPDFJSViewerUrl(file.url)" @click.stop="annotateFile(file, index)"
                        class="fas fa-pencil-alt" style="font-size: 14px; cursor: pointer; margin-right: 10px;"
                        alt="Annotate"></i>
                    </div> -->

                    <!-- <i 
                      v-else
                      @click.stop="doneAnnotation(file, index)" 
                      class="fas fa-check" 
                      style="font-size: 14px; cursor: pointer; color: green; margin-right: 10px;" 
                      alt="Done"></i>
                    </div>

                    <i 
                      v-if="annotations[file.url] === true"
                      @click.stop="cancelAnnotation(file, index)" 
                      class="fas fa-times" 
                      style="font-size: 14px; cursor: pointer; color: red; margin-right: 10px;" 
                      alt="Cancel"></i> -->
                  </li>
                </ul>
                <center v-if="activeSubmission && activeSubmission.submittedFiles
                  && activeSubmission.submittedFiles.length == 0">
                  Not Submitted Files
                </center>
              </div>
            </b-card>
          </b-tab>
          <b-tab title="Feedback">
            <b-card class="feedback-box" v-if="isSubmittedAssignment">
              <!-- File Selector -->
              <div class="file-selection-area mt-3">
                <ul class="submited-files" v-if="activeSubmission && activeSubmission.feedbackFiles
                  && activeSubmission.feedbackFiles.length > 0">
                  <li v-for="(file, index) in activeSubmission.feedbackFiles" :key="index"
                    class="active d-flex justify-content-between" @click="openFile(file.url)">
                    <div style="width: 60%">{{ file.name }}
                      <small><br />{{ file.type }}</small>
                    </div>
                    <div class="icon-container">
                      <div v-if="editField && editField == 'feedback' && !loading">
                        <i class="fas fa-trash-alt" @click.stop="removeFile(index)" style="cursor: pointer; font-size: 16px; color: red;"></i>
                      </div>
                    </div>
                  </li>

                </ul>
                <center v-if="!activeSubmission || !activeSubmission.feedbackFiles
                  || activeSubmission.feedbackFiles.length == 0">
                  No Feedback Files
                </center>
              </div>

              <div class="mt-3" v-if="isSubmittedAssignment && activeSubmission">
                <h6 style="margin: 0px;">Feedback</h6>
                <textarea v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback'"
                  :class="'form-control feedback-textarea '" v-model="activeSubmission.feedback"
                  :readonly="editField !== 'feedback'" rows="4"></textarea>
                <input class="mb-3"
                  v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback'" type="file"
                  @change="uploadTrainerMarkedFiles($event)" multiple /> 
                <div v-else class="form-control feedback-textarea">
                  {{ activeSubmission.feedback }}
                </div>
                <!-- Success Button (Submit) -->
                <a class="btn btn-success" style="border-radius:10px;" @click="saveFeedback"
                  v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback' && !loading">
                  Submit
                </a>
                <a class="btn btn-success" style="border-radius:10px;" disabled
                  v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback' && loading">
                  <i class="fa fa-spinner fa-spin"></i> Submitting...
                </a>

                <!-- Danger Button (Cancel) -->
                <a class="btn btn-danger" style="border-radius:10px; margin-left:15px;" @click="cancelEdit"
                  v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback' && !loading">
                  Cancel
                </a>


                <div style=" display: flex; text-align: right; justify-content:space-between">
                  <!-- <b-spinner small
                  v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'feedback' && loading && !responseStatus"
                  class="loading-btn"></b-spinner> -->

                <!-- <h6 class="mt-2" >
                  <b-badge v-if="responseStatus" style="backgroundColor: rgb(65, 65, 238)" variant="info">{{ responseStatus }}</b-badge>
                </h6> -->

                  <a role="button" class="border-btn edit-btn" @click="edit('feedback')"
                    v-if="!editField || editField != 'feedback' && !loading">
                    <i class="fas fa-pencil-alt edit-btn-icon me-2"></i>Edit
                  </a>
                </div>

                
                
              </div>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>


      <div class="mt-3" v-if="totalMarks">

        <h6  style="margin: 0px;">Marks &nbsp; 
          <i id="marks-info" class="fas fa-info-circle" 
          v-b-tooltip.hover.righttop="'Combined marks of all submissions'"></i>
        </h6>

        <div @click.stop class="marks-div">
          <input v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'marks'"
            v-on:keyup.enter="saveMarks" :class="'form-control score-box ' + (!validateMarks ? 'error' : '')" min="0"
            :max="extraProperties.Marks" v-model="updatedMarks" type="number" />
          <input v-else class="form-control score-box" @focus="$event.target.select()" @click="edit('marks')"
            v-model="updatedMarks" readonly type="number" />
          <a class="saveMarksBtn" @click="saveMarks"
            v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'marks' && !loading"><i
              class="fa fa-check"></i></a>
          <a class="cancelMarksBtn" @click="cancelEdit"
            v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'marks' && !loading"><i
              class="fa fa-times"></i></a>
          <b-spinner small
            v-if="currentEditableUserID == selectedStudentAssignment.user.id && editField == 'marks' && loading"
            class="loading-btn"></b-spinner>

          <span class="total-marks">/ {{ totalMarks }}</span>
        </div>
        <h6 class="mt-2"
          v-if="selectedStudentAssignment && (selectedStudentAssignment.isGraded || selectedStudentAssignment.marks)">
          <b-badge :variant="'success'">Graded</b-badge> </h6>

        <!-- <p class="text-danger mt-2">Overall marking for the assignment </p> -->

      </div>

    </div>
    <div :class="panelCollapsed ? 'col-md-12 ' : 'col-md-9 '+ 'full'" style="overflow: hidden;height: 100%;">



      <!-- here on inframe we have to hide the iframe and display only
      when loading file is false so iframe could be loaded 
      because v-if renders new one. it can only be done using computed property 
      and display none -->
      <div class="viewerModel full" v-if="activeFile">

        <div id="annotation-viewer" class="annotation-viewer"
          v-if="activeFile.extension.toLowerCase() == 'pdf' && getPDFJSViewerUrl(activeFile.url)">
          <iframe id="pdfjsviewer" class="google-docs iframe" :src="getPDFJSViewerUrl(activeFile.url)" frameborder="0"
            scrolling="no" marginheight="0" marginwidth="0" role="document" aria-label="PDF document" title="PDF document"
            @load="onFileLoaded"></iframe>
          <div class="viewer-footer">

            <button class="done-button mx-3"  @click="toggleFullscreen">
              <i :class="isFullscreen ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'"></i>
              <span style="margin-left: 8px;" >{{ isFullscreen ? 'Exit Fullscreen' : 'Fullscreen' }}</span>
            </button>

           <div v-if="Object.keys(annotations).length > 0" >
            <button class="done-button" @click="doneAnnotation" :disabled="loading">
              <i v-if="!loading" class="fas fa-check"></i>
              <b-spinner v-else small></b-spinner> 
              <span v-if="!loading"> Done</span>
              <span v-else> Uploading </span>
            </button>

            <button class="cancel-button" @click="cancelAnnotation" :disabled="loading">
              <i class="fas fa-times"></i> Cancel
            </button>

            </div>


            <button class="done-button" v-else 
              @click.stop="annotateFile()"> 
              <i class="fas fa-pencil-alt mx-1"></i><span>Annotate</span>
            </button>


            
          </div>
        </div>

        <iframe v-else-if="activeFile.extension.toLowerCase() == 'pdf'" class="google-docs iframe" :src="activeFile.url"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0" role="document" aria-label="PDF document"
          title="PDF document" @load="onFileLoaded"></iframe>

        <img style="width: 100%; height: 100%; object-fit: contain;" v-else-if="activeFile.type.toLowerCase() == 'image'"
          :src="activeFile.url" @load="onFileLoaded" />

        <video style="width: 100%; height: 100%;" class="p-3" autoplay controls
          v-else-if="activeFile.type.toLowerCase() == 'video'" :src="activeFile.url" @load="onFileLoaded" />

        <iframe v-else-if="isAllowedFileFormat" class="google-docs iframe"
          :src="'https://docs.google.com/viewer?url=' + encodeURI(activeFile.url) + '&embedded=true'" frameborder="0"
          scrolling="no" marginheight="0" marginwidth="0" role="document" aria-label="PDF document" title="PDF document"
          @load="onFileLoaded"></iframe>
        <div class="full no-file-support" v-else>
          <div class="inner">
            <h3>
              {{ activeFile.name }}
              <small><br />{{ activeFile.type.toLowerCase() == 'file' ? 'Unknown FormatFile':activeFile.type.toUpperCase()}}</small>
            </h3>
            <div style="text-align: -webkit-center;">
              <a class="step-btn download" :href="activeFile.url" download>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="full no-file-support" v-else>
        <div class="inner">
          <h3>
            No Assignment Submitted
            <small><br /></small>
          </h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from "sweetalert2";

export default {
  name: 'AssignmentDetail',
  data() {
    return {
      panelCollapsed: false,
      selectedStudentAssignmentIndex: -1,
      selectedSubmissionIndex: 0,
      activeFileIndex: 0,
      loadingFile: false,
      loading: false,
      updatedMarks: 0,
      currentEditableUserID: null,
      editField: null,
      feedbackText: '',
      selectedStudentid: null,
      annotations: {},
      activeTabIndex: 0,
      responseStatus: null,
      isFullscreen: false,
    }
  },
  props: {
    contentData: Object,
    assignmentResults: Array,
    assignmentResult: Object
  },
  computed: {
    ...mapState("appState", ["systemParameters"]),

    selectedStudentAssignment: function () {
      if (this.assignmentResults && this.selectedStudentAssignmentIndex > -1
        && this.assignmentResults.length > this.selectedStudentAssignmentIndex) {
        const studentAssignment = this.assignmentResults[this.selectedStudentAssignmentIndex];
        return studentAssignment;
      }
    },
    submittedSubmissions: function () {
      if (this.selectedStudentAssignment) {
        let submissions = this.selectedStudentAssignment
          && this.selectedStudentAssignment.submissions
          && this.selectedStudentAssignment.submissions.length > 0 ?
          this.selectedStudentAssignment.submissions : [];
        return submissions;
      }
      return [];
    },

    isSubmittedAssignment() {
      return this.selectedStudentAssignment.isSubmitAssignment;
    },

    activeSubmission() {
      if (this.submittedSubmissions.length > this.selectedSubmissionIndex) {
        return this.submittedSubmissions[this.selectedSubmissionIndex];
      }
    },

    activeFile: function () {
      if (
        this.activeSubmission &&
        this.activeSubmission.submittedFiles &&
        this.activeSubmission.submittedFiles.length > 0 &&
        this.activeFileIndex > -1
      ) {
        const file = this.activeSubmission.submittedFiles[this.activeFileIndex];
        if (file && file.url) {

          return {
            ...file,
            url: this.appendUniqueQueryParameter(file.url),
          };
        }
      }
      return null;
    },
    extraProperties() {
      return this.contentData && this.contentData.extraProperties
        && typeof (this.contentData.extraProperties) == 'string' ? JSON.parse(this.contentData.extraProperties) : this.contentData.extraProperties
    },
    totalMarks() {
      return this.extraProperties && this.extraProperties.Marks ? this.extraProperties.Marks : null;
    },
    validateMarks() {
      return this.updatedMarks &&
        this.totalMarks && parseInt(this.totalMarks + "") >= parseInt(this.updatedMarks)
    },
    isAllowedFileFormat() {
      const extension = this.activeFile.extension.toLowerCase();
      return this.getAllowedFormats().includes(extension);
    },
    assignmentResultsWithIndex() {
      if (this.assignmentResults) {
        this.assignmentResults.forEach((x, ind, arr) => {
          x.value = ind;
          x.name = x.user.firstName + " " + x.user.lastName;
        });
      }
      return this.assignmentResults;
    },
    panelStyle() {
      return {
        overflow: 'auto',
        display: this.panelCollapsed ? 'none' : 'block',
        borderRight: '1px solid lightgray',
        paddingRight: '15px'
      };
    },
  },
  mounted() {
    this.loadData()
    window.addEventListener("message", this.handleViewerEvents);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleViewerEvents);
  },

  created() {
    var assignment = this.assignmentResults.find(x => x.user.id == this.assignmentResult.user.id);
    if (assignment) {
      this.selectedStudentAssignmentIndex = this.assignmentResults.indexOf(assignment);

      this.selectLastSubmission();
    }
  },
  watch: {
    activeSubmission: function () {
      if (this.activeFile && this.isAllowedFileFormat) {
        this.loadingFile = true;
      }
    },

  },
  methods: {
    ...mapActions("assignment", [
      "submitAssignmentResult",
      "submitAssignmentFeedback",
    ]),
    ...mapActions("content", ["uploadContentFile"]),

    loadData() {
      this.updatedMarks = this.assignmentResult.marks;
    },
    parseDateTime(time) {
      if (time)
        return new Date(time).toDateString() + " " + new Date(time).toLocaleTimeString();

      return "";
    },
    changeFile(fileIndex, force = false) {
      if(Object.keys(this.annotations).length > 0){
      this.showSwalConfirmation({
        title: "Discard Changes",
        message: "Are you sure you want to proceed and discard chages?",
        confirmButtonText: 'OK',
        onConfirm: () => {
          this.selectFile(fileIndex, force);
          const iframe = document.getElementById('pdfjsviewer');
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ eventName: 'cancelannotate' }, '*');
          }
        }
      });
    }
    else {
      this.selectFile(fileIndex, force);
    }

    },
    selectFile(fileIndex, force = false) {
      if (force || this.activeFileIndex != fileIndex) {
        this.annotations = {};
        this.activeFileIndex = fileIndex;
        this.loadingFile = true;
      }
    },
    openFile(fileUrl) {
      if (fileUrl) {
        window.open(fileUrl, '_blank');
      }
    },
    onFileLoaded(e) {
      this.loadingFile = false;
    },
    getAllowedFormats() {
      return ['docx', 'doc', 'ppt', 'pptx', 'csv', 'xls', 'xlsx']
    },
    selectLastSubmission() {
      if (this.submittedSubmissions && this.submittedSubmissions.length > 0) {
        this.selectedSubmissionIndex = this.submittedSubmissions.length - 1;
      }
    },
    next() {
      if (this.selectedStudentAssignmentIndex < this.assignmentResults.length) {
        this.selectedStudentAssignmentIndex++;
        this.updatedMarks = this.selectedStudentAssignment.marks || "0";
        this.selectLastSubmission();
      }
    },
    previous() {
      if (this.selectedStudentAssignmentIndex > 0) {
        this.selectedStudentAssignmentIndex--;
        this.updatedMarks = this.selectedStudentAssignment.marks || "0";

        this.selectLastSubmission();
      }
    },

    nextSubmission() {
      if (this.selectedSubmissionIndex < this.submittedSubmissions.length - 1) {
        this.selectedSubmissionIndex++;
      }
    },
    previousSubmission() {
      if (this.selectedSubmissionIndex > 0) {
        this.selectedSubmissionIndex--;
      }
    },

    edit(key) {
      this.currentEditableUserID = this.selectedStudentAssignment.user.id;
      this.editField = key;

      if (key === "marks") {
        this.updatedMarks = this.selectedStudentAssignment.marks || 0;
      }
      else if (key === "feedback") {
        this.feedbackText = this.activeSubmission.feedback || "";
      }
    },
    cancelEdit() {
      this.currentEditableUserID = null;

      if (this.editField === "marks") {
        this.updatedMarks = this.selectedStudentAssignment.marks || null;
      }
      else if (this.editField === "feedback") {
        this.feedbackText = this.activeSubmission.feedback || "";
      }

      this.editField = null;
    },

    appendUniqueQueryParameter(url) {
      const separator = url.includes('?') ? '&' : '?';
      const uniqueParam = `var=${Math.random()}`;
      return url + separator + uniqueParam;
    },
    saveMarks() {
      if (this.currentEditableUserID && this.editField == 'marks' && this.validateMarks) {
        var updatedResult = {
          ContentId: this.contentData.id,
          UserId: this.currentEditableUserID,
          Marks: parseInt(this.updatedMarks)
        }

        this.loading = true;
        this.submitAssignmentResult(updatedResult).then((submittedAssignmentResult) => {

          if (submittedAssignmentResult) {
            this.selectedStudentAssignment.marks = submittedAssignmentResult.marks;
            this.currentEditableUserID = null;
            this.editField = null;
          }

          this.loading = false;
        });
      }
    },

    getFileNameWithoutExtension(filename) {
      const fileName = filename.split('.')[0];
      return fileName;
    },

    getFileType(filename) {
      const fileType = filename.split('.')[1];
      return fileType;
    },

    async uploadTrainerMarkedFiles(event) {
      this.loading = true;
      if (event.target.files) {
        let files = event.target.files;

        if (files && files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            let file = files[i];

            let formData = new FormData();
            formData.append('Type', file.type);
            formData.append('Entity', 'content/assignment');
            formData.append('File', file, file.name);
            formData.append('IsCompressionRequired', 'false');

            await this.uploadContentFile(formData).then((res) => {
              if (res) {
                this.activeSubmission.feedbackFiles.push(
                  {
                    name: this.getFileNameWithoutExtension(file.name),
                    url: res,
                    type: this.getFileType(file.name).toUpperCase(),
                    extension: this.getFileType(file.name).toLowerCase(),
                  }
                );
              }
            });
          }

          this.loading = false;
        }
      }
    },

    removeFile(index) {
      this.activeSubmission.feedbackFiles.splice(index, 1);
      console.log({ feedbackFiles: this.activeSubmission.feedbackFiles });

      let selectedStudentIndex = this.selectedStudentAssignmentIndex;
      this.selectedStudentAssignmentIndex= -1;
      this.selectedStudentAssignmentIndex = selectedStudentIndex;
    },

    saveFeedback() {
      this.activeSubmission.feedback = !(this.activeSubmission.feedback === null || this.activeSubmission.feedback.trim() === '')
      ? this.activeSubmission.feedback : 'Review your submission feedback';
 
      var assignmentFeedback = {
        ContentId: this.contentData.id,
        UserId: this.selectedStudentAssignment.user.id,
        submissionId: this.activeSubmission.id,
        feedback: this.activeSubmission.feedback,
        feedbackFiles: this.activeSubmission.feedbackFiles ? this.activeSubmission.feedbackFiles : []
      };

      this.loading = true;

      this.submitAssignmentFeedback(assignmentFeedback)
        .then((submittedAssignmentResult) => {

          if (submittedAssignmentResult) {
            this.currentEditableUserID = null;
            this.editField = null
          }

          this.loading = false;
        });
      // }

    },

    getPDFJSViewerUrl(url) {
      const baseUrl = this.systemParameters && this.systemParameters.PDFAnnotatorUrl
      if (!baseUrl) return null;
      const isAnnotating = this.activeFile && this.activeFile.url ? this.annotations[this.activeFile.url] == true : false;

      return `${baseUrl}?pdfUrl=${encodeURIComponent(url)}&&isAnnotating=${isAnnotating}`;
    },

    handleViewerEvents(event) {
      const { data, eventName } = event.data;

      if (eventName == 'annotatedFile') {
        this.handleAnnotatedFile(data);
      }

    },

    handleAnnotatedFile({ fileData, fileName }) {
      const name = `feedback-${this.activeFile.name}.pdf`;
      const blob = new Blob([fileData], { type: "application/pdf" });
      const file = new File([blob], name, { type: "application/pdf" });

      let formData = new FormData();
      formData.append('Type', file.type);
      formData.append('Entity', 'content/assignment');
      formData.append('File', file, file.name);
      formData.append('IsCompressionRequired', 'false');

      this.loading = true;
      this.responseStatus = 'Uploading'
      this.uploadContentFile(formData).then((res) => {
        if (res) {
          this.activeSubmission.feedbackFiles.push(
            {
              name: this.getFileNameWithoutExtension(file.name),
              url: res,
              type: this.getFileType(file.name).toUpperCase(),
              extension: this.getFileType(file.name).toLowerCase(),
            }
          );
          this.responseStatus = null;
          this.selectFile(this.activeFileIndex, true)

        }
        this.saveFeedback()
        // this.loading = false;
      });
    },

    async annotateFile() {

      await setTimeout(() => {
        let annotations = {};
        this.annotations = {}
        annotations['url'] = true
        this.annotations = annotations;

        const iframe = document.getElementById('pdfjsviewer');

        // Ensure the iframe exists and is loaded
        if (iframe && iframe.contentWindow) {
          // Send a message to the iframe
          iframe.contentWindow.postMessage({
            eventName: 'annotate',
          }, '*');
        }
      }, 400);

    },

    showSwalConfirmation({
      title = "Are you sure?",
      message = "Do you want to proceed?",
      confirmButtonText = "Yes, Submit",
      cancelButtonText = "Cancel",
      onConfirm = () => { },
      onCancel = () => { }
    }) {
      const swalOptions = {
        title: title,
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        cancelButtonColor: "#D3D3D3",
        confirmButtonColor: "#3085d6",
        confirmButtonText: confirmButtonText,
        allowOutsideClick: true,
        allowEscapeKey: true,
      };

      Swal.fire(swalOptions).then((result) => {
        if (result.isConfirmed) {
          onConfirm();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          onCancel();
        }
      });
    },



    doneAnnotation() {
      if(this.isFullscreen) this.toggleFullscreen()

      this.showSwalConfirmation({
        title: "Confirm Feedback Submission",
        message: "Are you sure you want to submit the feedback file?",
        onConfirm: () => {
          this.switchToFeedbackTab()
          this.loading = true;

          const iframe = document.getElementById('pdfjsviewer');
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ eventName: 'doneannotate' }, '*');
          }
        }
      });

    },

    cancelAnnotation() {
      if(this.isFullscreen) this.toggleFullscreen()

      this.showSwalConfirmation({
        title: "Cancel Editing",
        message: "Are you sure you want to cancel editing? Unsaved changes will be lost.",
        confirmButtonText: "Yes, Cancel",
        onConfirm: () => {
          this.annotations = {};
          const iframe = document.getElementById('pdfjsviewer');

          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ eventName: 'cancelannotate' }, '*');
          }

          // to reload the file
          let currentIndex = this.activeFileIndex;
          this.activeFileIndex = -1;
          this.selectFile(currentIndex, true)
        }
      });
    },

    switchToSubmissionTab() {
      this.activeTabIndex = 0; // Switch to the "Submission" tab
    },
    switchToFeedbackTab() {
      this.activeTabIndex = 1; // Switch to the "Feedback" tab
    },

    toggleFullscreen() {
      const element = document.getElementById('annotation-viewer');

      if (!document.fullscreenElement) {
        // Request fullscreen mode
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }

        this.isFullscreen = true; // Update state
      } else {
        // Exit fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }

        this.isFullscreen = false; // Update state
      }
    }

  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.back-btn {
  text-transform: capitalize;
  text-decoration: underline;
  font-size: small;


}

.user-image {
  object-fit: cover;
}

.top-side-div {
  display: flex;
  align-items: center;
}

div.full {
  height: 100%;
}

.top-side-div .icon-btn {
  font-size: xx-large;
  margin-left: 10px;
  color: #6c757d;
}

.top-side-div .icon-btn.first {
  margin-left: 0px;
  margin-right: 10px;
}

.submited-files {
  margin-left: 0px;
  padding-left: 0px;
}

.submited-files li {
  border: 0.2px solid whitesmoke;
  list-style: none;
  font-size: smaller;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  background-color: #f1f1f1;
  text-overflow: ellipsis;
  /* text-wrap: nowrap; */
  line-height: 10px;
  overflow: hidden;
  cursor: pointer;
}

.submited-files li small {
  font-size: xx-small;
  color: var(--primary-color);
}

.submited-files li:hover {
  border: 0.2px solid var(--primary-color);
  background-color: #fbfbfb;
}

.submited-files li:active {
  border: 0.2px solid var(--primary-color);
  background-color: #fbfbfb;
}

.submited-files li.active {
  border: 0.2px solid var(--primary-color);
  background-color: #fbfbfb;
}

.late-submitted-status {
  font-size: 10px;
  border-radius: 4px;
  line-height: 2px;
  padding: 5px;
}

.feedback-textarea {
  background-color: white;
}

.score-box {
  height: 30px;
  width: 50px;
  font-size: 24px;
  margin: 0px;
  margin-right: 10px;
  background-color: white;
}

.score-box:disabled {
  cursor: pointer;
}

.saveMarksBtn {
  margin-left: 10px;
  margin-top: 2px;
  font-size: large;
  color: rgb(var(--bs-success-rgb));
}

.cancelMarksBtn {
  margin-left: 10px;
  margin-top: 2px;
  font-size: large;
  color: rgb(var(--bs-danger-rgb));
}

.marks-div {
  display: flex;
  place-content: start;
  text-align: center;
}

.google-docs.iframe {
  width: 100%;
  height: 100%;
}

.no-file-support {
  position: relative;
}

.no-file-support .inner {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}


.no-file-support .inner h3 {
  text-align: center;
}

.step-btn.download {
  background-color: var(--primary-color);
  color: white;
  width: 100px;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999;
  margin-top: 250px;
  margin-left: 250px;
  margin-right: auto;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* New Styles */
.arrow-icon {
  height: 20px;
  width: 20px;
  color: #000;
  font-weight: 400;
}


.total-marks {
  font-size: 17px;
  color: #000;
  font-weight: 600;
  margin-top: 2.5px;
  margin-left: 5px;
}

.top-side-div .username-box {
  overflow: hidden;
  width: 82%;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.feedback-box {
  background-color: #f7f7f7 !important;
}

.badge.badge-success {
  background: green;
}
</style>
<style>
.top-side-div .vs__actions {
  display: none;
}

.top-side-div .vs__selected {
  margin: 0px;
  padding: 0px;
  z-index: 999;
}

.top-side-div .vs__search {
  position: absolute;
  z-index: 0;

}

.top-side-div .vs--open .vs__search {
  position: relative !important;

}

.feedback-textarea:read-only {
  cursor: text;
  background-color: whitesmoke;
}

.feedback-textarea {
  background-color: white;

}

.score-box:read-only {
  cursor: text;
}



.submitted-files .icon-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* .submitted-files i {
  transition: color 0.2s, transform 0.2s;
}

.submitted-files i:hover {
  transform: scale(1.2);
}

.submitted-files small {
  color: #666;
}

.submitted-files .fa-pencil-alt {
  color: #888;
}

.submitted-files .fa-check {
  color: #28a745;
} */

/* Viewer */
.annotation-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

iframe.google-docs {
  flex: 1;
  width: 100%;
}

.viewer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.viewer-footer .btn {
  margin-left: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.viewer-footer .btn i {
  margin-right: 5px;
}

.collapse-btn{
  margin-left:20px;
  background: black;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.275rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: white;

}

.done-button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.475rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: #000000;
}

.done-button:hover {
  background: var(--primary-color);
  border: 1px solid black;
  color: #ffffff;
}

.cancel-button {
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.475rem;
  text-decoration: none;
  margin-left: 10px;
  color: #000000;
}</style>