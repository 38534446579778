<template>
	<div class="register-page">
		<BannerSmall>
			<div class="banner-title-card">
				<h1 class="banner-title">Course Registration</h1>
			</div>
		</BannerSmall>
		<div class="container">
			<div class="page-content">
				<h3 v-if="!categorySelected" class="text-bold">Select Qualification</h3>
				<div v-if="!categorySelected" class="category-cards">
					<div
						@click="selectCategory"
						v-for="item in categories"
						:key="item.id"
						:id="item.id"
						:class="[
							'category-card',
							{ selected: categorySelected == item.id },
						]"
					>
						<span>{{ item.title }}</span>
					</div>
				</div>
				<div v-else class="back-container">
					<button @click="back" class="back-button border-btn">
						<i class="fas fa-arrow-left"></i> Back
					</button>
					<h3 v-if="categorySelected" class="category-heading m-0">
						{{ categoryTitle() }}
					</h3>
				</div>
				<div v-if="categorySelected" class="course-register-container">
					<div class="product-list-container">
						<div v-if="productsLoaded" class="product-list">
							<ProductRegistrationListItem
								v-for="item in productsByCategory[categorySelected]"
								:key="item.id"
								:productData="item"
								@addToList="addItemToCart"
								:disabled="isProductDisabled(item.id)"
							/>
						</div>
					</div>
					<div class="cart-container">
						<div class="cart-card" id="cart">
							<p class="top-label">Total Amount</p>
							<h3 class="total-amount">
								{{ parseCurrency(cart.totalAmount, cart.currency, 2) }}
							</h3>
							<span class="selected-batches">selected batches</span>
							<div v-if="cart.items.length > 0">
								<div class="cart-items-list">
									<div
										v-for="item in cart.items"
										:key="item.id"
										class="cart-item"
									>
										<div class="cart-row">
											<div>
												<h3 class="title">
													{{ item.product.title }}
												</h3>
												<!-- <p class="instructor">Ahmed Saya</p> -->
											</div>
											<p class="price">
												{{
													parseCurrency(
														item.pricing.amount,
														item.pricing.currency,
														0
													)
												}}
											</p>
										</div>
										<div class="cart-row m-0 bottom">
											<p class="details m-0 text-wrap">
												{{ item.title }} <br />
												{{ item.shortDescription }}
											</p>
											<button
												@click="removeFromCart(item)"
												type="button"
												class="delete-btn"
												:disabled="loading"
											>
												Delete
											</button>
										</div>
									</div>
								</div>
								<button
									@click="confirmRegistration"
									type="button"
									class="register-btn"
									:disabled="loading"
								>
									<b-spinner v-if="loading" small class="me-2"></b-spinner>
									confirm registration
								</button>
							</div>
							<div v-else class="cart-items-empty mt-3">No batches added</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BannerSmall from "../../components/Layout/BannerSmall.vue";
import ProductRegistrationListItem from "./components/ProductRegistrationListItem.vue";
import { mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";

// var cartCard;
// var cardTopOffset;

export default {
	name: "CourseRegistration",
	data() {
		return {
			categorySelected: null,
			productsLoaded: false,
			enrolledVariants: [],
			invoiceDetails: null,
			addedProducts: [],
			// categories: [
			// 	{
			// 		id: "1",
			// 		title: "O Levels",
			// 		isVisible: true,
			// 		childCategories: null,
			// 	},
			// 	{
			// 		id: "2",
			// 		title: "A Levels",
			// 		isVisible: true,
			// 		childCategories: null,
			// 	},
			// 	{
			// 		id: "3",
			// 		title: "A Levels Accelerated",
			// 		isVisible: true,
			// 		childCategories: null,
			// 	},
			// ],
		};
	},
	components: {
		BannerSmall,
		ProductRegistrationListItem,
	},
	methods: {
		...mapActions("cart", ["addToCart", "removeFromCart", "emptyCart"]),
		...mapActions("product", ["getProductByCategoryId"]),
		...mapActions("category", ["fetchCategories"]),
		...mapActions("user", [
			"enrollUserInProductVariant",
			"fetchUsersList",
			"fetchTrainersList",
		]),
		...mapActions("pricing", ["createBulkInvoice", "fetchInvoiceByUserId"]),
		selectCategory(e) {
			// if (this.categorySelected != e.target.id) {
			// 	this.emptyCart();
			// }
			// if (this.categorySelected == null) {
			// 	setTimeout(() => {
			// 		cartCard = document.getElementById("cart");
			// 		cardTopOffset = cartCard.getBoundingClientRect();
			// 	}, 200);
			// }
			this.categorySelected = e.target.id;
		},
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
		confirmRegistration() {
			if (this.directEnrollVariants && this.directEnrollVariants.length > 0) {
				this.directlyEnrollCourses();
			}
			if (this.paidVariants && this.paidVariants.length > 0) {
				this.cart.items.forEach((item) => {
					if (item.pricingStatus.toLowerCase() == "paid")
						this.getInvoiceDetails(item);
				});
				this.generateInvoice();
			}
		},
		generateInvoice() {
			var invoice = {
				customerID: this.user.id,
				invoiceDate: new Date().toISOString(),
				currency: "PKR",
				status: "unpaid",
				details: this.invoiceDetails,
			};

			this.createBulkInvoice([invoice]).then((res) => {
				console.log(res);
				if (res && res.length && res[0]?.id != 0) {
					this.$nextTick(() => {
						const event = new CustomEvent("invoice-created", {
							detail: this.addedProducts,
						});
						document.dispatchEvent(event);
					});
					// this.enrolledVariants.push(...this.invoiceDetails);
					this.fetchUsersList();
					this.fetchInvoiceByUserId(this.user.id).then(() => {
						// console.log(this.invoicesByUserId);
						this.$router.push("/user/invoices");
						// this.closeModal();
						this.$toasted.success(
							"Courses enrolled & invoice generated successfully",
							{
								theme: "outline",
								position: "bottom-center",
								duration: 3000,
							}
						);
						this.emptyCart(this.currentCurrency);
						this.addedProducts = [];
					});
				} else {
					this.$toasted.show(
						"You are already enrolled in one or more of the selected courses and have an unpaid invoice. Please pay the invoice to access the course.",
						{
							theme: "outline",
							position: "bottom-center",
							fullwidth: true,
							action: {
								text: "DISMISS",
								onClick: (e, toastObject) => {
									toastObject.goAway(0);
								},
							},
						}
					);
					this.$router.push("/user/invoices");
				}
			});
		},
		directlyEnrollCourses() {
			this.directEnrollVariants.forEach((variant) => {
				var requestObj = {
					userID: this.user.id,
					productVariantID: variant.id,
					expiresAt: variant.endDate
						? variant.endDate
						: new Date("12/31/2070").toISOString(),
					allowedRanges: [
						{
							startsFrom: variant.startDate
								? variant.startDate
								: new Date("12/31/1970").toISOString(),
							endsAt: variant.endDate
								? variant.endDate
								: new Date("12/31/2070").toISOString(),
						},
					],
				};
				this.enrollUserInProductVariant({
					user: this.user,
					requestObj,
				}).then(() => {
					console.log(
						`User ${this.user.id} enrolled in variant ${variant.id}.`
					);
					// this.disableEnroll = false;
					this.enrolledVariants.push(variant);
					if (
						this.enrolledVariants.length >= this.directEnrollVariants.length
					) {
						this.fetchUsersList().then(() => {
							console.log("direct enroll variants complete");
							if (this.paidVariants && this.paidVariants.length == 0) {
								this.$toasted.success("Courses enrolled successfully", {
									theme: "outline",
									position: "bottom-center",
									duration: 3000,
								});
								this.$router.push("/user/dashboard");
							}
							this.emptyCart(this.currentCurrency);
						});
					}
				});
			});
		},
		back() {
			this.categorySelected = null;
		},
		categoryTitle() {
			return this.categories.find((c) => c.id == this.categorySelected).title;
		},
		addItemToCart(item) {
			console.log("addItemToCart", item);
		},
		getInvoiceDetails(variant) {
			console.log("getInvoiceDetails", variant);
			var invoiceDetail = [];
			this.addedProducts.push(variant.id);
			if (variant.selectedPlan.type.toLowerCase() == "onetime") {
				var detail = {
					planDetailID: parseInt(variant.selectedPlan.details[0].id),
					amount: 0, //variant.selectedPlan.details[0].amount,
					status: "unpaid",
				};
				var dueDate = new Date().setDate(
					new Date().getDate() + variant.selectedPlan.details[0].gracePeriod
				);
				dueDate = new Date(dueDate).setHours(23, 59, 59);

				console.log(dueDate);
				detail.dueDate = new Date(dueDate).toISOString();
				detail.discount = 0.0;
				detail.discountType = null;
				invoiceDetail.push(detail);
			} else {
				variant.selectedPlan.details.forEach((detail) => {
					var invDetail = {
						planDetailID: parseInt(detail.id),
						amount: 0, //detail.amount,
						status: "unpaid",
					};
					var dueDate = new Date().setDate(
						new Date().getDate() + detail.gracePeriod
					);
					dueDate = new Date(dueDate).setHours(23, 59, 59);

					console.log(dueDate);
					invDetail.dueDate = new Date(dueDate).toISOString();
					invDetail.discount = 0.0;
					invDetail.discountType = null;
					invoiceDetail.push(invDetail);
				});
			}
			if (!this.invoiceDetails) this.invoiceDetails = invoiceDetail;
			else this.invoiceDetails.push(...invoiceDetail);
		},
		isProductDisabled(id) {
			var userEnrolledProducts =
				this.user.productVariants &&
				this.user.productVariants.map((variant) => variant.product.id);
			// console.log("userEnrolledProducts", userEnrolledProducts);
			var isEnrolled = userEnrolledProducts.find((p) => p == id);
			return isEnrolled ? true : false;
		},
	},
	computed: {
		...mapState("cart", ["cart"]),
		...mapState("product", ["productsByCategory"]),
		...mapState("category", ["categories"]),
		...mapState("user", ["user"]),
		...mapState("appState", ["currentCurrency"]),
		...mapState("pricing", ["loading"]),
		directEnrollVariants() {
			return this.cart.items.filter(
				(item) => item.pricingStatus.toLowerCase() != "paid"
			);
		},
		paidVariants() {
			return this.cart.items.filter(
				(item) => item.pricingStatus.toLowerCase() == "paid"
			);
		},
	},
	watch: {
		categorySelected: function () {
			if (
				this.categorySelected &&
				!this.productsByCategory[this.categorySelected]
			) {
				this.productsLoaded = false;
				this.getProductByCategoryId(this.categorySelected).then(() => {
					// console.log("data", this.productsByCategory[this.categorySelected]);
					this.productsLoaded = true;
				});
			} else {
				this.productsLoaded = true;
			}
		},
	},
	created() {
		this.fetchCategories().then(() => {
			console.log("categoriesFetched", this.categories);
		});

		if (this.cart.items && this.cart.items.length > 0) {
			this.cart.items.forEach((item) => {
				if (item.invoiceDetails) {
					this.invoiceDetails = item.invoiceDetails;
				}
			});
		}
		if (!this.trainers) {
			this.fetchTrainersList().then(() => console.log("trainers fetched"));
		}
		// document
		// 	.querySelector("course-register-container")
		// 	.addEventListener("scroll", function() {
		// 		if (cartCard) {
		// 			// console.log(cardTopOffset.top);
		// 			if (window.pageYOffset > cardTopOffset.top) {
		// 				cartCard.classList.add("sticky");
		// 			} else {
		// 				cartCard.classList.remove("sticky");
		// 				// return false;
		// 			}
		// 		}
		// 	});
	},
};

// function
</script>

<style scoped>
@media screen and (max-width: 767px) {
	.course-register-container {
		flex-direction: column-reverse !important;
	}

	.cart-container {
		width: 100% !important;
		margin-bottom: 2rem !important;
	}

	.product-list-container {
		width: 100% !important;
		margin-bottom: 2rem !important;
	}
}

@media screen and (max-width: 600px) {
	.product-list {
		flex-direction: column !important;
	}

	.product-list-item {
		width: 100% !important;
	}
}

.register-page .banner-small {
	height: 8rem;
	margin-bottom: 3rem;
}

.banner-title-card {
	background-color: var(--color-white);
	padding: 2.75rem 3rem 1.75rem 1.5rem;
	width: fit-content;
	border-radius: 8px;
	border: 1px solid #a3a3a3;
	position: absolute;
	top: 4rem;
	box-shadow: 0 0 16px #c9c9c929;
}

.banner-title {
	/* font-size: 2.5rem;
	font-weight: bold; */
	font-weight: bold;
	font-size: 2rem;
	color: #000000;
	/* padding: 2.5rem 0; */
	margin: 0;
}

.banner-title:before {
	content: "";
	width: 2rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -1rem;
	left: 0;
	position: relative;
}

.page-content {
	margin-top: 2.5rem;
}

.category-cards {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-bottom: 2.5rem;
	flex-wrap: wrap;
	row-gap: 1rem;
	/* overflow-y: auto; */
	width: 100%;
	padding: 1.5rem 0;
}

.category-card {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.25rem 1.75rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 0.5rem;
	flex-grow: 1;
	flex-shrink: 0;
	/* height: 15rem; */
	font-weight: 500;
	/* max-width: 32 */
	cursor: pointer;
	position: relative;
	/* width: calc(33% - 1.66667rem); */
	/*max-width: 32%; */
}

.category-card span {
	font-size: 1.5rem;
	pointer-events: none;
}

.category-card.selected {
	border-color: var(--primary-color);
}

.category-card.selected:before {
	content: url("~@/assets/images/check-icon.svg");
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	border-radius: 50%;
}

.course-register-container {
	/* border-top: 1px solid #c4c4c4; */
	display: flex;
	flex-direction: row;
	padding-top: 2.5rem;
	column-gap: 2rem;
}

.product-list-container {
	width: 70%;
}

.product-list {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	row-gap: 1rem;
	flex-wrap: wrap;
}

.cart-container {
	width: 30%;
}

.cart-card {
	background: #ffffff;
	border: 2px solid #ebebeb;
	box-sizing: border-box;
	box-shadow: 0px 3px 15px rgba(197, 197, 197, 0.25);
	border-radius: 12px;
	padding: 1.75rem;
}

.top-label {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.875rem;
	color: #c4c4c4;
	margin: 0 0 0.5rem;
}

.total-amount {
	font-weight: bold;
	font-size: 2.25rem;
	color: #000000;
	margin: 0 0 2rem;
}

.selected-batches {
	font-size: 1.125rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	color: var(--primary-color);
}

.cart-item {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 1rem 1.25rem;
	display: flex;
	flex-direction: column;
}

.cart-items-list {
	margin-top: 1.375rem;
	display: flex;
	flex-direction: column;
	row-gap: 1.125rem;
}

.cart-item .title,
.cart-item .price {
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 1.377;
	color: #000000;
	margin: 0;
}

.cart-item .price {
	font-weight: bold;
}

.cart-item .cart-row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 1rem;
	column-gap: 2rem;
}

.cart-item .cart-row.bottom {
	align-items: flex-end;
}

.cart-item .instructor {
	margin: 0;
	font-size: 0.875rem;
}

.cart-item .delete-btn {
	border: 2px solid #cd222d;
	box-sizing: border-box;
	border-radius: 8px;
	text-transform: uppercase;
	padding: 0.25rem 1rem;
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 1.125rem;
	color: #cd222d;
}

.cart-card .register-btn {
	background: var(--primary-color);
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-transform: uppercase;
	color: #ffffff;
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 0.01em;
	margin-top: 2rem;
}

.back-container {
	display: flex;
	column-gap: 1rem;
}

.sticky {
	position: fixed;
	top: 0;
}

.category-heading {
	font-weight: bold;
	line-height: 1.5;
}
</style>
