<template>
  <div class="pt-4 page-content-container fix-height">
    <div class="container">
      <div class="d-flex justify-content-between mb-3">
        <div class="page-title">
          <h3>{{ isManagementUser ? "" : "My " }}Support Tickets</h3>
          <p v-if="!isManagementUser">
            Create and track support requests as needed. You can view and
            access requests
          </p>
        </div>
        <div v-if="!isManagementUser" style="padding-top: 18px">
          <button class="add-btn" role="button" @click="showAddTicketModal()">
            <img
              src="@/assets/images/add-border-icon.svg"
              class="add-btn-icon"
            />
            Add New Ticket
          </button>
        </div>
        <div v-if="isManagementUser">
          <button class="add-btn" role="button" @click="refresh()">
            Refresh
          </button>
        </div>
      </div>
    </div>
    <div class="container d-flex">
      <div class="d-flex mb-4 gap-3">
        <button
          @click="setServiceID(null)"
          :class="[
            'filter-user-type-btn',
            { active: filter.serviceID == null },
          ]"
        >
          All
        </button>
        <button
          v-for="service in ticketServices"
          @click="setServiceID(service.id)"
          :class="[
            'filter-user-type-btn',
            { active: filter.serviceID == service.id },
          ]"
        >
          {{ service.title }}
        </button>
      </div>
      <div class="d-flex mb-4 gap-3 ms-auto">
        <button
          @click="setStatus(null)"
          :class="['filter-user-type-btn', { active: filter.status == null }]"
        >
          All
        </button>
        <button
          @click="setStatus('opened')"
          :class="[
            'filter-user-type-btn',
            { active: filter.status == 'opened' },
          ]"
        >
          Opened
        </button>
        <button
          @click="setStatus('closed')"
          :class="[
            'filter-user-type-btn',
            { active: filter.status == 'closed' },
          ]"
        >
          Closed
        </button>
      </div>
    </div>
    <div class="table-container container">
      <table class="table table-hover table-borderless">
        <thead>
          <tr>
            <th style="width: 2%; text-align: center;">#</th>
            <th style="width: 8%;">Ticket #</th>
            <th style="width: 15%">Service</th>
            <th style="width: 15%">Subject</th>
            <th v-if="isManagementUser" style="width: 15%">Opened By</th>
            <th style="width: 15%">Last person to intervene</th>
            <th v-if="!isManagementUser" style="width: 10%">Creation date</th>            
            <th style="width: 10%">Status</th>
          </tr>
          <!-- <th style="width: 5%"></th> -->
        </thead>
        <tbody>
          <tr
            v-for="(ticket, index) in tickets"
            id="ticket-list"
            :key="index"
            @click="showViewTicketModal(ticket.id)"
            title="Click to View Ticket"
            v-if="!isTicketLoader"
          >
            <td style="text-align: center;">{{ index+1 }}</td>
            <td>{{ parseTicketHash(ticket.id) }}</td>
            <td>{{ ticket.service.title }}</td>
            <td>{{ ticket.subject }}</td>
            <td v-if="isManagementUser">
              {{ ticket.user.firstName }} {{ ticket.user.lastName }}<br/>
              <small>{{ formatDate(ticket.createdAt) }}</small>
            </td>
            <td>
              <span
                v-if="ticket.lastTicketReply && ticket.lastTicketReply.user"
              >
                {{
                  ticket.lastTicketReply.user.firstName +
                  " " +
                  ticket.lastTicketReply.user.lastName
                }} <small v-if="ticket.lastTicketReply"
                ><br/>
                {{ formatDate(ticket.lastTicketReply.createdAt) }}
              </small>
              </span>
            </td>
            <td  v-if="!isManagementUser">{{ formatDate(ticket.createdAt) }}</td>
            
            <td><div
              :class="[
                'status-tag',
                {
                  warning: ticket.status && ticket.status.toLowerCase() == `opened`,
                  success: ticket.status && ticket.status.toLowerCase() == `closed`,
                },
              ]"
            >
              {{ ticket.status }} 
            </div></td>
            
          </tr>
        </tbody>
      </table>

      <div
        v-if="isTicketLoader"
        class="d-flex align-items-center justify-content-center gap-3 p-3 font-500 m-5"
      >
        <b-spinner></b-spinner> Loading...
      </div>
      <h5
        v-else-if="!isTicketLoader && (!tickets || tickets.length <= 0)"
        class="text-center p-4 m-0"
      >
        No Tickets found
      </h5>
    </div>
    <AddTicketModal
      :showModal="showNewTicket"
      @updateModalState="toggleAddTicket"
    />

    <ViewTicketModal
      :showModal="showViewTicket && selectedTicketID != null"
      :ticketID="selectedTicketID"
      @updateModalState="toggleViewTicket"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AddTicketModal from "../components/Support/AddTicketModal.vue";
import ViewTicketModal from "../components/Support/ViewTicketModal.vue";
import { toShortDateString } from "../helpers/FormatHelpers";

export default {
  components: {
    AddTicketModal,
    ViewTicketModal,
  },
  data() {
    return {
      showNewTicket: false,
      showViewTicket: false,
      isTicketLoader: false,
      filter: {
        serviceID: null,
        status: null,
      },
      selectedTicketID: null,
      ticketServices: [],
      isManagementUser: true,
    };
  },

  methods: {
    ...mapActions("support", ["fetchTickets", "fetchTicketServices"]),

    showAddTicketModal() {
      this.showNewTicket = true;
    },
    parseTicketHash(ticketID)
    {
      var ticketHash="";
      if(ticketID){
      for (let index = 0; index < 4-(ticketID).toString().length; index++) {
        ticketHash+="0";        
      }
      ticketHash+=ticketID;
    }
      return ticketHash;
    
    },
    toggleAddTicket(state) {
      if (state != undefined) {
        this.showNewTicket = state;
      } else this.showNewTicket = !this.showNewTicket;
    },

    toggleViewTicket(state) {
      if (state != undefined) {
        this.showViewTicket = state;
      } else this.showViewTicket = !this.showViewTicket;
    },

    showViewTicketModal(ticketID) {
      this.selectedTicketID = ticketID + "";
      this.showViewTicket = true;
    },

    async getSupportTickets() {
      this.isTicketLoader = true;
      var data = await this.fetchTickets(this.filter);
      if (data && data.length > 0) {
        // this.tickets = data;
        this.isTicketLoader = false;
      } else {
        // this.tickets = [];
        this.isTicketLoader = false;
      }
    },
    formatDate(date) {
      return this.formatDateWithTime(new Date(date));
    },
    formatDateWithTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return toShortDateString(date) + "  " + strTime;
    },
    setServiceID(val) {
      this.filter.serviceID = val;
      this.getSupportTickets();
    },
    setStatus(val) {
      this.filter.status = val;
      this.getSupportTickets();
    },
    refresh() {
      this.getSupportTickets();
    },
  },
  computed: {
    ...mapState("support", ["tickets"]),
  },

  async created() {
    await this.getSupportTickets();

    var data = await this.fetchTicketServices();

    this.ticketServices = data && data.length > 0 ? data : [];
    let user = JSON.parse(localStorage.getItem("user"));
    var userType = user.type || user.role;
    if (userType && userType.toLowerCase() != "management") {
      this.isManagementUser = false;
    }
  },
};
</script>

<style>
#ticket-list:hover {
  cursor: pointer;
}

.fix-height {
  height: 100vh !important;
}

.page-content-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
}
.filter-user-type-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.filter-user-type-btn.active {
  border: 1px solid var(--primary-color);
}
</style>
