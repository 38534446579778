<template>
    <div class="ms-5">
      <div>
        <h2 class="pt-5 pb-5">Visa/Master Card</h2>
        <h5 v-if="message" class="text-success">{{ message }}</h5>
        <h5 v-if="error" class="text-danger">{{ error }}</h5>
      </div>
  
      <div v-if="paymentData">
        <form id="paymentForm" :action="paymentData.data.gatewayUrl" method="POST">
          <input
            type="hidden"
            name="orderID"
            :value="paymentData.data.orderID"
          />
          <input
            type="hidden"
            name="merchantName"
            :value="paymentData.data.merchantName"
          />
          <input
            type="hidden"
            name="amount"
            :value="paymentData.data.amount"
          />
          <input
            type="hidden"
            name="transactionDescription"
            :value="paymentData.data.transactionDescription"
          />
          <input
            type="hidden"
            name="customerMobileNumber"
            :value="paymentData.data.customerMobileNumber"
          />
          <input
            type="hidden"
            name="customerEmail"
            :value="paymentData.data.customerEmail"
          />
          <input
            type="hidden"
            name="successUrl"
            :value="paymentData.data.successUrl"
          />
          <input
            type="hidden"
            name="failureUrl"
            :value="paymentData.data.failureUrl"
          />
          <input
            type="hidden"
            name="orderDate"
            :value="paymentData.data.orderDate"
          />
          <input
            type="hidden"
            name="checkoutUrl"
            :value="paymentData.data.checkoutUrl"
          />
          <input
            type="hidden"
            name="token"
            :value="paymentData.data.token"
          />
          <input type="hidden" name="grossAmount" :value="paymentData.data.amount" />
          <input type="hidden" name="taxAmount" :value="0" />
          <input type="hidden" name="discount" :value="0" />
          <input type="hidden" name="signature" :value="paymentData.data.signature" />
          <input type="hidden" name="institutionID" :value="paymentData.data.institutionID" />
          <!-- <input
            style="width: 160px; height: 45px"
            class="mt-3 btn-block-rounded-primary"
            type="hidden"
          /> -->
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { mounted } from "vue2-dropzone";
  import Swal from "sweetalert2";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "KuickPayCheckout",
    data() {
      return {
        message: null,
        error: null,
        paymentData: null,
        isSubmitForm: false,
      };
    },
    computed: {
      ...mapState("user", ["user"]),
      ...mapState("pricing", ["invoicesByUserId", "loading"]),
      isPostPayment() {
        let action = this.$route.params.action;
  
        return action && action.toLowerCase() == "postPayment";
      },
    },
    methods: {
      ...mapActions("pricing", ["fetchInvoiceByUserId", "processPayment"]),
    },
    created() {
      let action = this.$route.query.action;
      let status = this.$route.query.status;
      let msg = this.$route.query.message;
  
      if (action && action.toLowerCase() == "postpayment") {
        if (msg) {
          msg = msg.replace(/\+/g, "%20");
          msg = decodeURIComponent(msg);
  
          if (status && status.toLowerCase() == "failure") {
            //this.error = `${msg}. You may navigate to your courses.`;
            Swal.fire({
              title: "Transaction Failed",
              text: `${msg}. You may navigate to your courses.`,
              icon: "error",
              button: "Ok",
              dangerMode: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((confirm) => {
              if (confirm) {
                this.$router.push("/user/dashboard");
              }
            });
          } else {
            //this.message = `${msg}. You may navigate to your courses now to access the content.`;
            Swal.fire({
              title: "Transaction Successful",
              text: `${msg}. You may navigate to your courses.`,
              icon: "success",
              button: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((confirm) => {
              if (confirm) {
                this.$router.push("/user/dashboard");
              }
            });
          }
        }
      } else {
        let invoiceID = this.$route.params.id;
  
        if (invoiceID) {
          this.message = "Fetching payment details, please wait...";
          let invoice;
  
          if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
            invoice = this.invoicesByUserId[this.user.id].find(
              (inv) => inv.id == invoiceID
            );
          } else {
            this.fetchInvoiceByUserId(this.user.id).then(() => {
              invoice = this.invoicesByUserId[this.user.id].find(
                (invoice) => invoice.id == invoiceID
              );
            });
          }
  
          if (invoiceID == invoice.id) {
            this.processPayment({
              invoiceId: invoiceID,
              serviceCode: "KuickPayCheckout",
              data: {
                redirectUrl: `${window.location.origin}/payment/KuickPayCheckout`,
              },
            })
              .then((response) => {
                if (response && response.data && response.data.gatewayUrl) {
                  this.error = undefined;
                  this.paymentData = response;
                  console.log({paymentData: this.paymentData});
  
                  let count = 5;
  
                  setInterval(() => {
                    if(count > 0) {
                        count--;
                    }

                    this.message = `You will be redirect to KuickPay Payment Gateway in ${count} seconds. Don't reload or leave browser.`;
  
                    if (count == 1) {
                        console.log("submit form now");
                        document.getElementById("paymentForm").submit();
                        clearInterval();
                    }
                  }, 1000);
                }
              })
              .catch((error) => {
                console.log(error);
                this.error =
                  "Some error occurred while fetching data from payment gateway.";
              });
          } else {
            this.error = "Invoice not found.";
          }
        } else {
          this.error = "Invalid parameters";
        }
      }
    },
  };
  </script>
  
  <style scoped></style>
  