<template>
	<div class="home">
		<div class="container">
			custom homepage
		</div>
	</div>
</template>

<script>
export default {
	name: "Home",
	components: {},
	data() {
		return {
			// banners: banners,
		};
	},
};
</script>

<style scoped>
.home .container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.section-heading {
	font-weight: 500;
	font-size: 2.75rem;
	line-height: 1.3;
	color: var(--secondary-color);
	margin-bottom: 0;
	letter-spacing: -0.45px;
}

.section-subheading {
	font-size: 1.375rem;
	color: #898989;
	font-weight: normal;
}

.section-heading-container {
	margin: 4rem 0 3.375rem;
}

/* .section-heading::before {
	content: "";
	width: 3.1rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -0.7rem;
	left: 0.3rem;
	position: relative;
} */
</style>
