<template>
    <div class="tab-pane" id="bookmarksPane" role="tabpanel" aria-labelledby="bookmarksTab" tabindex="0">
        <center v-if="loading" class="loading">
		    <b-spinner large></b-spinner>
	    </center>
        <div v-else-if="hasBookmarks()">

            <div class="eb-header">
                <h5>Bookmarks</h5>
            </div>
            <div class="eb-content chapter-details bm-details">
                <div class="cd-body eb-bookmarks px-3 py-2 round">
                    <div class="bookmarks-table-wrapper">
                        <ul class="eb-table bookmarks-table">
                            <li class="thead">
                                <div class="t-cell">Name</div>
                                <div class="t-cell">Bookmarked</div>
                                <div class="t-cell"></div>
                            </li>
                            <li v-for="bookmark in bookmarks" class="t-row">
                                <div class="t-cell">
                                    <a role="button" @click="onBookmarkContentView(bookmark)" 
                                       class="red">{{ bookmark.title }}</a>
                                </div>
                                <div class="t-cell">{{ getDateOnly(bookmark.performAt) }}</div>
                                <div class="t-cell">
                                    <div class="eb-edit-bar">
                                        <button class="eb-notes-view" @click="onBookmarkContentView(bookmark)"><i
                                                class="fas fa-eye"></i></button>
                                        <button class="eb-notes-delete" @click="deleteBookmark(bookmark)">
                                            <i class="fas fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </li>

                        </ul>



                    </div>

                </div>
            </div><!-- /eb-content -->
        </div><!-- /tab-pane Bookmarks -->

        <center v-else class="no-content text-center">
            <h4 class="mt-4">No Bookmarks Found</h4>
        </center>
    </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: "ProductContentChaptersBookmarks",
    props: {
        active: Boolean,
        productVariantID: String,
    },
    data() {
        return {
            loading: false,
            bookmarks: []
        };
    },
    methods: {
        ...mapActions("content", ["fetchUserContentActionsByID", "setUserContentAction"]),
        fetchData() {
            this.loading = true;
            this.fetchUserContentActionsByID({ productVariantID: this.productVariantID, dataType: "bookmark" }).then((data) => {
                console.log("Actions Data: ", data);

                var tempBookmarks = [];

                for (let item of data) {
                    var content = item.content;

                    var bookmarkData =
                    {
                        "id": content.id,
                        "type": content.type,
                        "title": content.title,
                        "performAt": item.performAt,
                        "isBookmarked": item.data == "true",
                        "topLevelContentID": content.topLevelContentID,
                    }

                    if (bookmarkData.isBookmarked) {
                        tempBookmarks.push(bookmarkData);
                    }
                }
                this.bookmarks = tempBookmarks;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.error("Error fetching user content actions: ", error);
            });
        },
        getDateOnly(dateString) {
            var date = new Date(dateString);
            var options = { day: "2-digit", month: "2-digit", year: "numeric" };
            var formattedString = date.toLocaleDateString(undefined, options);
            return formattedString;
        },
        onBookmarkContentView(content) {
            this.$router.push({
                query: {
                    tab: 'tableofcontents',
                    chapterId: content.topLevelContentID,
                    contentId: content.id
                }
            });
        },

        deleteBookmark(bookmark) {
            var contentAction = {
                ContentID: bookmark.id,
                DataType: "bookmark",
                Data: "false",
            }

            this.setUserContentAction(contentAction).then((action) => {
                if (action && action.dataType && action.dataType.toLowerCase() == "bookmark") {
                    var index = this.bookmarks.findIndex((item) => item.id === bookmark.id)
                    if (index !== null && index >= 0 && index < this.bookmarks.length && this.bookmarks.length > 0) {
                        this.bookmarks.splice(index, 1);
                        var temps=this.bookmarks;
                        this.bookmarks=null;
                        this.bookmarks=temps;
                    }

                }
            });
        },

        hasBookmarks() {
            return this.bookmarks.length > 0;
        },
    },
    computed: {
    },
    watch: {
        active: function () {
            if (this.active) {
                this.fetchData()
            }
        },
        productVariantID: function () { 
            if (this.active) {
                this.fetchData()
            }
    }
    },
    components: {},
    created() {
        if (this.active) {
            this.fetchData()
        }
    },
    mounted() { 
    }
};
</script>

<style scoped>
.t-row .t-cell {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 0.3px solid var(--border-color);
    border-top: 0.3px solid var(--border-color);
}

.no-content {
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading {
	margin-top: 200px;
	margin-bottom: 200px;
}
</style>
