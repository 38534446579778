<template>
  <div class="container">
    <div class="product-content-page-container">
      <div
        v-if="viewMode != 'sidebar'"
        class="col-lg-8 col-sm-12 product-content-container"
      >
        <div v-if="!loading && productVariantData != null">
          <ProductDetailsTopCard
            v-if="!purchased && productVariantData.objectives != null"
            :listData="productVariantData.objectives"
          />
          <ProductContentTabs
            v-if="productVariantData.productVariantItems.length > 0"
            :productVariantData="productVariantData"
            :purchased="purchased"
            :viewMode="viewMode"
          />
          <div
            v-if="productVariantData.productVariantItems.length == 0"
            class="no-data p-5"
          >
            No content found
          </div>
        </div>
        <div v-else class="skeleton">
          <div class="skeleton-tabs d-flex">
            <b-skeleton
              type="button"
              class="m-3"
              width="15%"
              height="50px"
            ></b-skeleton>
            <b-skeleton
              type="button"
              class="m-3"
              width="15%"
              height="50px"
            ></b-skeleton>
            <b-skeleton
              type="button"
              class="m-3"
              width="15%"
              height="50px"
            ></b-skeleton>
            <b-skeleton
              type="button"
              class="m-3"
              width="15%"
              height="50px"
            ></b-skeleton>
          </div>
          <div class="skeleton-body mt-2 p-3">
            <b-skeleton width="20%"></b-skeleton>
            <b-skeleton
              class="mt-3"
              type="input"
              height="56px"
              width="100%"
            ></b-skeleton>
            <b-skeleton
              class="mt-3"
              type="input"
              height="56px"
              width="100%"
            ></b-skeleton>
            <b-skeleton
              class="mt-3"
              type="input"
              height="56px"
              width="100%"
            ></b-skeleton>
          </div>
        </div>
      </div>

      <div
        v-if="viewMode != 'sidebar'"
        class="product-details-card-container col-lg-4 col-sm-12"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductContentSidebar from "./ProductContentSidebar.vue";
import ProductContentTabs from "./ProductContentTabs.vue";
import ProductDetailsTopCard from "./ProductDetailsTopCard.vue";

export default {
  name: "ProductContent",
  props: {
    purchased: Boolean,
    viewMode: String,
    productVariantData: Object,
  },
  components: {
    ProductContentSidebar,
    ProductContentTabs,
    ProductDetailsTopCard,
  },

  computed: {
    ...mapState("productVariant", ["loading"]),
  },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
  /* .product-content-container {
		padding: 3rem 1rem !important;
	} */
  .skeleton-tabs {
    display: none !important;
  }
  .skeleton-body {
    width: 100% !important;
  }
  .product-content-page-container {
    flex-direction: column-reverse !important;
    padding: 0 0.25rem;
    margin-top: 2rem;
  }
  .product-details-card-container {
    min-height: fit-content !important;
  }
}

.product-content-container,
.product-details-card-container {
  min-height: 50vh;
  padding: 0;
}

.product-content-page-container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.no-data {
  text-align: center;
  padding: 2rem;
  font-weight: 500;
  color: var(--color-gray);
  font-size: 1.5rem;
}
</style>
