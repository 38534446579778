<template>
  <div class="ms-5">
    <h2 class="pt-5 pb-5 ">Meezan (Debit/Credit Card)</h2>
      <h5 v-if="message">{{message}}</h5>
      <h5 v-if="error" class="text-danger">{{error}}</h5>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "MeezanPaymentGateway",
  data() {
    return {
      message: undefined,
      error: undefined
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("pricing", ["invoicesByUserId", "loading"]),
    isPostPayment() {
      let action = this.$route.params.action;

      return action && action.toLowerCase() == "postPayment";
    }
  },
  methods: {
    ...mapActions("pricing", [
      "fetchInvoiceByUserId",
      "processPayment"
    ]),
  },
  created() {
    let action = this.$route.query.action;

    console.log({action});

    if(action && action.toLowerCase() == "postpayment") {

      let status = this.$route.query.status;
      let msg = this.$route.query.message;
      if(msg){
        msg = msg.replace(/\+/g, '%20');
        msg = decodeURIComponent(msg);
      }
      if(status && status.toLowerCase() == "failure") {        
        this.error = `${msg}. You may navigate to your courses now to access the content.`;
      } else {
        this.message = `${msg}. You may navigate to your courses now to access the content.`;
      }
    } else {

      let invoiceID = this.$route.params.id;

      if(invoiceID) {

        let invoice;

        if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
          invoice = this.invoicesByUserId[this.user.id].find(
              (inv) => inv.id == invoiceID
          );
        } else {
          this.fetchInvoiceByUserId(this.user.id).then(() => {
            invoice = this.invoicesByUserId[this.user.id].find(
                (invoice) => invoice.id == invoiceID
            );
          });
        }

        if(invoiceID == invoice.id) {
          this.message = 'Fetching payment details, please wait...';
          this.processPayment({invoiceId: invoiceID, serviceCode: 'Meezan', data: { redirectUrl: `${window.location.origin}/payment/Meezan` }}).then((response) => {
            console.log(response);

            if(response && response.data && response.data.paymentUrl) {
              this.error = undefined;
              window.location.href = response.data.paymentUrl;
            }
          }).catch((error) => {
            console.log(error);
            this.error = 'Some error occurred while fetching data from payment gateway.';
          });
        } else {
          this.error = "Invoice not found.";
        }
      } else {
        this.error = "Invalid parameters";
      }
    }


  }
}
</script>

<style scoped>

</style>