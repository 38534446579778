<template>
	<div class="left-panel-container">
		<div class="category-list" v-if="this.categories.length > 0 && !loading">
			<a
				role="button"
				v-for="item in categories"
				:key="item.id"
				:class="['category-item', { active: activeCategory == item.id }]"
				:id="item.id"
				@click="$emit('categoryClick', item.id)"
			>
				{{ item.title }}
			</a>
		</div>
		<div v-else class="skeleton">
			<b-skeleton
				v-for="item in 3"
				:key="item"
				width="100%"
				class="mt-3"
			></b-skeleton>
		</div>
		<a
			@click="handleClick('/search')"
			role="button"
			class="browse-all-btn border-btn"
			>Browse All Subjects</a
		>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "ProductDropdownLeftPanel",
	data() {
		return {
			// activeCategory: "",
		};
	},
	props: { categories: Array, loading: Boolean, activeCategory: String },
	methods: {
		// ...mapActions("category", ["fetchCategories"]),
		categoryClick(e) {
			e.preventDefault();
			this.activeCategory = e.target.id;
		},
		handleClick(link) {
			if (!this.isMobile) $("#coursesMenu.courses-btn")[0].click();
			else $("#coursesMenu.hamburger")[0].click();
			this.$router.push(link);
		},
	},
	computed: {
		...mapState("appState", ["isMobile"]),
		// ...mapState("category", ["categories", "loading"]),
	},
};
</script>

<style scoped>
.category-item {
	padding: 0.5rem 0.675rem;
	background-color: var(--color-white);
	border-radius: 6px;
	color: #000000;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	line-height: 1;
	font-weight: 500;
	display: block;
	text-decoration: none;
}

.category-item.active {
	background-color: var(--primary-color);
	color: var(--color-white);
	box-shadow: 0 3px 6px #d5d5d529;
	font-weight: normal;
}

.left-panel-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.left-panel-container .category-list {
	/* -ms-overflow-style: none;  */
	/* scrollbar-width: none;  */
	height: 100%;
	overflow: hidden;
}

.left-panel-container .category-list:hover {
	overflow: overlay;
}

.browse-all-btn {
	text-align: left;
	border-radius: 6px;
	padding: 0.5rem 0.675rem;
	line-height: 1;
	color: #000000 !important;
	font-weight: 500;
	font-size: 1rem;
	box-shadow: 0 3px 6px #d5d5d529;
	margin-top: 2rem;
}
</style>
