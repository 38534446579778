var render = function render(){var _vm=this,_c=_vm._self._c;return _c('editor',{attrs:{"api-key":"yw2m1nqx2e2si0mbgxu00oexgl0ofl14wsu6b8j50u0j6bly","cloud-channel":"5","disabled":false,"id":"uuid","init":{
		selector: 'textarea', // change this value according to your HTML
		menu: {
			happy: { title: 'Happy', items: 'code' },
		},
		plugins: 'code', // required by the code menu item
		menubar: 'happy', // adds happy to the menu bar
	},"initial-value":"","inline":true,"model-events":"","plugins":"","tag-name":"div","toolbar":"","value":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }