<template>
	<b-modal
		id="profile-edit-modal"
		@keydown="closeModal"
		:visible="visible"
		hide-header
		centered
		content-class="profile-edit-modal"
		size="xl"
		body-class="modal-body"
		ok-title="Update"
		footer-class="edit-modal-footer"
		:ok-disabled="disabled()"
		@ok="updateProfile"
		no-close-on-backdrop
		@cancel="closeModal"
	>
		<div class="top-icon">
			<div class="profile-edit-circle"><i class="far fa-user"></i></div>
			<h1 class="edit-modal-title mt-2">Change Password</h1>
		</div>
		<div class="modal-content-container">
			<form class="edit-profile-form">
				<div class="form-group row g-3">
					<div class="col-md-6">
						<label for="confirmPassword" class="form-label">Old Password</label>
						<input
							type="password"
							class="form-control edit-profile-input"
							id="confirmPassword"
							v-model="oldPassword"
							placeholder="Enter your old password"
							autocomplete
						/>
					</div>
					<div class="col-md-6">
						<label for="password" class="form-label">New Password</label>
						<input
							type="password"
							class="form-control edit-profile-input"
							id="password"
							v-model="newPassword"
							placeholder="Enter new password"
							@keydown="update"
							autocomplete
						/>
					</div>
				</div>
			</form>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "EditProfileModal",
	data() {
		return {
			changePassword: true,
			newPassword: "",
			oldPassword: "",
			updatedUserData: {},
		};
	},
	computed: {
		...mapState("user", ["loading"]),
		visible() {
			return this.showModal;
		},
	},
	props: { showModal: Boolean, profileData: Object },
	created() {
		this.newPassword = "";
		this.oldPassword = "";
	},
	methods: {
		...mapActions("user", [
			"updateUserProfile",
			"changeUserPassword",
			"fetchCurrentUser",
		]),
		updateProfile(e) {
			e.preventDefault();
			console.log("updateProfile");
			if (this.changePassword) {
				if (this.oldPassword !== this.newPassword) {
					this.updatedUserData.newPassword = this.newPassword;
					this.updatedUserData.oldPassword = this.oldPassword;
					this.changeUserPassword(this.updatedUserData).then((res) => {
						this.closeModal();
						this.$toasted.success("Password updated successfully", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
					});
				} else {
					// alert("New password cannot be the same as old password");
					this.$toasted.error(
						"New password cannot be the same as old password",
						{
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						}
					);
				}
			}
		},
		update(e) {
			var data = e.target.value;
			this.updatedUserData[e.target.id] = data;
		},
		disabled() {
			if (this.loading) return true;
			return Object.entries(this.updatedUserData).length == 0;
		},
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
	},
	watch: {
		newPassword: function (newVal, oldVal) {
			if (oldVal !== "") {
				// this.disabled = false;
				this.changePassword = true;
			}
		},
	},
};
</script>
