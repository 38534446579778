<template>
	<div class="container">
		<footer class="footer-container">
			<div class="footer-nav-container">
				<div class="d-flex flex-row align-items-center pb-4">
					<a class="logo"><img :src="logoPath" /></a>
					<ul class="nav footer-nav">
						<!-- <li class="nav-link">Home</li> -->
						<!-- <li class="nav-link">Popular Courses</li> -->
						<!-- <li class="nav-link">Teach with us</li> -->
						<!-- <li v-if="systemParameters.PrivacyPolicyUrl" @click="togglePrivacyModal($event,systemParameters.PrivacyPolicyUrl,'Privacy Policy')" class="nav-link">Privacy Policy</li>
						<li v-if="systemParameters.RefundPolicyUrl" @click="togglePrivacyModal($event,systemParameters.RefundPolicyUrl,'Refund Policy')" class="nav-link">Refund Policy</li>
						<li v-if="systemParameters.TermsOfUseUrl" @click="togglePrivacyModal($event,systemParameters.TermsOfUseUrl,'Terms Of Use')" class="nav-link">Terms Of Use</li>
						 -->
					</ul>
				</div>
				<p v-if="systemParameters.TenantInfo" class="footer-bottom-content-hr"></p>
				<div class="d-flex">
					<div class="footer-col-left">
						<div class="footer-bottom-content"
							v-if="systemParameters.TenantInfo && systemParameters.TenantInfo.Address">

							<i class="fas fa-map-marker-alt me-2"></i>
							<a v-if="systemParameters.TenantInfo.Address"
								:href="systemParameters.TenantInfo.AddressMapLink ? systemParameters.TenantInfo.AddressMapLink :
								systemParameters.TenantInfo.AddressMapLatitude && systemParameters.TenantInfo.AddressMapLongitude?
								`https://maps.google.com/?q=${systemParameters.TenantInfo.AddressMapLatitude},${systemParameters.TenantInfo.AddressMapLongitude}`:
								''"
								target="_blank">
								{{ systemParameters.TenantInfo.Address }}
							</a>

							<!-- <a role="button">Terms and Conditions</a> -->
						</div>

						<div class="footer-bottom-content"
							v-if="systemParameters.TenantInfo && systemParameters.TenantInfo.ContactNumbers">

							<i class="fas fa-phone-alt me-2"></i>
							<a v-for="contactNum in systemParameters.TenantInfo.ContactNumbers" :href="'tel:' + contactNum">
								{{ contactNum }}
							</a>

						</div>

						<div class="footer-bottom-content"
							v-if="systemParameters.TenantInfo && systemParameters.TenantInfo.WhatsAppNumbers">

							<i class="fab fa-whatsapp me-2"></i>

							<a v-for="whatsAppNum in systemParameters.TenantInfo.WhatsAppNumbers"
								:href="'https://wa.me/' + whatsAppNum">
								{{ whatsAppNum }}
							</a>
						</div>

						<div class="footer-bottom-content"
							v-if="systemParameters.TenantInfo && systemParameters.TenantInfo.Emails">

							<i class="far fa-envelope me-2"></i>

							<a v-for="email in systemParameters.TenantInfo.Emails" :href="'mailto:' + email">
								{{ email }}
							</a>
						</div>

					</div>
					<div class="footer-center-div">
						
					</div>
					<div class="footer-col-right align-items-end">
						<div class="footer-bottom-content last">
							<a class="me-2 footer-bottom-content-icon social-links"
								v-if="systemParameters.SocialLinks && systemParameters.SocialLinks.Facebook"
								:href="systemParameters.SocialLinks && systemParameters.SocialLinks.Facebook ? systemParameters.SocialLinks.Facebook : ''"
								target="_blank">
								<i class="fab fa-facebook-square"></i>
							</a>
							<a class="me-2 footer-bottom-content-icon social-links"
								v-if="systemParameters.SocialLinks && systemParameters.SocialLinks.Twitter"
								:href="systemParameters.SocialLinks && systemParameters.SocialLinks.Twitter ? systemParameters.SocialLinks.Twitter : ''"
								target="_blank">
								<i class="fab fa-twitter-square"></i>
							</a>
							<a class="me-2 footer-bottom-content-icon social-links"
								v-if="systemParameters.SocialLinks && systemParameters.SocialLinks.Instagram"
								:href="systemParameters.SocialLinks && systemParameters.SocialLinks.Instagram ? systemParameters.SocialLinks.Instagram : ''"
								target="_blank">
								<i class="fab fa-instagram"></i>
							</a>
							<a class="me-2 footer-bottom-content-icon social-links"
								v-if="systemParameters.SocialLinks && systemParameters.SocialLinks.LinkedIn"
								:href="systemParameters.SocialLinks && systemParameters.SocialLinks.LinkedIn ? systemParameters.SocialLinks.LinkedIn : ''"
								target="_blank">
								<i class="fab fa-linkedin"></i>
							</a>
							<a class="me-2 footer-bottom-content-icon social-links"
								v-if="systemParameters.SocialLinks && systemParameters.SocialLinks.YouTube"
								:href="systemParameters.SocialLinks && systemParameters.SocialLinks.YouTube ? systemParameters.SocialLinks.YouTube : ''"
								target="_blank">
								<i class="fab fa-youtube"></i>
							</a>
							<!-- <a role="button">Terms and Conditions</a> -->
						</div>
						<div>
							<ul class="nav footer-nav center-nav last">
								<li v-if="systemParameters.PrivacyPolicyUrl" @click="togglePrivacyModal($event, systemParameters.PrivacyPolicyUrl, 'Privacy Policy')" class="nav-link">Privacy Policy</li>
								<li v-if="systemParameters.PrivacyPolicyUrl" class="breaker"></li>
								<li v-if="systemParameters.RefundPolicyUrl" @click="togglePrivacyModal($event, systemParameters.RefundPolicyUrl, 'Refund Policy')" class="nav-link">Refund Policy</li>
								<li v-if="systemParameters.RefundPolicyUrl" class="breaker"></li>
								<li v-if="systemParameters.ComplainPolicyUrl" @click="togglePrivacyModal($event, systemParameters.ComplainPolicyUrl, 'Complaint Policy')" class="nav-link">Complaint Policy</li>
								<li v-if="systemParameters.ComplainPolicyUrl" class="breaker"></li>								
								<li v-if="systemParameters.TermsOfUseUrl" @click="togglePrivacyModal($event, systemParameters.TermsOfUseUrl, 'Terms Of Use')" class="nav-link">Terms Of Use</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<b-modal
			@change="togglePrivacyModal($event)"
			:visible="isShowPrivacyModel && currentPrivacyModelURL!=null"
			hide-header
			centered
			hide-footer
			modal-class="privacy-modal"
			size="xl"
		>
			<div class="body-container">
				<button
					@click="togglePrivacyModal($event)"
					class="close-button"
					role="button"
				>
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
				<h1 v-if="currentPrivacyModelHeading" class="add-content-title">{{currentPrivacyModelHeading}}</h1>
				<iframe :src="currentPrivacyModelURL">

				</iframe>
			</div>
		</b-modal>
	</div>
	
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "Footer",
	computed: {
		...mapState("appState", ["systemParameters"]),
		logoPath() {
			return this.systemParameters.LogoPath
				? this.systemParameters.LogoPath
				: require("@/assets/images/gocbe_logo.png");
		},
	},
	data() {
		return {
			currentPrivacyModelURL:null,
			currentPrivacyModelHeading:null,
			isShowPrivacyModel:false
		}
	},
	methods: {
		togglePrivacyModal : function(event,privacyModelUrl=null,privacyModelHeading=null){
			if(this.isShowPrivacyModel && !privacyModelUrl){
				this.isShowPrivacyModel=false;
				this.currentPrivacyModelURL = null;
				this.currentPrivacyModelHeading = null;
			}else{
				this.isShowPrivacyModel=true;
				this.currentPrivacyModelURL=privacyModelUrl;
				this.currentPrivacyModelHeading=privacyModelHeading;
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "footer-loaded" });
		});
	},
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
	.footer-container {
		padding: 2rem 1.5rem !important;
		margin: 0 -1rem !important;
		border-radius: 0 !important;
	}
}

.footer-container {
	padding: 2rem 2.5rem;
	background-color: #ebebeb;
	/* background-image: url("../../assets/images/homeFooterBack.jpg"); */
	/* background-size: cover; */
	position: relative;
	/* color: var(--color-white); */
	margin-top: 1.5rem;
	box-shadow: 0 3px 6px #a8a8a829;
	border-radius: 8px;
	margin: 5rem 0;
}

/* .footer-container:before {
	content: "";
	width: 50%;
	height: 10px;
	background-color: var(--primary-color);
	position: absolute;
	top: 0;
	left: 0;
} */

.footer-nav {
	display: flex;
	justify-content: flex-start;
}

.footer-nav .nav-link {
	color: #000000 !important;
	cursor: pointer;
	font-weight: 500 !important;
	font-size: 1.1rem;
}

.footer-nav-container .logo img {
	max-height: 3rem;
	margin-right: 2rem;
}

/* .footer-nav .logo img {
	max-height: 2rem;
} */

.footer-nav .nav-link:hover {
	color: var(--primary-color) !important;
}

.footer-nav-container {
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-direction: column;
}
.footer-bottom-content.last {
	text-align: end;
}
.footer-bottom-content-hr {
	margin: 0;
	font-size: 1rem;
	border-top: 1px solid #cbcbcb;
	padding-top: 2rem;
	font-weight: 500;
}
.footer-bottom-content.last{
	text-align-last: end;
}
.footer-bottom-content a {
	color: #000000 !important;
	cursor: pointer;
	font-weight: 500 !important;
	font-size: 1rem;
	text-decoration: none !important;
	margin-right: 15px;
}

.footer-bottom-content a.social-links {
	font-size: 1.5rem;
}

.footer-bottom-content a:hover {
	color: var(--primary-color) !important;
}
.privacy-modal .modal-body {
	padding: 0px !important;

	
}

.privacy-modal .modal-body iframe{
	width: 100%;
	height: 100%;
	min-height: 80vh;
}
.footer-col-left {
	max-width: 33%;
}
.center-nav li{
	margin-left: 10px;
}
.footer-center-div
{
	text-align: -webkit-center;
	flex: 1;
}
.center-nav .nav-link{
	padding: 0px;
}
.center-nav .breaker{
	border-left: 1px solid black;
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>