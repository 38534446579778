<template>
  <div class="page-content-container">
    <div class="title-container mb-4">
      <h1 class="page-title">Bulk Operations</h1>
    </div>
    <div class="row" v-if="users.length > 0">
      <div>
        <!-- id="modal-center" centered title="Select Courses" -->
        <b-modal
          :visible="showModal"
          id="select-courses-modal"
          hide-header
          centered
          content-class="select-courses-modal"
          size="lg"
          body-class="modal-body"
          modal-class="custom-modal"
        >
          <button
            @click="
              () => {
                showModal = false;
              }
            "
            class="close-button"
            role="button"
          >
            <img src="@/assets/images/cancel-icon.svg" />
          </button>
          <div>
            <div class="mb-5">
              <h1>Select Courses</h1>
              <p v-if="operationType && operationType.toLowerCase()=='enroll specific courses'" class="text-danger">Note: Expiry date of previously enrolled students in the given emails list will be overwritten to end of batch </p>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12">
                <multiselect
                  v-model="selectedCourses"
                  :options="productVariantList"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :preserve-search="true"
                  placeholder="Select Courses to Enroll/Unenroll"
                  :label="'title'"
                  :track-by="'id'"
                  :option-height="25"
                  :searchable="true"
                  :select-label="''"
                  :deselect-label="''"
                  :show-no-results="false"
                  :dropdown-toggle="false"
                  :select-on-tab="true"
                ></multiselect>
              </div>
            </div>
          </div>

          <template #modal-footer>
            <button
              v-b-modal.modal-close_visit
              class="btn btn-primary m-1"
              @click="bulkOperationConfirmationDialogue(operationType)"
              :disabled="selectedCourses.length <= 0"
            >
              Apply
            </button>
          </template>
        </b-modal>
      </div>
    </div>

    <div class="d-flex mt-3" style="flex-wrap: wrap" v-if="users.length > 0">
      <button
        class="add-btn me-2 mt-2"
        @click="editEmails()"
        :disabled="!emails || loading"
      >
        Edit
        <i class="fa fa-pencil ms-2" aria-hidden="true"></i>
      </button>
      <button
        class="add-btn me-2 mt-2"
        @click="bulkOperationConfirmationDialogue('Active Users')"
        :disabled="(!emails && users.length > 0) || loading"
      >
        <i class="fas fa-solid fa-unlock me-2"></i> Mark Active
      </button>
      <button
        class="add-btn me-2 mt-2"
        @click="bulkOperationConfirmationDialogue('InActive Users')"
        :disabled="(!emails && users.length > 0) || loading"
      >
        <i class="fas fa-solid fa-lock me-2"></i> Mark InActive
      </button>
      <button
        class="add-btn me-2 mt-2"
        @click="bulkOperationConfirmationDialogue('Unenroll All Courses')"
        :disabled="(!emails && users.length > 0) || loading"
      >
        <i class="fas fa-book-user me-2"></i>
        Unenroll All Courses
      </button>
      <button
        class="add-btn me-2 mt-2"
        @click="bulkOperationConfirmationDialogue('Reset Devices')"
        :disabled="(!emails && users.length > 0) || loading"
      >
        <i class="fas fa-rotate-left me-2"></i> Reset Devices
      </button>
      <button
        type="button"
        class="add-btn mt-2 me-2"
        @click="
          () => {
            operationType = 'Enroll Specific Courses';
            showModal = true;
          }
        "
        :disabled="loading"
      >
        <i class="fas fa-book-user me-2"></i>
        Enroll Selected Courses
      </button>
      <button
        type="button"
        class="add-btn mt-2"
        @click="
          () => {
            operationType = 'Unenroll Specific Courses';
            showModal = true;
          }
        "
        :disabled="loading"
      >
        <i class="fas fa-book-user me-2"></i>
        Unenroll Selected Courses
      </button>

      <!-- <button
        type="button"
        class="add-btn mt-2"
        :disabled="selectedCourses.length <= 0"
        @click="bulkOperationConfirmationDialogue('Block Specific Courses')"
      >
        Block Selected Courses
      </button> -->

      <b-spinner v-if="loading" class="mt-2"></b-spinner>
    </div>

    <div class="row mt-3" v-if="users.length <= 0">
      <div class="col-lg-7">
        <textarea
          class="form-control"
          name=""
          id=""
          cols="25"
          rows="12"
          placeholder="Enter line seperated emails"
          v-model="emails"
        ></textarea>
      </div>
      <div>
        <button
          class="add-btn me-3"
          @click="validateUserEmails()"
          :disabled="!emails || loading"
        >
          Validate Emails <i class="fas fa-solid fa-arrow-right ms-2"></i>
          <b-spinner v-if="loading" small></b-spinner>
        </button>
      </div>
    </div>
    <div v-if="users.length > 0" class="row mt-5">
      <div class="col-lg-10">
        <table
          v-if="users && users.length > 0"
          class="table table-hover table-borderless"
        >
          <thead>
            <th style="width: 15%">Status</th>
            <th style="width: 10%">ID</th>
            <th style="width: 25%">Name</th>
            <th style="width: 40%">Email</th>
            <th style="width: 25%">User Status</th>
          </thead>
          <!-- <tbody>
            {{
              getUsersRow()
            }}
          </tbody> -->
          <tbody>
            <template v-for="email in allEmails">
              <tr>
                <!-- <td>{{ email }}</td> -->
                <template v-if="users.find((user) => user.email.toLowerCase() == email.toLowerCase())">
                  <td><span class="badge bg-success">Found</span></td>
                  <td>
                    {{ users.find((user) => user.email.toLowerCase() == email.toLowerCase()).id }}
                  </td>
                  <td>
                    {{
                      users.find((user) => user.email.toLowerCase() == email.toLowerCase()).firstName +
                      " " +
                      users.find((user) => user.email.toLowerCase() == email.toLowerCase()).lastName
                    }}
                  </td>
                  <td>
                    {{ users.find((user) => user.email.toLowerCase() == email.toLowerCase()).email }}
                  </td>
                  <td
                    :class="
                      users
                        .find((user) => user.email.toLowerCase() == email.toLowerCase())
                        .status.toLowerCase() == 'active'
                        ? 'success-text'
                        : 'danger-text secondary'
                    "
                  >
                    {{ users.find((user) => user.email.toLowerCase() == email.toLowerCase()).status }}
                  </td>
                </template>
                <template v-else>
                  <td><span class="badge bg-danger">Not Found</span></td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    {{ email }}
                  </td>
                  <td>-</td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters, mapState } from "vuex";
import AdminHeader from "../../components/Admin/Layout/AdminHeader.vue";
import Vue from "vue";

export default {
  name: "BulkOperations",
  components: {
    AdminHeader,
    Multiselect,
  },
  data() {
    return {
      emails: null,
      operationType: null,
      loading: false,
      activeBulkOperationButtons: false,
      users: [],
      emailsNotFound: null,
      selectedCourses: [],
      selectedCoursesIDs: null,
      showModal: false,
    };
  },
  watch: {
    selectedCourses: function () {
      this.selectedCoursesIDs = this.selectedCourses.map((x) => x.id);
    },
  },

  computed: {
    ...mapState("productVariant", ["productVariants"]),

    productVariantList() {
      let productVariants = JSON.parse(
        JSON.stringify(Object.values(this.productVariants.items))
      );
      productVariants = productVariants.map((x) => {
        return {
          title: `${x.product.title} - ${x.title}`,
          id: x.id,
        };
      });

      return productVariants;
    },
  },

  methods: {
    ...mapActions("user", [
      "allUsersDevicesReset",
      "markUsersStatus",
      "getUsersByEmails",
    ]),
    ...mapActions("productVariant", [
      "unenrollAllUsersCourses",
      "enrollUsersSpecificCourses",
      "updateUsersSpecificCoursesSubscription",
    ]),

    editEmails() {
      this.loading = true;
      this.allEmails = [];
      this.users = [];
      this.loading = false;
      this.selectedCourses = [];
      this.selectedCoursesIDs = null;
    },

    reset() {
      this.loading = true;
      // this.allEmails = [];
      // this.users = [];
      this.loading = false;
      this.selectedCourses = [];
      this.selectedCoursesIDs = null;
    },

    async validateUserEmails() {
      this.loading = true;
      let _emails = this.emails.split("\n").join(",");
      this.allEmails = this.emails.split("\n");

      await this.getUsersByEmails(_emails).then((data) => {
        this.users = data ? data : [];
        console.log({ users: this.users });
      });
      this.loading = false;

      if (this.users.length <= 0) {
        Swal.fire({
          title: "No Users Found",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#DC3545",
        });
        return;
      }
    },

    resetUserDevices(emails) {
      this.loading = true;
      this.allUsersDevicesReset(emails)
        .then((res) => {
          if (res && res.ok) {
            // this.emails = null;
            Swal.fire({
              title: "Successful",
              text: "Devices Reset Successful",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#26A042",
            });
            this.reset();
            // _emails = null;
          } else {
            Swal.fire({
              title: "Unsuccessful",
              text: res.message,
              icon: "warning",
              confirmButtonText: "Ok",
              confirmButtonColor: "#DC3545",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async markUsersStatusToActiveOrInactive(data, status) {
      this.loading = true;
      await this.markUsersStatus(data)
        .then((res) => {
          // this.emails = null;
          if (res && res.ok) {
            let currentStatus =
              status == "Active Users"
                ? "Users Activated Successful"
                : "Users Inactive Successful";
            Swal.fire({
              title: "Successful",
              text: currentStatus,
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#26A042",
            });
            this.reset();
            // _emails = null;
          } else {
            Swal.fire({
              title: "Unsuccessful",
              text: "Some Unknown Error Occurred",
              icon: "warning",
              confirmButtonText: "Ok",
              confirmButtonColor: "#DC3545",
            });
          }

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async unenrollAllUsersCoursesSubscription(emails) {
      this.loading = true;
      await this.unenrollAllUsersCourses(emails)
        .then((res) => {
          // this.emails = null;
          if (res && res.ok) {
            Swal.fire({
              title: "Successful",
              text: "Courses Unenrolled Successful",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#26A042",
            });
            this.reset();
            // _emails = null;
          } else {
            Swal.fire({
              title: "Unsuccessful",
              text: "Some Unknown Error Occurred",
              icon: "warning",
              confirmButtonText: "Ok",
              confirmButtonColor: "#DC3545",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async EnrollUsersCoursesSubscriptions(data) {
      this.loading = true;
      await this.enrollUsersSpecificCourses(data)
        .then((res) => {
          // this.emails = null;
          if (res && res.ok) {
            this.showModal = false;
            Swal.fire({
              title: "Successful",
              text: "Courses Enrolled Successful",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#26A042",
            });
            this.reset();
            // _emails = null;
          } else {
            Swal.fire({
              title: "Unsuccessful",
              text: "Some Unknown Error Occurred",
              icon: "warning",
              confirmButtonText: "Ok",
              confirmButtonColor: "#DC3545",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async updateUsersCoursesSubscription(data) {
      this.loading = true;
      await this.updateUsersSpecificCoursesSubscription(data)
        .then((res) => {
          // this.emails = null;
          if (res && res.ok) {
            this.showModal = false;
            Swal.fire({
              title: "Successful",
              text: "Courses Unenrolled Successful",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#26A042",
            });
            this.reset();
            _emails = null;
            this.loading = false;
          } else {
            Swal.fire({
              title: "Unsuccessful",
              text: "Some Unknown Error Occurred",
              icon: "warning",
              confirmButtonText: "Ok",
              confirmButtonColor: "#DC3545",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    bulkOperationConfirmationDialogue(operationType) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to ${operationType}?`, {
          title: `${operationType.toUpperCase()}`,
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          bodyClass: "confirm-modal-body",
        })
        .then(async (value) => {
          if (value) {
            // let _emails = this.emails.split("\n").join(",");
            let _emails = this.users.map((x) => x.email);

            if (operationType == "Reset Devices") {
              await this.resetUserDevices(_emails);
              await this.validateUserEmails();
            } else if (operationType == "Active Users") {
              let requestData = {
                userEmails: _emails.toString(),
                status: "active",
              };
              await this.markUsersStatusToActiveOrInactive(
                requestData,
                operationType
              );
              await this.validateUserEmails();
            } else if (operationType == "InActive Users") {
              let requestData = {
                userEmails: _emails.toString(),
                status: "inactive",
              };
              await this.markUsersStatusToActiveOrInactive(
                requestData,
                operationType
              );
              await this.validateUserEmails();
            } else if (operationType == "Unenroll All Courses") {
              await this.unenrollAllUsersCoursesSubscription(_emails);
            } else if (operationType == "Unenroll Specific Courses") {
              let data = {
                UserEmails: _emails.toString(),
                ProductVariantIDs: this.selectedCoursesIDs.toString(),
                Status: "Unenrolled",
              };
              await this.updateUsersCoursesSubscription(data);
            } else if (operationType == "Enroll Specific Courses") {
              let data = {
                UserEmails: _emails,
                ProductVariantIDs: this.selectedCoursesIDs,
              };
              await this.EnrollUsersCoursesSubscriptions(data);
            } else if (operationType == "Block Specific Courses") {
              let data = {
                UserEmails: _emails.toString(),
                ProductVariantIDs: this.selectedCoursesIDs.toString(),
                Status: "Blocked",
              };
              await this.updateUsersCoursesSubscription(data);
            }
          }
        });
    },
  },

  created() {},
};
</script>
<style scoped>
.border-radius-static-style {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.btn-static-styling {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

td {
  padding-left: 0px !important;
}

.page-content-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
}

.page-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
  display: inline-block;
  /* margin: 0; */
}

.add-btn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.add-btn:hover {
  box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
}

.all-users-table thead,
.all-users-table td {
  height: 4rem;
  vertical-align: middle;
}

.all-users-table td:first-of-type {
  padding-left: 0;
}

.all-users-table input[type="checkbox"] {
  margin-left: 1.5rem;
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.filters-container {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
}

.filters-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.filter-inputs {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.search-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  column-gap: 0.75rem;
  position: relative;
  outline: none;

  /* transition: transform 0.3s; */
}

.search-button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: #000000;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.buttons-container .searchbar {
  flex-grow: 1;
}

.search-icon {
  font-size: 18px;
  position: absolute;
  margin-right: 1rem;
  right: 0;
  color: #000000;
}

.export-btn {
  padding: 0.5rem 1rem;
}

.dropdown-item {
  cursor: pointer;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  color: var(--color-white);
  background-color: #231f20;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  /* z-index: 100; */
}

.user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  object-position: top;
  margin-right: 0.5rem;
}

.user-name {
  cursor: pointer;
}

.filter-user-type-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.filter-user-type-btn.active {
  border: 1px solid var(--primary-color);
}

td.success-text {
  color: var(--color-success);
}

td.danger-text {
  color: var(--color-danger);
}

/*table > thead > th:first-child, table > tbody > tr > td:first-child {*/
/*  margin-left: 1.5rem!important;*/
/*}*/
</style>
