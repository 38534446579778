import cookieHelpers from "./CookieHelpers";
import parser from "ua-parser-js";
import Vue from "vue";
import { REGENERATE_USER_TOKEN } from "../constants/urls";
import store from "../store";
// import { cookieHelpers.setCookie, cookieHelpers.getCookie } from "../helpers/CookieHelpers";

async function makeAPICall(
	url,
	method,
	body,
	isLoginApi,
	contentType = "application/json"
) {
	// if (store.state.appState.amIOnline === false) return;
	// console.log(url, method, body, isLoginApi);
	var ua = parser(navigator.userAgent);
	var requestOptions = {
		method: method,
		headers: {
			AuthToken: "System123",
			Platform:
				 getPlatform(navigator.userAgent),
		},
	};
	// alert(ua.device.type);
	if (contentType) {
		requestOptions.headers["Content-Type"] = contentType;
	}

	// if (isAuthRequired) {
	const token = cookieHelpers.getCookie("token");
	if (token) {
		requestOptions.headers.Authorization = `Bearer ${token}`;
	}
	// }

	if (isLoginApi) {
		const deviceToken = JSON.parse(localStorage.getItem("deviceToken"));
		if (deviceToken) {
			requestOptions.headers.DeviceToken = deviceToken;
		}
	}

	if (body !== null) {
		requestOptions.body = body;
		console.log(body);
	}

	const data = await fetch(url, requestOptions)
		// .then(handleErrors)
		.then(async (response) => {
			if (!response) {
				return Promise.reject({
					status: 500,
					ok: false,
					message:
						"Some unexpected error occurred. Please check your internet connection and try again. If the problem persists please contact admin.",
				});
			}
			// console.log(response);
			if (!response.ok) {
				if (response.status == 500) {
					return Promise.reject({
						status: response.status,
						ok: response.ok,
						message:
							"Some unexpected error occurred. Please try again or contact admin.",
					});
				}
				if (response.status == 403 ) {
					var error= await response.clone().json();
					if (error.error && error.error.errorCode == 133)
						return Promise.reject({
							status: error.error.errorCode,
							ok: response.ok,
							message: error.error.message,
						});
				}
				var isTokenExpired = response.headers.get("tokenexpired");
				var isTokenInvalid =
					response.headers.get("www-authenticate") &&
					response.headers.get("www-authenticate").includes("invalid_token");
				// console.log(response.headers.get("date"));
				var timeSinceLastRegen =
					new Date().getTime() -
					parseInt(JSON.parse(localStorage.getItem("last_token_time")));
				console.log(
					"token expired: ",
					isTokenExpired,
					isTokenInvalid,
					timeSinceLastRegen
				);
				if ((isTokenExpired || isTokenInvalid) && timeSinceLastRegen > 30000) {
					localStorage.setItem(
						"last_token_time",
						JSON.stringify(new Date().getTime())
					);
					// console.log(response.status);
					return regenerateUserToken()
						.then((response) => {
							if (response) {
								return makeAPICall(
									url,
									requestOptions.method,
									requestOptions.body ? requestOptions.body : null,
									isLoginApi
								);
							}
							localStorage.setItem(
								"last_token_time",
								JSON.stringify(new Date().getTime())
							);
						})
						.catch((error) =>
							Vue.toasted.error(error, {
								icon: "exclamation-triangle",
								theme: "outline",
								position: "bottom-center",
								singleton: true,
								duration: 3000,
							})
						);
				} else return response;
			} else return response;
		})
		.catch((error) => {
			console.log(error);
			if (error.status && error.status == 500) {
				Vue.toasted.error(error.message, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					action: {
						text: "DISMISS",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						},
					},
				});
				return Promise.reject(error);
			}
			if (error.status && error.status == 133) {
               console.log(Vue.prototype);
			   Vue.prototype.$Modal.msgBoxOk(
				`${error.message}`,
				{
					title: "Access Denied",
					size: "md",
					buttonSize: "md",
					okTitle: "Ok",
					okVariant: "danger",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
				}
			);
				
				return Promise.reject(error);
			}
			return Promise.reject({
				status: 500,
				ok: false,
				message:
					"Some unexpected error occurred. Please check your internet connection and try again. If the problem persists please contact admin.",
			});
		});
	// console.log("response from the API", data);
	return data;
}

async function regenerateUserToken() {
	const token = cookieHelpers.getCookie("token");
	if (token) {
		// console.log(token);
		// store.dispatch("appState/startLoading");
		var ua = parser(navigator.userAgent);
		var requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				AuthToken: "System123",
				Platform:
					ua.os.name == "Windows" && ua.device.type == undefined
						? "desktop_web"
						: ua.device.type == "mobile" && ua.os.name == "Android"
						? "mobile_web"
						: "desktop",
				OldUserAuthenticationToken: token,
				// Authorization: "Bearer " + token,
			},
		};
		const response = await fetch(
			store.state.appState.apiURL + REGENERATE_USER_TOKEN,
			requestOptions
		).then(
			(response) => {
				// debugger;
				if (!response.ok) {
					console.log(response);
					store.dispatch("user/logout");
					return Promise.reject("Session Expired!");
				} else {
					return response.json().then((res) => {
						// console.log(res);
						cookieHelpers.setCookie(
							"token",
							res?.payload,
							new Date(
								new Date().setDate(new Date().getDate() + 30)
							).toUTCString()
						);
						return true;
					});
				}
			},
			(error) => {
				Vue.toasted.error(`Something went wrong, ${error}`, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					singleton: true,
				});
				store.dispatch("user/logout");
			}
		);
		if (response) return response;
	}
}
function getPlatform(userAgent)
{
	var ua = parser(userAgent);
	if(userAgent.toLowerCase().indexOf('electron')>-1)
	{
		return (ua.os?.name?.toLowerCase()=='windows')?'windows':(ua.os?.name?.toLowerCase()=='mac os')?"macos":"desktop_web";
	}
	else{
		return ua.device.type == "mobile" || ua.device.type == "tablet" || ua.device.type == "smarttv" 
		? "mobile_web"
		: "desktop_web";
	}

} 
export default {
	makeAPICall,
	regenerateUserToken,
};
