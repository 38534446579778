class UserStatus {
	static ACTIVE = "Active";
	static INACTIVE = "Inactive";
}

class UserType {
	static STUDENT = "Student";
	static MANAGEMENT = "Management";
	static TRAINER = "Trianer";
}

export { UserStatus, UserType };
