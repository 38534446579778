var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-card"},[_c('h4',{staticClass:"activity-card-heading"},[_vm._v("Last Activities")]),_c('div',{staticClass:"activity-content"},[_c('div',{staticClass:"accordion accordion-flush",attrs:{"id":"activityAccordian"}},_vm._l((1),function(item){return _c('div',{key:item,staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":`accordian-${item}`}},[_c('button',{class:[
							'accordion-button',
							{ collapsed: _vm.activeActivityAccordian != item },
						],attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#accordian-collapse-${item}`,"aria-expanded":_vm.activeActivityAccordian == item}},[_vm._v(" Today ")])]),_c('div',{class:[
						'accordion-collapse',
						{
							collapse: _vm.activeActivityAccordian != item,
							show: _vm.activeActivityAccordian == item,
						},
					],attrs:{"id":`accordian-collapse-${item}`,"data-bs-parent":"#activityAccordian"}},[_c('div',{staticClass:"accordion-body"},[_c('div',{staticClass:"activity-list"},_vm._l((5),function(item){return _c('div',{key:item,staticClass:"activity-item"},[_c('h4',{staticClass:"activity-title"},[_vm._v(" This course class teacher will be continue from Another teacher from next class ")]),_c('p',{staticClass:"activity-text"},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ")]),_c('a',{staticClass:"activity-read-more",attrs:{"role":"button"}},[_vm._v("Read more")])])}),0)])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }