<template>
	<div class="container">
		<div class="testimonials">
			<div class="col-lg-5">
				<img
					src="@/assets/images/testimonial.png"
					alt=""
					class="testimonial-image"
				/>
			</div>
			<div class="col-lg-7 col-sm-12 testimonial-text">
				<VueSlickCarousel>
					<div class="testimonial-item">
						<b-form-rating value="4" readonly></b-form-rating>
						<h1 class="title">
							Extremely satisfied with the quality of Education
						</h1>
						<p class="content">
							GoCBE has been a source of intellectual enrichment and practical
							professional development for me.
						</p>
						<span class="name">Nimra Shah</span>
						<span class="role">Student</span>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
	name: "HomePageTestimonials",
	components: { VueSlickCarousel },
};
</script>

<style scoped>
@media screen and (max-width: 1400px) {
	.testimonial-item {
		padding: 0 2rem !important;
	}
	.testimonial-item .form-control {
		margin-bottom: 1.25rem !important;
	}
	.testimonial-item .title {
		font-size: 3rem !important;
		margin-bottom: 1.5rem !important;
	}
	.testimonial-item .content {
		font-size: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.testimonial-item .name {
		font-size: 1.25rem !important;
	}
	.testimonial-item .role {
		font-size: 1.125rem !important;
	}
}

@media screen and (max-width: 1200px) {
	.testimonial-text {
		padding-left: 2rem !important;
	}
	.testimonial-item {
		padding: 0 1rem !important;
	}
	.testimonial-item .form-control {
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .title {
		font-size: 2.5rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .content {
		font-size: 1.25rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .name {
		font-size: 1.125rem !important;
	}
	.testimonial-item .role {
		font-size: 1rem !important;
	}
}
@media screen and (max-width: 996px) {
	.testimonials {
		padding: 1.5rem 1.25rem !important;
		margin-bottom: 4rem !important;
	}
	.testimonials .col-lg-5 {
		display: none !important;
	}
	.testimonial-text {
		padding: 0 !important;
	}
	.testimonial-image {
		display: none !important;
	}
	.testimonial-item {
		padding: 0 1rem !important;
	}
	.testimonial-item .form-control {
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .title {
		font-size: 2.5rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .content {
		font-size: 1.25rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .name {
		font-size: 1.125rem !important;
	}
	.testimonial-item .role {
		font-size: 1rem !important;
	}
	.testimonial-text::before {
		top: -1rem !important;
	}
	.testimonial-text::after {
		bottom: -10rem !important;
		right: 1rem !important;
	}
}
@media screen and (max-width: 480px) {
	.testimonials {
		padding: 1.5rem !important;
		margin-bottom: 2rem !important;
	}
	.testimonial-item {
		padding: 0 !important;
	}
	.testimonial-item .form-control {
		margin-bottom: 1rem !important;
		font-size: 1.5rem !important;
	}
	.testimonial-item .title {
		font-size: 1.5rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .content {
		font-size: 1.25rem !important;
		margin-bottom: 1.125rem !important;
	}
	.testimonial-item .name {
		font-size: 1.125rem !important;
	}
	.testimonial-item .role {
		font-size: 1rem !important;
	}
	.testimonial-text::before {
		top: -1rem !important;
	}
	.testimonial-text::after {
		bottom: -10rem !important;
		right: 1rem !important;
	}
}

.testimonials {
	padding: 0 1.25rem 0;
	background-color: var(--secondary-color);
	border-radius: 8px;
	box-shadow: 0 3px 6px #a8a8a829;
	display: flex;
	flex-direction: row;
	margin-bottom: 9.375rem;
}

.testimonials .slick-slider {
	width: 100%;
}

.testimonial-text {
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 4rem;
}

.testimonial-text::before {
	content: "“";
	font-size: 18.75rem;
	color: #ffffff1f;
	position: absolute;
	line-height: 1;
	top: 1rem;
	/* ❝ ❞ “ ” • */
}

.testimonial-text::after {
	content: "”";
	font-size: 18.75rem;
	color: #ffffff1f;
	position: absolute;
	line-height: 1;
	bottom: -7rem;
	right: 4rem;
}

.testimonial-image {
	margin: -1.75rem 0;
	border-radius: 10px;
	width: 100%;
}
.testimonial-item {
	color: #ffffff;
	padding: 0 4rem;
	width: 100%;
}

.testimonial-item .title {
	font-size: 3.375rem;
	font-weight: 500;
	margin-bottom: 1.875rem;
}

.testimonial-item .content {
	font-size: 1.875rem;
	font-weight: normal;
	color: #ffffff6a;
	margin-bottom: 1.875rem;
}
.testimonial-item .name {
	text-decoration: underline;
	font-size: 1.5rem;
	margin-right: 1rem;
}
.testimonial-item .role {
	font-size: 1.25rem;
	color: var(--primary-color);
	font-weight: normal;
}

.testimonial-item .form-control {
	height: fit-content;
	background-color: transparent !important;
	border: none;
	height: unset !important;
	margin-bottom: 1.875rem;
	margin-left: 0;
	padding: 0;
	font-size: 2.25rem;
	width: fit-content;
	box-shadow: none;
}

.testimonial-item .form-control:focus {
	box-shadow: none !important;
}
</style>
