<template>
	<div id="login-register">
		<h1 class="heading-bold-20">Forgot Password</h1>
		<div class="width-100">
			<form>
				<div v-if="isOTPVerified">
					<div class="form-group position-relative">
						<i class="input-icon-left fas fa-unlock-alt"></i>
						<input
							:type="showPassword ? 'text' : 'password'"
							:class="[
								'form-control input-w-icon',
								{ 'text-danger': pwdError },
							]"
							id="newPassword"
							v-model="newPassword"
							placeholder="Enter your new password"
						/>
						<i
							@click="toggleShowPassword"
							:class="[
								'fas input-icon-right',
								{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword },
							]"
						></i>
					</div>
				</div>
				<div v-else-if="isOTPGenerated">
					<div class="form-group">
						<i class="input-icon-left far fa-key"></i>
						<input
							class="form-control input-w-icon"
							type="text"
							name="OTPCode"
							id="OTPCode"
							maxlength="6"
							placeholder="Enter the OTP sent to your email"
							v-model="OTPCode"
						/>
					</div>
					<div class="d-flex gap-3">
						<button type="button" :disabled="timer > 0" @click="getOTP">
							Resend OTP
						</button>
						<span class="timer">0:{{ timer.toString().padStart(2, "0") }}</span>
					</div>
				</div>
				<div v-else>
					<div v-if="isNumber" class="form-group">
						<vue-tel-input
							:class="`form-control ${
								isPhoneError && showError ? 'error' : ''
							}`"
							defaultCountry="PK"
							:inputOptions="telInputOptions"
							validCharactersOnly
							v-model="resetInput"
							@input="validate"
							@blur="showError = true"
							@focus="showError = false"
							:autoFormat="true"
							mode="national"
							invalidMsg="Please enter a valid phone number"
							id="resetInput_number"
						></vue-tel-input>
						<p class="text-danger" v-if="isPhoneError && showError">
							Invalid Phone Number
						</p>
					</div>
					<div v-else class="form-group">
						<!-- <font-awesome-icon :icon="['far', 'user']" /> -->
						<i class="input-icon-left far fa-user"></i>
						<input
							v-model="resetInput"
							class="input-w-icon form-control"
							type="text"
							name="resetInput"
							id="resetInput"
							placeholder="Enter Email to receive OTP"
							required
							tabindex="10"
							@input="checkType"
							maxlength="80"
						/>
					</div>
				</div>

				<button
					:disabled="loading"
					class="login-register-btn primary-btn form-control mt-4"
					type="submit"
					@click="handleResetPassword"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ isOTPGenerated && !isOTPVerified ? "Next" : "Reset Password" }}
				</button>

				<button
					v-if="!isOTPGenerated && !isOTPVerified"
					class="secondary-btn btn-bold form-control mt-4"
					type="button"
					@click="() => $router.back()"
				>
					Back
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
const getCountryISO3 = require("country-iso-2-to-3");

export default {
	name: "ForgotPassword",
	data() {
		return {
			resetInput: null,
			resetPhone: null,
			// resetEmail: null,
			isOTPGenerated: false,
			verificationID: null,
			OTPCode: null,
			timer: 59,
			counter: null,
			resetPasswordToken: null,
			newPassword: null,
			isOTPVerified: false,
			showPassword: false,
			pwdError: false,
			isPhoneError: false,
			showError: false,
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: 10,
				placeholder: "Phone Number (required)",
				mode: "national",
			},
			isNumber: false,
		};
	},
	computed: {
		...mapState("user", ["loading"]),
	},
	methods: {
		...mapActions("user", ["generateOTP", "verifyOTP", "resetPassword"]),
		handleResetPassword(e) {
			e.preventDefault();
			if (this.isOTPVerified) {
				this.reset();
			} else if (this.isOTPGenerated) {
				this.verify();
			} else {
				this.validateAndGetOTP();
			}
		},
		validateAndGetOTP() {
			this.validateForm().then(() => {
				this.getOTP();
			});
		},
		getOTP() {
			this.timer = 59;
			var OTPObj = {
				Type: "forgotpassword",
			};
			if (this.isNumber) OTPObj.Phone = encodeURIComponent(this.resetPhone);
			else OTPObj.Email = this.resetInput;
			this.generateOTP(OTPObj)
				.then((res) => {
					this.$toasted.show(
						`OTP has been sent to your ${
							this.isNumber ? "phone number" : "email"
						}`,
						{
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						}
					);
					this.isOTPGenerated = true;
					this.verificationID = res.verificationID;
					this.counter = setInterval(() => {
						this.timer -= 1;
					}, 1000);
				})
				.catch((error) => {
					this.$toasted.error(error.message, {
						icon: "exclamation-triangle",
						duration: 3000,
						theme: "outline",
						position: "bottom-center",
					});
				});
		},
		verify() {
			this.validateForm()
				.then((valid) => {
					if (valid) {
						this.verifyOTP({
							verificationID: this.verificationID,
							verificationToken: this.OTPCode,
						})
							.then((res) => {
								this.$toasted.show(
									"OTP verified. Please enter your new password",
									{
										duration: 3000,
										theme: "outline",
										position: "bottom-center",
									}
								);
								this.resetPasswordToken = res.token;
								this.isOTPVerified = true;
							})
							.catch((error) => {
								this.$toasted.error(error.message, {
									icon: "exclamation-triangle",
									duration: 3000,
									theme: "outline",
									position: "bottom-center",
								});
							});
					}
				})
				.catch((message) => {
					this.$toasted.error(message, {
						icon: "exclamation-triangle",
						duration: 3000,
						theme: "outline",
						position: "bottom-center",
					});
				});
		},
		reset() {
			this.validateForm()
				.then((valid) => {
					if (valid) {
						this.resetPassword({
							verificationID: this.resetPasswordToken,
							resetPassword: this.newPassword,
						})
							.then((res) => {
								this.$toasted.success("Password reset successfully", {
									duration: 3000,
									theme: "outline",
									position: "bottom-center",
								});
								this.$router.push({ name: "Login" });
							})
							.catch((error) => {
								this.$toasted.error(error.message, {
									icon: "exclamation-triangle",
									duration: 3000,
									theme: "outline",
									position: "bottom-center",
								});
							});
					}
				})
				.catch((message) => {
					this.$toasted.error(message, {
						icon: "exclamation-triangle",
						duration: 3000,
						theme: "outline",
						position: "bottom-center",
					});
				});
		},
		async validateForm() {
			if (!this.isOTPGenerated) {
				if (!(this.resetPhone || this.resetInput)) {
					return Promise.reject("Please enter an email address");
				}
			}
			if (this.isOTPGenerated && !this.OTPCode) {
				return Promise.reject("Please enter OTP");
			}
			if (this.isOTPGenerated && this.isOTPVerified) {
				if (!this.newPassword) {
					return Promise.reject("Please enter your new password");
				}
			}
			return Promise.resolve(true);
		},
		toggleShowPassword() {
			this.showPassword = !this.showPassword;
		},
		checkType(e) {
			if (e.data) {
				// alert(e);
				var regExp = /[a-zA-Z]/g;
				var isAlphaInput = regExp.test(e.data);
				var containsLetters = regExp.test(this.resetInput);
				if (
					e.key != "@" &&
					!containsLetters &&
					!isAlphaInput
					// ((e.keyCode > 95 && e.keyCode < 106) ||
					// 	(e.keyCode > 47 && e.keyCode < 58))
				) {
					if (this.resetInput && this.resetInput.length > 3) {
						this.isNumber = true;

						setTimeout(() => $("#resetInput_number input").focus(), 10);

						// console.log(e, "is a number");
						return;
					}
				}
				this.isNumber = false;
				setTimeout(() => $("#resetInput").focus(), 10);
			}
		},
		validate(num, obj) {
			// console.log(num, obj);
			var numbers = "1234567890-+";
			if (!numbers.includes(num.at(-1))) {
				this.isNumber = false;
				setTimeout(() => $("#resetInput").focus(), 10);
				return;
			}
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.country && obj.country.iso2) {
				this.countryCode = getCountryISO3(obj.country.iso2);
				this.resetPhone = obj.number;
			}
			return false;
		},
	},
	watch: {
		timer: function () {
			if (this.timer == 0) clearInterval(this.counter);
		},
	},
};
</script>

<style scoped>
.timer {
	color: #aaa;
}

.input-icon-right {
	top: 40%;
	transform: translateY(-50%);
}
</style>
