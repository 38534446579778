<template>	            
		<div class="zoom-account-container">
			<div class="header-div">
				<h5 class="primary-label mt-2">Manage Zoom Accounts</h5>
				<a class="btn btn-sm btn-primary" @click="addZoomAccount()" target="_blank" rel="noopener noreferrer">
					Add New
				</a>
			</div>
			<br/>
			<div class="settings-container">
					<div v-if="!isLoading">
						<div v-if="zoomServices && zoomServices.length > 0">
							<div class="card" v-for="service in zoomServices">
								<div class="card-block">
									<div class="row align-items-center justify-content-center">
										<div class="col-auto">
											
											<img class="img-fluid rounded-circle" style="width:35px; height: 35px;" v-if="service.serviceData && parseData(service.serviceData) && parseData(service.serviceData).pic_url" :src="parseData(service.serviceData).pic_url" >
											<img class="img-fluid rounded-circle" style="width:35px; height: 35px;" v-else src="../../../assets/images/zoom-logo.png"/>
		
										</div>
										<div class="col">
											<h5 style="margin: 0px;">{{service.serviceName}}</h5>
											<span v-if="service.serviceData && parseData(service.serviceData)">{{parseData(service.serviceData).email?parseData(service.serviceData).email:parseData(service.serviceData).for}}</span><br/>
											<span v-if="service.serviceData && parseData(service.serviceData) && parseData(service.serviceData).id">{{parseData(service.serviceData).id}} | {{parseData(service.serviceData).account_id}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<p class="text-muted">
								No Zoom accounts currently added.
							</p>
						</div>

					</div>                
	                <div class="ms-4" v-if="isLoading">                    
	                    <b-spinner class="loader"> </b-spinner>                     
	                </div> 
			</div>            
	    </div>	
    
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "ZoomServiceAccountsIntegration",
    data() {
		return {    
           zoomServices:[],
		   isLoading:false
		};
	},
    methods: {		
		...mapActions("appState", ["fetchExternalServices"]),	
		loadZoomServiceAccounts(){
			this.isLoading=true;
			this.fetchExternalServices("liveclass").then(() => {
					var list = this.externalServicesList && this.externalServicesList["liveclass"] && this.externalServicesList["liveclass"].filter(
						(service) => service.serviceProvider && service.serviceProvider.toLowerCase()=="zoom"
					);
					this.isLoading=false;
					this.zoomServices=list;
			});
		},
		parseData(data){
			try{
			return JSON.parse(data);
			}catch(ex)
			{
				return null;
			}
		},
		addZoomAccount(){
			var left = (screen.width/2)-(650/2);
			
			var top = (screen.height/2)-(screen.height*0.7/2);
      		
			var popupWindow=window.open("/service/zoom", "Zoom Account Integration", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+650+',height='+screen.height*0.7+', top='+top+', left='+left);
			var self=this;
			window.beforeCloseCallback=function(){
				self.loadZoomServiceAccounts();
			};


			//let newWin = window.open("http://localhost:8080/management/preferences");

			// newWin.document.write(
			// 	"<script>window.opener.document.body.innerHTML = 'Test'<\/script>"
			// );
		}	
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		
	},	
	created() {  
		this.loadZoomServiceAccounts();
	},
	watch: {     
	},
}
</script>

<style scoped>
.zoom-account-container
{
	max-width: 35%;
}
.zoom-account-container .header-div{
	display: flex;
	align-items: center;
}
.zoom-account-container .header-div h5{
	flex: 1;
}

.card {
	border-radius: 0;
	-webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
			box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
	border: none;
	margin-bottom: 10px;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
  }
  .card .card-header {
	background-color: transparent;
	border-bottom: 1px solid #f1f1f1;
	padding: 20px 25px;
	position: relative;
  }
  .card .card-header h5 {
	margin-bottom: 0;
	color: #000;
	font-size: 17px;
	font-weight: 400;
	display: inline-block;
	margin-right: 10px;
	line-height: 1.1;
	position: relative;
  }
  .card .card-header h5:after {
	content: "";
	background-color: #04a9f5;
	position: absolute;
	left: -25px;
	top: 0;
	width: 4px;
	height: 20px;
  }
  .card .card-header.borderless {
	border-bottom: none;
  }
  .card .card-header.borderless h5:after {
	display: none;
  }
  .card .card-header .card-header-right {
	right: 10px;
	top: 10px;
	display: inline-block;
	float: right;
	padding: 0;
	position: absolute;
  }
  .card-block{
	padding: 10px;
  }
  @media only screen and (max-width: 575px) {
	.card .card-header .card-header-right {
	  display: none;
	}
  }
  @media only screen and (max-width: 1050px) {
	.zoom-account-container
{
	max-width: 50%;
}
  }
  
  @media only screen and (max-width: 730px) {
	.zoom-account-container
{
	max-width: 100%;
}
  }
</style>
