<template>
	<b-modal id="flash-card-modal" @change="$emit('updateModalState', false)" :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')" :visible="visible" hide-header centered
		content-class="flash-card-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">

			
			<div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button><button @click="$emit('updateModalState', false)" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			</div>
			
			<h1 class="add-content-title">
				{{ this.isEditMode ? "Edit" : "Create" }} Flash Card
			</h1>

			<div v-if="stepIndex === 0" style="display: flex; flex-direction: column;" class="body-content-container">
				<div class="info-container expand" style="flex: 1;">

					<div class="form-group mt-3">
						<label for="title" class="main-label">Flash Cards Title (Required)</label>
						<input placeholder="Type your flash card title" class="form-input" type="search" name="title"
							id="title" v-model="title" required>
					</div>

					<div class="form-group ">
						<div class="row">
							<div class="col-lg-6">
								<div class="form-group">
									<label for="start-date" class="main-label m-0">Start Date (Optional)</label>
									<input placeholder="Select start date" class="form-input" type="date" name="start-date"
										id="start-date" v-model="startDate" />
								</div>
							</div>
							<div class="col-lg-6 pe-0">
								<div class="form-group">
									<label for="end-date" class="main-label m-0">End Date (Optional)</label>
									<input placeholder="Select end date" class="form-input" type="date" name="end-date"
										id="end-date" v-model="endDate" />
								</div>
							</div>
						</div>
					</div>

					<div class="form-group mt-4">
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" role="switch" id="isAvailableForDemo"
								v-model="isAvailableForDemo" />
							<label class="form-check-label" for="isAvailableForDemo">Available for Demo</label>
						</div>
					</div>

				</div>

				<div class="step-btn-container">
					<button @click="nextStep()" role="button" class="step-btn">
						NEXT
						<img src="@/assets/images/arrow-right.svg" />
					</button>
				</div>

			</div>

			<div v-else-if="stepIndex === 1" >
				<div class="info-container expand">
					<div class="step-btn-container">
						<button @click="addSlide" role="button" class="step-btn">
							<img src="@/assets/images/add-border-icon.svg" alt="ADD" class="me-2" />
							Add Slide
						</button>
					</div>

					<table class="table">
						<thead>
							<tr>
								<th>
									<label :for="'slide-1'" class="form-label">Slide 1</label>
								</th>
								<th>
									<label :for="'slide-2'" class="form-label">Slide 2</label>
								</th>
								<th style="width: 30px;"></th>
							</tr>
						</thead>
						<draggable tag="tbody" v-model="flashCards" group="content" animation="200" handle=".content-handle"
							@change="updateSequence">
							<tr v-for="(slide, index) in flashCards" :key="index">
								<td>
									<div class="row align-items-center">
										<img src="@/assets/images/drag-icon.svg" 
											class="drag-icon content-handle handle mt-3" />
										<div class="form-group mb-0 col">
											<input placeholder="Enter your slide content" class="form-control" type="text"
												:name="'slide-1-' + index" :id="'slide-1-' + index" v-model="slide.slide1">
										</div>
									</div>
								</td>
								<td>
									<div class="form-group mb-0">
										<input placeholder="Enter your slide content" class="form-control" type="text"
											:name="'slide-2-' + index" :id="'slide-2-' + index" v-model="slide.slide2">
									</div>
								</td>
								<td>
									<div class="step-btn-container">
										<img id="delete-icon" @click="removeSlide(index)"
											src="@/assets/images/delete-icon.svg" />
									</div>
								</td>
							</tr>
						</draggable>
					</table>
				</div>
				<div class="step-btn-container">
					<div class="step-btn-container">
						<button @click="prevStep()" role="button" class="step-btn">
							BACK
						</button>
					</div>
					<div class="step-btn-container">
						<button @click=" { { isEditMode ? updateContentForm() : submitForm() } }" :disabled="!validateSlides()" role="button"
							class="step-btn ">
							{{ isEditMode ? "Update" : "Submit" }} 
						</button>
					</div>
				</div>

			</div>

		</div>
	</b-modal>
</template>

<script>
import Loading from "../../../components/Layout/Loading.vue";
import draggable from "vuedraggable";
import { ContentType } from "../../../constants/content";
import { parseDateToYMD } from "../../../helpers/FormatHelpers";
import { mapActions } from "vuex";

export default {
	name: "FlashCardActionModal",
	props: {
		visible: Boolean,
		isEditMode: Boolean,
		parentContentId: String,
		contentData: Object,
	},
	data() {
		return {
			title: null,
			startDate: null,
			endDate: null,
			isAvailableForDemo: false,
			stepIndex: 0,
			flashCards: [{
				slide1: null,
				slide2: null,
				sequence: 1,
			}],
			isExpanded:false,
			content: null,
			content: null,
		};
	},
	methods: {
		...mapActions("content", ["createFlashCards", "getContentById", "updateContent"]),
		validateForm() {
			return this.title != null
		},
		validateSlides() {
			for (let card of this.flashCards) {
				if (card.slide1 == null || card.slide1.trim() === '') {
					return false;
				}
				if (card.slide2 == null || card.slide2.trim() === '') {
					return false;
				}
			}
			return true;
		},
		nextStep() {
			if (this.validateForm()) {
				this.stepIndex = 1;
			}
		},
		prevStep() {
			this.stepIndex = 0;
		},
		addSlide() {
			this.flashCards.push({
				slide1: null,
				slide2: null,
				sequence: this.flashCards.length + 1
			});
		},
		removeSlide(index) {
			if (index !== null && index >= 0 && index < this.flashCards.length && this.flashCards.length > 1) {
				this.flashCards.splice(index, 1);
				this.updateSequence();
			}
			else {
				console.log('Invalid Index')
			}
		},
		submitForm() {
			if (this.validateSlides()) {
				var tempFlashCards = [];
				var tempStartDate = null;
				var tempEndDate = null;

				if (this.startDate != null) {
					tempStartDate = new Date(this.startDate).toISOString();
				}

				if (this.endDate != null) {
					tempEndDate = new Date(this.endDate).toISOString();
				}


				for (var iterate = 0; iterate < this.flashCards.length; iterate++) {
					tempFlashCards.push({
						"Slide1": this.flashCards[iterate].slide1,
						"Slide2": this.flashCards[iterate].slide2,
						"Sequence": this.flashCards[iterate].sequence
					});
				}
				var submitData = {
					title: this.title,
					availableFrom: tempStartDate,
					availableTo: tempEndDate,
					isAvailableForDemo: this.isAvailableForDemo || false,
					parentDirectoryID: this.parentContentId,
					type: ContentType.FLASHCARDS,
					data: { "flashcards": JSON.stringify(tempFlashCards) },
				};

				console.log('primary map:', submitData)
				this.createFlashCards(submitData).then(() => {
					this.$emit("updateModalState", false);
					Object.assign(this.$data, this.$options.data.call(this));
					this.$toasted.success("Flash Cards created successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			}
			else {
				this.$toasted.error("some required fields are empty", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			}

		},
		updateSequence() {
			for (var iterate = 0; iterate < this.flashCards.length; iterate++) {
				this.flashCards[iterate].sequence = iterate + 1;
			}
		},
		loadEditContent() {
			if (this.isEditMode) {
				this.getContentById({ id: this.content.id }).then((data) => {
					console.log("Flash Content ", data);
					this.title = data.title;
					this.startDate = data.availableFrom
						? parseDateToYMD(data.availableFrom)
						: null;
					this.endDate = data.availableTo
						? parseDateToYMD(data.availableTo)
						: null;
					this.isAvailableForDemo = data.isContentAvailableForDemo;
					var jsonFlashCards = JSON.parse(data.data).Flashcards;
					var tempFlashCards = [];

					for (let card of jsonFlashCards) {
						tempFlashCards.push({
							slide1: card.Slide1,
							slide2: card.Slide2,
							sequence: card.Sequence,
						});
					}

					this.flashCards = tempFlashCards;
					this.content = data;
					this.isAvailableForDemo = data.isContentAvailableForDemo;
					console.log(this.$data)
				});
			}
		},
		updateContentForm() {
			if (this.validateSlides()) {
				if (this.isEditMode && this.content != null) {

					var tempFlashCards = [];

					for (var iterate = 0; iterate < this.flashCards.length; iterate++) {
						tempFlashCards.push({
							"Slide1": this.flashCards[iterate].slide1,
							"Slide2": this.flashCards[iterate].slide2,
							"Sequence": this.flashCards[iterate].sequence
						});
					}

					var contentObject = {
						id: this.content.id,
						title: this.title,
						availableFrom: this.startDate ? new Date(this.startDate).toISOString() : null,
						availableTo: this.endDate ? new Date(this.endDate).toISOString() : null,
						isAvailableForDemo: this.isAvailableForDemo,
						parentDirectoryID: this.parentContentId,
						type: ContentType.FLASHCARDS,
						data: { "flashcards": JSON.stringify(tempFlashCards) },
					};

					console.log("content object:", contentObject);

					this.updateContent(contentObject).then(() => {
						this.$toasted.success("Flash Cards updated successfully", {
							duration: 3000,
							theme: "outline",
							position: "bottom-center",
						});
						this.$emit("updateModalState", false);
						Object.assign(this.$data, this.$options.data.call(this));
					});
				}
			}
			else {
				this.$toasted.error("some required fields are empty", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			}
		}

	},
	components: {
		Loading,
		draggable,
	},
	mounted() {

	},
	created() {
	},
	computed: {},
	watch: {
		visible: function () {
			if (this.visible) {
				this.content = this.contentData;
				this.loadEditContent()
			}
		},
	},
};
</script>

<style scoped>

	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}

.body-content-container .expand{
	width: 100%  !important;
	padding-right: 0px;
}
.info-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.info-container .form-group {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.info-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

.form-input:focus {
	border-color: var(--primary-color) !important;
}

.info-container tbody img {
	width: 40px;
}

#delete-icon {
	width: 20px;
	cursor: pointer;
	margin-top: 30px;
	margin-bottom: auto;
}
</style>
<style>
.flash-card-modal
{
  height: 45.375rem;
  /* width: 70vw; */
}
.flash-card-modal .modal-body.modal-body
{
	display: flex;
    flex-direction: row;
    padding: 0 !important;
	overflow: auto;
}
.flash-card-modal .modal-body.modal-body .body-container
{
	
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

#flash-card-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#flash-card-modal .expanded .flash-card-modal
{
	height: 90vh;
	
}

</style>
