<template>
	<b-modal id="form-modal" @change="$emit('updateModalState', false)" :visible="visible" hide-header centered
		content-class="form-modal-modal" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div v-if="formData">
		<h1 class="add-content-title">
			{{ title }}
		</h1>
		<p>{{ description }}</p>
		<div class="form-group" v-for="(field, index) in fields" :key="field.Key">
			<DynamicCustomFormInput :inputFieldOptions="field" :hasIcon="false" @inputValue="
				(value)=> {					
					formResponse[field.key] =value;
				}
			" :required="true" :showLabel="false" :tabIndex="61 + index" />
		</div>
		
		<div class="step-btn-container">
			
			<button @click="closeForm" v-if="!(formData && formData.isRequired==true)" role="button" class="step-btn" >				
			    Cancel 
			</button>
			<button @click="submitFormResponse" role="button" :disabled="isLoading" class="submit-btn" >
				
			    Submit 
			</button>
		</div></div>
		<div v-else>
			<h2>
			<center>Loading</center></h2>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DynamicCustomFormInput from "../Layout/DynamicCustomFormInput.vue"

export default {
	name: "FormModal",
	props: {
		visible: Boolean,
		formID: String
	},
	data() {
		return {
			title: "",
			description:"",
			formData:null,
			fields: [],
			formResponse: {},
			isLoading:false
		};
	},
	methods: {		
		...mapActions("form", ["fetchFormByID","submitForm"]),
	    submitFormResponse() {
			var invalidateFeilds=this.fields.filter(x=>!this.formResponse[x.key] && x.isRequired==true);
			if(invalidateFeilds.length>0)
			{
				this.$toasted.error(invalidateFeilds[0].error, {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
			}else{
				var data=JSON.stringify(this.formResponse);
				this.isLoading=true;
				this.submitForm({ FormID: this.formID, FormData: data }).then((submission) => {
					this.isLoading=false;
					if (submission && submission.id) {
						localStorage.removeItem("ProcessingFormID");
						this.closeForm();
						this.$toasted.success("Response Submitted", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
					} else {
						this.$toasted.error("Some Error Occured", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
					}
				});
				
			}
		},
		closeForm(){
			this.$emit('closeModalState');
		}
	},
	computed: {
		...mapState("form", ["loading"]),
	},
	created() {
		if (this.formID) {
			this.fetchFormByID(this.formID).then((form) => {
				if(!form)
				{
					this.closeForm();
				}

				if(!(form && form.isRequired==true)){

					localStorage.removeItem("ProcessingFormID");
				}
				this.formData=form;
				this.title = form.title;
				this.fields = form.fields;
				this.description=form.description;
			});
		}
	},
	components: {
		DynamicCustomFormInput,
	},
	watch: {
		visible: function () {

		}
	},
};
</script>

<style>
.form-modal {
	min-height: 45.375rem;
	width: 70vw;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.form-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}
.submit-btn{
	background-color: var(--primary-color);
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    box-shadow: 0 3px 6px #d5d5d529;
    padding: 1rem 1.25rem;
    line-height: 1.25;
    color: #ffffff !important;
}
.step-btn{
    padding: 1rem 1.25rem !important;
}
.step-btn-container{
	float: right;
}
.custom-alert-danger{
	padding: 5px;
	border: 1px solid;
	border-color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity));
	border-radius: 5px;
}
</style>
