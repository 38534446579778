<template>
	<div class="user-modal-tab-body invoices">
		<table class="table table-hover table-borderless enrolled-users-table">
			<thead>
				<tr class="d-flex">
					<th class="col-1">
						<input
							type="checkbox"
							name="select_all"
							id="select_all"
							@click="toggleSelectAll()"
							:checked="isSelectedAll()"
						/>
					</th>
					<th class="col-1">ID</th>
					<th class="col">Invoice Date</th>
					<th class="col">Amount</th>
					<th class="col">Currency</th>
					<th class="col">Status</th>
					<!-- <th class="col-1">Payments</th> -->
				</tr>
			</thead>
			<tbody v-if="invoices && invoices.length > 0">
				<tr
					class="d-flex invoice-item"
					v-for="invoice in invoices"
					:key="invoice.id"
					@click="toggleInvoiceModal(invoice, 'view')"
				>
					<td class="col-1">
						<input
							type="checkbox"
							name="select_1"
							:id="invoice.id"
							@click="toggleInvoiceSelect(invoice)"
							:checked="isChecked(invoice.id)"
						/>
					</td>
					<td class="col-1">{{ invoice.id }}</td>
					<td class="col">{{ dateParser(invoice.invoiceDate) }}</td>
					<td class="col">
						{{ currencyParser(totalAmount(invoice.details), invoice.currency) }}
					</td>
					<td class="col">
						{{ invoice.currency }}
					</td>
					<td class="col">
						<div
							:class="[
								'status-tag',
								{
									success:
										invoice.status && invoice.status == InvoiceStatus.PAID,
									danger:
										invoice.status &&
										(invoice.status == InvoiceStatus.UNPAID ||
											invoice.status == InvoiceStatus.OVER_PAID),
									warning:
										invoice.status &&
										invoice.status == InvoiceStatus.PARTIALLY_PAID,
								},
							]"
						>
							{{ invoice.status }}
						</div>
					</td>
				</tr>
			</tbody>
			<div
				v-else-if="invoices.length == 0 && loading"
				class="d-flex align-items-center justify-content-center gap-2 p-3 font-500"
			>
				<b-spinner small></b-spinner> Loading...
			</div>
			<div v-else class="no-content">No Invoices found</div>
		</table>
		<UserInvoiceDetailModal
			:invoice="invoiceToViewPay"
			:invoiceMode="invoiceMode"
			:showModal="showInvoiceModal"
			@updateModalState="toggleInvoiceModal"
			:user="user"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../../helpers/FormatHelpers";
import UserInvoiceDetailModal from "./UserInvoiceDetailModal.vue";
import { InvoiceStatus } from "../../../constants/pricing";

export default {
	name: "UserInvoiceAndPayments",
	data() {
		return {
			selectedInvoices: [],
			invoices: [],
			showInvoiceModal: false,
			invoiceToViewPay: null,
			invoiceMode: "view",
			InvoiceStatus,
		};
	},
	props: { user: Object },
	methods: {
		...mapActions("pricing", ["fetchInvoiceByUserId", "deleteInvoice"]),
		toggleSelectAll() {
			if (
				this.invoices &&
				this.invoices.length > 0 &&
				this.selectedInvoices.length != this.invoices.length
			) {
				this.selectedInvoices = this.invoices;
			} else {
				this.selectedInvoices = [];
			}
		},
		isSelectedAll() {
			return this.invoices.length == this.selectedInvoices.length;
		},
		toggleInvoiceSelect(invoice) {
			var found = this.selectedInvoices.find((s) => s.id == invoice.id);
			// console.log(found, invoice);
			if (!found) {
				this.selectedInvoices.push(invoice);
			} else {
				this.selectedInvoices = this.selectedInvoices.filter(
					(su) => su.id != invoice.id
				);
			}
		},
		isChecked(id) {
			var found = this.selectedInvoices.find((invoice) => invoice.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		currencyParser(amount, currency) {
			return formatter.toCurrencyString(parseInt(amount), currency, 0);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		totalAmount(details) {
			var total = 0;
			details.forEach((detail) => {
				total += detail.amount;
			});
			return total;
		},
		toggleInvoiceModal(invoice, mode) {
			this.showInvoiceModal = !this.showInvoiceModal;
			if (this.showInvoiceModal) {
				this.invoiceToViewPay = invoice;
				this.invoiceMode = mode;
			} else {
				// this.invoiceToViewPay = null;
				this.invoiceMode = "view";
			}
		},
		payInvoice(e, invoice) {
			e.stopPropagation();
			this.toggleInvoiceModal(invoice, "pay");
		},
	},
	computed: {
		...mapState("pricing", ["invoicesByUserId", "loading"]),
	},
	created() {
		if (!this.invoicesByUserId[this.user.id]) {
			this.fetchInvoiceByUserId(this.user.id).then(() => {
				console.log("invoices fetched");
				this.invoices = this.invoicesByUserId[this.user.id];
			});
		} else {
			this.invoices = this.invoicesByUserId[this.user.id];
		}

		this.invoices=this.invoices.sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate));



	},
	components: { UserInvoiceDetailModal },
	watch: {
		invoicesByUserId: function () {
			console.log("invoices updated in store");
			if (this.invoicesByUserId[this.user.id]) {
				this.invoices = this.invoicesByUserId[this.user.id];
			}
		},
	},
};
</script>

<style scoped>
.no-content {
	padding: 1rem;
	text-align: center;
	font-weight: 500;
	color: #666;
	font-size: 1.125rem;
}

input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.status-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
}

.status-tag.success {
	background-color: #00c672;
}

.status-tag.danger {
	background-color: var(--color-danger);
}

.status-tag.warning {
	background-color: var(--color-warning);
}

.invoice-item {
	cursor: pointer;
}

/* .user-modal-tab-body.invoices { */
/* max-height: 30rem;
	overflow-y: auto; */
/* } */
</style>
