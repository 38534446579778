import { categoryService } from "../services/category.service";
import Vue from "vue";
import store from ".";

const state = {
	categories: [],
	loading: false,
};

const getters = {
	allCategories: (state) => {
		return state.categories;
	},
};

const actions = {
	fetchCategories: async ({ commit }) => {
		commit("setLoading", true);
		// state.loading = true;
		await categoryService.fetchAllCategories().then(
			(category) => {
				// console.log(category);
				commit("setCategories", category?.payload);
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
	createCategory: async ({ commit }, title) => {
		commit("setLoading", true);
		await categoryService.createCategory(title).then(
			(category) => {
				console.log("category created, id:", category.payload);

				var categoryObject = {
					childCategories: null,
					id: category.payload,
					isVisible: true,
					title: title,
				};
				commit("addCategory", categoryObject);
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
	editProductCategorySequence: async ({commit}, categoryObj) => {
		const data = await categoryService.editProductCategorySequence(categoryObj).then(
			(res) => {
				if(res.ok) {
					Vue.toasted.success("Sequence Updated Successful", {
						theme: "outline",
						position: "bottom-center",
						duration: 5000,
					});
				} 
				else {
					Vue.toasted.error("Some error occurred", {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				}
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				Vue.toasted.error("Some error occurred", {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
	},
	updateCategory: async ({ commit }, updatedObject) => {
		commit("setLoading", true);
		await categoryService.updateCategory(updatedObject).then(
			(category) => {
				console.log("category updated, id:", updatedObject.id);

				var categoryObject = {
					...updatedObject,
					childCategories: null,
				};
				commit("updateCategory", categoryObject);
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
	deleteCategory: async ({ commit }, id) => {
		commit("setLoading", true);
		await categoryService.deleteCategory(id).then(
			(category) => {
				console.log("category deleted, id:", id);
				commit("deleteCategory", id);
				Vue.toasted.success("Qualification Deleted Successfully", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
	assignCategoryToProduct: async ({ commit }, productData) => {
		commit("setLoading", true);
		await categoryService.assignCategoryToProduct(productData).then(
			(category) => {
				// console.log("category deleted, id:", id);
				store.commit("product/assignCategoryToProduct", productData);
				commit("setLoading", false);

				Vue.toasted.success("Qualification assigned successfully", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
	unassignCategoryToProduct: async ({ commit }, productData) => {
		commit("setLoading", true);
		await categoryService.unassignCategoryToProduct(productData).then(
			(category) => {
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				commit("setLoading", false);
			}
		);
	},
};

const mutations = {
	setCategories(state, data) {
		// console.log(data);
		if (data != null) {
			state.categories = data;
		}
		state.loading = false;
	},
	addCategory(state, data) {
		state.categories.push(data);
		state.loading = false;
	},
	updateCategory(state, data) {
		console.log("updating state", data);
		state.categories = state.categories.map((item) => {
			if (item.id == data.id) return data;
			return item;
		});
		state.loading = false;
	},
	deleteCategory(state, id) {
		console.log("updating state", id);
		state.categories = state.categories.filter((item) => item.id != id);
		state.loading = false;
	},
	setLoading(state, data) {
		if (data) {
			console.log("categories loading...");
		} else {
			console.log("categories loading complete!");
		}
		state.loading = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
