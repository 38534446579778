<template>
	<div>
		<div class="sm-header sm-header-notifications">
			<img @click="showHideNotification" class="sm-header-arrow" src="@/assets/images/caret-right.svg" />
			<h6 class="sm-header-title">Notifications</h6>
		</div>
		<div class="eb-sidebar-classes-wrapper">
			<div class="sidebar-tabs-wrapper">
				<b-tabs content-class="sidebar-tab-content" nav-class="sidebar-classes-tabs">
					<b-tab title="Current Events" active>
						<div class="eb-sidebar-classes" v-if="filteredUpcomingContent(UpcomingContentType.CURRENT).length>0">
							<ReminderListItem v-for="content in filteredUpcomingContent(UpcomingContentType.CURRENT)" :reminderItem="content">
							</ReminderListItem>							
						</div>
						<div v-else class="no-content no-lc-content">
							<img src="@/assets/images/calendar-time-icon.svg">
							There are no current events scheduled
						</div>
					</b-tab>
					<b-tab title="Upcoming Events">
						<div class="eb-sidebar-classes" v-if="filteredUpcomingContent(UpcomingContentType.UPCOMING).length>0">
							<ReminderListItem v-for="content in filteredUpcomingContent(UpcomingContentType.UPCOMING)" :reminderItem="content">
							</ReminderListItem>								
						</div>
						<div v-else class="no-content no-lc-content">
							<img src="@/assets/images/calendar-time-icon.svg">
							There are no upcoming events scheduled
						</div>
					</b-tab>
				</b-tabs>
			</div>
			


			<div v-if="!isMobile" class="accordion accordion-flush" id="reminderAccordian" style="display: none;">
				<div v-for="contentType in Object.values(UpcomingContentType)" :key="contentType"
					class="accordion-item mb-3">
					<h3 class="accordion-header" :id="`accordian-${contentType}`">
						<button :class="[
							'accordion-button',
							{ collapsed: activeAnnouncementAccordian != contentType },
						]" type="button" data-bs-toggle="collapse" :data-bs-target="`#accordian-collapse-${contentType}`"
							:aria-expanded="activeAnnouncementAccordian == contentType">
							{{ contentType }} Events
						</button>
					</h3>
					<div :id="`accordian-collapse-${contentType}`" :class="['accordion-collapse']"
						data-bs-parent="#reminderAccordian">
						<div class="accordion-body">
							<div class="eb-sidebar-classes"
								v-if="filteredUpcomingContent(contentType).length > 0 && !loading">
								<ReminderListItem v-for="item in filteredUpcomingContent(contentType)" :key="item.id"
									:reminderItem="item" />
							</div>
							<div class="no-content no-lc-content" v-else>
								No {{ contentType.toLowerCase() }} events
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import ReminderListItem from "./ReminderListItem.vue";
import { mapActions, mapState } from "vuex";
import { UPDATES_CAROUSEL_SETTINGS } from "../../constants/productCarouselSettings";
import VueSlickCarousel from "vue-slick-carousel";
import { getInputDateFormat } from "../../helpers/FormatHelpers";
import { UpcomingContentType } from "../../constants/content";

export default {
	name: "Reminders",
	data() {
		return {
			activeAnnouncementAccordian: UpcomingContentType.CURRENT,
			activeTab: "reminder",
			settings: UPDATES_CAROUSEL_SETTINGS,
			currentReminderSlide: 1,
			currentAnnouncementSlide: 1,
			totalReminderSlides: 0,
			loading: false,
			UpcomingContentType,
		};
	},
	components: { ReminderListItem, VueSlickCarousel },
	methods: {
		...mapActions("content", ["getUpcomingContent"]),
		hasContentStarted(reminderItem) {
			return (
				new Date(
					new Date(reminderItem.availableFrom).getTime() - 5 * 60 * 1000
				) < new Date()
			);
			// return new Date(reminderItem.availableFrom) < new Date();
		},
		// check if meeting has ended and 15 minutes have passed
		hasContentEnded(reminderItem) {
			return (
				new Date() >
				new Date(
					new Date(reminderItem.availableTo).setTime(
						new Date(reminderItem.availableTo).getTime() + 900000
					)
				)
			);
		},
		changeTab(e) {
			this.activeTab = e.target.id;
		},
		nextSlide() {
			this.$refs.reminder_carousel.next();
			if (this.currentReminderSlide < this.totalReminderSlides)
				this.currentReminderSlide += 1;
		},
		prevSlide() {
			this.$refs.reminder_carousel.prev();
			if (this.currentReminderSlide > 1) this.currentReminderSlide -= 1;
		},
		updateSlide(currSlide) {
			this.currentReminderSlide = currSlide + 1;
		},
		filteredUpcomingContent(type = null, order = "asc") {
			if (type) {
				return this.upcomingContent.filter((content) => {
					switch (type) {
						case UpcomingContentType.CURRENT:
							if (
								this.hasContentStarted(content) &&
								!this.hasContentEnded(content)
							) {
								return content;
							}
							break;
						case UpcomingContentType.UPCOMING:
							if (!this.hasContentStarted(content)) {
								return content;
							}
							break;
						case UpcomingContentType.PAST:
							if (this.hasContentEnded(content)) {
								return content;
							}
							break;
					}
				});
			}
			return this.upcomingContent;
		},
		showHideNotification() {
			document.getElementById("coursesNotification").classList.toggle("active");
		},
	},
	created() {
		this.loading = true;
		var currentDate = new Date();
		var tomorrowDate = new Date(currentDate).setDate(currentDate.getDate() + 1);
		tomorrowDate = new Date(tomorrowDate).toISOString();
		this.getUpcomingContent({
			startDate: new Date(currentDate.setHours(0, 0, 0, 0)).toISOString(),
			endDate: tomorrowDate,
		}).then(() => {
			this.loading = false;
			this.totalReminderSlides = this.filteredUpcomingContent().length;
			this.$emit("updatesCardVisibility", this.upcomingContent.length > 0);
		});
	},
	computed: {
		...mapState("content", ["upcomingContent"]),
		...mapState("appState", ["isMobile"]),
	},
};
</script>

<style scoped>
@media screen and (min-width: 1440px) {
	.dashboard-updates-container {
		width: 30% !important;
	}
}

@media screen and (max-width: 996px) {
	.updates-card {
		box-shadow: 0 3px 6px #d5d5d529 !important;
	}

	.reminders-content {
		max-height: unset !important;
		padding: 1rem !important;
		margin: 0 !important;
		padding-bottom: 0.5rem !important;
	}

	.dashboard-updates-container {
		padding: 0 !important;
		width: 100% !important;
	}
}

.reminders-content.mobile {
	overflow: hidden;
}

.dashboard-updates-container {
	display: flex;
	padding: 0 0 0 0.5rem;
	flex-shrink: 0;
	width: 35%;
}

.updates-card {
	width: 100%;
	border: 1px solid #959595;
	border-radius: 8px;
	/* padding: 1.5rem 1.5rem 0; */
	margin-top: -3.5rem;
	z-index: 998;
	background-color: var(--color-white);
	box-shadow: 0 -6px 8px #e0e0e029;
	height: fit-content;
}

.updates-tabs {
	border-radius: 8px 8px 0 0;
	background-color: #f8f8f8;
}

.updates-tabs li {
	width: 50%;
	font-weight: 500;
	padding: 1rem;
}

.updates-tabs li:first-child {
	border-radius: 8px 0 0 0 !important;
}

.updates-tabs li.active {
	border-radius: 0 8px 0 0;
	background-color: #ffffff;
}

.announcements-content {
	padding: 1rem 1.5rem 0;
	max-height: 50vh;
	overflow-y: auto;
	margin: 0.5rem 0;
}

.reminders-content {
	padding: 1rem 1.5rem 0;
	max-height: 60vh;
	overflow-y: auto;
	margin: 0.5rem 0;
}

.tab-icon {
	font-size: 1.1rem;
	margin-right: 0.5rem;
	pointer-events: none;
}

.accordion-flush .accordion-item .accordion-button {
	text-transform: inherit;
	font-weight: inherit;
	font-size: inherit;
	padding: 0.5rem 0;
}

.accordion-button:not(.collapsed) {
	background-color: transparent !important;
	box-shadow: none !important;
	color: inherit;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%707070'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-body {
	padding: 1rem 0;
}

.announcement-item {
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background-color: #fafafa;
	box-shadow: 0 6px 8px #e0e0e029;
	padding: 1.25rem;
	margin-bottom: 1.5rem;
}

.announcement-item:last-child {
	margin-bottom: 0;
}

.announcement-title {
	font-size: 1.125rem;
	color: #000;
	font-weight: 500;
	width: 100%;
}

.announcement-text {
	font-size: 1rem;
	color: #4a4a4a;
	line-height: 1.5rem;
	font-weight: normal;
	margin-bottom: 0.5rem;
}

.announcement-read-more {
	font-size: 0.875rem;
	font-weight: 450;
	color: #ff0000;
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 6px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: #ebebeb;
}

::-webkit-scrollbar-thumb {
	background: #000000;
	border-radius: 1rem;
}

.notification-badge {
	position: relative;
	top: -0.75rem;
	right: 0.3rem;
	font-size: 0.7rem;
	line-height: 1;
	background-color: var(--color-red);
	display: inline-block;
	/* padding: 0.2rem 0.3rem; */
	width: 1rem;
	height: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5rem;
	font-weight: 500;
	color: #ffffff;
}

.accordion-button:focus {
	box-shadow: none !important;
}

.carousel-arrows {
	display: flex;
}

.carousel-arrow-mobile {
	margin: 0.5rem;
	font-size: 1.25rem;
	border: 1px solid var(--primary-color);
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	border-radius: 1rem;
	text-decoration: none;
	color: #000;
}

.carousel-arrow-mobile:nth-of-type(1) {
	padding-right: 0.125rem;
}

.carousel-arrow-mobile:nth-of-type(2) {
	padding-left: 0.125rem;
}

.carousel-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.25rem;
}

.carousel-slide-count {
	color: var(--color-gray);
}

/* .live-class-item {
	width: 100%;
	border: 2px solid var(--color-gray);
	border-radius: 4px;
	padding: 1rem;
	margin-bottom: 1rem;
}

.live-class-tag {
	border-radius: 4px;
	background-color: var(--color-red);
	padding: 0.1rem 0.5rem;
	color: var(--color-white);
	font-size: 0.65rem;
	text-transform: uppercase;
}

.class-timings {
	color: var(--color-dark-gray);
	font-size: 0.7rem;
	margin-left: auto;
	display: inline-block;
}

.live-class-title {
	font-size: 1rem;
	padding: 0.5rem 0;
	margin: 0;
	margin-top: 0.5rem;
	font-weight: bold;
}

.join-btn {
	text-transform: uppercase;
	text-decoration: none;
	font-size: 0.75rem;
	margin: 0;
	padding: 0.2rem 0.75rem;
	color: white;
	background-color: var(--color-red);
	border-radius: 1rem;
	margin-left: 0.5rem;
	font-weight: 500;
} */


.eb-sidebar-classes {
	padding: 20px 15px 15px;
}

.no-lc-content {
	padding: 2rem 2.5rem 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 400;
	color: var(--gray-2);
}

.no-lc-content img {
	margin: 30px 0;
	max-width: 78px;
}

.sm-header-notifications {
	display: none;
	padding: 25px 20px 15px 5px;
	align-items: center;
	height: 72px;
}

.sm-header-arrow {
	transform: scaleX(-1);
	padding: 10px 15px;
}

@media (max-width:768px) {
	.sm-header-notifications {
		display: flex;
	}

	.sm-header-notifications h6 {
		font-size: 1.75em;
		margin: 0;
	}

	.eb-sidebar-classes-wrapper {
		border: none;
		box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.05);
	}

	.sidebar-classes-tabs>li button {
		height: 48px;
		border-radius: 0 !important;
	}

	.eb-sidebar-classes {
		height: calc(100vh - 160px);
		overflow: auto;
		padding: 0;
	}

	.sidebar-tab-content {
		padding: 20px 15px 15px;
	}
}</style>
<style>
.sidebar-classes-tabs>li {
	width: 50%;
}

.sidebar-classes-tabs>li a {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 0;
	border: none;
	background: #f8f8f8;
	color: black;
	font-size: 1rem;
	text-align: center;
	font-weight: 600;
}
.sidebar-classes-tabs>li a:hover {
	
	color: black;
}

.sidebar-classes-tabs>li a.active {
	background: white;
	color: var(--primary-color) !important;
}

.sidebar-classes-tabs>li:first-child a {
	border-radius: 6px 0 0 0;
}

.sidebar-classes-tabs>li:last-child a {
	border-radius: 0 6px 0 0;
}

</style>
