<template>
	<div class="container product-category-container">
		<div class="section-heading-container">
			<h1 class="section-heading">Online Courses</h1>
			<h5 class="section-subheading">
				Grab the best courses in economical prices
			</h5>
		</div>
		<div class="product-category-tabs">
			<div
				v-if="categories && categories.length > 1"
				@click="changeCategory('0')"
				:class="['category-tab', { active: categoryActive == '0' }]"
			>
				All Courses
			</div>
			<div
				@click="changeCategory(category.id)"
				v-for="category in categories"
				:key="category.id"
				:class="['category-tab', { active: categoryActive == category.id }]"
			>
				{{ category.title }}
			</div>
		</div>
		<div :class="['category-tab-body', { 'first-tab': categoryActive == 0 }]">
			<ProductList
				v-if="filterProductsByCategory"
				viewMode="carousel"
				:productListData="filterProductsByCategory"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ProductList from "./ProductList.vue";
export default {
	name: "ProductCategoryTabs",
	data() {
		return {
			categoryActive: "0",
		};
	},
	components: { ProductList },
	computed: {
		...mapState("category", ["categories", "loading"]),
		...mapGetters("productVariant", ["allProductVariants"]),
		...mapState("productVariant", ["productVariantsByCategory"]),
		filterCategories() {
			const filtered = this.categories?.filter((category) => {
				if (category.isVisible) {
					if (
						this.productVariantsByCategory[category.id] &&
						!this.productVariantsByCategory[category.id].length
					) {
						return false;
					}
					return category;
				}
			});
			return filtered;
		},
		filterProductsByCategory() {
			var filtered = [];
			if (this.categoryActive == "0") {
				var allVariants = Object.values(this.allProductVariants);
				filtered = allVariants.filter((variant) => {
					// console.log(variant);
					if (this.carouselType.toLowerCase() == "featured") {
						if (!variant.isFeatured) {
							return false;
						}
					}
					if (
						variant.status.toLowerCase() != "private" &&
						variant.status.toLowerCase() != "draft"
					) {
						return variant;
					}
				});
				// console.log(filtered);
				// return filtered;
			} else if (
				this.productVariantsByCategory &&
				this.productVariantsByCategory[this.categoryActive]
			) {
				filtered = this.productVariantsByCategory[this.categoryActive].filter(
					(variant) => {
						if (this.carouselType.toLowerCase() == "featured") {
							if (!variant.isFeatured) {
								return false;
							}
						}
						if (
							variant.status.toLowerCase() != "private" &&
							variant.status.toLowerCase() != "draft"
						) {
							return variant;
						}
					}
				);
			}
			//filters productVariants according to category
			// const filtered = { length: 0, items: {} };
			// for (var product in this.allProductVariants.items) {
			// 	var found = false;
			// 	const productCategories = product.categories;
			// 	productCategories.map((category) => {
			// 		if (category.id == this.categoryActive) {
			// 			found = true;
			// 		}
			// 	});
			// 	if (found) {
			// 		filtered.items[product.id] = product;
			// 		filtered.length++;
			// 	}
			// }

			// if (filtered.length !== 0) {
			// 	return filtered;
			// }
			return filtered;
		},
	},
	props: { carouselType: { type: String, default: "" } },
	methods: {
		...mapActions("category", ["fetchCategories"]),
		...mapActions("productVariant", [
			"fetchProductVariants",
			"getProductVariantByCategoryId",
		]),
		changeCategory(id) {
			this.categoryActive = id;
		},
	},
	created() {
		// This is commented since the category were already fetched and are in state
		//this.fetchCategories().then(() => {});
		
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({
				event: "product-carousel-loaded",
				data: { type: this.carouselType },
			});
		});
	},
	watch: {
		categoryActive: function () {
			if (
				!this.productVariantsByCategory[this.categoryActive] &&
				this.categoryActive != "0" && this.categoryActive != null
			) {
				this.getProductVariantByCategoryId(this.categoryActive).then(() => {
					console.log("product variants by category fetched");
				});
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.category-tab {
		border: none !important;
		border-bottom: 2px solid transparent !important;
	}

	.category-tab-body {
		border: none !important;
		padding: 0 !important;
	}

	.category-tab.active {
		border-bottom: 2px solid var(--primary-color) !important;
	}
}

@media screen and (max-width: 480px) {
	.section-heading {
		font-size: 2rem !important;
	}

	.section-subheading {
		font-size: 1rem !important;
	}
	.section-heading-container {
		margin: 2rem 0 !important;
	}
}

.section-heading {
	font-weight: 500;
	font-size: 2.75rem;
	line-height: 1.3;
	color: var(--secondary-color);
	margin-bottom: 0;
	letter-spacing: -0.45px;
}

.section-subheading {
	font-size: 1.375rem;
	color: #898989;
	font-weight: normal;
}

.section-heading-container {
	margin: 4rem 0 3.375rem;
}

.product-category-tabs {
	display: flex;
	overflow-x: hidden;
	overflow-y: visible;
	/* z-index: 100; */
	position: relative;
	top: 0.063rem;
}

.product-category-tabs:hover {
	overflow-x: overlay;
}

.category-tab {
	padding: 1.25rem 0.75rem;
	color: #898989;
	border-radius: 8px 8px 0 0;
	font-weight: 500;
	min-width: fit-content;
	text-align: center;
	border: 1px solid transparent;
	cursor: pointer;
}

.category-tab.active {
	border: 1px solid #a7a9ac;
	border-bottom: 2px solid #ffffff;
	/* border-bottom-color: #ffffff; */
	position: relative;
	color: #000000;
	font-weight: bold;
}

.category-tab-body {
	padding: 2.125rem 1.5rem;
	border: 1px solid #a7a9ac;
	border-radius: 8px;
	/* position: relative;
	top: -1px; */
}

.category-tab-body.first-tab {
	border-top-left-radius: 0;
}
</style>
