<template>
	<div
		class="dropdown-menu dropdown-menu-lg-end add-payment-dropdown"
		@click="(e) => e.stopPropagation()"
		:aria-labelledby="`payment-${cycle.id}`"
	>
		<div class="inline-group">
			<div class="col">
				<label for="amountPayable" class="main-label extended"
					>Course Price</label
				>
				<input
					placeholder="Enter payable amount"
					class="form-input"
					type="text"
					id="amountPayable"
					disabled
					:value="selectedCyclePrice(cycle.id)"
				/>
			</div>
			<div class="col">
				<label
					for="amountReceived"
					:class="['main-label extended', { 'text-danger': isError }]"
					>Amount Received (required)</label
				>
				<input
					placeholder="Enter received amount"
					:class="['form-input', { 'text-danger': isError }]"
					type="number"
					id="amountReceived"
					v-model="cycleReceived"
					:max="selectedCyclePrice(cycle.id) - cycleDiscount"
					@change="validateAmount"
				/>
			</div>
			<div class="col">
				<label
					for="amountDiscount"
					:class="['main-label extended', { 'text-danger': isError }]"
					>Discount (optional)</label
				>
				<input
					placeholder="Enter discount"
					:class="['form-input', { 'text-danger': isError }]"
					type="number"
					id="amountDiscount"
					v-model="cycleDiscount"
					:max="selectedCyclePrice(cycle.id) - cycleReceived"
					@change="validateAmount"
				/>
			</div>
			<div class="col">
				<label
					for="amountBalance"
					:class="['main-label extended', { 'text-danger': isError }]"
					>Outstanding</label
				>
				<input
					placeholder="Enter balance"
					:class="['form-input', { 'text-danger': isError }]"
					type="text"
					id="amountBalance"
					:value="cycleOutstanding"
					disabled
				/>
			</div>
		</div>
		<p v-if="isError" class="text-danger">
			<i class="fas fa-exclamation-circle"></i> {{ errorText }}
		</p>
		<p class="text-danger" v-if="amountError">
			<i class="fas fa-exclamation-circle"></i> {{ errorText }}
		</p>
		<div class="buttons">
			<button class="skip-step-btn" @click="closeDropdown(cycle.id)">
				Cancel
			</button>
			<button
				:disabled="isError"
				class="step-btn"
				@click="!isError && togglePaid($event, cycle.id)"
			>
				Mark as Paid
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "PlanCyclePaymentDropdown",
	props: { cycle: Object, selectedCycles: Array, multiplePlanPayments: Object },
	data() {
		return {
			cycleReceived: null,
			cycleDiscount: null,
			amountError: false,
			errorText: "",
			isError: false,
			// cycleOutstanding: null,
		};
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
		togglePaid(e, id) {
			if (!this.cycleReceived) {
				e.preventDefault();
				this.errorText = "Please enter received amount";
				this.amountError = true;
			} else {
				var amount = {
					received: this.cycleReceived,
					discount: this.cycleDiscount ? this.cycleDiscount : "0",
				};
				this.$emit("payment", { id, amount });
			}
		},
		selectedCyclePrice(id) {
			var currentCycle = this.selectedCycles.find((cycle) => cycle.id == id);
			return currentCycle ? currentCycle.amount : 0;
		},
		closeDropdown(id) {
			document.getElementById(`payment-${id}`).click();
		},
		validateAmount(e) {
			console.log("validateAmount", e.target.value);
			if (e.target.value < 0) {
				this.isError = true;
				console.log("error", e.target.id);
				this.errorText = `Enter a valid amount ${
					e.target.id == "amountReceived"
						? "(Amount Received)"
						: e.target.id == "amountDiscount"
						? "(Discount)"
						: ""
				}`;
			} else {
				return this.cycleOutstanding;
			}
		},
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		// isError() {
		// 	var error = this.cycleOutstanding == "Invalid amount";
		// 	// if (error) {
		// 	// 	this.$emit("error", error);
		// 	// }
		// 	return error;
		// },
		cycleOutstanding() {
			var balance;
			if (this.cycle)
				balance = this.cycle.amount - this.cycleDiscount - this.cycleReceived;
			else balance = 0;
			if (balance >= 0) {
				this.isError = false;
				this.errorText = "";
				return balance;
			} else {
				this.isError = true;
				this.errorText = "Paid amount cannot be greater than course amount";
				return "Invalid amount";
			}
		},
	},
	created() {
		if (this.cycle.isPaid) {
			this.cycleReceived = this.multiplePlanPayments[this.cycle.id].received;
			this.cycleDiscount = this.multiplePlanPayments[this.cycle.id].discount;
		}
		// document
		// 	.getElementById("add-payment-dropdown")
		// 	.onclick((e) => e.stopPropagation());
	},
};
</script>

<style scoped>
.add-payment-dropdown {
	max-width: 50rem;
	padding: 1.5rem;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 1rem;
}

.main-label.extended {
	min-height: 2.75rem !important;
}
</style>
