<template>
    <div class="ms-5">
        <div>
            <h2 class="pt-5 pb-5">Visa/Master Card</h2>
            <h5 v-if="message" class="text-success">{{ message }}</h5>
            <h5 v-if="error" class="text-danger">{{ error }}</h5>
        </div>
    </div>
</template>
  
<script>
import { mounted } from "vue2-dropzone";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
    name: "Stripe",
    data() {
        return {
            message: null,
            error: null,
            paymentData: null,
            isSubmitForm: false,
        };
    },
    computed: {
        ...mapState("user", ["user"]),
        ...mapState("pricing", ["invoicesByUserId", "loading"]),
        isPostPayment() {
            let action = this.$route.params.action;

            return action && action.toLowerCase() == "postPayment";
        },
    },
    methods: {
        ...mapActions("pricing", ["fetchInvoiceByUserId", "processPayment"]),
    },
    created() {
        let action = this.$route.query.Action;

        if(action && action.toLowerCase() == "completed") {
            Swal.fire({
                title: "Transaction Successful",
                text: `Congratulations! Your payment has been processed successfully. You may navigate to your courses.`,
                icon: "success",
                button: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((confirm) => {
                if (confirm) {
                    this.$router.push("/user/dashboard");
                }
            });
        }
        else if(action && action.toLowerCase() == "cancel") {
            Swal.fire({
                title: "Transaction Failed",
                text: `Payment has been cancelled. You may navigate to your courses.`,
                icon: "error",
                button: "Ok",
                dangerMode: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((confirm) => {
                if (confirm) {
                    this.$router.push("/user/dashboard");
                }
            });
        }
        else {
            let invoiceID = this.$route.params.id;

            if (invoiceID) {
                this.message = "Fetching payment details, please wait...";
                let invoice;

                if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
                    invoice = this.invoicesByUserId[this.user.id].find(
                        (inv) => inv.id == invoiceID
                    );
                } else {
                    this.fetchInvoiceByUserId(this.user.id).then(() => {
                        invoice = this.invoicesByUserId[this.user.id].find(
                            (invoice) => invoice.id == invoiceID
                        );
                    });
                }

                if (invoiceID == invoice.id) {
                    this.processPayment({
                        invoiceId: invoiceID,
                        serviceCode: "Stripe",
                        data: {
                            redirectUrl: `${window.location.origin}/payment/Stripe/${invoiceID}`,
                        },
                    })
                        .then((response) => {
                            console.log({response});
                            if (response && response.data && response.data.paymentUrl) {
                                this.error = undefined;
                                let count = 5;

                                setInterval(() => {
                                    if (count > 0) {
                                        count--;
                                    }

                                    this.message = `You will be redirect to Stripe Payment Gateway in ${count} seconds. Don't reload or leave browser.`;

                                    if (count == 1) {
                                        clearInterval();
                                        window.location.href = response.data.paymentUrl;
                                    }
                                }, 1000);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.error =
                                "Some error occurred while fetching data from payment gateway.";
                        });
                } else {
                    this.error = "Invoice not found.";
                }
            } else {
                this.error = "Invalid parameters";
            }
        }
    },
};
</script>
  
<style scoped></style>
  