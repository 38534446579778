<template>
  <AppsDownloadInstructions v-if="!isDesktopApp && isWebDisabled && !hideAppsDownloadInstructionsOnLogin" />
	<div id="login-register" v-else>
		<h1 class="heading-bold-20">Login to your account</h1>
		<div class="width-100">
			<form @submit="handleSubmit">
				<div v-if="isNumber" class="form-group">
					<vue-tel-input
						:class="`form-control ${isPhoneError && showError ? 'error' : ''}`"
						defaultCountry="PK"
						:inputOptions="telInputOptions"
						validCharactersOnly
						v-model="username"
						@input="validate"
						@blur="showError = true"
						@focus="showError = false"
						:autoFormat="true"
						mode="national"
						invalidMsg="Please enter a valid phone number"
						id="username_number"
						tabIndex="10"
					></vue-tel-input>
					<p class="text-danger" v-if="isPhoneError && showError">
						Invalid Phone Number
					</p>
				</div>
				<div v-else class="form-group">
					<i class="input-icon-left far fa-user"></i>
					<input
						v-model="username"
						class="input-w-icon form-control"
						type="text"
						name="username"
						id="username"
						placeholder="Email / Username / Phone"
						required
						tabindex="10"
						@input="checkType"
						maxlength="80"
						autocomplete="off"
					/>
				</div>
				<div class="form-group">
					<i class="input-icon-left fas fa-unlock-alt"></i>
					<input
						v-model="password"
						class="input-w-icon form-control"
						type="password"
						name="password"
						id="password"
						placeholder="Password"
						required
						tabindex="20"
						autocomplete="on"
						maxlength="80"
					/>
				</div>
				<p v-if="isError" id="error" class="text-danger text-500 text-sm">
					{{ errorText }}
				</p>

        <div class="form-group text-center" id="form-options">
          <div id="forgot-password">
            <router-link tabindex="35" to="/account/forgot-password"
              >Forgot password?</router-link
            >
          </div>
        </div>

        <button
          class="login-register-btn form-control mt-4"
          type="submit"
          :disabled="loading || isloading"
          tabindex="40"
        >
          <b-spinner v-if="isloading || loading" small> </b-spinner>
          Login
        </button>
      </form>
    </div>
    <UserDevicesModal
      :showModal="showDevicesModal"
      :username="username"
      @updateModalState="toggleUserDevicesModal"
      @resetSuccess="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserDevicesModal from "./UserDevicesModal.vue";
import ErrorMessage from "../../constants/errorMessages";
import AppsDownloadInstructions from "@/components/Layout/AppsDownloadInstructions";
const getCountryISO3 = require("country-iso-2-to-3");

export default {
  name: "Login",
  data() {
    return {
      username: "",
      phone: "",
      password: "",
      errorText: "",
      isError: false,
      showDevicesModal: false,
      isNumber: false,
      isPhoneError: false,
      showError: false,
      isloading: false,
      telInputOptions: {
        autocomplete: "on",
        maxlength: 15,
        tabindex: 10,
        placeholder: "Phone Number (required)",
        mode: "national",
      },
    };
  },
  methods: {
    ...mapActions("user", ["login", "generateVerificationID"]),
    ...mapActions("form", ["fetchFormByEvent"]),
    async handleSubmit(e) {
      if (e) e.preventDefault();
      this.errorText = "";
      this.isError = false;
      var data;

      if (this.userCredentials && this.userCredentials.username && this.userCredentials.password) {
        data = {
          username: this.userCredentials.username
            ? this.userCredentials.username
            : null,
          password: this.userCredentials.password
            ? this.userCredentials.password
            : null,
        };
      } else {
        data = {
          username: this.isNumber ? this.phone : this.username,
          password: this.password,
        };
      }

      await this.login(data)
        .then(async (res) => {
          if (res) {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user.type.toLowerCase() == "student") {
              this.isloading = true;
              var forms = await this.fetchFormByEvent({
                entity: "user",
                event: "login",
              });
              this.isloading = false;
              if (forms && forms.length > 0) {
                localStorage.setItem("ProcessingFormID", forms[0].id);
                if (
                  this.$root.$children &&
                  this.$root.$children.length > 0 &&
                  this.$root.$children[0]
                ) {
                  this.$root.$children[0].checkForm();
                }
              }
            }
            this.isError = false;
            this.errorText = "";
            this.$router.push(
              this.$route.query.nextUrl
                ? this.$route.query.nextUrl
                : user.type.toLowerCase() == "management" ||
                  user.type.toLowerCase() == "trainer"
                ? "/management/course"
                : "/user/dashboard"
            );
            this.username = "";
            this.password = "";
            this.userCredentials.username = null;
            this.userCredentials.password = null;
          }
        })
        .catch(async (error) => {
          this.errorText = error.message;
          this.isError = true;
          if (error.errorCode != "102") {
            this.password = "";
          } else {
            this.showDevicesModal = true;
          }

          if (error.errorCode == "149") {
            this.userCredentials.username = data.username;
            this.userCredentials.password = data.password;

            var object = {
              email: error.args.email,
            };

            var res = await this.generateVerificationID(object);

            if (res.verificationID && error.args.email) {
              this.$toasted.success(`OTP has been sent to your Email`, {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
              });
              this.$router.push(
                `/account/user-verification?type=email&credentials=${error.args.email}&verificationId=${res.verificationID}`
              );
            }
          } else if (error.errorCode == "150") {
            this.userCredentials.username = data.username;
            this.userCredentials.password = data.password;

            var object = {
              phone: error.args.phone,
            };

            var res = await this.generateVerificationID(object);

            if (res.verificationID && error.args.phone) {
              this.$toasted.success(`OTP has been sent to your Phone number`, {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
              });
              this.$router.push(
                `/account/user-verification?type=phone&credentials=${error.args.phone}&verificationId=${res.verificationID}`
              );
            }
          }
        });
    },
    toggleUserDevicesModal(state) {
      if (state !== undefined) this.showDevicesModal = state;
      else this.showDevicesModal = !this.showDevicesModal;
    },
    checkType(e) {
      if (e.data) {
        // alert(e);
        var regExp = /[a-zA-Z]/g;
        var isAlphaInput = regExp.test(e.data);
        var containsLetters = regExp.test(this.username);
        if (
          e.key != "@" &&
          !containsLetters &&
          !isAlphaInput
          // ((e.keyCode > 95 && e.keyCode < 106) ||
          // 	(e.keyCode > 47 && e.keyCode < 58))
        ) {
          if (this.username && this.username.length > 6) {
            this.isNumber = true;

            setTimeout(() => $("#username_number input").focus(), 10);

            // console.log(e, "is a number");
            return;
          }
        }
        this.isNumber = false;
        setTimeout(() => $("#username").focus(), 10);
      }
    },
    validate(num, obj) {
      // console.log(num, obj);
      var numbers = "1234567890-+";
      if (!numbers.includes(num.at(-1))) {
        this.isNumber = false;
        setTimeout(() => $("#username").focus(), 10);
        return;
      }
      // console.log(num, obj);
      if (obj.valid != null && obj.valid === false) {
        this.isPhoneError = true;
        return;
      }
      this.isPhoneError = false;
      if (obj.country && obj.country.iso2) {
        this.countryCode = getCountryISO3(obj.country.iso2);
        this.phone = obj.number;
      }
      return false;
    },
  },
  computed: {
    ...mapState("user", ["loading"]),
    ...mapState("cart", ["cart"]),
    ...mapState("appState", ["systemParameters"]),
    ...mapState("user", ["userCredentials"]),

    isDesktopApp() {
      return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
    },
		isWebDisabled() {
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled == '1';
		},
    hideAppsDownloadInstructionsOnLogin() {
      return this.systemParameters &&
             this.systemParameters.HideAppsDownloadInstructionsOnLogin &&
             this.systemParameters.HideAppsDownloadInstructionsOnLogin == '1';
    }
	},
	components: {
    AppsDownloadInstructions,
		UserDevicesModal
	},
	watch: {
		username: function () {
			if (this.username && this.username.length < 7) {
				this.isNumber = false;
				setTimeout(() => $("#username").focus(), 10);
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "login-page-loaded", data: null });
		});
	},
  async created() {
    this.verification = this.$route.query.verificationSuccessful;

    if (this.verification) {
      await this.handleSubmit();
    }
  },
};
</script>

<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
#form-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-webDisabled {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 7% 0;
}
#forgot-password a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}
</style>
