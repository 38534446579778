<template>
	<b-modal
		@change="closeModal"
		id="create-exam-modal"
		:visible="visible"
		size="xl"
		hide-header
		hide-footer
		centered
		content-class="create-exam-modal"
		body-class="modal-body"
		modal-class="custom-modal"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="custom-stepper-container">
			<div class="custom-stepper">
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon first',
							{ active: currentStep == 1, complete: currentStep > 1 },
						]"
					>
						1
					</div>
					<div class="step-text">
						<h1 class="title">Basic information</h1>
						<h3 class="subtitle">Fill in basic information</h3>
					</div>
				</div>
				<!-- <div class="custom-stepper-step">
					<div
						:class="[
							'step-icon',
							{ active: currentStep == 2, complete: currentStep > 2 },
						]"
					>
						2
					</div>
					<div class="step-text">
						<h1 class="title">Additional Information</h1>
						<h3 class="subtitle">Fill in additional information</h3>
					</div>
				</div> -->
				<div class="custom-stepper-step">
					<div
						:class="[
							'step-icon',
							{ active: currentStep == 2, complete: currentStep > 2 },
						]"
					>
						2
					</div>
					<div class="step-text">
						<h1 class="title">Add Questions</h1>
						<h3 class="subtitle">Add questions to your exam</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="custom-stepper-body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-content-title">
				{{ isEditMode ? "Edit" : "Create New" }} Exam
			</h1>
			<div id="create-form">
				<div class="step-1" v-if="currentStep == 1">
					<div class="inline-group mb-3">
						<div class="form-group col">
							<label class="main-label" for="title"
								>Exam Title (required)</label
							>
							<input
								placeholder="Type your exam name here..."
								class="form-input"
								type="search"
								name="title"
								id="title"
								v-model="title"
								required
								@change="isEditMode && update($event)"
							/>
						</div>
						<div
							v-if="this.examThemes && this.examThemes.length > 1"
							class="form-group col"
						>
							<label class="main-label" for="examTheme"
								>Exam Theme (required)</label
							>
							<v-select
								v-model="examTheme"
								name="examTheme"
								id="examTheme"
								class="form-input"
								:options="examThemes"
								:appendToBody="true"
								:calculate-position="withPopper"
								placeholder="Select Exam theme"
								label="themeName"
								:reduce="(option) => option.id"
							></v-select>
						</div>
					</div>

					<div class="inline-group mb-4">
						<div class="form-group col">
							<div class="d-flex align-items-start mb-3">
								<label class="main-label mb-0" for="examDates"
									>Exam Availability
								</label>
								<!-- <button class="add-date-btn ms-auto" @click="addDate">
									+ Add Date
								</button> -->
							</div>
							<div class="exam-date-labels">
								<!-- <label class="mb-1 p-0" for="examDates">#</label> -->
								<label class="col mb-1" for="examDates">Start Date</label>
								<label class="col mb-1" for="examDates">End Date</label>
								<!-- <label class="col mb-1" for="examDates"
									>Allow Submissions Till</label -->
								<!-- > -->
								<label
									v-if="examDates.length > 1"
									class="mb-1 p-0 ms-2"
									for="examDates"
									>&nbsp;</label
								>
							</div>

							<div class="exam-dates-grid">
								<div
									class="exam-date-item"
									v-for="(input, index) in examDates"
									:key="index"
								>
									<!-- <span> {{ index + 1 }}</span> -->

									<input
										placeholder="Select Exam Date..."
										class="form-input"
										type="datetime-local"
										name="examDates"
										:id="`examLateEndDates-${index}`"
										v-model="examDates[index].examStartDateTime"
										required
										@change="isEditMode && update($event)"
									/>
									<input
										placeholder="Select Exam Date..."
										class="form-input"
										type="datetime-local"
										name="examDates"
										:id="`examLateEndDates-${index}`"
										v-model="examDates[index].examEndDateTime"
										required
										@change="isEditMode && update($event)"
									/>
									<!-- <input
										placeholder="Select Exam Date..."
										class="form-input"
										type="datetime-local"
										name="examDates"
										:id="`examLateEndDates-${index}`"
										v-model="examDates[index].examEndLateDateTime"
										required
										@change="isEditMode && update($event)"
									/> -->
									<button
										class="cancel-button"
										:id="index"
										@click="removeDate(index)"
										v-if="examDates.length > 1"
									>
										<i class="fas fa-times"></i>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="inline-group mb-3">
						<div class="form-group col">
							<label class="main-label" for="numberOfAllowedAttempts"
								>Allowed Attempts (required)</label
							>
							<input
								placeholder="Enter number of allowed attempts"
								class="form-input"
								type="number"
								name="numberOfAllowedAttempts"
								id="numberOfAllowedAttempts"
								v-model="numberOfAllowedAttempts"
								required
								@change="isEditMode && update($event)"
							/>
						</div>
						<div class="form-group col">
							<label class="main-label" for="passingPercentage"
								>Passing Percentage (required)</label
							>
							<div class="position-relative">
								<input
									placeholder="Enter passing percentage out of 100"
									class="form-input"
									type="number"
									name="passingPercentage"
									id="passingPercentage"
									v-model="passingPercentage"
									required
									@change="isEditMode && update($event)"
								/>
								<span class="input-unit">%</span>
							</div>
						</div>
					</div>
					<div class="inline-group mb-1">
						<div class="form-group col">
							<!-- <div class="form-check form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="isCalculatorAllowed"
									v-model="isCalculatorAllowed"
								/>
								<label class="form-check-label" for="isCalculatorAllowed"
									>Allow calculator</label
								>
							</div> -->

							<div class="form-check form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="isAvailableForDemo"
									v-model="isAvailableForDemo"
								/>
								<label class="form-check-label" for="isAvailableForDemo"
									>Available for demo</label
								>
							</div>
						</div>
						<div class="inline-group col-6 mb-0 align-items-center">
							<div class="form-check form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="isTimedExam"
									v-model="isTimedExam"
								/>
								<label class="form-check-label" for="isTimedExam"
									>Timed Exam</label
								>
							</div>
							<div v-if="isTimedExam" class="position-relative col ms-4">
								<input
									type="number"
									name="examDuration"
									id="examDuration"
									v-model="examDuration"
									required
									@change="isEditMode && update($event)"
									class="form-input col small"
									placeholder="Enter exam duration"
								/>
								<span class="input-unit small">minutes</span>
							</div>
						</div>
					</div>

					<button
						@click="toggleAddtionalOptions"
						class="d-flex align-items-center gap-2 mb-3"
					>
						{{ additionalOptions ? "Hide" : "Show" }} additional options
						<i :class="`fa fa-caret-${additionalOptions ? 'up' : 'down'}`"></i>
					</button>

					<div class="inline-group mb-0" v-if="additionalOptions">
						<div class="form-group col">
							<label class="main-label"> Exam Questions Settings </label>
							<div class="form-group">
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										role="switch"
										id="showAllQuestionsAtOnce"
										v-model="showAllQuestionsAtOnce"
									/>
									<label class="form-check-label" for="showAllQuestionsAtOnce"
										>Show all questions at once</label
									>
								</div>
							</div>
							<div class="form-group">
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										role="switch"
										id="allowReviewPreviousQuestions"
										v-model="allowReviewPreviousQuestions"
									/>
									<label
										class="form-check-label"
										for="allowReviewPreviousQuestions"
										>Allow student to review previous questions</label
									>
								</div>
							</div>
							<div class="form-group">
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										role="switch"
										id="allowReattemptPreviousQuestions"
										v-model="allowReattemptPreviousQuestions"
									/>
									<label
										class="form-check-label"
										for="allowReattemptPreviousQuestions"
										>Allow student to reattempt previously attempted
										questions</label
									>
								</div>
							</div>
						</div>
						<div class="form-group col">
							<label class="main-label"> Exam Results Settings </label>
							<div class="form-group">
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										role="switch"
										id="isReviewAllowed"
										v-model="isReviewAllowed"
									/>
									<label class="form-check-label" for="isReviewAllowed"
										>Allow student to review exam results</label
									>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="step-3" v-if="currentStep == 2">
					<div class="d-flex align-items-start">
						<label class="main-label mb-4">Exam Questions (required)</label>

						<button
							class="add-date-btn ms-auto dropdown-toggle"
							data-bs-toggle="dropdown"
							id="options-dropdown-btn"
							role="button"
						>
							+ Add Question
						</button>
						<ul
							id="options-dropdown"
							class="dropdown-menu dropdown-menu-end"
							aria-labelledby="dropdownMenuLink"
						>
							<li
								@click="toggleAddQuestionModal(true, ExamQuestionType.QUESTION)"
								class="options-dropdown-item"
							>
								<img src="@/assets/images/plus-icon.svg" /> Specific Question
							</li>
							<li
								@click="toggleAddQuestionModal(true, ExamQuestionType.CRITERIA)"
								class="options-dropdown-item"
							>
								<img src="@/assets/images/plus-icon.svg" /> Question based on
								Criteria
							</li>
						</ul>
					</div>
					<div class="exam-questions-container">
						<draggable
							class="exam-questions-list"
							v-if="examQuestions && examQuestions.length > 0"
							v-model="examQuestions"
							ghost-class="ghost"
							group="content-tab"
							animation="200"
							@move="moveQuestion"
						>
							<div v-for="(question, index) in examQuestions" :key="index">
								<ExamQuestionListItem
									v-if="question.ExamQuestionType == ExamQuestionType.CRITERIA"
									:questionData="question"
									@updateQuestion="editQuestions"
									@removeQuestion="deleteQuestion"
									:productVariantId="productVariantId"
									:examQuestionType="examQuestionType"
									:allowedQuestionTypes="allowedQuestionTypes"
								/>

								<div
									v-else-if="
										question.ExamQuestionType == ExamQuestionType.QUESTION
									"
								>
									<!-- <span>{{ "Question " + question.QuestionSequenceNo }}</span> -->
									<QuestionListItem
										:questionData="
											questionDetails(question.Question.QuestionID)
										"
										:selectMode="true"
										:showDelete="true"
										@removeQuestion="
											deleteQuestion(question.QuestionSequenceNo)
										"
									/>
								</div>
							</div>
						</draggable>
						<div v-else class="no-questions">
							<span>No questions added to this exam yet!</span>
						</div>

						<AddExamQuestionModal
							:showModal="showAddQuestionModal"
							@updateModalState="toggleAddQuestionModal"
							@addExamQuestions="addQuestions"
							:examQuestionSequence="nextSequenceNo"
							:productVariantId="productVariantId"
							:allowedQuestionTypes="allowedQuestionTypes"
							:examQuestionType="examQuestionType"
						/>
					</div>
				</div>
			</div>
			<div class="step-btn-container">
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
					><i class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<button
					v-if="currentStep < 2"
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					next <i class="fas fa-long-arrow-alt-right"></i>
				</button>
				<button
					v-if="currentStep == 2"
					@click="finish(ExamStatus.DRAFT)"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner
						v-if="loading && this.examStatus == ExamStatus.DRAFT"
						small
					></b-spinner>
					Save as Draft <i class="fas fa-long-arrow-alt-right"></i>
				</button>
				<button
					v-if="currentStep == 2"
					@click="finish(ExamStatus.PUBLISHED)"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner
						v-if="loading && this.examStatus == ExamStatus.PUBLISHED"
						small
					></b-spinner>
					Publish <i class="fas fa-long-arrow-alt-right"></i>
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { createPopper } from "@popperjs/core";
import {
	ExamStatus,
	ExamType,
	ExamQuestionType,
} from "../../../constants/exam";
import { ContentType } from "../../../constants/content";
import { mapActions, mapState } from "vuex";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import AddExamQuestionModal from "./AddExamQuestionModal.vue";
import ExamQuestionListItem from "./ExamQuestionListItem.vue";
import QuestionListItem from "../Question/QuestionListItem.vue";
import draggable from "vuedraggable";

export default {
	name: "CreateEditExamModal",
	data() {
		return {
			currentStep: 1,
			totalSteps: 2,
			id: null,
			title: null,
			examTheme: null,
			examStatus: ExamStatus.DRAFT,
			examDates: [
				{
					examStartDateTime: null,
					examEndDateTime: null,
					examEndLateDateTime: null,
				},
			],
			isTimedExam: true,
			examDuration: 60,
			numberOfAllowedAttempts: 1,
			passingPercentage: 60,
			isCalculatorAllowed: true,
			isReviewAllowed: false,
			showAllQuestionsAtOnce: false,
			allowReviewPreviousQuestions: false,
			allowReattemptPreviousQuestions: false,
			totalExamQuestions: null,
			examQuestions: [],
			showAddQuestionModal: false,
			isAvailableForDemo: false,
			additionalOptions: false,
			examQuestionType: null,
			ExamStatus,
			ExamType,
			ExamQuestionType,
			ContentType,
		};
	},
	methods: {
		...mapActions("exam", ["createExam", "fetchExamThemes", "updateExam"]),
		...mapActions("content", ["getContentById"]),
		toggleAddtionalOptions() {
			this.additionalOptions = !this.additionalOptions;
		},
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep--;
			}
		},
		getSmallestDate() {
			var smallest = this.examDates[0].examStartAndEndDate.startDate;
			this.examDates.forEach((date) => {
				if (date.examStartAndEndDate.startDate < smallest) {
					smallest = date.examStartAndEndDate.startDate;
				}
			});
			return new Date(smallest).toISOString();
		},
		getLargestDate() {
			var largest = this.examDates[0].examEndLateDateTime
				? this.examDates[0].examEndLateDateTime
				: this.examDates[0].examStartAndEndDate.endDate;
			this.examDates.forEach((date) => {
				if (date.examEndLateDateTime && date.examEndLateDateTime > largest) {
					largest = date.examEndLateDateTime;
				}
				if (date.examStartAndEndDate.startDate < largest) {
					largest = date.examStartAndEndDate.startDate;
				}
			});
			return new Date(largest).toISOString();
		},
		next() {
			if (this.currentStep < this.totalSteps) {
				this.currentStep++;
			}
		},
		finish(examStatus) {
			this.examStatus = examStatus;
			var examCreateObject = {
				title: this.title,
				data: {
					servicecode: "ES",
					examtypename: ExamType.EXAM,
					examstatus: this.examStatus,
					isreviewallowed: this.isReviewAllowed.toString(),
					passingpercentage: this.passingPercentage.toString(),
					iscalculatorallowed: this.isCalculatorAllowed.toString(),
					showallquestionsatonce: this.showAllQuestionsAtOnce.toString(),
					canreviewpreviousquestion:
						this.allowReviewPreviousQuestions.toString(),
					canreattemptpreviousattemptedquestion:
						this.allowReattemptPreviousQuestions.toString(),
					examdates: JSON.stringify(
						this.examDates.map((date) => {
							return {
								examStartDateTime: date.examStartDateTime
									? new Date(date.examStartDateTime).toISOString()
									: null,
								examEndDateTime: date.examEndDateTime
									? new Date(date.examEndDateTime).toISOString()
									: null,
								examEndLateDateTime: date.examEndLateDateTime
									? new Date(date.examEndLateDateTime).toISOString()
									: null,
							};
						})
					),
					examquestions: JSON.stringify(this.generateExamQuestionsList()),
					istimedexam: this.isTimedExam.toString(),
					numberofminutes: this.examDuration.toString(),
					numberofallowedattempts: this.numberOfAllowedAttempts.toString(),
					themeid: this.examTheme,
				},
				availableFrom: this.examStartDateTime,
				availableTo: this.examEndDateTime,
				isAvailableForDemo: this.isAvailableForDemo,
				parentDirectoryID: this.parentContentId,
				type: ContentType.EXAM,
			};
			console.log(examCreateObject);
			this.createExam(examCreateObject).then((result) => {
				// console.log(result);
				if (result) {
					this.closeModal();
					this.$toasted.success("Exam created successfully", {
						theme: "outline",
						position: "top-center",
						duration: 3000,
					});
				}
			});
		},
		update(e) {},
		generateExamQuestionsList() {
			var questionList = [];
			this.examQuestions.map((question) => {
				if (question.ExamQuestionType == ExamQuestionType.CRITERIA) {
					for (var i = 0; i < question.NoOfQuestions; i++) {
						questionList.push({
							ExamQuestionType: question.ExamQuestionType,
							QuestionSequenceNo: question.QuestionSequenceNo + i,
							Criteria: question.Criteria,
						});
					}
				} else if (question.ExamQuestionType == ExamQuestionType.QUESTION) {
					questionList.push(question);
				}
			});
			return questionList;
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		dateFormatter(date) {
			return getInputDateFormat(date, true);
		},
		toggleAddQuestionModal(value, type) {
			this.examQuestionType = type;
			if (value !== undefined) {
				this.showAddQuestionModal = value;
			} else {
				this.showAddQuestionModal = !this.showAddQuestionModal;
			}
		},
		addDate() {
			this.examDates.push({
				examStartAndEndDate: {
					startDate: null,
					endDate: null,
				},
				examEndLateDateTime: null,
			});
			setTimeout(
				() =>
					$(".exam-date-item:last-of-type")[0].scrollIntoView({
						behavior: "smooth",
						block: "end",
					}),
				300
			);
		},
		removeDate(pos) {
			this.examDates = this.examDates.filter((date, index) => pos != index);
		},
		formatDate(date) {
			if (date)
				return date.toLocaleString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
		},
		addQuestions(questionList) {
			this.examQuestions = [...this.examQuestions, ...questionList];
			this.examQuestions.sort(
				(a, b) => a.QuestionSequenceNo < b.QuestionSequenceNo
			);
		},
		editQuestions({ questions, sequence }) {
			console.log(questions, sequence);
			this.examQuestions = this.examQuestions.map((q, index) => {
				if (index == sequence - 1) {
					return questions[0];
				} else return q;
			});
		},
		deleteQuestion(sequence) {
			this.examQuestions = this.examQuestions.filter(
				(question) => question.QuestionSequenceNo !== sequence
			);
			this.examQuestions = this.examQuestions.map((q, index) => {
				q.QuestionSequenceNo = index + 1;
				return q;
			});
		},
		questionDetails(id) {
			return this.allQuestions.find((question) => question.ID == id);
		},
		moveQuestion(to, from) {
			console.log(to, from);
		},
	},
	computed: {
		...mapState("exam", [
			"examCategories",
			"loading",
			"examThemes",
			"allQuestions",
		]),
		visible() {
			return this.showModal;
		},
		disableNext() {
			if (this.currentStep == 1) {
				if (
					this.title &&
					this.title.isNotEmpty() &&
					this.examTheme &&
					this.passingPercentage &&
					this.numberOfAllowedAttempts &&
					parseInt(this.numberOfAllowedAttempts) > 0 &&
					this.examDates &&
					this.examDates[0].examStartDateTime &&
					this.examDates[0].examEndDateTime
				) {
					if (this.isTimedExam) {
						if (this.examDuration != null && this.examDuration > 0) {
							return false;
						} else return true;
					}
					return false;
				} else return true;
			} else if (this.currentStep == 2) {
				if (this.examQuestions && this.examQuestions.length > 0) {
					return false;
				} else return true;
			}
			return false;
		},
		stepButtonContent() {
			return this.currentStep < this.totalSteps ? "next" : "finish";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		allowedQuestionTypes() {
			return this.examThemes.find((theme) => theme.id == this.examTheme)
				?.questionTypes;
		},
		nextSequenceNo() {
			if (this.examQuestions && this.examQuestions.length > 0) {
				var lastElem = this.examQuestions.at(-1);
				if (lastElem.ExamQuestionType == ExamQuestionType.CRITERIA) {
					return (
						parseInt(lastElem.QuestionSequenceNo) +
						parseInt(lastElem.NoOfQuestions)
					);
				} else {
					return parseInt(lastElem.QuestionSequenceNo) + 1;
				}
			} else return 1;
		},
	},
	components: {
		AddExamQuestionModal,
		ExamQuestionListItem,
		QuestionListItem,
		draggable,
	},
	props: {
		showModal: Boolean,
		parentContentId: String,
		productVariantId: String,
		isEditMode: { type: Boolean, default: false },
		examData: { type: Object, default: null },
	},
	watch: {
		visible: function () {
			if (this.visible) {
				if (this.examThemes && this.examThemes.length == 0) {
					this.fetchExamThemes().then((res) => {
						if (res && res.length == 1) {
							this.examTheme = res[0].id;
						}
					});
				} else {
					if (this.examThemes && this.examThemes.length == 1) {
						this.examTheme = this.examThemes[0].id;
					}
				}
				if (this.isEditMode && this.examData.data) {
					this.id = this.examData.id;
					this.title = this.examData.data.title;
					this.examTheme = null;
					this.examStatus = this.examData.data.examstatus;
					this.examDates = JSON.parse(this.examData.data.examDates);
					this.isTimedExam = this.examData.data.IsTimedExam;
					this.examDuration = this.examData.data.NumberOfMinutes;
					this.numberOfAllowedAttempts =
						this.examData.data.numberOfAllowedAttempts;
					this.passingPercentage = this.examData.data.PassingPercentage;
					this.isCalculatorAllowed = this.examData.data.IsCalculatorAllowed;
					this.isReviewAllowed = this.examData.data.IsReviewAllowed;
					this.showAllQuestionsAtOnce =
						this.examData.data.ShowAllQuestionsAtOnce;
					this.allowReviewPreviousQuestions =
						this.examData.data.CanReviewPreviousQuestion;
					this.allowReattemptPreviousQuestions =
						this.examData.data.CanReattemptPreviousAttemptedQuestion;
					this.examQuestions = JSON.parse(this.examData.data.examquestions);
					this.isAvailableForDemo = this.examData.isAvailableForDemo;
				}
			}
		},
		// examQuestions: function () {
		// 	var sequence = 1;
		// 	this.examQuestions = this.examQuestions.map((question) => {
		// 		question.QuestionSequenceNo = sequence;
		// 		sequence++;
		// 		return question;
		// 	});
		// },
	},
};
</script>

<style>
.create-exam-modal {
	min-height: 90vh;
	height: fit-content;
	width: 80vw;
}
#create-exam-modal.custom-modal .modal-dialog {
	max-width: 80vw;
}

.create-exam-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.create-exam-modal .modal-body .custom-stepper-container {
	background: #f5f5f5;
	border-radius: 8px 0px 0px 8px;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.create-exam-modal .modal-body .custom-stepper-container .custom-stepper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 7.5rem;
}

.create-exam-modal .modal-body .custom-stepper-body-container {
	display: flex;
	flex-direction: column;
	width: 70%;
	padding: 2.5rem 2.5rem 1.5rem;
	position: relative;
	border-radius: 0 8px 8px 0;
}

.create-exam-modal .step-1,
.step-2,
.step-3 {
	margin-top: 1.625rem;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.exam-type .status-card {
	max-width: unset;
	width: 100%;
}

.create-exam-modal .input-unit {
	position: absolute;
	right: 0;
	bottom: 1rem;
	color: #aaa;
}

.create-exam-modal .input-unit.small {
	bottom: 0.375rem !important;
}

.create-exam-modal .no-questions {
	padding: 1rem;
	display: flex;
	justify-content: center;
	color: var(--color-gray);
	font-size: 1.25rem;
	height: 100%;
	align-items: center;
}

.add-date-btn {
	color: var(--primary-color);
}

.exam-dates-grid {
	display: flex;
	flex-direction: row;
	row-gap: 1rem;
	overflow-y: auto;
	flex-wrap: wrap;
	max-height: 9rem;
}

.exam-dates-grid .exam-date-item .form-input,
.exam-date-labels .main-label {
	display: flex;
	flex-basis: 48%;
	flex-grow: 1;
	position: relative;
}

.exam-dates-grid .exam-date-item,
.exam-date-labels {
	display: flex;
	column-gap: 1rem;
	flex-grow: 1;
	align-items: center;
	width: 100%;
}

.exam-dates-grid .exam-date-item span {
	min-width: 0.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-input.small {
	padding: 0 0 0.375rem !important;
	min-height: unset !important;
}

.exam-questions-list {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	margin-bottom: 1rem;
	height: 60vh;
	overflow-y: auto;
	margin-right: -0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.5rem;
}

.exam-questions-container {
	height: 40vh;
}

.add-date-btn::after {
	display: none !important;
}

.options-dropdown-item {
	padding: 0.75rem 1rem;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #000000;
	column-gap: 0.75rem;
}

.options-dropdown-item:hover {
	background-color: #ebebeb;
}

#create-form #numberOfAllowedAttempts::-webkit-outer-spin-button,
#create-form #numberOfAllowedAttempts::-webkit-inner-spin-button {
	-webkit-appearance: button !important;
	/* margin: 0; */
}

/* 
.exam-dates-grid .exam-date .cancel-button {
	right: 2rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
} */
</style>
