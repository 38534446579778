<template>
  <div class="product-variant-list-item" :id="productVariantData.id">
    <div
      class="content"
      @click="
        $router.push(`/management/course/${productVariantData.id}/content`)
      "
    >
      <img src="@/assets/images/drag-icon.svg" class="drag-icon handle" />
      {{ productVariantData.title }}
    </div>
    <div class="right-menu">
      <a role="button" class="right-menu-icon">
        <img @click="openEditModal" src="@/assets/images/edit-icon.svg" />
      </a>
      <CreateProductVariantModal
        :showModal="showEditModal"
        @updateModalState="updateModalState"
        :productId="productVariantData.product.id"
        :isEditMode="true"
        :productVariantData="productVariantData"
        @updateProductVariant="updateVariant"
      />
      <!-- <a role="button" class="right-menu-icon">
				<img src="@/assets/images/view-icon.svg" />
			</a> -->
      <a @click="showDeleteConfirmPopup" role="button" class="right-menu-icon">
        <img src="@/assets/images/delete-icon.svg" />
      </a>
    </div>
  </div>
</template>

<script>
import CreateProductVariantModal from "./CreateProductVariantModal.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ProductVariantListItem",
  data() {
    return {
      showEditModal: false,
      isEditMode: true,
    };
  },
  props: { productVariantData: Object, categoryId: String },
  methods: {
    ...mapActions("productVariant", [
      "deleteProductVariant",
      "saveCopiedProductVariant",
    ]),
    openEditModal() {
      this.showEditModal = true;
    },
    updateModalState(value) {
      this.isEditMode = false;
      this.showEditModal = value;
    },
    updateVariant(response) {
      this.$emit("updateProductVariant", response);
    },
    productVariantDelete() {
      this.deleteProductVariant({
        productVariant: this.productVariantData,
        categoryId: this.categoryId,
      }).then(() => {
        console.log("ProductVariant deleted successfully");
      });
    },
    showDeleteConfirmPopup(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete the Batch: ${this.productVariantData.title}?`,
          {
            title: "Are you sure?",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
            bodyClass: "confirm-modal-body",
          }
        )
        .then((value) => {
          // this.isConfirm = value;
          if (value) {
            this.productVariantDelete();
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },

    copyProductVariant() {
      console.log(this.productVariantData);
      this.saveCopiedProductVariant(this.productVariantData);
      this.$toasted.success("Course copied", {
        duration: 3000,
        theme: "outline",
        icon: "check",
        position: "bottom-center",
      });
    },

    showContextMenu() {
      var _this = this;
      $.contextMenu({
        selector: `#${this.productVariantData.id}`,
        callback: (key, options) => {
          switch (key) {
            case "copy":
              _this.copyProductVariant();
          }
        },
        items: {
          copy: { name: "Copy", icon: "copy" },
        },
      });
    },
  },

  mounted() {
    this.showContextMenu();
  },
  components: { CreateProductVariantModal },
};
</script>

<style scoped>
.product-variant-list-item {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 0.875rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
}
.product-variant-list-item .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  flex-grow: 1;
  cursor: pointer;
}

.product-variant-list-item .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-left: auto;
  margin-right: 0.75rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
}

.product-variant-list-item:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.drag-icon {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.drag-icon:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
