<template>
	<div class="questions-column">
		<div class="top-bar">
			<div
				class="question-heading"
				v-if="selectedCategory && selectedCategory.length > 0"
			>
				<div
					class="heading-item"
					v-for="(category, index) in selectedCategory"
					:key="index"
				>
					<span class="text">{{ category.categoryName }}</span>
					<img
						v-if="index != selectedCategory.length - 1"
						src="@/assets/images/caret-down.svg"
						class="caret-icon"
					/>
				</div>
			</div>
			<div class="question-heading" v-else>
				<div class="heading-item">Uncategorized Questions</div>
			</div>

			<div class="menu-container flex-shrink-0">
				<div class="position-relative">
					<input
						type="search"
						class="searchbar"
						placeholder="Search question"
						v-model="questionSearchText"
					/>
					<a role="button"
						><img class="search-icon" src="@/assets/images/search-icon.svg"
					/></a>
				</div>
				<a
					v-if="!selectMode"
					class="add-btn"
					role="button"
					@click="toggleQuestionModal(true)"
				>
					<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
					add question
				</a>
				<AddQuestionModal
					v-if="!selectMode"
					:showModal="showQuestionModal"
					@updateModalState="toggleQuestionModal"
					:productVariantId="productVariantId"
					:selectedCategory="selectedCategory"
				/>
			</div>
		</div>
		<QuestionList
			:questionList="filteredQuestions"
			:productVariantId="productVariantId"
			:selectedCategory="selectedCategory"
			:searchText="questionSearchText"
			@fetchMore="updateCurrentPage"
			:pageSize="pageSize"
			:currentPage="currentPage"
			:moreLoading="moreLoading"
			:selectMode="selectMode"
			@selectQuestions="selectQuestions"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuestionList from "../Question/QuestionList.vue";
import AddQuestionModal from "../Question/AddQuestionModal.vue";
export default {
	name: "QuestionsColumn",
	data() {
		return {
			questionSearchText: "",
			showQuestionModal: false,
			pageSize: 5,
			currentPage: 1,
			moreLoading: false,
		};
	},
	components: {
		QuestionList,
		AddQuestionModal,
	},
	props: {
		selectedCategory: Array,
		productVariantId: String,
		selectMode: { type: Boolean, default: false },
	},
	computed: {
		...mapState("exam", ["questionCategories", "allQuestions"]),
		filteredQuestions() {
			if (
				this.selectedCategory &&
				this.selectedCategory.length > 0 &&
				this.allQuestions &&
				this.allQuestions.length > 0
			) {
				return this.allQuestions.filter(
					(question) =>
						question.Categories.findIndex(
							(cat) => cat.ID == this.selectedCategory.at(-1).id
						) != -1 &&
						((question.id || "")
							.toLocaleLowerCase()
							.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.questionTitle || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.questionText || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.requirementsText || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1)
				);
			} else {
				return this.allQuestions.filter(
					(question) =>
						question.Categories.findIndex(
							(cat) =>
								cat.ID == this.questionCategories[this.productVariantId].root.id
						) != -1 &&
						((question.id || "")
							.toLocaleLowerCase()
							.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.questionTitle || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.questionText || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1 ||
							(question.requirementsText || "")
								.toLocaleLowerCase()
								.indexOf(this.questionSearchText.toLocaleLowerCase()) > -1)
				);
			}
		},
	},
	methods: {
		...mapActions("exam", ["fetchQuestions"]),
		// categoryName(category) {
		// 	if (category && category.length > 1) {
		// 		var childCategory = category.at(-1);
		// 		var parentCategory = childCategory.parentID;

		// 	}
		// 	return this.questionCategories[id].categoryName;
		// },
		toggleQuestionModal(value) {
			if (value !== undefined) {
				this.showQuestionModal = value;
			} else {
				this.showQuestionModal = !this.showQuestionModal;
			}
		},
		updateCurrentPage(value) {
			this.currentPage = value;
		},
		selectQuestions(selectedQuestions) {
			this.$emit("selectQuestions", selectedQuestions);
		},
	},
	created() {
		this.fetchQuestions({
			productVariantID: this.productVariantId,
			categoryID: this.selectedCategory.at(-1)
				? this.selectedCategory.at(-1).id
				: null,
			pageSize: this.pageSize,
			currentPage: this.currentPage,
		}).then((res) => console.log(res));
	},
	watch: {
		selectedCategory: function (newValue, oldValue) {
			this.pageSize = 5;
			this.currentPage = 1;
			if (this.selectedCategory.length > 0) {
				var found = this.allQuestions.find((q) => {
					if (q.Categories[0].ID == this.selectedCategory.at(-1).id) {
						return q;
					}
				});

				if (!found) {
					this.fetchQuestions({
						productVariantID: this.productVariantId,
						categoryID: this.selectedCategory.at(-1)
							? this.selectedCategory.at(-1).id
							: null,
						pageSize: this.pageSize,
						currentPage: this.currentPage,
					}).then((res) => console.log(res));
				}
			}
		},
		currentPage: function () {
			this.moreLoading = true;
			this.fetchQuestions({
				productVariantID: this.productVariantId,
				categoryID: this.selectedCategory.at(-1)
					? this.selectedCategory.at(-1).id
					: null,
				pageSize: this.pageSize,
				currentPage: this.currentPage,
			}).then((res) => console.log(res), (this.moreLoading = false));
		},
	},
};
</script>

<style scoped>
.questions-column {
	display: flex;
	flex-direction: column;
	width: 75%;
}

.top-bar {
	padding: 1rem;
	background-color: #f9f9f9;
	font-size: 1rem;
	/* border-bottom: 1px solid #ddd; */
	display: flex;
	border-radius: 0 0.5rem 0 0;
	justify-content: space-between;
	align-items: center;
	height: 4.625rem;
}

.question-heading {
	font-size: 1.125rem;
	line-height: 1;
	vertical-align: middle;
	/* font-weight: 500; */
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
}

.top-bar .searchbar {
	border: 1px solid #ddd;
	padding: 0.5rem 0.75rem;
	width: 100%;
	border-radius: 0.5rem;
	font-size: 1rem;
}

.search-icon {
	position: absolute;
	margin-right: 1rem;
	right: 0;
	width: 1.375rem;
	top: 50%;
	transform: translateY(-50%);
}

.menu-container {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.question-heading .caret-icon {
	transform: rotate(-90deg);
}

.question-heading .heading-item {
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
}

.question-heading .heading-item:last-of-type .text {
	color: var(--primary-color);
}
.question-heading .heading-item:first-of-type .text {
	color: #000000;
}
</style>
