<template>
	<div class="container" v-if="feedbacks!=null && feedbacks.length>0">
		<div class="col-lg-8 col-sm-12 feedback-div">
			<div class="d-flex justify-content-between">
				<h2>Students Feedback</h2>
				<div class="d-flex" v-if="!isViewAll">
					<a class="feedback-arrow" role="button"  @click="$event=>previous()"
						><i class="fas fa-angle-left"></i
						></a>
						<a class="feedback-arrow right" role="button"   @click="$event=>next()" 
							><i class="fas fa-angle-right"></i
						></a>
				</div>
			</div>
			<div  v-if="!isViewAll"> 
				<div class="feedback-box" v-if="mainFeedbackIndex > -1 && mainFeedbackIndex < feedbacks.length">
					<star-rating  v-bind:rating="feedbacks[mainFeedbackIndex].ratingPoint" v-bind:star-size="20" active-color="#FFBF00" v-bind:increment="0.1" v-bind:read-only="true" v-bind:show-rating="false" ></star-rating>
					<p class="feedback-text"> {{ feedbacks[mainFeedbackIndex].review }}</p>
					<p class="feedback-text-user">- {{ feedbacks[mainFeedbackIndex].user.firstName }} {{ feedbacks[mainFeedbackIndex].user.lastName }}</p>					
				</div>
			</div>
			<div  v-else > 
				<div class="feedback-box" v-for="feedback in feedbacks">
					<star-rating  v-bind:rating="feedback.ratingPoint" v-bind:star-size="20" active-color="#FFBF00" v-bind:increment="0.1" v-bind:read-only="true" v-bind:show-rating="false" ></star-rating>
					<p class="feedback-text"> {{ feedback.review }}</p>
					<p class="feedback-text-user">- {{ feedback.user.firstName }} {{ feedback.user.lastName }}</p>					
				</div>
			</div>
			<div class="d-flex liner-div" v-if="feedbacks.length>1">
				<div>
					<a  v-if="!isViewAll"  class="more">{{feedbacks.length-1}} More Feedbacks</a>
				</div>
				<div><a  v-if="isViewAll" @click="$event=>{ isViewAll=false; }" > Collapse All &nbsp; <i class="fas fa-solid fa-arrow-right"></i></a>
					<a  v-if="!isViewAll" @click="$event=>{ isViewAll=true; }" > View All &nbsp; <i class="fas fa-solid fa-arrow-right"></i></a></div>
			</div>

		</div>
	</div>
</template>

<script>
import router from "../../router";
import { mapActions, mapState } from "vuex";
import StarRating from "vue-star-rating"
export default {
	name: "ProductFeedbackCard",
	data() {
		return {
			feedbacks:null,
			mainFeedbackIndex:0,
			isViewAll:false
		};
	},
	components:{		
		StarRating
	},
	props: { productVariantData: Object },
	methods: {
		...mapActions("productVariant", ["fetchRatingByProductVariant"]),
		next(){
			this.mainFeedbackIndex++;
			
			if(this.mainFeedbackIndex>(this.feedbacks.length-1))
			{
				this.mainFeedbackIndex=0;
			}
		},
		previous(){
			this.mainFeedbackIndex--;
			if(this.mainFeedbackIndex<0)
			{
				this.mainFeedbackIndex=this.feedbacks.length-1;
			}
		}
	},
	computed: {
		
	},
	created() {
		
	},
	watch: {
		productVariantData: function () {
			var id=this.productVariantData.id+"";
			this.fetchRatingByProductVariant(id).then((val)=>{
				this.feedbacks=val;
			});
		},
	},
};
</script>

<style scoped>
.feedback-text{
	color: rgb(153, 153,153);
	font-size: larger;
	margin-top: 20px;
}
.feedback-div{
	padding: 0px;
	padding-right: 30px;
}
.feedback-arrow{
	font-size: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    background-color: #ffffff;
    box-shadow: 3px 3px 6px #00000029;
    color: var(--secondary-color);
    text-decoration: none;
	margin-left: 10px;
}
.feedback-text-user{
	color: black;	
	font-size: larger;
	font-weight: bold;
	margin-top: 20px;
	margin: 0px;
	padding: 0px;
}
.feedback-box{
	margin-top: 10px;
	padding: 20px 1.875rem;
    border: 1px solid #a7a9ac;
    border-radius: 8px;
}
.liner-div{
	
	justify-content: space-between;   
	 margin: 5px;
    margin-top: 10px;
}
.liner-div hr{
	width: 100%;
 }
 
 .liner-div a{
	 text-decoration: none;
	 color: black;
	 cursor: pointer;
  }

</style>
