import parser from "ua-parser-js";
import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  POST_USER_LOGIN,
  POST_USER_REGISTER,
  FETCH_CURRENT_USER,
  PUT_USER_PROFILE,
  UPDATE_USER_PROFILE,
  FETCH_TRAINERS_API,
  FETCH_POST_USERS_API,
  EXPORT_POST_USERS_API,
  EXPORT_USERS_ENROLLMENT_API,
  PUT_USERS_API,
  FETCH_POST_USERS_BY_PRODUCT_VARIANT_API,
  POST_PRODUCT_VARIANT_ENROLL_USER,
  PUT_UPDATE_PRODUCT_VARIANT_ENROLL_USER,
  FETCH_POST_USER_DEVICES_API,
  POST_USER_DEVICES_RESET_API,
  POST_USER_DEVICE_REMOVE_API,
  FETCH_USER_DEVICE_RESTRICTIONS_API,
  GET_GENERATE_OTP,
  POST_VERIFY_OTP,
  POST_RESET_PASSWORD,
  PATCH_USER_STATUS,
  RESET_ALL_USERS_DEVICES,
  MARK_USERS_STATUS_ACTIVE_OR_INACTIVE,
  FETCH_USERS_BY_EMAILS,
  VERIFY_USER,
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

export const userService = {
  login,
  logout,
  register,
  getCurrentUser,
  editUserProfile,
  editUserPassword,
  fetchTrainersList,
  fetchUsersList,
  fetchPaginatedUsersList,
  exportUsersList,
  exportUsersEnrollmentReport,
  fetchUserById,
  createUser,
  editUser,
  deleteUser,
  fetchUsersByProductVariant,
  enrollUserInProductVariant,
  editUserErollmentInProductVariant,
  editUserSubscription,
  fetchUserDevices,
  resetUserDeviceById,
  resetUserDevices,
  fetchUserDeviceRestrictions,
  setUserDeviceRestrictions,
  generateOTP,
  verifyOTP,
  resetPassword,
  updateUserStatus,
  allUsersDevicesReset,
  markUsersStatus,
  getUsersByEmails,
  generateVerificationID,
  verifyUser,
};

async function generateVerificationID(data) {
  let url = store.state.appState.apiURL + GET_GENERATE_OTP;
  if (data.email) {
    url += `?email=${data.email}`;
  } else if (data.phone) {
    url += `?phone=${encodeURIComponent(data.phone)}`;
  }

  url += "&type=userverification";

  const response = await APIHelpers.makeAPICall(url, "GET").then(
    handleResponse
  );

  return response;
}

async function verifyUser(data) {
  let url = store.state.appState.apiURL + VERIFY_USER;

  const response = await APIHelpers.makeAPICall(
    url,
    "POST",
    JSON.stringify(data)
  ).then(handleResponse);

  return response;
}

async function login(username, password) {
  store.dispatch("appState/startLoading");

  const response = await APIHelpers.makeAPICall(
    store.state.appState.apiURL + POST_USER_LOGIN,
    "POST",
    JSON.stringify({ username, password }),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);

  if (response?.payload?.token) {
    // console.log("token found in response: ", response?.payload?.token);
    store.dispatch("appState/stopLoading");
    helpers.setCookie(
      "token",
      response?.payload?.token,
      new Date(new Date().setDate(new Date().getDate() + 30)).toUTCString()
    );
    localStorage.setItem(
      "last_token_time",
      JSON.stringify(new Date().getTime())
    );

    // commented due to malfunction on backend
    // -------------------------------------------------
    if (response?.payload?.deviceToken) {
      localStorage.setItem(
        "deviceToken",
        JSON.stringify(response?.payload?.deviceToken)
      );
    }
    // --------------------------------------------------

    // console.log("returned from user service: ", response);
    // console.log(requestOptions);
    return response;
  }
}

function logout() {
  // remove user from localstorage and token from cookies to logout user
  store.dispatch("appState/startLoading");
  setTimeout(() => {
    store.dispatch("appState/stopLoading");
  }, 1000);
  localStorage.removeItem("user");
  helpers.removeCookie("token");
}

async function register(user) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_USER_REGISTER,
    "POST",
    JSON.stringify(user),
    false
  ).then(handleResponse);
  return response;
}
async function forgotPassword(user) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_USER_REGISTER,
    "POST",
    JSON.stringify(user),
    false
  ).then(handleResponse);
  return response;
}

async function generateOTP(userData) {
  store.dispatch("appState/startLoading");
  var queryString = "";
  Object.entries(userData).forEach(([key, value]) => {
    if (value) {
      queryString +=
        `${queryString.length > 0 ? "&" : "?"}` + key + "=" + value;
    }
  });
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL +
      GET_GENERATE_OTP +
      `${queryString.length != 0 ? queryString : ""}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function verifyOTP(otpData) {
  store.dispatch("appState/startLoading");

  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_VERIFY_OTP,
    "POST",
    JSON.stringify(otpData),
    false
  ).then(handleResponse);
  return response;
}

async function resetPassword(resetData) {
  store.dispatch("appState/startLoading");

  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_RESET_PASSWORD,
    "POST",
    JSON.stringify(resetData),
    false
  ).then(handleResponse);
  return response;
}

async function getCurrentUser() {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_CURRENT_USER,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}
async function editUserProfile(newUserData) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + PUT_USER_PROFILE,
    "PUT",
    JSON.stringify(newUserData),
    false
  ).then(handleResponse);
  return response;
}

async function editUserPassword(newUserPassword) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + UPDATE_USER_PROFILE,
    "POST",
    JSON.stringify(newUserPassword),
    false
  ).then(handleResponse);
  return response;
}

async function fetchTrainersList() {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_TRAINERS_API,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function fetchUserById(id) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_POST_USERS_API + `/${id}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function fetchUsersList(type) {
  store.dispatch("appState/startLoading");
  var requestLink = type ? `?Type=${type}` : "";
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_POST_USERS_API + requestLink,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function fetchPaginatedUsersList({
  type,
  pageSize,
  nextPageToken,
  searchString,
  status,
}) {
  store.dispatch("appState/startLoading");

  var requestLink = type ? `?Type=${type}` : "";
  requestLink +=
    pageSize && status
      ? `${requestLink.startsWith("?") ? "&" : "?"}Status=${status}`
      : "";
  requestLink += pageSize
    ? `${requestLink.startsWith("?") ? "&" : "?"}PageSize=${pageSize}`
    : "";
  requestLink += nextPageToken
    ? `${requestLink.startsWith("?") ? "&" : "?"}NextPageToken=${nextPageToken}`
    : "";
  requestLink += searchString
    ? `${requestLink.startsWith("?") ? "&" : "?"}SearchString=${searchString}`
    : "";
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_POST_USERS_API + requestLink,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function exportUsersList({ type, searchString, status }) {
  var requestLink = type ? `?Type=${type}` : "";
  requestLink += status
    ? `${requestLink.startsWith("?") ? "&" : "?"}Status=${status}`
    : "";
  requestLink += searchString
    ? `${requestLink.startsWith("?") ? "&" : "?"}SearchString=${searchString}`
    : "";
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + EXPORT_POST_USERS_API + requestLink,
    "GET",
    null,
    false
  );
  if (!response.ok) {
    return response.json();
  } else {
    return response;
  }
}

async function exportUsersEnrollmentReport(productVariantId = undefined) {
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + EXPORT_USERS_ENROLLMENT_API + (productVariantId ? `?ProductVariantID=${productVariantId}` : ''),
    "GET",
    null,
    false
  );
  if (!response.ok) {
    return response.json();
  } else {
    return response;
  }
}

async function fetchUsersByProductVariant(id) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL +
      FETCH_POST_USERS_BY_PRODUCT_VARIANT_API +
      `${id}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function createUser(user) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_POST_USERS_API,
    "POST",
    JSON.stringify(user),
    false
  ).then(handleResponse);
  return response;
}

async function editUser(user) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + PUT_USERS_API,
    "PUT",
    JSON.stringify(user),
    false
  ).then(handleResponse);
  return response;
}

async function updateUserStatus(user) {
  var updatedUser = { userID: user.userID, status: user.status };
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + PATCH_USER_STATUS,
    "PATCH",
    JSON.stringify(updatedUser),
    false
  ).then(handleResponse);
  return response;
}

async function deleteUser(id) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + PUT_USERS_API + `/${id}`,
    "DELETE",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function enrollUserInProductVariant(user) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_PRODUCT_VARIANT_ENROLL_USER,
    "POST",
    JSON.stringify(user),
    false
  );
  // .then(handleResponse);
  // return response;
}

async function editUserErollmentInProductVariant(user) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_PRODUCT_VARIANT_ENROLL_USER,
    "PUT",
    JSON.stringify(user),
    false
  );
  // .then(handleResponse);
  // return response;
}
async function editUserSubscription(obj) {
  store.dispatch("appState/startLoading");
  var res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + PUT_UPDATE_PRODUCT_VARIANT_ENROLL_USER,
    "PUT",
    JSON.stringify(obj),
    false
  ).then(handleResponse);
  return res;
}

async function fetchUserDevices(username) {
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL +
      FETCH_POST_USER_DEVICES_API +
      `?Username=${username}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function resetUserDevices(userID) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_USER_DEVICES_RESET_API + userID,
    "POST",
    null,
    false
  ).then(handleResponse);
}

async function resetUserDeviceById(deviceData) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + POST_USER_DEVICE_REMOVE_API,
    "POST",
    JSON.stringify(deviceData),
    false
  ).then(handleResponse);
}

async function fetchUserDeviceRestrictions(userID) {
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_USER_DEVICE_RESTRICTIONS_API + userID,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function setUserDeviceRestrictions(data) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_POST_USER_DEVICES_API,
    "POST",
    JSON.stringify(data),
    false
  ).then(handleResponse);
}

async function allUsersDevicesReset(emails) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL +
      RESET_ALL_USERS_DEVICES +
      "?UserEmails=" +
      emails,
    "POST",
    null,
    false
  ).then(handleResponse);
}

async function markUsersStatus(requestData) {
  store.dispatch("appState/startLoading");
  await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + MARK_USERS_STATUS_ACTIVE_OR_INACTIVE,
    "PATCH",
    JSON.stringify(requestData),
    false
  ).then(handleResponse);
}

async function getUsersByEmails(emails) {
  store.dispatch("appState/startLoading");
  const response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_USERS_BY_EMAILS,
    "POST",
    JSON.stringify({ UserEmails: emails }),
    false
  ).then(handleResponse);
  return response;
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
