<template>
  <div class="search-page">
    <BannerSmall class="mb-4">
      <!-- <div class="banner-title-card">
				<h1 class="banner-title">Search Courses</h1>
			</div> -->
      <div
        class="d-flex flex-column align-items-start justify-content-center h-100 mt-4"
      >
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <i class="fas fa-home-lg-alt"></i> Home
          </li>
          <li class="breadcrumb-item">Search</li>
          <li v-if="searchText" class="breadcrumb-item active">
            Search Results for "{{ searchText }}"
          </li>
        </ol>
        <h2 class="text-bold" v-if="searchText">
          Search Results for "{{ searchText }}"
        </h2>
        <h2 class="text-bold" v-else>Search</h2>
      </div>
    </BannerSmall>
    <div class="container">
      <!-- <div>Search Page</div> -->

      <ProductList
        viewMode="list"
        :showFilters="true"
        :productListData="filteredProductVariants"
        :filters="filters"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ProductList from "../components/Product/ProductList.vue";
import BannerSmall from "../components/Layout/BannerSmall.vue";

export default {
  name: "Search",
  data() {
    return {
      searchText: "",
      filters: {}
    };
  },
  components: {
    ProductList,
    BannerSmall,
  },
  computed: {
		...mapGetters("productVariant", ["allProductVariants"]),
		filteredProductVariants() {
			return this.allProductVariants.filter((productVariant) => {
				if (
					productVariant.status.toLowerCase() != "draft" &&
					productVariant.status.toLowerCase() != "private"
				)
					return productVariant;
			});
		},
	},
	methods: {
		...mapActions("productVariant", ["fetchProductVariants"]),
    loadFiltersFromQueryString(query) {
      this.filters = {};
      this.searchText = '';

      if(query && query.title) {
        this.searchText = query.title;
        this.filters.productTitle = query.title;
      }

      if(query && query.categoryId) {
        this.filters.categoryId = query.categoryId;
      }

      if(query && query.productId) {
        this.filters.productId = query.productId;
      }
    }
	},
	created() {

    this.loadFiltersFromQueryString(this.$route.query);

    if(!this.allProductVariants) {
		  this.fetchProductVariants(this.filters);
    }
    // console.log(this.productVariants);
  },
  watch: {
    $route(to, from) {
      console.log({to});

      this.loadFiltersFromQueryString(to.query);
    }
  }
};
</script>

<style scoped>
.search-page .banner-small {
  height: 8rem;
  margin-bottom: 3rem;
}
.banner-title-card {
  background-color: var(--color-white);
  padding: 2.75rem 3rem 1.75rem 1.5rem;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #a3a3a3;
  position: absolute;
  top: 3rem;
  box-shadow: 0 0 16px #c9c9c929;
}

.banner-title {
  /* font-size: 2.5rem;
	font-weight: bold; */
  font-weight: bold;
  font-size: 2rem;
  color: #000000;
  /* padding: 2.5rem 0; */
  margin: 0;
}

.banner-title:before {
  content: "";
  width: 2rem;
  height: 0.35rem;
  background-color: var(--primary-color);
  display: block;
  top: -1rem;
  left: 0;
  position: relative;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb i {
  font-size: 1.125rem;
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.breadcrumb.mobile {
  margin-bottom: 1.5rem;
  padding-left: 0.25rem;
}

.breadcrumb-item a {
  color: #231f20 !important;
  font-weight: normal;
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: bold !important;
  color: #231f20 !important;
}
</style>
