<template>
	<center v-if="loading" class="loading">
		<b-spinner large></b-spinner>
	</center>

	<div v-else class="eb-content eb-live-classes">

		<div v-if="classes.length > 0">
			<div class="cd-body round-top">

				<div class="lc-table-wrapper">
					<ul class="eb-table lc-table">
						<li class="thead">
							<div class="t-cell">Day
								<span class="ms-3 d-flex flex-column">
									<span @click="sortByDate(true)" class="cursor-pointer">
										<i class="fa fa-caret-up fa-lg" :class="!isSortByDateAsc ? 'caret-icon-opacity' : ''"></i>
									</span>
									<span @click="sortByDate(false)" class="cursor-pointer">
										<i class="fa fa-caret-down fa-lg" :class="isSortByDateAsc ? 'caret-icon-opacity' : ''"></i>
									</span>
								</span>
							</div>
							<div class="t-cell">Timing</div>
							<div class="t-cell">Class</div>
							<div class="t-cell text-center">Status</div>
							<!-- <div class="t-cell text-center">Progress</div> -->
							<div class="t-cell"></div>
						</li>

						<li class="t-row" v-for="upcomingClass in classes"
							:key="upcomingClass.id" 
							@click="()=>{ $router.push({ params:{ page:'chapters', id:productVariantID }, query:{  contentId:upcomingClass.id }}) }">
							<div class="t-cell">
								<div class="datebox">
									<span class="datebox-day">{{ new Date(upcomingClass.availableFrom).getDate()
									}}</span>
									<span class="datebox-month">{{ getMonthName(upcomingClass.availableFrom)
									}}</span>
								</div>
								
							</div>
							<div class="t-cell">
								<span class="font-medium">{{ formatTimeRange(upcomingClass.availableFrom,
									upcomingClass.availableTo) }}</span>
							</div>
							<div class="t-cell">
								<span class="font-medium">{{ upcomingClass.title }}</span>
							</div>
							<div id="status" class="t-cell text-center">
								<span
									:class="[{ 'absent': isAbsent(upcomingClass) }, { 'present': !isAbsent(upcomingClass) }]">{{
										isScheduled(upcomingClass) ? "Scheduled" :
									isActiveClass(upcomingClass) ? 'In Progress' : isAbsent(upcomingClass) ? "Absent" : "Present" }}
									<i class="fas fa-circle comment-notify-batch" v-if="getReason(upcomingClass)"></i>
								</span>

								<div v-if="getReason(upcomingClass)" class="dropdown-content">
									<p class="p-2">
										{{ getReason(upcomingClass) }}
									</p>
								</div>
							</div>
							<!-- <div class="t-cell text-center">
							<div class="lc-progress d-flex">
								<div class="progress mb-1">
									<div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25"
										aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<div class="lc-progress-label mb-1">
									<span id="progressVal">55</span>%
								</div>
							</div>
						</div> -->
							<div class="t-cell justify-content-center">

								<div v-if="isActiveClass(upcomingClass)" class="lc-actions" @click.stop>
									<button @click="onJoinClassPressed(upcomingClass)"
										class="btn btn-outline-primary">Join</button>
								</div>
							</div>
						</li>


					</ul>
				</div>


			</div><!-- /cd-body-->
		</div>
		<center v-else class="no-content text-center">
			<h4 class="mt-4">No Classes Found</h4>
		</center>
		<!-- <div class="cd-footer">
			<div class="px-3 py-3 d-flex align-items-center">
				<div class="">
					Page 1 to 10
				</div>
				<div class="ms-auto cd-footer-nav">
					<button type="submit" class="btn btn-outline-primary btn-sm btn-prev me-2"><img 
							src="@/assets/images/arrow-bottom.svg"> Previous</button>
					<button type="submit" class="btn btn-outline-primary btn-next btn-sm">Next <img 
							src="@/assets/images/arrow-bottom.svg"></button>
				</div>
			</div>
		</div> -->


	</div><!-- /eb-content -->
</template>

<script>
// imports come here
import { mapActions, mapState } from "vuex";
import { LiveClassTabs } from '../../constants/live_class';
import Loading from "../Layout/Loading.vue";

export default {
	name: "ProductContentClassesTab",
	props: {
		tab: String
	},
	data() {
		return {
			loading: false,
			productVariantID: null,
			attendenceReport: null,
			classes: [],
			isSortByDateAsc: true
		};
	},
	methods: {
		...mapActions("content", ["getUpcomingContent", "getLiveClassContentAttendance"]),
		getMonthName(dateString) {
			const dateObj = new Date(dateString);
			const monthIndex = dateObj.getMonth();

			const monthNames = [
				'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
				'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
			];

			return monthNames[monthIndex];
		}
		,
		formatTimeRange(startDateTime, endDateTime) {
			const startTime = new Date(startDateTime).toLocaleString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			});
			const endTime = new Date(endDateTime).toLocaleString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			});
			return `${startTime} To ${endTime}`;
		},
		async fetchTabData() {
			var currentDate = new Date();
			console.log(currentDate);
			if (this.tab == LiveClassTabs.TODAY) {
				this.loading = true;
				this.productVariantID = this.$route.params.id;

				await this.getUpcomingContent({
					startDate: new Date(currentDate.setHours(0, 0, 0, 0)).toISOString(),
					endDate: new Date(currentDate.setHours(23, 59, 0, 0)).toISOString(),
					productVariantID: this.productVariantID
				}).then(() => {
					this.loading = false;
				});
			}
			else if (this.tab == LiveClassTabs.ALL) {
				this.loading = true;
				await this.getUpcomingContent({
					startDate: undefined,
					endDate: undefined,
					productVariantID: this.productVariantID
				}).then(() => {
					this.loading = false;
				});
			}
			else if (this.tab == LiveClassTabs.PREVIOUS) {
				this.loading = true;
				await this.getUpcomingContent({
					startDate: undefined,
					endDate: currentDate.toISOString(),
					productVariantID: this.productVariantID
				}).then(() => {
					this.loading = false;
				});
			}
			else if (this.tab == LiveClassTabs.UPCOMING) {
				this.loading = true;
				await this.getUpcomingContent({
					startDate: currentDate.toISOString(),
					endDate: undefined,
					productVariantID: this.productVariantID
				}).then(() => {
					this.loading = false;
				});
			}
		},
		filterClasses(type, classes) {
			const now = new Date();

			switch (type) {
				case LiveClassTabs.UPCOMING:
					return classes.filter((classItem) => {
						return !this.isActiveClass(classItem)
					});

				case LiveClassTabs.ALL:
				case LiveClassTabs.TODAY:
					return classes;

				case LiveClassTabs.PREVIOUS:
					return classes.filter((classItem) => {
						return new Date(classItem.availableTo) < now;
					});

				default:
					return [];
			}
		},

		getLiveClassAttendenceReport() {
			if (this.productVariantID != null) {
				this.getLiveClassContentAttendance({ productVariantID: this.productVariantID }).then((data) => {
					this.attendenceReport = new Map(data.map((item) => [item.contentID, item]));
				});
			}
		},
		isAbsent(upcomingClass) {
			try {
				const report = this.attendenceReport.get(upcomingClass.id);
				return !report || !report.status || report.status === "absent";
			} catch (error) {
				return true;
			}

		},
		isScheduled(upcomingClass) {
			if(upcomingClass && upcomingClass.availableTo && upcomingClass.availableFrom) {
				var currentDate = new Date();
				var start = new Date(upcomingClass.availableFrom);
				var end = new Date(upcomingClass.availableTo);

				return currentDate < start && currentDate < end ? true : false
			}
			return false;
		},
		getReason(upcomingClass) {
			try {
				const report = this.attendenceReport.get(upcomingClass.id);
				return !report && !report.reason ? false : report.reason
			} catch (error) {
				return false
			}

		},
		isActiveClass(upcomingClass) {
			var currentDate = new Date();

			const start = new Date(upcomingClass.availableFrom)
			const end = new Date(upcomingClass.availableTo)

			return currentDate > start && currentDate < end
		},
		onJoinClassPressed(activeClass) {
			this.$router.push({
				path:
					"/content/liveclass/" +
					activeClass.title
						.toLowerCase()
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.split(" ")
						.join("-") +
					"-" +
					activeClass.id +
					"_" +
					this.productVariantID,
			});
			return;

		},
		sortByDate(isAsc) {
			this.isSortByDateAsc = isAsc ? true : false;

			if(this.isSortByDateAsc) {
				this.classes = this.classes.sort((a, b) => new Date(a.availableFrom) - new Date(b.availableFrom));
			}
			else if(!this.isSortByDateAsc) {
				this.classes = this.classes.sort((a, b) => new Date(b.availableFrom) - new Date(a.availableFrom));
			}
		}
	},
	computed: {
		...mapState("content", ["upcomingContent"]),
	},
	watch: {
		tab: async function () {
			await this.fetchTabData();	
			this.classes = await this.filterClasses(this.tab, this.upcomingContent);
			this.sortByDate(this.isSortByDateAsc);

			this.getLiveClassAttendenceReport();
		},
		$route(to, from) {
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.fetchTabData();
			}
		}
	},
	components: { Loading },
	created() {
	},
	async mounted() {
		await this.fetchTabData();
		this.classes = await this.filterClasses(this.tab, this.upcomingContent);

		this.getLiveClassAttendenceReport()
	},
};
</script>


<style scoped>
.caret-icon-opacity {
	opacity: 0.4;
}
.lc-table .t-row {
	cursor: pointer;
}

.lc-table .t-row:hover {
	background-color: var(--gray-light-hover);
}
.cd-body{
	height: auto;
}
.lc-table-wrapper{
	font-size: 1rem;
}
.lc-table>li>.t-cell {
	flex: 0.6;
	align-items: center;
	display: flex;
	border-bottom: 1px solid var(--border-color);
}

.lc-table>li>.t-cell:nth-child(1) {
	flex: 0.3;
	padding-left: 20px;
}

.lc-table>li>.t-cell:nth-child(2) {
	flex: 0.8;
}

.lc-table>li>.t-cell:nth-child(3) {
	flex: 1.2;
}

.lc-table>li>.t-cell:nth-child(4) {
	padding-left: 20px;
}

.lc-table>li>.t-cell:last-child {
	flex: 0.4;
}

.datebox {
	border-radius: 8px;
	text-align: center;
	padding: 2px;
	border: solid 1px var(--border-color);
	font-weight: 700;
	width: 44px;
	height: 42px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.datebox-day {
	color: var(--primary-color);
	font-size: 18px;
	display: block;
	line-height: 16px;
}

.datebox-month {
	font-size: 12px;
	line-height: 14px;
	display: block;
	font-weight: 600;
}

.lc-table .datebox {
	margin: 4px 0;
}

.lc-progress {
	display: flex;
	align-items: center;
	justify-content: center;
}

.lc-progress .progress {
	width: 90px;
	margin-right: 4px;
}

.lc-progress .progress-bar {
	background-color: var(--progress-bar);
}

.lc-progress .progress {
	height: 7px;
}

.lc-progress-label {
	font-size: 12px;
}

.lc-table .present,
.lc-table .absent {
	font-weight: 600;
}

.lc-table .present {
	color: var(--progress-bar);
}

.loading {
	margin-top: 200px;
	margin-bottom: 200px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 999;
	right: 0;
}

/* Links inside the dropdown */
.dropdown-content p {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

#status span {
	position: relative;
}

/* Change color of dropdown links on hover */
#status span:hover {
	cursor: pointer;
}

#status:hover .dropdown-content {
	display: block;
}

.comment-notify-batch {
	z-index: 1;
	color: red;
	position: absolute;
	right: -4px;
	font-size: 6px;
	top: -4px;
}

.no-content {
	margin-top: 50px;
	margin-bottom: 50px;
}
@media (max-width:768px){
	.lc-table>li>.t-cell:nth-child(1) {
		padding-left: 10px;
	}
}
</style>
