<template>
	<!-- <div class="product-activity-container"> -->
	<div class="activity-card">
		<h4 class="activity-card-heading">Last Activities</h4>
		<div class="activity-content">
			<div class="accordion accordion-flush" id="activityAccordian">
				<div v-for="item in 1" :key="item" class="accordion-item">
					<h2 class="accordion-header" :id="`accordian-${item}`">
						<button
							:class="[
								'accordion-button',
								{ collapsed: activeActivityAccordian != item },
							]"
							type="button"
							data-bs-toggle="collapse"
							:data-bs-target="`#accordian-collapse-${item}`"
							:aria-expanded="activeActivityAccordian == item"
						>
							Today
						</button>
					</h2>
					<div
						:id="`accordian-collapse-${item}`"
						:class="[
							'accordion-collapse',
							{
								collapse: activeActivityAccordian != item,
								show: activeActivityAccordian == item,
							},
						]"
						data-bs-parent="#activityAccordian"
					>
						<div class="accordion-body">
							<div class="activity-list">
								<div class="activity-item" v-for="item in 5" :key="item">
									<h4 class="activity-title">
										This course class teacher will be continue from Another
										teacher from next class
									</h4>
									<p class="activity-text">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore
									</p>
									<a role="button" class="activity-read-more">Read more</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
export default {
	name: "ProductActivityCard",
	data() {
		return {
			activeActivityAccordian: 1,
		};
	},
	// methods: {
	// 	changeTab(e) {
	// 		this.activeTab = e.target.id;
	// 	},
	// },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.activity-card {
		margin-top: -1rem !important;
	}

	.product-activity-container {
		padding: 0 !important;
	}
}

.product-activity-container {
	display: flex;
	width: 35%;
	padding: 0 0.5rem;
}

.activity-card {
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 8px;
	/* padding: 1.5rem 1.5rem 0; */
	margin-top: -9rem;
	z-index: 998;
	background-color: var(--color-white);
	box-shadow: 0 -6px 8px #e0e0e029;
	height: fit-content;
}

.activity-card-heading {
	padding: 1.375rem 1.5rem 0;
}

.activity-tabs {
	border-radius: 8px 8px 0 0;
	background-color: #f8f8f8;
}

.activity-tabs li {
	width: 50%;
	font-weight: 500;
	padding: 1rem;
}

.activity-tabs li:first-child {
	border-radius: 8px 0 0 0 !important;
}

.activity-tabs li.active {
	border-radius: 0 8px 0 0;
	background-color: #ffffff;
}

.activity-content {
	padding: 0 1.5rem 0;
	max-height: 80vh;
	overflow-y: auto;
	margin: 0.5rem 0;
}

.tab-icon {
	font-size: 1.1rem;
	margin-right: 0.5rem;
	pointer-events: none;
}

.accordion-button {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.75rem;
	padding: 0.5rem 0;
}

.accordion-button:not(.collapsed) {
	color: #8b8b8b !important;
	background-color: transparent !important;
	box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%707070'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion-body {
	padding: 1rem 0;
}

.activity-item {
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background-color: #fafafa;
	box-shadow: 0 6px 8px #e0e0e029;
	padding: 1.25rem;
	margin-bottom: 1.5rem;
}
.activity-item:last-child {
	margin-bottom: 0;
}

.activity-title {
	font-size: 1.125rem;
	color: #000;
	font-weight: 500;
	width: 100%;
}

.activity-text {
	font-size: 1rem;
	color: #4a4a4a;
	line-height: 1.5rem;
	font-weight: normal;
	margin-bottom: 0.5rem;
}

.activity-read-more {
	font-size: 0.875rem;
	font-weight: 450;
	color: #ff0000;
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 6px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: #ebebeb;
}

::-webkit-scrollbar-thumb {
	background: #000000;
	border-radius: 1rem;
}

.notification-badge {
	position: relative;
	top: -0.75rem;
	right: 0.3rem;
	font-size: 0.7rem;
	line-height: 1;
	background-color: var(--color-red);
	display: inline-block;
	padding: 0.2rem 0.3rem;
	border-radius: 5rem;
	font-weight: 500;
	color: #ffffff;
}

/* .live-class-item {
	width: 100%;
	border: 2px solid var(--color-gray);
	border-radius: 4px;
	padding: 1rem;
	margin-bottom: 1rem;
}

.live-class-tag {
	border-radius: 4px;
	background-color: var(--color-red);
	padding: 0.1rem 0.5rem;
	color: var(--color-white);
	font-size: 0.65rem;
	text-transform: uppercase;
}

.class-timings {
	color: var(--color-dark-gray);
	font-size: 0.7rem;
	margin-left: auto;
	display: inline-block;
}

.live-class-title {
	font-size: 1rem;
	padding: 0.5rem 0;
	margin: 0;
	margin-top: 0.5rem;
	font-weight: bold;
}

.join-btn {
	text-transform: uppercase;
	text-decoration: none;
	font-size: 0.75rem;
	margin: 0;
	padding: 0.2rem 0.75rem;
	color: white;
	background-color: var(--color-red);
	border-radius: 1rem;
	margin-left: 0.5rem;
	font-weight: 500;
} */
</style>
