<template>
	<div class="eb-content-wrapper">
		<div class="tab-content eb-body" id="myTabContent">
			<div class="tab-pane show active" id="tocPane" role="tabpanel" aria-labelledby="tocTab" tabindex="0">

				<div class="eb-header d-flex mb-2">
					<h5 class="align-self-end">
						Live Classes
					</h5>

					<div class="btn-group live-class-filters ms-md-auto" role="group">
						<button type="button" @click="changeTab(LiveClassTabs.ALL)" :class="['btn', 'btn-outline-primary', { active: activeTab === LiveClassTabs.ALL }]">All</button>
						<button type="button" @click="changeTab(LiveClassTabs.TODAY)" :class="['btn', 'btn-outline-primary', { active: activeTab === LiveClassTabs.TODAY }]">Today</button>
						<button type="button" @click="changeTab(LiveClassTabs.PREVIOUS)" :class="['btn', 'btn-outline-primary', { active: activeTab === LiveClassTabs.PREVIOUS }]">Previous</button>
						<button type="button" @click="changeTab(LiveClassTabs.UPCOMING)" :class="['btn', 'btn-outline-primary', { active: activeTab === LiveClassTabs.UPCOMING }]">Upcoming</button>
					</div>
				</div>

				<div v-if="activeTab === LiveClassTabs.TODAY">
					<ProductContentClassesTab :tab="LiveClassTabs.TODAY"/>
				</div>
				<div v-else-if="activeTab === LiveClassTabs.ALL">
					<ProductContentClassesTab :tab="LiveClassTabs.ALL"/>
				</div>
				<div v-else-if="activeTab === LiveClassTabs.PREVIOUS">
					<ProductContentClassesTab :tab="LiveClassTabs.PREVIOUS"/>
				</div>
				<div v-else-if="activeTab === LiveClassTabs.UPCOMING">
					<ProductContentClassesTab :tab="LiveClassTabs.UPCOMING"/>
				</div>
			</div><!-- /tab-pane -->


		</div>
	</div><!-- /eb-content-wrapper -->
</template>

<script>
// imports come here
import ProductContentClassesTab from './ProductContentClassesTab.vue';
import { LiveClassTabs } from '../../constants/live_class';

export default {
	name: "ProductContentClasses",
	data() {
		return {
			activeTab: 'Today',
			LiveClassTabs : LiveClassTabs
		};
	},
	methods: {
		changeTab(tab){
			this.activeTab = tab;
		}
	},
	computed: {
	},
	watch: {},
	components: {ProductContentClassesTab},
	created() {
	this.activeTab = LiveClassTabs.TODAY
	},
	mounted() {
	}
};
</script>

<style scoped>
.live-class-filters .btn {
	min-width: 90px;
	color: var(--primary-color);
	font-weight: 600;
	background-color: white;
}

.live-class-filters .btn:active,
.live-class-filters .btn:hover {
	color: var(--primary-color);
}

.live-class-filters .btn.active {
	color: var(--secondary-color);
}

@media (max-width:768px){
	.eb-header{
		height: auto;
	}
}

</style>
