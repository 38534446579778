<template>
	<div class="position-relative">
		<VueSlickCarousel
			ref="instructors_carousel"
			class="instructor-listing-container container-fluid"
			v-if="instructorList.length > 0"
			v-bind="settings"
		>
			<InstructorListItem
				v-for="instructor in instructorList"
				:key="instructor.id"
				:instructorData="instructor"
			/>
		</VueSlickCarousel>
		<a class="carousel-arrow" role="button" @click="prevSlide"
			><i class="fas fa-angle-left"></i
		></a>
		<a class="carousel-arrow right" role="button" @click="nextSlide"
			><i class="fas fa-angle-right"></i
		></a>
	</div>
</template>

<script>
import InstructorListItem from "./InstructorListItem.vue";
import { INSTRUCTOR_CAROUSEL_SETTINGS } from "../../constants/productCarouselSettings";
import VueSlickCarousel from "vue-slick-carousel";

export default {
	name: "InstructorList",
	data() {
		return { settings: INSTRUCTOR_CAROUSEL_SETTINGS };
	},
	props: { instructorList: Array },
	components: { InstructorListItem, VueSlickCarousel },
	methods: {
		nextSlide() {
			this.$refs.instructors_carousel.next();
			if (this.currentReminderSlide < this.totalReminderSlides)
				this.currentReminderSlide += 1;
		},
		prevSlide() {
			this.$refs.instructors_carousel.prev();
			if (this.currentReminderSlide > 1) this.currentReminderSlide -= 1;
		},
	},
};
</script>

<style>
@media screen and (max-width: 996px) {
	.instructor-listing-container {
		padding: 0 !important;
	}
	.instructor-listing-container .slick-slide {
		padding: 2.25rem 0.5rem 0 !important;
	}
}

.instructor-listing-container .slick-slide {
	padding: 0 1rem;
	padding-top: 2.25rem;
}

.carousel-arrow {
	/* margin: 0.5rem; */
	font-size: 2rem;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 1;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 50%;
	border: 2px solid var(--secondary-color);
	background-color: #ffffff;
	box-shadow: 3px 3px 6px #00000029;
	color: var(--secondary-color);
	text-decoration: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.carousel-arrow:hover {
	color: var(--secondary-color);
	text-decoration: none;
}

.carousel-arrow.right {
	right: 0;
}
</style>
