<template>
	<div>
		<div v-if="tabData" class="tab-body-container pb-5">
			<div
				v-if="tabData.children && tabData.children.length > 0"
				class="content-list"
			>
				<div class="d-flex mb-3 justify-content-end">
					<!-- <b-dropdown id="dropdown-1" text="Action" class="m-md-2" v-if="!isChapterSelectable">
						<b-dropdown-item @click="makeChapterSelectable">Select CHAPTERs/SECTIONs</b-dropdown-item>
					</b-dropdown> -->
					<!-- <button @click="makeChapterSelectable" v-if="isChapterSelectable">Cancel</button> -->
					<button class="add-btn" role="button" @click="makeBulkChapterSelectable" v-if="!isChapterSelectable">
						Select CHAPTERs/SECTIONs
					</button>

					<button
						v-if="isChapterSelectable"
						title="Cancel Select"
						role="button"
						class="right-menu-icon me-2"
						@click="makeBulkChapterUnselectable"
					>
						<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
					</button>
					<button
						v-if="isChapterSelectable"
						title="Copy Selected"
						role="button"
						class="right-menu-icon me-3"
						@click="selectedChapters" 
						:disabled="!copiedSection || copiedSection.length <= 0"
						style="display: inline-block;"
					>
						<img src="@/assets/images/check-icon-green.svg" alt="save" />
					</button>
				</div>

				<draggable
					v-model="tabData.children"
					ghost-class="ghost"
					group="content-tab"
					animation="200"
					handle=".chapter-handle"
					@change="update($event, tabData.id)"
				>
					<CourseContentSection
						v-for="content in tabData.children"
						:key="content.id"
						:contentData="content"
						:tabData="tabData"
						:productVariantId="productVariantData.id"
					/>
				</draggable>
				<button
					v-if="mode == 'view'"
					@click="changeMode()"
					class="add-section-btn"
					id="add-section-btn"
				>
					<img src="@/assets/images/plus-icon-gray.svg" />
					Add Section
				</button>
			</div>
			<div
				v-if="
					(tabData.children == null || tabData.children.length == 0) &&
					mode == 'view'
				"
				class="no-content"
			>
				<h1 class="title">
					Start Creating your course by adding the first section
				</h1>
				<img src="@/assets/images/no-content-icon.svg" />
				<button @click="changeMode()" class="create-section" role="button">
					<img src="@/assets/images/add-border-icon.svg" alt="" />
					start sections
				</button>
			</div>
			<CourseContentSection
				v-if="mode == 'create'"
				:createMode="true"
				aria-describedby="new"
				@cancelCreate="changeMode"
				:tabData="tabData"
				:productVariantId="productVariantData.id"
			/>
		</div>
		<div v-else-if="isBlocked" class="blocked-content no-content">
			<i class="fas fa-exclamation-triangle blocked-icon"></i>
			You are not allowed to access this subject
		</div>
	</div>
</template>

<script>
import CourseContentSection from "./CourseContentSection.vue";
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";

export default {
	name: "ContentTabBody",
	data() {
		return {
			mode: "view",
		};
	},
	components: {
		CourseContentSection,
		draggable,
	},
	props: {
		tabData: Object,
		productVariantData: Object,
		isBlocked: Boolean,
		activeTab: String,
	},
	methods: {
		...mapActions("content", ["editContentSequence", "copyContent", "makeBulkChapterUnselectable", "makeBulkChapterSelectable", "selectedChapters"]),
		changeMode(mode) {
			if (mode) {
				this.mode = mode;
				return;
			}
			this.mode = "create";
			setTimeout(() => {
				document.getElementById("content-title").focus();
				document
					.getElementById("content-title")
					.parentNode.parentNode.parentNode.scrollIntoView({
						behavior: "smooth",
					});
			}, 200);
		},
		update(e, parent) {
			console.log(e, parent);
			var contentObj = {};
			var contentID;
			var afterContentID;
			if (e.added) {
				if (e.added.newIndex != 0) {
					afterContentID = this.tabData.children[e.added.newIndex - 1].id;
					contentObj.afterContentID = afterContentID;
				}
				contentObj = { ...contentObj, parentContentID: parent };
				contentID = e.added.element.id;
			}
			if (e.moved) {
				if (e.moved.newIndex != 0) {
					afterContentID = this.tabData.children[e.moved.newIndex - 1].id;
					contentObj.afterContentID = afterContentID;
				}
				contentObj = { ...contentObj, parentContentID: parent };
				contentID = e.moved.element.id;
			}
			if (!e.removed) {
				this.editContentSequence({ content: contentObj, id: contentID }).then(
					() => {
						console.log("editContentSequence content", contentObj);
					}
				);
			}
		},
		pasteContent() {
			for(let i=0; i<this.copiedSection.length; i++) {
				var copyContentObject = {
					content: this.copiedSection[i],
					destinationContentID: this.tabData.id,
				};
				this.copyContent(copyContentObject).then(() => {
					document.getElementById("add-section-btn").focus();
				});
			}
		}
	},
	computed: {
		...mapState("content", ["content", "copiedSection", "isChapterSelectable"]),
		// ...mapState("content", ["copiedSection"]),
	},
	mounted() {
		$(() => {
			$(".page-content-container").contextMenu({
				selector: `.col-9.tab-body#${this.activeTab}`,
				callback: (key, options) => {
					if (key == "paste") {
						this.pasteContent();
					}
				},
				items: {
					paste: {
						name: "Paste copied Chapter/Section",
						icon: "paste",
						disabled: () => !this.copiedSection || this.copiedSection.length <= 0,
					},
				},
			});
		});
	},
};
</script>

<style scoped>
.right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
	color: #000000;
	text-decoration: none;
}

.right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

.no-content {
	padding: 4.25rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	row-gap: 4rem;
}

.no-content .title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	text-align: center;
	color: #000000;
}

.no-content img {
	width: 13.625rem;
	height: 13.625rem;
}

.no-content .create-section {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.875rem 1.125rem;
	font-size: 1.125rem;
	line-height: 1.37;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75rem;
	color: #000000;
}

.no-content .create-section img {
	width: 1.25rem;
	height: 1.25rem;
}

.add-section-btn {
	display: flex;
	flex-direction: row;
	column-gap: 0.5rem;
	align-items: center;
	color: #838383;
	font-size: 1.125rem;
}

.blocked-content {
	color: #838383;
	font-size: 1.5rem;
	row-gap: 2rem;
	font-weight: 500;
}

.blocked-icon {
	font-size: 8rem;
}

.settings-menu-right {
	position: relative;
    height: 50px;
    text-align: right;
}
</style>
