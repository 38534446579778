<template>
	<div id="loading">
		<p><img src="@/assets/images/preloader.gif" /></p>
		<!-- <div class="progress-line"></div> -->
		<p>Loading...</p>
	</div>
</template>

<script>

export default {
	name: "Loading",
};
</script>

<style scoped>
@keyframes loading-animation {
	from {
		opacity: 0.35;
	}

	to {
		opacity: 1;
	}
}

#loading {
	position: fixed;
	/* z-index: 9; */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #ffffff;
	transition: 1s 0.4s;
	text-align: center;
	opacity: 0.95;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#loading p {
	/* margin-top: 45vh; */
	/* color: #ffffff; */
	opacity: 1;
}

#loading p img {
	height: 60px;
	width: auto;
	margin-bottom: 10px;
}

.progress-line,
.progress-line:before {
	height: 3px;
	width: 20%;
	margin: 0;
}
.progress-line {
	background-color: #ffd83f;
	display: -webkit-flex;
	display: flex;
	margin: auto;
}
.progress-line:before {
	background-color: #03334e;
	content: "";
	-webkit-animation: running-progress 0.6s linear infinite alternate;
	animation: running-progress 0.6s linear infinite alternate;
}
@-webkit-keyframes running-progress {
	0% {
		margin-left: 0px;
		margin-right: 100%;
	}
	50% {
		margin-left: 50%;
		margin-right: 0%;
	}
	100% {
		margin-left: 100%;
		margin-right: 0;
	}
}
@keyframes running-progress {
	0% {
		margin-left: 0px;
		margin-right: 100%;
	}
	50% {
		margin-left: 50%;
		margin-right: 0%;
	}
	100% {
		margin-left: 100%;
		margin-right: 0;
	}
}
</style>
