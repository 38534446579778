<template>
	<div
		class="dropdown-menu dropdown-menu-lg-end add-payment-dropdown"
		@click="(e) => e.stopPropagation()"
		:aria-labelledby="`payment-${invoice.id}`"
	>
		<div class="inline-group">
			<div class="col">
				<label for="amountPayable" class="main-label">Course Price</label>
				<input
					placeholder="Enter payable amount"
					class="form-input"
					type="search"
					id="amountPayable"
					disabled
					:value="selectedCyclePrice(invoice.id)"
				/>
			</div>
			<div class="col">
				<label
					for="amountReceived"
					:class="['main-label', { 'text-danger': isError }]"
					>Amount Received (required)</label
				>
				<input
					placeholder="Enter received amount"
					:class="['form-input', { 'text-danger': isError }]"
					type="number"
					id="amountReceived"
					v-model="cycleReceived"
					:max="selectedCyclePrice(invoice.id) - cycleDiscount"
				/>
			</div>
			<div class="col">
				<label
					for="amountDiscount"
					:class="['main-label', { 'text-danger': isError }]"
					>Discount (optional)</label
				>
				<input
					placeholder="Enter discount"
					:class="['form-input', { 'text-danger': isError }]"
					type="number"
					id="amountDiscount"
					v-model="cycleDiscount"
					:max="selectedCyclePrice(invoice.id) - cycleReceived"
				/>
			</div>
			<div class="col">
				<label
					for="amountBalance"
					:class="['main-label', { 'text-danger': isError }]"
					>Outstanding</label
				>
				<input
					placeholder="Enter balance"
					:class="['form-input', { 'text-danger': isError }]"
					type="search"
					id="amountBalance"
					:value="cycleOutstanding"
					disabled
				/>
			</div>
		</div>
		<p v-if="isError" class="text-danger">
			<i class="fas fa-exclamation-circle"></i> Paid amount cannot be greater
			than course price
		</p>
		<div class="buttons">
			<button class="skip-step-btn" @click="closeDropdown(invoice.id)">
				Cancel
			</button>
			<button
				:disabled="isError"
				class="step-btn"
				@click="togglePaid(invoice.id)"
			>
				Mark as Paid
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "UserInvoicePaymentDropdown",
	props: {
		invoice: Object,
		selectedCycles: Array,
		multiplePlanPayments: Object,
	},
	data() {
		return {
			cycleReceived: null,
			cycleDiscount: null,
			// cycleOutstanding: null,
		};
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
		togglePaid(id) {
			var amount = {
				received: this.cycleReceived,
				discount: this.cycleDiscount ? this.cycleDiscount : "0",
			};
			this.$emit("payment", { id, amount });
		},
		selectedCyclePrice(id) {
			var currentCycle = this.selectedCycles.find(
				(invoice) => invoice.id == id
			);
			return currentCycle ? currentCycle.price : 0;
		},
		closeDropdown(id) {
			document.getElementById(`payment-${id}`).click();
		},
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		isError() {
			var error = this.cycleOutstanding == "Invalid amount";
			// if (error) {
			// 	this.$emit("error", error);
			// }
			return error;
		},
		cycleOutstanding() {
			var balance;
			if (this.invoice)
				balance = this.invoice.price - this.cycleDiscount - this.cycleReceived;
			else balance = 0;
			return balance >= 0 ? balance : "Invalid amount";
		},
	},
	created() {
		if (this.invoice.isPaid) {
			this.cycleReceived = this.multiplePlanPayments[this.invoice.id].received;
			this.cycleDiscount = this.multiplePlanPayments[this.invoice.id].discount;
		}
		// document
		// 	.getElementById("add-payment-dropdown")
		// 	.onclick((e) => e.stopPropagation());
	},
};
</script>

<style scoped>
.add-payment-dropdown {
	max-width: 50rem;
	padding: 1.5rem;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 1rem;
}
</style>
