<template>
	<div class="home">
		<div class="banner-custom-container">
			<div class="banner-section">
				<HomePageBanner />
			</div>
			<div class="login-section">
				<Login />
			</div>
		</div>
	</div>
</template>

<script>
import HomePageBanner from "@/components/Layout/HomePageBanner.vue";
import { mapActions, mapState } from "vuex";
import Login from "@/components/Account/Login.vue";
export default {
	name: "Home",
	components: {
		HomePageBanner,
		Login,
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		...mapState("user", ["trainers"]),
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "homepage-loaded", data: null });
		});
	},
	created() {
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
	},
};
</script>

<style scoped>
.home .container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.section-heading {
	font-weight: 500;
	font-size: 2.75rem;
	line-height: 1.3;
	color: var(--secondary-color);
	margin-bottom: 0;
	letter-spacing: -0.45px;
}

.section-subheading {
	font-size: 1.375rem;
	color: #898989;
	font-weight: normal;
}

.section-heading-container {
	margin: 4rem 0 3.375rem;
}

.home .banner-custom-container {
	display: flex;
	flex-direction: row;
	width: 100vw !important;
}

.banner-custom-container .banner-section {
	width: 75%;
	flex-grow: 1;
}

.banner-custom-container .login-section {
	width: 25%;
	flex-grow: 1;
	padding: 2rem 0;
}

@media screen and (max-width: 1024px) {
	.home .banner-custom-container {
		flex-direction: column-reverse !important;
	}

	.banner-custom-container .banner-section {
		width: 100% !important;
	}

	.banner-custom-container .login-section {
		width: 100% !important;
	}
}
/* .section-heading::before {
	content: "";
	width: 3.1rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -0.7rem;
	left: 0.3rem;
	position: relative;
} */
</style>
