<template>
  <div>
    <div class="user-modal-tab-body devices">
      <div class="d-flex justify-content-between">
        <div>
          <div v-if="isUserViewLog && selectedDetail">
            <h6 v-if="parseProductVariants(selectedDetail.content.parentContents)"
              style="color: var(--primary-color); margin: 0px;">
              {{ parseProductVariants(selectedDetail.content.parentContents).map(x => x.ProductVariantTitle).join(", ") }}
            </h6>
            <h5 style="margin: 0px;">{{ selectedDetail.content.title }}</h5>
            <small v-if="parseSubjects(selectedDetail.content.parentContents)">
              {{ parseSubjects(selectedDetail.content.parentContents).map(x => x.title).join(", ") }}
            </small>
            <small v-if="parseChapters(selectedDetail.content.parentContents)">
              ->
              {{ parseChapters(selectedDetail.content.parentContents).map(x => x.title).join(", ") }}
            </small>
          </div>
        </div>
        <div v-if="isUserViewLog">
          <button class="step-btn" @click="backToList">Back To List</button>
        </div>
      </div>
      <div v-if="!isUserViewLog">
        <table class="table table-hover table-borderless enrolled-users-table" v-if="contentDetail.length > 0">
          <thead>
            <tr>
              <th class="col">#</th>
              <th class="col" v-if="!productVariantID">Course</th>
              <th class="col">Subject</th>
              <th class="col">Chapter</th>
              <th class="col">Content</th>
              <th class="col">View Count</th>
              <th class="col">Last Viewed At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in contentDetail" :disabled="!data.viewsCount || data.viewsCount <= 0"
              @click="showDetail(index)" style="cursor: pointer;" :key="data.id">
              <!-- v-for="device in devices"
                :key="device.id" -->
              <td>
                {{ index+1 }}
              </td>  
              <td class="col" v-if="!productVariantID">
                  <span v-if="parseProductVariants(data.content.parentContents)">
                    {{ parseProductVariants(data.content.parentContents).map(x => x.ProductVariantTitle).join(", ") }}
                  </span>
              </td>
              <td class="col">                
                  <span v-if="parseSubjects(data.content.parentContents)">
                    {{ parseSubjects(data.content.parentContents).map(x => x.title).join(", ") }}
                  </span>
              </td>
              <td class="col">                
                  <span v-if="parseChapters(data.content.parentContents)">
                    {{ parseChapters(data.content.parentContents).map(x => x.title).join(", ") }}
                  </span>
              </td>
              <td class="col">
                <div style="display: flex;">
                  <img v-if="data.content.type.toLowerCase() == 'recordedvideos'" class="page-view-icon" src="@/assets/images/recorded-lecture.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'exam'" class="page-view-icon" src="@/assets/images/exam-test.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'flashcards'" class="page-view-icon" src="@/assets/images/flash-card.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'freetext'" class="page-view-icon" src="@/assets/images/freeText.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'studymaterial'" class="page-view-icon" src="@/assets/images/study-material.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'quiz'" class="page-view-icon" src="@/assets/images/exam-test.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'assignment'" class="page-view-icon" src="@/assets/images/assignment.png" />
                  <img v-else-if="data.content.type.toLowerCase() == 'liveclass'" class="page-view-icon" src="@/assets/images/zoom-logo.png" />
                  <img v-else class="page-view-icon" src="@/assets/images/study-material.png" />
                  <h6 style="margin: 0px;">
                    {{ data.content.title }}
                    <small><br />{{ data.content.type }}</small>
                  </h6>
                </div>
              </td>
              <td>
                <div>
                  {{
                    data.viewsCount && data.viewsCount > 0
                    ? data.viewsCount + " Views"
                    : "No Views"
                  }}
                </div>
              </td>
              <td>
                <div>
                  {{ timeParser(data.lastViewedAt) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else-if="contentDetail.length == 0 && loading"
          class="d-flex align-items-center justify-content-center gap-2 p-3 font-500">
          <b-spinner small></b-spinner> Loading...
        </div>
        <div v-else class="no-content">No Data found</div>
      </div>
      <table class="table table-hover table-borderless" v-else>
        <thead>
          <tr v-if="selectedDetail.content.type.toLowerCase() == 'liveclass'">
            <th style="width: 2%; text-align: center">#</th>
            <th style="width: 25%">Time in</th>
            <th style="width: 25%">Time out</th>
            <th style="width: 25%">Duration</th>
            <th style="width: 23%">Attended From</th>
          </tr>
          <tr v-else>
            <th style="width: 2%; text-align: center">#</th>
            <th style="width: 10%">Viewed At</th>
            <th style="width: 35%">Device</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!selectedDetailViewLogs">
            <td colspan="5">
              <center>Loading...</center>
            </td>
          </tr>
          <tr v-else-if="selectedDetailViewLogs.length <= 0">
            <td colspan="5">
              <center>No Data Found</center>
            </td>

          </tr>
          <tr v-else-if="selectedDetailViewLogs.length > 0 && selectedDetail.content.type.toLowerCase() != 'liveclass'" v-for="(viewLog, index) in selectedDetailViewLogs">
            <td>
              <center>{{ index + 1 }}</center>
            </td>
            <td>{{ timeParser(viewLog.viewedAt) }}</td>
            <td>
              <span v-if="viewLog.device && viewLog.device.deviceToken">{{
                viewLog.device.deviceToken
              }}</span>
              <center v-else>-</center>
            </td>
            <!-- <td><span v-if="viewLog.device && viewLog.device.platform">{{ viewLog.device.platform }}</span><span v-else>-</span></td>
							<td><span v-if="viewLog.device && viewLog.device.ip">{{ viewLog.device.ip }}</span><span v-else>-</span></td> -->
          </tr>
          <tr v-else v-for="(viewLog, index) in selectedDetailViewLogs">
            <td>
              <center>{{ index + 1 }}</center>
            </td>
            <td>{{ timeParser(viewLog.timeIn) }}</td>
            <td>{{ timeParser(viewLog.timeOut) }}</td>
            <td>{{ secondsToHHMMSSFormat(viewLog.duration) }}</td>
            <td>{{ viewLog.attendedFrom }}</td>
            <!-- <td><span v-if="viewLog.device && viewLog.device.platform">{{ viewLog.device.platform }}</span><span v-else>-</span></td>
							<td><span v-if="viewLog.device && viewLog.device.ip">{{ viewLog.device.ip }}</span><span v-else>-</span></td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../../helpers/FormatHelpers";

export default {
  name: "UserAttendance",
  data() {
    return {
      contentDetail: [],
      selectedDetailViewLogs: [],
      isUserViewLog: false,
      selectedDetail: null
    };
  },
  props: { user: Object, mode: Number },
  methods: {
    ...mapActions("content", [
      "fetchContentAttendance",
      "fetchContentAttendanceDetail",
      "exportContentAttendanceReport",
      "fetchSingleLiveClassAttendance"
    ]),

    secondsToHHMMSSFormat:function (seconds) {
      if(seconds == null || seconds == undefined) {
        return "-";
      }
      else if(seconds == 0) {
        return "0 sec";
      }
			var sec_num = parseInt(seconds, 10); // don't forget the second param
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
			var seconds = sec_num - (hours * 3600) - (minutes * 60);

			return (parseInt(hours)>0 ? (hours + ' hr ') : "") + 
				   (parseInt(minutes)>0 ? (minutes + ' min ') : "") + 
				   (parseInt(seconds)>0 ? (seconds + ' sec'): "") ;
		},

    showDetail(index) {
      this.isUserViewLog = true;

      this.selectedDetail = this.contentDetail[index];
      
      if(this.selectedDetail.content.type.toLowerCase() == 'liveclass') {
        this.fetchSingleLiveClassAttendance({contentId: this.selectedDetail.content.id, userId: this.user.id})
					.then((data) => {
						if (data && data.length > 0) {
							data.sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn));
							this.selectedDetailViewLogs = data;
						} else {
              this.selectedDetailViewLogs = [];
            }
					});
        return;
      }
      this.fetchContentAttendanceDetail({
        contentID: this.contentDetail[index].content.id,
        userID: this.user.id,
      })
        .then((data) => {
          if (data && data.length > 0) {
            this.selectedDetailViewLogs = data[0].viewLogs;
          } else {
            this.contentDetail = [];
          }
        });
    },

    timeParser(date) {
      if(!date) {
        return "-";
      }
      return (
        formatter.getInputDateFormat(date) +
        " " +
        formatter.getTimeIn12Hours(date)
      );
    },

    backToList() {
      this.selectedDetailViewLogs = [];
      this.isUserViewLog = false;
    },
    parseContentParentHerarchy(parentContents) {
      if (parentContents) {
        var obj = JSON.parse(parentContents);
        var chapters = obj.filter(x => x.ParentContentID);
        var subjects = obj.filter(x => !x.ParentContentID);
        var productVariants = obj.filter(x => x.ProductVariantID && x.ProductVariantTitle).map(x => { return { ProductVariantID: x.ProductVariantID, ProductVariantTitle: x.ProductVariantTitle } });
        return { chapters, subjects, productVariants };
      }
    },
    parseProductVariants(parentContents) {
      return this.parseContentParentHerarchy(parentContents)?.productVariants.filter(x=>!this.productVariantID || x.ProductVariantID==this.productVariantID);
    },
    parseSubjects(parentContents) {
      var subjects = {};
      this.parseContentParentHerarchy(parentContents)?.subjects
        .forEach(subject => {
          subjects[subject.ID] = subject;
        });
      return Object.values(subjects);
    },
    parseChapters(parentContents) {
      var chapters = {};
      this.parseContentParentHerarchy(parentContents)?.chapters
        .forEach(chapter => {
          chapters[chapter.ID] = chapter;
        });
      return Object.values(chapters);
    }
  },
  computed:{
    productVariantID(){
      if(this.$route.meta.title.toLowerCase()=='enrollment' && this.$route.params.id)
      {
        return this.$route.params.id;
      }
    }
  },
  created() {
    this.loading = true;
    this.fetchContentAttendance({
      contentID: null,
      userID: this.user.id,
      productVariantID: this.productVariantID
    })
      .then((data) => {
        this.loading = false;
        if (data && data.length > 0) {
          this.contentDetail = data;
        } else {
          this.contentDetail = [];
        }
      })
      .catch(() => {
        this.contentDetail = [];
        this.loading = false;
      });
  },
};
</script>
<style scoped>
.page-view-icon {
  width: 30px;
  height: 30px;
}

.no-content {
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  color: #666;
  font-size: 1.125rem;
}

.set-limits-container {
  display: flex;
  flex-direction: column;
}

.right-float-label {
  position: absolute;
  right: 0;
  /* bottom: 0.5rem; */
  color: #aaa;
}

.platform-item input[type="checkbox"] {
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.125rem;
  height: 1.125rem;
}

.platform-item label {
  flex-grow: 1;
}
</style>
