<template>
	<div>	
		<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
	  />
		<div v-if="!isQuizStarted && !isQuizEnded" >
			<div class="p-5 col-lg-12 text-center exam-content" >
				<img				
					class="page-view-icon mb-3"
					src="../../assets/images/exam-test.png"
				/>
				<h2>{{ contentData.title }}
					<!-- <br/>
			<small>Exam</small> -->
					</h2>
				<div
		
			>
				<div class="text-muted" >
					<h3>
					<strong v-if="productVariantData && productVariantData.product">{{ productVariantData.product.title }} {{ productVariantData.title }}</strong><br/>
					<small>{{ dateParser(contentData.availableFrom) }} - 
						{{ dateParser(contentData.availableTo) }} <span v-if="haveTimeLimit == true">| {{ timeLimit }}</span></small>
				</h3>
				</div>

				<div class="exam-total-row d-flex justify-content-center my-4">
					<div class="col-2 exam-total-card text-center">
						<h3 class="total-card-val">{{ contentData.data.totalQuestions }}</h3>
						<div class="total-card-label text-muted">Total Questions</div>
					</div>
					<div class="col-2 exam-total-card text-center">
						<h3 class="total-card-val">{{ contentData.data.totalMarks }}</h3>
						<div class="total-card-label text-muted">Total Marks</div>
					</div>
				</div>
	
				<div class="d-flex justify-content-center exam-actions-row">
					<button 	
					v-if="!contentData.availableTo || new Date() <= new Date(contentData.availableTo)"			
					type="button" @click="startQuiz" class="primary-btn attempt-btn btn-round mx-2">
						<!-- <img class="page-view-icon" src="@/assets/images/exam-paper-white-icon.svg"/> -->
						<h4 style="margin: 0px; padding: 0px;">Start Quiz</h4>	
					</button>
				</div>
			
			</div>
		</div>
	</div>
		<div v-else-if="isQuizStarted && !isQuizEnded" class="div-box" >
		  <div class="disable-box" v-if="isTimeEnded">

		  </div>
			<div class="MainQuiz-Box">
				<b-card class="header">
					<h4>{{ contentData.title }}<br/>
					<small v-if="productVariantData">
						{{ productVariantData.product.title }} {{ productVariantData.title }}
					</small></h4>
					<div style="flex: 1;"></div>
					<h6 style="align-self: end;"><b>Question</b>  {{ currentQuestionIndex + 1 }}/{{ contentData.data.totalQuestions }}</h6>
				</b-card >
		
					<b-card class="p-3 pt-4 pb-4 center-section card-overflow-handle"  style="min-height: 40vh;">
						<div  :class="`animate__animated ${loadQuestionClass}`">
							<h3 class="mb-4" v-html="questions[currentQuestionIndex].questionText">
								
							</h3>
							<RadioButtonQuestion @setAnswer="setAnswer" :isReviewMode="isReviewMode" :answers="questions[currentQuestionIndex].answers" :correctAnswers="questions[currentQuestionIndex].correctAnswers" v-if="questions[currentQuestionIndex].type.toLowerCase() == 'radio'" :options="questions[currentQuestionIndex].options"></RadioButtonQuestion>
							<SelectButtonQuestion @setAnswer="setAnswer" :isReviewMode="isReviewMode" :answers="questions[currentQuestionIndex].answers" :correctAnswers="questions[currentQuestionIndex].correctAnswers" v-if="questions[currentQuestionIndex].type.toLowerCase() == 'select'" :options="questions[currentQuestionIndex].options"></SelectButtonQuestion>
							<TextboxQuestion @setAnswer="setAnswer" :isReviewMode="isReviewMode" :answers="questions[currentQuestionIndex].answers" :correctAnswers="questions[currentQuestionIndex].correctAnswers" v-if="questions[currentQuestionIndex].type.toLowerCase() == 'textbox'" :options="questions[currentQuestionIndex].options"></TextboxQuestion>
							<CheckboxButtonQuestion @setAnswer="setAnswer" :isReviewMode="isReviewMode" :answers="questions[currentQuestionIndex].answers" :correctAnswers="questions[currentQuestionIndex].correctAnswers" v-if="questions[currentQuestionIndex].type.toLowerCase() == 'checkbox'" :options="questions[currentQuestionIndex].options"></CheckboxButtonQuestion>
						</div>
					</b-card>
		
				<b-card >
					<div style="display: flex;">
						<button 	
						@click="previousQuestion"
						v-if="currentQuestionIndex > 0"			
						type="button" class="primary-btn attempt-btn btn-round mx-2">
							Previous Question
						</button>
						<div style="flex: 1;" class="pt-1" >
							<h3 style="text-align: center; margin:0px; font-weight: lighter;" v-if="haveTimeLimit && !isReviewMode">{{ timeFormat(currentLeftTime) }}</h3>
						</div>
						<button 	
						@click="nextQuestion"
						v-if="(currentQuestionIndex + 1) < contentData.data.totalQuestions"			
						type="button" class="primary-btn attempt-btn btn-round mx-2">
							Next Question
						</button>
						<button 	
						v-else-if="!isReviewMode"	
						@click="submitQuiz"
						type="button" class="primary-btn attempt-btn btn-round mx-2">
							Submit
						</button>
						
						<button 	
						v-else-if="isReviewMode"	
						@click="showResult"
						type="button" class="primary-btn attempt-btn btn-round mx-2">
							Show Result
						</button>
					</div>
				</b-card>
			</div>
		
		</div>
		<!--  -->
		<div  v-else-if="isQuizStarted && isQuizEnded && quizResult">
			<div class="p-5 col-lg-12 text-center  result" >
			
				<h4>{{ contentData.title }}<br/>
						<small v-if="productVariantData && productVariantData.product">
							{{ productVariantData.product.title }} {{ productVariantData.title }}
						</small></h4>
				<circle-counter 
				size="10rem" 
				dashCount="100"
				dash-spacing="0"
				stroke-width="20"
				active-width="20"
				:active-count="quizResult.overallPercentage"
				:text="quizResult.overallPercentage+'%'"
				:activeStroke="quizResult.result.toLowerCase()=='pass'?'#5cb85c':'#FF0000'"
			  >
			  </circle-counter>
			  <div class="exam-total-row d-flex justify-content-center mt-4">
				<div class="col-4 exam-total-card text-center">
					<h3 class="total-card-val">{{ quizResult.noOfCorrectQuestions ? quizResult.noOfCorrectQuestions : "0" }} <span style="font-weight: lighter;">out of</span> {{contentData.data.totalQuestions}} <span style="font-weight: lighter;">Questions</span></h3>
					<div class="total-card-label text-muted">Correct Questions</div>
				</div>
				<div class="col-2 exam-total-card text-center">
					<h3 :style="'color:'+(quizResult.result.toLowerCase()=='pass'?'green':'#FF0000')">{{quizResult.result.toLowerCase()=='pass'?'PASS':'FAIL'}}</h3>
					<div class="total-card-label text-muted">Result</div>
				</div>
				<div class="col-4 exam-total-card text-center">
					<h3 class="total-card-val">{{ quizResult.obtainedMarks }} <span style="font-weight: lighter;">out of</span> {{ contentData.data.totalMarks }}</h3>
					<div class="total-card-label text-muted">Obtained Scores</div>
				</div>
			  </div>
			  <div class="d-flex justify-content-center exam-actions-row mt-3">
				<button
					type="button" @click="attemptAgain" class="primary-btn attempt-btn btn-round mx-2">
						<!-- <img class="page-view-icon" src="@/assets/images/exam-paper-white-icon.svg"/> -->
						<h4 style="margin: 0px; padding: 0px;">Attempt Again</h4>	
				</button>
			  <button 	
				@click="reviewQuiz"			
				type="button" class="primary-btn attempt-btn btn-round mx-2">
					<!-- <img class="page-view-icon" src="@/assets/images/exam-paper-white-icon.svg"/> -->
					<h4 style="margin: 0px; padding: 0px;">Review</h4>	
				</button>
			  </div>			
			</div>	
		</div>
	</div>
</template>

<script>
import ProductVariant from "../../models/productVariant";
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import formatter from "./../../helpers/FormatHelpers";
import SelectButtonQuestion from "./Quiz/SelectButtonQuestionOptions.vue"
import TextboxQuestion from "./Quiz/TextboxQuestionOptions.vue"
import circleCounter from 'vue-circle-counter';
import RadioButtonQuestion from "./Quiz/RadioButtonQuestionOptions";
import CheckboxButtonQuestion from "./Quiz/CheckboxButtonQuestionOptions.vue";
import Swal from "sweetalert2";
export default {
	name: "Quiz",
	props: { productVariantData: ProductVariant, contentData: Object },
	data(){
		return {
			isQuizStarted:false,
			currentAttemptID:null,
			questions:[],
			currentQuestionIndex:0,
			loadQuestionClass:'',
			currentLeftTime:0,
			currentTimer:null,
			isQuizEnded:false,
			quizResult:null,
			isReviewMode:false
			
		};
	},
	components:{
		RadioButtonQuestion, circleCounter, SelectButtonQuestion, TextboxQuestion, CheckboxButtonQuestion
	},
	computed: {
		haveTimeLimit:function(){
			return this.contentData.extraProperties.HaveTimeLimit && this.contentData.extraProperties.HaveTimeLimit.toString().toLowerCase()=='true';
		},
		timeLimit:function(){
			if(this.haveTimeLimit==true){

				var seconds = this.contentData.extraProperties.Time;
				return this.timeFormat (seconds);
				
			}
		},
		isTimeEnded:function(){
			if(this.haveTimeLimit && this.currentLeftTime<=0 && !this.isReviewMode)
			{
				return true;
			}
			return false;
		}

	},

	methods:{
		
		...mapActions("content", ["getContentById"]),
		...mapActions("quiz", ["submitQuizAttempt", "getQuizDetail"]),
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		
		timeFormat(seconds)
		{
			if(seconds>0){
					const hours = Math.floor(seconds / 3600);   
					var minutes = Math.floor((seconds % 3600) / 60);
					var remainingSeconds = seconds % 60;

					return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

			}
		},

		async startQuiz(){
			await this.getContentById({
				id: this.contentData.id,
				extraParams: { Action: 'Attempt' },
			}).then(async (content) => {
				if(content.data && content.data.questions && content.data.questions.length>0){					
					this.questions=content.data.questions;
					this.currentAttemptID=content.data.quizAttemptID; 
					this.isQuizStarted=true;
					if(this.haveTimeLimit && this.contentData.extraProperties.Time>0)
					{
						this.startQuizTimer();
					}
					var queryData = this.$route.query;
					queryData["action"] = "Attempt";

					this.$router.replace({});
					this.$router.replace({ query: queryData});


				}
			});
		},
		startQuizTimer(){
			this.currentLeftTime = this.contentData.extraProperties.Time;
			var self = this;
			this.currentTimer = setInterval(function () {

				if(self._isDestroyed)
				{
					clearInterval(self.currentTimer);
					return;
				}


				self.currentLeftTime--;
				
				if(self.currentLeftTime<=0 && self.currentTimer)
				{
					clearInterval(self.currentTimer);

					self.quizTimeEnded();
					self.currentTimer=null;
				}
			}, 1000);
		},
		quizTimeEnded(){	
			if(this.currentTimer)
			{
				clearInterval(this.currentTimer);
			}		
			Swal.fire({
				title: "Time Ended",
				text: "Time's up for the quiz.",
				button: "Ok",
				dangerMode: true,
				allowOutsideClick: false,
				allowEscapeKey: false,
			}).then((confirm) => {
				if (confirm) {
					this.submitQuiz();	
				}
			});
		},
		nextQuestion(){
			this.loadQuestionClass='animate__fadeOutLeft';
			var self=this;			
			setTimeout(function () {						
				self.currentQuestionIndex++;
				self.loadQuestionClass='animate__fadeInRight';		
			}, 300);
		},
		previousQuestion(){
			this.loadQuestionClass='animate__fadeOutRight';			
			var self=this;			
			setTimeout(function () {	
				self.currentQuestionIndex--;	
				self.loadQuestionClass='animate__fadeInLeft';		
			}, 300);
		},
		setAnswer(answers){
			this.questions[this.currentQuestionIndex].answers = answers;
		},
		submitQuiz() {
			var queryData = this.$route.query;
			queryData["action"] = undefined;
			this.$router.replace({});
			this.$router.replace({ query: queryData });

			if (!this.isReviewMode) {
				if (!this.isTimeEnded) {
					Swal.fire({
						title: 'Are you sure to submit quiz?',
						showDenyButton: true,
						showCancelButton: false,
						confirmButtonText: 'Yes',
						denyButtonText: `No`,
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							this.compileQuizAndSubmitExam();
							return;
						} else if (result.isDenied) {

						}

					});
					return;
				}


				this.compileQuizAndSubmitExam();
			}
		},
		compileQuizAndSubmitExam(){
			if (this.haveTimeLimit && this.currentTimer) {
				clearInterval(this.currentTimer);
			}
			this.isQuizStarted=true;
			this.isQuizEnded=true;
			var attempQAndA = this.questions.map(x=>{ return {
					QuestionID:x.id,
					Answers:x.answers
				};} ); 
			var attemptObj ={
				AttemptID:this.currentAttemptID,
				Answers: attempQAndA
			};

			this.submitQuizAttempt(attemptObj).then((result)=>{
				if(result)
				{
					this.quizResult =  result;
				}
			});




		},
		attemptAgain(){
			
			this.currentTimer=null;
			this.isQuizStarted=false;
			this.isQuizEnded=false;
			this.currentLeftTime=0;
			this.currentQuestionIndex=0;
			this.questions=[];
			this.quizResult=null;			
		},
		reviewQuiz(questionIndex = 0){
			if (this.quizResult) {
				this.currentTimer=null;
				this.isReviewMode = true;
				this.isQuizStarted = true;
				this.isQuizEnded = false;
				this.currentQuestionIndex = !questionIndex ? 0 : this.currentQuestionIndex;
				
				this.questions=this.quizResult.quizAttemptDetails.map(x=>{
					return {
						id:x.QuestionID,
						questionText:x.questionText,
						options:JSON.parse(x.answerOptions),
						answers:JSON.parse(x.userAnswer),
						correctAnswers:JSON.parse(x.correctAnswers),
						type:x.type
					};
				});
			}
		
		}
		,
		showResult(){
			if (this.quizResult) {
				this.isReviewMode = false;
				this.isQuizStarted = true;
				this.isQuizEnded = true;				
			}
		
		}

	},
	created() {
		let isReviewMode = this.$route.query.isReviewMode;

		if(isReviewMode != null && isReviewMode != undefined && isReviewMode == 'true'){
			let attemptId = this.$route.query.attemptId;
			let questionId = this.$route.query.questionId;
			
			this.getQuizDetail(attemptId).then((res) => {
				if(res && res.length > 0) {
					this.isQuizEnded = true;
					this.isQuizStarted = true;
					this.quizResult = res[0];

					if(questionId) {
						this.currentQuestionIndex = this.quizResult.quizAttemptDetails.findIndex(x => x.id == questionId);
						this.reviewQuiz(this.currentQuestionIndex);
					}
				}
			});
			return;
		}

		if(this.contentData.extraProperties){
			this.contentData.extraProperties=JSON.parse(this.contentData.extraProperties);
		}
	},	
	beforeRouteUpdate(to, from, next) {
		debugger;
	},
	watch:{
		$route (to, from){
        	debugger;
    	}
	}
};
</script>

<style scoped>
.card-overflow-handle {
	overflow: scroll;
}
.header{
	
	border-bottom: 2px solid var(--primary-color);
}
.header .card-body{
	display: flex;
}
.disable-box{
	position: absolute;
    background-color: white;
    height: 100%;
    z-index: 9;
    width: 100%;
    opacity: 0.5;
}
</style>
<style>
.result svg text{
	font-size: 40px;
    font-weight: bolder;
}

</style>
