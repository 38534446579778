<template>
	<div>
		<div
			v-if="!createMode && mode == 'view'"
			:class="`category-collapse-button ${
				selectedCategory.find((c) => c.id == categoryData.id) ? 'active' : ''
			}`"
			@click="selectCategory"
		>
			<button
				:id="`collapse-btn-${categoryData.id}`"
				v-b-toggle="`collapse-${categoryData.id}`"
				@click="(e) => e.stopPropagation()"
			>
				<img src="@/assets/images/caret-down.svg" class="caret-icon" />
			</button>
			<div class="category-name">
				{{ categoryData.categoryName }}
			</div>
			<span class="ms-auto"
				>({{
					categoryData.questionsCount != null
						? categoryData.questionsCount + categoryData.childQuestionsCount
						: 0
				}})
			</span>
			<div v-if="!selectMode" class="right-menu">
				<a
					@click="toggleSubCategoryCreate($event, true)"
					role="button"
					class="right-menu-icon"
				>
					<img src="@/assets/images/plus-icon.svg" />
				</a>
				<a
					role="button"
					@click="changeMode($event, 'edit')"
					class="right-menu-icon"
				>
					<img src="@/assets/images/edit-icon.svg" />
				</a>
				<a
					role="button"
					@click="showDeleteConfirmPopup"
					class="right-menu-icon"
				>
					<img src="@/assets/images/delete-icon.svg" />
				</a>
			</div>
		</div>

		<b-collapse
			v-model="expanded"
			v-if="!createMode && mode == 'view'"
			:id="`collapse-${categoryData.id}`"
			class="category-collapse-body"
		>
			<div
				class="sub-category-container"
				v-if="
					expanded &&
					mode == 'view' &&
					questionCategories[productVariantId][categoryData.id]
				"
			>
				<QuestionCategoryListItem
					v-for="category in questionCategories[productVariantId][
						categoryData.id
					].childCategories"
					:key="category.id"
					:categoryData="category"
					:selectedCategory="selectedCategory"
					@selectCategory="selectChildCategory"
					:productVariantId="productVariantId"
					:parentCategoryId="categoryData.id"
					:selectMode="selectMode"
				/>
				<div
					v-if="
						questionCategories[productVariantId][categoryData.id]
							.childCategories &&
						questionCategories[productVariantId][categoryData.id]
							.childCategories.length == 0 &&
						!isSubCategoryCreate
					"
					class="no-categories mb-2"
				>
					<span> No sub categories </span>
				</div>
			</div>
			<div
				v-if="expanded && isSubCategoryCreate"
				class="sub-category-container"
			>
				<QuestionCategoryListItem
					:parentCategoryId="categoryData.id"
					:selectedCategory="selectedCategory"
					@selectCategory="selectChildCategory"
					:createMode="isSubCategoryCreate"
					:productVariantId="productVariantId"
					@cancelCreate="cancelSubCategoryCreate"
					:selectMode="selectMode"
				/>
			</div>
		</b-collapse>
		<div
			v-if="createMode || mode == 'edit'"
			class="category-collapse-button create"
		>
			<form @submit="createCategory($event)">
				<input
					type="search"
					name="categoryName"
					id="categoryName"
					class="category-input"
					v-model="categoryName"
					placeholder="Enter pool name"
					@blur="
						createMode ? $emit('cancelCreate') : changeMode($event, 'view')
					"
				/>
				<div class="ms-auto d-flex gap-3">
					<button
						@click="createCategory($event)"
						type="submit"
						class="category-action-btn save"
					>
						<img src="@/assets/images/check-icon-green.svg" alt="save" />
					</button>
					<button
						@click="changeMode($event, 'view')"
						class="category-action-btn cancel"
						type="button"
						role="button"
					>
						<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "QuestionCategoryListItem",
	data() {
		return {
			mode: "view",
			expanded: false,
			categoryName: null,
			isSubCategoryCreate: false,
		};
	},
	props: {
		categoryData: { type: Object, default: null },
		selectedCategory: Array,
		createMode: { type: Boolean, default: false },
		productVariantId: String,
		parentCategoryId: { type: String, default: null },
		selectMode: { type: Boolean, default: false },
	},
	methods: {
		...mapActions("exam", [
			"createQuestionCategory",
			"fetchQuestionCategories",
			"updateQuestionCategory",
			"deleteQuestionCategory",
		]),
		toggleCollapse(e) {
			e.stopPropagation();
			// if (
			// 	this.questionCategories[this.productVariantId] &&
			// 	!this.questionCategories[this.productVariantId][this.categoryData.id]
			// )
			if (this.expanded)
				setTimeout(() => (this.expanded = !this.expanded), 300);
			else this.expanded = !this.expanded;
		},
		toggleSubCategoryCreate(e, value) {
			e.stopPropagation();
			if (value !== undefined) {
				this.isSubCategoryCreate = value;
				this.expanded = value;
				if (value) setTimeout(() => $("input#categoryName").focus(), 100);
			} else {
				this.isSubCategoryCreate = !this.isSubCategoryCreate;
				this.expanded = !this.expanded;
				if (this.isSubCategoryCreate)
					setTimeout(() => $("input#categoryName").focus(), 100);
			}
		},
		cancelSubCategoryCreate() {
			setTimeout(() => (this.isSubCategoryCreate = false), 300);

			// if (
			// 	this.questionCategories[this.productVariantId][this.categoryData.id] &&
			// 	this.questionCategories[this.productVariantId][this.categoryData.id]
			// 		.childCategories.length == 0
			// )
			// 	setTimeout(() => (this.expanded = false), 300);
		},
		selectCategory() {
			this.$emit("selectCategory", [
				{
					id: this.categoryData.id,
					categoryName: this.categoryData.categoryName,
				},
			]);
			this.expanded = true;
		},
		selectChildCategory(category) {
			this.$emit("selectCategory", [
				{
					id: this.categoryData.id,
					categoryName: this.categoryData.categoryName,
				},
				...category,
			]);
		},
		changeMode(e, mode) {
			e.stopPropagation();
			if (mode == "view") {
				setTimeout(() => (this.mode = mode), 300);
				this.$emit("cancelCreate");
			} else if (mode == "edit") {
				this.mode = mode;
				this.categoryName = this.categoryData.categoryName;
				setTimeout(() => $("input#categoryName").focus(), 100);
			}
		},
		createCategory(e) {
			e.preventDefault();
			var parentID = this.parentCategoryId
				? this.parentCategoryId
				: this.questionCategories[this.productVariantId].root.id;

			var createCategoryObject = {
				categoryName: this.categoryName,
				parentID: parentID,
			};
			if (this.mode == "edit") {
				createCategoryObject.id = this.categoryData.id;
				createCategoryObject.parentID = this.categoryData.parentID;
				this.updateQuestionCategory({
					createCategoryObject,
					productVariantID: this.productVariantId,
				}).then((res) => {
					console.log(res);
					this.fetchQuestionCategories({
						productVariantID: this.productVariantId,
						categoryID: parentID,
					}).then((res) => {
						this.mode = "view";
					});
				});
			} else {
				this.createQuestionCategory(createCategoryObject).then((res) => {
					console.log(res);
					this.fetchQuestionCategories({
						productVariantID: this.productVariantId,
						categoryID: parentID,
					}).then((res) => {
						this.$emit("cancelCreate");
					});
				});
			}
			// this.$emit("createCategory", this.categoryName);
		},
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete the question pool: ${this.categoryData.categoryName}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.questionCategoryDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		questionCategoryDelete() {
			this.deleteQuestionCategory(this.categoryData.id).then((res) => {
				if (res) {
					var parentID = this.parentCategoryId
						? this.parentCategoryId
						: this.questionCategories[this.productVariantId].root.id;
					this.fetchQuestionCategories({
						productVariantID: this.productVariantId,
						categoryID: parentID,
					}).then((res) => {
						this.$toasted.success("Question Pool deleted!", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
					});
				}
			});
		},
	},
	computed: {
		...mapState("exam", [
			"questionCategories",
			"questionCategoriesByParent",
			"loading",
		]),
		hasChildCategories() {
			return (
				this.categoryData.childCategories &&
				this.categoryData.childCategories.length > 0
			);
		},
	},
	created() {
		this.selectedCategory.forEach((cat, index) => {
			if (
				cat.id == this.categoryData.id &&
				index != this.selectedCategory.length - 1
			) {
				console.log("expand");
				this.expanded = true;
			}
		});

		if (
			!this.createMode &&
			!this.questionCategories[this.productVariantId][this.categoryData.id]
		) {
			this.fetchQuestionCategories({
				productVariantID: this.productVariantId,
				categoryID: this.categoryData.id,
			}).then((res) => {
				console.log(res);
			});
		}
	},
	watch: {
		selectedCategory: function () {
			this.selectedCategory.forEach((cat, index) => {
				if (
					cat.id == this.categoryData.id &&
					index != this.selectedCategory.length - 1
				) {
					console.log("expand");
					this.expanded = true;
				}
			});
		},
	},
};
</script>

<style scoped>
.category-collapse-button {
	background: #ffffff;
	/* border: 1px solid #dcdcdc; */
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	margin-bottom: 0.5rem;
	color: #000000;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75rem;
	position: relative;
	cursor: pointer;
}

.category-collapse-button:hover,
.category-collapse-button.create {
	background-color: #fafafa;
}

.category-collapse-button form {
	display: flex;
	align-items: center;
	width: 100%;
}
.category-collapse-button .category-name {
	display: flex;
	align-items: center;
	flex-grow: 0;
	overflow: hidden;
}

.category-collapse-button input {
	background-color: transparent;
	border: none;
	flex-grow: 1;
}

.category-collapse-button.active {
	background-color: #fafafa;
	color: var(--primary-color);
}

.caret-icon {
	transition: transform 0.3s;
	transform: rotate(-90deg);
}

.not-collapsed .caret-icon {
	transform: rotate(0deg);
}

.category-collapse-body {
	padding-left: 1rem;
	margin-left: 1rem;
	border-left: 1px solid #dedede;
}

.category-collapse-button.no-child {
	padding-left: 2.25rem;
}

.category-collapse-button .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	right: 0.75rem;
	background: #fafafae2;
	height: 100%;
}

.category-collapse-button:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.category-action-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	/* padding: 0.375rem; */
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	transform: translateY(-50%);
}

.category-action-btn.save {
	border: 1px solid #21cf47;
	color: #21cf47;
	position: absolute;
	right: 3rem;
}
.category-action-btn.cancel {
	border: 1px solid #ff0000;
	color: #ff0000;
	position: absolute;
	right: 0.75rem;
}

.right-menu-icon {
	color: #000000;
	line-height: 1;
}
</style>
