<template>
  <div>
    <b-form-group >
      <h5   v-for="option in options" :class="'radio-inputs '+(isReviewMode && isCorrect(option)?' correct':(isReviewMode && isInCorrect(option)?' incorrect':''))" >
        <b-form-radio :disabled="isReviewMode" v-model="selectedAnswer"  class="quiz-radio" :value="option.identifier"> <span class="input-option" v-html="option.option"></span> </b-form-radio>
      </h5>
    </b-form-group>
  </div>
</template>

<script>
  export default {
    name: 'RadioButtonQuestion',
    data() {
      return {
        selectedAnswer:null
      }
    },
    props:{
      isReviewMode:Boolean,
      options:Array,
      answers:Array,
      correctAnswers:Array
    },
    computed: {},
    mounted() {},
    methods: {
      isCorrect(option)
      {
        if(this.isReviewMode && this.correctAnswers)
        {
          return this.correctAnswers.indexOf(option.identifier)>-1;
        }
        return false;
      },
      isInCorrect(option){
        if(this.isReviewMode && !this.isCorrect(option) && this.answers)
        {
          return this.answers.indexOf(option.identifier)>-1;
        }
        return false;
      }
    },
    created(){
      if(this.answers && this.answers.length>0)
        {
          this.selectedAnswer= this.answers[0];
        }
    },
    watch:{
      selectedAnswer:function(){
        this.$emit("setAnswer", [this.selectedAnswer]);
      },
      answers:function(){
        if(this.answers && this.answers.length>0)
        {
          this.selectedAnswer= this.answers[0];
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-option{
  margin-left: 10px;
  font-weight: lighter;
}
.radio-inputs{
  max-width: 40%;
  padding: 5px;
  border-radius: 10px;

}
.radio-inputs.correct{
  background: #ddf1dd;

}
.radio-inputs.incorrect{  
  background: #fed2d2;

}

</style>

<style>

.quiz-radio input[type=radio]{
  accent-color: var(--primary-color);
}

.radio-inputs .custom-control-label{
  margin: 0px;
}
</style>