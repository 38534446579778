<template>
	<div class="create-category-container">
		<a
			data-bs-toggle="dropdown"
			id="add-dropdown-btn"
			class="add-btn dropdown-toggle"
			role="button"
			@click="showDropdown"
		>
			<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
			add qualification
		</a>
		<div
			id="add-dropdown"
			class="dropdown-menu dropdown-menu-end"
			aria-labelledby="dropdownMenuLink"
		>
			<form @submit="addCategory">
				<input
					type="search"
					class="create-category-input"
					v-model="categoryTitle"
					placeholder="Enter qualification title (required)"
					id="create-category-input"
					required
					v-on:keyup.esc="hideDropdown"
				/>
				<button
					@click="addCategory"
					type="submit"
					class="content-action-btn save"
				>
					<img src="@/assets/images/check-icon-green.svg" alt="save" />
				</button>
				<button
					@click="hideDropdown"
					class="content-action-btn cancel"
					type="button"
					role="button"
				>
					<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "CreateCategoryDropdown",
	data() {
		return {
			categoryTitle: "",
		};
	},
	methods: {
		...mapActions("category", ["createCategory"]),
		addCategory(e) {
			e.preventDefault();
			this.categoryTitle = this.categoryTitle.trim();
			if (this.categoryTitle.length > 0 && !this.loading) {
				this.createCategory(this.categoryTitle).then(() => {
					this.categoryTitle = "";
					setTimeout(
						() =>
						document.querySelector('.category-list-item:last-of-type').scrollIntoView({
									behavior: "smooth",
								}),
						300
					);
					// console.log("category created");
					// document.getElementById("add-dropdown-btn").click();
				});

				// console.log("CreateCategory called");
			} else {
				document.getElementById("add-dropdown-btn").click();
			}

			// this.categoryTitle = "";
		},
		showDropdown() {
			console.log("dropdown toggled");
			document.getElementById("create-category-input").focus();
		},
		hideDropdown() {
			document.getElementById("add-dropdown-btn").click();
		},
	},
	computed: {
		...mapState("category", ["loading"]),
		showSave() {
			return this.categoryTitle !== "";
		},
	},
};
</script>

<style scoped>
.create-category-container {
	margin-left: auto;
}

.add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.add-btn::after {
	display: none !important;
}

.create-category-input {
	height: 3.125rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.875rem 1rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
	padding-right: 5.25rem;
}

#add-dropdown {
	padding: 1rem;
	border-radius: 8px;
	width: 22.625rem;
}

#add-dropdown form {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.content-action-btn {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 50%;
	width: 1.625rem;
	height: 1.625rem;
	/* padding: 0.375rem; */
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0.875rem;
}

.content-action-btn.save {
	border: 1px solid #21cf47;
	color: #21cf47;
	position: absolute;
	right: 3rem;
}
.content-action-btn.cancel {
	border: 1px solid #ff0000;
	color: #ff0000;
	position: absolute;
	right: 0.75rem;
}
</style>
