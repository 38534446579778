<template>
	<b-card
		no-body
		:class="{
			active: accordianActive == section.id,
			'card-margin': viewMode != 'sidebar' && viewMode != 'nested',
		}"
	>
		<b-card-header
			header-tag="header"
			role="tab"
			v-b-toggle="'accordion-' + section.id"
			:id="section.id"
			@click="accordianClick"
			class="d-flex"
		>
			<span
				v-if="viewMode == 'sidebar' || viewMode == 'nested'"
				v-html="headerIcon"
				class="header-icon"
			></span>
			<span class="header-icon" v-else>{{ headerIcon }}</span
			>{{ section.title }}
		</b-card-header>
		<b-collapse
			:id="'accordion-' + section.id"
			:accordion="viewMode == 'nested' ? `nested-accordion` : `main-accordion`"
			role="tabpanel"
			:visible="isFirstAccordian || isExpandedAccordian"
			appear
		>
			<ProductContentList
				v-if="contentList"
				:contentData="contentData"
				:purchased="purchased"
				:viewMode="viewMode"
				:productVariantId="productVariantId"
				:parentContentId="section.id"
			/>
			<b-card-body v-else>
				<b-skeleton width="100%" height="2rem" class="mt-3"></b-skeleton>
				<b-skeleton width="100%" height="2rem" class="mt-3"></b-skeleton>
				<b-skeleton width="100%" height="2rem" class="mt-3"></b-skeleton>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductContentList from "./ProductContentList.vue";

export default {
	name: "ProductContentAccordian",
	props: {
		accordianActive: String,
		section: Object,
		isFirstAccordian: Boolean,
		viewMode: String,
		purchased: Boolean,
		productVariantId: String,
	},
	components: { ProductContentList },
	data() {
		return {
			id: "",
			isAccordianLoaded: this.isFirstAccordian ? true : false,
			contentData: null,
			isExpandedAccordian: false,
		};
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		accordianClick(e) {
			if (this.viewMode == "nested") {
				this.$emit("nestedAccordianClick", e);
			} else this.$emit("accordianClick", e);
		},
		// ...mapActions("content", ["getContentById"]),
	},
	computed: {
		...mapState("content", ["content"]),
		...mapState("content", ["loading"]),
		contentList() {
			if (this.content[this.id]) {
				// console.log({
				// 	msg: "accordian content found in store",
				// 	currentID: this.id,
				// });
				this.isAccordianLoaded = true;
				this.contentData = this.content[this.id].children;
				this.$nextTick(() => {
					window.postMessage({ event: "content-accordian-loaded", data: null });
				});
				return true;
			} else {
				// console.log({ msg: "accordian content not found in store" });
				if (this.accordianActive == this.id && this.loading == false) {
					this.getContentById({ id: this.id }).then(() => {
						this.isAccordianLoaded = true;
						this.contentData = this.content[this.id].children;
						this.$nextTick(() => {
							window.postMessage({
								event: "content-accordian-loaded",
								data: null,
							});
						});
					});
				}
				return false;
			}
		},
		headerIcon() {
			if (this.viewMode == "sidebar" || this.viewMode == "nested") {
				if (this.accordianActive == this.section.id) {
					return '<i class="fas fa-caret-up"></i>';
				} else return '<i class="fas fa-caret-down"></i>';
			} else {
				if (this.accordianActive == this.section.id) {
					return "-";
				} else return "+";
			}
		},
	},
	created() {
		this.id = this.section.id;
		console.log("Accordian loaded", this.section.id);
	},
	mounted() {
		if (
			this.$route.query.chapterId &&
			this.$route.query.chapterId == this.section.id
		) {
			this.isExpandedAccordian = true;
			$(`#${this.section.id}`)[0].click();
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.product-content-accordian .card-header,
	.product-content-accordian .card.active .card-header,
	.product-content-accordian .card.active .card-body {
		border-radius: 0 !important;
		border: 0 !important;
		border-bottom: 2px solid var(--color-light-gray) !important;
		box-shadow: none !important;
	}

	.card-margin {
		margin-bottom: 0 !important;
	}

	.product-content-accordian .card {
		border: 0 !important;
	}

	.product-content-accordian .nested-content-accordian .card-header,
	.product-content-accordian
		.nested-content-accordian
		.card.active
		.card-header {
		border: 0 !important;
	}
}

.card-margin {
	margin-bottom: 1rem;
}

.product-content-accordian .card-header {
	background-color: var(--color-white);
	cursor: pointer;
	text-align: left;
	padding: 0.875rem 1.125rem !important;
	color: var(--text-color-default);
	/* border-bottom: 0 !important; */
	font-weight: 500;
	border-radius: 0.5rem;
	border: 2px solid var(--color-light-gray);
	font-size: 1.125rem;
}

.product-content-accordian .card.active .card-header {
	background-color: #f8f8f8;
	/* color: var(--color-white); */
	/* border-color: var(--secondary-color) !important; */
	border-radius: 0.5rem 0.5rem 0 0;
}

.product-content-accordian .card {
	border: none;
	box-shadow: 0 3px 6px #d5d5d529;
}

.product-content-accordian .card.active .card-body {
	border: 2px solid var(--color-light-gray);
	border-top: 0;
	border-radius: 0 0 0.5rem 0.5rem;
}

.product-content-accordian .card-header .header-icon {
	display: inline-flex;
	width: 1.75rem;
	height: 1.75rem;
	background-color: var(--secondary-color);
	border-radius: 50%;
	margin-right: 1.25rem;
	/* border: 2px solid var(--primary-color); */
	color: var(--color-white);
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	font-weight: normal;
	margin-top: 0;
	pointer-events: none;
}

.sidebar-content-accordian .card {
	border: none !important;
	padding: 0 1rem !important;
}

.sidebar-content-accordian .card-header {
	cursor: pointer;
	padding: 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: 500;
}

.sidebar-content-accordian .header-icon {
	margin-right: 1rem;
}

.nested-content-accordian .card-header {
	background-color: #fff !important;
	padding: 0 !important;
	border: none !important;
}

.nested-content-accordian .card {
	border: none;
	box-shadow: none !important;
}

.nested-content-accordian .card.active .card-body {
	border: none !important;
}

.nested-content-accordian .card-body {
	border: none !important;
}

.nested-content-accordian .header-icon {
	background-color: transparent !important;
	color: #000 !important;
}

.content-item-list {
	list-style: none;
	font-size: 0.85rem;
	padding: 0;
	margin-bottom: 0;
}

.content-item-list li {
	padding: 0.5rem 0.75rem;
	border-bottom: 1px solid var(--color-gray);
	color: var(--secondary-color);
	display: flex;
	flex-direction: row;
}
</style>
