class PlatformText {
	static DESKTOP_WEB = "Desktop | Chrome";
	static MOBILE_WEB = "Mobile | Chrome";
	static ANDROID = "Android | App";
	static IOS = "iPhone/iPad | App";
	static DESKTOP = "Desktop/Macbook | App";
}

class Platform {
	static DESKTOP_WEB = "desktop_web";
	static MOBILE_WEB = "mobile_web";
	static ANDROID = "android";
	static IOS = "ios";
	static DESKTOP = "desktop";
}

class Platforms {
	static [Platform.DESKTOP_WEB] = PlatformText.DESKTOP_WEB;
	static [Platform.MOBILE_WEB] = PlatformText.MOBILE_WEB;
	static [Platform.ANDROID] = PlatformText.ANDROID;
	static [Platform.IOS] = PlatformText.IOS;
	static [Platform.DESKTOP] = PlatformText.DESKTOP;
}

export { Platform, PlatformText, Platforms };
