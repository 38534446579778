<template>
	<b-modal
		id="bulk-user-upload-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="bulk-user-upload-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-enroll-title">Bulk Upload Users</h1>
			<div class="d-flex gap-2 mb-4 mt-4">
				<a
					type="button"
					class="add-btn"
					href="https://gocbe-lms-files.b-cdn.net/LMSv4_Bulk_Users_Upload_Template_Updated.xlsx"
					download="https://gocbe-lms-files.b-cdn.net/LMSv4_Bulk_Users_Upload_Template_Updated.xlsx"
					target="_blank"
				>
					<i class="fas fa-arrow-to-bottom me-2"></i> Download Template
				</a>
				<input
					type="file"
					id="import_csv"
					hidden
					@input="getFile"
					@change="getFile"
					accept=".xls,.xlsx"
				/>
				<vue-tel-input
					ref="phone_validate"
					defaultCountry="PK"
					:inputOptions="telInputOptions"
					validCharactersOnly
					v-model="phoneInput"
					@input="validate"
					:autoFormat="true"
					mode="national"
					id="phone_validate"
					hidden
				></vue-tel-input>
				<button class="add-btn" @click="importFile">
					<i class="fas fa-arrow-to-top me-2"></i> Upload Excel File
				</button>
			</div>
			<table
				v-if="userImportData"
				class="table table-hover table-borderless enrolled-users-table"
			>
				<thead>
					<th class="col-1">S.No #</th>
					<th>Name</th>
					<th>Phone</th>
					<th>Email</th>
					<th>Username</th>
					<th class="col-1">Password</th>
					<th class="col-1">Type</th>
					<th class="col-1">Status</th>
				</thead>
				<tbody>
					<tr v-for="(user, index) in userImportData" :key="index">
						<td v-b-tooltip.hover class="col-1">
							{{ (index + 1).toString().padStart(3, "0") }}
						</td>
						<td v-b-tooltip.hover :title="user.FirstName + ' ' + user.LastName">
							{{ user.FirstName }} {{ user.LastName }}
						</td>
						<td v-b-tooltip.hover :title="user.Phone" class="col-2">
							{{ user.Phone }}
						</td>
						<td v-b-tooltip.hover :title="user.Email">{{ user.Email }}</td>
						<td v-b-tooltip.hover :title="user.Username">
							{{ user.Username }}
						</td>
						<td
							class="col-1 text-center"
							v-b-tooltip.hover
							:title="user.Password"
						>
							****
						</td>
						<td class="col-1">{{ user.Type }}</td>
						<td
							v-if="usersGenerating"
							class="col-1 text-center"
							v-b-tooltip.hover
							:title="userStatusTitle(index)"
						>
							<div
								v-if="userCreateResponse[index]"
								:class="`status-tag ${isSuccess(index) ? 'success' : 'danger'}`"
							>
								{{ userStatusText(index) }}
							</div>
							<div v-else><b-spinner small></b-spinner></div>
						</td>
						<td
							v-else
							class="col-1 text-center"
							v-b-tooltip.hover
							:title="validationStatusTitle(index)"
						>
							<div
								v-if="userValidationStatus[index]"
								:class="`status-tag ${
									isValidationSuccess(index) ? 'success' : 'danger'
								}`"
							>
								{{ validationStatusText(index) }}
							</div>
							<div v-else><b-spinner small></b-spinner></div>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-else>
				<!-- <p class="text-danger">
					Please read the below instructions very carefully to avoid any
					unexpected behavior
				</p> -->
				<h4 class="text-danger">Instructions</h4>
				<p class="text-danger" style="font-size: 1.125rem">
					<strong>1.</strong> Email, Phone, Username, Password, FirstName,
					LastName, Type, CountryCode and City are mandatory fields.<br />
					<strong>2.</strong> BatchIDs field is optional, if you pass any Batch
					IDs, the student will be enrolled in those batches. If there are
					multiple batches in which you want to enroll student, then, provide
					comma-separated batch IDs. Example: 3231,7623.<br />
					<strong>3.</strong> Please ensure that Email, Phone and Username
					fields must be unique. If a user already exists with same email, phone
					number or username then the row will not be inserted as user already
					exists in the system.<br />
					<strong>4.</strong> Make sure Email is in correct format. Phone number
					must start with "+", followed by country code and then mobile number.
					Example: +923215544331.<br />
					<strong>5.</strong> Do not change column names in the template
					file.<br />
					<strong>6.</strong> Value in the Type column must be "Student".<br />
					<strong>7.</strong> If you have any custom fields enabled in the
					system, then add those columns in the last. If you are unsure about
					this, contact our support team.<br />
				</p>
			</div>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					{{ isUploadComplete ? "close" : "cancel" }}
				</button>
				<button
					v-if="!isUploadComplete"
					:disabled="disableNext() || loading"
					@click="uploadUsers()"
					role="button"
					class="step-btn"
				>
					<b-spinner small v-if="loading"></b-spinner>
					Upload
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import csvToJSON from "../../../utils/csv-reader";
import XLSXtoJSON from "../../../utils/xlsx-reader";
const getCountryISO3 = require("country-iso-2-to-3");
import Vue from "vue";
import { UserType } from "../../../constants/user";

export default {
	name: "BulkUserUploadModal",
	data() {
		return {
			userImportData: null,
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: 10,
				placeholder: "Phone Number (required)",
				mode: "national",
			},
			phoneInput: null,
			isPhoneError: false,
			extraFields: null,
			userRegisterFields: [
				"FirstName",
				"LastName",
				"Username",
				"Email",
				"Password",
				"Phone",
				"Type",
				"CountryCode",
				"City",
				"ProfilePicture",
			],
			extraproperties: { CustomFields: {} },
			userCreateResponse: [],
			userValidationStatus: [],
			usersGenerating: false,
			isValidationError: false,
			isValidating: false,
			isUploadComplete: false,
		};
	},
	props: {
		showModal: Boolean,
	},
	methods: {
		...mapActions("user", ["createUser", "enrollUserInProductVariant"]),
		...mapActions("productVariant", ["fetchProductVariants"]),
		importFile() {
			document.querySelector("#import_csv").click();
		},
		getFile(evt) {
			console.log(evt);
			this.userCreateResponse = [];
			this.userValidationStatus = [];
			let file = evt.target.files[0];
			let reader = new FileReader();

			// XLSXtoJSON(file);
			reader.onload = async (event) => {
				// console.log(event);
				var data = event.target.result;
				var workbook = XLSX.read(data, {
					type: "binary",
				});

				var sheetsJSONArray = [];

				workbook.SheetNames.forEach(function (sheet) {
					let rowObject = XLSX.utils.sheet_to_row_object_array(
						workbook.Sheets[sheet]
					);
					sheetsJSONArray.push(rowObject);
					// console.log(rowObject);
				});
				this.userImportData = sheetsJSONArray[0];
				this.isValidating = true;
				//var isValid = await this.validateJSON();
        var isValid = true;
				console.log(isValid);
				this.isValidating = false;
				this.isValidationError = !isValid;
				// this.createUsersAndEnrollBatches();
			};

			reader.readAsBinaryString(file);
			document.querySelector("#import_csv").value = "";

			// console.log(files);
			// reader.onload = ((_) => (e) => {
			// 	console.log(e.target.result);
			// 	this.userImportData = e.target.result;
			// 	this.userImportData = csvToJSON(this.userImportData);
			// 	// this.extractExtraFields();

			// })(file);

			// reader.readAsText(file);
		},
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		validateJSON() {
			return new Promise((resolve, reject) => {
				try {
					this.userImportData.forEach((user, index) => {
						this.validateUsername(user, index);
						this.validateEmailFormat(user, index);
						this.validatePassword(user, index);
						this.validateType(user, index);
						this.validateCountryCode(user, index);
						this.validatePhoneNumber(user, index);
						this.validateRequiredFields(user, index);
						// this.validateBatchIDs(user, index);
						if (!this.userValidationStatus[index]) {
							Vue.set(this.userValidationStatus, index, {
								validationMessages: ["User Data is valid"],
								validationSuccess: true,
							});
						}
					});
					this.userValidationStatus.forEach((user) => {
						console.log(user);
						if (!user.validationSuccess) {
							resolve(false);
						}
					});
					resolve(true);
				} catch (e) {
					alert(e);
				}
			});
		},
		extractExtraFields(type) {
			this.extraFields = Object.keys(this.userImportData[0]).filter(
				(item) => !this.userRegisterFields.includes(item)
			);
			var extraFieldsFromSystemParams = this.parsedExtraFields(type).map(
				(item) => item.Key
			);
			this.extraFields = this.extraFields.filter((item) =>
				extraFieldsFromSystemParams.includes(item)
			);
		},
		validate(num, obj) {
			console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;

				return;
			}
			this.isPhoneError = false;
			if (obj.country && obj.country.iso2) {
				// this.countryCode = getCountryISO3(obj.country.iso2);
				this.phoneInput = obj.number;
			}
			return false;
		},
		async createUsersAndEnrollBatches() {
			this.usersGenerating = true;
			for (var index = 0; index < this.userImportData.length; index++) {
				let item = this.userImportData[index];
				var createUserObject = {
					firstName: item.FirstName.toString().trim(),
					lastName: item.LastName.toString().trim(),
					username: item.Username.toString().trim(),
					email: item.Email.toString().trim(),
					password: item.Password.toString().trim(),
					type: item.Type.toString().trim(),
					countryCode: item.CountryCode.toString().trim(),
					city: item.City.toString().trim(),
					mobile: item.Phone.toString().trim(),
				};
				this.extractExtraFields(item.Type);
				if (this.hasExtraFields(item.Type)) {
					this.extraproperties = { CustomFields: {} };
					this.extraFields.forEach(
						(field) => (this.extraproperties.CustomFields[field] = item[field])
					);
					createUserObject.extraproperties = JSON.stringify(
						this.extraproperties
					);
				}
				var user=null;
				await this.createUser(createUserObject)
					.then(async (res) => {
						if (res) {
							// this.userCreateResponse[index] = {
							// 	createSuccess: true,
							// 	createMessage: "User created successfully",
							// };
                            user=res;
							Vue.set(this.userCreateResponse, index, {
								createSuccess: true,
								createMessage: "User created",
							});					
						}
					})
					.catch((error) => {

						if (error && error.errorCode && error.errorCode == 116) {
							// User Already Exist
							user=error.user;
							Vue.set(this.userCreateResponse, index, {
								createSuccess: true,
								createMessage: "User created successfully",
							});		

						} else {
							Vue.set(this.userCreateResponse, index, {
								createSuccess: false,
								createMessage: error?.message ?? "Some unknown error occurred",
							});
						}
					});
                    
					if (item.BatchIDs && user) {
								item.BatchIDs = item.BatchIDs.split(",");
								for (var batch of item.BatchIDs) {
									batch = batch.trim();
									if (!this.productVariants.items[batch]) continue;
									var requestObj = {
										userID: user.id,
										productVariantID: batch,
										allowedRanges: [
											{
												startsFrom: this.productVariants.items[batch].startDate
													? this.productVariants.items[batch].startDate
													: new Date("12/31/1970").toISOString(),
												endsAt: this.productVariants.items[batch].endDate
													? this.productVariants.items[batch].endDate
													: new Date("12/31/2070").toISOString(),
											},
										],
									};
									await this.enrollUserInProductVariant({
										user: user,
										requestObj,
									})
										.then((response) => {
											Vue.set(this.userCreateResponse, index, {
												...this.userCreateResponse[index],
												enrollSuccess: true,
												enrollMessage: "Batches enrolled Successfully",
											});
										})
										.catch((err) => {
											console.log(err);
											Vue.set(this.userCreateResponse, index, {
												...this.userCreateResponse[index],
												enrollSuccess: false,
												enrollMessage:
													"Enrollment failed for batch: " +
													this.productVariants.items[batch].title,
											});
										});
								}
							}
 

			}
			// this.userImportData.forEach(async (item, index) => {});
		},
        


		hasExtraFields(type) {
			if (
				(this.systemParameters.UserRegisterExtraFields &&
					type.toLowerCase() == "student") ||
				(this.systemParameters.TrainerUserRegisterExtraFields &&
					type.toLowerCase() == "trainer")
			)
				return true;
			else return false;
		},
		parsedExtraFields(type) {
			// console.log(this.systemParameters);
			if (
				this.systemParameters &&
				this.systemParameters.UserRegisterExtraFields &&
				type.toLowerCase() == "student"
			) {
				// console.log("user type student");
				return this.systemParameters.UserRegisterExtraFields;
			}
			if (
				this.systemParameters &&
				this.systemParameters.TrainerUserRegisterExtraFields &&
				type.toLowerCase() == "trainer"
			) {
				// console.log("user type TRAINER");
				return this.systemParameters.TrainerUserRegisterExtraFields;
			}
			return [];
		},
		userStatusText: function (index) {
			return this.userCreateResponse[index]?.createSuccess
				? "Success"
				: "Failed";
		},
		userStatusTitle: function (index) {
			return this.userCreateResponse[index]?.createMessage ?? "Loading...";
		},
		isSuccess: function (index) {
			return this.userCreateResponse[index]
				? this.userCreateResponse[index].createSuccess &&
						this.userCreateResponse[index].enrollSuccess
				: false;
		},
		validationStatusText: function (index) {
			return this.userValidationStatus[index]?.validationSuccess
				? "OK"
				: "Error";
		},
		validationStatusTitle: function (index) {
			return (
				this.userValidationStatus[index]?.validationMessages
					.toString()
					.split(",")
					.join(", ") ?? "Loading..."
			);
		},
		isValidationSuccess: function (index) {
			return this.userValidationStatus[index]
				? this.userValidationStatus[index].validationSuccess
				: false;
		},
		validateRequiredFields(user, index) {
			this.userRegisterFields.forEach((field) => {
				if ((!user[field] || user[field] == "") && field != "ProfilePicture") {
					if (!this.userValidationStatus[index]) {
						this.userValidationStatus[index] = {};
					}
					this.userValidationStatus[index].validationSuccess = false;

					this.userValidationStatus[index].validationMessages
						? this.userValidationStatus[index].validationMessages.push(
								field + " is required"
						  )
						: (this.userValidationStatus[index].validationMessages = [
								field + " is required",
						  ]);
				}
			});
		},
		validatePhoneNumber(user, index) {
			setTimeout(() => {
				if (!user.Phone.includes("+")) {
					user.Phone = "+" + user.Phone;
				}
				this.phoneInput = user.Phone;
			});
		},
		validateEmailFormat(user, index) {
			if (user.Email) {
				var emailFormat = new RegExp(
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
				);
				var isValid = emailFormat.test(user.Email);
				console.log(isValid);
				if (!isValid) {
					if (this.userValidationStatus[index]) {
						this.userValidationStatus[index].validationMessages.push(
							"Invalid Email"
						);
						return;
					}
					Vue.set(this.userValidationStatus, index, {
						validationSuccess: false,
						validationMessages: ["Invalid Email"],
					});
				}
				return emailFormat.test(user.Email);
			}
		},
		validateUsername(user, index) {
			if (!user.Username) {
				if (user.FirstName && user.LastName)
					var length = 4,
						charset =
							"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
						randomCode = "";
				for (var i = 0, n = charset.length; i < length; ++i) {
					randomCode += charset.charAt(Math.floor(Math.random() * n));
				}
				user.Username =
					user.FirstName.trim().replace(/ +/g, "_").toLowerCase() +
					"_" +
					user.LastName.trim().replace(/ +/g, "_").toLowerCase() +
					"_" +
					randomCode;
			}
		},
		validatePassword(user, index) {
			if (!user.Password) {
				var length = 4,
					charset = "0123456789",
					randomCode = "";
				for (var i = 0, n = charset.length; i < length; ++i) {
					randomCode += charset.charAt(Math.floor(Math.random() * n));
				}
				user.Password = randomCode;
			}
		},
		validateType(user, index) {
			if (!user.Type) {
				user.Type = UserType.STUDENT;
				return;
			}
			var found = Object.values(UserType).find(
				(type) => type.toLowerCase() == user.Type.toLowerCase()
			);
			if (!found) {
				if (this.userValidationStatus[index]) {
					this.userValidationStatus[index].validationMessages.push(
						"Invalid Type, type should be one of Student, Trainer, Management"
					);
					return;
				}
				Vue.set(this.userValidationStatus, index, {
					validationSuccess: false,
					validationMessages: [
						"Invalid Type, type should be one of Student, Trainer, Management",
					],
				});
			}
		},
		validateCountryCode(user, index) {
			if (!user.CountryCode) {
				// Code to add country code from phone number
				return;
			}
			if (user.CountryCode.length && user.CountryCode.length < 3) {
				if (this.userValidationStatus[index]) {
					this.userValidationStatus[index].validationMessages.push(
						"Invalid CountryCode. It should be a 3 letter ISO code"
					);
					return;
				}
				Vue.set(this.userValidationStatus, index, {
					validationSuccess: false,
					validationMessages: [
						"Invalid CountryCode. It should be a 3 letter ISO code",
					],
				});
			}
		},
		validateBatchIDs(user, index) {
			var batches = user.BatchIDs.split(",");
			batches.forEach((batch) => {
				if (!this.productVariants.items[batch]) {
					if (this.userValidationStatus[index]) {
						this.userValidationStatus[index].validationMessages.push(
							"Batch " + batch + "does not exist"
						);
						return;
					}
					Vue.set(this.userValidationStatus, index, {
						validationSuccess: false,
						validationMessages: ["Batch " + batch + "does not exist"],
					});
				}
			});
		},
		disableNext() {
			return !this.userImportData || this.isValidationError;
		},
		uploadUsers() {
			this.createUsersAndEnrollBatches().then((res) => {
				this.isUploadComplete = true;
			});
		},
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		...mapState("productVariant", ["productVariants"]),
		...mapState("user", ["loading"]),
		visible() {
			return this.showModal;
		},
	},
	watch: {
		visible: function () {
			if (this.visible) this.fetchProductVariants();
		},
		phoneInput: function () {
			console.log(this.phoneInput);
			// this.$nextTick(function () {
			// 	this.$refs.phone_validate.onInput();
			// });
		},
	},
};
</script>

<style>
.bulk-user-upload-modal {
	min-height: 45.375rem;
	width: 70vw;
}

.bulk-user-upload-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}
.bulk-user-upload-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 100%;
}

.bulk-user-upload-modal .add-enroll-title {
	text-transform: capitalize;
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

.bulk-user-upload-modal .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.bulk-user-upload-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.bulk-user-upload-modal .add-btn {
	text-decoration: none !important;
	color: #000000;
}

.bulk-user-upload-modal .enrolled-users-table th {
	padding: 8px !important;
}

.bulk-user-upload-modal .enrolled-users-table tbody {
	display: block;
	height: 50vh;
	overflow: auto;
}

.bulk-user-upload-modal .enrolled-users-table tbody td {
	text-overflow: ellipsis;
	overflow: hidden;
}

.bulk-user-upload-modal .enrolled-users-table thead,
.bulk-user-upload-modal .enrolled-users-table tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}
</style>
