<template>
	<div class="profile-page-banner">
		<div class="container">
			<a role="button" class="edit-button"
				><i class="fas fa-pencil-alt edit-button-icon"></i
			></a>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProfileBanner",
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
	.profile-page-banner {
		height: 15vh !important;
	}
}

.profile-page-banner {
	background-color: #bababa;
	height: 35vh;
	max-height: 27.5rem;
	/* width: 100vw; */
}

.edit-button {
	width: 3.125rem;
	height: 3.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border-radius: 50%;
	margin-left: auto;
	margin-right: 1.7rem;
	margin-top: 1.125rem;
	text-decoration: none;
}

.edit-button-icon {
	font-size: 1.25rem;
	color: var(--primary-color);
}
</style>
