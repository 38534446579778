<template>
	<div class="admin-header" v-if="this.$route.meta.isHideAdminLayout!=true">
		<router-link
			to="/management/course"
			:class="['sidebar-header', { collapsed: collapsed }]"
		>
			<img
				:src="logoPath"
				:class="`sidebar-header-logo${
					systemParameters.tenantName ? ' w-name' : ''
				}`"
			/>
			<span class="ms-3" v-if="!collapsed">
				{{ systemParameters.tenantName ? systemParameters.tenantName : "" }}
			</span>
		</router-link>
		<div class="header-container">
			<a
				v-if="this.$route.meta.isAdmin"
				role="button"
				class="my-nav-link"
				@click="$emit('toggleSidebar')"
				><img
					src="@/assets/images/menu-icon-admin-header.png"
					class="menu-icon"
			/></a>
			<div v-if="showTabs" class="header-tabs">
				<span class="product-variant-title">{{ productVariantTitle }}</span>
				<ul class="inline-list">
					<li class="header-tab">
						<router-link :to="`/management/course/${productVariantId}/content`"
							>Course Content</router-link
						>
					</li>
					<li
						v-if="
							this.externalServicesList &&
							this.externalServicesList[ContentType.EXAM] &&
							this.externalServicesList[ContentType.EXAM].find(x=>x.serviceProvider.toLowerCase()=='es')
						"
						class="header-tab"
					>
						<router-link
							:to="`/management/course/${productVariantId}/questionbank`"
							>Question Bank</router-link
						>
					</li>
					<li class="header-tab">
						<router-link :to="`/management/course/${productVariantId}/feedback`"
							>Feedback</router-link
						>
					</li>
					<li
						v-if="(user.role || user.type).toLowerCase() == 'management'"
						class="header-tab"
					>
						<router-link
							:to="`/management/course/${productVariantId}/enrollment`"
							>Enrollment</router-link
						>
					</li>
					<li
						v-if="(user.role || user.type).toLowerCase() == 'management'"
						class="header-tab"
					>
						<router-link :to="`/management/course/${productVariantId}/pricing`">
							Pricing</router-link
						>
					</li>
				</ul>
			</div>
			<div v-else class="searchbar">
				<input
					type="search"
					autocomplete="off"
					placeholder="Find your course here..."
					v-model="searchText"
					@focus="showDropdown = true"
					@blur="closeDropdown"
				/>
				<a role="button"
					><img class="search-icon" src="@/assets/images/search-icon.svg"
				/></a>
				<div class="search-dropdown" v-if="showDropdown">
					<ul v-if="searchList.length > 0">
						<li
							@click="handleLinkClick(item)"
							v-for="(item, index) in searchList"
							:key="index"
						>
							<img
								class="searchbar-image"
								src="@/assets/images/sample-thumbnail.jpeg"
								alt=""
							/>
							{{ item.product && item.product.title }} - {{ item.title }} <br />
							<!-- {{ item.creator.name }} -->
						</li>
					</ul>
					<div
						v-if="searchList.length == 0 && searchText.length > 0"
						class="no-content p-4 text-center text-muted"
					>
						Sorry! No relevant results found.
					</div>
				</div>
			</div>
			<div class="right-nav">
				<button
					@click="showPDF"
					v-if="
						loggedIn &&
						(systemParameters.AdminKnowledgeBase ||
							systemParameters.TrainerKnowledgeBase)
					"
					class="my-nav-link desktop-only"
				>
					Knowledgebase
				</button>
				<router-link
					v-if="isNonAdminPage"
					to="/management/course"
					class="my-nav-link desktop-only"
				>
					<i class="fa fa-arrow-left pe-2"></i> Back to Admin Panel
				</router-link>
				<div
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					class="user-profile-btn my-nav-link dropdown-toggle"
				>
					<div class="user-avatar">
						<img v-if="user.profilePicture" :src="user.profilePicture" />
						<span v-else>{{ user.firstName[0] }}</span>
					</div>
					<span>{{ user.firstName }} {{ user.lastName }}</span>
				</div>
				<div
					class="dropdown-menu custom-user-dropdown"
					aria-labelledby="coursesMenu"
				>
					<a
						role="button"
						class="custom-dropdown-item"
						@click="togglePasswordModal(true)"
					>
						Change Password
					</a>
					<a role="button" class="custom-dropdown-item" @click="logout">
						Logout
					</a>
				</div>
				<a @click="logout" role="button" class="my-nav-link">
					<img class="header-link-icon" src="@/assets/images/logout-icon.svg" />
				</a>
			</div>
		</div>
		<ChangePasswordModal
			:showModal="showChangePasswordModal"
			@updateModalState="updateModalPasswordState"
			:profileData="user"
		/>
	</div>
</template>

<script>
import AdminSidebar from "./AdminSidebar.vue";
import ChangePasswordModal from "../../Profile/ChangePasswordModal.vue";
import { mapState, mapActions } from "vuex";
import { ContentType } from "../../../constants/content";

export default {
	name: "AdminHeader",
	data() {
		return {
			showChangePasswordModal: false,
			productVariantTitle: null,
			searchText: "",
			showDropdown: false,
			ContentType,
		};
	},
	props: {
		collapsed: Boolean,
	},
	components: {
		AdminSidebar,
		ChangePasswordModal,
	},
	computed: {
		...mapState("user", ["loggedIn", "user"]),
		...mapState("productVariant", ["productVariants", "loading"]),
		...mapState("appState", ["systemParameters", "externalServicesList"]),
		showTabs() {
			return this.$route.meta.showTabs;
		},
		productVariantId() {
			if (this.showTabs) {
				return this.$route.params.id;
			} else return null;
		},
		logoPath() {
			return this.systemParameters.LogoPath
				? this.systemParameters.LogoPath
				: require("@/assets/images/gocbe_logo_small.png");
		},
		searchList() {
			// console.log("searchOnInput called");
			var list = [];
			if (this.productVariants.length > 0 && this.searchText !== "") {
				for (var item of Object.values(this.productVariants.items)) {
					// console.log(
					// 	this.productVariants.items[item].product.title
					// 		.toLowerCase()
					// 		.includes(this.searchText.toLowerCase())
					// );
					if (
						(item.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(item.id || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(item.product &&
							(item.product.title || "")
								.toLocaleLowerCase()
								.indexOf(this.searchText.toLocaleLowerCase()) > -1)
					) {
						// this.searchList.push(item);
						list.push(item);
						// console.log(
						// 	"Search result:",
						// 	this.productVariants.items[item].product.title
						// );
					}
				}
			}
			return list;
		},
		isNonAdminPage() {
			return !this.$route.meta.isAdmin;
		},
	},
	methods: {
		...mapActions("user", ["logout"]),
		...mapActions("productVariant", [
			"getProductVariantById",
			"fetchProductVariants",
		]),
		handleLinkClick(item) {
			this.searchText = "";
			this.$router.push(`/management/course/${item.id}/content`);
		},
		closeDropdown() {
			setTimeout(() => (this.showDropdown = false), 300);
		},
		showPDF() {
			if (
				this.user.type &&
				this.systemParameters.AdminKnowledgeBase &&
				this.systemParameters.TrainerKnowledgeBase
			) {
				this.$emit(
					"openPDF",
					this.user.type.toLowerCase() == "management"
						? this.systemParameters.AdminKnowledgeBase
						: this.user.type.toLowerCase() == "trainer"
						? this.systemParameters.TrainerKnowledgeBase
						: ""
				);
			}
		},
		togglePasswordModal(value) {
			this.showChangePasswordModal = value;
		},
		updateModalPasswordState() {
			this.showChangePasswordModal = false;
		},
	},
	created() {
		if (this.showTabs) {
			// console.log(!this.productVariants.items[this.productVariantId]);
			if (!this.productVariants.items[this.productVariantId]) {
				this.getProductVariantById(this.productVariantId).then(() => {
					this.productVariantTitle = `${
						this.productVariants.items[this.productVariantId].product.title
					} ( ${this.productVariants.items[this.productVariantId].title} )`;
				});
			}
		}
		this.fetchProductVariants().then(() => {
			// console.log("fetched product variants for search");
		});
	},
	watch: {
		$route: function () {
			if (this.$route.meta.showTabs) {
				// console.log(!this.productVariants.items[this.productVariantId]);
				if (!this.productVariants.items[this.productVariantId]) {
					this.getProductVariantById(this.productVariantId).then(() => {
						this.productVariantTitle = `${
							this.productVariants.items[this.productVariantId].product.title
						} ( ${this.productVariants.items[this.productVariantId].title} )`;
					});
				} else {
					this.productVariantTitle = `${
						this.productVariants.items[this.productVariantId].product.title
					} ( ${this.productVariants.items[this.productVariantId].title} )`;
				}
			}
		},
	},
};
</script>

<style scoped>
.admin-header .header-container {
	background-color: #ffffff;
	height: 5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	padding: 0 1.5rem;
	column-gap: 1.25rem;
	box-shadow: 0px 0px 6px rgba(185, 185, 185, 0.25);
}
.admin-header {
	background-color: #ffffff;
	height: 5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.admin-header .header-container .menu-icon {
	width: 1.875rem;
}

.admin-header .header-container .searchbar input {
	background-color: #f5f5f5;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 0.75rem 1.25rem;
	width: 100%;
	outline: none;
}

.searchbar {
	flex-grow: 16;
	font-size: 1rem;
	position: relative;
	max-width: 45.75rem;
}

.search-icon {
	position: absolute;
	margin-right: 1.25rem;
	right: 0;
	width: 1.375rem;
	top: 0.875rem;
}

.admin-header .header-container .right-nav {
	margin-left: auto;
	display: flex;
	align-items: center;
	column-gap: 1.25rem;
}

.my-nav-link {
	display: inline-flex;
	color: #231f20 !important;
	align-items: center;
	height: fit-content;
	text-decoration: none;
}

.my-nav-link:hover {
	color: var(--primary-color) !important;
}

.user-avatar {
	width: 2rem;
	height: 2rem;
	color: var(--color-white);
	background-color: #231f20;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2rem;
	margin-right: 0.5rem;
	/* z-index: 100; */
}

.user-avatar img {
	vertical-align: middle;
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.user-profile-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.header-link-icon {
	width: 1rem;
}

.sidebar-header {
	padding: 0 1.5rem;
	height: 5rem;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	box-shadow: 6px 3px 6px 0px #b9b9b912;
	z-index: 100;
	transition: all 0.3s ease;
	text-decoration: none !important;
}

.sidebar-header span {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
	color: #000000;
	transition: all 0.3s ease;
}

.sidebar-header.collapsed {
	width: 6.25rem;
	justify-content: center;
}

.sidebar-header:not(.collapsed) {
	width: 20.5rem;
}

.sidebar-header .sidebar-header-logo {
	/* width: 3rem; */
	height: 56px;
	margin-right: 1rem;
}
.sidebar-header.collapsed .sidebar-header-logo,
.sidebar-header-logo.w-name {
	margin-right: 0;
	width: 3rem;
	height: unset;
}

.header-tabs {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
}

.header-tab {
	padding: 0;
}

.header-tab a {
	color: #000000;
	text-decoration: none;
	padding: 1.75rem 1.5rem;
	font-weight: bold;
	font-size: 1.125rem;
}

.header-tab a.router-link-active {
	background-color: #f9f9f9;
}

.product-variant-title {
	padding: 0 0.5rem;
	font-weight: 500;
	max-width: 18vw;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.search-dropdown {
	position: absolute;
	top: 4.5rem;
	background-color: #fff;
	/* padding: 1rem; */
	z-index: 1000;
	width: 100%;
	box-shadow: 0 3px 6px #2f2f2f1f;
	border-radius: 8px;
}

.searchbar ul {
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 8px;
	max-height: 70vh;
	overflow-y: auto;
}

.searchbar ul li {
	padding: 1rem;
	font-size: 1rem;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	cursor: pointer;
	transition: all 0.3s linear;
}

.searchbar ul li:hover {
	background-color: #f1f1f1;
}

.searchbar ul li:first-of-type {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

.searchbar ul li:last-of-type {
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.searchbar-image {
	width: 4rem;
	height: 4rem;
	object-fit: cover;
	object-position: top;
	border-radius: 6px;
	margin-right: 1rem;
}

.custom-user-dropdown {
	background-color: #ffffff;
	width: 21.25rem;
	box-shadow: 6px 3px 6px #b9b9b929;
	z-index: 999;
}

.custom-dropdown-item {
	display: flex;
	padding: 1.125rem;
	font-size: 1.125rem;
	font-weight: normal !important;
	text-decoration: none !important;
	color: #231f20 !important;
}

.custom-dropdown-item:hover {
	background-color: #f7f7f7;
}

.dropdown-toggle.show {
	display: flex !important;
}

.header-tabs ul.inline-list {
	width: 40vw;
	overflow-y: auto;
	flex-wrap: nowrap;
	display: flex;
}
</style>
