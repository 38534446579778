import { render, staticRenderFns } from "./StudyMaterial.vue?vue&type=template&id=45412820&scoped=true"
import script from "./StudyMaterial.vue?vue&type=script&lang=js"
export * from "./StudyMaterial.vue?vue&type=script&lang=js"
import style0 from "./StudyMaterial.vue?vue&type=style&index=0&id=45412820&prod&lang=css"
import style1 from "./StudyMaterial.vue?vue&type=style&index=1&id=45412820&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45412820",
  null
  
)

export default component.exports