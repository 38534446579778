<template>
    <div>
        <div class="jumbotron ">
            <div class="container tt-box">
                <div style="flex: 1;">
                    <h4 class="inst-title">Instructor</h4>
                    <h1 class="instructor-name-text">{{ trainer.name }}<span></span></h1>
                    <h4 class="instructor-sd">{{ trainer.short_description }}</h4>
                </div>
                <div class="ml-5">
                    <img class="tt-trainer-image" :src="trainer.image_path || '/img/user_img_placeholder.52f910c4.jpg'">
                </div>
            </div>
        </div>
        <div class="container">
            <h2 v-if="trainer.long_description">Bio</h2>
            <h4 v-if="trainer.long_description" style="font-weight: normal;" class="mb-5 mt-3 instructor-ld">{{ trainer.long_description }}</h4>
            <div v-if="filteredProductVariants && filteredProductVariants.length > 0">
                <!-- <h1 class="mt-4">Course</h1> -->
                <div class="mt-3">
                    <ProductListItem v-for="product in filteredProductVariants" :key="product.id" class="grid" :data="product" :purchased="true" style="margin-bottom: 15px;" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapState,
    mapGetters
} from "vuex";
import ProductListItem from "../Product/ProductListItem.vue";
export default {
    data() {
        return {
            trainersid: this.$route.query.trainersid,
            trainer: null,
            filteredProductVariants: [],
        }
    },
    computed: {
        ...mapGetters("productVariant", ["allProductVariants"]),
        ...mapState("user", ["trainers"])
    },
    components: {
        ProductListItem
    },
    methods: {
        ...mapActions("user", ["fetchTrainersList"]),
        ...mapActions("productVariant",["fetchProductVariants"]),
        getTrainer() {
            var trainersList = this.trainers.filter(x => x.id == this.trainersid).map((t) => {
                return {
                    id: t.id,
                    name: t.firstName + " " + t.lastName,
                    long_description: t.extraProperties &&
                        JSON.parse(t.extraProperties).CustomFields.LongDescription
                        ? JSON.parse(t.extraProperties).CustomFields.LongDescription
                        : "",
                    short_description:
                        t.extraProperties &&
                            JSON.parse(t.extraProperties).CustomFields.ShortDescription
                            ? JSON.parse(t.extraProperties).CustomFields.ShortDescription
                            : "",
                    courses: 0,
                    image_path: t.profilePicture ?? null,
                };
            });
            this.trainer = trainersList?.find(x => x);
        },
        filterTrainerCourse(){
            
        this.filteredProductVariants = this.allProductVariants.filter(element => {
            if (element.properties.trainers == null) {
                return false;
            }
            var trainerList = JSON.parse(element.properties.trainers);
            if (trainerList.includes(this.trainersid)) {
                return true;
            }
            return false;
        });
        }
    },
    created() {
        if(this.allProductVariants==null || this.allProductVariants.length<=0)
        {
            this.fetchProductVariants();
        }
        this.filterTrainerCourse();
        if (this.trainers && this.trainers.length > 0) {
            this.getTrainer();
        } else {
            this.fetchTrainersList().then(() => {
                this.getTrainer();
            });
        }
    },
    watch:{
        allProductVariants(){
            this.filterTrainerCourse();
        }
    }

}
</script>
<style scoped>
.inst-title {
    color: var(--primary-color);
    margin-bottom: 0px;
}

.tt-trainer-image {
    border-radius: 90px;
    object-fit: cover;
    object-position: top;
    width: 130px;
    height: 130px;
}

.tt-box {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .tt-box {
        flex-direction: column-reverse;
        align-items: center;
    }

    .tt-box img {
        margin-bottom: 25px;
        border-radius: 100px;
        width: 200px;
        height: 200px;
    }
}
@media only screen and (max-width: 600px) {
    .container{
        max-width: 85%;
    }
    .jumbotron .container{
        max-width: 100%;
    }
}
@media screen and (min-width: 768px){
.jumbotron h1, .jumbotron .h1 {
    font-size: 50px;
    color: #231F20;
}
}
@media (min-width: 1200px)
{
.h1, h1 {
    font-size: 2rem;
}
}
</style>
<style>
.trainer-image {
    border-radius: 20px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    object-fit: cover;
    object-position: top;
    width: 300px;
    height: 250px;
}

img {
    vertical-align: middle;
}

img {
    border: 0;
}

.name-of-instructor {
    font-weight: 500;
    font-size: 2.75rem;
    line-height: 1.3;
    color: var(--secondary-color);
    margin-bottom: 0;
    letter-spacing: -.45px;
    padding: 40px 15px 15px;
}

.instructor-description {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    padding: 0px;
    display: block;
    color: #000;
    text-align: left;
}

.subdesc-of-instructor {
    font-size: 1.375rem;
    color: #898989;
    font-weight: 400;
    display: block;
    line-height: 1.2px;
}

.instructor-name {
    padding: 26px 5px 15px;
}

.description-div {
    padding: 0px 5px;
}
.instructor-name-text{
    color: #231F20;
font-family: Circular Std;
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.instructor-sd{
    color: #231F20;
font-family: Circular Std;
font-size:11px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.instructor-ld{
    color: #4A4A4A;
font-family: Circular Std;
font-size: 14px;
font-style: normal;
font-weight: 450;
line-height: normal;
}
.jumbotron .container {
    max-width: 89%;
}
</style>