var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"show-ticket-modal","visible":_vm.visible,"hide-header":"","centered":"","content-class":"show-ticket-modal","size":"xl","body-class":"modal-body","hide-footer":"","modal-class":"custom-modal","no-close-on-backdrop":""},on:{"change":function($event){return _vm.$emit('updateModalState')}}},[_c('div',{staticClass:"body-container"},[_c('button',{staticClass:"close-button",attrs:{"role":"button"},on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg")}})]),(!_vm.ticket)?_c('div',[_c('h4',[_vm._v("Loading...")])]):_vm._e(),(_vm.ticket!=null)?_c('div',[_c('div',{staticClass:"mb-3"},[_c('h1',{staticClass:"add-enroll-title"},[_vm._v(_vm._s(_vm.parseTicketHash(_vm.ticket.id))+" - "+_vm._s(_vm.ticket.subject))]),_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('div',{class:[
              'status-tag',
              {
                warning: _vm.ticket.status && _vm.ticket.status.toLowerCase() == `opened`,
                success: _vm.ticket.status && _vm.ticket.status.toLowerCase() == `closed`,
              },
            ]},[_vm._v(" "+_vm._s(_vm.ticket.status)+" ")]),_c('i',{staticClass:"fas fa-circle",staticStyle:{"font-size":"0.4em"}}),_vm._v(" "+_vm._s(_vm.ticket.service ? _vm.ticket.service.title : "")+" ")])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-lg-8 row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_c('strong',[_vm._v("Opened By :")]),_vm._v(" "+_vm._s(_vm.ticket.user ? `${_vm.ticket.user.firstName} ${_vm.ticket.user.lastName}` : "")+" ( "+_vm._s(_vm.formatDate(_vm.ticket.createdAt))+" )")])]),(_vm.ticket.lastTicketReply)?_c('div',{staticClass:"col-lg-12"},[_c('label',[_c('strong',[_vm._v("Last Modification :")]),(_vm.ticket.lastTicketReply && _vm.ticket.lastTicketReply.user)?_c('span',[_vm._v(" "+_vm._s(_vm.ticket.lastTicketReply.user.firstName + " " + _vm.ticket.lastTicketReply.user.lastName)+" ")]):_vm._e(),_vm._v(" ( "+_vm._s(_vm.ticket.lastTicketReply ? _vm.formatDate(_vm.ticket.lastTicketReply.createdAt) : _vm.formatDate(_vm.ticket.createdAt))+" )")])]):_vm._e(),(
              _vm.ticket && _vm.ticket.status && _vm.ticket.status.toLowerCase() == 'closed'
            )?_c('div',{staticClass:"col-lg-12"},[_c('label',[_c('strong',[_vm._v("Closed By : ")]),(_vm.ticket.closedBy)?_c('span',[_vm._v(" "+_vm._s(_vm.ticket.closedBy.firstName + " " + _vm.ticket.closedBy.lastName)+" ")]):_vm._e(),_vm._v(" ( "+_vm._s(_vm.ticket.closedAt ? _vm.formatDate(_vm.ticket.closedAt) : "")+" )")])]):_vm._e()]),(_vm.ticket.status && _vm.ticket.status.toLowerCase() == `opened`)?_c('div',{staticClass:"col-lg-4",staticStyle:{"text-align":"-webkit-right"}},[_c('button',{staticClass:"add-btn",attrs:{"role":"button"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v(" Refresh ")])]):_vm._e(),_c('div',{staticClass:"col-lg-12 pt-2"},[_c('hr'),_c('h4',{staticClass:"add-enroll-title"},[_vm._v("Description")]),_vm._v(" "+_vm._s(_vm.ticket.body)+" ")])]),(_vm.replies && _vm.replies.length > 0)?_c('div',[_c('div',{staticClass:"mt-4"},[_c('h4',{staticClass:"add-enroll-title"},[_vm._v("Correspondence")])]),_vm._l((_vm.replies),function(reply){return _c('div',{class:[
            'chat-box-style',
            'mb-3',
            { management: reply.user.type.toLowerCase() == 'management' },
          ]},[_c('label',{attrs:{"for":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("From")]),_vm._v(" "+_vm._s(reply.user.firstName)+" "+_vm._s(reply.user.lastName))]),_c('p',{attrs:{"for":""}},[_vm._v(" "+_vm._s(reply.reply)+" ")]),_c('label',{staticClass:"text-end w-100",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.formatDate(reply.createdAt)))])])})],2):_vm._e()]):_vm._e(),(
        _vm.ticket && _vm.ticket.status && _vm.ticket.status.toLowerCase() == 'opened'
      )?_c('div',{staticClass:"col-lg-12 mt-4 reply-body ps-0"},[_c('hr'),(_vm.ticket!=null)?_c('div',[_c('h4',[_vm._v("Reply")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reply),expression:"reply"}],staticClass:"form-input",attrs:{"placeholder":"Enter here...","disabled":_vm.loading},domProps:{"value":(_vm.reply)},on:{"input":[function($event){if($event.target.composing)return;_vm.reply=$event.target.value},_vm.resizeTextarea]}}),_c('div',{staticClass:"step-btn-container mt-2"},[_c('button',{staticClass:"step-btn",attrs:{"role":"button","disabled":!_vm.reply || !_vm.ticket || _vm.loading},on:{"click":function($event){return _vm.sendTicketReply()}}},[_vm._v(" Send Reply ")]),_c('button',{staticClass:"step-btn",attrs:{"role":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeTicket()}}},[_vm._v(" Close The Ticket ")])])]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }