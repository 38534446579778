<template>
	<div class="question-list-container" id="question-list-container">
		<div v-if="selectMode">
			<div
				v-for="(question, index) in questionList"
				:key="question.ID"
				class="d-flex align-items-center gap-2"
			>
				<input
					class="form-check-input m-0"
					type="checkbox"
					name="exam-question"
					:id="`question-${index}`"
					:value="question.ID"
					v-model="questionSelected"
				/>
				<label class="w-100" :for="`question-${index}`">
					<QuestionListItem :questionData="question" :selectMode="selectMode" />
				</label>
			</div>
		</div>
		<div class="d-flex flex-column gap-2" v-else>
			<QuestionListItem
				v-for="question in questionList"
				:key="question.ID"
				:questionData="question"
				:selectMode="selectMode"
			/>
		</div>
		<div class="d-flex h-100" v-if="questionList && questionList.length == 0">
			<div v-if="searchText.length == 0 && !selectMode" class="no-questions">
				<span
					>You haven't added any questions
					{{ this.selectedCategory.length > 0 ? "in this pool" : " yet" }}</span
				>
				<button
					v-if="!selectMode"
					class="add-btn"
					role="button"
					@click="toggleQuestionModal(true)"
				>
					<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
					add question
				</button>
				<AddQuestionModal
					v-if="!selectMode"
					:showModal="showQuestionModal"
					@updateModalState="toggleQuestionModal"
					:productVariantId="productVariantId"
					:selectedCategory="selectedCategory"
				/>
			</div>
			<div v-else class="no-questions">
				<span>No Questions found</span>
			</div>
		</div>
		<b-spinner v-if="moreLoading"></b-spinner>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuestionListItem from "./QuestionListItem.vue";
import AddQuestionModal from "./AddQuestionModal.vue";

export default {
	name: "QuestionList",
	data() {
		return {
			showQuestionModal: false,
			questionSelected: [],
		};
	},
	components: {
		QuestionListItem,
		AddQuestionModal,
	},
	computed: {
		...mapState("exam", ["totalPages"]),
	},
	props: {
		questionList: Array,
		productVariantId: String,
		selectedCategory: Array,
		searchText: String,
		pageSize: Number,
		currentPage: Number,
		moreLoading: Boolean,
		selectMode: { type: Boolean, default: false },
	},
	methods: {
		toggleQuestionModal(value) {
			if (value !== undefined) {
				this.showQuestionModal = value;
			} else {
				this.showQuestionModal = !this.showQuestionModal;
			}
		},
	},
	mounted() {
		// jQuery(function ($) {
		var myDiv = document.getElementById("question-list-container");
		// console.log(myDiv);
		myDiv.addEventListener("scroll", () => {
			if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 5) {
				// console.log("scrolled to bottom");
				if (this.currentPage < this.totalPages[this.selectedCategory.at(-1).id])
					this.$emit("fetchMore", this.currentPage + 1);
			}
		});
		// $("#question-list-container").on("scroll", function () {
		// 	console.log("scrolling");
		// 	if (
		// 		$(this).scrollTop() + $(this).innerHeight() >=
		// 		$(this)[0].scrollHeight
		// 	) {
		// 		alert("end reached");
		// 	}
		// });
		// });
	},
	watch: {
		questionSelected: function () {
			this.$emit("selectQuestions", this.questionSelected);
		},
	},
};
</script>

<style scoped>
.question-list-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	overflow-y: auto;
	flex-grow: 1;
}

.no-questions {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-grow: 1;
	color: var(--color-gray);
	font-size: 1.25rem;
	row-gap: 1rem;
}
</style>
