<template>
	<div class="home">
		<HomePageBanner
			v-if="
				systemParameters.Banners && systemParameters.Banners.slides.length > 0
			"
		/>

		<ProductCategoryTabs v-if="categories && categories.length">
		</ProductCategoryTabs>

		<HomePageInstructorSection v-if="trainers && trainers.length > 0" />
		<!-- <HomePageClientsSection /> -->
		<!-- <HomePageTeachOrPartner /> -->
		<HomePageSignupSteps />
		<!-- <HomePageWhyUs /> -->
		<!-- <HomePageTestimonials /> -->
		<!-- </div> -->
		<!-- </div> -->
	</div>
</template>

<script>
// import HomePageServiceFeatures from "../components/Layout/HomePageServiceFeatures.vue";
import HomePageBanner from "../components/Layout/HomePageBanner.vue";
import HomePageBannerWithOverlay from "../components/Layout/HomePageBannerWithOverlay.vue";

// import ProductList from "../components/Product/ProductList.vue";
import ProductCategoryTabs from "@/components/Product/ProductCategoryTabs.vue";
import HomePageInstructorSection from "../components/Instructor/HomepageInstructorSection.vue";
// import HomePageClientsSection from "../components/Layout/HomePageClientsSection.vue";
// import HomePageTeachOrPartner from "../components/Layout/HomePageTeachOrPartner.vue";
import HomePageSignupSteps from "../components/Layout/HomePageSignupSteps.vue";
// import HomePageWhyUs from "../components/Layout/HomePageWhyUs.vue";
// import HomePageTestimonials from "../components/Layout/HomePageTestimonials.vue";
import { mapActions, mapState } from "vuex";
export default {
	name: "Home",
	components: {
		HomePageBanner,
		// HomePageBannerWithOverlay,
		// HomePageServiceFeatures,
		// ProductList,
		ProductCategoryTabs,
		HomePageInstructorSection,
		// HomePageClientsSection,
		// HomePageTeachOrPartner,
		HomePageSignupSteps,
		// HomePageWhyUs,
		// HomePageTestimonials,
	},
	data() {
		return {
			// banners: banners,
		};
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		...mapState("user", ["trainers"]),
		...mapState("category", ["categories"]),
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		...mapActions("category", ["fetchCategories"]),
		...mapActions("productVariant", [
			"fetchProductVariants"
		]),
	},
	mounted() {
		this.$nextTick(() => {
			// const event = new CustomEvent("homepage-loaded");
			// document.dispatchEvent(event);
			window.postMessage({ event: "homepage-loaded", data: null });
		});
	},
	created() {
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		this.fetchCategories().then(() => {});
		this.fetchProductVariants().then(() => {});
	},
};
</script>

<style scoped>
.section-heading {
	font-weight: 500;
	font-size: 2.75rem;
	line-height: 1.3;
	color: var(--secondary-color);
	margin-bottom: 0;
	letter-spacing: -0.45px;
	margin-bottom: 0;
}

.section-subheading {
	font-size: 1.375rem;
	color: #898989;
	font-weight: normal;
}

.section-heading-container {
	margin: 4rem 0 3.375rem;
}

/* .section-heading::before {
	content: "";
	width: 3.1rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -0.7rem;
	left: 0.3rem;
	position: relative;
} */
</style>
