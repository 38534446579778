class ExamStatus {
	static DRAFT = "Draft";
	static PUBLISHED = "Published";
}

class ExamType {
	static SURVEY = "Survey";
	static EXAM = "Exam";
}

class ExamQuestionType {
	static QUESTION = "Question";
	static CRITERIA = "Criteria";
}

class ExamQuestionWeightageType {
	static FIXED_VALUE = "FixedValue";
	static PERCENTAGE = "Percentage";
}

class ExamCriteria {
	static CATEGORY = "CategoryIDs";
	static DIFFICULTY_LEVEL = "DifficultyLevels";
	static MARKS_RANGE = "MarksRange";
	static QUESTION_TYPE = "QuestionTypes";
}

class ExamCriteriaText {
	static [ExamCriteria.CATEGORY] = "Pools";
	static [ExamCriteria.DIFFICULTY_LEVEL] = "Difficulty Levels";
	static [ExamCriteria.MARKS_RANGE] = "Marks Range";
	static [ExamCriteria.QUESTION_TYPE] = "Question Types";
}

const examTypeOptions = [
	{
		label: "Survey",
		value: ExamType.SURVEY,
	},
	{
		label: "Exam",
		value: ExamType.EXAM,
	},
];

const examQuestionTypeOptions = [
	{
		label: "Question",
		value: ExamQuestionType.QUESTION,
	},
	{
		label: "Criteria",
		value: ExamQuestionType.CRITERIA,
	},
];

const examQuestionWeightageTypeOptions = [
	{
		label: "Fixed Value (Marks/Points)",
		value: ExamQuestionWeightageType.FIXED_VALUE,
	},
	{
		label: "Percentage",
		value: ExamQuestionWeightageType.PERCENTAGE,
	},
];

const examCriteriaOptions = [
	{
		label: "Question Pools",
		value: ExamCriteria.CATEGORY,
	},
	{
		label: "Difficulty Levels",
		value: ExamCriteria.DIFFICULTY_LEVEL,
	},
	{
		label: "Marks Range",
		value: ExamCriteria.MARKS_RANGE,
	},
	{
		label: "Question Types",
		value: ExamCriteria.QUESTION_TYPE,
	},
];

class LegacyExam {
	static GET_EXAM_ACTION = {
		ATTEMPT: "attempt_test",
		VIEW_RESULTS: "view_testwise_report",
		REVIEW_EXAM: "review_exam",
		CUSTOM_URL: "custom_url",
		LIST_QUESTION: "list_question",
		CREATE_QUESTION: "create_question",
		REVIEW_QUESTIONS: "review_questions",
	};
}

export {
	ExamType,
	ExamStatus,
	ExamQuestionType,
	ExamQuestionWeightageType,
	ExamCriteria,
	ExamCriteriaText,
	examTypeOptions,
	examQuestionTypeOptions,
	examQuestionWeightageTypeOptions,
	examCriteriaOptions,
	LegacyExam,
};
