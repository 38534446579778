import { categoryService } from "../services/category.service";
import Vue from "vue";
import store from ".";
import { quizService } from "../services/quiz.service";

const state = {
  loading: false,
};

const getters = {};

const actions = {
  submitQuizAttempt: async ({ commit }, quizAttempt) => {
    commit("setLoading", true);
    var quizAttempt = await quizService.submitQuizAttempt(quizAttempt).then(
      (quizAttempt) => {
        commit("setLoading", false);
        return quizAttempt.payload;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return quizAttempt;
  },

  getQuizDetail: async ({ commit }, quizAttemptId) => {
    commit("setLoading", true);
    var data = await quizService.getQuizDetail(quizAttemptId).then(
      (data) => {
        commit("setLoading", false);
        return data.payload;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  createQuestion: async ({ commit }, quizObj) => {
    commit("setLoading", true);
    var quiz = await quizService.createQuestion(quizObj).then(
      (res) => {
        commit("setLoading", false);
        return res;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return quiz;
  },

  getQuizQuestions: async ({ commit }, contentID) => {
    commit("setLoading", true);

    var quizQuestions = await quizService.getQuizQuestions(contentID).then(
      (res) => {
        commit("setLoading", false);
        return res?.payload;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return quizQuestions;
  },

  deleteQuestion: async ({ commit }, questionID) => {
    commit("setLoading", true);

    var data = await quizService.deleteQuestion(questionID).then(
      (res) => {
        commit("setLoading", false);
        return res;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  updateQuestion: async ({ commit }, obj) => {
    commit("setLoading", true);

    var data = await quizService.updateQuestion(obj).then(
      (res) => {
        commit("setLoading", false);
        return res;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
};

const mutations = {
  setLoading(state, data) {
    state.loading = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
