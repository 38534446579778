class AttendanceStatus {
	static PRESENT = "present";
	static ABSENT = "absent";
	static ONLINE = "online";
	static UNPAID = "unpaid";
}

class AttendanceType {
	static PHYSICAL = "physical";
	static ONLINE = "online";
}

module.exports = { AttendanceStatus, AttendanceType };
